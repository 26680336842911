import { Edge } from "Constants/Edge";
import { EDGE_FRAGMENT } from "GraphQL/Fragment/EdgeFragment";
import { Metadata, MetadataWhereInput } from "GraphQL/Schema";
import { gql } from "Lib/apollo-client";
import { useImperativeQuery } from "Utils/useImperativeQuery";

export const LOAD_METADATA_TRACK = gql`
  ${EDGE_FRAGMENT}
  query LOAD_METADATA_TRACK($where: MetadataWhereInput, $first: Int, $page: Int, $orderBy: [MetadataOrderByInput!]) {
    metadata(where: $where, first: $first, page: $page, orderBy: $orderBy, group: true) {
      metadata_id
    }
    edge(node: Metadata, where: { metadata: $where }, first: $first, page: $page, orderBy: { metadata: $orderBy }) {
      ...EdgeFragment
    }
  }
`;

type Result = {
  readonly metadata: Metadata[];
  readonly edge: Edge;
};

type Args = {
  readonly where?: MetadataWhereInput;
  readonly first?: number;
  readonly page?: number;
  readonly orderBy?: string[];
  readonly skip?: boolean;
};

export const useImperativeMetadata = () => {
  const refetch = useImperativeQuery<Result, Args>(LOAD_METADATA_TRACK);

  return { loadMetdata: refetch };
};
