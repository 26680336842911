import styled from "styled-components";
import { Content } from "./Content";

export const PurchaseItems = () => {
  return (
    <Layout>
      <Content />
    </Layout>
  );
};

const Layout = styled.div``;
