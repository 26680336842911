import styled from "styled-components";
import { MenuContent } from "./MenuContent";

export const MenuList = () => {
  return (
    <Layout>
      <MenuContent />
    </Layout>
  );
};

const Layout = styled.div``;
