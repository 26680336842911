import { Button, ButtonColor } from "App/Atomics/Button";
import { Icon } from "App/Atomics/Icon";
import { ModalAction, ModalProps, ModalStore } from "App/Store/ModalStore";
import { useTrackActivityNodeStore } from "App/Store/TrackActivity";
import { Color } from "Constants/Color";
import { DownloadMusicPurchaseModal, FullDownloadModalDescription, MusicDownload } from "Constants/Locale";
import { useLoadMetadatasUrl } from "GraphQL/Queries/useLoadMetadatasUrl";
import { Metadata, TrackActivityType } from "GraphQL/Schema";
import { Modal } from "Lib/modal";
import { Toast } from "Lib/toast";
import { LOADED, useLoading } from "Lib/use-loading";
import { TrackID } from "Models/TrackModel";
import { useState } from "react";
import styled from "styled-components";
import { downloadResource } from "Utils/DownloadResource";

type Props = {
  readonly props: ModalProps;
  readonly isOpen: boolean;
};

const typeUrl: Record<string, string> = {
  mp3high: "MP3",
  wav: "WAV"
};

export const TracksDownloadModal = ({ props, isOpen }: Props) => {
  const tracks = props.tracks as TrackID[];
  const activity = useTrackActivityNodeStore(store => store.values()).filter(activity => activity.type === TrackActivityType.Purchase);
  const arrowTrackIds = tracks.filter(({ raw }) => activity.map(({ track }) => track.raw).includes(raw));

  const onRequestClose = () => ModalStore.dispatch(ModalAction.closeAll());

  const { data, loading } = useLoadMetadatasUrl({ ids: arrowTrackIds.map(({ raw }) => raw) });

  const metadata = data?.metadata ?? [];

  const downloadLoading = useLoading(LOADED);

  const [selectTypeUrl, setSelectTypeUrl] = useState<string | null>();
  const tryToDownloadResource = async (metadata: Metadata[], type_url: string) => {
    setSelectTypeUrl(type_url);
    downloadLoading.setLoading();
    const filterTypeUrlMetadata = metadata.filter(({ metadata_url }) => metadata_url?.find(m => m.type_url === type_url));
    if (!filterTypeUrlMetadata.length) {
      Toast.warning(`${type_url} 음원이 없습니다.`);
    }
    for (const item of filterTypeUrlMetadata) {
      const url = item.metadata_url?.find(m => m.type_url === type_url)?.url;
      if (url) {
        await downloadResource(url, item?.title);
      }
    }
    downloadLoading.setLoaded();
    setSelectTypeUrl(null);
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Layout>
        <HeaderLayout>
          <Title>
            <MusicDownload />
          </Title>
          <CrossIcon name="close" onClick={onRequestClose}></CrossIcon>
        </HeaderLayout>
        <Desciprtion>
          <DownloadMusicPurchaseModal />
        </Desciprtion>
        <Desciprtion>
          <FullDownloadModalDescription />
        </Desciprtion>

        {!!arrowTrackIds.length ? (
          <ButtonGroup>
            {loading && <Loading name="loading" />}
            {!loading && !!metadata?.length && (
              <>
                {Object.entries(typeUrl).map(([key]) => (
                  <ButtonContainer>
                    <Text>{key}</Text>
                    {downloadLoading.isLoaded && (
                      <PlayButton color={ButtonColor.Primary} onClick={() => tryToDownloadResource(metadata, key)}>
                        <ButtonText>
                          <MusicDownload />
                        </ButtonText>
                      </PlayButton>
                    )}
                    {downloadLoading.isLoading && (
                      <PlayButton color={ButtonColor.Disable}>
                        <ButtonText>
                          {selectTypeUrl === key && <Loading name="loading" />}
                          {selectTypeUrl !== key && <MusicDownload />}
                        </ButtonText>
                      </PlayButton>
                    )}
                  </ButtonContainer>
                ))}
              </>
            )}
          </ButtonGroup>
        ) : (
          <></>
        )}
      </Layout>
    </Modal>
  );
};

const Layout = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;
  background-color: ${Color.Ac_Charcoal};
  padding: 30px;
  overflow: hidden;
  pointer-events: auto;

  display: flex;
  flex-direction: column;
`;

const HeaderLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  font-size: 1.5rem;
`;

const CrossIcon = styled(Icon)`
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
`;

const Desciprtion = styled.p`
  font-size: 0.8rem;
  font-weight: bold;
  white-space: normal;
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem;
`;

const PlayButton = styled(Button)`
  height: 35px;
  border-radius: 1rem;

  border: none;
`;
const ButtonText = styled.span`
  font-size: 0.75rem;

  line-height: 1;
  font-weight: bold;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 0;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
`;

const Text = styled.div`
  font-weight: bold;
  font-size: 0.9rem;
`;

const Loading = styled(Icon)`
  margin: 0 6px;
  width: 54.7px;
  height: 20px;
`;
