import { setTrackLicense } from "App/Common/setTrackLicense";
import { TrackLicenseNodeStore } from "App/Store/TrackLicenseStore";
import { useLoadTrackLicense } from "GraphQL/Queries/useLoadTrackLicense";
import { useLoaded } from "Lib/use-loaded";
import { TrackID } from "Models/TrackModel";
import { useEffect } from "react";

interface Props {
  trackId: TrackID;
}

export const useTrackLicense = ({ trackId }: Props) => {
  const { data, loading } = useLoadTrackLicense({
    where: {
      metadata__some: { metadata_id: trackId.raw.toString() },
      copyright: true,
      neighboring: true,
      country_code__in: ["ZZ", "KR"]
    }
  });

  const { isLoaded } = useLoaded(loading);

  useEffect(() => {
    TrackLicenseNodeStore.clear();
    if (data) {
      const { trackLicense } = data;
      setTrackLicense({ trackLicenseList: trackLicense });
    }
  }, [data]);

  return { isLoaded };
};
