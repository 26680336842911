import { setTrack } from "App/Common/setTrack";
import { MAX_FIRST_COUNT } from "Constants/Count";
import { useLoadTrack } from "GraphQL/Queries/useLoadTrack";
import { useLoaded } from "Lib/use-loaded";
import { useEffect } from "react";

interface Props {
  ids: number[];
  isSkip?: boolean;
}

export const useMyPlaylistTrack = ({ ids, isSkip }: Props) => {
  const { data, loading } = useLoadTrack({
    where: {
      id__in: ids
    },
    skip: isSkip,
    first: MAX_FIRST_COUNT
  });
  const { isLoaded } = useLoaded(loading);

  useEffect(() => {
    if (data) {
      const { track } = data;
      setTrack(track);
    }
  }, [data]);

  return { isLoaded };
};
