import { Icon } from "App/Atomics/Icon";
import { DESKTOP_MODE, MOBILE_MODE, Size, WIDE_MODE } from "Constants/Size";
import { Playing, Repeat } from "Lib/player";
import styled, { css } from "styled-components";
import { Player, useCurrent, usePlayer } from "Utils/artistscardPlayer";

export const PlayerButtonGroup = () => {
  const { isShuffle, playing, repeat } = usePlayer();
  const toggleShuffle = () => Player.toggleShuffle();
  const current = useCurrent();

  const isPlay = playing === Playing.Play;

  const onPlayByType = (type: "current" | "prev" | "next" = "current") => {
    switch (type) {
      case "prev":
        Player.previous();
        return;
      case "next":
        Player.next();
        return;
      case "current":
        if (!current?.track) {
          Player.next();
          return;
        }
        Player.play();
        return;
    }
  };

  return (
    <Layout onClick={e => e.stopPropagation()}>
      <ButtonWrap className="shuffle" onClick={toggleShuffle}>
        {isShuffle && <ButtonIcon name="shuffle-on" />}
        {!isShuffle && <ButtonIcon name="shuffle" />}
      </ButtonWrap>
      <IconButton className="prev" onClick={() => onPlayByType("prev")}>
        <ButtonIcon name="back-play" />
      </IconButton>
      {isPlay && (
        <IconButton onClick={Player.pause}>
          <PlayIcon name="pause" />
        </IconButton>
      )}
      {!isPlay && (
        <IconButton onClick={() => onPlayByType("current")}>
          <PlayIcon name="play" />
        </IconButton>
      )}
      <IconButton className="next" onClick={() => onPlayByType("next")}>
        <ButtonIcon name="forward-play" />
      </IconButton>
      <ButtonWrap className="repeat" onClick={Player.nextRepeat}>
        {repeat === Repeat.Stop && <ButtonIcon name="repeat" />}
        {repeat === Repeat.Current && <ButtonIcon name="repeat-on-one" />}
        {repeat === Repeat.All && <ButtonIcon name="repeat-on" />}
      </ButtonWrap>
    </Layout>
  );
};

const Layout = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: center;
  @media ${WIDE_MODE} {
    align-items: center;
    margin: 0 auto;
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 820px) {
    display: none;
  }
`;

const ButtonStyle = css`
  margin: 0 ${Size.MARGIN_LARGE_PX};
  @media ${DESKTOP_MODE} {
    margin: 0 ${Size.MARGIN_X_LARGE_PX};
  }

  cursor: pointer;
  transition: transform 0.15s;
  &:hover {
    transform: scale(1.1);
  }
`;

const ButtonIcon = styled(Icon)`
  ${ButtonStyle}
  width: 1.5rem;
  height: 1.5rem;

  @media ${MOBILE_MODE} {
    width: 1.8rem;
    height: 1.8rem;
  }
`;
const PlayIcon = styled(Icon)`
  ${ButtonStyle}
  width: 1.8rem;
  height: 1.8rem;
  @media ${MOBILE_MODE} {
    width: 2.2rem;
    height: 2.2rem;
  }
`;

const IconButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${Size.PADDING_SMALL_PX} 0;
`;
