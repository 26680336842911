import { ReactComponent as HeaderLogo } from "Assets/Icons/header-logo.svg";
import { Color } from "Constants/Color";
import { ReactComponent as Plus } from "Assets/Icons/plus.svg";
import { ReactComponent as Close } from "Assets/Icons/close.svg";
import { ReactComponent as DownArrow } from "Assets/Icons/down-arrow.svg";
import { ReactComponent as Play } from "Assets/Icons/play.svg";
import { ReactComponent as Minus } from "Assets/Icons/minus.svg";
import { ReactComponent as LoginLogo } from "Assets/Icons/login-logo.svg";
import { ReactComponent as CheckboxFill } from "Assets/Icons/checkbox-fill.svg";
import { ReactComponent as CheckBox } from "Assets/Icons/checkbox.svg";
import { ReactComponent as LikeFill } from "Assets/Icons/like-fill.svg";
import { ReactComponent as Like } from "Assets/Icons/like.svg";
import { ReactComponent as AddPlaylist } from "Assets/Icons/add-playlist.svg";
import { ReactComponent as Download } from "Assets/Icons/download.svg";
import { ReactComponent as Link } from "Assets/Icons/link.svg";
import { ReactComponent as BackPlay } from "Assets/Icons/back-play.svg";
import { ReactComponent as ForwardPlay } from "Assets/Icons/forward-play.svg";
import { ReactComponent as Volume0 } from "Assets/Icons/volume-0.svg";
import { ReactComponent as Volume1 } from "Assets/Icons/volume-1.svg";
import { ReactComponent as Volume2 } from "Assets/Icons/volume-2.svg";
import { ReactComponent as Search } from "Assets/Icons/search.svg";
import { ReactComponent as Clear } from "Assets/Icons/clear.svg";
import { ReactComponent as YoutubeGray } from "Assets/Icons/youtube-gray.svg";
import { ReactComponent as InstaGray } from "Assets/Icons/insta-gray.svg";
import { ReactComponent as Cart } from "Assets/Icons/cart.svg";
import { ReactComponent as Mood } from "Assets/Icons/mood.svg";
import { ReactComponent as Genre } from "Assets/Icons/genre.svg";
import { ReactComponent as DropdownDown } from "Assets/Icons/dropdown-down.svg";
import { ReactComponent as FlagUsa } from "Assets/Icons/flag-usa.svg";
import { ReactComponent as FlagSouthKorea } from "Assets/Icons/flag-south-korea.svg";
import { ReactComponent as Hamburger } from "Assets/Icons/hamburger.svg";
import { ReactComponent as ChangePen } from "Assets/Icons/change-pen.svg";
import { ReactComponent as BasicTrack } from "Assets/Icons/basic-track.svg";
import { ReactComponent as DetailTrack } from "Assets/Icons/detail-track.svg";
import { ReactComponent as Loading } from "Assets/Icons/loading.svg";
import { ReactComponent as LibraryFill } from "Assets/Icons/library-fill.svg";
import { ReactComponent as Library } from "Assets/Icons/library.svg";
import { ReactComponent as Logout } from "Assets/Icons/logout.svg";
import { ReactComponent as My } from "Assets/Icons/my.svg";
import { ReactComponent as Pause } from "Assets/Icons/pause.svg";
import { ReactComponent as AddPlaylistFill } from "Assets/Icons/add-playlist-fill.svg";
import { ReactComponent as DownButton } from "Assets/Icons/down-button.svg";
import { ReactComponent as Repeat } from "Assets/Icons/repeat.svg";
import { ReactComponent as RepeatOnOne } from "Assets/Icons/repeat-on-one.svg";
import { ReactComponent as RepeatButton } from "Assets/Icons/repeat-button.svg";
import { ReactComponent as RepeatOneButton } from "Assets/Icons/repeat-one-button.svg";
import { ReactComponent as RepeatOn } from "Assets/Icons/repeat-on.svg";
import { ReactComponent as ShuffleOn } from "Assets/Icons/shuffle-on.svg";
import { ReactComponent as Shuffle } from "Assets/Icons/shuffle.svg";
import { ReactComponent as MainLogo } from "Assets/Icons/main-logo.svg";
import { ReactComponent as Logo } from "Assets/Icons/logo.svg";
import { ReactComponent as Filter } from "Assets/Icons/filter.svg";
import { ReactComponent as Coin } from "Assets/Icons/coin.svg";
import { ReactComponent as HamburgerBar } from "Assets/Icons/hamburger-bar.svg";
import { ReactComponent as LogoWhite } from "Assets/Icons/logo-white.svg";
import { ReactComponent as Home } from "Assets/Icons/home.svg";
import { ReactComponent as Exchange } from "Assets/Icons/exchange.svg";
import { ReactComponent as Notice } from "Assets/Icons/notice.svg";
import { ReactComponent as Question } from "Assets/Icons/question.svg";
import { ReactComponent as Money } from "Assets/Icons/money.svg";
import { ReactComponent as AccountMail } from "Assets/Icons/account-mail.svg";
import { ReactComponent as ArrowNext } from "Assets/Icons/arrow-next.svg";
import { ReactComponent as paymentComplete } from "Assets/Icons/payment-complete.svg";
import { ReactComponent as Curation } from "Assets/Icons/curation.svg";
import { ReactComponent as Check } from "Assets/Icons/check.svg";
import { ReactComponent as RemoveMusic } from "Assets/Icons/remove-music.svg";
import { ReactComponent as Pdf } from "Assets/Icons/pdf.svg";

export const icons = {
  "header-logo": HeaderLogo,
  "down-arrow": DownArrow,
  play: Play,
  plus: Plus,
  close: Close,
  minus: Minus,
  "login-logo": LoginLogo,
  "checkbox-fill": CheckboxFill,
  checkbox: CheckBox,
  "like-fill": LikeFill,
  like: Like,
  "add-playlist": AddPlaylist,
  "add-playlist-fill": AddPlaylistFill,
  download: Download,
  link: Link,
  "back-play": BackPlay,
  "forward-play": ForwardPlay,
  "volume-0": Volume0,
  "volume-1": Volume1,
  "volume-2": Volume2,
  search: Search,
  clear: Clear,
  "youtube-gray": YoutubeGray,
  "insta-gray": InstaGray,
  mood: Mood,
  genre: Genre,
  cart: Cart,
  "dropdown-down": DropdownDown,
  "flag-usa": FlagUsa,
  "flag-south-korea": FlagSouthKorea,
  hamburger: Hamburger,
  "basic-track": BasicTrack,
  "detail-track": DetailTrack,
  "change-pen": ChangePen,
  loading: Loading,
  repeat: Repeat,
  "repeat-on-one": RepeatOnOne,
  "repeat-button": RepeatButton,
  "repeat-one-button": RepeatOneButton,
  "repeat-on": RepeatOn,
  "library-fill": LibraryFill,
  "shuffle-on": ShuffleOn,
  shuffle: Shuffle,
  library: Library,
  logout: Logout,
  my: My,
  pause: Pause,
  "down-button": DownButton,
  "main-logo": MainLogo,
  logo: Logo,
  filter: Filter,
  coin: Coin,
  "hamburger-bar": HamburgerBar,
  "logo-white": LogoWhite,
  home: Home,
  exchange: Exchange,
  notice: Notice,
  question: Question,
  money: Money,
  "account-mail": AccountMail,
  "arrow-next": ArrowNext,
  "payment-complete": paymentComplete,
  curation: Curation,
  check: Check,
  "remove-music": RemoveMusic,
  pdf: Pdf
};

export type IconName = keyof typeof icons;

type Props = Styleable & {
  name: IconName;
  title?: string;
  disabled?: boolean;
  fill?: string;
  onClick?: () => void;
};

export const Icon = ({ style, name, fill = Color.Ac_White, ...props }: Props) => {
  const Icon = icons[name] || null;
  return Icon && <Icon style={{ fill, ...style }} {...props} />;
};
