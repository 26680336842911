import { usePlaylistNode } from "App/Store/PlaylistNodeStore";
import { PlaylistModalAdd } from "Constants/Locale";
import { useNoCacheAddPlaylistTrack } from "GraphQL/Mutations/useNoCacheAddPlaylistTrack";
import { Toast } from "Lib/toast";
import { PlaylistID } from "Models/PlaylistModel";
import { TrackID } from "Models/TrackModel";
import styled from "styled-components";

type Props = {
  readonly id: PlaylistID;
  readonly trackIds: TrackID[];
  readonly onClose: () => void;
};

export const PlaylistItem = ({ id, trackIds, onClose }: Props) => {
  const playlist = usePlaylistNode(id);
  const { addPlaylistTrack } = useNoCacheAddPlaylistTrack();

  const addPlaylist = async () => {
    try {
      const playlistTracks = playlist?.trackList.map(({ id }) => id.raw) ?? [];
      const addTrackList = trackIds.filter(({ raw }) => !playlistTracks.includes(raw)) ?? [];

      for (const addTrack of addTrackList) {
        await addPlaylistTrack({
          variables: {
            playlistId: id.raw,
            trackId: addTrack.raw
          }
        });
      }

      Toast.success(PlaylistModalAdd.message());
    } catch (error) {
      console.log(error);
    }
    onClose();
  };

  return (
    <Layout onClick={addPlaylist}>
      <Title>{playlist?.title}</Title>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }

  justify-content: space-between;
`;

const Title = styled.p`
  font-size: 0.825rem;
`;
