import { DocumentNode, TypedDocumentNode, useQuery } from "@apollo/client";

export const useImperativeQuery = <TData extends any, TVariables>(query: DocumentNode | TypedDocumentNode<TData, TVariables>) => {
  const { refetch } = useQuery<TData, TVariables>(query, { skip: true, fetchPolicy: "no-cache" });

  const imperativelyCallQuery = async (variables: TVariables) => {
    return refetch(variables);
  };

  return imperativelyCallQuery;
};
