import { Edge } from "Constants/Edge";
import { PLAYLIST_FRAGMENT } from "GraphQL/Fragment/PlaylistFragment";
import { CurationManagement, CurationManagementWhereInput } from "GraphQL/Schema";
import { gql, useQuery } from "Lib/apollo-client";

export const LOAD_CURATION_MANAGEMENT_DETAIL = gql`
  ${PLAYLIST_FRAGMENT}
  query LOAD_CURATION_MANAGEMENT_DETAIL($where: CurationManagementWhereInput) {
    curationManagement: curation_management(where: $where) {
      id
      is_owner
      created_at
      playlist_id
      user_id
      playlist {
        ...PlaylistFragment
      }
      user {
        id
        name
        email
        company
      }
    }
  }
`;

type Result = {
  readonly curationManagement: CurationManagement[];
  readonly edge: Edge;
};

type Args = {
  readonly first?: number;
  readonly page?: number;
  readonly where?: CurationManagementWhereInput;
  readonly isForce?: boolean;
};

export const useLoadCurationManagementDetail = ({ first, page, where, isForce = false }: Args) => {
  const { data, loading } = useQuery<Result, Args>(LOAD_CURATION_MANAGEMENT_DETAIL, {
    variables: {
      first,
      page,
      where,
      isForce
    }
  });

  return { loading, data };
};
