import { NumericID } from "Utils/id";
import { immerable } from "immer";
import { TrackActivityType } from "GraphQL/Schema";
import { TrackID } from "./TrackModel";
import { Time } from "Lib/time";

export class TrackActivityID extends NumericID {
  constructor(id: number) {
    super("trackActivity", id);
  }
}

export type TrackActivityPlayload = {
  readonly id: number;
  readonly track: TrackID;
  readonly type: TrackActivityType;
  readonly createAt: string;
};

export class TrackActivityModel {
  static form({ id, type, track, createAt }: TrackActivityPlayload) {
    return new TrackActivityModel(new TrackActivityID(id), type, track, Time(createAt));
  }

  readonly [immerable] = true;

  constructor(readonly id: TrackActivityID, readonly type: TrackActivityType, readonly track: TrackID, readonly createAt: Time) {}
}
