import { useCookies } from "Lib/cookie";

const TRACK_DETAIL_INFO = "TRACK_DETAIL_INFO";

type ReturnType = {
  readonly isInfo: boolean;
  readonly set: (isInfo: boolean) => void;
  readonly clear: () => void;
};

export const useTrackInfo = (): ReturnType => {
  const [cookies, setCookie, removeCookie] = useCookies([TRACK_DETAIL_INFO]);
  const isInfo = cookies[TRACK_DETAIL_INFO] ? cookies[TRACK_DETAIL_INFO] === "true" : false;
  const set = (isInfo: boolean): void => setCookie(TRACK_DETAIL_INFO, isInfo, { path: "/" });
  const clear = (): void => removeCookie(TRACK_DETAIL_INFO, { path: "/" });

  // 기본값을 디테일로 임시설정
  const invertIsInfo = !isInfo;

  return { isInfo: invertIsInfo, set, clear };
};
