import { gql, useMutation } from "Lib/apollo-client";
import { Reference } from "@apollo/client";
import { LOAD_CART_COUNT } from "GraphQL/Queries/useLoadCartCount";
import { TrackActivityNodeStore } from "App/Store/TrackActivity";
import { TrackActivityID } from "Models/TrackActivity";
import { useLoaded } from "Lib/use-loaded";
import { LOAD_CART } from "GraphQL/Queries/useLoadCart";

const REMOVE_CART_ITEM = gql`
  mutation REMOVE_CART_ITEM($trackId: Int!) {
    removeCartItem: remove_cart_item(track_id: $trackId)
  }
`;

type Result = {
  readonly removeCartItem: number;
};

type Args = {
  readonly trackId: number;
};

export const useRemoveCartItemNoCache = () => {
  const [fetch, { loading }] = useMutation<Result, Args>(REMOVE_CART_ITEM, {
    update(cache, { data }) {
      cache.modify({
        fields: {
          track_activity(existingTrackActivityRefs = [], { readField }) {
            TrackActivityNodeStore.remove(new TrackActivityID(data?.removeCartItem!));
            const trackActivityRefs = existingTrackActivityRefs.filter(
              (trackActivityRefs: Reference) => readField("id", trackActivityRefs) !== data?.removeCartItem
            );
            return [...trackActivityRefs];
          }
        }
      });
    },
    refetchQueries: [LOAD_CART, LOAD_CART_COUNT],
    awaitRefetchQueries: true
  });
  const { isLoaded } = useLoaded(loading);

  return { removeCartItem: fetch, isLoaded };
};
