import { TrackList } from "App/Features/List/TrackList";
import { useMyPlaylistTrack } from "App/Pages/PlayListDetail/hooks/useMyPlaylistTrack";
import { usePlaylistNode } from "App/Store/PlaylistNodeStore";
import { PlaylistID } from "Models/PlaylistModel";
import styled from "styled-components";
import { useLibraryFavoritesSelector } from "../../Store";

type Props = {
  readonly id: PlaylistID;
};

export const LibraryFavoriteForm = ({ id }: Props) => {
  const playlist = usePlaylistNode(id);
  const trackList = playlist?.trackList.map(({ id }) => id) ?? [];

  const isLoadedLibraryFavorites = useLibraryFavoritesSelector(store => store.isLoadedLibraryFavorites);

  const { isLoaded: isLoadedLibraryPlaylist } = useMyPlaylistTrack({
    ids: trackList.map(({ raw }) => raw) ?? [],
    isSkip: !isLoadedLibraryFavorites || !trackList.length
  });

  const isLoaded = isLoadedLibraryFavorites && isLoadedLibraryPlaylist;

  return (
    <Layout>
      <TrackList trackList={trackList} isLoadedTrack={isLoaded} isReloadPlaylist={false} />
    </Layout>
  );
};

const Layout = styled.div`
  flex: 1;
  min-width: 0;
  overflow: hidden;
  position: relative;
`;
