import { ProductType } from "GraphQL/Schema";
import { Time } from "Lib/time";
import { NumericID } from "Utils/id";
import { AccountID } from "./AccountModel/AccountID";
import { TrackID } from "./TrackModel";

export class PurchaseHistoryID extends NumericID {
  constructor(id: number) {
    super("playlist", id);
  }
}

export type PurchaseHistoryPlayload = {
  readonly id: number;
  readonly amount: number;
  readonly count: number;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly userId: AccountID;
  readonly isCancel: boolean;
  readonly type: ProductType;
  readonly productIds: TrackID[];
};

export class PurchaseHistoryModel {
  static form({ id, amount, count, createdAt, updatedAt, type, userId, isCancel, productIds }: PurchaseHistoryPlayload) {
    return new PurchaseHistoryModel(
      new PurchaseHistoryID(id),
      amount,
      count,
      Time(createdAt),
      Time(updatedAt),
      type,
      userId,
      isCancel,
      productIds
    );
  }

  constructor(
    readonly id: PurchaseHistoryID,
    readonly amount: number,
    readonly count: number,
    readonly createAt: Time,
    readonly updateAt: Time,
    readonly type: ProductType,
    readonly userId: AccountID,
    readonly isCancel: boolean,
    readonly productIds: TrackID[]
  ) {}
}
