import { PLAYLIST_FRAGMENT } from "GraphQL/Fragment/PlaylistFragment";
import { Playlist, PlaylistType } from "GraphQL/Schema";
import { gql, useQuery } from "Lib/apollo-client";

export const LOAD_PLAYLIST_DETAIL = gql`
  ${PLAYLIST_FRAGMENT}
  query LOAD_PLAYLIST_DETAIL($id: Int, $type: [PlaylistType!], $first: Int) {
    playlist(where: { id: $id, type__in: $type }, first: $first) {
      ...PlaylistFragment
    }
  }
`;

type Result = {
  readonly playlist: Playlist[];
};

type Args = {
  readonly id?: number;
  readonly type?: PlaylistType[];
  readonly first?: number;
};

export const useLoadPlaylistDetail = ({ id, type, first }: Args) => {
  const { data, loading } = useQuery<Result, Args>(LOAD_PLAYLIST_DETAIL, {
    variables: {
      id,
      type,
      first
    }
  });
  return { loading, data };
};
