import styled from "styled-components";
import { LibraryPlaylistsForm } from "./LibraryPlaylistsForm";

export const LibraryPlaylist = () => {
  return (
    <Layout>
      <LibraryPlaylistsForm />
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
  min-height: 100vh;
  margin-top: 2rem;
`;
