import { gql, useMutation } from "Lib/apollo-client";

const REPLACE_TRACKS_IN_PLAYLIST = gql`
  mutation REPLACE_TRACKS_IN_PLAYLIST($playlistId: Int!, $trackId: [Int]!) {
    replaceTracksInPlaylist: replace_tracks_in_playlist(playlist_id: $playlistId, track_id: $trackId) {
      id
    }
  }
`;

type Result = {
  readonly replaceTracksInPlaylist: boolean;
};

type Args = {
  readonly playlistId: number;
  readonly trackId: number[];
};

export const useReplaceTracksInPlaylist = () => {
  const [fetch, { loading, data, error }] = useMutation<Result, Args>(REPLACE_TRACKS_IN_PLAYLIST);

  return { replaceTracksInPlaylist: fetch, loading, data, error };
};
