import { setMetadataTrack } from "App/Common/setMetadataTrack";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useSubclass } from "Utils/useSubclass";
import { HomeAction, HomeStore } from "../Store";
import { useMetadataTrackCacheList } from "./useMetadataTrackCacheList";
import { useMetadataTrackList } from "./useMetadataTrackList";

export const useMetadataSwitch = () => {
  const [searchParam] = useSearchParams();

  const genres = searchParam.getAll("genres");
  const moods = searchParam.getAll("moods");
  const roles = searchParam.getAll("roles");
  const artist = searchParam.get("artist") ?? undefined;
  const search = searchParam.get("search");
  const page = +(searchParam.get("page") || 1);
  const order = searchParam.get("order");
  const bpm = searchParam.get("bpm");
  const duration = searchParam.get("duration");
  const { metadataType } = useSubclass();

  const isSkip = !search && !genres.length && !moods.length && !roles.length && page === 1 && !order && !bpm && !duration && !artist;

  const { data: cacheData, loading: cacheLoading } = useMetadataTrackCacheList({ skip: !isSkip, metadataType });
  const { data, loading } = useMetadataTrackList({
    bpm,
    duration,
    genres,
    isSkip,
    moods,
    page,
    roles,
    search,
    subclass: metadataType.subClass,
    companyIds: metadataType.companyIds,
    order,
    artist
  });
  const resultData = data ? data : cacheData ? cacheData : null;

  useEffect(() => {
    if (resultData) {
      const { metadata, edge } = resultData;
      const track = setMetadataTrack(metadata);
      HomeStore.dispatch(HomeAction.setTrackList(track));
      HomeStore.dispatch(HomeAction.setEdge(edge));
    }
  }, [resultData]);

  useEffect(() => {
    HomeStore.dispatch(HomeAction.setIsLoadedTrackList(!loading && !cacheLoading));
  }, [loading, cacheLoading]);
};
