import { DEFAULT_LANGUAGE_CODE, KOREAN_LANGUAGE_CODE } from "Models/LocalizationModel";
import { useAuth } from "Utils/useAuth";
import { useAsyncEffect } from "use-async-effekt";
import { loadPayoneqInfo } from "GraphQL/Queries/loadPayoneqInfo";
import { ImageNodeStore } from "App/Store/ImageNodeStore";
import { ImageID, ImageModel } from "Models/ImageModel";
import { PayoneqModel } from "Models/PayoneqModel";
import { PayoneqNodeStore } from "App/Store/PayoneqNodeStore";
import { BuyCheckAction, BuyCheckStore } from "../Store";
import { PayoneqServiceType } from "GraphQL/Schema";
export const useLoadPayoneqInfo = () => {
  const auth = useAuth();

  useAsyncEffect(async () => {
    if (!auth.token) return;
    const { data } = await loadPayoneqInfo();
    if (data) {
      const payoneqMs = data.payoneq_information.map(({ id, name, name_en, icon, pgcode, service_type }) => {
        if (icon) {
          ImageNodeStore.set(new ImageID(icon), ImageModel.from({ id: icon, source: icon }));
        }

        const payoneqM = PayoneqModel.from({
          id,
          name: [
            { languageCode: DEFAULT_LANGUAGE_CODE, value: name_en ?? "-" },
            { languageCode: KOREAN_LANGUAGE_CODE, value: name ?? "-" }
          ],
          image: pgcode === "credit" ? null : icon ?? null,
          pgcode: pgcode!,
          isGlobal: service_type === PayoneqServiceType.Global
        });
        PayoneqNodeStore.set(payoneqM.id, payoneqM);
        return payoneqM;
      });

      const firstPayoneq = payoneqMs.find(({ isGlobal }) => !isGlobal);
      if (firstPayoneq) {
        BuyCheckStore.dispatch(BuyCheckAction.setPayoneqInformation(firstPayoneq.id));
      }

      const firstPayoneqGlobal = payoneqMs.find(({ isGlobal }) => isGlobal);
      if (firstPayoneqGlobal) {
        BuyCheckStore.dispatch(BuyCheckAction.setPayoneqGlobalInformation(payoneqMs.find(({ isGlobal }) => isGlobal)!.id));
      }
    }
  }, []);

  return;
};
