import { ReactElement, useEffect } from "react";

export const BUY_CANCEL_MESSAGE = "purchase-cancel";

export const BuyCancelRedirect = (): ReactElement => {
  useEffect(() => {
    const data = {
      service: BUY_CANCEL_MESSAGE
    };
    window.opener.postMessage(data, window.location.origin);
  }, []);
  return <></>;
};
