import { FC, Fragment, ReactElement } from "react";
import { createNodeList, isTagNode, isVariableNode, Node } from "./createNodeList";
import { createTokenList } from "./createTokenList";
import { optimizeNodeList } from "./optimizeNodeList";

export type Document = {
  readonly raw: () => string;
  readonly message: (args: JSONObject) => string;
  readonly Message: (args: Record<string, Primitive | FC>) => ReactElement;
};

export const createDocument = (source: string): Document => {
  const nodeList = optimizeNodeList(createNodeList(createTokenList(source)));

  const raw = (): string => source;
  const message = (args: JSONObject): string => {
    const format = (node: Node): string => {
      if (isVariableNode(node)) {
        return String(args[node.name] ?? "");
      }
      if (isTagNode(node)) {
        const { name, children } = node;
        return `<${name}>${children.map(format).join("")}</${name}>`;
      }
      return node.text;
    };
    return nodeList.map(format).join("");
  };
  const Message = (args: Record<string, Primitive | FC>): ReactElement => {
    const format = (node: Node, index: number): ReactElement => {
      if (isVariableNode(node)) {
        return <Fragment key={index}>{args[node.name]}</Fragment>;
      }
      if (isTagNode(node)) {
        const Component = args[node.name] as FC;
        return (
          <Component {...node.attributes} key={index}>
            {node.children.map(format)}
          </Component>
        );
      }
      return <Fragment key={index}>{node.text}</Fragment>;
    };
    return <>{nodeList.map(format)}</>;
  };

  return { raw, message, Message };
};
