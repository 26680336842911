import { setArtist } from "App/Common/setArtist";
import { useLoadArtist } from "GraphQL/Queries/useLoadArtist";
import { useLoaded } from "Lib/use-loaded";
import { useEffect } from "react";

export const useArtist = (id: string) => {
  const { data, loading } = useLoadArtist({
    where: {
      artist_id: id,
      type_artist_class: "person"
    }
  });

  const { isLoaded } = useLoaded(loading);

  useEffect(() => {
    if (data) {
      setArtist(data.artist);
    }
  }, [data]);

  return { isLoaded };
};
