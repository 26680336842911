import { setPurchaseItem } from "App/Common/setPurchaseItem";
import { BASIC_FIRST_COUNT } from "Constants/Count";
import { useLoadPurchaseItem } from "GraphQL/Queries/useLoadPurchaseItem";
import { ProductType } from "GraphQL/Schema";
import { useEffect } from "react";
import { PurchaseHistoryAction, PurchaseHistoryStore } from "../Store";

export const usePurchaseItem = () => {
  const { data, loading, fetchMore } = useLoadPurchaseItem({
    first: BASIC_FIRST_COUNT,
    where: {
      product_type: ProductType.Track
    },
    fetchPolicy: "cache-first"
  });

  useEffect(() => {
    if (data) {
      const { purchaseItem, edge } = data;
      const purchaseItemMs = setPurchaseItem(purchaseItem);
      PurchaseHistoryStore.dispatch(PurchaseHistoryAction.addPurchaseItemList(purchaseItemMs.map(({ id }) => id)));
      PurchaseHistoryStore.dispatch(PurchaseHistoryAction.setEdge(edge));
    }
  }, [data]);

  useEffect(() => {
    return () => {
      PurchaseHistoryStore.dispatch(PurchaseHistoryAction.clear());
    };
  }, []);

  useEffect(() => {
    PurchaseHistoryStore.dispatch(PurchaseHistoryAction.setIsLoadedPurchaseItem(!loading));
  }, [loading]);

  return { fetchMore };
};
