import { useIntersection } from "Lib/use-intersection-observer";
import { useRef, useState } from "react";
import styled from "styled-components";
import { useAsyncEffect } from "use-async-effekt";
import { useCurationManagement } from "../hooks/useCurationManagement";
import { useLibraryCurationSelector } from "../Store";

import { CurationHeader } from "App/Features/Header/CurationHeader";
import { CurationList } from "App/Features/List/CurationList";

export const LibraryCurationListForm = () => {
  const { fetchMore } = useCurationManagement();
  const { curationManagement, isLoadedLibraryCuration, edge } = useLibraryCurationSelector(store => store);

  const ref = useRef<HTMLDivElement>(null);
  const [observer] = useIntersection(ref);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  useAsyncEffect(async () => {
    if (!edge || isLoadingMore) return;
    const { hasNextPage, endCursor } = edge;

    if (observer && hasNextPage) {
      try {
        setIsLoadingMore(true);
        await fetchMore({
          variables: {
            where: { id__lt: +endCursor! }
          }
        });
        setIsLoadingMore(false);
      } catch (error) {
        console.log("큐레이션 리스트 가져오기 실패!");
      }
    }
  }, [observer]);

  return (
    <Layout>
      <CurationHeader />
      <CurationList curationList={curationManagement} isLoadedCuration={isLoadedLibraryCuration} isLoadedMoreCuration={!isLoadingMore} />
      <Observer ref={ref} />
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
`;

const Observer = styled.div``;
