import { gql } from "@apollo/client";
import { TRACK_FRAGMENT } from "./TrackFragment";

export const PLAYLIST_IN_PLAYLIST_ITEM_FRAGMENT = gql`
  ${TRACK_FRAGMENT}
  fragment PlaylistInPlayItemFragment on Playlist {
    id
    description
    title
    type
    updated_at
    user_id
    playlist_item {
      id
      track_id
      track {
        ...TrackFragment
      }
    }
  }
`;
