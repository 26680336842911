import { setPointTransaction } from "App/Common/setPointTransaction";
import { PointTransactionNodeStore } from "App/Store/PointTransactionNodeStore";
import { Edge } from "Constants/Edge";
import { useLoadPointTransaction } from "GraphQL/Queries/useLoadPointTransaction";
import { useLoaded } from "Lib/use-loaded";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const usePointTransaction = () => {
  const [search] = useSearchParams();
  const page = +(search.get("page") || 1);
  const keyword = search.get("keyword") || undefined;
  const { data, loading } = useLoadPointTransaction({
    page: page,
    first: 20,
    where: {
      user__some: {
        email__starts_with: keyword
      }
    },
    isForce: true
  });
  const [edge, setEdge] = useState<Edge | null>(null);
  const { isLoaded } = useLoaded(loading);

  useEffect(() => {
    PointTransactionNodeStore.clear();
    if (data) {
      const { pointTransaction, edge } = data;
      setPointTransaction(pointTransaction);
      setEdge(edge);
    }
  }, [data]);

  return { isLoaded, edge };
};
