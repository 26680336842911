import classNames from "classnames";
import { noop } from "lodash-es";
import { FC, useState } from "react";
import ReactSelect from "react-select";
import styles from "./style.module.css";

type Value = string;
type Label = string;

export type Info = {
  id: Value;
  name: Label;
  isDisabled?: boolean;
};

type Props = {
  style?: import("react-select/src/styles").Styles;
  className?: string;
  classNamePrefix?: string;
  isDisabled?: boolean;
  isMenuDisable?: boolean;
  inputValue?: string;
  value?: readonly Info[];
  defaultValue?: readonly Info[];
  placeholder?: string;
  optionList?: readonly Info[];
  indicator?: null | FC;
  autoFocus?: boolean;
  onInputChange?: (value: string) => void;
  onChange?: (option: readonly Info[]) => void;
  onBlur?: (option: readonly Info[]) => void;
};

export const TagSelect = ({
  style,
  className,
  isMenuDisable,
  defaultValue = [],
  optionList,
  indicator,
  onInputChange = noop,
  onChange = noop,
  onBlur = noop,
  ...props
}: Props) => {
  const [info, setInfo] = useState(defaultValue);
  const updateInput: ReactSelect<Info>["props"]["onInputChange"] = (nextInfo, meta) => {
    switch (meta.action) {
      case "input-change":
      case "set-value": {
        onInputChange(nextInfo);
      }
    }
  };
  const change: ReactSelect<Info>["props"]["onChange"] = nextInfo => {
    setInfo((nextInfo || []) as readonly Info[]);
    onChange((nextInfo || []) as readonly Info[]);
  };
  const blur = () => onBlur(info);
  return (
    <ReactSelect
      styles={{
        ...{
          control: base => ({ ...base, overflowY: "scroll", maxHeight: "3em" }),
          indicatorsContainer: base => ({ ...base, position: "sticky", top: 0, height: "2.25em" }),
          valueContainer: base => ({ ...base, paddingRight: "5em" }),
          menuList: base => ({ ...base, maxHeight: "16em" })
        },
        ...style
      }}
      className={classNames(styles.layout, className)}
      menuIsOpen={isMenuDisable === undefined || !isMenuDisable ? undefined : !isMenuDisable}
      components={{
        ...(indicator ? { DropdownIndicator: indicator } : isMenuDisable && { DropdownIndicator: null })
      }}
      defaultValue={defaultValue}
      isMulti
      getOptionValue={info => info.id}
      getOptionLabel={info => info.name}
      options={optionList}
      onInputChange={updateInput}
      onChange={change}
      onBlur={blur}
      {...props}
    />
  );
};
