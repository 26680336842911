import { configs } from "Config";
import { User } from "GraphQL/Schema";
import { createHttpLink, gql, setContext, ApolloClient, InMemoryCache } from "Lib/apollo-client";

const UPDATE_PASSWORD = gql`
  mutation UPDATE_EMAIL($password: String!) {
    updatePassword(password: $password) {
      id
    }
  }
`;

type Result = {
  readonly updatePassword: User;
};

export const updatePassword = async ({ token, password }: { token: string; password: string }) => {
  const httpLink = createHttpLink({
    uri: configs.urls.service
  });
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`
      }
    };
  });
  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  });
  const { data } = await client.mutate<Result>({ mutation: UPDATE_PASSWORD, variables: { password } });

  return data?.updatePassword;
};
