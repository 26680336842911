import styled from "styled-components";
import { LibraryCurationListForm } from "./LibraryCurationListForm";

export const LibraryCuration = () => {
  return (
    <Layout>
      <LibraryCurationListForm />
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
  min-height: 100vh;
  margin-top: 2rem;
`;
