import { Edge } from "Constants/Edge";
import styled from "styled-components";
import { CurationManagementContent } from "./CurationManagementContent";
import { CurationManagementFooter } from "./CurationManagementFooter";

import { CurationManagementHeader } from "./CurationManagementHeader";

type Props = {
  readonly edge: Edge | null;
};

export const Form = ({ edge }: Props) => {
  return (
    <Layout>
      <Scroll>
        <Table>
          <CurationManagementHeader />
          <CurationManagementContent />
        </Table>
      </Scroll>
      <CurationManagementFooter edge={edge} />
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
  height: 100%;
`;

const Scroll = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;
