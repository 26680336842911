import { setPlaylist } from "App/Common/setPlaylist";
import { PlaylistNodeStore } from "App/Store/PlaylistNodeStore";
import { BASIC_FIRST_COUNT } from "Constants/Count";
import { Edge } from "Constants/Edge";
import { useLoadCuration } from "GraphQL/Queries/useLoadCuration";
import { PlaylistType } from "GraphQL/Schema";
import { useLoaded } from "Lib/use-loaded";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const useCuration = () => {
  const [search] = useSearchParams();
  const page = +(search.get("page") || 1);
  const keyword = search.get("keyword") ?? undefined;
  const { data, loading } = useLoadCuration({
    where: {
      type: PlaylistType.Curation,
      title__starts_with: keyword
    },
    fetchPolicy: "no-cache",
    first: BASIC_FIRST_COUNT,
    page: page
  });

  const [edge, setEdge] = useState<Edge | null>(null);
  const { isLoaded } = useLoaded(loading);

  useEffect(() => {
    PlaylistNodeStore.clear();

    if (data) {
      const { curation, edge } = data;
      setPlaylist(curation);
      setEdge(edge);
    }
  }, [data]);

  return { isLoaded, edge };
};
