import { Edge } from "Constants/Edge";
import { EDGE_FRAGMENT } from "GraphQL/Fragment/EdgeFragment";
import { TRACK_FRAGMENT } from "GraphQL/Fragment/TrackFragment";
import { Metadata, MetadataWhereInput } from "GraphQL/Schema";
import { gql, useQuery } from "Lib/apollo-client";

export const LOAD_METADATA_TRACK = gql`
  ${EDGE_FRAGMENT}
  ${TRACK_FRAGMENT}
  query LOAD_METADATA_TRACK($where: MetadataWhereInput, $first: Int, $page: Int, $orderBy: [MetadataOrderByInput]) {
    metadata(where: $where, first: $first, page: $page, orderBy: $orderBy, group: true) {
      metadata_id
      metadata_url(where: { valid_check: V3 }) {
        url
      }
      track {
        ...TrackFragment
      }
    }
    edge(node: Metadata, where: { metadata: $where }, first: $first, page: $page) {
      ...EdgeFragment
    }
  }
`;

type Result = {
  readonly metadata: Metadata[];
  readonly edge: Edge;
};

type Args = {
  readonly where?: MetadataWhereInput;
  readonly first?: number;
  readonly page?: number;
  readonly orderBy?: string[];
  readonly skip?: boolean;
};

export const useLoadMetadataTrack = ({ where, first, page, orderBy, skip }: Args) => {
  const { data, loading } = useQuery<Result, Args>(LOAD_METADATA_TRACK, {
    variables: {
      where,
      first,
      page,
      orderBy
    },
    skip
  });

  return { loading, data };
};
