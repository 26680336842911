import { Pagination } from "App/Atomics/Pagination";
import { Edge } from "Constants/Edge";
import { useMemo } from "react";

const LIMIT = 10;

type Props = {
  edge: null | Edge;
  goTo: (index: number) => void;
};

export const EdgePagination = ({ edge, goTo }: Props) => {
  const createPageList = () => {
    if (!edge) {
      return [];
    }
    const first = edge.currentPage - ((edge.currentPage - 1) % LIMIT);
    const indexList = [first] as number[];
    while (indexList.length !== LIMIT && indexList[indexList.length - 1] < edge.pageCount) {
      indexList.push(indexList[indexList.length - 1] + 1);
    }
    return indexList;
  };
  const pageList = useMemo(createPageList, [edge]);
  if (!edge) {
    return null;
  }

  return (
    <Pagination
      start={edge.currentPage !== 1 && edge.currentPage !== 0 && 1}
      previous={edge.hasPreviousPage && edge.currentPage > LIMIT && previous(edge)}
      pageList={pageList}
      next={edge.hasNextPage && edge.pageCount - pageList[0] > LIMIT && next(edge)}
      end={edge.hasNextPage && edge.pageCount}
      isCurrent={page => page === edge.currentPage}
      goTo={goTo}
    />
  );
};

const previous = (edge: Edge): number =>
  edge.currentPage - LIMIT < 1
    ? 1
    : edge.currentPage % LIMIT === 0
    ? edge.currentPage - LIMIT
    : edge.currentPage - (edge.currentPage % LIMIT);

const next = (edge: Edge): number =>
  edge.currentPage + LIMIT - ((edge.currentPage - 1) % LIMIT) <= edge.pageCount
    ? edge.currentPage + LIMIT - ((edge.currentPage - 1) % LIMIT)
    : edge.pageCount - edge.currentPage < LIMIT
    ? edge.currentPage + LIMIT - ((edge.pageCount - 1) % LIMIT)
    : edge.pageCount;
