import { Color } from "Constants/Color";
import styled from "styled-components";
import { DESKTOP_MODE, WIDE_MODE } from "Constants/Size";
import { MenuList } from "./MenuList";

export const Sidebar = () => {
  return (
    <Layout>
      <MenuLayout>
        <MenuList />
      </MenuLayout>
    </Layout>
  );
};

const Layout = styled.div`
  background-color: ${Color.Ac_Charcoal};
  max-height: 100vh;

  overflow-y: auto;
  overflow-x: hidden;

  position: sticky;
  top: 0;

  @media ${DESKTOP_MODE} {
    width: 13.5rem;
    display: block;
  }
  @media ${WIDE_MODE} {
    width: 15.5rem;
    display: block;
  }

  display: none;
`;

const MenuLayout = styled.div`
  padding: 1.25rem 1.25rem 21rem;
`;
