import ms from 'ms.macro';
import './styles.css';
import {ReactNode} from 'react';
import {toast, ToastContent, ToastOptions, ToastPosition, TypeOptions, Flip} from 'react-toastify';
export {ToastContainer} from 'react-toastify';

type openProps = {
  children: ToastContent;
  type?: TypeOptions;
  option?: CommonOption;
};

type CommonOption = {
  timeout?: number | false;
  position?: ToastPosition;
  draggable?: boolean;
};

type infoOption = CommonOption;
type warningOption = CommonOption;
type successOption = CommonOption;
type errorOption = CommonOption;

const defaultOption = (): CommonOption => ({
  timeout: ms('1s'),
  draggable: false,
});

const open = ({children, type, option = defaultOption()}: openProps) => {
  const {timeout} = option;
  const toastOption: ToastOptions = {
    type,
    autoClose: timeout,
    position: 'top-center',
    hideProgressBar: true,
    pauseOnFocusLoss: false,
    transition: Flip,
  };
  closeAll();
  return toast(children, toastOption);
};

const close = (toastId: number | string) => toast.dismiss(toastId);
const closeAll = () => toast.dismiss();

const primary = (children: ReactNode, option?: errorOption) => open({children, option});
const info = (children: ReactNode, option?: infoOption) => open({children, type: 'info', option});
const warning = (children: ReactNode, option?: warningOption) => open({children, type: 'warning', option});
const success = (children: ReactNode, option?: successOption) => open({children, type: 'success', option});
const error = (children: ReactNode, option?: errorOption) => open({children, type: 'error', option});

export const Toast = {primary, info, warning, success, error, close, closeAll};
