import { Tree } from "App/Features/Trees/Tree";
import { GenreNodeStore, useGenreNode } from "App/Store/GenreNodeStore";
import { useCurrentLanguage } from "Lib/localization";
import { useSearch } from "Lib/use-search-query";
import { GenreID } from "Models/GenreModel/GenreModel";
import { TitleModelSort } from "Utils/sort";
import { useSubclass } from "Utils/useSubclass";

export const GenreTree = ({ search, id }: { search: string; id: GenreID }) => {
  const { query, appendQuery, setQuery, removeQuery } = useSearch();
  const tagsParmas = query.getAll(search);
  const genre = useGenreNode(id)!;
  const allTags = [genre.id.raw, ...genre.childGenreIds.map((childGenreId: GenreID) => childGenreId.raw)];
  const { sortGenreTitleAsc } = TitleModelSort();
  const languageCode = useCurrentLanguage();

  const changeParentTree = (id: string, isCheck: boolean) => {
    if (isCheck) {
      appendQuery(search, id);
    } else {
      setQuery(search, [...tagsParmas.filter(tag => !allTags.includes(tag))]);
    }
  };

  const changeChildTree = (id: string, isCheck: boolean) => {
    if (isCheck) {
      appendQuery(search, id);
    } else {
      removeQuery(search, id);
    }
  };

  const tagInTagsParmas = (tag: string) => tagsParmas.includes(tag);
  const isSelectParent = allTags.some(tagInTagsParmas);
  const { metadataType } = useSubclass();
  return (
    <Tree
      name={genre.title.text(languageCode)}
      id={genre.id.raw}
      key={genre.id.key}
      defaultOption={isSelectParent}
      isDisable={true}
      onChange={changeParentTree}
    >
      {GenreNodeStore.getList(genre.childGenreIds)
        .filter(({ metdataKind }) => metdataKind.includes(metadataType.kind))
        .sort((a, b) => sortGenreTitleAsc(a, b))
        .map(({ id }) => id)
        .map((childGenreId: GenreID) => {
          const childGenre = GenreNodeStore.get(childGenreId);
          return (
            <Tree
              name={childGenre!.title.text(languageCode)}
              id={childGenre!.id.raw}
              key={childGenre!.id.key}
              onChange={changeChildTree}
              defaultOption={!!tagsParmas.find(tag => tag === childGenre!.id.raw)}
            />
          );
        })}
    </Tree>
  );
};
