import { Edge } from "Constants/Edge";
import { EDGE_FRAGMENT } from "GraphQL/Fragment/EdgeFragment";
import { TRACK_FRAGMENT } from "GraphQL/Fragment/TrackFragment";
import { Aggregate, Track, TrackOrderByInput, TrackWhereInput } from "GraphQL/Schema";
import { gql } from "Lib/apollo-client";
import { useImperativeQuery } from "Utils/useImperativeQuery";

export const LOAD_TRACK = gql`
  ${TRACK_FRAGMENT}
  ${EDGE_FRAGMENT}
  query LOAD_TRACK($where: TrackWhereInput, $first: Int, $orderBy: [TrackOrderByInput!]) {
    track(where: $where, first: $first, orderBy: $orderBy) {
      ...TrackFragment
    }
    edge(node: Track, where: { track: $where }, first: $first, orderBy: { track: $orderBy }) {
      ...EdgeFragment
    }
  }
`;

type Result = {
  readonly track: Track[];
  readonly edge: Edge;
  readonly getCount: Aggregate;
};

type Args = {
  readonly where?: TrackWhereInput;
  readonly skip?: boolean;
  readonly first?: number;
  readonly orderBy?: TrackOrderByInput[];
};

export const useImperativeTrack = () => {
  const fetch = useImperativeQuery<Result, Args>(LOAD_TRACK);

  return { loadTrack: fetch };
};
