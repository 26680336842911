import { useRef, useEffect } from "react";

export const useInterval = (callback: () => void, delay: number) => {
  const ref = useRef<() => void>();
  ref.current = callback;

  useEffect(() => {
    const id = window.setInterval(() => ref.current!(), delay);
    return () => window.clearInterval(id);
  }, [delay]);
};
