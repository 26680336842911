import { PaymentSuccessResponse } from "GraphQL/Schema";
import { gql, useLazyQuery } from "Lib/apollo-client";

const INVALIDATE_CACHE_ALL = gql`
  query InvalidateCacheAll {
    invalidate_cache_all
  }
`;

type Result = {
  readonly pay: PaymentSuccessResponse;
};

type Args = {
  readonly token: string;
  readonly pgcode: string;
  readonly amount: number;
};

export const useInvalidateCacheAll = () => {
  const [loadInvalidateCacheAll, { data, error, loading }] = useLazyQuery<Result, Args>(INVALIDATE_CACHE_ALL);

  return { loadInvalidateCacheAll, data, error, loading };
};
