import { NumericID } from "Utils/id";

export class PayletterBillID extends NumericID {
  constructor(id: number) {
    super("payletter", id);
  }
}

export type PayletterBillPlayload = {
  readonly id: number;
  readonly isCancel: boolean;
};

export class PayletterBillModel {
  static form({ id, isCancel }: PayletterBillPlayload) {
    return new PayletterBillModel(new PayletterBillID(id), isCancel);
  }

  constructor(readonly id: PayletterBillID, readonly isCancel: boolean) {}
}
