import { Edge } from "Constants/Edge";
import { createStore } from "Lib/store";
import { TrackID } from "Models/TrackModel";

const createInitialState = () => ({
  trackList: [] as TrackID[],
  isLoadedTrackList: false,
  edge: null as Edge | null,
  count: null as number | null
});

export const {
  useSelector: useHomeSelector,
  action: HomeAction,
  ...HomeStore
} = createStore("Home", createInitialState, {
  setTrackList(state, trackList: TrackID[]) {
    state.trackList = trackList;
  },
  setEdge(state, edge: Edge) {
    state.edge = edge;
  },
  setIsLoadedTrackList(state, isLoadedTrackList: boolean) {
    state.isLoadedTrackList = isLoadedTrackList;
  },
  setCount(state, count: number) {
    state.count = count;
  }
});
