import { AdminTemplate } from "App/Templates/AdminTemplate";
import styled from "styled-components";
import { Form } from "./Form";
import { useLoadGradeDetail } from "./hooks/useLoadGradeDetail";
import { useProductPrice } from "./hooks/useProductPrice";

export const GradeEdit = () => {
  const { isLoaded: gradeDetailIsLoaded } = useLoadGradeDetail();
  const { isLoaded: productPriceIsLoaded } = useProductPrice();

  const isLoaded = gradeDetailIsLoaded && productPriceIsLoaded;

  return (
    <AdminTemplate>
      <Layout>{isLoaded && <Form />}</Layout>
    </AdminTemplate>
  );
};

const Layout = styled.div``;
