import { EventType, TransactionType } from "GraphQL/Schema";
import { Time } from "Lib/time";
import { NumericID } from "Utils/id";
import { AccountID } from "./AccountModel/AccountID";
import { PayletterBillID } from "./PayletterBillModel";
import { PurchaseHistoryID } from "./PurchaseHistoryModel";

export class PointTransactionID extends NumericID {
  constructor(id: number) {
    super("pointTransaction", id);
  }
}

export type PointTransactionPlayload = {
  readonly id: number;
  readonly amount: number;
  readonly eventType: EventType;
  readonly targetUserId: AccountID;
  readonly requestUserId: AccountID;
  readonly transactionType: TransactionType;
  readonly payletterBill: null | PayletterBillID;
  readonly purchaseHistory: null | PurchaseHistoryID;
  readonly createAt: string;
};

export class PointTransactionModel {
  static form({
    id,
    amount,
    eventType,
    targetUserId,
    requestUserId,
    transactionType,
    createAt,
    payletterBill,
    purchaseHistory
  }: PointTransactionPlayload) {
    return new PointTransactionModel(
      new PointTransactionID(id),
      amount,
      eventType,
      targetUserId,
      requestUserId,
      transactionType,
      Time(createAt),
      payletterBill,
      purchaseHistory
    );
  }

  constructor(
    readonly id: PointTransactionID,
    readonly amount: number,
    readonly eventType: EventType,
    readonly targetUserId: AccountID,
    readonly requestUserId: AccountID,
    readonly transactionType: TransactionType,
    readonly createAt: Time,
    readonly payletterBill: null | PayletterBillID,
    readonly purchaseHistory: null | PurchaseHistoryID
  ) {}
}
