import { Time } from "Lib/time";
import { NumericID } from "Utils/id";
import { AccountID } from "./AccountModel/AccountID";

export class WalletID extends NumericID {
  constructor(id: number) {
    super("wallet", id);
  }
}

export type WalletPlayload = {
  readonly id: number;
  readonly userId: AccountID;
  readonly balance: number;
  readonly eventPoint: number;
  readonly createdAt: Time;
  readonly updatedAt: Time;
};

export class WalletModel {
  static form({ id, userId, balance, eventPoint, createdAt, updatedAt }: WalletPlayload) {
    return new WalletModel(new WalletID(id), userId, balance, eventPoint, createdAt, updatedAt);
  }

  constructor(
    readonly id: WalletID,
    readonly userId: AccountID,
    readonly balance: number,
    readonly eventPoint: number,
    readonly createdAt: Time,
    readonly updatedAt: Time
  ) {}
}
