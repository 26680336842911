import { PlaylistHeader } from "App/Features/Header/PlaylistHeader";
import { useIntersection } from "Lib/use-intersection-observer";
import { useRef, useState } from "react";
import styled from "styled-components";
import { useAsyncEffect } from "use-async-effekt";
import { useMyPlaylists } from "../hooks/useMyPlaylists";

import { PlaylistType } from "GraphQL/Schema";
import { useMyPlaylistSelector } from "../Store";
import { Playlists } from "App/Features/List/PlayLists";

export const MyPlayListsForm = () => {
  const { fetchMore } = useMyPlaylists();
  const { isLoadedMyPlaylist, myPlaylists, edge } = useMyPlaylistSelector(store => store);

  const ref = useRef<HTMLDivElement>(null);
  const [observer] = useIntersection(ref);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  useAsyncEffect(async () => {
    if (!edge || isLoadingMore) return;
    const { hasNextPage, endCursor } = edge;

    if (observer && hasNextPage) {
      try {
        setIsLoadingMore(true);
        await fetchMore({
          variables: {
            where: { id__lt: +endCursor!, type__in: [PlaylistType.My, PlaylistType.Curation] }
          }
        });
        setIsLoadingMore(false);
      } catch (error) {
        console.log("플레이 리스트 가져오기 실패!");
      }
    }
  }, [observer]);

  return (
    <Layout>
      <PlaylistHeader />
      <Playlists playlists={myPlaylists} isLoadedMorePlaylist={!isLoadingMore} isLoadedPlaylist={isLoadedMyPlaylist} />
      <Observer ref={ref} />
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
`;

const Observer = styled.div``;
