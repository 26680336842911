import { useCurationManagementNode } from "App/Store/CurationManagementStore";
import { usePlaylistNode } from "App/Store/PlaylistNodeStore";
import { Color } from "Constants/Color";
import { curationDetailPath } from "Constants/Routes";
import { DESKTOP_MODE } from "Constants/Size";
import { CurationManagementID } from "Models/CurationManagementModel";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { formatDate } from "Utils/format";

type Props = {
  readonly id: CurationManagementID;
};

export const CurationItem = ({ id }: Props) => {
  const curation = useCurationManagementNode(id);
  const playlist = usePlaylistNode(curation?.playlist);

  const navigate = useNavigate();
  const moveToCurationDetail = (id: number) => {
    navigate(curationDetailPath.path({ id: id.toString() }));
  };

  if (!curation || !playlist) {
    return null;
  }

  return (
    <>
      <Layout onClick={() => moveToCurationDetail(id.raw)}>
        <CurationListNameContainer>
          <Title>{playlist.title}</Title>
        </CurationListNameContainer>
        <ModifyDateContainer>
          <Text>{formatDate(playlist.updatedAt)}</Text>
        </ModifyDateContainer>
        <MusicCountContainer>
          <Text>{playlist.count} Tracks</Text>
        </MusicCountContainer>
      </Layout>
    </>
  );
};

const Layout = styled.div`
  width: 100%;
  min-height: 80px;
  display: flex;

  border-bottom: 1px solid ${Color.Ac_Gray};
  padding: 10px 30px;
  cursor: pointer;

  &:hover {
    background-color: ${Color.Ac_Shadow};
    h3 {
      color: ${Color.Ac_Apricot};
    }
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const CurationListNameContainer = styled(Container)`
  flex: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 5rem;
`;

const MusicCountContainer = styled(Container)`
  flex: 1;
  display: none;
  @media ${DESKTOP_MODE} {
    display: flex;
  }
`;

const ModifyDateContainer = styled(Container)`
  flex: 1;
`;

const Title = styled.h3`
  font-size: 1rem;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Text = styled.p`
  font-size: 0.8rem;
  font-weight: bold;
`;
