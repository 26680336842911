import { gql, useMutation } from "Lib/apollo-client";

const SEND_CURATION = gql`
  mutation SEND_CURATION($playlistId: Int!, $userId: Int!, $priceId: Int!) {
    sendCuration: send_curation(playlist_id: $playlistId, to_user_id: $userId, price_id: $priceId)
  }
`;

type Result = {
  readonly sendCuration: boolean;
};

type Args = {
  readonly playlistId: number;
  readonly userId: number;
  readonly priceId: number;
};

export const useSendCuration = () => {
  const [fetch, { loading, data, error }] = useMutation<Result, Args>(SEND_CURATION);

  return { sendCuration: fetch, loading, data, error };
};
