import { TrackList } from "App/Features/List/TrackList";
import { useMyPlaylistTrack } from "App/Pages/PlayListDetail/hooks/useMyPlaylistTrack";
import { usePlaylistNode } from "App/Store/PlaylistNodeStore";
import { PlaylistID } from "Models/PlaylistModel";
import { useEffect } from "react";
import styled from "styled-components";
import { CurationDetailAction, CurationDetailStore } from "../../Store";

interface Props {
  readonly id: PlaylistID;
  readonly isLoadedCuration: boolean;
}
export const PlaylistTrackForm = ({ id, isLoadedCuration }: Props) => {
  const playlist = usePlaylistNode(id);
  const trackList = playlist?.trackList.map(({ id }) => id) ?? [];

  const { isLoaded: isLoadedMyPlaylist } = useMyPlaylistTrack({
    ids: trackList.map(({ raw }) => raw) ?? [],
    isSkip: !isLoadedCuration
  });

  const isLoaded = isLoadedMyPlaylist && isLoadedCuration;

  useEffect(() => {
    CurationDetailStore.dispatch(CurationDetailAction.setIsLoadedCurationDetail(isLoaded));
  }, [isLoaded]);

  return (
    <Layout>
      <TrackList trackList={trackList} isLoadedTrack={isLoaded} />
    </Layout>
  );
};

const Layout = styled.div``;
