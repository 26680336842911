import { configs } from "Config";
import { createHttpLink, gql, setContext, ApolloClient, InMemoryCache, onError, from } from "Lib/apollo-client";

const UPDATE_PASSWORD = gql`
  mutation UPDATE_PASSWORD($password: String!) {
    updatePasswordByEmailAccess(password: $password) {
      id
    }
  }
`;

type Result = {
  readonly updatePasswordByEmailAccess: boolean;
};
const ERROR_UNAUTHORIZED = "Unauthorized";

export const updatePasswordByEmailAccess = async ({
  token,
  password,
  errorCallback
}: {
  token: string;
  password: string;
  errorCallback?: () => void;
}): Promise<boolean> => {
  console.log(token);
  const httpLink = createHttpLink({
    uri: configs.urls.service
  });
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`
      }
    };
  });

  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors?.[0].message === ERROR_UNAUTHORIZED && errorCallback) {
      console.log("CLEAR");
      errorCallback();
    }
  });

  const client = new ApolloClient({
    link: from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache()
  });

  const { data } = await client.mutate<Result>({ mutation: UPDATE_PASSWORD, variables: { password } });

  return data?.updatePasswordByEmailAccess ?? false;
};
