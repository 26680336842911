import { kebabCase } from "lodash-es";

type Value = number | string;

export const createVariablesInStyleSheets = <R extends Record<string, Value>>(variables: R): R => {
  const record = {} as Record<keyof R, any>;

  const element = window.document.createElement("style");
  element.appendChild(window.document.createTextNode(":root {"));

  const mobileVariable = [] as string[];
  for (const [key, value] of Object.entries(variables)) {
    const variableKey = `--${kebabCase(key)}`;
    record[key as keyof R] = `var(${variableKey})` as any;
    if (Array.isArray(value)) {
      element.appendChild(window.document.createTextNode(`${variableKey}: ${value[0]};`));
      mobileVariable.push(`${variableKey}: ${value[1]}`);
    } else {
      element.appendChild(window.document.createTextNode(`${variableKey}: ${value};`));
    }
  }
  element.appendChild(window.document.createTextNode("}"));

  if (mobileVariable.length) {
    element.appendChild(window.document.createTextNode(`@media (min-width: 720px) {`));
    element.appendChild(window.document.createTextNode(`:root {`));
    for (const value of mobileVariable) {
      element.appendChild(window.document.createTextNode(value));
    }
    element.appendChild(window.document.createTextNode("}"));
    element.appendChild(window.document.createTextNode("}"));
  }

  document.head.append(element);

  return record;
};
