import { Button, ButtonColor } from "App/Atomics/Button";
import { Icon } from "App/Atomics/Icon";
import { GenreNodeStore } from "App/Store/GenreNodeStore";
import { ModalAction, ModalProps, ModalStore } from "App/Store/ModalStore";
import { RoleNodeStore } from "App/Store/RoleNodeStore";
import { useTrackActivityNodeStore } from "App/Store/TrackActivity";
import { Color } from "Constants/Color";
import { DownloadCsv, DownloadCsvPurchaseModal, FullDownloadCsvModalDescription } from "Constants/Locale";
import { useImperativeMetadataDetail } from "GraphQL/Queries/useImperativeMetadataDetail";
import { TrackActivityType } from "GraphQL/Schema";
import { Modal } from "Lib/modal";
import { LOADED, useLoading } from "Lib/use-loading";
import { useToggle } from "Lib/use-toggle";
import { GenreKind } from "Models/GenreModel/GenreKind";
import { GenreID } from "Models/GenreModel/GenreModel";
import { TrackItem } from "Models/PlaylistModel";
import { RoleID, RoleModel } from "Models/RoleModel";
import { TrackID } from "Models/TrackModel";
import styled from "styled-components";
import { CsvTrackInformation, useCsvTrackInformation } from "./useCsvTrackInformation";

type Props = {
  readonly props: ModalProps;
  readonly isOpen: boolean;
};

export const CsvsDownloadModal = ({ props, isOpen }: Props) => {
  const trackItems = props.trackItems as TrackItem[];
  const activity = useTrackActivityNodeStore(store => store.values()).filter(activity => activity.type === TrackActivityType.Purchase);
  const arrowTrackItemIds = trackItems.filter(({ id }) => activity.map(({ track }) => track.raw).includes(id.raw));

  const onRequestClose = () => ModalStore.dispatch(ModalAction.closeAll());
  const { downloadExcel } = useCsvTrackInformation();
  const downloadLoading = useLoading(LOADED);

  const { loadMetdataDetail } = useImperativeMetadataDetail();
  const toggle = useToggle();
  const onLoadMetdata = async (arrowTrackIds: TrackID[]) => {
    let page = 1;
    toggle.on();

    const metdata = [];

    while (true) {
      const { data } = await loadMetdataDetail({
        first: 100,
        page: page,
        where: {
          metadata_id__in: arrowTrackIds.map(({ raw }) => raw.toString())
        }
      });
      if (data.metadata.length) {
        metdata.push(...data.metadata);
      }
      if (!data.edge.hasNextPage) {
        break;
      }
      page += 1;
    }
    toggle.off();
    return metdata;
  };

  const tryToDownloadCsv = async (arrowTrackItemIds: TrackItem[]) => {
    downloadLoading.setLoading();
    const trackIds = arrowTrackItemIds.map(({ id }) => id);

    const metdatas = await onLoadMetdata(trackIds);

    const data = arrowTrackItemIds.reduce((data: CsvTrackInformation[], arrowTrackItem) => {
      const { id, description } = arrowTrackItem;

      const metadata = metdatas.find(({ metadata_id }) => metadata_id === id.raw.toString());

      const track = metadata?.track![0];

      const genreModel = GenreNodeStore.getList(track?.genre_id.map(genreId => new GenreID(genreId)) ?? []);

      const csvData: CsvTrackInformation = {
        id: id.raw,
        description,
        artistName: track?.artist_name ?? "-",
        duration: track?.duration ?? track?.duration_temporary ?? null,
        bpm: track?.bpm ?? null,
        title: track?.title ?? "-",
        genre: genreModel.filter(({ kind }) => kind === GenreKind.Genre) ?? [],
        mood: genreModel.filter(({ kind }) => kind === GenreKind.Mood) ?? [],
        role: (RoleNodeStore.getList(track?.role_id.map(roleId => new RoleID(roleId)) ?? []) ?? []) as RoleModel[]
      };
      data.push(csvData as CsvTrackInformation);
      return data;
    }, []);

    await downloadExcel(data);

    downloadLoading.setLoaded();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Layout>
        <HeaderLayout>
          <Title>
            <DownloadCsv />
          </Title>
          <CrossIcon name="close" onClick={onRequestClose}></CrossIcon>
        </HeaderLayout>
        <Desciprtion>
          <DownloadCsvPurchaseModal />
        </Desciprtion>
        <Desciprtion>
          <FullDownloadCsvModalDescription />
        </Desciprtion>

        {!!arrowTrackItemIds.length ? (
          <ButtonGroup>
            <ButtonContainer>
              <Text>CSV</Text>
              {downloadLoading.isLoaded && (
                <PlayButton color={ButtonColor.Primary} onClick={() => tryToDownloadCsv(arrowTrackItemIds)}>
                  <ButtonText>
                    <DownloadCsv />
                  </ButtonText>
                </PlayButton>
              )}
              {downloadLoading.isLoading && (
                <PlayButton color={ButtonColor.Disable}>
                  <ButtonText>
                    <Loading name="loading" />
                  </ButtonText>
                </PlayButton>
              )}
            </ButtonContainer>
          </ButtonGroup>
        ) : (
          <></>
        )}
      </Layout>
    </Modal>
  );
};

const Layout = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;
  background-color: ${Color.Ac_Charcoal};
  padding: 30px;
  overflow: hidden;
  pointer-events: auto;

  display: flex;
  flex-direction: column;
`;

const HeaderLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  font-size: 1.5rem;
`;

const CrossIcon = styled(Icon)`
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
`;

const Desciprtion = styled.p`
  font-size: 0.8rem;
  font-weight: bold;
  white-space: normal;
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem;
`;

const PlayButton = styled(Button)`
  height: 35px;
  border-radius: 1rem;

  border: none;
`;
const ButtonText = styled.span`
  font-size: 0.75rem;

  line-height: 1;
  font-weight: bold;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 0;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
`;

const Text = styled.div`
  font-weight: bold;
  font-size: 0.9rem;
`;

const Loading = styled(Icon)`
  margin: 0 6px;
  width: 54.7px;
  height: 20px;
`;
