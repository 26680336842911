import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { Color } from "Constants/Color";
import { Curation, FavoriteTrack, Playlist, PurchasedTrack, PurchaseHistory } from "Constants/Locale";
import { LIBRARY_CURATION, LIBRARY_FAVORITES, LIBRARY_PLAYLIST, LIBRARY_PURCHASE_TRACK, LIBRARY_PURCHASE_HISTORY } from "Constants/Routes";
import { createRef, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export const MenuList = () => {
  const menuListRef = useRef<HTMLDivElement>(null);
  const menuList = [
    {
      title: PurchaseHistory.message(),
      link: LIBRARY_PURCHASE_HISTORY,
      ref: createRef<HTMLDivElement>()
    },
    {
      title: Playlist.message(),
      link: LIBRARY_PLAYLIST,
      ref: createRef<HTMLDivElement>()
    },
    {
      title: Curation.message(),
      link: LIBRARY_CURATION,
      ref: createRef<HTMLDivElement>()
    },
    {
      title: PurchasedTrack.message(),
      link: LIBRARY_PURCHASE_TRACK,
      ref: createRef<HTMLDivElement>()
    },
    {
      title: FavoriteTrack.message(),
      link: LIBRARY_FAVORITES,
      ref: createRef<HTMLDivElement>()
    }
  ];
  const currentPathName = window.location.pathname;

  useEffect(() => {
    if (!menuListRef.current) {
      console.log("부모 또는 ");
      return;
    }

    const currentMenu = menuList.find(({ link }) => currentPathName.includes(link));

    if (!currentMenu?.ref.current) {
      console.log("자식이 없습니다.");
      return;
    }

    menuListRef.current.scrollLeft =
      currentMenu.ref.current.offsetLeft - (menuListRef.current.clientWidth / 2 - currentMenu.ref.current.clientWidth);
  }, [currentPathName]);

  const navigate = useNavigate();

  const moveToLink = (link: string) => {
    navigate(link);
  };

  return (
    <Layout ref={menuListRef}>
      <MenuLayout>
        {menuList.map(({ link, ref, title }) => (
          <Menu ref={ref} isSelected={currentPathName.includes(link)} onClick={() => moveToLink(link)}>
            <Typography weight={TypoWeight.Bold} size={TypoSize.F18}>
              {title}
            </Typography>
          </Menu>
        ))}
      </MenuLayout>
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
  height: 40px;
  overflow-y: hidden;
  overflow-x: auto;
`;

const MenuLayout = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
`;

const Menu = styled.div<{ isSelected: boolean }>`
  &:first-child {
    margin-right: 1rem;
  }

  &:not(:first-child) {
    margin: 0 1rem;
  }
  span {
    color: ${props => (props.isSelected ? `${Color.Ac_White}` : `${Color.Ac_Gray1}`)};
  }
  cursor: pointer;
  position: relative;

  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 2px;
    bottom: -5px;
    width: ${props => (props.isSelected ? `90%` : `0`)};
    background: ${Color.AC_Carrot};
    transition: all 0.25s;
  }

  &:hover {
    span {
      color: ${Color.Ac_White};
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      height: 2px;
      bottom: -5px;
      width: 90%;
      background: ${Color.AC_Carrot};
      transition: all 0.25s;
    }
  }
`;
