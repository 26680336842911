enum Tier {
  Admin = "ADMIN",
  Staff = "STAFF",
  Normal = "NORMAL"
}

export abstract class AccountTierModel {
  static from(tier?: string): AccountTierModel {
    switch (tier?.toUpperCase()) {
      case Tier.Admin: {
        return new AdminAccountTierModel();
      }

      case Tier.Staff: {
        return new StaffAccountTierModel();
      }
    }
    return new NormalAccountTierModel();
  }

  abstract readonly displayName: string;
}

export class AdminAccountTierModel extends AccountTierModel {
  readonly displayName = "ADMIN";

  toString(): string {
    return Tier.Admin;
  }
}

export class NormalAccountTierModel extends AccountTierModel {
  readonly displayName = "NORMAL";

  toString(): string {
    return Tier.Normal;
  }
}

export class StaffAccountTierModel extends AccountTierModel {
  readonly displayName = "STAFF";

  toString(): string {
    return Tier.Staff;
  }
}

export const isNormalAccountTier = (tier: AccountTierModel): tier is NormalAccountTierModel => tier instanceof NormalAccountTierModel;

export const isAdminAccountTier = (tier: AccountTierModel): tier is AdminAccountTierModel => tier instanceof AdminAccountTierModel;

export const isStaffAccountTier = (tier: AccountTierModel): tier is StaffAccountTierModel => tier instanceof StaffAccountTierModel;
