import { Lottie } from "Lib/lottie";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { usePurchaseItemDetail } from "./hooks/usePurchaseItemDetail";
import { PurchaseItems } from "./PurchaseItems";
import LoadingLottie from "Assets/Lottie/loading.json";

export const PurchaseHistoryDetail = () => {
  const { id } = useParams();
  const { isLoaded } = usePurchaseItemDetail(+id!);
  return (
    <Layout>
      {isLoaded && <PurchaseItems />}
      {!isLoaded && (
        <LoadingContainer>
          <Lottie data={LoadingLottie} loop playing />
        </LoadingContainer>
      )}
    </Layout>
  );
};

const Layout = styled.div``;
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 150px;
`;
