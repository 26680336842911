import { gql, useMutation } from "Lib/apollo-client";

const WITHDRAWAL_POINT_BY_ADMIN = gql`
  mutation WITHDRAWAL_POINT_BY_ADMIN($targetUserId: Int!, $amount: Int!) {
    withdrawalPointByAdmin: withdrawal_point_by_admin(target_user_id: $targetUserId, amount: $amount)
  }
`;

type Result = {
  readonly withdrawalPointByAdmin: boolean;
};

type Args = {
  readonly targetUserId: number;
  readonly amount: number;
};

export const useWithdrawalPoint = () => {
  const [fetch, { loading, data, error }] = useMutation<Result, Args>(WITHDRAWAL_POINT_BY_ADMIN);

  return { withdrawalPointByAdmin: fetch, loading, data, error };
};
