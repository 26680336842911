import {Cookies} from 'react-cookie';

type PrimitiveType = null | boolean | number | string | {[index: string]: PrimitiveType} | PrimitiveType[];

const cookie = new Cookies();

export const Cookie = {
  set(key: string, value: PrimitiveType): void {
    cookie.set(key, JSON.stringify(value));
  },
  get(key: string): null | string {
    return cookie.get(key) ?? null;
  },
  has(key: string): boolean {
    return (cookie.get(key) ?? null) !== null;
  },
  remove(key: string): void {
    cookie.remove(key);
  },
};
