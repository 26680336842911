import { TrackInformationContentLoader } from "App/Features/ContentLoaders/TrackInformationContentLoader";
import { useTrackActivity } from "App/hooks/useTrackActivity";
import { TrackID } from "Models/TrackModel";
import styled from "styled-components";
import { TrackInformation } from "./TrackInformation";

interface Props {
  readonly isLoadedTrack: boolean;
  readonly id: TrackID;
}

export const TrackInformationForm = ({ isLoadedTrack, id }: Props) => {
  const { isLoaded: isLoadedTrackActivity } = useTrackActivity({
    trackList: [id],
    isLoadedTrack: isLoadedTrack
  });

  const isLoaded = isLoadedTrackActivity && isLoadedTrack;

  return <Layout>{isLoaded ? <TrackInformation id={id} /> : <TrackInformationContentLoader />}</Layout>;
};

const Layout = styled.div``;
