import { gql, useMutation } from "Lib/apollo-client";
import { Reference } from "@apollo/client";
import { LOAD_CART_COUNT } from "GraphQL/Queries/useLoadCartCount";
import { TrackActivityNodeStore } from "App/Store/TrackActivity";
import { TrackActivityID } from "Models/TrackActivity";
import { useLoaded } from "Lib/use-loaded";
import { Aggregate } from "GraphQL/Schema";

const REMOVE_CART_ITEM = gql`
  mutation REMOVE_CART_ITEM($trackId: Int!) {
    removeCartItem: remove_cart_item(track_id: $trackId)
  }
`;

type Result = {
  readonly removeCartItem: number;
};

type Args = {
  readonly trackId: number;
};

type Props = {
  cartId?: number;
};

type CartCountResult = {
  readonly cartCount: Aggregate;
};

export const useRemoveCartItem = ({ cartId }: Props) => {
  const [fetch, { loading }] = useMutation<Result, Args>(REMOVE_CART_ITEM, {
    update(cache, { data }) {
      cache.modify({
        fields: {
          track_activity(existingTrackActivityRefs = [], { readField }) {
            TrackActivityNodeStore.remove(new TrackActivityID(data?.removeCartItem!));
            const trackActivityRefs = existingTrackActivityRefs.filter(
              (trackActivityRefs: Reference) => readField("id", trackActivityRefs) !== data?.removeCartItem
            );
            return [...trackActivityRefs];
          },
          getCount(existingGetCount) {
            const cartCount = cache.readQuery<CartCountResult>({ query: LOAD_CART_COUNT });

            if (existingGetCount.count === cartCount?.cartCount.count) {
              cache.updateQuery<CartCountResult>({ query: LOAD_CART_COUNT }, data => {
                return { cartCount: { ...data?.cartCount, count: data?.cartCount.count! - 1 } };
              });
            }

            return existingGetCount;
          },
          cart(existingTrackActivityRefs = []) {
            if (cartId) {
              const normalizedPlaylistItemId = cache.identify({ id: cartId, __typename: "CartItem" });
              cache.evict({ id: normalizedPlaylistItemId });
              cache.gc();
              return existingTrackActivityRefs;
            }
          }
        }
      });
    }
  });
  const { isLoaded } = useLoaded(loading);

  return { removeCartItem: fetch, isLoaded };
};
