import { LOAD_CART } from "GraphQL/Queries/useLoadCart";
import { LOAD_CART_COUNT } from "GraphQL/Queries/useLoadCartCount";
import { LOAD_TRACK_ACTIVITY } from "GraphQL/Queries/useLoadTrackActivity";
import { LOAD_USER_WALLET } from "GraphQL/Queries/useLoadUserWallet";
import { PurchaseHistory } from "GraphQL/Schema";
import { gql, useMutation } from "Lib/apollo-client";

const PURCHASE_ALL_CART_ITEMS = gql`
  mutation PURCHASE_ALL_CART_ITEMS {
    purchaseAllCartItems: purchase_all_cart_items {
      id
      item_total_count
      user_id
      item_total_amount
      created_at
      updated_at
      purchase_item {
        track {
          id
        }
        id
        product_id
      }
    }
  }
`;

type Result = {
  readonly purchaseAllCartItems: PurchaseHistory;
};

export const usePurchaseAllCartItems = () => {
  const [fetch, { loading, data, error }] = useMutation<Result>(PURCHASE_ALL_CART_ITEMS, {
    update(cache) {
      cache.modify({
        fields: {
          track_activity(details) {
            return details.DELETE;
          },
          point_transaction(details) {
            return details.DELETE;
          },
          purchase_item(details) {
            return details.DELETE;
          }
        }
      });
    },
    awaitRefetchQueries: true,
    refetchQueries: [LOAD_CART, LOAD_USER_WALLET, LOAD_TRACK_ACTIVITY, LOAD_CART_COUNT]
  });

  return { purchaseAllCartItems: fetch, loading, data, error };
};
