import { Input } from "App/Atomics/Input";
import { GenreTree } from "App/Features/Trees/GenreTree";
import { GenreNodeStore, useGenreNodeStore } from "App/Store/GenreNodeStore";
import { Color } from "Constants/Color";
import { UppercaseGenre } from "Constants/Locale";
import { Size } from "Constants/Size";
import { useCurrentLanguage } from "Lib/localization";
import { GenreKind } from "Models/GenreModel/GenreKind";
import { useState } from "react";
import styled from "styled-components";
import { getRegExp } from "Utils/koreanRegexp";
import { TitleModelSort } from "Utils/sort";
import { useSubclass } from "Utils/useSubclass";
import { MenuItem } from "../MenuItem";

export const GenreMenu = () => {
  const { sortGenreTitleAsc } = TitleModelSort();
  const [search, setSearch] = useState<string>("");

  const languageCode = useCurrentLanguage();
  const { metadataType } = useSubclass();

  const genreList = useGenreNodeStore(store => store.values())
    .filter(({ weight, kind, title, childGenreIds, metdataKind }) => {
      return (
        kind === GenreKind.Genre &&
        metdataKind.includes(metadataType.kind) &&
        weight === 1 &&
        (RegExp(getRegExp(search)).test(title.text(languageCode)) ||
          GenreNodeStore.getList(childGenreIds).find(({ title }) => RegExp(getRegExp(search)).test(title.text(languageCode))))
      );
    })
    .sort((a, b) => sortGenreTitleAsc(a, b))
    .map(({ id }) => id);

  return (
    <MenuItem name={UppercaseGenre.message()}>
      <SearchLayout>
        <Search placeholder="Search..." onChange={setSearch}></Search>
      </SearchLayout>
      {genreList.map(id => (
        <GenreTree key={id.key} search="genres" id={id} />
      ))}
    </MenuItem>
  );
};

const SearchLayout = styled.div``;

const Search = styled(Input.Text)`
  width: 100%;
  border: 2.25px solid ${Color.GRAY_8};
  background-color: ${Color.GRAY_8};
  border-radius: 4px;
  padding: 0.5rem ${Size.PADDING_LARGE_PX};
  font-size: 0.75rem;
  transition: all 0.3s ease-in;
  color: ${Color.Ac_Gray4}!important;
  margin-right: 4px;
  margin-bottom: 0.5rem;
  &:hover {
    border-color: ${Color.GRAY_7};
  }

  input {
    color: ${Color.Ac_Gray4};
  }

  &::placeholder {
    color: ${Color.GRAY_4};
  }
  &:focus {
    border-color: ${Color.AC_Carrot};
    background-color: ${Color.Ac_Black};

    &::placeholder {
      color: ${Color.Ac_Black};
    }
  }
  &:disabled {
    cursor: not-allowed;
  }
`;
