import { ProductType } from "GraphQL/Schema";
import { Time } from "Lib/time";
import { NumericID } from "Utils/id";
import { PurchaseHistoryID } from "./PurchaseHistoryModel";
import { TrackID } from "./TrackModel";

export class PurchaseItemID extends NumericID {
  constructor(id: number) {
    super("purchaseItem", id);
  }
}

export type PurchaseItemPlayload = {
  readonly id: number;
  readonly purchaseHistoryId: PurchaseHistoryID;
  readonly type: ProductType;
  readonly price: number;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly track: TrackID;
};

export class PurchaseItemModel {
  static form({ id, purchaseHistoryId, type, price, createdAt, updatedAt, track }: PurchaseItemPlayload) {
    return new PurchaseItemModel(new PurchaseItemID(id), purchaseHistoryId, type, price, Time(createdAt), Time(updatedAt), track);
  }

  constructor(
    readonly id: PurchaseItemID,
    readonly purchaseHistoryId: PurchaseHistoryID,
    readonly type: ProductType,
    readonly price: number,
    readonly createAt: Time,
    readonly updateAt: Time,
    readonly track: TrackID
  ) {}
}
