import { PlaylistNodeStore } from "App/Store/PlaylistNodeStore";
import { Playlist } from "GraphQL/Schema";
import { AccountID } from "Models/AccountModel/AccountID";
import { PlaylistID, PlaylistModel } from "Models/PlaylistModel";
import { TrackID } from "Models/TrackModel";
import { setTrack } from "./setTrack";
import { PlaylistItemID } from "Models/PlaylistItemModel";

export const setPlaylistDetail = (playlists: Playlist[]) => {
  for (const playlist of playlists) {
    for (const playItem of playlist.playlist_item!) {
      const track = playItem!.track!;
      if (track) setTrack([track]);
    }
    const playlistM = PlaylistModel.form({
      id: playlist.id,
      type: playlist.type,
      title: playlist.title,
      count: playlist.playlist_item?.length ?? 0,
      description: playlist.description ?? "",
      trackList:
        playlist.playlist_item!.map(track => ({
          id: new TrackID(track!.track_id),
          description: track?.description ?? "",
          item_id: new PlaylistItemID(track!.id)
        })) ?? [],
      updatedAt: playlist.updated_at,
      userId: new AccountID(playlist.user_id)
    });
    PlaylistNodeStore.set(new PlaylistID(playlist.id), playlistM);
  }
};
