import { gql, useQuery } from "@apollo/client";
import { Grade, GradeWhereInput } from "GraphQL/Schema";

export const LOAD_GRADE = gql`
  query LOAD_GRADE($where: GradeWhereInput) {
    grade(where: $where) {
      id
      grade
      created_at
      updated_at
      product_price_id
      product_price {
        id
        price
        title
        description
        created_at
        is_approve
        type
        updated_at
      }
    }
  }
`;

type Result = {
  readonly grade: Grade[];
};

type Args = {
  readonly where?: GradeWhereInput;
};

export const useLoadGrade = ({ where }: Args) => {
  const { data, loading } = useQuery<Result, Args>(LOAD_GRADE, {
    variables: {
      where
    }
  });
  return { loading, data };
};
