import { Icon } from "App/Atomics/Icon";
import { PlayListItem } from "App/Features/Items/PlayListItem";
import { NoPlaylist } from "Constants/Locale";
import { Lottie } from "Lib/lottie";
import styled from "styled-components";
import LoadingLottie from "Assets/Lottie/loading.json";
import { PlaylistID } from "Models/PlaylistModel";

interface Props {
  readonly isLoadedPlaylist?: boolean;
  readonly isLoadedMorePlaylist?: boolean;
  readonly playlists: PlaylistID[];
}

export const Playlists = ({ isLoadedPlaylist = true, isLoadedMorePlaylist = true, playlists }: Props) => {
  return (
    <>
      {!isLoadedPlaylist && (
        <LoadingContainer>
          <Lottie data={LoadingLottie} loop playing />
        </LoadingContainer>
      )}
      {isLoadedPlaylist && !playlists.length && (
        <NotFoundContainer>
          <NotFoundIcon name="search" />
          <NotFound>
            <NoPlaylist />
          </NotFound>
        </NotFoundContainer>
      )}
      {isLoadedPlaylist && playlists.map(playlist => <PlayListItem key={playlist.key} id={playlist} />)}
      {!isLoadedMorePlaylist && (
        <LoadingContainer>
          <Lottie data={LoadingLottie} loop playing />
        </LoadingContainer>
      )}
    </>
  );
};

const NotFoundContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 10rem 0;
`;

const NotFound = styled.span`
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 1rem;
`;

const NotFoundIcon = styled(Icon)`
  width: 2.5em;
  height: 2.5rem;
`;
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 150px;
`;
