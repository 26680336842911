import { PlaylistItem } from "GraphQL/Schema";
import { gql, useMutation } from "Lib/apollo-client";

const UPDATE_PLAYLIST_ITEM = gql`
  mutation ($playlistItemId: Int!, $description: String) {
    update_playlist_item(input: { playlist_item_id: $playlistItemId, description: $description }) {
      id
      playlist_id
      description
    }
  }
`;

type Result = {
  readonly updatePlaylist: PlaylistItem;
};

type Args = {
  readonly playlistItemId: number;
  readonly description?: string;
};

export const useUpdatePlaylistItem = () => {
  const [fetch, { loading, data, error }] = useMutation<Result, Args>(UPDATE_PLAYLIST_ITEM);

  return { updatePlaylistItem: fetch, loading, data, error };
};
