import { gql, useMutation } from "Lib/apollo-client";

const DELETE_ACCOUNT = gql`
  mutation DELETE_ACCOUNT($password: String!) {
    deleteAccount(password: $password)
  }
`;

type Result = {
  readonly deleteAccount: boolean;
};

type Args = {
  readonly password: string;
};

export const useDeleteAccount = () => {
  const [fetch, data] = useMutation<Result, Args>(DELETE_ACCOUNT);
  return { fetch, data };
};
