import { Edge } from "Constants/Edge";
import { EDGE_FRAGMENT } from "GraphQL/Fragment/EdgeFragment";
import { USER_FRAGMENT } from "GraphQL/Fragment/UserFragment";
import { PointTransaction, PointTransactionWhereInput } from "GraphQL/Schema";
import { gql, useQuery } from "Lib/apollo-client";

export const LOAD_POINT_TRANSACTION = gql`
  ${EDGE_FRAGMENT}
  ${USER_FRAGMENT}
  query LOAD_POINT_TRANSACTION($first: Int, $page: Int, $where: PointTransactionWhereInput, $isForce: Boolean) {
    pointTransaction: point_transaction(where: $where, first: $first, page: $page, orderBy: [id__DESC], is_force: $isForce) {
      id
      target_user_id
      request_user_id
      amount
      event_type
      transaction_type
      created_at
      updated_at
      payletter_bill {
        id
        is_cancel
      }
      purchase_history {
        id
        is_cancel
      }
      requester {
        ...UserFragment
      }
      user {
        ...UserFragment
      }
    }
    edge(is_force: $isForce, node: PointTransaction, where: { point_transaction: $where }, first: $first, page: $page) {
      ...EdgeFragment
    }
  }
`;

type Result = {
  readonly pointTransaction: PointTransaction[];
  readonly edge: Edge;
};

type Args = {
  readonly first?: number;
  readonly page?: number;
  readonly where?: PointTransactionWhereInput;
  readonly isForce?: boolean;
};

export const useLoadPointTransaction = ({ first, page, where, isForce = false }: Args) => {
  const { data, loading } = useQuery<Result, Args>(LOAD_POINT_TRANSACTION, {
    variables: {
      first,
      page,
      where,
      isForce
    }
  });

  return { loading, data };
};
