import { Edge } from "Constants/Edge";
import styled from "styled-components";
import { LicenseContent } from "./LicenseContent";
import { LicenseFooter } from "./LicenseFooter";
import { LicenseHeader } from "./LicenseHeader";

type Props = {
  readonly edge: Edge | null;
};

export const Form = ({ edge }: Props) => {
  return (
    <Layout>
      <Scroll>
        <Table>
          <LicenseHeader />
          <LicenseContent />
        </Table>
      </Scroll>

      <LicenseFooter edge={edge} />
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
  height: 100%;
`;

const Scroll = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;
