import { Button } from "App/Atomics/Button";
import { Icon } from "App/Atomics/Icon";
import { SelectTagContentLoader } from "App/Features/ContentLoaders/SelectTagContentLoader";
import { useLoadingSelector } from "App/Store/LoadingStore";
import { Color } from "Constants/Color";
import { useSearch } from "Lib/use-search-query";
import styled from "styled-components";
import { SwitchTag } from "./SwitchTag";

type Props = {
  readonly id: string;
  readonly type: string;
};

export const SelectTagItem = ({ id, type }: Props) => {
  const { removeQuery } = useSearch();

  const isLoadedTag = useLoadingSelector(store => store.isLoadedCache);

  if (!isLoadedTag) {
    return <SelectTagContentLoader />;
  }

  return (
    <Layout>
      <ButtonText>
        <SwitchTag id={id} type={type} />
      </ButtonText>
      <SelectCloseIcon name="close" onClick={() => removeQuery(type, id)} />
    </Layout>
  );
};

const SimpeButton = styled(Button)`
  height: 30px;
  margin: 0.3rem;
  border-radius: 2rem;
  color: ${Color.Ac_Gray1}!important;
  &:hover {
    border: 2px solid ${Color.Ac_White}!important;
    color: ${Color.Ac_White}!important;

    path {
      fill: ${Color.Ac_White};
    }
  }
`;

const Layout = styled(SimpeButton)`
  padding: 0 0.8rem;
  padding-right: 0.5rem;
`;

const ButtonText = styled.span`
  font-size: 0.75rem;
  margin: 0 0.5rem;
  line-height: 1;
  font-weight: bold;
`;

const SelectCloseIcon = styled(Icon)`
  width: 0.9rem;
  path {
    fill: ${Color.Ac_Gray1};
  }
`;
