import { GraphqlType } from "Constants/GraphqlType";
import { Role, RoleWhereInput } from "GraphQL/Schema";
import { gql, useQuery } from "Lib/apollo-client";

const LOAD_ROLE = gql`
  query LOAD_CACHE_ROLE($first: Int, $where: RoleWhereInput) {
    role(where: $where, first: $first, group: true) {
      role_id
      role_title(where: { type_role_title: "name" }, first: $first, orderBy: exposure_order__ASC) {
        language_code
        value
      }
    }
  }
`;

type Result = {
  readonly role: Role[];
};

type Args = {
  readonly first?: number;
  readonly where?: RoleWhereInput;
};

export const useLoadRole = ({ first, where }: Args) => {
  const { data, loading } = useQuery<Result, Args>(LOAD_ROLE, {
    variables: {
      first,
      where
    },
    context: {
      serviceName: GraphqlType.Cache
    }
  });

  return { loading, data };
};
