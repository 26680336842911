import { NumericID } from "Utils/id";
import { immerable } from "immer";
import { ProductPriceID } from "./ProductPriceModel";
import { Time } from "Lib/time";
import { TrackGrade } from "GraphQL/Schema";

export class GradeID extends NumericID {
  constructor(id: number) {
    super("grade", id);
  }
}

export type GradePlayload = {
  readonly id: number;
  readonly grade: TrackGrade;
  readonly productPrice: ProductPriceID;
  readonly createdAt: string;
  readonly updatedAt: string;
};

export class GradeModel {
  static form({ id, grade, productPrice, createdAt, updatedAt }: GradePlayload) {
    return new GradeModel(new GradeID(id), grade, productPrice, Time(createdAt), Time(updatedAt));
  }

  constructor(
    readonly id: GradeID,
    readonly grade: TrackGrade,
    readonly productPrice: ProductPriceID,
    readonly createdAt: Time,
    readonly updatedAt: Time
  ) {}
  readonly [immerable] = true;
}
