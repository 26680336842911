import { TryToAgain } from "Constants/Locale";
import { gql, useMutation } from "Lib/apollo-client";
import { Toast } from "Lib/toast";
import { useEffect } from "react";

const REFUND_CURATION = gql`
  mutation REFUND_CURATION($playlistId: Int!) {
    refundCuration: refund_curation(playlist_id: $playlistId)
  }
`;

type Result = {
  readonly refundCuration: boolean;
};

type Args = {
  readonly playlistId: number;
};

export const useRefundCuration = () => {
  const [fetch, { loading, data, error }] = useMutation<Result, Args>(REFUND_CURATION);

  useEffect(() => {
    if (error) {
      Toast.error(TryToAgain.message());
    }
  }, [error]);

  return { refundCuration: fetch, loading, data };
};
