import { configs } from "Config";
import { createHttpLink, gql, setContext, ApolloClient, InMemoryCache } from "Lib/apollo-client";

const UPDATE_EMAIL = gql`
  mutation UPDATE_EMAIL($email: String!) {
    updateEmail(email: $email) {
      id
    }
  }
`;

type Result = {
  readonly updateEmail: boolean;
};

export const updateEmail = async ({ token, email }: { token: string; email: string }): Promise<boolean> => {
  const httpLink = createHttpLink({
    uri: configs.urls.service
  });
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`
      }
    };
  });
  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  });
  const { data } = await client.mutate<Result>({ mutation: UPDATE_EMAIL, variables: { email } });

  return data?.updateEmail ?? false;
};
