import { Typography, TypoWeight } from "App/Atomics/Typography";
import { AdminTemplate } from "App/Templates/AdminTemplate";
import { Color } from "Constants/Color";
import styled from "styled-components";

export const Home = () => {
  return (
    <AdminTemplate>
      <Layout>
        <Typography weight={TypoWeight.Bold} color={Color.AC_Carrot}>
          아티스츠카드 BGM 관리자 페이지 입니다
        </Typography>
      </Layout>
    </AdminTemplate>
  );
};

const Layout = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;
