import { ArtistNodeStore } from "App/Store/ArtistNodeStore";
import { Artist } from "GraphQL/Schema";
import { ArtistID, ArtistModel } from "Models/ArtistModel";
import { RoleID } from "Models/RoleModel";

export const setArtist = (artistList: Artist[]) => {
  for (const artist of artistList) {
    const roleList = artist.metadata_artist_relation?.map(role => role.role).flat() ?? [];

    const artistM = ArtistModel.form({
      id: +artist.artist_id!,
      name: artist.name!,
      roleList: roleList.map(role => new RoleID(role!.role_id))
    });

    ArtistNodeStore.set(new ArtistID(+artist.artist_id!), artistM);
  }
};
