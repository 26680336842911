import { NumericID } from "Utils/id";
import { ImageID } from "Models/ImageModel";
import { LocalizationModel, LocalizationPayload } from "Models/LocalizationModel";

export class PayoneqID extends NumericID {
  constructor(id: number) {
    super("Payoneq", id);
  }
}

export type PayoneqPayload = {
  readonly id: number;
  readonly name: LocalizationPayload;
  readonly image: string | null;
  readonly pgcode: string;
  readonly isGlobal: boolean;
};

export class PayoneqModel {
  static from({ id, name, image, pgcode, isGlobal }: PayoneqPayload): PayoneqModel {
    return new PayoneqModel(new PayoneqID(id), LocalizationModel.from(name), image ? new ImageID(image) : null, pgcode, isGlobal);
  }

  constructor(
    readonly id: PayoneqID,
    readonly title: LocalizationModel,
    readonly image: ImageID | null,
    readonly pgcode: string,
    readonly isGlobal: boolean
  ) {}
}

export const PayoneqEx = PayoneqModel.from({
  id: 123,
  name: [
    { value: "paypal", languageCode: "ko" },
    { value: "paypal", languageCode: "en" }
  ],
  image: null,
  pgcode: "123",
  isGlobal: true
});
