import { ImageNodeStore } from "App/Store/ImageNodeStore";
import { random } from "Lib/random";
import { StringID } from "Utils/id";

export class ImageID extends StringID {
  constructor(raw: string) {
    super("image", raw);
  }
}

export enum ImageFormat {
  Png = "png",
  WebP = "webp",
  Default = Png
}

export type ImagePayload = {
  readonly id: string;
  readonly source: string;
  readonly format?: ImageFormat;
  readonly size?: null | string;
  readonly description?: string;
  readonly isOriginal?: boolean;
};

export type LocalImagePayload = {
  readonly source: LocalImageSource;
  readonly format: ImageFormat;
};

export type RemoteImagePayload = {
  readonly source: string;
  readonly format: ImageFormat;
  readonly size?: null | string;
  readonly description?: string;
  readonly isOriginal?: boolean;
};

export class ImageModel {
  static from({ id, source, format = ImageFormat.Default, size = null, isOriginal, description }: ImagePayload): ImageModel {
    return new ImageModel(new ImageID(id), source, format, size, isOriginal, description);
  }

  static local({ source, format }: LocalImagePayload): ImageID {
    const id = new ImageID(random().toString());
    const image = new ImageModel(id, source, format, null, true);
    ImageNodeStore.set(id, image);
    return id;
  }

  static remote({ source, format, size = null, isOriginal, description }: RemoteImagePayload): ImageID {
    const id = new ImageID(random().toString());
    const image = new ImageModel(id, source, format, size, isOriginal, description);
    ImageNodeStore.set(id, image);
    return id;
  }

  constructor(
    readonly id: ImageID,
    readonly source: string,
    readonly format: ImageFormat,
    readonly size: null | string,
    readonly isOriginal = false,
    readonly description?: string
  ) {}
}
