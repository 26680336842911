import { MAX_FIRST_COUNT } from "Constants/Count";
import { TRACK_FRAGMENT } from "GraphQL/Fragment/TrackFragment";
import { Cart } from "GraphQL/Schema";
import { gql, useQuery } from "Lib/apollo-client";

export const LOAD_CART = gql`
  ${TRACK_FRAGMENT}
  query LOAD_CART($first: Int) {
    cart {
      id
      item_total_count
      item_total_amount
      cart_item(first: $first) {
        id
        track_id
        track {
          ...TrackFragment
        }
      }
    }
  }
`;

type Result = {
  readonly cart: Cart;
};

type Args = {
  readonly first?: number;
  readonly isSkip?: boolean;
};

export const useLoadCart = ({ isSkip }: Args) => {
  const { data, loading } = useQuery<Result, Args>(LOAD_CART, {
    skip: isSkip,
    variables: {
      first: MAX_FIRST_COUNT
    },

    fetchPolicy: "network-only"
  });

  return { loading, data };
};
