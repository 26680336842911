import Tippy from "@tippyjs/react";
import { Icon } from "App/Atomics/Icon";
import { Typography, TypoWeight } from "App/Atomics/Typography";
import { SearchForm } from "App/Molecules/SearchForm";
import { useAccountSelector } from "App/Store/AccountStore";
import { ModalAction, ModalStore, ModalType } from "App/Store/ModalStore";
import { SidebarAction, SidebarStore, useSidebarSelector } from "App/Store/SidebarStore";
import { Color } from "Constants/Color";
import { CCL_COMPANY_IDS, EFFECT_COMPANY_IDS, PUBLIC_DOMAIN_COMPANY_IDS, TRACK_COMPANY_IDS } from "Constants/CompanyIds";
import { Curation, Filter, Like, Playlist } from "Constants/Locale";
import { HOME, MY_CURATION, MY_FAVORITES, MY_PLAYLIST } from "Constants/Routes";
import { DESKTOP_MODE, WIDE_MODE, X_WIDE_MODE } from "Constants/Size";
import { useLocation } from "Lib/use-router";
import { isGuestAccountModel } from "Models/AccountModel/AccountModel";
import { MetdataKind } from "Models/GenreModel/GenreModel";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import "tippy.js/dist/tippy.css";
import { useSubclass, TypeMetadataSubclass } from "Utils/useSubclass";

type Props = {
  readonly isSidebar: boolean;
  readonly isCenterTab: boolean;
};

export const Bottom = ({ isSidebar, isCenterTab }: Props) => {
  const navigate = useNavigate();
  const isGuest = useAccountSelector(store => isGuestAccountModel(store.current));
  const { isDesktopOpen } = useSidebarSelector(store => store.current);

  const { metadataType, set } = useSubclass();
  const [, setSearch] = useSearchParams();

  const setSubClass = (subClass: TypeMetadataSubclass, kind: MetdataKind, companyIds: string[]) => {
    set({ companyIds, kind, subClass });
    setSearch({});
  };

  const moveToMyLikeTrack = () => {
    if (isGuest) {
      openLogin();
      return;
    }
    navigate(MY_FAVORITES);
  };
  const moveToMyPlayList = () => {
    if (isGuest) {
      openLogin();
      return;
    }
    navigate(MY_PLAYLIST);
  };
  const moveToMyCuration = () => {
    if (isGuest) {
      openLogin();
      return;
    }
    navigate(MY_CURATION);
  };
  const openLogin = () => ModalStore.dispatch(ModalAction.replace(ModalType.SIGNIN));

  const location = useLocation();

  const onSearch = (keyword: string) => {
    navigate({
      pathname: HOME,
      search: `?search=${keyword}`
    });
  };

  const toggleSidebar = () => {
    SidebarStore.dispatch(SidebarAction.toggle());
  };

  return (
    <Layout mobileShow={isCenterTab}>
      <Container>
        <Left>
          {isSidebar && (
            <FiterContainer open={isDesktopOpen} onClick={toggleSidebar}>
              <HamburgerIcon name="filter" />
              <FilterText weight={TypoWeight.Bold}>
                <Filter />
              </FilterText>
            </FiterContainer>
          )}
          <LeftContainer>
            <SearchContainer>
              <SearchForm onSearch={onSearch} />
            </SearchContainer>
          </LeftContainer>
        </Left>
        {isCenterTab && (
          <Center>
            <TagButtonList>
              <TagButton
                isSelected={
                  metadataType.subClass === TypeMetadataSubclass.Track &&
                  metadataType.companyIds.toString() === TRACK_COMPANY_IDS.toString()
                }
                onClick={() => setSubClass(TypeMetadataSubclass.Track, MetdataKind.Track, TRACK_COMPANY_IDS)}
              >
                <TagIcon name="genre"></TagIcon>
                <TagText>BGM</TagText>
              </TagButton>

              <TagButton
                isSelected={
                  metadataType.subClass === TypeMetadataSubclass.Effect &&
                  metadataType.companyIds.toString() === EFFECT_COMPANY_IDS.toString()
                }
                onClick={() => setSubClass(TypeMetadataSubclass.Effect, MetdataKind.Effect, EFFECT_COMPANY_IDS)}
              >
                <TagIcon name="mood"></TagIcon>
                <TagText>SFX</TagText>
              </TagButton>
              <>
                <TagButton
                  isSelected={
                    metadataType.subClass === TypeMetadataSubclass.Track &&
                    metadataType.companyIds.toString() === CCL_COMPANY_IDS.toString()
                  }
                  onClick={() => setSubClass(TypeMetadataSubclass.Track, MetdataKind.Ccl, CCL_COMPANY_IDS)}
                >
                  <TagIcon name="genre"></TagIcon>
                  <TagText>CCL</TagText>
                </TagButton>
                <TagButton
                  isSelected={
                    metadataType.subClass === TypeMetadataSubclass.Track &&
                    metadataType.companyIds.toString() === PUBLIC_DOMAIN_COMPANY_IDS.toString()
                  }
                  onClick={() => setSubClass(TypeMetadataSubclass.Track, MetdataKind.Pd, PUBLIC_DOMAIN_COMPANY_IDS)}
                >
                  <TagIcon name="mood"></TagIcon>
                  <TagText>PD</TagText>
                </TagButton>
              </>
            </TagButtonList>
          </Center>
        )}

        <Right>
          <StatusButton onClick={moveToMyLikeTrack} isSelected={location.pathname === MY_FAVORITES}>
            <Tippy content={Like.message()} arrow placement="top">
              <IconContainer>
                <StatusIcon name="like"></StatusIcon>
              </IconContainer>
            </Tippy>
          </StatusButton>
          <StatusButton onClick={moveToMyPlayList} isSelected={location.pathname === MY_PLAYLIST}>
            <Tippy content={Playlist.message()} arrow placement="top">
              <IconContainer>
                <StatusIcon name="add-playlist"></StatusIcon>
              </IconContainer>
            </Tippy>
          </StatusButton>
          <StatusButton onClick={moveToMyCuration} isSelected={location.pathname === MY_CURATION}>
            <Tippy content={Curation.message()} arrow placement="top">
              <IconContainer>
                <StatusIcon name="curation"></StatusIcon>
              </IconContainer>
            </Tippy>
          </StatusButton>
        </Right>
      </Container>
    </Layout>
  );
};

const Layout = styled.div<{ mobileShow: boolean }>`
  width: 100%;

  display: ${props => (props.mobileShow ? "flex" : "none")};
  justify-content: center;

  flex-direction: column;

  background-color: ${Color.Ac_Shadow};
  box-shadow: 0 0 10px rgb(0 0 0 / 30%);

  @media ${DESKTOP_MODE} {
    display: flex;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 72px;
`;

const SearchContainer = styled.div`
  position: relative;
  align-items: center;
  justify-content: flex-end;
  flex: 1;

  & > div {
    width: 100%;
  }

  max-width: 20.5rem;
  display: flex;

  width: 100%;
  height: 100%;
  padding-right: 20px;
`;

const StatusButton = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 100%;

  & > svg path {
    transition: all 0.25s;
  }

  cursor: pointer;
  user-select: none;
  position: relative;
  &:hover {
    path {
      fill: ${Color.AC_Carrot};
    }
  }

  ${props =>
    props.isSelected &&
    `
    color: ${Color.AC_Carrot};
    path {
      fill: ${Color.AC_Carrot}!important;
    }

  &:after {
    content: "";
    display: block;
    position: absolute;
    height: 2px;
    bottom: 0;
    left: 10px;
    right: 10px;
    background: ${Color.AC_Carrot};
  }`};
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  padding: 0 20px;

  @media ${DESKTOP_MODE} {
    display: flex;
  }
  display: none;
`;

const Right = styled.div`
  flex: 1;
  padding: 0 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 10px;

  @media ${WIDE_MODE} {
    display: flex;
  }
  display: none;
`;

const Left = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  display: none;
  @media ${DESKTOP_MODE} {
    display: flex;
  }
`;

const HamburgerIcon = styled(Icon)`
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 10px;
  svg {
    path {
      fill: ${Color.Ac_White};
    }
  }
`;

const StatusIcon = styled(Icon)`
  width: 24px;
  height: 24px;

  path {
    fill: ${Color.Ac_Gray1};
  }
`;

const FilterText = styled(Typography)`
  display: none;
  @media ${X_WIDE_MODE} {
    display: block;
  }
`;

const FiterContainer = styled.div<{ open?: boolean }>`
  background-color: ${Color.Ac_Charcoal};
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 20px;
  cursor: pointer;
  & > span,
  & > svg path {
    transition: all 0.25s;
  }

  &:hover {
    span {
      color: ${Color.AC_Carrot};
    }
    svg {
      path {
        fill: ${Color.AC_Carrot};
      }
    }
  }

  ${props =>
    props.open
      ? `
      svg{
        path {
        fill: ${Color.AC_Carrot};
       }
      }
       
        span {
          color: ${Color.AC_Carrot};
        }
     `
      : `
      svg{
        path {
          fill: ${Color.Ac_White};
        }
      }
      span {
        color: ${Color.Ac_White};
      }`}
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Center = styled.div`
  display: flex;
  align-items: center;
`;

const TagButtonList = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 10px;

  justify-content: center;
  @media ${DESKTOP_MODE} {
    justify-content: flex-end;
  }

  @media ${WIDE_MODE} {
    justify-content: center;
  }
`;

const TagButton = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 100%;
  margin: 0 10px;
  cursor: pointer;
  user-select: none;
  position: relative;

  & > span,
  & > svg path {
    transition: all 0.25s;
  }

  &:hover {
    color: ${Color.AC_Carrot};

    svg {
      path {
        fill: ${Color.AC_Carrot};
      }
    }
  }

  ${props =>
    props.isSelected &&
    `
    color: ${Color.AC_Carrot};
    svg {
      path {
        fill: ${Color.AC_Carrot};
    
      }
    }
  &:after {
    content: "";
    display: block;
    position: absolute;
    height: 2px;
    bottom: 0;
    left: 10px;
    right: 10px;
    background: ${Color.AC_Carrot};
  }`};
`;

const TagIcon = styled(Icon)`
  width: 25px;
  height: 25px;

  path {
    fill: ${Color.Ac_White};
  }
`;
const TagText = styled.span`
  margin-left: 10px;
  font-weight: bold;

  @media ${WIDE_MODE} {
    font-size: 1rem;
  }
  font-size: 0.8rem;
`;
