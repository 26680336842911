import { loadMetadataUrl } from "GraphQL/Queries/loadMetadataUrl";
import { createPlayer, Playing } from "Lib/player";
import { EventKind } from "Lib/player/createPlayerEvent";
import { TrackID } from "Models/TrackModel";
import { RefObject, useEffect, useRef } from "react";
import WaveSurfer from "wavesurfer.js";

export const { useCurrent, usePlayer, usePreload, useSeek, useCurrentTime, usePlaylist, useProgress, ...Player } = createPlayer<TrackID>({
  hash({ key }) {
    return key;
  },
  playingTimer: 30
});

export const useAudioWave = (ref: RefObject<HTMLElement>) => {
  const waveSurferRef = useRef<WaveSurfer | null>(null);

  const createWaveSurfer = (ref: RefObject<HTMLElement>) => {
    if (!ref.current) {
      return;
    }
    const wave = WaveSurfer.create({
      container: ref.current,
      waveColor: "#333",
      progressColor: `#FF6339`,
      barWidth: 2,
      responsive: true,
      height: 0
    });

    wave.on("audioprocess", () => {
      Player.currentTime(wave.getCurrentTime(), true);
    });

    wave.on("finish", () => {
      Player.end();
    });

    waveSurferRef.current = wave;
  };

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    Player.addEventListener(EventKind.Current, async event => {
      const { currentTrack } = event;

      if (currentTrack) {
        const { data } = await loadMetadataUrl({ id: currentTrack?.raw });
        const metadata = data.metadata[0];
        const metadataUrl = metadata.metadata_url?.find(url => url.type_url === "mp3high") ?? metadata.metadata_url![0];
        if (Player.getCurrent()?.raw === currentTrack.raw) {
          if (!ref.current) {
            return;
          }
          if (!waveSurferRef.current) {
            createWaveSurfer(ref);
          }
          const wave = waveSurferRef.current!;

          wave.load(metadataUrl.url);
          wave.on("ready", () => {
            wave.setHeight(50);
            Player.setDuration(currentTrack, wave.getDuration());
            Player.currentTime(wave.getCurrentTime(), true);
            if (Player.getPlaying() === Playing.Play) {
              Player.play();
            }
          });
        }
      }
    });

    Player.addEventListener(EventKind.Playing, event => {
      const wave = waveSurferRef.current;
      switch (event.playing) {
        case Playing.Play: {
          wave?.play();
          break;
        }
        case Playing.Pause: {
          wave?.pause();
          break;
        }
        case Playing.Stop: {
          wave?.pause();
          break;
        }
      }
    });

    Player.addEventListener(EventKind.Next, async event => {});

    Player.addEventListener(EventKind.Mute, event => {
      waveSurferRef.current?.setMute(event.isMuted);
    });

    Player.addEventListener(EventKind.Volume, event => {
      waveSurferRef.current?.setVolume(event.volume);
    });

    Player.addEventListener(EventKind.Select, event => {
      //
    });

    Player.addEventListener(EventKind.Autoplay, event => {
      const wave = waveSurferRef.current;
      if (wave) {
        wave.autoplay = event.autoplay;
      }
    });

    Player.addEventListener(EventKind.CurrentTime, event => {
      waveSurferRef.current?.setCurrentTime(event.currentTime);
    });

    Player.addEventListener(EventKind.PlayingTimer, async event => {});

    return () => {
      if (waveSurferRef.current) {
        waveSurferRef.current.destroy();
      }
    };
  }, []);
};
