import { Icon } from "App/Atomics/Icon";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { usePointTransactionNode } from "App/Store/PointTransactionNodeStore";
import { Color } from "Constants/Color";
import {
  PointTransactionAdmin,
  PointTransactionAdminCancel,
  PointTransactionPayletter,
  PointTransactionPayletterCancel,
  PointTransactionProduct,
  PointTransactionProductCancel
} from "Constants/Locale";
import { EventType, TransactionType } from "GraphQL/Schema";
import { PointTransactionID } from "Models/PointTransactionModel";
import styled from "styled-components";

type Props = {
  readonly id: PointTransactionID;
};

export const HistoryItem = ({ id }: Props) => {
  const pointTransaction = usePointTransactionNode(id);

  // const tryToRefundPayletterPoint = (payletterBill: PayletterBillID | null) => {};

  // const tryToRefundPurchaseHistory = (purchaseHistory: PurchaseHistoryID | null) => {};

  return (
    <Layout>
      <Coin>
        <Typography
          size={TypoSize.F14}
          weight={TypoWeight.Bold}
          color={pointTransaction?.transactionType === TransactionType.Deposit ? Color.AC_Blue : Color.Ac_Red1}
        >
          {pointTransaction?.transactionType === TransactionType.Deposit ? "+" : "-"}
          {pointTransaction?.amount.toLocaleString()}
        </Typography>
        <Icon name="coin" />
      </Coin>
      <Target>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          {pointTransaction?.eventType === EventType.Admin && pointTransaction?.transactionType === TransactionType.Deposit && (
            <PointTransactionAdmin />
          )}
          {pointTransaction?.eventType === EventType.Admin && pointTransaction?.transactionType !== TransactionType.Deposit && (
            <PointTransactionAdminCancel />
          )}

          {pointTransaction?.eventType === EventType.AdminCancel && <PointTransactionAdminCancel />}
          {pointTransaction?.eventType === EventType.Payletter && <PointTransactionPayletter />}
          {pointTransaction?.eventType === EventType.PayletterCancel && <PointTransactionPayletterCancel />}
          {pointTransaction?.eventType === EventType.Product && <PointTransactionProduct />}
          {pointTransaction?.eventType === EventType.ProductCancel && <PointTransactionProductCancel />}
        </Typography>
        {/* {pointTransaction?.payletterBill && (
          <IconContainer onClick={() => tryToRefundPayletterPoint(pointTransaction.payletterBill!)}>
            <ArrowIcon name="arrow-next"></ArrowIcon>
          </IconContainer>
        )}

        {pointTransaction?.purchaseHistory && (
          <IconContainer onClick={() => tryToRefundPurchaseHistory(pointTransaction.purchaseHistory!)}>
            <ArrowIcon name="arrow-next"></ArrowIcon>
          </IconContainer>
        )} */}
      </Target>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;
const Coin = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${Color.AC_Carrot};
`;
const Target = styled.span`
  font-size: 0.8rem;
  font-weight: bold;

  display: flex;
  justify-content: center;
`;

// const IconContainer = styled.div`
//   position: relative;
//   top: 1px;
// `;

// const ArrowIcon = styled(Icon)`
//   width: 1rem;
//   height: 1rem;
// `;
