import immer, { enableMapSet } from "immer";
import { ReactElement, ReactNode, useEffect, useLayoutEffect, useState } from "react";
import { deepEqual } from "../deep-equal";

enableMapSet();

export type Equal<T> = (current: T, next: T) => boolean;

export type Action<P> = {
  readonly type: string;
  readonly payload: P;
};

const createInitialAction = (namespace: string): Action<any> => ({
  type: Symbol(`${namespace}.@initialize`) as unknown as string,
  payload: null
});

export type ActionCreator<S, R extends Record<string, (state: S, ...payload: readonly any[]) => void>> = {
  readonly [key in keyof R]: R[key] extends (state: S, ...payload: infer P) => void ? (...payload: P) => Action<P> : never;
};

type Select<S, T> = (store: S) => T;

type ProviderProps = {
  readonly children: ReactNode;
};

export type Store<S, R extends Record<string, (state: S, ...payload: readonly any[]) => void>> = {
  action: ActionCreator<S, R>;
  useSelector: <T>(select: Select<S, T>, equal?: Equal<T>) => T;
  getState: () => S;
  dispatch: (...actionList: readonly Action<any>[]) => void;
  subscribe: (callback: (state: S) => void) => void;
  Provider: ({ children }: ProviderProps) => ReactElement;
};

export const createStore = <N extends string, S, R extends Record<string, (state: S, ...payload: readonly any[]) => void>>(
  namespace: N,
  createInitialState: () => S,
  reducers: R
): Store<S, R> => {
  const map = new Map(Object.entries(reducers).map(([key, value]) => [`${namespace}.${key}`, value]));

  const reduce = (current: S = createInitialState(), action: Action<any> | readonly Action<any>[]): S => {
    const actionList: readonly Action<any>[] = Array.isArray(action) ? action : ([action] as readonly Action<any>[]);
    let next = current;
    let isChanged = false;
    for (const action of actionList) {
      const reduce = map.get(action.type);
      if (reduce) {
        const state = immer(next, draft => {
          reduce(draft as S, ...action.payload);
        });
        isChanged = isChanged || next !== state;
        next = state;
      }
    }
    return isChanged ? next : current;
  };

  const action: ActionCreator<S, R> = Object.keys(reducers).reduce((record, type) => {
    record[type as keyof R] = (...payload: readonly any[]) => ({
      type: `${namespace}.${type}`,
      payload
    });
    return record;
  }, {} as Record<keyof R, any>);

  type Next = (store: S) => void;

  const nextSet = new Set<Next>();

  let state: null | S = null;

  const getState = (): S => {
    if (state === null) {
      state = reduce(undefined as unknown as S, createInitialAction(namespace));
    }
    return state;
  };

  const dispatch = (...actionList: readonly Action<any>[]): void => {
    const current = getState();
    const next = reduce(current, actionList);
    if (current !== next) {
      state = next;
      for (const next of nextSet) {
        next(state);
      }
    }
  };

  const subscribe = (callback: (state: S) => void): (() => void) => {
    nextSet.add(callback);
    return () => {
      nextSet.delete(callback);
    };
  };

  function useSelector<T>(select: Select<S, T>, equal: Equal<T> = deepEqual): T {
    const [current, setCurrent] = useState(() => select(getState()));

    useLayoutEffect(() => {
      const next = (state: S): void => {
        const next = select(state);
        if (!equal(current, next)) {
          setCurrent(() => next);
        }
      };
      nextSet.add(next);
      return () => {
        nextSet.delete(next);
      };
    }, [current]);

    return current;
  }

  const Provider = ({ children }: ProviderProps): ReactElement => {
    useEffect(
      () => () => {
        state = null;
      },
      []
    );

    return <>{children}</>;
  };

  return { action, useSelector, getState, dispatch, subscribe, Provider };
};
