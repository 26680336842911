import { Icon } from "App/Atomics/Icon";
import { ModalAction, ModalStore, ModalType } from "App/Store/ModalStore";
import { usePlaylistNode } from "App/Store/PlaylistNodeStore";
import { Color } from "Constants/Color";
import { Tracks } from "Constants/Locale";
import { playListDetailPath } from "Constants/Routes";
import { DESKTOP_MODE, WIDE_MODE } from "Constants/Size";
import { PlaylistID } from "Models/PlaylistModel";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { formatDate } from "Utils/format";

type Props = {
  readonly id: PlaylistID;
};

export const PlayListItem = ({ id }: Props) => {
  const playlist = usePlaylistNode(id);

  const navigate = useNavigate();
  const moveToPlayListDetail = (id: number) => {
    navigate(playListDetailPath.path({ id: id.toString() }));
  };

  const openModifyPlaylist = () => {
    ModalStore.dispatch(
      ModalAction.open(ModalType.MODIFY_PLAYLIST, {
        playlist: id
      })
    );
  };

  if (!playlist) {
    return null;
  }

  return (
    <>
      <Layout onClick={() => moveToPlayListDetail(id.raw)}>
        <PlayListNameContainer>
          <Title>{playlist.title}</Title>
        </PlayListNameContainer>
        <PlayListDescriptionContainer>
          <Title>{playlist.desciprtion ? playlist.desciprtion : "-"}</Title>
        </PlayListDescriptionContainer>
        <ModifyDateContainer>
          <Text>{formatDate(playlist.updatedAt)}</Text>
        </ModifyDateContainer>
        <MusicCountContainer>
          <Text>
            {playlist.count} <Tracks />
          </Text>
        </MusicCountContainer>
        <EditContainer>
          <EditButton
            onClick={e => {
              e.stopPropagation();
              openModifyPlaylist();
            }}
          >
            <ChnagePenIcon name="change-pen" />
          </EditButton>
        </EditContainer>
      </Layout>
    </>
  );
};

const Layout = styled.div`
  width: 100%;
  min-height: 80px;
  display: flex;

  border-bottom: 1px solid ${Color.Ac_Gray};
  padding: 10px 30px;
  cursor: pointer;

  &:hover {
    background-color: ${Color.Ac_Shadow};
    h3 {
      color: ${Color.Ac_Apricot};
    }
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const PlayListNameContainer = styled(Container)`
  flex: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 5rem;
`;

const PlayListDescriptionContainer = styled(Container)`
  flex: 1;
  display: none;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 2rem;
  @media ${WIDE_MODE} {
    display: flex;
  }
`;

const MusicCountContainer = styled(Container)`
  flex: 1;
  display: none;
  @media ${DESKTOP_MODE} {
    display: flex;
  }
`;

const ModifyDateContainer = styled(Container)`
  flex: 1;
`;

const EditContainer = styled(Container)`
  flex: 0 0 45px;
  display: none;
  @media ${WIDE_MODE} {
    display: flex;
  }
`;

const Title = styled.h3`
  font-size: 1rem;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Text = styled.p`
  font-size: 0.8rem;
  font-weight: bold;
`;

const ChnagePenIcon = styled(Icon)`
  width: 2rem;
  height: 2rem;

  &:hover {
    path {
      fill: ${Color.Ac_White};
    }
  }
  path {
    fill: ${Color.Ac_Gray1};
  }
`;

const EditButton = styled.div``;
