import styled from "styled-components";
import { First } from "./First";

export const Form = () => {
  return (
    <Layout>
      <First />
    </Layout>
  );
};

const Layout = styled.div`
  max-width: 500px;
  width: 100%;
  margin: 4rem auto;
`;
