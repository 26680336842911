import { Input } from "App/Atomics/Input";
import { RoleTree } from "App/Features/Trees/RoleTree";
import { useRoleNodeStore } from "App/Store/RoleNodeStore";
import { Color } from "Constants/Color";
import { UppercaseRole } from "Constants/Locale";
import { Size } from "Constants/Size";
import { useCurrentLanguage } from "Lib/localization";
import { useState } from "react";
import styled from "styled-components";
import { getRegExp } from "Utils/koreanRegexp";
import { TitleModelSort } from "Utils/sort";
import { useSubclass } from "Utils/useSubclass";
import { MenuItem } from "../MenuItem";

export const RoleMenu = () => {
  const { sortRoleTitleAsc } = TitleModelSort();
  const [search, setSearch] = useState<string>("");
  const languageCode = useCurrentLanguage();
  const { metadataType } = useSubclass();
  const roleList = useRoleNodeStore(store => store.values())
    .filter(({ title, kind }) => RegExp(getRegExp(search)).test(title.text(languageCode)) && kind.includes(metadataType.kind))
    .sort((a, b) => sortRoleTitleAsc(a, b))
    .map(({ id }) => id);

  return (
    <MenuItem name={UppercaseRole.message()}>
      <SearchLayout>
        <Search placeholder="Search..." onChange={setSearch}></Search>
      </SearchLayout>
      {roleList.map(id => (
        <RoleTree key={id.key} search="roles" id={id} />
      ))}
    </MenuItem>
  );
};

const SearchLayout = styled.div``;

const Search = styled(Input.Text)`
  width: 100%;
  border: 2.25px solid ${Color.GRAY_8};
  background-color: ${Color.GRAY_8};
  border-radius: 4px;
  padding: 0.5rem ${Size.PADDING_LARGE_PX};
  font-size: 0.75rem;
  transition: all 0.3s ease-in;
  color: ${Color.Ac_Gray4}!important;
  margin-right: 4px;
  margin-bottom: 0.5rem;
  &:hover {
    border-color: ${Color.GRAY_7};
  }

  input {
    color: ${Color.Ac_Gray4};
  }

  &::placeholder {
    color: ${Color.GRAY_4};
  }
  &:focus {
    border-color: ${Color.AC_Carrot};
    background-color: ${Color.Ac_Black};

    &::placeholder {
      color: ${Color.Ac_Black};
    }
  }
  &:disabled {
    cursor: not-allowed;
  }
`;
