import {ReactNode} from 'react';
import {Link} from 'react-router-dom';

export enum LinkTarget {
  Top = '_top',
  Self = '_self',
  Parent = '_parent',
  Blank = '_blank',
}

type Props = {
  to: string;
  target?: LinkTarget;
  children: ReactNode;
};

export const ExternalLink = ({to, children, ...props}: Props) => (
  <Link to={to} {...props}>
    {children}
  </Link>
);
