import { Icon } from "App/Atomics/Icon";
import { useFileRuleNode } from "App/Store/FileRuleNodeStore";
import { useGradeNode } from "App/Store/GradeNodeStore";
import { useProductPriceNode } from "App/Store/ProductPriceNodeStore";
import { Color } from "Constants/Color";
import { BuyButtonLicensePriceInfo } from "Constants/Locale";
import { LICENSE } from "Constants/Routes";
import { FileRuleID } from "Models/FileRuleModel";
import { GradeID } from "Models/GradeModel";
import styled from "styled-components";

interface Props {
  readonly grade: GradeID;
  readonly fileRule: FileRuleID;
}

export const LicensePrice = ({ grade, fileRule }: Props) => {
  const gradeModel = useGradeNode(grade);
  const fileRuleModel = useFileRuleNode(fileRule);
  const licensePrice = useProductPriceNode(gradeModel?.productPrice);
  const price = (licensePrice?.price ?? 0) + (fileRuleModel?.amount ?? 0);

  return (
    <Layout>
      <Header>
        <Title>
          <CoinIcon name="coin" />
          {price.toLocaleString()}{" "}
        </Title>
      </Header>

      <Desciprtion>
        <BuyButtonLicensePriceInfo a={({ children }) => <Link href={LICENSE}>{children}</Link>}></BuyButtonLicensePriceInfo>
      </Desciprtion>
    </Layout>
  );
};

const Layout = styled.div`
  padding: 1.25em 1rem;
  white-space: normal;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const Title = styled.h2`
  font-size: 0.9rem;
  color: ${Color.AC_Carrot};
  display: flex;
  align-items: center;
`;

const Desciprtion = styled.div`
  width: 100%;
  font-size: 0.8rem;
  margin-top: 0.7rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Link = styled.a`
  text-decoration: underline;
  font-weight: bold;
`;
const CoinIcon = styled(Icon)`
  width: 1.5rem;
  height: 1.5rem;

  margin-right: 0.25rem;
`;
