import { useState } from "react";

export type LoadingState = {
  isLoading: boolean;
  isLoaded: boolean;
  isLoadedError: boolean;
};

export const LOADING = "LOADING";
export const LOADED = "LOADED";
export const LOADED_ERROR = "LOADED_ERROR";

type Loading = typeof LOADING | typeof LOADED | typeof LOADED_ERROR;

export const useLoading = (initialState: Loading = LOADING) => {
  const [state, setState] = useState<Loading>(initialState);
  return {
    isLoading: state === LOADING,
    isLoaded: state === LOADED,
    isLoadedError: state === LOADED_ERROR,
    setLoading() {
      setState(LOADING);
    },
    setLoaded() {
      setState(LOADED);
    },
    setLoadedError() {
      setState(LOADED_ERROR);
    }
  };
};
