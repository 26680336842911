import { ArtistID } from "Models/ArtistModel";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useArtist } from "../hooks/useArtist";
import { ArtistInformationForm } from "./ArtistInformationForm";
import { TrackListForm } from "./TrackListForm";

export const Form = () => {
  const { id } = useParams();
  const artistId = new ArtistID(+id!);

  const { isLoaded: isLoadedArtist } = useArtist(id!);

  return (
    <Layout>
      <ArtistInformationForm id={artistId} isLoadedArtist={isLoadedArtist} />
      <TrackListForm id={artistId} isLoadedArtist={isLoadedArtist} />
    </Layout>
  );
};

const Layout = styled.div``;
