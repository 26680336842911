import styled from "styled-components";

import { Bottom } from "./Bottom";
import { Top } from "./Top";

type Props = {
  readonly isMenu: boolean;
  readonly isSidebar: boolean;
  readonly isCenterTab: boolean;
};

export const Header = ({ isMenu, isSidebar, isCenterTab }: Props) => {
  return (
    <Layout>
      <Top />
      {isMenu && <Bottom isSidebar={isSidebar} isCenterTab={isCenterTab} />}
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  flex-direction: column;
`;
