import { setGrade } from "App/Common/setGrade";
import { useLoadCacheGrade } from "GraphQL/Queries/useLoadCacheGrade";
import { useEffect } from "react";

export const useGradeList = () => {
  const { data, loading } = useLoadCacheGrade({});
  useEffect(() => {
    if (data) {
      setGrade(data.grade);
    }
  }, [data]);

  return { loading };
};
