import { Button, ButtonColor, ButtonSize } from "App/Atomics/Button";
import { Icon } from "App/Atomics/Icon";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { usePlaylistNode } from "App/Store/PlaylistNodeStore";
import { Color } from "Constants/Color";
import { ADMIN_CURATION } from "Constants/Routes";
import { useUpdateCuration } from "GraphQL/Mutations/useUpdateCuration";
import { Toast } from "Lib/toast";
import { PlaylistID } from "Models/PlaylistModel";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Style } from "../Style";

export const Form = () => {
  const { id } = useParams();
  const playlist = usePlaylistNode(new PlaylistID(+id!));

  const [title, setTitle] = useState<string>(playlist?.title ?? "");
  const [description, setDescription] = useState<string>(playlist?.desciprtion ?? "");
  const isDisable = !title.trim() || !title.trim();
  const { updatePlaylist, data, error, loading } = useUpdateCuration();
  const navigate = useNavigate();

  const tryToCreateStaff = () => {
    if (!title.trim()) {
      Toast.warning("제목을 입력해주세요.");
      return;
    }
    updatePlaylist({
      variables: {
        playlistId: +id!,
        title,
        description
      }
    });
  };

  useEffect(() => {
    if (data) {
      navigate(ADMIN_CURATION);
    }
  }, [data]);

  return (
    <Layout>
      <Typography weight={TypoWeight.Bold} color={Color.Ac_White} size={TypoSize.F24}>
        큐레이션 수정을 위해 아래 정보를 입력해주세요.
      </Typography>
      {error && (
        <ErrorBox>
          <Typography size={TypoSize.F13} weight={TypoWeight.Bold} color={Color.Ac_Red1}>
            {error.graphQLErrors[0].message}
          </Typography>
        </ErrorBox>
      )}

      <Container>
        <Style.Row>
          <Style.Caption>제목*</Style.Caption>
          <Style.SubCaption>큐레이션시 사용되는 제목입니다</Style.SubCaption>
          <Style.InputText placeholder={"제목"} onChange={value => setTitle(value)} value={title} />
        </Style.Row>
        <Style.Row>
          <Style.Caption>설명</Style.Caption>
          <Style.SubCaption>관리자용 메모 입니다</Style.SubCaption>
          <Style.TextAreaText placeholder={"설명"} onChange={value => setDescription(value)} value={description} />
        </Style.Row>
        <ButtonGroup>
          {!loading && (
            <SubmitButton
              size={ButtonSize.Small}
              color={isDisable ? ButtonColor.Disable : ButtonColor.Primary}
              disabled={isDisable}
              onClick={tryToCreateStaff}
            >
              수정
            </SubmitButton>
          )}
          {loading && (
            <SubmitButton size={ButtonSize.Small} color={ButtonColor.Disable} disabled>
              <Loading name="loading" />
            </SubmitButton>
          )}
        </ButtonGroup>
      </Container>
    </Layout>
  );
};

const Layout = styled.div`
  max-width: 500px;
  width: 100%;
  margin: 4rem auto;
`;

const Container = styled.div`
  margin-top: 2rem;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
`;
const SubmitButton = styled(Button)`
  width: 100px;
`;

const Loading = styled(Icon)`
  width: 1.25rem;
  height: 1.25rem;
`;

const ErrorBox = styled.div`
  width: 100%;
  padding: 1rem;
  background-color: ${Color.Ac_Shadow};
  border-radius: 1rem;
  border: 2px solid ${Color.AC_Carrot};
  display: flex;
  flex-direction: column;
  white-space: normal;
  margin: 1rem 0;
`;
