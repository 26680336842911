import { noop } from "lodash-es";
import { useEffect, useState } from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { ko, ja } from "date-fns/locale";
import styled from "styled-components";

interface Props {
  placeholder?: string;
  selected?: Date;
  timeIntervals?: number;
  showTimeSelect?: boolean;
  dateFormat?: string;
  locale?: string;
  disabled?: boolean;
  value: null | Date;
  onChange?: (date: Date, e: React.SyntheticEvent<any> | undefined) => void;
  onBlur?: (date: Date) => void;
}

export const LocaleWidthTimePicker = ({ placeholder, selected, locale, value, onChange = noop, onBlur = noop, ...props }: Props) => {
  const [loading, setLoading] = useState<boolean>();

  useEffect(() => {
    if (!locale) {
      setLoading(true);
      return;
    }
    switch (locale) {
      case "ko":
        registerLocale(locale, ko);
        break;
      case "ja":
        registerLocale(locale, ja);
        break;
      default:
        break;
    }
    setLoading(true);
  }, []);

  const change = (date: Date | [Date | null, Date | null] | null, e: React.SyntheticEvent<any> | undefined) => {
    if (!date || Array.isArray(date)) {
      return;
    }
    onChange(date, e);
  };

  const blur = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!value) {
      return;
    }

    const nextDate = new Date(value);
    if (nextDate.toString() === "Invalid Date") {
      return;
    }

    onBlur(nextDate);
  };

  return (
    <>
      {loading && (
        <CustomPicker
          locale={locale}
          placeholderText={placeholder}
          selected={selected}
          dateFormat="yyyy.MM.dd h:mm aa"
          timeFormat="p"
          timeCaption="time"
          popperProps={{
            positionFixed: true
          }}
          onChange={(date, e) => change(date, e)}
          onBlur={e => blur(e)}
          {...props}
        />
      )}
    </>
  );
};

const CustomPicker = styled(ReactDatePicker)``;
