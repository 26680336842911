import { SimpleSearchForm } from "App/Molecules/SimpleSearchForm";
import { Size } from "Constants/Size";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

const Layout = styled.div`
  margin: 1rem ${Size.MARGIN_LARGE_PX};

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SearchContainer = styled.div``;

const SearchLayout = styled.div`
  display: flex;
  align-items: center;
`;
const Left = styled.div`
  display: flex;
  button {
    margin-right: 1rem;
  }
`;

export const NavigationTab = () => {
  // const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const oldKeyword = searchParams.get("keyword") || "";

  const search = (keyword: string) => {
    if (keyword !== oldKeyword) {
      setSearchParams({ keyword });
    }
  };

  return (
    <Layout>
      <Left></Left>
      <SearchLayout>
        <SearchContainer>
          <SimpleSearchForm onSearch={search} defaultValue={oldKeyword} placeholder="이메일" />
        </SearchContainer>
      </SearchLayout>
    </Layout>
  );
};
