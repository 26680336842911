import { User, UserWhereInput } from "GraphQL/Schema";
import { gql, useQuery } from "Lib/apollo-client";

const LOAD_USER_ADMIN = gql`
  query LOAD_USER_ADMIN($where: UserWhereInput!, $first: Int) {
    user(where: $where, first: $first) {
      id
      email
      name
      company
      user_wallet {
        balance
      }
    }
  }
`;

type Result = {
  user: User[];
};

type Props = {
  first?: number;
  where: UserWhereInput;
  skip?: boolean;
};

type Args = {
  readonly where?: UserWhereInput;
  first?: number;
};

export const useLoadUserAdmin = ({ first, where, skip }: Props) => {
  const { data, loading } = useQuery<Result, Args>(LOAD_USER_ADMIN, {
    variables: {
      where,
      first
    },
    fetchPolicy: "network-only",
    skip
  });

  return { data, loading };
};
