import styled from "styled-components";

import { PurchaseHistoryItems } from "./PurchaseHistoryItems";

export const PurchaseTrack = () => {
  return (
    <Layout>
      <PurchaseHistoryItems />
    </Layout>
  );
};

const Layout = styled.div``;
