import { setTrackActivity } from "App/Common/setTrackActivity";
import { MAX_FIRST_COUNT } from "Constants/Count";
import { useLoadAdminTrackActivity } from "GraphQL/Queries/useLoadAdminTrackActivity";
import { useLoaded } from "Lib/use-loaded";
import { AccountID } from "Models/AccountModel/AccountID";
import { TrackID } from "Models/TrackModel";
import { useEffect } from "react";

type Props = {
  trackList: readonly TrackID[];
  user?: AccountID | null;
};

export const useAdminTrackActivity = ({ trackList, user }: Props) => {
  const skip = !user?.raw || !trackList.length;
  const { data, loading } = useLoadAdminTrackActivity({
    first: MAX_FIRST_COUNT,
    skip,
    userId: user?.raw,
    trackIds: trackList.map(({ raw }) => raw)
  });
  const { isLoaded } = useLoaded(loading);

  useEffect(() => {
    if (data) {
      setTrackActivity({ trackActivityList: data.trackActivity });
    }
  }, [data]);

  return { isLoaded };
};
