import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { Library } from "Constants/Locale";
import styled from "styled-components";
import { MenuList } from "./MenuList";

export const Header = () => {
  return (
    <Layout>
      <Top>
        <Typography weight={TypoWeight.Bold} size={TypoSize.F32}>
          <Library />
        </Typography>
      </Top>
      <Bottom>
        <MenuList />
      </Bottom>
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Top = styled.div`
  padding: 50px 30px 10px;
`;

const Bottom = styled.div`
  margin-top: 1.5rem;

  padding: 0 30px;
`;
