import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { PayInfoContentLoader } from "App/Features/ContentLoaders/PayInfoContentLoader";
import { usePayoneqStore } from "App/Store/PayoneqNodeStore";
import { PaymentMethod } from "Constants/Locale";
import { PayoneqID } from "Models/PayoneqModel";
import { ReactElement } from "react";
import styled from "styled-components";
import { BuyCheckAction, BuyCheckStore, useBuyCheckSelector } from "../../Store";
import { BuyCheckStyle } from "../../Style";
import { PayButton } from "./PayButton";

export const PayInfo = (): ReactElement => {
  const payoneqInformation = usePayoneqStore(store => store.filter(({ isGlobal }) => !isGlobal)).map(({ id }) => id);
  const payoneqGlobalInformation = usePayoneqStore(store => store.filter(({ isGlobal }) => isGlobal)).map(({ id }) => id);

  const { payoneq, isGlobal, payoneqGlobal } = useBuyCheckSelector(store => store.current);
  const onChangePayoneq = (id: PayoneqID) => BuyCheckStore.dispatch(BuyCheckAction.setPayoneqInformation(id));
  const onChangePayoneqGlobal = (id: PayoneqID) => {
    BuyCheckStore.dispatch(BuyCheckAction.setPayoneqGlobalInformation(id));
  };

  return (
    <BuyCheckStyle.Layout>
      <BuyCheckStyle.Title>
        <Typography size={TypoSize.F15} weight={TypoWeight.Bold}>
          <PaymentMethod />
        </Typography>
      </BuyCheckStyle.Title>
      <GridGroup>
        {!isGlobal && (
          <>
            {payoneqInformation.map(id => (
              <PayButton key={id.key} id={id} isCurrent={id.key === payoneq?.key} onClick={() => onChangePayoneq(id)} />
            ))}
            {!payoneqInformation.length && [...Array(6)].map((_, key) => <PayInfoContentLoader key={key} />)}
          </>
        )}
        {isGlobal && (
          <>
            {payoneqGlobalInformation.map(id => (
              <PayButton key={id.key} id={id} isCurrent={id.key === payoneqGlobal?.key} onClick={() => onChangePayoneqGlobal(id)} />
            ))}
            {!payoneqInformation.length && [...Array(2)].map((_, key) => <PayInfoContentLoader key={key} />)}
          </>
        )}
      </GridGroup>
    </BuyCheckStyle.Layout>
  );
};

const GridGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 8px;
`;
