import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { BasicTemplate } from "App/Templates/BasicTemplate";
import { Color } from "Constants/Color";
import {
  EmailQuicklyReply,
  GoToSupport,
  SearchQuestions,
  SendEmail,
  SendEmailLink,
  SupportLink,
  SupportMainDescriptionText,
  SupportMainTitleText
} from "Constants/Locale";
import { DESKTOP_MODE } from "Constants/Size";
import styled from "styled-components";

export const Support = () => {
  return (
    <BasicTemplate isSidebar={false} isMenu={false}>
      <Layout>
        <Background />
        <Container>
          <FirstSection>
            <Left>
              <Title size={TypoSize.F48} weight={TypoWeight.Bold}>
                <SupportMainTitleText />
              </Title>
              <Description size={TypoSize.F18}>
                <SupportMainDescriptionText />
              </Description>
            </Left>
            <Right></Right>
          </FirstSection>
          <InformationContainer>
            <Inforatmion>
              <InformationLeft>
                <Typography color={Color.Ac_Black} weight={TypoWeight.Bold} size={TypoSize.F32}>
                  <SendEmail />
                </Typography>

                <EmailText color={Color.Ac_Black}>
                  <SendEmailLink a={({ children }) => <Link href="mailto:contact@artistscard.com">{children}</Link>} />
                </EmailText>
                <Typography color={Color.Ac_Black}>
                  <EmailQuicklyReply />
                </Typography>
              </InformationLeft>
              <InformationRight>
                <Typography color={Color.Ac_Black} weight={TypoWeight.Bold} size={TypoSize.F32}>
                  <GoToSupport />
                </Typography>

                <EmailText color={Color.Ac_Black}>
                  <SupportLink a={({ children }) => <Link href={"https://support.artistscard.com/bgm-manager"}>{children}</Link>} />
                </EmailText>
                <Typography color={Color.Ac_Black}>
                  <SearchQuestions />
                </Typography>
              </InformationRight>
            </Inforatmion>
          </InformationContainer>
        </Container>
      </Layout>
    </BasicTemplate>
  );
};

const Layout = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  position: relative;
  margin-top: 70px;
  max-width: 1300px;
  width: 100%;
`;

const FirstSection = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 100px;
  padding: 0 20px;
  text-align: center;
  @media ${DESKTOP_MODE} {
    text-align: left;
  }
`;

const Left = styled.div`
  white-space: pre-line;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const Right = styled.div`
  white-space: pre-line;
  flex: 1;
  height: 100%;
  display: none;
  flex-direction: column;

  @media ${DESKTOP_MODE} {
    display: flex;
  }
`;

const Title = styled(Typography)`
  margin: 40px 0;
  font-size: 25px;
  @media ${DESKTOP_MODE} {
    font-size: 48px;
  }
`;

const Description = styled(Typography)`
  font-size: 14px;
  @media ${DESKTOP_MODE} {
    font-size: 18px;
  }
`;

const EmailText = styled(Typography)`
  margin: 2rem 0;
`;

const Background = styled.div`
  position: absolute;

  width: 100%;
  height: 100%;

  background: rgb(21, 30, 51);
  background: linear-gradient(78deg, rgba(21, 30, 51, 1) 0%, rgba(95, 88, 83, 1) 100%);
`;

const InformationContainer = styled.div`
  width: 100%;

  background-color: ${Color.Ac_White};
  border-radius: 0.25rem;
`;
const Inforatmion = styled.div`
  display: flex;

  width: 100%;
  height: 100%;

  flex-direction: column;
  @media ${DESKTOP_MODE} {
    flex-direction: row;
  }
`;

const InformationLeft = styled(Left)`
  align-items: center;
  padding: 100px 20px;
  border-bottom: 1px solid ${Color.Ac_Gray3};
  text-align: center;
  @media ${DESKTOP_MODE} {
    border-right: 1px solid ${Color.Ac_Gray3};
  }
`;
const InformationRight = styled(Right)`
  align-items: center;
  text-align: center;
  padding: 100px 20px;
  display: flex;
`;

const Link = styled.a`
  color: ${Color.AC_Carrot};
`;
