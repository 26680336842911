import { useAuth } from "Utils/useAuth";
import { purchase } from "GraphQL/Mutations/purchase";
import { Toast } from "Lib/toast";
import { PayoneqNodeStore } from "App/Store/PayoneqNodeStore";
import { useBuyCheckSelector } from "../Store";
import { TryToAgain } from "Constants/Locale";

export const usePurchasePoint = () => {
  const token = useAuth().token?.access_token;
  const { payoneq } = useBuyCheckSelector(store => store.current);

  const purchasePoint = async (price: number) => {
    if (!token) {
      Toast.primary("로그인 정보를 확인할 수 없어요.");
      return;
    }
    if (!payoneq || !price) {
      Toast.primary("구매 정보를 확인할 수 없어요.");
      return;
    }
    const pgcode = PayoneqNodeStore.get(payoneq)!.pgcode;
    try {
      const result = await purchase({
        token,
        pgcode,
        amount: price
      });
      console.log(result);
      return result;
    } catch (err) {
      console.log(err);
      Toast.primary(TryToAgain.message());
    }
    return;
  };
  return { purchasePoint };
};
