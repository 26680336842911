import LottiePlayer, { AnimationItem } from "lottie-web";
import { useEffect, useRef } from "react";

type Data = any;

type Props = Styleable & {
  readonly data: Data;
  readonly loop?: boolean;
  readonly playing?: boolean;
  readonly width?: number;
  readonly height?: number;
};

export const Lottie = ({ style, className, data, loop = false, playing = false }: Props) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const animationRef = useRef<AnimationItem>();

  useEffect(() => {
    if (!playing) {
      animationRef.current?.stop();
      return;
    }

    animationRef.current = LottiePlayer.loadAnimation({
      container: elementRef.current!,
      animationData: data,
      renderer: "svg",
      loop,
      autoplay: true
    });
  }, [playing]);

  return <div ref={elementRef} className={className} style={style} />;
};
