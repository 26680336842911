import { ArtistNodeStore } from "App/Store/ArtistNodeStore";
import { TrackNodeStore } from "App/Store/TrackNodeStore";
import { Track } from "GraphQL/Schema";
import { ArtistID, ArtistModel } from "Models/ArtistModel";
import { FileRuleID } from "Models/FileRuleModel";
import { GenreID } from "Models/GenreModel/GenreModel";
import { GradeID } from "Models/GradeModel";
import { RoleID } from "Models/RoleModel";
import { TrackID, TrackModel } from "Models/TrackModel";

export const setTrack = (trackList: Track[]) => {
  for (const track of trackList) {
    const isTrack = !!TrackNodeStore.get(new TrackID(track.id));
    if (!isTrack) {
      const trackM = TrackModel.form({
        id: track.id!,
        artist: new ArtistID(track.artist_id!),
        bpm: track.bpm ?? 0,
        duration: track.duration ?? track.duration_temporary ?? 0,
        title: track.title!,
        grade: new GradeID(track.grade_id!),
        genreList: track.genre_id.map(genre => new GenreID(genre!.toString())),
        roleList: track.role_id.map(role => new RoleID(role!.toString())),
        relationTrackList: [],
        fileRule: new FileRuleID(track.file_rule_id),
        subClass: track.type_metadata_subclass
      });

      if (track.artist_id) {
        if (!ArtistNodeStore.get(new ArtistID(track.artist_id))) {
          const artistM = ArtistModel.form({
            id: track.artist_id!,
            name: track.artist_name!,
            roleList: []
          });
          ArtistNodeStore.set(new ArtistID(track.artist_id!), artistM);
        }
      }

      TrackNodeStore.set(new TrackID(track.id!), trackM);
    }
  }
};
