import { JwtTokenSet } from "GraphQL/Schema";
import { useCookies } from "Lib/cookie";
import { AccountType } from "Models/AccountModel/AccountType";
import { parseJwt } from "Utils/format";

const AUTH_TOKEN_COOKIE = "AUTH_TOKEN_COOKIE";

type ReturnType = {
  readonly token: JwtTokenSet | null;
  readonly userId: number | null;
  readonly type: AccountType;
  readonly set: (tokens: JwtTokenSet) => void;
  readonly clear: () => void;
};

export const useAuth = (): ReturnType => {
  const [cookies, setCookie, removeCookie] = useCookies([AUTH_TOKEN_COOKIE]);
  const token = cookies[AUTH_TOKEN_COOKIE] ? (cookies[AUTH_TOKEN_COOKIE] as JwtTokenSet) : null;

  const userId = token ? parseJwt(token.access_token).id : null;
  const tier = token ? parseJwt(token.access_token).tier : null;

  const type = tier === "ADMIN" || tier === "STAFF" ? AccountType.Admin : AccountType.Normal;

  const set = (token: JwtTokenSet): void => {
    setCookie(AUTH_TOKEN_COOKIE, token, { path: "/" });
  };
  const clear = (): void => removeCookie(AUTH_TOKEN_COOKIE, { path: "/" });
  return { token, userId, type, set, clear };
};
