import { Color } from "Constants/Color";
import { Modal } from "Lib/modal";
import styled from "styled-components";
import { Button, ButtonSize } from "App/Atomics/Button";
import { Icon } from "App/Atomics/Icon";
import { ModalAction, ModalStore } from "App/Store/ModalStore";
import { MenuList } from "App/Templates/BasicTemplate/Sidebar/MenuList";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { useDesktop } from "Utils/useDesktop";

type Props = {
  readonly isOpen: boolean;
};

export const TrackFilterModal = ({ isOpen }: Props) => {
  const onRequestClose = () => ModalStore.dispatch(ModalAction.closeAll());

  const isDesktop = useDesktop();
  if (isDesktop) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} animation={false}>
      <Layout>
        <Container>
          <Typography size={TypoSize.F18} weight={TypoWeight.Bold}>
            FILTER
          </Typography>
          <CloseButton onClick={onRequestClose} size={ButtonSize.Xsmall}>
            <CloseIcon name="close" />
          </CloseButton>
        </Container>
        <MenuLayout>
          <MenuList />
        </MenuLayout>
      </Layout>
    </Modal>
  );
};

const Layout = styled.div`
  background-color: ${Color.Ac_Charcoal};
  width: 80%;
  height: 100vh;
  overflow: hidden;
  pointer-events: auto;
  margin-right: auto;
`;

const CloseButton = styled(Button)`
  border: 0;
  margin-left: auto;
  padding: 0;
`;

const CloseIcon = styled(Icon)`
  width: 1rem;
  height: 1rem;
`;

const MenuLayout = styled.div`
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  background-color: ${Color.Ac_Charcoal};
  padding: 1.25rem 1.25rem 21rem;
`;

const Container = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: center;

  background-color: ${Color.Ac_Shadow};
`;
