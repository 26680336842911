import { createStore } from "Lib/store";

const createInitialState = () => ({
  isLoadedCurationDetail: false as boolean
});

export const {
  useSelector: useCurationDetailSelector,
  action: CurationDetailAction,
  ...CurationDetailStore
} = createStore("CurationDetail", createInitialState, {
  setIsLoadedCurationDetail(state, isLoadedCurationDetail: boolean) {
    state.isLoadedCurationDetail = isLoadedCurationDetail;
  }
});
