import { Button, ButtonColor, ButtonSize } from "App/Atomics/Button";
import { Icon } from "App/Atomics/Icon";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { useProductPriceNode } from "App/Store/ProductPriceNodeStore";
import { Color } from "Constants/Color";
import { ADMIN_PRICE } from "Constants/Routes";
import { useUpdatePrice } from "GraphQL/Mutations/useUodatePrice";
import { ProductPriceType } from "GraphQL/Schema";
import { Toast } from "Lib/toast";
import { ProductPriceID } from "Models/ProductPriceModel";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { SelectStyle } from "../../CechargePoint/Style";
import { Style } from "../Style";

const productPriceOption = [
  { id: ProductPriceType.Curation, name: "큐레이션" },
  { id: ProductPriceType.Track, name: "트랙" },
  { id: ProductPriceType.Mp3, name: "mp3" },
  { id: ProductPriceType.Wav, name: "wav" }
];

export const Form = () => {
  const { id } = useParams();
  const productPrice = useProductPriceNode(new ProductPriceID(+id!));
  const { updatePrice, loading, error } = useUpdatePrice();
  const [description, setDescription] = useState<string>(productPrice?.description ?? "");
  const navigate = useNavigate();
  const tryToUpdatePrice = async () => {
    try {
      await updatePrice({
        variables: {
          productPriceId: +id!,
          description: description
        }
      });
      navigate(ADMIN_PRICE);
    } catch (error) {
      Toast.error("오류 발생");
    }
  };

  return (
    <Layout>
      <Typography weight={TypoWeight.Bold} color={Color.Ac_White} size={TypoSize.F24}>
        금액을 수정을 위해 아래 정보를 입력해주세요.
      </Typography>
      {error && (
        <ErrorBox>
          <Typography size={TypoSize.F13} weight={TypoWeight.Bold} color={Color.Ac_Red1}>
            {error.graphQLErrors[0].message}
          </Typography>
        </ErrorBox>
      )}

      <Container>
        <Style.Row>
          <Style.Caption>금액*</Style.Caption>
          <Style.SubCaption>금액을 입력해 주세요</Style.SubCaption>
          <Style.InputText placeholder={"충전금액"} type="text" isReadonly value={productPrice?.price.toLocaleString()} />
        </Style.Row>
        <Style.Row>
          <Style.Caption>타입</Style.Caption>
          <Style.SubCaption>금액의 대한 타입을 선택해 주세요</Style.SubCaption>
          <Style.Select
            isDisabled={true}
            style={SelectStyle}
            optionList={productPriceOption}
            defaultValue={productPriceOption.find(({ id }) => id === productPrice?.type)}
            placeholder="타입"
          />
        </Style.Row>
        <Style.Row>
          <Style.Caption>설명</Style.Caption>
          <Style.SubCaption>관리자용 메모 입니다</Style.SubCaption>
          <Style.TextAreaText placeholder={"설명"} onChange={value => setDescription(value)} value={description} />
        </Style.Row>
        <ButtonGroup>
          {!loading && (
            <SubmitButton
              size={ButtonSize.Small}
              color={loading ? ButtonColor.Disable : ButtonColor.Primary}
              disabled={loading}
              onClick={tryToUpdatePrice}
            >
              수정
            </SubmitButton>
          )}
          {loading && (
            <SubmitButton size={ButtonSize.Small} color={ButtonColor.Disable} disabled>
              <Loading name="loading" />
            </SubmitButton>
          )}
        </ButtonGroup>
      </Container>
    </Layout>
  );
};

const Layout = styled.div`
  max-width: 500px;
  width: 100%;
  margin: 4rem auto;
`;

const Container = styled.div`
  margin-top: 2rem;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
`;
const SubmitButton = styled(Button)`
  width: 100px;
`;

const Loading = styled(Icon)`
  width: 1.25rem;
  height: 1.25rem;
`;

const ErrorBox = styled.div`
  width: 100%;
  padding: 1rem;
  background-color: ${Color.Ac_Shadow};
  border-radius: 1rem;
  border: 2px solid ${Color.AC_Carrot};
  display: flex;
  flex-direction: column;
  white-space: normal;
  margin: 1rem 0;
`;
