import { configs } from "Config";
import { Metadata } from "GraphQL/Schema";
import { ApolloClient, createHttpLink, gql, InMemoryCache } from "Lib/apollo-client";

const LOAD_METADATA_URL = gql`
  query LOAD_METADATA_URL($id: ID) {
    metadata(where: { metadata_id: $id }) {
      metadata_id
      metadata_url(where: { valid_check: V3 }) {
        type_url
        url
      }
    }
  }
`;

type Result = {
  readonly metadata: Metadata[];
};

type Args = {
  readonly id?: number;
};

export const loadMetadataUrl = async ({ id }: Args) => {
  const httpLink = createHttpLink({
    uri: configs.urls.service
  });

  const client = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache()
  });

  const { data, error } = await client.query<Result, Args>({
    query: LOAD_METADATA_URL,
    variables: {
      id
    }
  });

  return { data, error };
};
