import { createStore } from "Lib/store";
import { SIDEBAR_MODAL } from "Models/SidebarModel";

const createInitialState = () => ({ current: SIDEBAR_MODAL });

export const {
  useSelector: useSidebarSelector,
  action: SidebarAction,
  ...SidebarStore
} = createStore("Sidebar", createInitialState, {
  toggle(state) {
    state.current.isDesktopOpen = !state.current.isDesktopOpen;
  },
  open(state) {
    state.current.isDesktopOpen = true;
  },
  close(state) {
    state.current.isDesktopOpen = false;
  },

  toggleAdmin(state) {
    state.current.isAdminMobileOpen = !state.current.isAdminMobileOpen;
    state.current.isAdminDesktopOpen = !state.current.isAdminDesktopOpen;
  },
  closeAdmin(state) {
    state.current.isAdminDesktopOpen = false;
    state.current.isAdminMobileOpen = true;
  },
  openAdmin(state) {
    state.current.isAdminDesktopOpen = true;
    state.current.isAdminMobileOpen = false;
  }
});
