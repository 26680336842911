import { Button, ButtonColor, ButtonSize } from "App/Atomics/Button";
import { Icon } from "App/Atomics/Icon";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { Color } from "Constants/Color";
import { useWithdrawalPoint } from "GraphQL/Mutations/useWithdrawalPoint";
import { useLoadUser } from "GraphQL/Queries/useLoadUser";
import { Toast } from "Lib/toast";
import { useEffect } from "react";
import styled from "styled-components";
import { CachargePointAction, CachargePointStore, Section, useCachargePointSelector } from "../../Store/WithdrawalPointStore";
import { Style } from "../../Style";

export const Second = () => {
  const { data, error, loading, withdrawalPointByAdmin } = useWithdrawalPoint();
  const back = () => CachargePointStore.dispatch(CachargePointAction.setSection(Section.First));
  const { amount, userInfo } = useCachargePointSelector(store => store);
  const { data: userData, loading: loaduser } = useLoadUser({
    where: {
      id: +userInfo?.id!
    }
  });
  const tryToWithdrawalPoint = async () => {
    if (!window.confirm("회수 하시겠습니까?")) {
      return;
    }
    withdrawalPointByAdmin({
      variables: {
        amount,
        targetUserId: +userInfo?.id!
      }
    });
  };

  useEffect(() => {
    if (data) {
      Toast.success("회수했습니다");
      CachargePointStore.dispatch(CachargePointAction.clear());
    }
  }, [data]);

  return (
    <Layout>
      <Typography weight={TypoWeight.Bold} color={Color.Ac_White} size={TypoSize.F24}>
        회수할 유저의 정보가 맞는지 확인해주세요
      </Typography>
      {error && (
        <ErrorBox>
          <Typography size={TypoSize.F13} weight={TypoWeight.Bold} color={Color.Ac_Red1}>
            {error.graphQLErrors[0].message}
          </Typography>
        </ErrorBox>
      )}

      <Container>
        {!!loaduser && (
          <LoadingContainer>
            <Loading name="loading" />
          </LoadingContainer>
        )}
        {!loaduser && (
          <>
            <Style.Row>
              <Style.Caption>유저 이메일</Style.Caption>
              <Style.InputText defaultValue={userData?.user[0].email} isDisabled />
            </Style.Row>
            <Style.Row>
              <Style.Caption>유저 이름</Style.Caption>
              <Style.InputText defaultValue={userData?.user[0].name ?? "-"} isDisabled />
            </Style.Row>
            <Style.Row>
              <Style.Caption>유저 회사</Style.Caption>
              <Style.InputText defaultValue={userData?.user[0].company ?? "-"} isDisabled />
            </Style.Row>
            <Style.Row>
              <Style.Caption>소지 금액</Style.Caption>
              <Style.InputText defaultValue={userData?.user[0].user_wallet?.balance.toString() + "원"} isDisabled />
            </Style.Row>
            <Style.Row>
              <Style.Caption>회수 금액</Style.Caption>
              <Style.InputText defaultValue={amount.toString() + "원"} isDisabled />
            </Style.Row>
            <ButtonGroup>
              {!loading && (
                <>
                  <SubmitButton size={ButtonSize.Small} color={ButtonColor.PrimaryNotFill} onClick={back}>
                    뒤로가기
                  </SubmitButton>
                  <SubmitButton size={ButtonSize.Small} color={ButtonColor.Primary} onClick={tryToWithdrawalPoint}>
                    확인
                  </SubmitButton>
                </>
              )}
              {loading && (
                <SubmitButton size={ButtonSize.Small} color={ButtonColor.Disable} disabled>
                  <Loading name="loading" />
                </SubmitButton>
              )}
            </ButtonGroup>
          </>
        )}
      </Container>
    </Layout>
  );
};

const Layout = styled.div``;
const Loading = styled(Icon)`
  width: 1.25rem;
  height: 1.25rem;
`;

const ErrorBox = styled.div`
  width: 100%;
  padding: 1rem;
  background-color: ${Color.Ac_Shadow};
  border-radius: 1rem;
  border: 2px solid ${Color.AC_Carrot};
  display: flex;
  flex-direction: column;
  white-space: normal;
  margin: 1rem 0;
`;
const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
`;
const SubmitButton = styled(Button)`
  width: 100px;
  margin: 0 0.25rem;
`;
const Container = styled.div`
  border-radius: 1rem;
  margin: 2rem 0;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
`;
