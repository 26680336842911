import { Color } from "Constants/Color";
import { DESKTOP_MODE, MOBILE_MODE, Size, WIDE_MODE } from "Constants/Size";
import styled from "styled-components";

type Page = number;

type Props = {
  pageList?: Page[];
  next?: false | Page;
  previous?: false | Page;
  start?: false | Page;
  end?: false | Page;
  isCurrent: (page: Page) => boolean;
  goTo: (page: Page) => void;
};

export const Pagination = ({ pageList = [], next, previous, start, end, isCurrent, goTo }: Props) => (
  <Layout>
    {start && <StartLink onClick={() => goTo(start)}>{"<<"}</StartLink>}
    {previous && <PreviousLink onClick={() => goTo(previous)}>{"<"}</PreviousLink>}
    <Container>
      {pageList.map(page =>
        isCurrent(page) ? (
          <CurrentPageLink key={page} onClick={() => goTo(page)}>
            {page}
          </CurrentPageLink>
        ) : (
          <PageLink key={page} onClick={() => goTo(page)}>
            {page}
          </PageLink>
        )
      )}
    </Container>

    {next && <NextLink onClick={() => goTo(next)}>{">"}</NextLink>}
    {end && <EndLink onClick={() => goTo(end)}>{">>"}</EndLink>}
  </Layout>
);

const Layout = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: min-content;
  margin-top: ${Size.MARGIN_XX_LARGE_PX};
  margin-bottom: ${Size.MARGIN_XX_LARGE_PX};
`;

const Container = styled.div`
  display: grid;
  margin: 0 1rem;

  grid-auto-flow: column;
  justify-content: center;
  grid-gap: calc(100% / 250);
`;

const AtomicPageLink = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4rem 0.38rem;
  @media ${MOBILE_MODE} {
    padding: 0.4rem 0.8rem;
  }
  @media ${DESKTOP_MODE} {
    padding: 0.4rem 0.3rem;
  }

  @media ${WIDE_MODE} {
    padding: 0.4rem 0.8rem;
  }

  color: ${Color.Ac_Gray0};
  font-weight: bold;
  cursor: pointer;
  &:hover {
    color: ${Color.Ac_Apricot};
  }
`;

const LinkButton = styled(AtomicPageLink)`
  display: none;
  width: 10px;
  padding: 0.3rem 0.3rem;
  font-size: 1rem;
  min-height: 40px;
  border-radius: 10rem;
  margin: 0 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    border-color: ${Color.Ac_Apricot};
  }
  font-weight: bold;
  color: ${Color.Ac_Gray0};

  @media ${DESKTOP_MODE} {
    border: 2px solid ${Color.Ac_Gray0};
    width: 40px;
  }
`;

const StartLink = styled(LinkButton)`
  font-size: 0.9rem;

  display: none;
  @media ${DESKTOP_MODE} {
    display: flex;
  }
`;

const PreviousLink = LinkButton;
const CurrentPageLink = styled(AtomicPageLink)`
  pointer-events: none;
  cursor: default;
  color: ${Color.Ac_Apricot};
  transform: scale(1.2);
  background-color: ${Color.Ac_Shadow};

  border-radius: 10rem;
`;

const PageLink = AtomicPageLink;
const NextLink = styled(LinkButton)``;
const EndLink = styled(LinkButton)`
  font-size: 0.9rem;
  padding: 0.3rem 1.1rem 0.3rem 1.2rem;
  display: none;
  @media ${DESKTOP_MODE} {
    display: flex;
  }
`;
