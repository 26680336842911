import { Button, ButtonColor } from "App/Atomics/Button";
import { Icon } from "App/Atomics/Icon";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { useAccountSelector } from "App/Store/AccountStore";
import { ModalAction, ModalStore, ModalType } from "App/Store/ModalStore";
import { Color } from "Constants/Color";
import { ALREADY_USED_SESSION, LACK_OF_BALANCE, NO_ITEM_FOR_PAY, NO_USER_EMAIL } from "Constants/ErrorCode";
import { AllCount, OrderTrackInforatmion, Pieces, PointChargeText, Purchase, TotalPaymentAmount, TryToAgain } from "Constants/Locale";
import { adminPaymentCompletePath, POINT_CHARGE } from "Constants/Routes";
import { DESKTOP_MODE, WIDE_MODE } from "Constants/Size";
import { usePurchaseAllCartItems } from "GraphQL/Mutations/usePurchaseAllCartItems";
import { Toast } from "Lib/toast";
import { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAuth } from "Utils/useAuth";
import { CartTrackForm } from "./CartTrackForm";

interface Props {
  readonly isLoadedCart: boolean;
}

export const Right = ({ isLoadedCart }: Props) => {
  const navigate = useNavigate();

  const { purchaseAllCartItems, loading, data, error } = usePurchaseAllCartItems();

  const cart = useAccountSelector(store => store.cart);
  const isVerification = useAccountSelector(store => store.current.isVerification);
  const { token } = useAuth();

  const [agree, setAgree] = useState<boolean>(false);
  const [agree2, setAgree2] = useState<boolean>(false);

  const tryToPurchaseCart = async () => {
    if (!token) {
      Toast.error(TryToAgain.message());
      return;
    }

    if (!isVerification) {
      const accessToken = token.access_token;
      const refreshToken = token.refresh_token;
      ModalStore.dispatch(ModalAction.replace(ModalType.USER_VERIFY, { accessToken, refreshToken }));
      return;
    }
    try {
      await purchaseAllCartItems();
    } catch (error) {
      console.log(error);
    }
  };

  const trytToChargePoint = () => {
    navigate(POINT_CHARGE);
  };

  useEffect(() => {
    if (data) {
      const histroy = data.purchaseAllCartItems;

      navigate(
        adminPaymentCompletePath.args({
          userId: histroy.user_id,
          amount: histroy.item_total_amount,
          count: histroy.item_total_count,
          date: histroy.created_at
        })
      );
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      switch (error?.graphQLErrors[0].extensions.code) {
        case LACK_OF_BALANCE:
          Toast.error("잔액부족");
          break;
        case NO_ITEM_FOR_PAY:
          Toast.error("장바구니에 담긴 트랙이 없습니다.");
          break;
        case NO_USER_EMAIL:
          Toast.error("유저 이름이 없습니다");
          break;
        case ALREADY_USED_SESSION:
          Toast.error("동시결제가 발생했습니다 다시 시도해 주세요.");
          break;
        default:
          Toast.error("결제중 오류가 발생했습니다. 잠시 후 시도해주세요.");
      }
    }
  }, [error]);

  const isAgree = agree && agree2;

  return (
    <SkeletonTheme baseColor={Color.Ac_Charcoal} highlightColor={Color.Ac_Gray0}>
      <Layout>
        <HeaderLayout>
          <Typography weight={TypoWeight.Bold}>
            <OrderTrackInforatmion />
          </Typography>
        </HeaderLayout>
        <Content>
          <CartItemsLayout>
            <CartTrackForm isLoadedCartItem={isLoadedCart} />
          </CartItemsLayout>
          <ReceiptLayout>
            <ReceiptItem>
              <Typography color={Color.Ac_Gray1} size={TypoSize.F15}>
                <AllCount />
              </Typography>
              {!isLoadedCart ? (
                <Skeleton width={30} />
              ) : (
                <CountText>
                  {cart?.count} <Pieces />
                </CountText>
              )}
            </ReceiptItem>
            <ReceiptItem>
              <Typography color={Color.Ac_Gray1} size={TypoSize.F15}>
                <TotalPaymentAmount />
              </Typography>
              {!isLoadedCart ? (
                <Skeleton width={100} />
              ) : (
                <CoinText>
                  <CoinIcon name="coin" />
                  {cart?.amount.toLocaleString()}
                </CoinText>
              )}
            </ReceiptItem>
          </ReceiptLayout>
        </Content>
        <ValidateContainer>
          <Validate onClick={() => setAgree(agree => !agree)}>
            <CheckboxIcon name={agree ? `checkbox-fill` : `checkbox`} />
            <Typography size={TypoSize.F13} color={agree ? Color.AC_Carrot : Color.Ac_Gray0}>
              구매하려는 상품이 맞습니다.
            </Typography>
          </Validate>
          <Validate onClick={() => setAgree2(agree2 => !agree2)}>
            <CheckboxIcon name={agree2 ? `checkbox-fill` : `checkbox`} />
            <Typography size={TypoSize.F13} color={agree2 ? Color.AC_Carrot : Color.Ac_Gray0}>
              BGM서비스 이용정책을 모두 확인하였으며, 구매합니다.
            </Typography>
          </Validate>
        </ValidateContainer>
        <Footer>
          <Button color={ButtonColor.PrimaryNotFill} onClick={trytToChargePoint}>
            <PointChargeText />
          </Button>
          {loading && (
            <Button color={ButtonColor.Disable}>
              <Loading name="loading" />
            </Button>
          )}
          {!loading && (
            <Button
              color={!cart?.count || !isAgree ? ButtonColor.Disable : ButtonColor.Primary}
              disabled={!cart?.count || !isAgree}
              onClick={tryToPurchaseCart}
            >
              <Purchase />
            </Button>
          )}
        </Footer>
      </Layout>
    </SkeletonTheme>
  );
};

const Layout = styled.div`
  background-color: ${Color.Ac_Shadow};
  border-radius: 1rem;

  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${DESKTOP_MODE} {
    max-width: 400px;
  }
  @media ${WIDE_MODE} {
    max-width: 500px;
  }
`;

const HeaderLayout = styled.div`
  padding: 1.5rem;
`;

const Content = styled.div`
  padding: 1.5rem;
  border-bottom: 1px solid ${Color.Ac_Gray};
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CoinText = styled(Typography)`
  display: flex;
  align-items: center;
  font-weight: bold;
`;

const ReceiptLayout = styled.div``;
const ReceiptItem = styled.div`
  margin-bottom: 1.5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const CartItemsLayout = styled.div`
  width: 100%;
  background-color: ${Color.Ac_Shadow};
  margin-bottom: 2rem;
`;

const Footer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;

  padding: 1.5rem;
  button {
    width: 100%;
    border-radius: 1rem;
    font-weight: bold;
    font-size: 0.8rem;
    &:first-child {
      width: min-content;
      margin-right: 1rem;
    }
  }
`;

const Loading = styled(Icon)`
  width: 1rem;
  height: 1rem;
`;

const ValidateContainer = styled.div`
  border-bottom: 1px solid ${Color.Ac_Gray};
  padding: 1.5rem;
`;

const Validate = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
  user-select: none;
`;

const CheckboxIcon = styled(Icon)`
  width: 1rem;
  height: 1rem;
  margin-right: 0.4rem;

  rect {
    stroke: ${Color.Ac_Gray0};
  }
`;

const CoinIcon = styled(Icon)`
  width: 1.8rem;
  height: 1.8rem;
  margin-left: 0.25rem;
`;

const CountText = styled(Typography)`
  display: flex;
`;
