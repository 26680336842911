import { AdminTemplate } from "App/Templates/AdminTemplate";
import styled from "styled-components";
import { Form } from "./Form";
import { useProductPriceDetail } from "./hooks/useProductPriceDetail";

export const PriceEdit = () => {
  const { isLoaded } = useProductPriceDetail();
  return (
    <AdminTemplate>
      <Layout>{isLoaded && <Form />}</Layout>
    </AdminTemplate>
  );
};

const Layout = styled.div``;
