import { GraphqlType } from "Constants/GraphqlType";
import { Genre, GenreWhereInput } from "GraphQL/Schema";
import { gql, useQuery } from "Lib/apollo-client";

const LOAD_GENRE = gql`
  query LOAD_CACHE_GENRE($first: Int, $where: GenreWhereInput) {
    genre(where: $where, first: $first, group: true) {
      genre_id
      type_kind
      name
      weight
      genre_title(where: { type_genre_title: "name" }, orderBy: exposure_order__ASC) {
        value
        language_code
      }
      genre_self_relation_child {
        genre_self_relation_parent {
          genre_id
          weight
          type_kind
          name
          genre_title(where: { type_genre_title: "name" }, orderBy: exposure_order__ASC) {
            value
            language_code
          }
        }
      }
    }
  }
`;

type Result = {
  readonly genre: Genre[];
};

type Args = {
  readonly first?: number;
  readonly where?: GenreWhereInput;
};

export const useLoadGenre = ({ first, where }: Args) => {
  const { data, loading } = useQuery<Result, Args>(LOAD_GENRE, {
    variables: {
      first,
      where
    },
    context: {
      serviceName: GraphqlType.Cache
    }
  });

  return { loading, data };
};
