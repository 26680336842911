import { HOME } from "Constants/Routes";
import { Navigate } from "react-router-dom";
import { useAuth } from "Utils/useAuth";

export const UnnecessaryAuth = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth();
  const isSignedIn = auth.token !== null;
  if (isSignedIn) {
    return <Navigate to={HOME} />;
  }
  return children;
};
