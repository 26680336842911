import { Typography, TypoSize } from "App/Atomics/Typography";
import { Color } from "Constants/Color";
import { ReactElement } from "react";
import styled from "styled-components";

type Props = {
  title: string;
  subtitle: string;
};

export const TermItem = ({ title, subtitle }: Props): ReactElement => {
  return (
    <Layout>
      <Text size={TypoSize.F13}>{title}</Text>
      <SubText dangerouslySetInnerHTML={{ __html: subtitle }} />
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  white-space: normal;
`;
const Text = styled(Typography)`
  & + & {
    margin-top: 4px;
  }
`;

const SubText = styled.div`
  font-size: 11px;
  color: ${Color.Ac_Gray1};
  a {
    text-decoration: underline;
  }
`;
