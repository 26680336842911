import { User } from "GraphQL/Schema";
import { gql, useMutation } from "Lib/apollo-client";

const CREATE_STAFF = gql`
  mutation CREATE_STAFF($email: String!, $password: String!, $name: String) {
    createStaff(payload: { email: $email, password: $password, name: $name }) {
      id
    }
  }
`;

type Result = {
  readonly createStaff: User;
};

type Args = {
  readonly email: string;
  readonly password: string;
  readonly name?: string;
};

export const useCreateStaff = () => {
  const [fetch, { loading, data, error }] = useMutation<Result, Args>(CREATE_STAFF);

  return { createStaff: fetch, loading, data, error };
};
