import React, { useMemo, useState } from "react";
import ReactSelect from "react-select/async";
import debounce from "debounce-promise";
import { identity } from "Utils/identity";
import { SelectComponents } from "react-select/src/components";
import { Option } from "react-select/src/filters";
import { FocusEventHandler, Styles } from "react-select";
import { Color } from "Constants/Color";

type Value = string;
type Label = string;

export type Info = Readonly<{
  id: Value;
  name: Label;
  type?: string;
  license?: string;
  path?: string;
  extra?: any;
}>;

type Props = Readonly<{
  style?: import("react-select/src/styles").Styles;
  components?: Partial<SelectComponents<any>>;
  className?: string;
  classNamePrefix?: string;
  isDisabled?: boolean;
  delay?: number;
  placeholder?: string;
  defaultValue?: null | Info;
  defaultOptions?: [] | Info[];
  isMulti?: boolean;
  autoFocus?: boolean;
  filterOption?: ((option: Option, rawInput: string) => boolean) | null | undefined;
  isClearable?: boolean;
  loadInfoList: (keyword: string) => readonly Info[] | Promise<readonly Info[]>;
  onFocus?: FocusEventHandler;
  onChange?: (info: null | Info) => void;
  onBlur?: (info: null | Info) => void;
}>;

export const TextAutoComplete = ({
  style,
  components,
  className,
  classNamePrefix,
  isDisabled = false,
  defaultValue = null,
  defaultOptions,
  delay = 500,
  placeholder,
  autoFocus,
  loadInfoList: loadSuggestionInfo,
  filterOption,
  onFocus,
  onChange = identity,
  onBlur = identity,
  isMulti,
  isClearable
}: Props) => {
  const [info, setInfo] = useState(defaultValue);
  const createSuggestionInfoLoader = () =>
    debounce(
      async (keyword: string, callback: (infoList: readonly Info[]) => void) => Promise.resolve(loadSuggestionInfo(keyword)).then(callback),
      delay
    );
  const loadSuggestionInfoByKeyword = useMemo(createSuggestionInfoLoader, []);
  const change: ReactSelect<Info>["props"]["onChange"] = (nextInfo = []) => {
    setInfo(nextInfo as Info);
    onChange(nextInfo as Info);
  };
  const blur = () => onBlur(info);
  return (
    <ReactSelect
      styles={{ ...styles, ...style }}
      className={className}
      classNamePrefix={classNamePrefix}
      menuPosition={"fixed"}
      isDisabled={isDisabled}
      autoFocus={autoFocus}
      cacheOptions={true}
      filterOption={filterOption}
      defaultValue={defaultValue}
      defaultOptions={defaultOptions}
      getOptionLabel={info => {
        return info.type ? `${info.name} (${info.type})` : info.name;
      }}
      getOptionValue={info => info.id}
      isMulti={isMulti}
      components={{ ...components }}
      placeholder={placeholder}
      loadOptions={loadSuggestionInfoByKeyword}
      onFocus={onFocus}
      onChange={change}
      onBlur={blur}
      isClearable={isClearable}
    />
  );
};

const styles: Styles = {
  control: (base, state) => ({
    ...base,
    boxShadow: "#ddd",
    borderColor: state.isFocused ? Color.Ac_Gray6 : "#cdcdcd",
    ":hover": {
      borderColor: Color.Ac_Gray6
    }
  }),
  option: (styles, { isSelected, isFocused }) => ({
    ...styles,
    color: isSelected && Color.Ac_White,
    backgroundColor: (isSelected || isFocused) && "rgba(0,0,0,0.5)",
    ":hover": {
      backgroundColor: "rgba(0,0,0,.2)"
    }
  })
};
