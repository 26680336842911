import styled from "styled-components";
import { MyCurationListForm } from "./MyCurationListForm";
import { Header } from "./Header";

export const MyCuration = () => {
  return (
    <Layout>
      <Header />
      <MyCurationListForm />
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
  min-height: 100vh;
`;
