import { Button, ButtonColor } from "App/Atomics/Button";
import { RoleTagItem } from "App/Features/Items/TagItem";
import { useArtistNode } from "App/Store/ArtistNodeStore";
import { useTrackNodeStore } from "App/Store/TrackNodeStore";
import { Color } from "Constants/Color";
import { AllPlay } from "Constants/Locale";
import { DESKTOP_MODE } from "Constants/Size";
import { ArtistID } from "Models/ArtistModel";
import styled from "styled-components";
import { Player } from "Utils/artistscardPlayer";

type Props = {
  readonly id: ArtistID;
};

export const ArtistInformation = ({ id }: Props) => {
  const aritst = useArtistNode(id);

  console.log(aritst);
  const trackList = useTrackNodeStore(store => store.filter(({ artist }) => artist.raw === id.raw).map(({ id }) => id));
  const onPlay = () => {
    if (!trackList) {
      return;
    }
    Player.insertListToLast(trackList);
    Player.current(trackList[0]);
  };

  return (
    <Layout>
      <Artist>{aritst?.name}</Artist>
      <TagList>
        {aritst?.roleList.map(id => (
          <RoleTagItem id={id} key={id.key} />
        ))}
      </TagList>
      <PlayButton color={ButtonColor.Primary} onClick={onPlay}>
        <ButtonText>
          <AllPlay />
        </ButtonText>
      </PlayButton>
    </Layout>
  );
};

const Layout = styled.div`
  position: relative;
  width: 100%;
  min-height: 500px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Artist = styled.h2`
  font-size: 2rem;
  @media ${DESKTOP_MODE} {
    font-size: 3.5rem;
  }

  margin: 2rem 0;
  text-shadow: 0 0 50px ${Color.Ac_Black};
  text-align: center;
  white-space: normal;
`;

const ButtonText = styled.span`
  font-size: 0.75rem;

  line-height: 1;
  font-weight: bold;
`;

const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 700px;
  margin-bottom: 2rem;
`;

const PlayButton = styled(Button)`
  height: 35px;
  border-radius: 1.5rem;

  border: none;
`;
