import { UserNodeStore } from "App/Store/UserNodeStore";
import { User } from "GraphQL/Schema";
import { UserID, UserModel } from "Models/UserModel";

export const setUser = (userList: User[]) => {
  for (const user of userList) {
    const { id, name, email, company, user_wallet } = user;
    const balance = user_wallet?.balance ?? 0;

    const userModel = UserModel.form({ balance, company: company ?? "-", email, id, name: name ?? "-" });
    UserNodeStore.set(new UserID(id), userModel);
  }
};
