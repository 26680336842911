import { LOAD_LICENSE } from "GraphQL/Queries/useLoadLicense";
import { CreateLicenseInput, License } from "GraphQL/Schema";
import { gql, useMutation } from "Lib/apollo-client";

const CREATE_LICENSE = gql`
  mutation CREATE_LICENSE($input: CreateLicenseInput) {
    createLicense: create_license(input: $input) {
      id
    }
  }
`;

type Result = {
  readonly createLicense: License;
};

type Args = {
  readonly input: CreateLicenseInput;
};

export const useCreateLicense = () => {
  const [fetch, { loading, error }] = useMutation<Result, Args>(CREATE_LICENSE, {
    refetchQueries: [LOAD_LICENSE]
  });

  return { createLicense: fetch, loading, error };
};
