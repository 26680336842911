import { Dropdown, DropdownList } from "App/Atomics/Dropdown";
import { Icon } from "App/Atomics/Icon";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { useAccountSelector } from "App/Store/AccountStore";
import { useSignOut } from "Utils/useSignout";
import { Color } from "Constants/Color";
import { AccountInfoManage, AccountLogout, Library, Point } from "Constants/Locale";
import { ACCOUNT, ADMIN_HOME, HOME, LIBRARY_PURCHASE_HISTORY, POINT_CHARGE } from "Constants/Routes";
import { ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAuth } from "Utils/useAuth";
import { AccountType } from "Models/AccountModel/AccountType";

type Props = {
  readonly color?: string;
};

export const HeaderUserInfo = ({ color = Color.Ac_White }: Props): ReactElement => {
  const [isShowDropdown, setIsShowDropdown] = useState(false);
  const { email, displayName } = useAccountSelector(store => store.current);
  const wallet = useAccountSelector(store => store.wallet);
  const auth = useAuth();
  const isAdmin = auth.type === AccountType.Admin;
  const navigate = useNavigate();
  const signOut = useSignOut();

  const moveToHistory = () => navigate(LIBRARY_PURCHASE_HISTORY);
  const signOutAndGoSignIn = () => {
    signOut();
    navigate(HOME);
  };

  const moveToAdminHome = () => {
    navigate(ADMIN_HOME);
  };

  return (
    <Dropdown.Layout
      trigger="mouseenter focus"
      placement={"bottom-start"}
      onShow={() => setIsShowDropdown(true)}
      onHide={() => setIsShowDropdown(false)}
      content={
        <MenuWrap>
          <TopProfile>
            <Info>
              <Typography color={Color.Ac_Black} style={{ overflow: "hidden", textOverflow: "ellipsis" }} weight={TypoWeight.Bold}>
                {email}
              </Typography>
            </Info>
          </TopProfile>
          <Bar />
          <MenuItem onClick={() => navigate(POINT_CHARGE)}>
            <MenuIcon fill={Color.Ac_Black} className="menu-icon" name="coin" />
            <Typography color={Color.Ac_Black} className="menu-item" size={TypoSize.F13}>
              {wallet?.balance.toLocaleString()} <Point />
            </Typography>
          </MenuItem>
          <MenuItem onClick={moveToHistory}>
            <MenuIcon fill={Color.Ac_Black} className="menu-icon" name="library" />
            <Typography color={Color.Ac_Black} className="menu-item" size={TypoSize.F13}>
              <Library />
            </Typography>
          </MenuItem>
          <MenuItem onClick={() => navigate(ACCOUNT)}>
            <MenuIcon fill={Color.Ac_Black} className="menu-icon" name="my" />
            <Typography color={Color.Ac_Black} className="menu-item" size={TypoSize.F13}>
              <AccountInfoManage />
            </Typography>
          </MenuItem>

          <MenuItem onClick={signOutAndGoSignIn}>
            <MenuIcon fill={Color.Ac_Black} className="menu-icon" name="logout" />
            <Typography color={Color.Ac_Black} className="menu-item" size={TypoSize.F13}>
              <AccountLogout />
            </Typography>
          </MenuItem>
          {isAdmin && (
            <MenuItem onClick={moveToAdminHome}>
              <MenuIcon fill={Color.Ac_Black} className="menu-icon" name="library" />
              <Typography color={Color.Ac_Black} className="menu-item" size={TypoSize.F13}>
                관리자 사이트
              </Typography>
            </MenuItem>
          )}
        </MenuWrap>
      }
    >
      <Layout>
        <UserAvatar>{displayName ? displayName.text("ko") : null}</UserAvatar>
        <DropdownIcon name="dropdown-down" className={isShowDropdown ? "dropdown-show" : ""} fill={color} />
      </Layout>
    </Dropdown.Layout>
  );
};

const Layout = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

const UserAvatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MenuWrap = styled(DropdownList)`
  padding: 20px 15px 15px;
  background-color: ${Color.Ac_White};
  overflow: hidden;
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  & + & {
    margin-top: 15px;
  }

  &:hover {
    .menu-item {
      color: ${Color.AC_Carrot} !important;
    }
    .menu-icon {
      fill: ${Color.AC_Carrot} !important;
    }
  }
`;

const MenuIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  margin-right: 8px;
`;

const TopProfile = styled.div`
  display: flex;
  align-items: center;
`;

const Info = styled.div`
  width: 120px;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  overflow: hidden;
`;

const Bar = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${Color.Ac_Gray4};
  margin: 10px 0;
`;

const DropdownIcon = styled(Icon)`
  transition: transform 0.2s ease-in-out;
  &.dropdown-show {
    transform: rotate(-180deg);
  }

  path {
    fill: ${Color.Ac_Black};
  }
`;
