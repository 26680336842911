import { HOME } from "Constants/Routes";
import { AccountType } from "Models/AccountModel/AccountType";
import { Navigate, Outlet } from "react-router-dom";

import { useAuth } from "Utils/useAuth";

export const CheckAdmin = () => {
  const auth = useAuth();
  const isAdmin = auth.type === AccountType.Admin;

  if (!isAdmin) {
    return <Navigate to={HOME} />;
  }

  return <Outlet />;
};
