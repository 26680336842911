import { Button, ButtonColor, ButtonSize } from "App/Atomics/Button";
import { Icon } from "App/Atomics/Icon";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { Color } from "Constants/Color";
import { ADMIN_PRICE } from "Constants/Routes";
import { useCreatePrice } from "GraphQL/Mutations/useCreatePrice";
import { ProductPriceType, TypeMetadataSubclass } from "GraphQL/Schema";
import { Toast } from "Lib/toast";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { SelectStyle } from "../../CechargePoint/Style";
import { Style } from "../Style";

const productPriceOption = [
  { id: ProductPriceType.Curation, name: "큐레이션" },
  { id: ProductPriceType.Track, name: "트랙" },
  { id: ProductPriceType.Mp3, name: "mp3" },
  { id: ProductPriceType.Wav, name: "wav" }
];

const metadataSubClassOption = [
  { id: TypeMetadataSubclass.Bgm, name: "BGM" },
  { id: TypeMetadataSubclass.Effect, name: "EFFECT" }
];

export const Form = () => {
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [type, setType] = useState<ProductPriceType>(ProductPriceType.Track);
  const [target, setTarget] = useState<TypeMetadataSubclass>(TypeMetadataSubclass.Bgm);
  const [price, setPrice] = useState<number>(0);

  const isDisable = !title.trim() || !title.trim();
  const { createPrice, data, error, loading } = useCreatePrice();
  const navigate = useNavigate();

  const tryToCreateStaff = () => {
    if (!title.trim()) {
      Toast.warning("제목을 입력해주세요.");
      return;
    }
    createPrice({
      variables: {
        title,
        type,
        price,
        target,
        description
      }
    });
  };

  useEffect(() => {
    if (data) {
      navigate(ADMIN_PRICE);
    }
  }, [data]);

  return (
    <Layout>
      <Typography weight={TypoWeight.Bold} color={Color.Ac_White} size={TypoSize.F24}>
        금액을 생성을 위해 아래 정보를 입력해주세요.
      </Typography>
      {error && (
        <ErrorBox>
          <Typography size={TypoSize.F13} weight={TypoWeight.Bold} color={Color.Ac_Red1}>
            {error.graphQLErrors[0].message}
          </Typography>
        </ErrorBox>
      )}

      <Container>
        <Style.Row>
          <Style.Caption>제목*</Style.Caption>
          <Style.SubCaption>금액 제목입니다</Style.SubCaption>
          <Style.InputText placeholder={"제목"} onChange={value => setTitle(value)} value={title} />
        </Style.Row>
        <Style.Row>
          <Style.Caption>금액*</Style.Caption>
          <Style.SubCaption>금액을 입력해 주세요</Style.SubCaption>
          <Style.InputText
            placeholder={"충전금액"}
            type="text"
            onChange={value => {
              const price = +value.split(",").join("");
              if (!isNaN(price)) {
                setPrice(price);
              }
            }}
            value={price.toLocaleString()}
          />
        </Style.Row>
        <Style.Row>
          <Style.Caption>종류</Style.Caption>
          <Style.SubCaption>금액의 대한 종류를 선택해 주세요</Style.SubCaption>
          <Style.Select
            isDisabled={false}
            style={SelectStyle}
            optionList={metadataSubClassOption}
            placeholder="종류"
            onChange={info => info && setTarget(info.id as TypeMetadataSubclass)}
          />
        </Style.Row>
        <Style.Row>
          <Style.Caption>타입</Style.Caption>
          <Style.SubCaption>금액의 대한 타입을 선택해 주세요</Style.SubCaption>
          <Style.Select
            isDisabled={false}
            style={SelectStyle}
            optionList={productPriceOption}
            placeholder="타입"
            onChange={info => info && setType(info.id as ProductPriceType)}
          />
        </Style.Row>
        <Style.Row>
          <Style.Caption>설명</Style.Caption>
          <Style.SubCaption>관리자용 메모 입니다</Style.SubCaption>
          <Style.TextAreaText placeholder={"설명"} onChange={value => setDescription(value)} value={description} />
        </Style.Row>
        <ButtonGroup>
          {!loading && (
            <SubmitButton
              size={ButtonSize.Small}
              color={isDisable ? ButtonColor.Disable : ButtonColor.Primary}
              disabled={isDisable}
              onClick={tryToCreateStaff}
            >
              생성
            </SubmitButton>
          )}
          {loading && (
            <SubmitButton size={ButtonSize.Small} color={ButtonColor.Disable} disabled>
              <Loading name="loading" />
            </SubmitButton>
          )}
        </ButtonGroup>
      </Container>
    </Layout>
  );
};

const Layout = styled.div`
  max-width: 500px;
  width: 100%;
  margin: 4rem auto;
`;

const Container = styled.div`
  margin-top: 2rem;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
`;
const SubmitButton = styled(Button)`
  width: 100px;
`;

const Loading = styled(Icon)`
  width: 1.25rem;
  height: 1.25rem;
`;

const ErrorBox = styled.div`
  width: 100%;
  padding: 1rem;
  background-color: ${Color.Ac_Shadow};
  border-radius: 1rem;
  border: 2px solid ${Color.AC_Carrot};
  display: flex;
  flex-direction: column;
  white-space: normal;
  margin: 1rem 0;
`;
