import { gql, useQuery } from "@apollo/client";

import { GraphqlType } from "Constants/GraphqlType";
import { Grade, GradeWhereInput } from "GraphQL/Schema";

export const LOAD_CACHE_GRADE = gql`
  query LOAD_CACHE_GRADE($where: GradeWhereInput) {
    grade(where: $where) {
      id
      grade
      created_at
      updated_at
      product_price_id
      product_price {
        id
        price
        title
        description
        created_at
        is_approve
        type
        updated_at
      }
    }
  }
`;

type Result = {
  readonly grade: Grade[];
};

type Args = {
  readonly where?: GradeWhereInput;
};

export const useLoadCacheGrade = ({ where }: Args) => {
  const { data, loading } = useQuery<Result, Args>(LOAD_CACHE_GRADE, {
    variables: {
      where
    },
    context: {
      serviceName: GraphqlType.Cache
    }
  });
  return { loading, data };
};
