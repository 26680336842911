import { RoleNodeStore } from "App/Store/RoleNodeStore";
import { Role } from "GraphQL/Schema";
import { MetdataKind } from "Models/GenreModel/GenreModel";
import { RoleID, RoleModel } from "Models/RoleModel";

export const setRole = (roleList: Role[], kind: MetdataKind) => {
  for (const role of roleList) {
    const roleId = new RoleID(role.role_id);
    const roleNode = RoleNodeStore.get(roleId);

    if (roleNode) {
      RoleNodeStore.update(roleId, node => {
        node.kind = [...new Set([...node.kind, kind])] as MetdataKind[];
      });
    } else {
      const roleM = RoleModel.form({
        id: role.role_id,
        kind: [kind],
        title: role.role_title?.map(({ language_code, value }) => ({ languageCode: language_code!, value })) ?? []
      });
      RoleNodeStore.set(new RoleID(role.role_id), roleM);
    }
  }
};
