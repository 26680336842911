import { negotiateLanguages } from "@fluent/langneg";
import { LANGUAGE_INFO } from "Constants/StorageKey";
import { Cookie } from "Lib/cookie";
import { LanguageClient } from "Utils/clients";
import { loadLocale, LocaleItem } from "./loadLocale";

type Item = {
  readonly currentLanguage: string;
  readonly fallbackLanguage: string;
  readonly supportLanguageList: string[];
  readonly locales: LocaleItem[];
};

export type LocaleInformation = Item;

export const loadLocaleInformation = async (): Promise<Item | null> => {
  try {
    const { default_language: fallbackLanguage, list } = await LanguageClient.get("index.json");
    const supportLanguageList: string[] = list.map(({ language }: any) => language);
    const cookieLanguage = getCookieLanguage(supportLanguageList);
    const searchLanguage = getSearchLanguage(supportLanguageList);
    const browserLanguage = window.navigator.language;
    const currentLanguage =
      negotiateLanguages([searchLanguage ?? cookieLanguage ?? browserLanguage], supportLanguageList)[0] ?? fallbackLanguage;
    const locales = await Promise.all(
      [currentLanguage, currentLanguage !== fallbackLanguage && fallbackLanguage].filter(Boolean).map(loadLocale)
    );
    return { currentLanguage, fallbackLanguage, supportLanguageList, locales };
  } catch (error) {
    return null;
  }
};

const getCookieLanguage = (supportLanguageList: string[]): null | string => {
  const language = Cookie.get(LANGUAGE_INFO)!;
  return supportLanguageList.find(supportLanguage => supportLanguage === language) ?? null;
};

const getSearchLanguage = (supportLanguageList: string[]): null | string => {
  const urlSearchParams = new window.URLSearchParams(window.location.search);
  const language = urlSearchParams.get("lang");
  return supportLanguageList.find(supportLanguage => supportLanguage === language) ?? null;
};
