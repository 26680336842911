import { gql, useMutation } from "Lib/apollo-client";

const CECHARGE_POINT = gql`
  mutation CECHARGE_POINT($targetUserId: Int!, $amount: Int!) {
    deposit_point_by_admin(target_user_id: $targetUserId, amount: $amount)
  }
`;

type Result = {
  readonly cechargePoint: boolean;
};

type Args = {
  readonly targetUserId: number;
  readonly amount: number;
};

export const useCechargePoint = () => {
  const [fetch, { loading, data, error }] = useMutation<Result, Args>(CECHARGE_POINT);

  return { cechargePoint: fetch, loading, data, error };
};
