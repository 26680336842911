import { configs } from "Config";
import { JwtTokenSet } from "GraphQL/Schema";
import { createHttpLink, gql, setContext, ApolloClient, InMemoryCache } from "Lib/apollo-client";

const REFRESH = gql`
  query REFRESH {
    accessTokenRefresh {
      access_token
      refresh_token
      iat
      exp
    }
  }
`;

type Result = {
  readonly accessTokenRefresh: JwtTokenSet;
};

export const refresh = async (refreshToken: string) => {
  const httpLink = createHttpLink({
    uri: configs.urls.service
  });
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${refreshToken}`
      }
    };
  });
  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  });
  const { data, error } = await client.query<Result>({ query: REFRESH });

  return { data, error };
};
