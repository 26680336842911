import { ButtonColor, ButtonSize } from "App/Atomics/Button";
import { Checkbox } from "App/Atomics/Checkbox";
import { LinkTarget } from "App/Atomics/ExternalLink";
import { InternalLink } from "App/Atomics/InternalLink";
import { Color } from "Constants/Color";
import {
  AccountEmailTitle,
  AccountName,
  AccountNamePlaceholder,
  AccountRegister,
  Password,
  SettingAge,
  SettingPrivacyPolicyAgree,
  SettingTermsOfService,
  SignupError
} from "Constants/Locale";
import { PRIVACY_POLICY, TERM_OF_SERVICE } from "Constants/Routes";
import { Toast } from "Lib/toast";
import { FormEvent, useMemo, useState } from "react";
import styled from "styled-components";
import { AuthForm } from "../style";
import { useSignUp } from "./useSignUp";

export const SignUpForm = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [name, setName] = useState<string>("");

  const [isTermsofService, setIsTermsofService] = useState(false);
  const [isPrivacyPolicy, setIsPrivacyPolicy] = useState(false);
  const [isAge, setIsAge] = useState(false);

  const isAllowButton = useMemo(
    () => !!(email.length && password.length && name.length && isTermsofService && isPrivacyPolicy && isAge),
    [email, password, name, isTermsofService, isPrivacyPolicy, isAge]
  );
  const { signUp, isLoaded } = useSignUp();

  const tryToSignUp = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    try {
      await signUp(name, email, password, isTermsofService, isPrivacyPolicy, isAge);
    } catch (error) {
      Toast.error(SignupError.message());
    }
  };

  return (
    <AuthForm.Layout onSubmit={tryToSignUp}>
      <AuthForm.LabelBox>
        <AuthForm.Label>
          <AccountEmailTitle />
        </AuthForm.Label>
        <AuthForm.Input onChange={setEmail} type="email" placeholder={AccountEmailTitle.message()} />
      </AuthForm.LabelBox>
      <AuthForm.LabelBox>
        <AuthForm.Label>
          <AccountName />
        </AuthForm.Label>
        <AuthForm.Input onChange={setName} type="text" placeholder={AccountNamePlaceholder.message()} maxLength={20} />
      </AuthForm.LabelBox>
      <AuthForm.LabelBox>
        <AuthForm.Label>
          <Password />
        </AuthForm.Label>
        <AuthForm.Input onChange={setPassword} type="password" placeholder={Password.message()} autoComplete="new-password" />
      </AuthForm.LabelBox>
      <Agreement>
        <InputCheck isChecked={isTermsofService} onToggle={() => setIsTermsofService(prev => !prev)} id={"isTermsofService"} />
        <SettingTermsOfService
          b={({ children }) => (
            <UnderlineLink to={TERM_OF_SERVICE} target={LinkTarget.Blank}>
              {children}
            </UnderlineLink>
          )}
        />
      </Agreement>
      <Agreement>
        <InputCheck isChecked={isPrivacyPolicy} onToggle={() => setIsPrivacyPolicy(prev => !prev)} id={"isPrivacyPolicy"} />
        <SettingPrivacyPolicyAgree
          c={({ children }) => (
            <UnderlineLink to={PRIVACY_POLICY} target={LinkTarget.Blank}>
              {children}
            </UnderlineLink>
          )}
        />
      </Agreement>
      <Agreement>
        <InputCheck isChecked={isAge} onToggle={() => setIsAge(prev => !prev)} id={"isAge"} />
        <SettingAge />
      </Agreement>
      <AuthForm.ButtonGroup>
        <AuthForm.Button
          disabled={!isAllowButton || !isLoaded}
          type="submit"
          size={ButtonSize.Small}
          color={!isAllowButton || !isLoaded ? ButtonColor.Disable : ButtonColor.Primary}
        >
          {!isLoaded ? <AuthForm.Icon name="loading" /> : <AccountRegister />}
        </AuthForm.Button>
      </AuthForm.ButtonGroup>
    </AuthForm.Layout>
  );
};

const Agreement = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 10px;
  user-select: none;
  color: ${Color.Ac_Gray1};
  font-size: 12px;
`;

const InputCheck = styled(Checkbox)`
  margin-left: 2px;
  margin-right: 4px;
`;

const UnderlineLink = styled(InternalLink)`
  margin: 0 2px;
  cursor: pointer;
  text-decoration: underline;
  color: ${Color.Ac_Gray1};
  font-size: 12px;
  &:hover {
    color: ${Color.Ac_Gray0};
  }
`;
