import { useCurationManagementNodeStore } from "App/Store/CurationManagementStore";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Section, useCurationUserSelector } from "../Store/CurationUserStore";
import { CurationManagementDetail } from "./CurationManagementDetail";
import { First } from "./First";
import { Second } from "./Second";
import { Third } from "./Third";

export const Form = () => {
  const { id } = useParams();
  const { section } = useCurationUserSelector(store => store);
  const curationManagement = useCurationManagementNodeStore(store =>
    store.values().find(curation => curation.playlist.raw === +id! && !curation.isOwner)
  );

  return (
    <Layout>
      {!curationManagement && (
        <>
          {section === Section.First && <First />}
          {section === Section.Second && <Second />}
          {section === Section.Third && <Third />}
        </>
      )}
      {curationManagement && <CurationManagementDetail id={curationManagement.id} />}
    </Layout>
  );
};

const Layout = styled.div`
  max-width: 500px;
  width: 100%;
  margin: 4rem auto;
`;
