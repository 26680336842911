import { Button, ButtonColor, ButtonSize } from "App/Atomics/Button";
import { Input } from "App/Atomics/Input";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { LicenseDocument } from "App/Features/Document/LicenseDocument";
import { AccountAction, AccountStore, useAccountSelector } from "App/Store/AccountStore";
import { useLicenseNodeStore } from "App/Store/LicenseNodeStore";
import { ModalAction, ModalProps, ModalStore } from "App/Store/ModalStore";
import { Color } from "Constants/Color";
import { Size } from "Constants/Size";
import { updateUserInfo } from "GraphQL/Mutations/updateUserInfo";
import { useCreateLicense } from "GraphQL/Mutations/useCreateLicense";
import { Modal } from "Lib/modal";
import { Toast } from "Lib/toast";
import { TrackID } from "Models/TrackModel";
import { useEffect, useRef } from "react";
import styled from "styled-components";
import { useAuth } from "Utils/useAuth";
import { useLicense } from "./hooks/useLicense";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { DEFAULT_LANGUAGE_CODE, LocalizationModel } from "Models/LocalizationModel";
import {
  AccountNameErrorNull,
  CheckLicenseInformation,
  CompanyName,
  CompanyNameErrorNull,
  Completion,
  Download,
  ErrorNetworkMessage,
  ExistLicense,
  LicenseDownloadDescription,
  LicenseFooterDescription1,
  LicenseFooterDescription2,
  LicenseFooterDescription3,
  LicenseInforatmionDesciprtion
} from "Constants/Locale";

import { AccountName } from "Constants/Locale";

type Props = {
  readonly isOpen: boolean;
  readonly props: ModalProps;
};

const ERROR_ALREAD_LICENSE = "target license is already exist";

export const LicenseModal = ({ isOpen, props }: Props) => {
  const trackId = props.license.track as TrackID;
  const { isLoaded } = useLicense(trackId.raw);

  const displayNameRef = useRef<HTMLInputElement>(null);
  const companyRef = useRef<HTMLInputElement>(null);

  const { displayName, company } = useAccountSelector(store => store.current);
  const account = useAccountSelector(store => store.current);

  const onRequestClose = () => ModalStore.dispatch(ModalAction.closeAll());
  const auth = useAuth();

  const license = useLicenseNodeStore(store => store.filter(store => store.trackId.raw === trackId.raw))[0];

  const { createLicense, error } = useCreateLicense();

  const onUpdateUser = async (): Promise<boolean> => {
    const name = displayNameRef.current?.value ?? "";
    const companyValue = companyRef.current?.value ?? "";
    if (name === displayName.text() && (companyValue === company ?? "")) {
      return true;
    }
    try {
      if (name.length) {
        await updateUserInfo({ token: auth.token?.access_token!, name, company: companyValue });
        const newAccount = {
          ...account,
          displayName: name ? LocalizationModel.from([{ languageCode: DEFAULT_LANGUAGE_CODE, value: name }]) : account.displayName,
          company: companyValue
        };
        AccountStore.dispatch(AccountAction.current(newAccount));
        return true;
      } else {
        throw new Error();
      }
    } catch (err) {
      Toast.error(ErrorNetworkMessage.message());
      return false;
    }
  };

  const onCreateLicense = async () => {
    const name = displayNameRef.current?.value ?? "";
    const companyValue = companyRef.current?.value ?? "";
    try {
      await createLicense({
        variables: {
          input: {
            track_id: trackId.raw,
            name: name,
            company: companyValue
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const updateuserAndLicense = async () => {
    try {
      const isSuccess = await onUpdateUser();
      if (isSuccess) {
        await onCreateLicense();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (error) {
      if (error?.graphQLErrors[0].message === ERROR_ALREAD_LICENSE) {
        console.log(ExistLicense.message());
      }
    }
  }, [error]);

  if (!isLoaded) {
    return null;
  }

  if (license) {
    return (
      <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
        <DownloadLayout>
          <DescriptionText size={TypoSize.F13}>{LicenseDownloadDescription.message()}</DescriptionText>
          <PDFDownloadLink document={<LicenseDocument id={license.id} />}>
            {({ url, loading }) => {
              if (!loading && url) {
                // window.open(url);
              }
              return (
                <Button size={ButtonSize.Xsmall} color={ButtonColor.Primary}>
                  <Download />
                </Button>
              );
            }}
          </PDFDownloadLink>
        </DownloadLayout>
      </Modal>
    );
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Layout>
        <Container>
          <Title>
            <Typography color={Color.Ac_White} weight={TypoWeight.Bold} size={TypoSize.F24}>
              <CheckLicenseInformation />
            </Typography>
          </Title>
          <Description>
            <Typography color={Color.Ac_Gray1} size={TypoSize.F15}>
              <LicenseInforatmionDesciprtion />
            </Typography>
          </Description>
          <InputWrap>
            <Wrap>
              <Typography weight={TypoWeight.Bold} size={TypoSize.F14}>
                <AccountName />*
              </Typography>
              <InputText
                ref={displayNameRef}
                size={TypoSize.F14}
                weight={TypoWeight.Bold}
                placeholder={AccountNameErrorNull.message()}
                defaultValue={displayName.text()}
              />
            </Wrap>
            <Wrap>
              <Typography weight={TypoWeight.Bold} size={TypoSize.F14}>
                <CompanyName />
              </Typography>
              <InputText
                ref={companyRef}
                size={TypoSize.F14}
                weight={TypoWeight.Bold}
                placeholder={CompanyNameErrorNull.message()}
                defaultValue={company}
              />
            </Wrap>
          </InputWrap>
          <StyledButton size={ButtonSize.Medium} onClick={updateuserAndLicense}>
            <Completion />
          </StyledButton>
          <Typography color={Color.Ac_Gray0} size={TypoSize.F14}>
            - <LicenseFooterDescription1 />
          </Typography>
          <Typography color={Color.Ac_Gray0} size={TypoSize.F14}>
            - <LicenseFooterDescription2 />
          </Typography>
          <Typography color={Color.Ac_Gray0} size={TypoSize.F14}>
            - <LicenseFooterDescription3 />
          </Typography>
        </Container>
      </Layout>
    </Modal>
  );
};

const Layout = styled.div`
  max-width: 600px;
  width: 100%;
  padding: 20px;
  border-radius: 5px;
  background-color: ${Color.Ac_Shadow};
  pointer-events: auto;
  white-space: normal;
  word-wrap: break-word;
`;

const DownloadLayout = styled.div`
  padding: 20px;
  border-radius: 5px;
  background-color: ${Color.Ac_Shadow};
  pointer-events: auto;
  white-space: normal;
  word-wrap: break-word;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  overflow: hidden;
`;
const Title = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

const InputWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  flex-direction: column;
  margin-bottom: 20px;
`;

const Description = styled.p`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  white-space: normal;
`;

const InputText = styled(Input.Text)`
  width: 100%;
  border: 2.25px solid ${Color.GRAY_8};
  background-color: ${Color.GRAY_8};
  border-radius: 4px;
  padding: 0.5rem ${Size.PADDING_LARGE_PX};
  font-size: 0.75rem;
  transition: all 0.3s ease-in;
  color: ${Color.Ac_Gray4}!important;
  margin-right: 4px;
  margin-top: 0.5rem;
  &:hover {
    border-color: ${Color.GRAY_7};
  }

  input {
    color: ${Color.Ac_Gray4};
  }

  &::placeholder {
    color: ${Color.GRAY_4};
  }
  &:focus {
    border-color: ${Color.AC_Carrot};
    background-color: ${Color.Ac_Black};

    &::placeholder {
      color: ${Color.Ac_Black};
    }
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  &:first-child {
    margin-bottom: 1.5rem;
  }
`;
const StyledButton = styled(Button)`
  width: 100%;
  font-size: 14px;
  margin-bottom: 8px;

  &:first-child {
    margin-right: 1rem;
  }
`;

const DescriptionText = styled(Typography)`
  text-align: center;
  width: 100%;
  margin-right: 1.5rem;
`;
