import { setPlaylist } from "App/Common/setPlaylist";
import { MAX_FIRST_COUNT } from "Constants/Count";
import { useLoadPlaylist } from "GraphQL/Queries/useLoadPlaylist";
import { PlaylistOrderByInput, PlaylistType } from "GraphQL/Schema";
import { useEffect } from "react";
import { PlaylistModalAction, PlaylistModalStore } from "./Store";

export const usePlaylists = () => {
  const { data, loading } = useLoadPlaylist({
    first: MAX_FIRST_COUNT,
    where: {
      type__in: [PlaylistType.My, PlaylistType.Curation]
    },
    orderBy: [PlaylistOrderByInput.IdDesc]
  });

  useEffect(() => {
    if (data) {
      const { playlist } = data;
      const playlistMs = setPlaylist(playlist);
      PlaylistModalStore.dispatch(PlaylistModalAction.setPlaylist(playlistMs.map(({ id }) => id)));
    }
  }, [data]);

  useEffect(() => {
    PlaylistModalStore.dispatch(PlaylistModalAction.setIsLoadedPlaylist(!loading));
  }, [loading]);
};
