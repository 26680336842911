import { setPlaylistDetail } from "App/Common/setPlaylistDetail";
import { useLoadPlaylistDetail } from "GraphQL/Queries/useLoadPlaylistDetail";
import { PlaylistType } from "GraphQL/Schema";
import { useLoaded } from "Lib/use-loaded";
import { useEffect } from "react";
import { PlaylistDetailAction, PlaylistDetailStore } from "../Store";

interface Props {
  readonly id: number;
}

export const useMyPlaylistDetail = ({ id }: Props) => {
  const { data, loading } = useLoadPlaylistDetail({
    id,
    type: [PlaylistType.My, PlaylistType.Curation]
  });
  const { isLoaded } = useLoaded(loading);
  useEffect(() => {
    if (data) {
      const { playlist } = data;
      setPlaylistDetail(playlist);
    }
  }, [data]);

  useEffect(() => {
    PlaylistDetailStore.dispatch(PlaylistDetailAction.setIsLoadedPlaylist(isLoaded));
  }, [isLoaded]);
};
