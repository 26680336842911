import styled from "styled-components";
import { UserContent } from "./UserContent";
import { UserHeader } from "./UserHeader";

export const Form = () => {
  return (
    <Layout>
      <Scroll>
        <Table>
          <UserHeader />
          <UserContent />
        </Table>
      </Scroll>
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
  height: 100%;
`;

const Scroll = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;
