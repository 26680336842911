import { Icon } from "App/Atomics/Icon";
import { PurchaseHistoryItem } from "App/Features/Items/PurchaseHistoryItem";
import { NoPurchaseHistory } from "Constants/Locale";
import { Lottie } from "Lib/lottie";
import styled from "styled-components";
import { usePurchaseHistorySelector } from "../../Store";
import LoadingLottie from "Assets/Lottie/loading.json";

export const Content = () => {
  const { isLoadedPurchaseHistory, purchaseHistoryList } = usePurchaseHistorySelector(store => store);

  return (
    <Layout>
      {!isLoadedPurchaseHistory && (
        <LoadingContainer>
          <Lottie data={LoadingLottie} loop playing />
        </LoadingContainer>
      )}

      {isLoadedPurchaseHistory && !purchaseHistoryList.length && (
        <NotFoundContainer>
          <NotFoundIcon name="search" />
          <NotFound>
            <NoPurchaseHistory />
          </NotFound>
        </NotFoundContainer>
      )}
      {isLoadedPurchaseHistory && purchaseHistoryList.map(id => <PurchaseHistoryItem key={id.key} id={id} />)}
    </Layout>
  );
};

const Layout = styled.div``;
const NotFoundContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 10rem 0;
`;

const NotFound = styled.span`
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 1rem;
`;

const NotFoundIcon = styled(Icon)`
  width: 2.5em;
  height: 2.5rem;
`;
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 150px;
`;
