import { Time } from "Lib/time";

export const secondsToTime = (time: number) => {
  const h = Math.floor(time / 3600);
  const m = Math.floor((time - h * 3600) / 60);
  const s = time - h * 3600 - m * 60;
  const hours = `0${h}`.slice(-2);
  const minutes = `0${m}`.slice(-2);
  const seconds = `0${s}`.slice(-2);
  return h > 0 ? `${hours}:${minutes}:${seconds}` : `${minutes}:${seconds}`;
};

export const parseJwt = (token: string) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const formatDate = (time: Date | Time): string => Time(time).local().format("YYYY-MM-DD");
export const foramtNumberToPriceFormat = (price: number) => new Intl.NumberFormat().format(price);
