import { setTrackActivity } from "App/Common/setTrackActivity";
import { useAccountSelector } from "App/Store/AccountStore";
import { MAX_FIRST_COUNT } from "Constants/Count";
import { useLoadTrackActivity } from "GraphQL/Queries/useLoadTrackActivity";
import { useLoaded } from "Lib/use-loaded";
import { isGuestAccountModel } from "Models/AccountModel/AccountModel";
import { TrackID } from "Models/TrackModel";
import { useEffect } from "react";

type Props = {
  trackList: readonly TrackID[];
  isLoadedTrack?: boolean;
};

export const useTrackActivity = ({ trackList, isLoadedTrack = true }: Props) => {
  const isGuest = useAccountSelector(store => isGuestAccountModel(store.current));

  const skip = !isLoadedTrack || isGuest || !trackList.length;
  const { data, loading } = useLoadTrackActivity({
    first: MAX_FIRST_COUNT,
    skip,
    trackIds: trackList.map(({ raw }) => raw)
  });
  const { isLoaded } = useLoaded(loading);

  useEffect(() => {
    if (data) {
      setTrackActivity({ trackActivityList: data.trackActivity });
    }
  }, [data]);

  return { isLoaded };
};
