import { Reference } from "@apollo/client";
import { TrackActivityNodeStore } from "App/Store/TrackActivity";
import { RemoveResponse } from "GraphQL/Schema";
import { gql, useMutation } from "Lib/apollo-client";
import { TrackActivityID } from "Models/TrackActivity";

const UNLIKE_TRACK_ACTIVITY = gql`
  mutation UNLIKE_TRACK($trackId: Int!) {
    unlikeTrack: unlike_track(track_id: $trackId) {
      id
    }
  }
`;

interface Props {
  isReloadPlaylist?: boolean;
}

type Result = {
  readonly unlikeTrack: RemoveResponse;
};

type Args = {
  readonly trackId: number;
};

export const useUnlikeTrack = ({ isReloadPlaylist = true }: Props) => {
  const [fetch, { loading }] = useMutation<Result, Args>(UNLIKE_TRACK_ACTIVITY, {
    update(cache, { data }) {
      cache.modify({
        fields: {
          track_activity(existingTrackActivityRefs = [], { readField }) {
            TrackActivityNodeStore.remove(new TrackActivityID(data?.unlikeTrack?.id!));
            const trackActivityRefs = existingTrackActivityRefs.filter(
              (trackActivityRefs: Reference) => readField("id", trackActivityRefs) !== data?.unlikeTrack.id
            );
            return [...trackActivityRefs];
          },
          playlist(existingPlaylistRefs = [], { DELETE }) {
            if (!isReloadPlaylist) {
              return existingPlaylistRefs;
            }
            return DELETE;
          }
        }
      });
    }
  });

  return { unlikeTrack: fetch, loading };
};
