import {TagNode, isTagNode, isTextNode, isVariableNode, Node, TextNode} from './createNodeList';

export const optimizeNodeList = (nodeList: readonly Node[]): readonly Node[] => {
  const currentNodeList: Node[] = [];

  let cursor = 0;

  while (cursor < nodeList.length) {
    const node = nodeList[cursor];
    cursor += 1;
    if (isVariableNode(node)) {
      currentNodeList.push(node);
    } else if (isTagNode(node)) {
      const currentNode = new TagNode(node.name, node.attributes, optimizeNodeList(node.children));
      currentNodeList.push(currentNode);
    } else if (isTextNode(node)) {
      const lastNode = currentNodeList[currentNodeList.length - 1] ?? null;
      if (isTextNode(lastNode)) {
        currentNodeList.pop();
        currentNodeList.push(new TextNode(lastNode.text + node.text));
      } else {
        currentNodeList.push(node);
      }
    }
  }

  return currentNodeList;
};
