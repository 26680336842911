import styled from "styled-components";
import { PurchaseHistoryList } from "./PurchaseHistoryList";

export const PurchaseHistory = () => {
  return (
    <Layout>
      {/* <NavigationTab /> */}
      <PurchaseHistoryList />
    </Layout>
  );
};

const Layout = styled.div``;
