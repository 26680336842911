import styled from "styled-components";
import { Button } from "App/Atomics/Button";
import { Size } from "Constants/Size";
import { Color } from "Constants/Color";
import { Input } from "App/Atomics/Input";
import { Icon } from "App/Atomics/Icon";

const Layout = styled.form`
  display: flex;
  flex-direction: column;
`;

const LabelBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${Size.MARGIN_LARGE_PX} 0;
`;
const Label = styled.label`
  font-size: 0.8rem;
  margin-bottom: ${Size.MARGIN_SMALL_PX};
`;

const Description = styled.p`
  font-size: 0.725rem;
  color: ${Color.GRAY_4};
  margin-bottom: ${Size.MARGIN_SMALL_PX};
`;

const CustomInput = styled(Input.Text)`
  width: 100%;
  border: 2.25px solid ${Color.GRAY_8};
  background-color: ${Color.GRAY_8};
  border-radius: 4px;
  padding: 0.5rem ${Size.PADDING_LARGE_PX};
  font-size: 0.75rem;
  transition: all 0.3s ease-in;
  color: ${Color.Ac_Gray4}!important;

  &:hover {
    border-color: ${Color.GRAY_7};
  }

  input {
    color: ${Color.Ac_Gray4};
  }

  &::placeholder {
    color: ${Color.GRAY_4};
  }
  &:focus {
    border-color: ${Color.AC_Carrot};
    background-color: ${Color.Ac_Black};

    &::placeholder {
      color: ${Color.Ac_Black};
    }
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

const ButtonGroup = styled.div`
  margin: ${Size.MARGIN_X_LARGE_PX} 0;
  height: 1.6rem;
`;

const TextButton = styled.button`
  width: min-content;
  font-size: 0.7rem;
  text-align: left;
  color: ${Color.Ac_Apricot};
  &:hover {
    text-decoration: underline;
  }
`;

const ErrorBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  border: 2px solid ${Color.Ac_Red1};
  border-left: 4px solid ${Color.Ac_Red1};
  border-radius: 0.5rem;
  margin: 0.5rem 0;
`;

const ErrorText = styled.span`
  width: min-content;
  font-size: 0.7rem;
  text-align: left;
  color: ${Color.Ac_White};
  font-weight: bold;
  margin-left: 0.5rem;
`;

const SignButton = styled(Button)`
  width: 100%;
`;

const CustomIcon = styled(Icon)`
  width: 1rem;
  height: 1rem;
`;

const ErrorIcon = styled(Icon)`
  width: 1.25rem;
  height: 1.25rem;
  path {
    fill: ${Color.Ac_Red1};
  }
`;

export const AuthForm = {
  Layout,
  LabelBox,
  Label,
  Description,
  Input: CustomInput,
  ErrorText,
  ErrorBox,
  ButtonGroup,
  TextButton,
  Button: SignButton,
  Icon: CustomIcon,
  ErrorIcon
};
