import { ReactElement, useEffect } from "react";

export const BUY_FAIL_MESSAGE = "purchase-fail";

export const BuyFailRedirect = (): ReactElement => {
  useEffect(() => {
    const data = {
      service: BUY_FAIL_MESSAGE
    };
    window.opener.postMessage(data, window.location.origin);
  }, []);
  return <></>;
};
