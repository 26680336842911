import { InMemoryCache } from "@apollo/client";

export const cache = new InMemoryCache({
  typePolicies: {
    Metadata: {
      keyFields: ["metadata_id"]
    },
    Genre: {
      keyFields: ["genre_id"]
    },
    Role: {
      keyFields: ["role_id"]
    },
    Playlist: {
      keyFields: ["id"]
    },
    TrackActivity: {
      keyFields: ["id"]
    },
    Cart: {
      fields: {
        cart_item: {
          merge(_, incoming) {
            return incoming;
          }
        }
      }
    },
    Query: {
      fields: {
        track: {
          read(existing, { args, toReference, canRead }) {
            if (args?.where.id) {
              const refs = [toReference({ __typename: "Track", id: args.where.id })];
              if (refs.find(ref => !canRead(ref))) {
                return;
              }
              return refs;
            }
            return existing;
          }
        },
        curation_management: {
          keyArgs: ["isForce", "where", ["id"]],
          read(existing, { args, toReference, canRead }) {
            if (args?.where?.id) {
              const refs = [toReference({ __typename: "CurationManagement", id: args.where.id })];
              if (refs.find(ref => !canRead(ref))) {
                return;
              }
              return refs;
            }
            return existing;
          },
          merge(existing = [], incoming, { args, readField }) {
            if (args?.page) {
              return [...incoming];
            }

            const merged = [...existing];
            const existingField = merged.map(item => readField("id", item));
            incoming.forEach((item: any) => {
              const b = readField("id", item);
              if (!existingField.includes(b)) {
                merged.push(item);
              }
            });
            return merged;
          }
        },
        metadata: {
          read(existing, { args, toReference, canRead }) {
            if (args?.where.metadata_id) {
              const refs = [toReference({ __typename: "Metadata", metadata_id: args.where.metadata_id })];
              if (refs.find(ref => !canRead(ref))) {
                return;
              }
              return refs;
            }
            return existing;
          }
        },
        edge: {
          keyArgs: ["node", "where", ["type__in", "id"], "first", "page"],
          read(existing) {
            return existing;
          },
          merge(_, incoming) {
            return incoming;
          }
        },
        purchase_item: {
          keyArgs: ["first"],
          merge(existing = [], incoming) {
            return [...existing, ...incoming];
          }
        },
        playlist: {
          keyArgs: ["where", ["type__in", "id"], "page"],
          read(existing, { args, toReference, canRead }) {
            if (args?.where.id) {
              const refs = [toReference({ __typename: "Playlist", id: args.where.id })];
              if (refs.find(ref => !canRead(ref))) {
                return;
              }
              return refs;
            }
            return existing;
          },
          merge(existing = [], incoming, { readField }) {
            const merged = [...existing];
            const existingField = merged.map(item => readField("id", item));
            incoming.forEach((item: any) => {
              const b = readField("id", item);
              if (!existingField.includes(b)) {
                merged.push(item);
              }
            });
            return merged;
          }
        },
        genre: {
          read(existing, { args, toReference, canRead }) {
            if (args?.where.genre_id) {
              const refs = [toReference({ __typename: "Genre", genre_id: args.where.genre_id })];
              if (refs.find(ref => !canRead(ref))) {
                return;
              }
              return refs;
            }
            return existing;
          }
        },
        role: {
          read(existing, { args, toReference, canRead }) {
            if (args?.where.role_id) {
              const refs = [toReference({ __typename: "Role", role_id: args.where.role_id })];
              if (refs.find(ref => !canRead(ref))) {
                return;
              }
              return refs;
            }
            return existing;
          }
        }
      }
    }
  }
});
