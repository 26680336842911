import { ReactElement } from "react";
import { useParams } from "Lib/use-router";
import styled from "styled-components";
import { CurationManagementID } from "Models/CurationManagementModel";
import { useCurationManagementNode } from "App/Store/CurationManagementStore";
import { useCurationManagementDetail } from "../hooks/useCurationManagementDetail";
import { CurationInformationForm } from "./CurationInformationForm";
import { PlaylistTrackForm } from "./PlaylistTrackForm";

export const Form = (): ReactElement => {
  const { id } = useParams();
  const curationManagementId = new CurationManagementID(+id!);
  const { isLoaded: isLoadedCurationDetail } = useCurationManagementDetail(+id!);

  const curation = useCurationManagementNode(curationManagementId);
  const playlistId = curation?.playlist!;

  return (
    <Layout>
      <>
        <CurationInformationForm id={playlistId} isLoadedCuration={isLoadedCurationDetail} />
        <PlaylistTrackForm id={playlistId} isLoadedCuration={isLoadedCurationDetail} />
      </>
    </Layout>
  );
};

const Layout = styled.div`
  position: relative;
`;
