import { Icon } from "App/Atomics/Icon";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { useAccountSelector } from "App/Store/AccountStore";
import { useWallet } from "App/Templates/BasicTemplate/hooks/useWallet";
import { Color } from "Constants/Color";
import { Balance, PointPayment } from "Constants/Locale";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

export const SelectContent = () => {
  const wallet = useAccountSelector(store => store.wallet);
  const { loading: walletLoading } = useWallet({
    fetchPolicy: "cache-and-network"
  });
  return (
    <Layout>
      <Top>
        <CheckIcon name="checkbox-fill" />
        <PointPayment />
      </Top>
      <Bottom>
        {walletLoading ? (
          <Skeleton width={150} height={26} />
        ) : (
          <Wallet weight={TypoWeight.Bold} size={TypoSize.F18}>
            <Balance /> :
            <CoinIcon name="coin" /> {wallet?.balance.toLocaleString()}
          </Wallet>
        )}
      </Bottom>
    </Layout>
  );
};

const Layout = styled.div``;
const Top = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 24px;
`;

const Bottom = styled.div`
  padding: 40px 24px;
  background-color: ${Color.Ac_Charcoal};
  margin: 0 -24px;
`;

const CheckIcon = styled(Icon)`
  margin-right: 1rem;
`;

const Wallet = styled(Typography)`
  display: flex;
  align-items: center;
`;

const CoinIcon = styled(Icon)`
  width: 1.8rem;
  height: 1.8rem;
  margin-left: 0.25rem;
`;
