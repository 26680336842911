import { gql, useMutation } from "Lib/apollo-client";

const RESET_PASSWORD = gql`
  mutation SEND_RESET_PASSWORD_EMAIL($email: String!) {
    sendResetPasswordEmail(email: $email)
  }
`;

type Result = {
  readonly sendResetPasswordEmail: boolean;
};

type Reponse = {
  readonly email: string;
};

export const useSendResetPasswordEmail = () => {
  const [fetch] = useMutation<Result, Reponse>(RESET_PASSWORD);

  return { sendResetPasswordEmail: fetch };
};
