import { Hash } from ".";
import { createDuck } from "./store";

export function createIndexActions() {
  const createInitialState = () => ({
    cursor: 0,
    hashList: [] as Hash[],
    indexList: [] as number[]
  });

  return createDuck({
    namespace: "Index",
    createInitialState,
    reducers: {
      clear(state) {
        state.cursor = 0;
        state.hashList = [];
        state.indexList = [];
      },
      insertList(state, hashList: readonly Hash[], index: number) {
        for (
          let currentIndex = state.hashList.length, lastIndex = currentIndex + hashList.length;
          currentIndex < lastIndex;
          currentIndex += 1
        ) {
          state.indexList.push(currentIndex);
        }
        state.hashList.splice(index, 0, ...hashList);
        state.hashList = [...new Set(state.hashList)];
        state.indexList = Array.from({ length: state.hashList.length }, (_, index) => index);
      },
      removeList(state, hashList: readonly Hash[]) {
        const currentHash = state.hashList[state.indexList[state.cursor]];
        const hashSet = new Set(hashList);
        if (hashSet.has(currentHash)) {
          let cursor = state.cursor;
          for (const index of state.hashList.map((_, index) => index)) {
            if (index <= cursor) {
              cursor += -1;
            } else {
              break;
            }
          }
          state.cursor = Math.max(cursor, 0);
          state.hashList = state.hashList.filter(hash => !hashSet.has(hash));
        } else {
          state.hashList = state.hashList.filter(hash => !hashSet.has(hash));
          state.cursor = state.hashList.findIndex(hash => hash === currentHash);
        }
        state.indexList = Array.from({ length: state.hashList.length }, (_, index) => index);
      },
      upsertList(state, hashList: readonly Hash[], index: number) {
        const hashes = new Set(hashList);
        const currentHash = state.hashList[state.indexList[state.cursor]];
        const forward = state.hashList.slice(0, index).filter(hash => !hashes.has(hash));
        const backward = state.hashList.slice(index);
        const nextHashes = new Set(forward.concat(hashList).concat(backward));
        state.hashList = [...nextHashes];
        state.indexList = Array.from({ length: nextHashes.size }, (_, index) => index);
        const nextIndex = state.hashList.findIndex(hash => hash === currentHash);
        const nextCursor = state.indexList.findIndex(index => index === nextIndex);
        state.cursor = Math.max(0, Math.min(nextHashes.size - 1, nextCursor));
      },
      setIndexList(state, indexList: number[]) {
        state.indexList = indexList;
      },
      current(state, hash: Hash) {
        const cursor = state.hashList.findIndex(current => current === hash);
        state.cursor = state.indexList.findIndex(index => index === cursor);
      },
      setCursor(state, cursor: number) {
        state.cursor = Math.max(0, Math.min(state.hashList.length - 1, cursor));
      },
      moveTo(state, hash: Hash, index: number) {
        const hashIndex = state.hashList.findIndex(currentHash => currentHash === hash);
        if (hashIndex !== index) {
          const currentHash = state.hashList[state.indexList[state.cursor]];

          state.hashList.splice(hashIndex, 1);
          state.hashList.splice(index, 0, hash);

          const nextCursor = state.hashList.findIndex(hash => hash === currentHash);
          state.cursor = state.indexList.findIndex(currentIndex => currentIndex === nextCursor);
        }
      }
    }
  });
}
