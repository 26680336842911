import { createStore } from "Lib/store";
import { PayoneqID } from "Models/PayoneqModel";

type Orders = Readonly<{
  amount: number;
  agreeTerm: boolean;
  payoneq: PayoneqID | null;
  payoneqGlobal: PayoneqID | null;
  isGlobal: boolean;
}>;

const createInitialState = () => ({
  current: {
    isShipping: true,
    agreeTerm: false,
    payoneq: null,
    payoneqGlobal: null,
    isGlobal: false,
    amount: 1000
  } as Orders
});

export const {
  useSelector: useBuyCheckSelector,
  action: BuyCheckAction,
  ...BuyCheckStore
} = createStore("BuyCheck", createInitialState, {
  clear(state) {
    state.current = {} as Orders;
  },
  setAmount(state, amount: number) {
    state.current = { ...state.current, amount };
  },
  addAmount(state, amount: number) {
    state.current = { ...state.current, amount: state.current.amount + amount };
  },

  toggleAgreeTerm(state) {
    state.current = { ...state.current, agreeTerm: !state.current.agreeTerm };
  },
  setPayoneqInformation(state, payoneq: PayoneqID | null) {
    state.current = { ...state.current, payoneq };
  },
  setPayoneqGlobalInformation(state, payoneqGlobal: PayoneqID | null) {
    state.current = { ...state.current, payoneqGlobal };
  },

  setIsGlobal(state, isGlobal: boolean) {
    state.current = { ...state.current, isGlobal };
  }
});
