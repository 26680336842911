import { Lottie } from "Lib/lottie";
import { TrackID } from "Models/TrackModel";
import styled from "styled-components";
import LoadingLottie from "Assets/Lottie/loading.json";
import { useTrackActivity } from "App/hooks/useTrackActivity";
import { PlayerItem } from "App/Features/Items/PlayerItem";

interface Props {
  readonly isLoadedPlayerlist?: boolean;
  readonly trackList: TrackID[];
}

export const PlayerList = ({ trackList, isLoadedPlayerlist = true }: Props) => {
  const { isLoaded: isLoadedTrackActivity } = useTrackActivity({
    trackList: trackList,
    isLoadedTrack: isLoadedPlayerlist
  });
  const isLoaded = isLoadedPlayerlist && isLoadedTrackActivity;
  return (
    <Layout>
      {!isLoaded && (
        <LoadingContainer>
          <Lottie data={LoadingLottie} loop playing />
        </LoadingContainer>
      )}
      {isLoaded && trackList.map(item => <PlayerItem key={item.key} id={item} />)}
    </Layout>
  );
};

const Layout = styled.div``;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 150px;
`;
