import { setLicense } from "App/Common/setLicense";
import { MAX_FIRST_COUNT } from "Constants/Count";
import { useLoadLicense } from "GraphQL/Queries/useLoadLicense";
import { useLoaded } from "Lib/use-loaded";
import { useEffect } from "react";

export const useLicense = (id: number) => {
  const { data, loading } = useLoadLicense({
    first: MAX_FIRST_COUNT,
    where: {
      track_id: id
    }
  });

  const { isLoaded } = useLoaded(loading);

  useEffect(() => {
    if (data) {
      const { license } = data;
      setLicense(license);
    }
  }, [data]);

  return { isLoaded };
};
