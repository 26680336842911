// import { User, UserDataCreateInput } from "GraphQL/Schema";
import { SignUpPayload, User } from "GraphQL/Schema";
import { gql, useMutation } from "Lib/apollo-client";

const SING_UP = gql`
  mutation SING_UP($email: String!, $name: String, $password: String!) {
    singUp(payload: { email: $email, password: $password, name: $name }) {
      id
      token {
        access_token
        refresh_token
      }
    }
  }
`;

type Result = {
  readonly singUp: User;
};

export const useSingUp = () => {
  const [singUp, { data, error, loading }] = useMutation<Result, SignUpPayload>(SING_UP, {
    fetchPolicy: "network-only"
  });

  return { singUp, data, error, loading };
};
