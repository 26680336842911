import { setPlaylist } from "App/Common/setPlaylist";
import { BASIC_FIRST_COUNT } from "Constants/Count";
import { useLoadPlaylist } from "GraphQL/Queries/useLoadPlaylist";
import { PlaylistOrderByInput, PlaylistType } from "GraphQL/Schema";
import { useEffect } from "react";
import { LibraryPlaylistAction, LibraryPlaylistStore } from "../Store";

export const useLibraryPlaylists = () => {
  const { data, loading, fetchMore } = useLoadPlaylist({
    first: BASIC_FIRST_COUNT,
    where: {
      type__in: [PlaylistType.My, PlaylistType.Curation]
    },
    orderBy: [PlaylistOrderByInput.IdDesc],
    fetchPolicy: "cache-and-network"
  });

  useEffect(() => {
    if (data) {
      const { playlist: responsePlaylist, edge } = data;
      const playlistMs = setPlaylist(responsePlaylist);
      const playlist = playlistMs.map(playlistM => playlistM.id);
      LibraryPlaylistStore.dispatch(LibraryPlaylistAction.setLibraryPlaylist(playlist));
      LibraryPlaylistStore.dispatch(LibraryPlaylistAction.setEdge(edge));
    }
  }, [data]);

  useEffect(() => {
    LibraryPlaylistStore.dispatch(LibraryPlaylistAction.setIsLoadedLibraryPlaylist(!loading));
  }, [loading]);

  return { fetchMore };
};
