import { AccountAction, AccountStore } from "App/Store/AccountStore";
import { Cart } from "GraphQL/Schema";
import { CartModel } from "Models/CartModel";

export const setCartCount = (cart: Cart) => {
  const CartM = CartModel.form({
    count: cart.item_total_count,
    id: cart.id,
    amount: cart.item_total_amount
  });

  AccountStore.dispatch(AccountAction.setCart(CartM));
};
