import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import styled from "styled-components";

export const AccountContentLoader = () => {
  return (
    <SkeletonTheme baseColor="rgb(238,238,238)" highlightColor="rgb(221,221,221)">
      <Layout>
        <Skeleton width={50} height={24} />
      </Layout>
    </SkeletonTheme>
  );
};

const Layout = styled.div``;
