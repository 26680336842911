import { pixelize, Size, UNIT } from "Constants/Size";
import styled from "styled-components";
import { AudioTime } from "./AudioTime";
import { TrackInfo } from "./TrackInfo";

export const LeftController = () => {
  return (
    <Layout>
      <TrackInfo />
      <AudioTime />
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  align-items: center;

  width: ${pixelize(UNIT * 30)};
  display: flex;
  align-items: center;
  height: 100%;
  padding-bottom: ${Size.PADDING_SMALL_PX};
`;
