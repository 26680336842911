import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { Color } from "Constants/Color";
import { ContactEmailLink, FaqEmailText, GoToSupport, MoveLink, SendEmail, SupportMainDescriptionText } from "Constants/Locale";
import { DESKTOP_MODE } from "Constants/Size";
import styled from "styled-components";

export const Faq = () => {
  return (
    <Layout>
      <Container>
        <Inforatmion>
          <InformationLeft>
            <Typography color={Color.Ac_White} weight={TypoWeight.Bold} size={TypoSize.F24}>
              <SendEmail />
            </Typography>

            <EmailText color={Color.Ac_Gray2}>
              <FaqEmailText />
            </EmailText>
            <Typography color={Color.Ac_White}>
              <ContactEmailLink a={({ children }) => <Link href="mailto:contact@artistscard.com">{children}</Link>}></ContactEmailLink>
            </Typography>
          </InformationLeft>
          <InformationRight>
            <Typography color={Color.Ac_White} weight={TypoWeight.Bold} size={TypoSize.F24}>
              <GoToSupport />
            </Typography>

            <EmailText color={Color.Ac_Gray2}>
              <SupportMainDescriptionText />
            </EmailText>
            <Typography color={Color.Ac_White}>
              <MoveLink a={({ children }) => <Link href={"https://support.artistscard.com/bgm-manager"}>{children}</Link>}></MoveLink>
            </Typography>
          </InformationRight>
        </Inforatmion>
      </Container>
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${Color.Ac_Gray};
`;

const Container = styled.div`
  width: 100%;
  max-width: 1000px;
  padding: 150px 30px;
  @media ${DESKTOP_MODE} {
    padding: 200px 30px;
  }
`;

const Inforatmion = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;

  flex-direction: column;
  @media ${DESKTOP_MODE} {
    flex-direction: row;
  }
`;

const Left = styled.div`
  white-space: pre-line;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const Right = styled.div`
  white-space: pre-line;
  flex: 1;
  height: 100%;
  display: none;
  flex-direction: column;

  @media ${DESKTOP_MODE} {
    display: flex;
  }
`;

const InformationLeft = styled(Left)`
  align-items: center;

  text-align: center;
  margin-bottom: 7rem;
  @media ${DESKTOP_MODE} {
    margin-bottom: 0rem;
  }
`;
const InformationRight = styled(Right)`
  align-items: center;
  text-align: center;

  display: flex;
`;

const Link = styled.a`
  color: ${Color.AC_Carrot};
  text-decoration: underline;
  font-weight: bold;
  font-size: 0.8rem;
`;

const EmailText = styled(Typography)`
  margin: 2rem 0;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
