import { DESKTOP_MODE } from "Constants/Size";
import styled from "styled-components";
import { useCart } from "../hooks/useCart";
import { Left } from "./Left";
import { Right } from "./Right";

export const Form = () => {
  const { isLoaded: isLoadedCart } = useCart();
  return (
    <Layout>
      <Left />
      <Right isLoadedCart={isLoadedCart} />
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;

  flex-direction: column;
  @media ${DESKTOP_MODE} {
    flex-direction: row;
  }
`;
