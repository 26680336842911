import { NumericID } from "Utils/id";
import { immerable } from "immer";
import { TrackID } from "./TrackModel";
import { GradeID } from "./GradeModel";

export class CartItemID extends NumericID {
  constructor(id: number) {
    super("cartItem", id);
  }
}

export type CartItemPlayload = {
  readonly id: number;
  readonly track: TrackID;
  readonly grade: GradeID;
};

export class CartItemModel {
  static form({ id, track, grade }: CartItemPlayload) {
    return new CartItemModel(new CartItemID(id), track, grade);
  }

  constructor(readonly id: CartItemID, readonly track: TrackID, readonly grade: GradeID) {}
  readonly [immerable] = true;
}
