import type { Reducer } from "react";

type Reducers<S> = {
  readonly [Key in keyof S]: Reducer<S[Key], any>;
};

export type Action<P> = {
  readonly type: string;
  readonly payload: P;
};
export type ActionList<P> = readonly [Action<P>, ...(readonly Action<P>[])];

export type InternalAction<P> = Readonly<Action<P>> & {
  readonly namespace: string;
  readonly key: string;
};

export type AfterChange<S> = (previousStore: S, nextStore: S, action: InternalAction<any>) => void | Promise<void>;

/**
 * 여러 reducer를 하나의 reducer인 것처럼 작동하게 해주는 함수
 *
 * @param reducers key-value 형태의 reducer 오브젝트
 * @param afterChange dispatch 후 store의 변화가 있으면 호출하는 callback 함수
 */
export const combineReducers = <S extends Record<string, any>, A>(reducers: Reducers<S>, afterChange: AfterChange<S> = () => {}) => {
  const reducer: Reducer<S, A> = (previousStore = {} as S, action) => {
    const actions = Array.isArray(action) ? ((action as unknown) as ActionList<any>) : [(action as unknown) as Action<any>];
    const nextStore = {} as S;
    let isChanged = false;
    for (const action of actions) {
      let isChangedLocal = false;
      for (const key in reducers) {
        const reducer = reducers[key];
        const previousState = nextStore[key] || previousStore[key];
        const nextState = reducer(previousState, action);
        nextStore[key] = nextState;
        if (!isChangedLocal) {
          isChangedLocal = previousState !== nextState;
        }
      }
      if (isChangedLocal) {
        afterChange(previousStore, nextStore, action as InternalAction<A>);
      }
      if (!isChanged) {
        isChanged = isChangedLocal;
      }
    }
    return isChanged ? nextStore : previousStore;
  };
  return reducer;
};
