import { LinkTarget } from "App/Atomics/ExternalLink";
import { Icon } from "App/Atomics/Icon";
import { SidebarLanguageInfo } from "App/Molecules/SidebarLanguageInfo";
import { useAccountSelector } from "App/Store/AccountStore";
import { ModalAction, ModalStore, ModalType } from "App/Store/ModalStore";
import { Color } from "Constants/Color";
import {
  AccountInfoManage,
  AccountLogin,
  AccountLogout,
  AccountSignup,
  Cart,
  Curation,
  FavoriteTrack,
  Music,
  Playlist,
  PurchasedTrack,
  PurchaseHistory,
  SettingLegalNotice,
  SettingPrivacyPolicy,
  SettingTerms
} from "Constants/Locale";
import {
  ACCOUNT,
  CART,
  HOME,
  LEGAL_NOTICE,
  LIBRARY_CURATION,
  LIBRARY_FAVORITES,
  LIBRARY_PLAYLIST,
  LIBRARY_PURCHASE_HISTORY,
  LIBRARY_PURCHASE_TRACK,
  PRIVACY_POLICY,
  SUPPORT,
  TERM_OF_SERVICE
} from "Constants/Routes";
import { Modal } from "Lib/modal";
import { useToggle } from "Lib/use-toggle";
import { isGuestAccountModel } from "Models/AccountModel/AccountModel";

import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useDesktop } from "Utils/useDesktop";
import { useSignOut } from "Utils/useSignout";

type Props = {
  readonly isOpen: boolean;
};

export const SidebarMenuModal = ({ isOpen }: Props) => {
  const isGuest = useAccountSelector(store => isGuestAccountModel(store.current));
  const onRequestClose = () => ModalStore.dispatch(ModalAction.closeAll());
  const navigate = useNavigate();

  const moveToPurchaseHistory = () => {
    navigate(LIBRARY_PURCHASE_HISTORY);
    onRequestClose();
  };
  const moveToLibaryLike = () => {
    navigate(LIBRARY_FAVORITES);
    onRequestClose();
  };
  const moveToLibaryPurchaseTrack = () => {
    navigate(LIBRARY_PURCHASE_TRACK);
    onRequestClose();
  };
  const moveToLibaryPlaylist = () => {
    navigate(LIBRARY_PLAYLIST);
    onRequestClose();
  };
  const moveToLibaryCuration = () => {
    navigate(LIBRARY_CURATION);
    onRequestClose();
  };

  const signOut = useSignOut();
  const moveToCart = () => {
    navigate(CART);
    onRequestClose();
  };

  const moveToAccount = () => {
    navigate(ACCOUNT);
    onRequestClose();
  };

  const isDesktop = useDesktop();
  const moveToMusic = () => {
    navigate(HOME);
    onRequestClose();
  };

  const moveToSupport = () => {
    navigate(SUPPORT);
    onRequestClose();
  };

  const signOutAndGoSignIn = () => {
    signOut();
    navigate(HOME);
    onRequestClose();
  };

  const showLibaryToggle = useToggle();

  const signin = () => {
    ModalStore.dispatch(ModalAction.replace(ModalType.SIGNIN));
  };

  if (isDesktop) {
    return null;
  }
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} animation={false}>
      <Layout>
        <Header>
          <CloseButton name="close" onClick={onRequestClose} />
        </Header>
        <Container>
          <MenuContainer>
            <Menu onClick={moveToMusic}>
              <MenuText>
                <Music />
              </MenuText>
            </Menu>
            <Menu onClick={moveToSupport}>
              <MenuText>FAQ</MenuText>
            </Menu>

            {isGuest && (
              <Menu onClick={signin}>
                <MenuText>
                  <AccountLogin /> / <AccountSignup />
                </MenuText>
              </Menu>
            )}

            {!isGuest && (
              <>
                <Menu onClick={moveToCart}>
                  <MenuText>
                    <Cart />
                  </MenuText>
                </Menu>
                <Menu onClick={signOutAndGoSignIn}>
                  <MenuText>
                    <AccountLogout />
                  </MenuText>
                </Menu>
                <Menu onClick={moveToAccount}>
                  <MenuText>
                    <AccountInfoManage />
                  </MenuText>
                </Menu>

                <Menu onClick={() => showLibaryToggle.toggle()}>
                  <MenuText>라이브러리</MenuText>
                  <ArrowIconContainer show={showLibaryToggle.isToggled}>
                    <ArrowIcon name="down-arrow" />
                  </ArrowIconContainer>
                </Menu>

                <HiddenMenu show={showLibaryToggle.isToggled}>
                  <Menu onClick={moveToPurchaseHistory}>
                    <MenuText>
                      <PurchaseHistory />
                    </MenuText>
                  </Menu>

                  <Menu onClick={moveToLibaryPlaylist}>
                    <MenuText>
                      <Playlist />
                    </MenuText>
                  </Menu>

                  <Menu onClick={moveToLibaryCuration}>
                    <MenuText>
                      <Curation />
                    </MenuText>
                  </Menu>

                  <Menu onClick={moveToLibaryPurchaseTrack}>
                    <MenuText>
                      <PurchasedTrack />
                    </MenuText>
                  </Menu>

                  <Menu onClick={moveToLibaryLike}>
                    <MenuText>
                      <FavoriteTrack />
                    </MenuText>
                  </Menu>
                </HiddenMenu>
              </>
            )}
          </MenuContainer>
          <MenuButton href={TERM_OF_SERVICE} target={LinkTarget.Blank}>
            <SettingTerms />
          </MenuButton>
          <MenuButton href={PRIVACY_POLICY} target={LinkTarget.Blank}>
            <SettingPrivacyPolicy />
          </MenuButton>
          <MenuButton href={LEGAL_NOTICE} target={LinkTarget.Blank}>
            <SettingLegalNotice />
          </MenuButton>
          <SidebarLanguageInfo close={onRequestClose} />
        </Container>
      </Layout>
    </Modal>
  );
};

const Layout = styled.div`
  background-color: ${Color.Ac_Shadow};
  max-width: 300px;
  width: 100%;
  height: 100vh;

  margin-left: auto;
  pointer-events: auto;

  display: flex;
  flex-direction: column;
  padding: 10px;

  overflow-y: scroll;
`;

const Header = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-items: flex-end;
  align-items: center;
`;

const CloseButton = styled(Icon)`
  width: 1.5rem;
  height: 1.5rem;
  margin-left: auto;
  cursor: pointer;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
`;

const Menu = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px solid ${Color.Ac_Gray0};
  padding: 1rem 0;
  justify-content: space-between;
  cursor: pointer;
`;

const MenuContainer = styled.div`
  padding-bottom: 2rem;
`;
const MenuText = styled.p`
  font-size: 1rem;
  font-weight: bold;
  color: ${Color.Ac_White};
`;

const MenuButton = styled.a`
  font-size: 0.9rem;
  color: ${Color.Ac_Gray1};
  padding: 10px 0;
  cursor: pointer;
`;

const ArrowIcon = styled(Icon)`
  width: 1.25rem;
  height: 1.25rem;
`;

const ArrowIconContainer = styled.div<{ show: boolean }>`
  transform: ${props => (props.show ? `rotate(180deg)` : `rotate(0deg)`)};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 1s;
`;

const HiddenMenu = styled.div<{ show: boolean }>`
  transition: max-height 0.3s ${props => (props.show ? "cubic-bezier(1, 0, 1, 0)" : "cubic-bezier(0,1,0,1)")};
  max-height: ${props => (props.show ? "99999px" : "0px")};
  overflow: hidden;
`;
