import { Icon } from "App/Atomics/Icon";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { Color } from "Constants/Color";
import { memo, ReactNode, useState } from "react";
import styled from "styled-components";

export const MenuItem = memo<{
  readonly children?: ReactNode;
  readonly defaultOption?: boolean;
  readonly subName?: string | null;
  readonly name: string;
}>(({ defaultOption = false, name, children, subName }) => {
  const [isOpen, setOpen] = useState<boolean>(defaultOption);
  return (
    <MenuItemLayout isOpen={isOpen}>
      <Menu onClick={() => setOpen(!isOpen)}>
        <MenuText size={TypoSize.F16} weight={TypoWeight.Bold} color={Color.Ac_White}>
          {name}{" "}
          {subName && (
            <SubName size={TypoSize.F13} color={Color.Ac_Gray0}>
              [{subName}]
            </SubName>
          )}
        </MenuText>
        {!isOpen && <MenuPlus name="plus" />}
        {isOpen && <MenuPlus name="minus" />}
      </Menu>
      <MenuContent>{children}</MenuContent>
    </MenuItemLayout>
  );
});

const MenuItemLayout = styled.div<{ isOpen: boolean }>`
  border-bottom: 1px solid ${Color.Ac_Gray};

  overflow: hidden;
  transition: max-height 0.3s ${props => (props.isOpen ? "cubic-bezier(1, 0, 1, 0)" : "cubic-bezier(0,1,0,1)")};
  max-height: ${props => (props.isOpen ? "99999px" : "4.3rem")};
`;

const Menu = styled.div`
  width: 100%;
  height: 4.3rem;
  user-select: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover svg {
    line {
      stroke: ${Color.Ac_White};
    }
  }
`;

const MenuText = styled(Typography)`
  flex: 1;
  height: 100%;

  display: flex;
  align-items: center;
`;

const MenuPlus = styled(Icon)`
  width: 1rem;
`;

const MenuContent = styled.div`
  padding-bottom: 30px;
`;

const SubName = styled(Typography)`
  margin-left: 0.25rem;
`;
