import { PlaylistInformationContentLoader } from "App/Features/ContentLoaders/PlaylistInformationContentLoader";
import { PlaylistID } from "Models/PlaylistModel";
import styled from "styled-components";
import { CurationInformation } from "./CurationInformation";

interface Props {
  readonly id: PlaylistID;
  readonly isLoadedCuration: boolean;
}

export const CurationInformationForm = ({ id, isLoadedCuration }: Props) => {
  return <Layout>{isLoadedCuration ? <CurationInformation id={id} /> : <PlaylistInformationContentLoader />}</Layout>;
};

const Layout = styled.div``;
