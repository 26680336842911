import { setProductPrice } from "App/Common/setProductPrice";
import { useLoadProductPrice } from "GraphQL/Queries/useLoadProductPrice";
import { useLoaded } from "Lib/use-loaded";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export const useProductPriceDetail = () => {
  const { id } = useParams();
  const { data, loading } = useLoadProductPrice({
    where: {
      id: +id!
    }
  });
  const { isLoaded } = useLoaded(loading);

  useEffect(() => {
    if (data) {
      const { productPrice } = data;
      setProductPrice(productPrice);
    }
  }, [data]);

  return { isLoaded };
};
