import { AdminTemplate } from "App/Templates/AdminTemplate";
import styled from "styled-components";
import { Form } from "./Form";

export const StaffCreate = () => {
  return (
    <AdminTemplate>
      <Layout>
        <Form />
      </Layout>
    </AdminTemplate>
  );
};

const Layout = styled.div``;
