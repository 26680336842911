import { LinkTarget } from "App/Atomics/ExternalLink";
import { Icon } from "App/Atomics/Icon";
import { Typography, TypoSize } from "App/Atomics/Typography";
import { configs } from "Config";
import { Color } from "Constants/Color";
import { FirebaseConfig } from "Constants/FirebaseConfig";
import { CookiePolicy, SettingLegalNotice, SettingPrivacyPolicy, SettingTerms } from "Constants/Locale";
import { COOKIE_POLICY, LEGAL_NOTICE, PRIVACY_POLICY, TERM_OF_SERVICE } from "Constants/Routes";
import { MOBILE_MODE, Size, WIDE_MODE } from "Constants/Size";
import { getAllRemoteConfig } from "Lib/firebase";
import { currentLanguage } from "Lib/localization";
import { ReactElement } from "react";
import styled from "styled-components";

type ConfigValue = {
  readonly key: string;
  readonly value: string[];
};

export const Footer = (): ReactElement => {
  const language = currentLanguage();
  const remoteValue = getAllRemoteConfig()[language === "ko" ? FirebaseConfig.BUSINESS_INFO : FirebaseConfig.BUSINESS_INFO_EN];
  const configValue = JSON.parse(remoteValue?.asString() ?? "null") as ConfigValue[];
  return (
    <Layout>
      <Container>
        <Center>
          <Menu>
            <MenuButton href={TERM_OF_SERVICE} target={LinkTarget.Blank}>
              <SettingTerms />
            </MenuButton>
            <MenuButton href={PRIVACY_POLICY} target={LinkTarget.Blank}>
              <SettingPrivacyPolicy />
            </MenuButton>
            <MenuButton href={LEGAL_NOTICE} target={LinkTarget.Blank}>
              <SettingLegalNotice />
            </MenuButton>
            <MenuButton href={COOKIE_POLICY} target={LinkTarget.Blank}>
              <CookiePolicy />
            </MenuButton>
          </Menu>
          {configValue && (
            <Info>
              {configValue.map(({ key, value }, index) => (
                <RowGroup key={index}>
                  <RowKey>{`${key} :`}</RowKey>
                  {value.map((item, i) => (
                    <RowValue key={i}>{item}</RowValue>
                  ))}
                </RowGroup>
              ))}
            </Info>
          )}
          <IconContainer>
            <IconLink href={configs.urls.youtubeLink} target={LinkTarget.Blank}>
              <Icon name="youtube-gray" />
            </IconLink>
            <IconLink href={configs.urls.instagramLink} target={LinkTarget.Blank}>
              <Icon name="insta-gray" />
            </IconLink>
          </IconContainer>

          <CopyRight color={Color.Ac_Gray1} size={TypoSize.F12}>
            Copyright © Artists’Card Inc. All rights reserved.
          </CopyRight>
        </Center>
      </Container>
    </Layout>
  );
};

const Layout = styled.div`
  padding-bottom: 3rem;
  background-color: ${Color.Ac_Charcoal};
  text-align: center;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media ${WIDE_MODE} {
    flex-direction: row;
  }

  padding: 60px 25px;
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const IconLink = styled.a`
  cursor: pointer;
  & + & {
    margin-left: 30px;
  }
`;

const Menu = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;
const Info = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 30px;
  max-width: 500px;
`;

const MenuButton = styled.a`
  font-weight: bold;
  font-size: 15px;

  @media ${MOBILE_MODE} {
    & + & {
      margin-left: 60px;
    }
  }
`;

const RowGroup = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${Color.Ac_White};
  margin-bottom: ${Size.MARGIN_SMALL_PX};
  margin-right: 8px;
`;
const RowKey = styled.div`
  margin-right: 8px;
`;
const RowValue = styled.div`
  white-space: normal;
  & + & {
    margin-left: 8px;
  }
`;

const IconContainer = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`;

const CopyRight = styled(Typography)`
  width: 100%;
`;
