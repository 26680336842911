import { createDuck } from "./store";

export enum Playing {
  Stop = "stop",
  Pause = "pause",
  Play = "play"
}

export const createPlayingActions = () => {
  const createInitialState = () => ({ playing: Playing.Stop });

  return createDuck({
    namespace: "Playing",
    createInitialState,
    reducers: {
      setPlaying(state, playing: Playing) {
        state.playing = playing;
      }
    }
  });
};
