import { useSidebarSelector } from "App/Store/SidebarStore";
import { Color } from "Constants/Color";
import { DESKTOP_MODE } from "Constants/Size";
import { ReactNode } from "react";
import styled from "styled-components";
import { Header } from "./Header";
import { SideBar } from "./SideBar";

type Props = {
  readonly children: ReactNode;
};

export const AdminTemplate = ({ children }: Props) => {
  const { isAdminDesktopOpen, isAdminMobileOpen } = useSidebarSelector(store => store.current);
  return (
    <Layout>
      <HeaderLayout>
        <Header />
      </HeaderLayout>
      <Container>
        <SideBarLayout data-mobile-visible={isAdminMobileOpen} data-desktop-visible={isAdminDesktopOpen}>
          <SideBar />
        </SideBarLayout>
        <Main data-mobile-visible={isAdminMobileOpen} data-desktop-visible={isAdminDesktopOpen}>
          {children}
        </Main>
      </Container>
    </Layout>
  );
};

const Layout = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  color: ${Color.Ac_Gray0};
  display: flex;
  flex-direction: column;
`;

const HeaderLayout = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 64px;
  background-color: ${Color.Ac_Shadow};
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.2);
  z-index: 2;
  padding: 12px 16px 16px;
`;

const Container = styled.div`
  display: flex;
  height: calc(100vh - 64px);
  background-color: ${Color.Ac_Black};
`;

const Main = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`;

const SideBarLayout = styled.div`
  width: 100%;
  max-width: 260px;
  height: 100%;

  overflow-y: auto;

  background-color: ${Color.Ac_Shadow};
  display: none;
  &[data-desktop-visible="false"] {
    max-width: min-content;
    width: auto;
    display: block;
  }

  @media ${DESKTOP_MODE} {
    &[data-desktop-visible="false"] {
      max-width: min-content;
      width: auto;
    }
    display: block;
  }
`;
