import { ApolloCache } from "@apollo/client";
import { gql, useMutation } from "Lib/apollo-client";
import { Toast } from "Lib/toast";
import { useEffect } from "react";

const REMOVE_PLAYLIST_TRACK = gql`
  mutation REMOVE_PLAYLIST_TRACK($playlistId: Int!, $trackId: Int!) {
    removePlaylistTrack: remove_playlist_item(playlist_id: $playlistId, track_id: $trackId)
  }
`;

type Result = {
  readonly removePlaylistTrack: number | null;
};

type Args = {
  readonly playlistId: number;
  readonly trackId?: number;
};

type addPlaylistTrackCacheType = {
  readonly cache: ApolloCache<any>;
  readonly deleteId: number;
};

const removePlaylistTrackFormCache = ({ cache, deleteId }: addPlaylistTrackCacheType) => {
  const normalizedPlaylistItemId = cache.identify({ id: deleteId, __typename: "PlaylistItem" });
  cache.evict({ id: normalizedPlaylistItemId });
  cache.gc();
};

export const useRemovePlaylistTrack = () => {
  const [fetch, { loading, error }] = useMutation<Result, Args>(REMOVE_PLAYLIST_TRACK, {
    update(cache, { data }) {
      const id = data?.removePlaylistTrack;
      if (id) {
        removePlaylistTrackFormCache({ cache, deleteId: id });
      }
      return false;
    }
  });

  useEffect(() => {
    if (error) {
      if (error?.graphQLErrors[0].message === "is already purchased curation") {
        Toast.error("환불 후 수정해주세요.");
      }
    }
  }, [error]);

  return { removePlaylistTrack: fetch, loading };
};
