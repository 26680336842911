import { Button, ButtonColor } from "App/Atomics/Button";
import { Input } from "App/Atomics/Input";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { ModalAction, ModalStore } from "App/Store/ModalStore";
import { Color } from "Constants/Color";
import {
  AccountNewPasswordTitle,
  AccountPasswordErrorMaxLength,
  Change,
  ChangePassword,
  Close,
  SucessAccountPasswordChange,
  TryToAgain
} from "Constants/Locale";
import { updatePassword } from "GraphQL/Mutations/updatePassword";
import { Modal } from "Lib/modal";
import { Toast } from "Lib/toast";
import { FormEvent, ReactElement, useRef } from "react";
import styled from "styled-components";
import { sha256 } from "Utils/hash";
import { useAuth } from "Utils/useAuth";

type Props = {
  readonly isOpen: boolean;
};

export const PasswordChangeModal = ({ isOpen }: Props): ReactElement => {
  const onRequestClose = () => ModalStore.dispatch(ModalAction.closeAll());

  const auth = useAuth();

  const newPasswordRef = useRef<HTMLInputElement>(null);

  const checkPassword = (next: string): boolean => {
    switch (true) {
      case next.length > 32:
        Toast.primary(AccountPasswordErrorMaxLength.message());
        return false;
      default:
        return true;
    }
  };
  const onUpdatePassword = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    const newPassword = newPasswordRef.current!.value;
    const result = checkPassword(newPassword);
    if (!result) return;
    try {
      await updatePassword({
        token: auth.token?.access_token!,
        password: sha256(newPassword)
      });
      Toast.primary(SucessAccountPasswordChange.message());
      onRequestClose();
    } catch (err) {
      console.log(err);
      Toast.primary(TryToAgain.message());
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Layout onSubmit={onUpdatePassword}>
        <Container>
          <Title size={TypoSize.F20} weight={TypoWeight.Bold}>
            <ChangePassword />
          </Title>
          <RowGroup>
            <Label size={TypoSize.F12} weight={TypoWeight.Bold}>
              <AccountNewPasswordTitle />
            </Label>
            <SerialInput ref={newPasswordRef} type="password" placeholder={AccountNewPasswordTitle.message()} />
          </RowGroup>
          <ButtonGroup>
            <StyledButton color={ButtonColor.Disable} onClick={onRequestClose}>
              <Close />
            </StyledButton>
            <StyledButton type="submit" color={ButtonColor.Primary}>
              <Change />
            </StyledButton>
          </ButtonGroup>
        </Container>
      </Layout>
    </Modal>
  );
};

const Layout = styled.form`
  width: 400px;
  padding: 40px 20px 20px;
  margin: 0 1.5rem;
  border-radius: 5px;
  background-color: ${Color.Ac_Charcoal};
  pointer-events: auto;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Title = styled(Typography)`
  margin-bottom: 20px;
`;
const RowGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 20px;
`;
const Label = styled(Typography)`
  margin-bottom: 8px;
`;
const SerialInput = styled(Input.Text)`
  position: relative;
  width: 100%;
  height: 44px;
  margin-top: 4px;
  background-color: ${Color.Ac_Shadow}!important;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.5);
  color: ${Color.Ac_White}!important;
  border: none;
  border-radius: 8px;
  line-height: 22px;
  padding: 1rem;
  transition: box-shadow 0.2s ease;
  &:hover {
    box-shadow: inset 0 0 0 2px ${Color.Ac_Gray0};
  }
  &:focus {
    box-shadow: inset 0 0 0 2px ${Color.AC_Carrot};
  }
`;
const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;
const StyledButton = styled(Button)`
  width: 100%;
  font-weight: bold;
  & + & {
    margin-left: 8px;
  }
`;
