import { TRACK_ACTIVITY_FRAGMENT } from "GraphQL/Fragment/TrackActivityFragment";
import { LOAD_CART_COUNT } from "GraphQL/Queries/useLoadCartCount";
import { Aggregate, TrackActivity } from "GraphQL/Schema";
import { gql, useMutation } from "Lib/apollo-client";
import { useLoaded } from "Lib/use-loaded";

const ADD_CART_ITEM = gql`
  ${TRACK_ACTIVITY_FRAGMENT}
  mutation ADD_CART_ITEM($trackId: Int!) {
    addCartItem: push_cart_item(track_id: $trackId) {
      ...TrackActivityFragment
    }
  }
`;

type Result = {
  readonly addCartItem: TrackActivity;
};

type Args = {
  readonly trackId: number;
};

type CartCountResult = {
  readonly cartCount: Aggregate;
};

export const useAddCartItem = () => {
  const [fetch, { loading }] = useMutation<Result, Args>(ADD_CART_ITEM, {
    update(cache, { data }) {
      const trackActivity = data?.addCartItem;
      cache.modify({
        fields: {
          track_activity(existingTrackActivity = []) {
            const newTrackActivity = cache.writeFragment({
              data: trackActivity,
              fragment: TRACK_ACTIVITY_FRAGMENT
            });
            return existingTrackActivity.concat(newTrackActivity);
          },
          getCount(existingGetCount) {
            const cartCount = cache.readQuery<CartCountResult>({ query: LOAD_CART_COUNT });

            if (existingGetCount.count === cartCount?.cartCount.count) {
              cache.updateQuery<CartCountResult>({ query: LOAD_CART_COUNT }, data => {
                return { cartCount: { ...data?.cartCount, count: data?.cartCount.count! + 1 } };
              });
            }

            return existingGetCount;
          },
          cart({ DELETE }) {
            return DELETE;
          }
        }
      });
    }
  });

  const { isLoaded } = useLoaded(loading);

  return { addCartItem: fetch, isLoaded };
};
