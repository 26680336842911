import { Icon } from "App/Atomics/Icon";
import { ModalAction, ModalStore, ModalType } from "App/Store/ModalStore";
import { Color } from "Constants/Color";
import { AccountLogin, AccountSignup } from "Constants/Locale";
import { Size } from "Constants/Size";
import { Modal } from "Lib/modal";
import styled from "styled-components";
import { SignInForm } from "./SignInForm";
import { SignUpForm } from "./SignUpForm";

type Props = {
  readonly isOpen: boolean;
  readonly type: ModalType;
};

export const AuthModal = ({ isOpen, type }: Props) => {
  const onRequestClose = () => ModalStore.dispatch(ModalAction.closeAll());

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Layout>
        <Container>
          <Header>
            <LogoIcon name="logo-white" />
          </Header>

          <TabContainer>
            <Nav>
              <NavItem isActive={type === ModalType.SIGNIN} onClick={() => ModalStore.dispatch(ModalAction.replace(ModalType.SIGNIN))}>
                <AccountLogin />
              </NavItem>
              <NavItem isActive={type === ModalType.SIGNUP} onClick={() => ModalStore.dispatch(ModalAction.replace(ModalType.SIGNUP))}>
                <AccountSignup />
              </NavItem>
            </Nav>
          </TabContainer>

          {type === ModalType.SIGNIN && <SignInForm />}
          {type === ModalType.SIGNUP && <SignUpForm />}
        </Container>
      </Layout>
    </Modal>
  );
};

const Layout = styled.div`
  pointer-events: auto;
  background-color: ${Color.Ac_Shadow};
  width: 23rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Size.PADDING_X_LARGE_PX} ${Size.PADDING_X_LARGE_PX};
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 ${Size.PADDING_X_LARGE_PX} ${Size.PADDING_LARGE_PX};
`;
const TabContainer = styled.div`
  width: 100%;
`;
const Nav = styled.ul`
  padding: ${Size.PADDING_SMALL_PX} 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;
const NavItem = styled.li<{ isActive?: boolean }>`
  text-align: center;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  padding: ${Size.PADDING_SMALL_PX} 0;
  transition: all 0.15s ease-in;
  ${props => props.isActive && `border-bottom-color: ${Color.AC_Carrot}`};
  &:hover {
    color: ${Color.GRAY_3};
  }
`;

const LogoIcon = styled(Icon)`
  width: 11rem;
`;
