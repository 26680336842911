import { SECOND } from "Lib/time";
import { shuffle as _shuffle, noop } from "lodash";
import { Reducer } from "react";
import { hash as defaultHash } from "Utils/hash";
import { createConfigActions, nextRepeat as defaultNextRepeat, Repeat } from "./createConfigActions";
import { createIndexActions } from "./createIndexActions";
import { createPlayingActions, Playing } from "./createPlayerActions";
import {
  AutoplayEvent,
  ClearEvent,
  createPlayerEvent,
  CurrentEvent,
  CurrentTimeEvent,
  EventKind,
  MuteEvent,
  NeedDurationEvent,
  NextEvent,
  PlayingEvent,
  PlayingTimerEvent,
  PreviousEvent,
  ProgressEvent,
  RepeatEvent,
  SeekEvent,
  SelectEvent,
  ShuffleEvent,
  UnselectEvent,
  VolumeEvent
} from "./createPlayerEvent";
import { createPlaylistActions, Metadata } from "./createPlaylistActions";
import { createProgressActions } from "./createProgressActions";
import { createSeekActions } from "./createSeekActions";
import { createSelectionActions } from "./createSelectionActions";
import { createStoreLogger } from "./createStoreLogger";
import { createTimeActions } from "./createTimeActions";
import { combineReducers, createStore } from "./store";
import { Hash, HashedTrack } from "./types";

export enum Mode {
  Default,
  Debug,
  Production
}

export type ArtistsCardPlayerOption<Track> = {
  readonly hash?: (track: Track) => Hash;
  readonly nextRepeat?: (repeat: Repeat) => Repeat;
  readonly mode?: Mode;
  readonly playingTimer?: number;
};

export type Shuffler = (indexList: readonly number[]) => number[];

export type Sorter<Track> = (trackList: Readonly<Metadata<Track>>[]) => Promise<readonly Readonly<Metadata<Track>>[]>;

export type Serial<T> = {
  readonly previous: T;
  readonly current: T;
  readonly next: T;
};

export function createPlayer<TrackID>({
  hash = defaultHash,
  nextRepeat: _nextRepeat = defaultNextRepeat,
  mode = Mode.Default,
  playingTimer = 0
}: ArtistsCardPlayerOption<TrackID> = {}) {
  const { addEventListener, emit, on } = createPlayerEvent<TrackID>();

  const emitClear = (current: null | TrackID): void => emit(EventKind.Clear, new ClearEvent(current));
  const emitPlaying = (current: null | TrackID, playing: Playing): void => emit(EventKind.Playing, new PlayingEvent(current, playing));
  const emitMute = (current: null | TrackID, isMuted: boolean): void => emit(EventKind.Mute, new MuteEvent(current, isMuted));
  const emitVolume = (current: null | TrackID, volume: number): void => emit(EventKind.Volume, new VolumeEvent(current, volume));
  const emitAutoplay = (current: null | TrackID, autoplay: boolean): void => emit(EventKind.Autoplay, new AutoplayEvent(current, autoplay));
  const emitRepeat = (current: null | TrackID, repeat: Repeat): void => emit(EventKind.Repeat, new RepeatEvent(current, repeat));
  const emitShuffle = (current: null | TrackID, isShuffle: boolean): void => emit(EventKind.Shuffle, new ShuffleEvent(current, isShuffle));
  const emitPrevious = (current: null | TrackID): void => emit(EventKind.Previous, new PreviousEvent(current));
  const emitNext = (current: null | TrackID): void => emit(EventKind.Next, new NextEvent(current));
  const emitCurrent = (current: null | TrackID): void => emit(EventKind.Current, new CurrentEvent(current));
  const emitSelect = (current: null | TrackID, trackList: readonly TrackID[], indexList: readonly number[]): void =>
    emit(EventKind.Select, new SelectEvent(current, trackList, indexList));
  const emitUnselect = (current: null | TrackID, trackList: readonly TrackID[], indexList: readonly number[]): void =>
    emit(EventKind.Unselect, new UnselectEvent(current, trackList, indexList));
  const emitCurrentTime = (current: null | TrackID, currentTime: number): void =>
    emit(EventKind.CurrentTime, new CurrentTimeEvent(current, currentTime));
  const emitProgress = (current: null | TrackID, progress: number): void => emit(EventKind.Progress, new ProgressEvent(current, progress));
  const emitSeek = (current: null | TrackID, seek: null | number): void => emit(EventKind.Seek, new SeekEvent(current, seek));
  const emitNeedDuration = (current: null | TrackID): void => emit(EventKind.NeedDuration, new NeedDurationEvent(current));
  const emitPlayingTimer = (current: TrackID): void => emit(EventKind.PlayingTimer, new PlayingTimerEvent(current));

  const emitBy = (callback: (currentTrack: null | TrackID) => void): void => callback(currentTrack());

  const PlayerActions = createPlayingActions();
  const PlaylistActions = createPlaylistActions<TrackID>();
  const ConfigActions = createConfigActions();
  const IndexActions = createIndexActions();
  const SelectionActions = createSelectionActions();
  const SeekActions = createSeekActions();
  const ProgressActions = createProgressActions();
  const TimeActions = createTimeActions();

  const currentReducer = combineReducers(
    {
      Player: PlayerActions,
      Playlist: PlaylistActions,
      Config: ConfigActions,
      Index: IndexActions,
      Selection: SelectionActions
    },
    createStoreLogger("ArtistsCardPlayer::Current")
  );
  const { useSelector: useCurrentSelector, dispatch, getState: currentStore, ...CurrentStore } = createStore(currentReducer);

  type CurrentStore = typeof currentReducer extends Reducer<infer SS, any> ? SS : never;

  // Time은 특성상 렌더링을 자주 하게 되므로, 다른 부분에 영향이 가지 않도록 별도의 Store를 만들어 최적화.
  const { useSelector: useTimeSelector, ...TimeStore } = createStore(combineReducers({ Time: TimeActions }, noop));

  // Progress는 특성상 렌더링을 자주 하게 되므로, 다른 부분에 영향이 가지 않도록 별도의 Store를 만들어 최적화.
  const { useSelector: useProgressSelector, ...ProgressStore } = createStore(
    combineReducers({ Progress: ProgressActions }, mode === Mode.Production ? noop : createStoreLogger("ArtistsCardPlayer::Progress"))
  );

  // Seek는 특성상 렌더링을 자주 하게 되므로, 다른 부분에 영향이 가지 않도록 별도의 Store를 만들어 최적화.
  const { useSelector: useSeekSelector, ...SeekStore } = createStore(
    combineReducers({ Seek: SeekActions }, mode === Mode.Production ? noop : createStoreLogger("ArtistsCardPlayer::Seek"))
  );

  const previousCursor = (store = currentStore()): number => {
    const current = currentCursor(store);
    return current === 0 ? store.Index.hashList.length - 1 : current - 1;
  };
  const currentCursor = (store = currentStore()): number => store.Index.cursor;
  const nextCursor = (store = currentStore()): number => (currentCursor(store) + 1) % store.Index.hashList.length;

  const previousIndex = (store = currentStore()): number => store.Index.indexList[previousCursor(store)];
  const currentIndex = (store = currentStore()): number => store.Index.indexList[currentCursor(store)];
  const nextIndex = (store = currentStore()): number => store.Index.indexList[nextCursor(store)];

  const previousHash = (store = currentStore()): Hash => store.Index.hashList[previousIndex(store)];
  const currentHash = (store = currentStore()): Hash => store.Index.hashList[currentIndex(store)];
  const nextHash = (store = currentStore()): Hash => store.Index.hashList[nextIndex(store)];

  const previousTrack = (store = currentStore()): null | TrackID => store.Playlist.trackMap.get(previousHash(store))?.track ?? null;
  const currentTrack = (store = currentStore()): null | TrackID => store.Playlist.trackMap.get(currentHash(store))?.track ?? null;
  const nextTrack = (store = currentStore()): null | TrackID => store.Playlist.trackMap.get(nextHash(store))?.track ?? null;

  const needDuration = (current: null | TrackID): void => {
    if (current !== null) {
      const currentHash = hash(current);
      const currentState = currentStore();
      if (currentState.Playlist.trackMap.get(currentHash)!.duration === null) {
        emitNeedDuration(current);
      }
    }
  };

  // * Player
  const play = (): void => {
    dispatch(PlayerActions.setPlaying(Playing.Play));
    emitBy(currentTrack => {
      emitPlaying(currentTrack, Playing.Play);
      needDuration(currentTrack);
    });
  };
  const pause = (): void => {
    dispatch(PlayerActions.setPlaying(Playing.Pause));
    emitBy(currentTrack => emitPlaying(currentTrack, Playing.Pause));
  };
  const stop = (): void => {
    TimeStore.dispatch(TimeActions.set(0));
    dispatch(PlayerActions.setPlaying(Playing.Stop));

    emitBy(currentTrack => {
      emitPlaying(currentTrack, Playing.Stop);
      emitCurrentTime(currentTrack, 0);
    });
  };
  const togglePlay = (): void => {
    if (currentStore().Player.playing === Playing.Play) {
      pause();
    } else {
      play();
    }
  };
  const setPlaying = (playing: Playing): void => {
    switch (playing) {
      case Playing.Play: {
        play();
        break;
      }
      case Playing.Pause: {
        pause();
        break;
      }
      case Playing.Stop: {
        stop();
        break;
      }
    }
  };
  const end = (): void => {
    emitBy(currentTrack => emitPlaying(currentTrack, Playing.Stop));
    const currentState = currentStore();
    if (currentState.Config.repeat === Repeat.Current) {
      TimeStore.dispatch(TimeActions.set(0));
      dispatch(PlayerActions.setPlaying(Playing.Play));
      emitBy(currentTrack => {
        emitPlaying(currentTrack, Playing.Play);
        emitCurrentTime(currentTrack, 0);
      });
      return;
    }
    const size = currentState.Index.hashList.length;
    if (size !== 0) {
      dispatch(IndexActions.setCursor(nextIndex(currentState)));
      emitBy(currentTrack => {
        emitNext(currentTrack);
        emitCurrent(currentTrack);
      });
    }
    {
      const currentState = currentStore();
      if (currentState.Index.cursor === 0 && currentState.Config.repeat !== Repeat.All) {
        pause();
      }
    }
  };

  // * Config
  const mute = (): void => {
    if (currentStore().Config.isMuted) {
      return;
    }

    dispatch(ConfigActions.mute(true));
    emitMute(currentTrack(), true);
  };
  const unmute = (): void => {
    if (!currentStore().Config.isMuted) {
      return;
    }

    dispatch(ConfigActions.mute(false));
    emitMute(currentTrack(), false);
  };
  const toggleMute = (): void => {
    if (currentStore().Config.isMuted) {
      unmute();
    } else {
      mute();
    }
  };

  const _volume = (volume: number): void => {
    dispatch(ConfigActions.volume(volume));
    emitVolume(currentTrack(), currentStore().Config.volume);
  };
  const addVolume = (volume: number): void => _volume(currentStore().Config.volume + volume);

  const preloadPrevious = (count: number): void => dispatch(ConfigActions.setPreloadPreviousCount(count));
  const preloadNext = (count: number): void => dispatch(ConfigActions.setPreloadNextCount(count));

  const autoplay = (): void => {
    if (currentStore().Config.isAutoPlay) {
      return;
    }

    dispatch(ConfigActions.setAutoplay(true));
    emitAutoplay(currentTrack(), true);
  };
  const unAutoplay = (): void => {
    if (!currentStore().Config.isAutoPlay) {
      return;
    }

    dispatch(ConfigActions.setAutoplay(false));
    emitAutoplay(currentTrack(), false);
  };
  const toggleAutoplay = (): void => {
    if (currentStore().Config.isAutoPlay) {
      unAutoplay();
    } else {
      autoplay();
    }
  };

  const nextRepeat = (): void => {
    const currentRepeat = currentStore().Config.repeat;
    switch (_nextRepeat(currentRepeat)) {
      case Repeat.Stop: {
        repeatStop();
        break;
      }
      case Repeat.All: {
        repeatAll();
        break;
      }
      case Repeat.Current: {
        repeatCurrent();
        break;
      }
    }
  };
  const repeatStop = (): void => {
    if (currentStore().Config.repeat === Repeat.Stop) {
      return;
    }

    dispatch(ConfigActions.setRepeat(Repeat.Stop));

    emitRepeat(currentTrack(), Repeat.Stop);
  };
  const repeatAll = (): void => {
    if (currentStore().Config.repeat === Repeat.All) {
      return;
    }

    dispatch(ConfigActions.setRepeat(Repeat.All));

    emitRepeat(currentTrack(), Repeat.All);
  };
  const repeatCurrent = (): void => {
    if (currentStore().Config.repeat === Repeat.Current) {
      return;
    }

    dispatch(ConfigActions.setRepeat(Repeat.Current));

    emitRepeat(currentTrack(), Repeat.Current);
  };

  const shuffle = (shuffle: Shuffler = _shuffle): void => {
    const currentState = currentStore();
    const current = currentIndex(currentState);
    const indexList = shuffle(currentState.Index.indexList);
    const nextCursor = indexList.findIndex(index => index === current);
    dispatch([IndexActions.setCursor(nextCursor), IndexActions.setIndexList(indexList), ConfigActions.shuffle(true)]);

    emitShuffle(currentTrack(), true);
  };
  const unshuffle = (): void => {
    if (!currentStore().Config.isShuffle) {
      return;
    }

    const currentState = currentStore();
    const current = currentIndex(currentState);
    const indexList = Array.from({ length: currentState.Index.hashList.length }, (_, index) => index);
    const nextCursor = indexList.findIndex(index => index === current);
    dispatch([IndexActions.setCursor(nextCursor), IndexActions.setIndexList(indexList), ConfigActions.shuffle(false)]);

    emitShuffle(currentTrack(), false);
  };
  const toggleShuffle = (shuffler: Shuffler = _shuffle): void => {
    if (currentStore().Config.isShuffle) {
      unshuffle();
    } else {
      shuffle(shuffler);
    }
  };

  const sort = async (fn: Sorter<TrackID>): Promise<void> => {
    const current = currentHash();
    const trackList = await fn(Array.from(currentStore().Playlist.trackMap.values()));
    const currentIndex = trackList.findIndex(({ track }) => hash(track) === current);
    const nextCursor = currentStore().Index.indexList.findIndex(index => index === currentIndex);
    dispatch([PlaylistActions.upsertList(trackList.map(({ track }) => [hash(track), track])), IndexActions.setCursor(nextCursor)]);
  };

  // * Playlist
  const clear = (): void => {
    dispatch([PlaylistActions.clear(), IndexActions.clear(), PlayerActions.setPlaying(Playing.Stop)]);

    emitBy(currentTrack => {
      emitPlaying(currentTrack, Playing.Stop);
      emitClear(currentTrack);
    });
  };
  const insertToFirst = (track: TrackID, shuffler: Shuffler = _shuffle): void => insertListToFirst([track], shuffler);
  const insertListToFirst = (trackList: readonly TrackID[], shuffler: Shuffler = _shuffle): void => insertList(trackList, 0, shuffler);
  const insertToLast = (track: TrackID, shuffler: Shuffler = _shuffle): void => insertListToLast([track], shuffler);
  const insertListToLast = (trackList: readonly TrackID[], shuffler: Shuffler = _shuffle) =>
    insertList(trackList, currentStore().Index.indexList.length, shuffler);
  const insertToNext = (track: TrackID, shuffler: Shuffler = _shuffle): void => insertListToNext([track], shuffler);
  const insertListToNext = (trackList: readonly TrackID[], shuffler: Shuffler = _shuffle): void => {
    insertList(trackList, currentIndex() + 1, shuffler);
  };
  const insert = (track: TrackID, index: number, shuffler: Shuffler = _shuffle): void => insertList([track], index, shuffler);
  const insertList = (trackList: readonly TrackID[], index: number, shuffler: Shuffler = _shuffle): void => {
    if (!trackList.length) {
      return;
    }
    const hashedTrackList = trackList.map(track => [hash(track), track] as const);
    const hashList = hashedTrackList.map(([hash]) => hash);
    dispatch([PlaylistActions.upsertList(hashedTrackList), IndexActions.upsertList(hashList, index)]);
    if (currentStore().Config.isShuffle) {
      shuffle(shuffler);
    }
  };
  const remove = (track: TrackID, shuffler: Shuffler = _shuffle): void => removeList([track], shuffler);
  const removeList = (trackList: readonly TrackID[], shuffler: Shuffler = _shuffle): void => {
    if (!trackList.length) {
      return;
    }
    const current = currentHash()!;
    const hashList = trackList.map(hash);
    dispatch([PlaylistActions.removeList(hashList), IndexActions.removeList(hashList)]);
    const store = currentStore();
    if (!store.Playlist.trackMap.size) {
      emitPlaying(null, Playing.Stop);
      return;
    }
    if (store.Config.isShuffle) {
      shuffle(shuffler);
    }
    const next = currentHash(store);
    if (current !== next) {
      const currentTrack = store.Playlist.trackMap.get(next)!.track;
      emitCurrent(currentTrack);
      emitPlaying(currentTrack, Playing.Play);
    }
  };
  const replace = (track: TrackID, shuffler: Shuffler = _shuffle): void => replaceList([track], shuffler);
  const replaceList = (trackList: ReadonlyArray<TrackID>, shuffler: Shuffler = _shuffle): void => {
    if (!trackList.length) {
      return;
    }
    const current = currentHash()!;
    const hashedTrackList = trackList.map(track => [hash(track), track] as const);
    const hashList = hashedTrackList.map(([hash]) => hash);
    dispatch([
      PlaylistActions.clear(),
      IndexActions.clear(),
      PlaylistActions.upsertList(hashedTrackList),
      IndexActions.insertList(hashList, 0)
    ]);
    const store = currentStore();
    if (!store.Playlist.trackMap.size) {
      emitPlaying(null, Playing.Stop);
      return;
    }
    if (store.Config.isShuffle) {
      shuffle(shuffler);
    }
    const next = currentHash(store);
    if (current !== next) {
      const currentTrack = store.Playlist.trackMap.get(next)!.track;
      emitCurrent(currentTrack);
      emitPlaying(currentTrack, Playing.Play);
    }
  };
  const updateTrack = (track: TrackID): void => updateTrackList([track]);
  const updateTrackList = (trackList: readonly TrackID[]): void =>
    dispatch(PlaylistActions.upsertList(trackList.map(track => [hash(track), track])));
  const setDuration = (track: TrackID, duration: number): void => setDurationList([[track, duration]]);
  const setDurationList = (durationList: ReadonlyArray<readonly [TrackID, number]>): void =>
    dispatch(PlaylistActions.setDurationList(durationList.map(([track, maxTime]) => [hash(track), maxTime])));
  const setMaxTime = (track: TrackID, maxTime: number): void => setMaxTimeList([[track, maxTime]]);
  const setMaxTimeList = (maxTimeList: ReadonlyArray<readonly [TrackID, number]>): void =>
    dispatch(PlaylistActions.setMaxTimeList(maxTimeList.map(([track, maxTime]) => [hash(track), maxTime])));

  // * Index
  const current = (track: TrackID): void => {
    emitBy(currentTrack => emitPlaying(currentTrack, Playing.Stop));
    dispatch([IndexActions.current(hash(track)), PlayerActions.setPlaying(Playing.Play)]);
    emitBy(currentTrack => {
      emitCurrent(currentTrack);
      needDuration(currentTrack);
    });
  };
  const previous = (): void => {
    const currentState = currentStore();
    const size = currentState.Index.hashList.length;
    if (size === 0) {
      return;
    }

    emitBy(currentTrack => emitPlaying(currentTrack, Playing.Stop));

    dispatch([IndexActions.setCursor(previousCursor(currentState)), PlayerActions.setPlaying(Playing.Play)]);

    emitBy(currentTrack => {
      emitPrevious(currentTrack);
      emitCurrent(currentTrack);
      emitPlaying(currentTrack, Playing.Play);
      needDuration(currentTrack);
    });
  };
  const next = (): void => {
    const currentState = currentStore();
    const size = currentState.Index.hashList.length;
    if (size === 0) {
      return;
    }

    emitBy(currentTrack => emitPlaying(currentTrack, Playing.Stop));

    dispatch([IndexActions.setCursor(nextCursor(currentState)), PlayerActions.setPlaying(Playing.Play)]);

    emitBy(currentTrack => {
      emitNext(currentTrack);
      emitCurrent(currentTrack);
      emitPlaying(currentTrack, Playing.Play);
      needDuration(currentTrack);
    });
  };
  const moveTo = (track: TrackID, index: number): void => dispatch(IndexActions.moveTo(hash(track), index));

  // * Selection
  const select = (track: TrackID): void => selectList([track]);
  const selectList = (trackList: readonly TrackID[]): void => {
    dispatch(SelectionActions.selectList(trackList.map(hash)));

    const store = currentStore();
    const hashList = Array.from(store.Selection.selectionSet);
    const selectedTrackList = hashList.map(hash => store.Playlist.trackMap.get(hash)!.track);
    const selectIndexList = hashList.map(hash => store.Index.hashList.findIndex(currentHash => currentHash === hash));
    emitSelect(currentTrack(), selectedTrackList, selectIndexList);
  };
  const selectAll = (): void => selectList(Array.from(currentStore().Playlist.trackMap.values(), ({ track }) => track));

  const unselect = (track: TrackID): void => unselectList([track]);
  const unselectList = (trackList: readonly TrackID[]): void => {
    dispatch(SelectionActions.unselectList(trackList.map(hash)));

    const store = currentStore();
    const selectSet = store.Selection.selectionSet;
    const unselectedMetadataList = Array.from(store.Playlist.trackMap).filter(([hash]) => !selectSet.has(hash));
    const unselectedTrackList = unselectedMetadataList.map(metadata => metadata[1].track);
    const unselectIndexList = unselectedMetadataList.map(([hash]) => store.Index.hashList.findIndex(currentHash => currentHash === hash));
    emitUnselect(currentTrack(), unselectedTrackList, unselectIndexList);
  };
  const unselectAll = (): void => unselectList(Array.from(currentStore().Playlist.trackMap.values(), ({ track }) => track));

  let playingHash: null | Hash = null;
  let playingSecond = playingTimer;
  let playingTime = Date.now();

  // * Time
  const currentTime = (time: number, isStopPropagate = false): void => {
    {
      const current = currentHash();
      const currentTime = Date.now();
      if (current === playingHash) {
        if (playingSecond !== 0) {
          const timeDiff = currentTime - playingTime;
          if (SECOND <= timeDiff) {
            playingSecond += -1;
            playingTime = currentTime;
            if (playingSecond === 0) {
              emitPlayingTimer(currentTrack()!);
            }
          }
        }
      } else {
        playingHash = current;
        playingSecond = playingTimer;
        playingTime = currentTime;
      }
    }

    TimeStore.dispatch(TimeActions.set(time));
    if (!isStopPropagate) {
      SeekStore.dispatch(SeekActions.set(null));
    }

    const current = track(currentStore(), currentHash());
    const maxTime = current?.maxTime ?? null;
    const currentTime = TimeStore.getState().Time.time;
    if (maxTime !== null && maxTime <= currentTime) {
      end();
    } else if (!isStopPropagate) {
      emitCurrentTime(current?.track ?? null, currentTime);
    }
  };
  const addCurrentTime = (time: number, isStopPropagate = false): void =>
    currentTime(TimeStore.getState().Time.time + time, isStopPropagate);

  // * Progeress
  const progress = (time: number): void => {
    ProgressStore.dispatch(ProgressActions.set(time));

    emitProgress(currentTrack(), ProgressStore.getState().Progress.time);
  };

  // * Seek
  const seek = (time: number): void => {
    SeekStore.dispatch(SeekActions.set(time));

    emitSeek(currentTrack(), SeekStore.getState().Seek.time);
  };

  // * Utility Hooks
  const track = (store: CurrentStore, hash: Hash): null | HashedTrack<TrackID> => {
    const currentTrack = store.Playlist.trackMap.get(hash);
    return !currentTrack
      ? null
      : {
          ...currentTrack,
          hash,
          isCurrent: currentHash(store) === hash,
          isSelected: store.Selection.selectionSet.has(hash)
        };
  };

  const useCurrent = (): null | HashedTrack<TrackID> => useCurrentSelector(store => track(store, currentHash(store)));
  const usePlaylist = (): readonly HashedTrack<TrackID>[] =>
    useCurrentSelector(store =>
      store.Index.hashList.map(hash => track(store, hash)).filter((hashed): hashed is HashedTrack<TrackID> => hashed !== null)
    );
  const usePreload = (): readonly HashedTrack<TrackID>[] =>
    useCurrentSelector(store => {
      const previousHashList = store.Index.hashList.slice(currentCursor() - 1, -store.Config.preloadPrevious);
      const nextHashList = store.Index.hashList.slice(currentCursor() + 1, store.Config.preloadNext);
      const previous = previousHashList.map(hash => track(store, hash)).filter((hashed): hashed is HashedTrack<TrackID> => hashed !== null);
      const next = nextHashList.map(hash => track(store, hash)).filter((hashed): hashed is HashedTrack<TrackID> => hashed !== null);
      const previousTrackList = previous.map(hashedTrack => [hashedTrack.hash, hashedTrack] as const);
      const nextTrackList = next.map(hashedTrack => [hashedTrack.hash, hashedTrack] as const);
      const trackMap = new Map(previousTrackList.concat(nextTrackList));
      return [...trackMap.values()];
    });
  const usePlayer = () => useCurrentSelector(store => ({ ...store.Config, playing: store.Player.playing }));
  const useCurrentTime = (): number => useTimeSelector(store => store.Time.time, Object.is);
  const useProgress = (): number => useProgressSelector(store => store.Progress.time, Object.is);
  const useSeek = (): null | number => useSeekSelector(store => store.Seek.time, Object.is);
  const useCursorSerial = (): Serial<number> =>
    useCurrentSelector(store => ({
      previous: previousCursor(store),
      current: currentCursor(store),
      next: nextCursor(store)
    }));
  const useIndexSerial = (): Serial<number> =>
    useCurrentSelector(store => ({
      previous: previousIndex(store),
      current: currentIndex(store),
      next: nextIndex(store)
    }));
  const useHashSerial = (): Serial<Hash> =>
    useCurrentSelector(store => ({
      previous: previousHash(store),
      current: currentHash(store),
      next: nextHash(store)
    }));
  const useTrackSerial = (): Serial<null | TrackID> =>
    useCurrentSelector(store => ({
      previous: previousTrack(store),
      current: currentTrack(store),
      next: nextTrack(store)
    }));
  const useHashedTrackSerial = (): Serial<null | HashedTrack<TrackID>> =>
    useCurrentSelector(store => ({
      previous: track(store, previousHash(store)),
      current: track(store, currentHash(store)),
      next: track(store, nextHash(store))
    }));

  const getCurrent = (): null | TrackID => {
    const store = currentStore();
    return track(store, currentHash(store))?.track ?? null;
  };
  const getPlaying = (): Playing => currentStore().Player.playing;
  const getPlayList = (): TrackID[] => Array.from(currentStore().Playlist.trackMap.values(), ({ track }) => track);

  return {
    addEventListener,
    on,

    // * Player
    play,
    pause,
    stop,
    togglePlay,
    setPlaying,
    end,

    // * Config
    mute,
    unmute,
    toggleMute,

    volume: _volume,
    addVolume,

    preloadPrevious,
    preloadNext,

    autoplay,
    unAutoplay,
    toggleAutoplay,

    nextRepeat,
    repeatStop,
    repeatAll,
    repeatCurrent,

    shuffle,
    unshuffle,
    toggleShuffle,

    sort,

    // * Playlist
    clear,
    insertToFirst,
    insertListToFirst,
    insertToLast,
    insertListToLast,
    insertToNext,
    insertListToNext,
    insert,
    insertList,
    remove,
    removeList,
    replace,
    replaceList,
    updateTrack,
    updateTrackList,
    setDuration,
    setDurationList,
    setMaxTime,
    setMaxTimeList,

    // * Index
    current,
    previous,
    next,
    moveTo,

    // * Selection
    select,
    selectList,
    selectAll,
    unselect,
    unselectList,
    unselectAll,

    // * Time
    currentTime,
    addCurrentTime,

    // * Progress
    progress,

    // * Seek
    seek,

    // * Utility Hooks
    useCurrent,
    usePlaylist,
    usePreload,
    usePlayer,
    useCurrentTime,
    useProgress,
    useSeek,
    useCursorSerial,
    useIndexSerial,
    useHashSerial,
    useTrackSerial,
    useHashedTrackSerial,

    getCurrent,
    getPlaying,
    getPlayList
  };
}
