import { random } from "Lib/random";
import { NumericID } from "Utils/id";

export class AccountID extends NumericID {
  static random(): AccountID {
    return new AccountID(random());
  }

  constructor(id: number) {
    super("account", id);
  }
}
