import { Repeat } from "./createConfigActions";
import { Playing } from "./createPlayerActions";

export enum EventKind {
  Clear = "onclear",
  Playing = "onplaying",
  Mute = "onmute",
  Volume = "onvolume",
  Autoplay = "onautoplay",
  Repeat = "onrepeat",
  Shuffle = "onshuffle",
  Previous = "onprevious",
  Next = "onnext",
  Current = "oncurrent",
  Select = "onselect",
  Unselect = "onunselect",
  CurrentTime = "oncurrenttime",
  Progress = "onprogress",
  Seek = "onseek",
  NeedDuration = "onneedduration",
  PlayingTimer = "onplayingtimer"
}

type EventListener<TrackID, Ret> = {
  (event: EventKind.Clear, handler: ClearEventHandler<TrackID>): Ret;
  (event: EventKind.Playing, handler: PlayingEventHandler<TrackID>): Ret;
  (event: EventKind.Mute, handler: MuteEventHandler<TrackID>): Ret;
  (event: EventKind.Volume, handler: VolumeEventHandler<TrackID>): Ret;
  (event: EventKind.Autoplay, handler: AutoplayEventHandler<TrackID>): Ret;
  (event: EventKind.Repeat, handler: RepeatEventHandler<TrackID>): Ret;
  (event: EventKind.Shuffle, handler: ShuffleEventHandler<TrackID>): Ret;
  (event: EventKind.Previous, handler: PreviousEventHandler<TrackID>): Ret;
  (event: EventKind.Next, handler: NextEventHandler<TrackID>): Ret;
  (event: EventKind.Current, handler: CurrentEventHandler<TrackID>): Ret;
  (event: EventKind.Select, handler: SelectEventHandler<TrackID>): Ret;
  (event: EventKind.Unselect, handler: UnselectEventHandler<TrackID>): Ret;
  (event: EventKind.CurrentTime, handler: CurrentTimeEventHandler<TrackID>): Ret;
  (event: EventKind.Progress, handler: ProgressEventHandler<TrackID>): Ret;
  (event: EventKind.Seek, handler: SeekEventHandler<TrackID>): Ret;
  (event: EventKind.NeedDuration, handler: NeedDurationEventHandler<TrackID>): Ret;
  (event: EventKind.PlayingTimer, handler: PlayingTimerEventHandler<TrackID>): Ret;
};

export type AddEventListener<TrackID> = EventListener<TrackID, Unsubscriber>;
export type On<TrackID> = EventListener<TrackID, void>;
export type Unsubscribe<TrackID> = EventListener<TrackID, void>;

export class PlayerEvent<TrackID> {
  constructor(readonly currentTrack: null | TrackID) {}
}

export class ClearEvent<TrackID> extends PlayerEvent<TrackID> {}

export class PlayingEvent<TrackID> extends PlayerEvent<TrackID> {
  constructor(currentTrack: null | TrackID, readonly playing: Playing) {
    super(currentTrack);
  }
}

export class MuteEvent<TrackID> extends PlayerEvent<TrackID> {
  constructor(currentTrack: null | TrackID, readonly isMuted: boolean) {
    super(currentTrack);
  }
}

export class VolumeEvent<TrackID> extends PlayerEvent<TrackID> {
  constructor(currentTrack: null | TrackID, readonly volume: number) {
    super(currentTrack);
  }
}

export class AutoplayEvent<TrackID> extends PlayerEvent<TrackID> {
  constructor(currentTrack: null | TrackID, readonly autoplay: boolean) {
    super(currentTrack);
  }
}

export class RepeatEvent<TrackID> extends PlayerEvent<TrackID> {
  constructor(currentTrack: null | TrackID, readonly repeat: Repeat) {
    super(currentTrack);
  }
}

export class ShuffleEvent<TrackID> extends PlayerEvent<TrackID> {
  constructor(currentTrack: null | TrackID, readonly isShuffle: boolean) {
    super(currentTrack);
  }
}

export class PreviousEvent<TrackID> extends PlayerEvent<TrackID> {}

export class NextEvent<TrackID> extends PlayerEvent<TrackID> {}

export class CurrentEvent<TrackID> extends PlayerEvent<TrackID> {}

export class SelectEvent<TrackID> extends PlayerEvent<TrackID> {
  constructor(currentTrack: null | TrackID, readonly trackList: readonly TrackID[], readonly indexList: readonly number[]) {
    super(currentTrack);
  }
}

export class UnselectEvent<TrackID> extends PlayerEvent<TrackID> {
  constructor(currentTrack: null | TrackID, readonly trackList: readonly TrackID[], readonly indexList: readonly number[]) {
    super(currentTrack);
  }
}

export class CurrentTimeEvent<TrackID> extends PlayerEvent<TrackID> {
  constructor(currentTrack: null | TrackID, readonly currentTime: number) {
    super(currentTrack);
  }
}

export class ProgressEvent<TrackID> extends PlayerEvent<TrackID> {
  constructor(currentTrack: null | TrackID, readonly progress: number) {
    super(currentTrack);
  }
}

export class SeekEvent<TrackID> extends PlayerEvent<TrackID> {
  constructor(currentTrack: null | TrackID, readonly seek: null | number) {
    super(currentTrack);
  }
}

export class NeedDurationEvent<TrackID> extends PlayerEvent<TrackID> {}

export class PlayingTimerEvent<TrackID> extends PlayerEvent<TrackID> {
  constructor(readonly currentTrack: TrackID) {
    super(currentTrack);
  }
}

export type ClearEventHandler<TrackID> = (event: ClearEvent<TrackID>) => void;
export type PlayingEventHandler<TrackID> = (event: PlayingEvent<TrackID>) => void;
export type MuteEventHandler<TrackID> = (event: MuteEvent<TrackID>) => void;
export type VolumeEventHandler<TrackID> = (event: VolumeEvent<TrackID>) => void;
export type AutoplayEventHandler<TrackID> = (event: AutoplayEvent<TrackID>) => void;
export type RepeatEventHandler<TrackID> = (event: RepeatEvent<TrackID>) => void;
export type ShuffleEventHandler<TrackID> = (event: ShuffleEvent<TrackID>) => void;
export type PreviousEventHandler<TrackID> = (event: PreviousEvent<TrackID>) => void;
export type NextEventHandler<TrackID> = (event: NextEvent<TrackID>) => void;
export type CurrentEventHandler<TrackID> = (event: CurrentEvent<TrackID>) => void;
export type SelectEventHandler<TrackID> = (event: SelectEvent<TrackID>) => void;
export type UnselectEventHandler<TrackID> = (event: UnselectEvent<TrackID>) => void;
export type CurrentTimeEventHandler<TrackID> = (event: CurrentTimeEvent<TrackID>) => void;
export type ProgressEventHandler<TrackID> = (event: ProgressEvent<TrackID>) => void;
export type SeekEventHandler<TrackID> = (event: SeekEvent<TrackID>) => void;
export type NeedDurationEventHandler<TrackID> = (event: NeedDurationEvent<TrackID>) => void;
export type PlayingTimerEventHandler<TrackID> = (event: PlayingTimerEvent<TrackID>) => void;

export type EventHandler<TrackID> = {
  (kind: EventKind.Clear, event: ClearEvent<TrackID>): void;
  (kind: EventKind.Playing, event: PlayingEvent<TrackID>): void;
  (kind: EventKind.Mute, event: MuteEvent<TrackID>): void;
  (kind: EventKind.Volume, event: VolumeEvent<TrackID>): void;
  (kind: EventKind.Autoplay, event: AutoplayEvent<TrackID>): void;
  (kind: EventKind.Repeat, event: RepeatEvent<TrackID>): void;
  (kind: EventKind.Shuffle, event: ShuffleEvent<TrackID>): void;
  (kind: EventKind.Previous, event: PreviousEvent<TrackID>): void;
  (kind: EventKind.Current, event: CurrentEvent<TrackID>): void;
  (kind: EventKind.Next, event: NextEvent<TrackID>): void;
  (kind: EventKind.Select, event: SelectEvent<TrackID>): void;
  (kind: EventKind.Unselect, event: SelectEvent<TrackID>): void;
  (kind: EventKind.CurrentTime, event: CurrentTimeEvent<TrackID>): void;
  (kind: EventKind.Progress, event: ProgressEvent<TrackID>): void;
  (kind: EventKind.Seek, event: SeekEvent<TrackID>): void;
  (kind: EventKind.NeedDuration, event: NeedDurationEvent<TrackID>): void;
  (kind: EventKind.PlayingTimer, event: PlayingTimerEvent<TrackID>): void;
};
export type Unsubscriber = () => void;

export type PlayerEventInstance<TrackID> = {
  readonly emit: EventHandler<TrackID>;
  readonly on: On<TrackID>;
  readonly addEventListener: AddEventListener<TrackID>;
  readonly unsubscribe: Unsubscribe<TrackID>;
  readonly unsubscribeAll: () => void;
};

export const createPlayerEvent = <TrackID>(): PlayerEventInstance<TrackID> => {
  let eventMapWithFunction = createEventMapWithFunction();
  let eventMapWithSet = createEventMapWithSet();

  const emit: EventHandler<TrackID> = (kind: any, event: any): void => {
    const handler = eventMapWithFunction.get(kind);
    handler?.(event);

    for (const handler of eventMapWithSet.get(kind)!) {
      handler(event);
    }
  };

  const on: On<TrackID> = (event: any, handler: any) => {
    eventMapWithFunction.set(event, handler);
  };

  const addEventListener: AddEventListener<TrackID> = (event: any, handler: any) => {
    eventMapWithSet.get(event)!.add(handler);
    return () => {
      eventMapWithSet.get(event)!.delete(handler as any);
    };
  };

  const unsubscribe: Unsubscribe<TrackID> = (event: any, handler: any) => {
    eventMapWithSet.get(event)!.delete(handler);
  };

  const unsubscribeAll = (event?: EventKind): void => {
    if (event === undefined) {
      eventMapWithFunction = createEventMapWithFunction();
      eventMapWithSet = createEventMapWithSet();
    } else {
      eventMapWithFunction.set(event, () => {});
      eventMapWithSet.get(event)!.clear();
    }
  };

  return { emit, on, addEventListener, unsubscribe, unsubscribeAll };
};

export const createEventMapWithFunction = <TrackID>(): Map<EventKind, (event: PlayerEvent<TrackID>) => void> =>
  createEventMap(() => () => {});
export const createEventMapWithSet = <TrackID>(): Map<EventKind, Set<(event: PlayerEvent<TrackID>) => void>> =>
  createEventMap(() => new Set());

export const createEventMap = <Emitter>(emitter: () => Emitter): Map<EventKind, Emitter> =>
  new Map(
    Object.values(EventKind)
      .filter((kind): kind is EventKind => kind.startsWith("on"))
      .map(kind => [kind, emitter()])
  );
