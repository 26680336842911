import { Edge } from "Constants/Edge";
import { createStore } from "Lib/store";
import { PlaylistID } from "Models/PlaylistModel";

const createInitialState = () => ({
  myPlaylists: [] as PlaylistID[],
  isLoadedMyPlaylist: false as boolean,
  edge: null as Edge | null
});

export const {
  useSelector: useMyPlaylistSelector,
  action: MyPlaylistAction,
  ...MyPlaylistStore
} = createStore("MyPlaylist", createInitialState, {
  setEdge(state, edge: Edge | null) {
    state.edge = edge;
  },
  setMyPlaylist(state, myPlaylists: PlaylistID[]) {
    state.myPlaylists = [...myPlaylists];
  },
  setIsLoadedMyPlaylist(state, isLoadedMyPlaylist: boolean) {
    state.isLoadedMyPlaylist = isLoadedMyPlaylist;
  }
});
