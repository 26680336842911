import { setCurationManagement } from "App/Common/setCurationManagement";
import { LicenseNodeStore } from "App/Store/LicenseNodeStore";
import { Edge } from "Constants/Edge";
import { useLoadCurationManagement } from "GraphQL/Queries/useLoadCurationManagement";
import { useLoaded } from "Lib/use-loaded";
import { useEffect, useState } from "react";

export const useCurationManagement = () => {
  //   const [search] = useSearchParams();

  //   const keyword = search.get("keyword") ?? undefined;

  const { data, loading } = useLoadCurationManagement({
    isForce: true
  });
  const [edge, setEdge] = useState<Edge | null>(null);
  const { isLoaded } = useLoaded(loading);

  useEffect(() => {
    LicenseNodeStore.clear();
    if (data) {
      const { curationManagement, edge } = data;
      setCurationManagement(curationManagement);
      setEdge(edge);
    }
  }, [data]);

  return { isLoaded, edge };
};
