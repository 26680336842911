import { FileRuleNodeStore } from "App/Store/FileRuleNodeStore";
import { FileRule } from "GraphQL/Schema";
import { FileRuleID, FileRuleModel } from "Models/FileRuleModel";
import { ProductPriceID } from "Models/ProductPriceModel";

export const setFileRule = (fileRuleList: FileRule[]) => {
  for (const fileRule of fileRuleList) {
    const filelRuleM = FileRuleModel.form({
      id: fileRule.id,
      mp3PriceId: fileRule.mp3_price ? new ProductPriceID(fileRule.mp3_price.id) : null,
      wavPriceId: fileRule.wav_price ? new ProductPriceID(fileRule.wav_price.id) : null,
      amount: (fileRule?.mp3_price?.price ?? 0) + (fileRule?.wav_price?.price ?? 0)
    });
    FileRuleNodeStore.set(new FileRuleID(fileRule.id), filelRuleM);
  }
};
