import { Icon } from "App/Atomics/Icon";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { ModalAction, ModalStore, ModalType } from "App/Store/ModalStore";
import { usePurchaseHistoryNode } from "App/Store/PurchaseHistoryNodeStore";
import { Color } from "Constants/Color";
import { Curation, Track } from "Constants/Locale";
import { purchaseHistoryDetailPath } from "Constants/Routes";
import { DESKTOP_MODE } from "Constants/Size";
import { ProductType } from "GraphQL/Schema";
import { PurchaseHistoryID } from "Models/PurchaseHistoryModel";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { formatDate } from "Utils/format";

type Props = {
  readonly id: PurchaseHistoryID;
};

export const PurchaseHistoryItem = ({ id }: Props) => {
  const purchaseHistory = usePurchaseHistoryNode(id);

  const navigate = useNavigate();
  if (!purchaseHistory) {
    return null;
  }
  const moveToPurchaseDetail = (id: number) => {
    if (purchaseHistory.type === ProductType.Curation) {
      return;
    }
    navigate(purchaseHistoryDetailPath.path({ id }));
  };

  const createPlaylist = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    ModalStore.dispatch(ModalAction.open(ModalType.PLAYLISTS, { tracks: purchaseHistory.productIds }));
  };

  return (
    <Layout onClick={() => moveToPurchaseDetail(id.raw)}>
      <ProductContainer>
        <Typography weight={TypoWeight.Bold} size={TypoSize.F16}>
          {purchaseHistory.type === ProductType.Curation && <Curation />}
          {purchaseHistory.type === ProductType.Track && <Track />}
        </Typography>
      </ProductContainer>
      <AmountContainer>
        <Amount cancel={purchaseHistory.isCancel}>{purchaseHistory.amount.toLocaleString()} 원</Amount>
        <RefundText cancel={purchaseHistory.isCancel}>(환불)</RefundText>
      </AmountContainer>
      <CreateAtContainer>
        <Typography weight={TypoWeight.Bold} size={TypoSize.F16}>
          {formatDate(purchaseHistory.createAt)}
        </Typography>
      </CreateAtContainer>
      <AllCountContainer>
        <Typography weight={TypoWeight.Bold} size={TypoSize.F16}>
          {purchaseHistory.count}개
        </Typography>
      </AllCountContainer>
      <PlaylistCountContainer onClick={createPlaylist}>
        <Icon name="add-playlist" />
      </PlaylistCountContainer>
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
  min-height: 80px;
  display: flex;

  border-bottom: 1px solid ${Color.Ac_Gray};
  padding: 10px 30px;
  cursor: pointer;

  &:hover {
    background-color: ${Color.Ac_Shadow};
    h3 {
      color: ${Color.Ac_Apricot};
    }
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const ProductContainer = styled(Container)`
  flex: 0 0 100px;
  display: flex;
  justify-content: flex-start;
`;

const AmountContainer = styled(Container)`
  flex: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 5rem;
`;

const CreateAtContainer = styled(Container)`
  flex: 0 0 100px;
  display: flex;
  justify-content: flex-end;
`;

const AllCountContainer = styled(Container)`
  flex: 0 0 80px;
  display: flex;
  justify-content: center;
  display: none;
  @media ${DESKTOP_MODE} {
    display: flex;
  }
`;

const PlaylistCountContainer = styled(Container)`
  flex: 0 0 80px;
  display: flex;
  justify-content: center;
  display: none;
  @media ${DESKTOP_MODE} {
    display: flex;
  }
`;

const Amount = styled.span<{ cancel: boolean }>`
  font-weight: bold;
  font-size: 1rem;
  color: ${props => (props.cancel ? Color.Ac_Red1 : Color.Ac_White)};
  text-decoration: ${props => (props.cancel ? "line-through" : "")};
`;

const RefundText = styled.span<{ cancel: boolean }>`
  font-weight: bold;
  font-size: 1rem;
  display: ${props => (props.cancel ? "inline-block" : "none")};
  color: ${Color.Ac_Red1};
  margin-left: 0.25rem;
`;
