import { Input } from "App/Atomics/Input";
import { Color } from "Constants/Color";
import { Composer, Searching, UppercaseGenre, UppercaseMood, UppercaseRole, UppercaseTrack } from "Constants/Locale";
import { HOME, trackDetailPath } from "Constants/Routes";
import { useSearchAutoComplete } from "GraphQL/Queries/useSearchAutoComplete";
import { useDebouncedCallback } from "Lib/use-debounced-callback";
import { useToggle } from "Lib/use-toggle";
import ms from "ms.macro";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MetadataType, useSubclass } from "Utils/useSubclass";

interface Props {
  onSearch: (keyword: string) => void;
  defaultValue?: string;
}

export const SearchForm = ({ onSearch, defaultValue }: Props) => {
  const ref = useRef<HTMLInputElement>(null);
  const focus = useToggle();
  const { searchAutoComplete, data, loading } = useSearchAutoComplete();
  const { metadataType } = useSubclass();
  const navigate = useNavigate();
  const onSubmit = () => {
    const keyword = ref.current!.value.trim();
    ref.current?.blur();
    onSearch(keyword);
  };

  const searchToArtist = (id: string) => {
    navigate({
      pathname: HOME,
      search: `?artist=${id}`
    });
    ref.current?.blur();
  };

  const searchToGenre = (id: string) => {
    navigate({
      pathname: HOME,
      search: `?genres=${id}`
    });
    ref.current?.blur();
  };
  const searchToMood = (id: string) => {
    navigate({
      pathname: HOME,
      search: `?moods=${id}`
    });
    ref.current?.blur();
  };
  const searchToRole = (id: string) => {
    navigate({
      pathname: HOME,
      search: `?roles=${id}`
    });
    ref.current?.blur();
  };

  useEffect(() => {
    if (!defaultValue) {
      ref.current!.value = "";
    }
  }, [defaultValue]);

  const onDebounceSearch = useDebouncedCallback(ms("300ms"), (word, type: MetadataType) => {
    searchAutoComplete({
      variables: {
        word,
        first: 5,
        subclass: type.subClass.toLocaleLowerCase(),
        companyIds: type.companyIds
      }
    });
  });

  const search = (word: string, type: MetadataType) => {
    onDebounceSearch(word, type);
  };

  const isData = !!data?.genre.length || !!data?.mood.length || !!data?.role.length || !!data?.metadata.length || !!data?.artist.length;

  const moveToTrackDetail = (id: string) => navigate(trackDetailPath.path({ id }));

  return (
    <Layout>
      <SearchInput
        ref={ref}
        placeholder="Search..."
        onFocus={focus.on}
        onBlur={focus.off}
        defaultValue={defaultValue}
        onEnter={onSubmit}
        onChange={word => search(word, metadataType)}
      />
      <SearchResultLayout isShow={focus.isToggled}>
        {loading && (
          <SearchResult>
            <SearchItem>
              <Loading>
                <Searching />
              </Loading>
            </SearchItem>
          </SearchResult>
        )}
        {!loading && isData && (
          <SearchResult>
            {data!.genre.map(({ genre_id, name }) => (
              <SearchItem key={genre_id} onClick={() => searchToGenre(genre_id)}>
                <SearchTitle>{name}</SearchTitle>
                <Type>
                  [
                  <UppercaseGenre />]
                </Type>
              </SearchItem>
            ))}
            {data!.mood.map(({ genre_id, name }) => (
              <SearchItem key={genre_id} onClick={() => searchToMood(genre_id)}>
                <SearchTitle>{name}</SearchTitle>
                <Type>
                  [
                  <UppercaseMood />]
                </Type>
              </SearchItem>
            ))}
            {data!.role.map(({ role_id, name }) => (
              <SearchItem key={role_id} onClick={() => searchToRole(role_id)}>
                <SearchTitle>{name}</SearchTitle>
                <Type>
                  [
                  <UppercaseRole />]
                </Type>
              </SearchItem>
            ))}
            {data!.metadata.map(({ metadata_id, title }) => (
              <SearchItem key={metadata_id} onClick={() => moveToTrackDetail(metadata_id!)}>
                <SearchTitle>{title}</SearchTitle>
                <Type>
                  [
                  <UppercaseTrack />]
                </Type>
              </SearchItem>
            ))}
            {data!.artist.map(({ artist_id, name }) => (
              <SearchItem key={artist_id} onClick={() => searchToArtist(name!)}>
                <SearchTitle>{name}</SearchTitle>
                <Type>
                  [
                  <Composer />]
                </Type>
              </SearchItem>
            ))}
          </SearchResult>
        )}
      </SearchResultLayout>
    </Layout>
  );
};

const Layout = styled.div`
  position: relative;
  z-index: 3;
`;

const SearchInput = styled(Input.Search)`
  position: relative;
  width: 100%;
  height: 25px;
  border: none;
  background: none;
  outline: none;
`;

const SearchResultLayout = styled.div<{ isShow: boolean }>`
  width: 100%;
  overflow: hidden;
  transition: all 0.3s ${props => (props.isShow ? "cubic-bezier(1, 0, 1, 0)" : "cubic-bezier(0,1,0,1)")};
  max-height: ${props => (props.isShow ? "99999px" : "46px")};

  background-color: #333;
  top: 0px;
  border-radius: 1.5rem;
  position: absolute;
  z-index: 1;
`;

const SearchResult = styled.div`
  width: 100%;
  padding: 3rem 0 1.5rem;
`;
const SearchItem = styled.span`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.5rem 1.25rem;
  cursor: pointer;
  overflow: hidden;
  &:hover {
    background-color: ${Color.Ac_Shadow};
  }
`;

const SearchTitle = styled.p`
  font-size: 0.8rem;
  color: ${Color.Ac_White};
  margin-right: 0.3rem;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Type = styled.p`
  font-size: 0.8rem;
  font-weight: 600;
  color: ${Color.Ac_Gray3};
`;

const Loading = styled.div`
  width: 100%;
  padding: 0.5rem 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Color.Ac_White};
  font-weight: bold;
  font-size: 0.8rem;
`;
