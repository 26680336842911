import { TrackLicenseNodeStore } from "App/Store/TrackLicenseStore";
import { Track_License } from "GraphQL/Schema";
import { TrackLicenseID, TrackLicenseModel } from "Models/TrackLicenseModel";

type Props = {
  readonly trackLicenseList: Track_License[];
};

export const setTrackLicense = ({ trackLicenseList }: Props) => {
  for (const trackLicense of trackLicenseList) {
    const trackLicenseM = TrackLicenseModel.form({
      id: trackLicense.id!,
      copyright: trackLicense.copyright!,
      neighboring: trackLicense.neighboring!,
      text: JSON.parse(trackLicense.notice ?? "[]").text,
      metadataId: trackLicense.metadata_id!
    });
    TrackLicenseNodeStore.set(new TrackLicenseID(trackLicense.id), trackLicenseM);
  }
};
