export const DEFAULT_LANGUAGE_CODE = "EN";
export const FALLBACK_LANGUAGE_CODE = "XX";
export const PUBLIC_LANGUAGE_CODE = "OO";
export const KOREAN_LANGUAGE_CODE = "KO";

export type LocalizationPayload = readonly LocalePayload[];

export type LocalePayload = {
  readonly languageCode: string;
  readonly value: string;
};

export class LocalizationModel {
  static from(localeList: LocalizationPayload): LocalizationModel {
    return new LocalizationModel(localeList.map(LocaleModel.from));
  }

  static fallback(value: string): LocalizationModel {
    return new LocalizationModel([LocaleModel.from({ languageCode: FALLBACK_LANGUAGE_CODE, value })]);
  }

  static dummy(value: string): LocalizationModel {
    return new LocalizationModel([{ languageCode: DEFAULT_LANGUAGE_CODE, value }]);
  }

  private constructor(readonly localeList: readonly LocaleModel[]) {}

  currentLocale(languageCode?: string): null | LocaleModel {
    return this.localeList.find(locale => locale.languageCode === languageCode?.toLowerCase()) ?? null;
  }

  get defaultLocale(): null | LocaleModel {
    return this.localeList.find(locale => locale.languageCode === DEFAULT_LANGUAGE_CODE) ?? null;
  }

  get fallbackLocale(): null | LocaleModel {
    return this.localeList.find(locale => locale.languageCode === FALLBACK_LANGUAGE_CODE) ?? null;
  }

  locale(languageCode?: string): null | LocaleModel {
    return (
      this.currentLocale(languageCode) ??
      this.currentLocale(PUBLIC_LANGUAGE_CODE) ??
      this.defaultLocale ??
      this.fallbackLocale ??
      this.localeList[0] ??
      null
    );
  }

  text(languageCode?: string): string {
    return this.locale(languageCode)?.value ?? "";
  }
}

class LocaleModel {
  static from({ languageCode, value }: LocalePayload): LocaleModel {
    return new LocaleModel(languageCode.toLowerCase(), value);
  }

  private constructor(readonly languageCode: string, readonly value: string) {}
}
