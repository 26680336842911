import type { Reducer } from "react";
import type { Action, ActionList, InternalAction } from "./combineReducers";

export interface InternalStore<S, A extends Action<any> | ActionList<any>> {
  readonly getState: GetState<S>;
  readonly dispatch: Dispatch<A>;
  readonly clear: () => void;
  readonly initialize: (initialize: GetState<S>) => void;
  readonly subscribe: (callback: Callback<S>) => Unsubscriber;
}

type Callback<S> = (state: S) => void;

export type Unsubscriber = () => void;

export type GetState<S> = () => S;

export type Dispatch<A extends Action<any> | ActionList<any>> = (action: A) => void;

const createInitialAction = (): InternalAction<any> => {
  const NAMESPACE = "InitialStore";
  const KEY = "initialize";
  return {
    type: `${NAMESPACE}.${KEY}`,
    payload: [],
    namespace: NAMESPACE,
    key: KEY
  };
};

export const createInitialStore = <S, A extends Action<any> | ActionList<any>>(reduce: Reducer<S, A>): InternalStore<S, A> => {
  const callbackSet = new Set<Callback<S>>();
  let state: null | S = null;

  const publish = (state: S): void => {
    for (const callback of callbackSet) {
      callback(state);
    }
  };

  const getState = (): S => {
    if (state === null) {
      state = reduce({} as S, (createInitialAction() as unknown) as A);
    }
    return state;
  };
  const dispatch = (action: A): void => {
    const previous = getState();
    const next = reduce(previous, action);
    if (previous !== next) {
      state = next;
      publish(state);
    }
  };
  const initialize = (initialize: () => S): void => {
    if (state === null) {
      state = reduce(initialize(), (createInitialAction() as unknown) as A);
      publish(state);
    }
  };
  const clear = (): void => {
    state = null;
  };
  const subscribe = (callback: Callback<S>): Unsubscriber => {
    callbackSet.add(callback);
    return (): void => {
      callbackSet.delete(callback);
    };
  };

  return { getState, dispatch, clear, initialize, subscribe };
};
