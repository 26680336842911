import React, { ReactNode } from "react";
import styled from "styled-components";
import copy from "copy-to-clipboard";
import { Color } from "Constants/Color";
import { Toast } from "Lib/toast";
import { Copied } from "Constants/Locale";

type Props = {
  text: string;
  toastMessage?: string;
  children?: ReactNode;
};

const CopySpan = styled.span`
  cursor: copy;
  &:hover {
    color: ${Color.GRAY_6};
    text-decoration: underline;
  }
`;

export const Clipboard = ({ text, toastMessage, children }: Props) => {
  const handleCopy = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (!text.length) {
      return;
    }
    copy(text);
    Toast.success(toastMessage ?? Copied.message());
  };
  return (
    <Layout style={{ display: "inline" }} onClick={handleCopy}>
      {!children ? <CopySpan>{text}</CopySpan> : children}
    </Layout>
  );
};

const Layout = styled.div`
  display: flex !important;
`;
