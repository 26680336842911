import { createStore } from "Lib/store";
import { TrackID } from "Models/TrackModel";

const createInitialState = () => ({
  isVisiblePlayer: false,
  track: null as null | TrackID
});

export const {
  useSelector: usePlayerSelector,
  action: PlayerAction,
  ...PlayerStore
} = createStore("Player", createInitialState, {
  clear(state) {
    state.isVisiblePlayer = false;
    state.track = null;
  },
  close(state) {
    state.isVisiblePlayer = false;
  },
  open(state) {
    state.isVisiblePlayer = true;
  },
  toggle(state) {
    state.isVisiblePlayer = !state.isVisiblePlayer;
  },
  track(state, trackId: TrackID) {
    state.track = trackId;
  }
});
