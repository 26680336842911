import { Edge } from "Constants/Edge";
import { EDGE_FRAGMENT } from "GraphQL/Fragment/EdgeFragment";
import { PurchaseHistory, PurchaseHistoryOrderByInput } from "GraphQL/Schema";
import { gql, useQuery } from "Lib/apollo-client";

const LOAD_PURCHASE_HISTORY = gql`
  ${EDGE_FRAGMENT}
  query LOAD_PURCHASE_HISTORY($first: Int, $page: Int, $orderBy: [PurchaseHistoryOrderByInput!]) {
    purchaseHistory: purchase_history(first: $first, page: $page, orderBy: $orderBy) {
      id
      user_id
      item_total_amount
      item_total_count
      created_at
      updated_at
      is_cancel
      purchase_item(first: 9999) {
        id
        product_type
        product_id
      }
    }
    edge(node: PurchaseHistory, first: $first, page: $page, orderBy: { purchase_history: $orderBy }) {
      ...EdgeFragment
    }
  }
`;

type Result = {
  readonly purchaseHistory: PurchaseHistory[];
  readonly edge: Edge;
};

type Args = {
  readonly first?: number;
  readonly page?: number;
  readonly orderBy?: [PurchaseHistoryOrderByInput];
};

export const useLoadPurchaseHistory = ({ first, page, orderBy }: Args) => {
  const { data, loading } = useQuery<Result, Args>(LOAD_PURCHASE_HISTORY, {
    variables: {
      first,
      page,
      orderBy
    },
    fetchPolicy: "cache-and-network"
  });

  return { loading, data };
};
