import { setTrack } from "App/Common/setTrack";
import { useLoadTrack } from "GraphQL/Queries/useLoadTrack";
import { useLoaded } from "Lib/use-loaded";
import { useEffect } from "react";

export const useTrackDetail = (id: string) => {
  const { data, loading } = useLoadTrack({
    where: {
      id: +id
    }
  });
  const { isLoaded } = useLoaded(loading);

  useEffect(() => {
    if (data) {
      const { track } = data;
      setTrack(track);
    }
  }, [data]);

  return { isLoaded };
};
