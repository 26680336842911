import { Button, ButtonColor, ButtonSize } from "App/Atomics/Button";
import { Icon } from "App/Atomics/Icon";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { TrackTextAutoComplete } from "App/Molecules/AutoCompletes/Track/TextAutoComplete";
import { usePlaylistNode } from "App/Store/PlaylistNodeStore";
import { TrackNodeStore } from "App/Store/TrackNodeStore";
import { Color } from "Constants/Color";
import { ADMIN_CURATION } from "Constants/Routes";
import { useReplaceTracksInPlaylist } from "GraphQL/Mutations/useReplaceTracksInPlaylist";
import { Toast } from "Lib/toast";
import { PlaylistID } from "Models/PlaylistModel";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { SelectStyle } from "../../CechargePoint/Style";
import { Style } from "../Style";

type TrackInfo = {
  index: number;
  id: string;
  title: string;
};

export const Form = () => {
  const { id } = useParams();
  const playlist = usePlaylistNode(new PlaylistID(+id!));
  const { data, error, loading, replaceTracksInPlaylist } = useReplaceTracksInPlaylist();
  const [trackList, setTrackList] = useState<TrackInfo[]>(
    TrackNodeStore.getList(playlist?.trackList.map(({ id }) => id) ?? []).map((track, index) => ({
      index: index,
      id: track.id.raw.toString(),
      title: track.title
    }))
  );

  const isDisable = trackList.length <= 0;

  const navigate = useNavigate();

  const tryToReplaceTracksInPlaylist = () => {
    if (trackList.find(track => !track.id)) {
      Toast.error("비어 있는 칸이 있습니다");
      return;
    }
    replaceTracksInPlaylist({
      variables: {
        playlistId: +id!,
        trackId: trackList.map(({ id }) => +id)
      }
    });
  };
  const addTrack = (index: number) => {
    setTrackList(trackList => [...trackList, { index: index } as TrackInfo]);
  };

  const deleteTrack = (reponseIndex: number) => {
    setTrackList(trackList => trackList.filter(({ index }) => index !== reponseIndex));
  };

  const udpateTrack = (index: number, info: TrackInfo) => {
    const copy = [...trackList];
    copy[index] = info;
    setTrackList(copy);
  };

  useEffect(() => {
    if (data) {
      navigate(ADMIN_CURATION);
    }
  }, [data]);

  return (
    <Layout>
      <Typography weight={TypoWeight.Bold} color={Color.Ac_White} size={TypoSize.F24}>
        트랙 관리를 위해 아래 정보를 입력해주세요.
      </Typography>
      {error && (
        <ErrorBox>
          <Typography size={TypoSize.F13} weight={TypoWeight.Bold} color={Color.Ac_Red1}>
            {error.graphQLErrors[0].message}
          </Typography>
        </ErrorBox>
      )}
      <Container>
        <Style.Row>
          <Style.Caption>트랙 </Style.Caption>
          <Style.SubCaption>- 큐레이션에 들어갈 트랙을 선택해주세요</Style.SubCaption>
          <Style.ColContainer>
            {trackList.map((track, index) => (
              <GridGroup key={index}>
                <Style.RoundButton color={Color.Ac_Shadow} onClick={() => deleteTrack(index)}>
                  -
                </Style.RoundButton>
                <TrackTextAutoComplete
                  style={SelectStyle}
                  placeholder={"트랙명"}
                  onBlur={info =>
                    info &&
                    udpateTrack(index, {
                      index,
                      id: info.id,
                      title: info.name
                    })
                  }
                  defaultValue={
                    track?.id
                      ? {
                          id: track.id,
                          name: track.title
                        }
                      : undefined
                  }
                />
              </GridGroup>
            ))}
            <Style.RoundButton color={Color.Ac_Purple} onClick={() => addTrack(trackList.length)}>
              +
            </Style.RoundButton>
          </Style.ColContainer>
        </Style.Row>
        <ButtonGroup>
          {!loading && (
            <SubmitButton
              size={ButtonSize.Small}
              color={isDisable ? ButtonColor.Disable : ButtonColor.Primary}
              disabled={isDisable}
              onClick={tryToReplaceTracksInPlaylist}
            >
              수정
            </SubmitButton>
          )}
          {loading && (
            <SubmitButton size={ButtonSize.Small} color={ButtonColor.Disable} disabled>
              <Loading name="loading" />
            </SubmitButton>
          )}
        </ButtonGroup>
      </Container>
    </Layout>
  );
};

const Layout = styled.div`
  max-width: 500px;
  width: 100%;
  margin: 4rem auto;
`;

const Container = styled.div`
  margin-top: 2rem;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
`;
const SubmitButton = styled(Button)`
  width: 100px;
`;

const Loading = styled(Icon)`
  width: 1.25rem;
  height: 1.25rem;
`;

const ErrorBox = styled.div`
  width: 100%;
  padding: 1rem;
  background-color: ${Color.Ac_Shadow};
  border-radius: 1rem;
  border: 2px solid ${Color.AC_Carrot};
  display: flex;
  flex-direction: column;
  white-space: normal;
  margin: 1rem 0;
`;
const GridGroup = styled.div`
  display: grid;
  grid-template-columns: 2.6rem auto;
  grid-gap: 4px;
  align-items: center;

  & + & {
    margin: 0.25rem 0;
  }
`;
