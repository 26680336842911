import { Icon } from "App/Atomics/Icon";
import { PurchaseItem } from "App/Features/Items/PurchaseItem";
import { useTrackActivity } from "App/hooks/useTrackActivity";
import { NoPurchaseHistory } from "Constants/Locale";
import { TrackID } from "Models/TrackModel";
import styled from "styled-components";

interface Props {
  readonly trackList: TrackID[];
  readonly isLoadedTrack?: boolean;
}

export const PurchaseItems = ({ trackList, isLoadedTrack = true }: Props) => {
  const { isLoaded: isLoadedTrackActivity } = useTrackActivity({
    trackList: trackList,
    isLoadedTrack: isLoadedTrack
  });

  return (
    <Layout>
      {!trackList.length && (
        <NotFoundContainer>
          <NotFoundIcon name="search" />
          <NotFound>
            <NoPurchaseHistory />
          </NotFound>
        </NotFoundContainer>
      )}
      {isLoadedTrackActivity && trackList.map(id => <PurchaseItem key={id.key} id={id} isDetail={true} />)}
    </Layout>
  );
};

const Layout = styled.div``;
const NotFoundContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 10rem 0;
`;

const NotFound = styled.span`
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 1rem;
`;

const NotFoundIcon = styled(Icon)`
  width: 2.5em;
  height: 2.5rem;
`;
