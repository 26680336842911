import { useTrackNode } from "App/Store/TrackNodeStore";
import { Color } from "Constants/Color";
import { DESKTOP_MODE } from "Constants/Size";
import styled from "styled-components";
import { useCurrent, useCurrentTime } from "Utils/artistscardPlayer";
import { secondsToTime } from "Utils/format";

export const AudioTime = () => {
  const current = useCurrent();
  const currentTime = useCurrentTime();

  const track = useTrackNode(current?.track);

  return (
    <Layout>
      <Timer>
        <Now>{secondsToTime(Math.floor(currentTime))}</Now>
        <Remain>{secondsToTime(Math.floor(current?.duration ?? 0))}</Remain>
      </Timer>
      <Bpm>{!!track?.bpm ? track?.bpm : "-"} BPM</Bpm>
    </Layout>
  );
};

const Timer = styled.div`
  display: none;
  color: ${Color.GRAY_6};
  user-select: none;
  font-family: Helvetica, sans-serif;
  @media ${DESKTOP_MODE} {
    display: flex;
    align-items: center;
  }
  font-size: 0.85rem;
`;

const Now = styled.span`
  &::after {
    color: ${Color.GRAY_5};
    display: inline-block;
    margin: 0 4px;
    font-weight: 700;
    content: "/";
  }
`;

const Remain = styled.span`
  color: ${Color.GRAY_4};
`;

const Layout = styled.div``;

const Bpm = styled.span`
  font-size: 0.7rem;
  display: none;
  @media ${DESKTOP_MODE} {
    display: inline-block;
  }
`;
