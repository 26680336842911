import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { BasicTemplate } from "App/Templates/BasicTemplate";
import { PurchaseComplete } from "Constants/Locale";
import styled from "styled-components";
import { Form } from "./Form";

export const PayOrder = () => {
  return (
    <BasicTemplate isMenu={false} isSidebar={false}>
      <Layout>
        <Padding>
          <Typography weight={TypoWeight.Bold} size={TypoSize.F32}>
            <PurchaseComplete />
          </Typography>
          <Form />
        </Padding>
      </Layout>
    </BasicTemplate>
  );
};

const Layout = styled.div`
  position: relative;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Padding = styled.div`
  max-width: 800px;
  width: 100%;
  padding: 80px 0;
  margin: 0 20px;
`;
