import { gql, useLazyQuery } from "@apollo/client";
import { UserWallet } from "GraphQL/Schema";

export const LOAD_USER_WALLET = gql`
  query LOAD_USER_WALLET {
    userWallet: user_wallet {
      id
      user_id
      balance
      event_point
      created_at
      updated_at
    }
  }
`;

type Result = {
  readonly userWallet: UserWallet;
};

export const useLazyLoadUserWallet = () => {
  const [loadUserWallet, { data, loading }] = useLazyQuery<Result>(LOAD_USER_WALLET, {
    fetchPolicy: "network-only"
  });

  return { loadUserWallet, loading, data };
};
