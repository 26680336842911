const downloadBlob = (blob: string, filename: string) => {
  const aTag = document.createElement("a");
  aTag.download = filename;
  aTag.href = blob;
  document.body.appendChild(aTag);
  aTag.click();
  aTag.remove();
};

export const downloadResource = async (url: string, title?: string) => {
  const pathname = new URL(url).pathname;
  const splitPathname = pathname.split("/");

  const fileName = splitPathname[splitPathname.length - 1];
  const splitFileName = fileName.split(".");
  const ext = splitFileName[splitFileName.length - 1];
  const filename = title ? `${title}.${ext}` : fileName;

  const response = await fetch(url);
  const blob = await response.blob();
  const blobUrl = window.URL.createObjectURL(blob);
  downloadBlob(blobUrl, filename);
};
