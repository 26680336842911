import { Color } from "Constants/Color";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import styled from "styled-components";

export const PayInfoContentLoader = () => {
  return (
    <SkeletonTheme baseColor={Color.Ac_Charcoal} highlightColor={Color.Ac_Gray0}>
      <Layout>
        <Skeleton width={"100%"} />
      </Layout>
    </SkeletonTheme>
  );
};

const Layout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  width: 100%;
  border-radius: 5px;

  user-select: none;

  span {
    width: 100%;
    height: 45px;
  }
`;
