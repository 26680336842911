import { PLAYLIST_FRAGMENT } from "GraphQL/Fragment/PlaylistFragment";
import { Playlist } from "GraphQL/Schema";
import { gql, useMutation } from "Lib/apollo-client";

const CREATE_PLAYLIST = gql`
  ${PLAYLIST_FRAGMENT}
  mutation CREATE_PLAYLIST($title: String, $description: String) {
    createPlaylist: create_playlist(title: $title, description: $description) {
      ...PlaylistFragment
    }
  }
`;

type Result = {
  readonly createPlaylist: Playlist;
};

type Args = {
  readonly title: string;
  readonly description?: string;
};

export const useCreatePlaylist = () => {
  const [fetch, { loading, error }] = useMutation<Result, Args>(CREATE_PLAYLIST, {
    update(cache, { data }) {
      cache.modify({
        fields: {
          playlist(existingPlaylist = []) {
            const newPlaylist = cache.writeFragment({
              data: data?.createPlaylist,
              fragment: PLAYLIST_FRAGMENT
            });
            return [newPlaylist, ...existingPlaylist];
          }
        }
      });
    }
  });

  return { createPlaylist: fetch, loading, error };
};
