import { Button, ButtonColor } from "App/Atomics/Button";
import { Input } from "App/Atomics/Input";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { AccountAction, AccountStore, useAccountSelector } from "App/Store/AccountStore";
import { ModalAction, ModalStore } from "App/Store/ModalStore";
import { Color } from "Constants/Color";
import {
  AccountName,
  AccountNameErrorLength,
  AccountNamePlaceholder,
  Change,
  ChangedMessage,
  Close,
  CompanyName,
  CompanyNameErrorNull,
  EditProfile,
  ProfileEditError
} from "Constants/Locale";
import { updateUserInfo } from "GraphQL/Mutations/updateUserInfo";
import { Modal } from "Lib/modal";
import { Toast } from "Lib/toast";
import { DEFAULT_LANGUAGE_CODE, LocalizationModel } from "Models/LocalizationModel";
import { FormEvent, ReactElement, useRef, useState } from "react";
import styled from "styled-components";
import { useAuth } from "Utils/useAuth";

type Props = {
  readonly isOpen: boolean;
};

export const UpdateProfileModal = ({ isOpen }: Props): ReactElement => {
  const onRequestClose = () => ModalStore.dispatch(ModalAction.closeAll());
  const account = useAccountSelector(store => store.current);

  const nameRef = useRef<HTMLInputElement>(null);
  const companyRef = useRef<HTMLInputElement>(null);

  const auth = useAuth();

  const [loading, setLoading] = useState<boolean>(false);

  const onUpdatePassword = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    const name = nameRef.current!.value.trim();
    const company = companyRef.current!.value.trim() ?? undefined;
    if (name.length < 1 || name.length > 20) {
      Toast.error(AccountNameErrorLength.message());
      return;
    }
    try {
      setLoading(true);
      await updateUserInfo({
        token: auth.token?.access_token!,
        name,
        company
      });

      const newAccount = {
        ...account,
        company: company,
        displayName: name ? LocalizationModel.from([{ languageCode: DEFAULT_LANGUAGE_CODE, value: name }]) : account.displayName
      };
      AccountStore.dispatch(AccountAction.current(newAccount));
      Toast.success(ChangedMessage.message());
      onRequestClose();
      setLoading(false);
    } catch (error) {
      Toast.primary(ProfileEditError.message());
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Layout onSubmit={onUpdatePassword}>
        <Container>
          <Title size={TypoSize.F20} weight={TypoWeight.Bold}>
            <EditProfile />
          </Title>
          <RowGroup>
            <Label size={TypoSize.F12} weight={TypoWeight.Bold}>
              <AccountName />*
            </Label>
            <SerialInput
              ref={nameRef}
              type="text"
              placeholder={AccountNamePlaceholder.message()}
              defaultValue={account.displayName.text()}
            />
          </RowGroup>
          <RowGroup>
            <Label size={TypoSize.F12} weight={TypoWeight.Bold}>
              <CompanyName />
            </Label>
            <SerialInput ref={companyRef} type="text" placeholder={CompanyNameErrorNull.message()} defaultValue={account.company} />
          </RowGroup>

          <ButtonGroup>
            <StyledButton color={ButtonColor.Disable} onClick={onRequestClose} disabled={loading}>
              <Close />
            </StyledButton>
            <StyledButton type="submit" color={loading ? ButtonColor.Disable : ButtonColor.Primary} disabled={loading}>
              <Change />
            </StyledButton>
          </ButtonGroup>
        </Container>
      </Layout>
    </Modal>
  );
};

const Layout = styled.form`
  width: 400px;
  padding: 40px 20px 20px;
  margin: 0 1.5rem;
  border-radius: 5px;
  background-color: ${Color.Ac_Charcoal};
  pointer-events: auto;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Title = styled(Typography)`
  margin-bottom: 20px;
`;
const RowGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 20px;
`;
const Label = styled(Typography)`
  margin-bottom: 8px;
`;
const SerialInput = styled(Input.Text)`
  position: relative;
  width: 100%;
  height: 44px;
  margin-top: 4px;
  background-color: ${Color.Ac_Shadow}!important;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.5);
  color: ${Color.Ac_White}!important;
  border: none;
  border-radius: 8px;
  line-height: 22px;
  padding: 1rem;
  transition: box-shadow 0.2s ease;
  &:hover {
    box-shadow: inset 0 0 0 2px ${Color.Ac_Gray0};
  }
  &:focus {
    box-shadow: inset 0 0 0 2px ${Color.AC_Carrot};
  }
`;
const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;
const StyledButton = styled(Button)`
  width: 100%;
  font-weight: bold;
  & + & {
    margin-left: 8px;
  }
`;
