import { Button, ButtonColor } from "App/Atomics/Button";
import { Icon } from "App/Atomics/Icon";
import { ModalAction, ModalProps, ModalStore } from "App/Store/ModalStore";
import { useTrackNode } from "App/Store/TrackNodeStore";
import { Color } from "Constants/Color";
import { DownloadMusicPurchaseModal, FullDownloadModalDescription, MusicDownload, NoMusic } from "Constants/Locale";
import { useLoadMetadataUrl } from "GraphQL/Queries/useLoadMetadataUrl";
import { Modal } from "Lib/modal";
import { LOADED, useLoading } from "Lib/use-loading";
import { TrackActivityID } from "Models/TrackActivity";
import { TrackID } from "Models/TrackModel";
import { useState } from "react";
import styled from "styled-components";
import { downloadResource } from "Utils/DownloadResource";

type Props = {
  readonly props: ModalProps;
  readonly isOpen: boolean;
};

const typeUrl: Record<string, string> = {
  mp3high: "MP3",
  wav: "WAV"
};

export const TrackDownloadModal = ({ props, isOpen }: Props) => {
  const track = props.track as TrackID;
  const purchaseActivity = props.purchaseActivity as TrackActivityID | null;
  const onRequestClose = () => ModalStore.dispatch(ModalAction.closeAll());
  const { data, loading } = useLoadMetadataUrl({ id: track.raw, skip: !purchaseActivity });
  const urlList = data?.metadata[0].metadata_url;
  const downloadLoading = useLoading(LOADED);

  const trackModel = useTrackNode(track);

  const [selectTypeUrl, setSelectTypeUrl] = useState<string | null>();
  const tryToDownloadResource = async (url: string, type_url: string) => {
    setSelectTypeUrl(type_url);
    downloadLoading.setLoading();

    await downloadResource(url, trackModel?.title);
    downloadLoading.setLoaded();
    setSelectTypeUrl(null);
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Layout>
        <HeaderLayout>
          <Title>
            <MusicDownload />
          </Title>
          <CrossIcon name="close" onClick={onRequestClose}></CrossIcon>
        </HeaderLayout>
        <Desciprtion>
          <DownloadMusicPurchaseModal />
        </Desciprtion>
        <Desciprtion>
          <FullDownloadModalDescription />
        </Desciprtion>

        {purchaseActivity ? (
          <ButtonGroup>
            {loading && <Loading name="loading" />}
            {!loading && !urlList?.length && (
              <Text>
                <NoMusic />
              </Text>
            )}
            {!loading &&
              urlList
                ?.filter(url => url.type_url === "mp3high" || url.type_url === "wav")
                ?.map(({ url, type_url }, index) => (
                  <ButtonContainer key={index}>
                    <Text>{typeUrl[type_url]}</Text>
                    {downloadLoading.isLoaded && (
                      <PlayButton color={ButtonColor.Primary} onClick={() => tryToDownloadResource(url, type_url)}>
                        <ButtonText>
                          <MusicDownload />
                        </ButtonText>
                      </PlayButton>
                    )}
                    {downloadLoading.isLoading && (
                      <PlayButton color={ButtonColor.Disable}>
                        <ButtonText>
                          {selectTypeUrl === type_url && <Loading name="loading" />}
                          {selectTypeUrl !== type_url && <MusicDownload />}
                        </ButtonText>
                      </PlayButton>
                    )}
                  </ButtonContainer>
                ))}
          </ButtonGroup>
        ) : (
          <></>
        )}
      </Layout>
    </Modal>
  );
};

const Layout = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;
  background-color: ${Color.Ac_Charcoal};
  padding: 30px;
  overflow: hidden;
  pointer-events: auto;

  display: flex;
  flex-direction: column;
`;

const HeaderLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  font-size: 1.5rem;
`;

const CrossIcon = styled(Icon)`
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
`;

const Desciprtion = styled.p`
  font-size: 0.8rem;
  font-weight: bold;
  white-space: normal;
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem;
`;

const PlayButton = styled(Button)`
  height: 35px;
  border-radius: 1rem;

  border: none;
`;
const ButtonText = styled.span`
  font-size: 0.75rem;

  line-height: 1;
  font-weight: bold;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 0;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
`;

const Text = styled.div`
  font-weight: bold;
  font-size: 0.9rem;
`;

const Loading = styled(Icon)`
  margin: 0 6px;
  width: 54.7px;
  height: 20px;
`;
