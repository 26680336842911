import { createStore } from "Lib/store";
import { PlaylistID } from "Models/PlaylistModel";

const createInitialState = () => ({
  playlists: [] as PlaylistID[],
  isLoadedPlaylist: false as boolean
});

export const {
  useSelector: usePlaylistModalSelector,
  action: PlaylistsModalAction,
  ...PlaylistsModalStore
} = createStore("PlaylistsModal", createInitialState, {
  setPlaylists(state, playlists: PlaylistID[]) {
    state.playlists = [...playlists];
  },
  setIsLoadedPlaylists(state, isLoadedPlaylist: boolean) {
    state.isLoadedPlaylist = isLoadedPlaylist;
  }
});
