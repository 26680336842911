import { Button, ButtonColor } from "App/Atomics/Button";
import { Icon } from "App/Atomics/Icon";
import { Color } from "Constants/Color";
import { Playing } from "Lib/player";
import { TrackID } from "Models/TrackModel";
import { MouseEvent, useState } from "react";
import styled from "styled-components";
import { Player, useCurrent, usePlayer } from "Utils/artistscardPlayer";

type Props = {
  readonly id: TrackID;
  readonly type?: "insert-to-last" | "current";
};

export const PlayerButton = ({ id, type = "insert-to-last" }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const current = useCurrent();
  const player = usePlayer();
  const isCurrentTrack = current?.track.raw === id?.raw && player.playing !== Playing.Play;
  const isCurrentPlaying = current?.track.raw === id?.raw && player.playing === Playing.Play;

  const onPlayMusic = async () => {
    if (isLoading) return;
    setIsLoading(true);
    if (type === "insert-to-last") {
      Player.insertToLast(id!);
      Player.current(id!);
    } else if (type === "current") {
      Player.current(id!);
    }

    setIsLoading(false);
  };

  const onTogglePlay = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (isCurrentPlaying) {
      Player.pause();
    } else if (isCurrentTrack) {
      Player.play();
    } else {
      onPlayMusic();
    }
  };

  return (
    <Layout onClick={onTogglePlay}>
      {isCurrentPlaying && (
        <ButtonContainer>
          <SimpleButton color={ButtonColor.PrimaryTransperent}>
            <PauseButton name="pause" fill={Color.AC_Carrot} />
          </SimpleButton>
        </ButtonContainer>
      )}

      {!isCurrentPlaying && (
        <ButtonContainer>
          <SimpleButton>
            <PlayButton name="play" />
          </SimpleButton>
        </ButtonContainer>
      )}
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
  height: 100%;
`;

const SimpleButton = styled(Button)`
  width: 100%;
  height: 100%;
  border-radius: 3rem;
  margin: 0;
  padding: 0;
`;
const PlayerIcon = styled(Icon)`
  width: 1.5rem;
`;

const PlayButton = styled(PlayerIcon)`
  margin-top: 0.1rem;
  margin-left: 0.2rem;
`;
const PauseButton = styled(PlayerIcon)`
  path {
    fill: ${Color.AC_Carrot};
  }
  margin-left: 0.1rem;
`;

const ButtonContainer = styled.div`
  width: 100%;
  height: 100%;
`;
