import { setLicense } from "App/Common/setLicense";
import { LicenseNodeStore } from "App/Store/LicenseNodeStore";
import { Edge } from "Constants/Edge";
import { useLoadAdminLicense } from "GraphQL/Queries/useLoadLicenseAdmin";
import { useLoaded } from "Lib/use-loaded";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const useAllLicense = () => {
  const [search] = useSearchParams();

  const keyword = search.get("keyword") ?? undefined;
  const page = +(search.get("page") ?? 1);
  const { data, loading } = useLoadAdminLicense({
    first: 20,
    page: page,
    where: {
      user__some: keyword
        ? {
            email__starts_with: keyword
          }
        : undefined
    }
  });
  const [edge, setEdge] = useState<Edge | null>(null);
  const { isLoaded } = useLoaded(loading);

  useEffect(() => {
    LicenseNodeStore.clear();
    if (data) {
      const { license, edge } = data;
      setLicense(license);
      setEdge(edge);
    }
  }, [data]);

  return { isLoaded, edge };
};
