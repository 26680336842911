import { setRelationMetadataTrack } from "App/Common/setRelationMatadataTrack";
import { BASIC_FIRST_COUNT } from "Constants/Count";
import { useLoadMetadataTrack } from "GraphQL/Queries/useLoadMetadataTrack";
import { useLoaded } from "Lib/use-loaded";
import { GenreID, MetdataKind } from "Models/GenreModel/GenreModel";
import { TrackID } from "Models/TrackModel";
import { useEffect } from "react";
import { useSubclass } from "Utils/useSubclass";

export const useRelationMetadataTrack = (trackId: TrackID, genreIds: GenreID[]) => {
  const { metadataType } = useSubclass();

  const isLicense = metadataType.kind === MetdataKind.Pd || metadataType.kind === MetdataKind.Ccl;
  const { data, loading } = useLoadMetadataTrack({
    first: BASIC_FIRST_COUNT,
    where: {
      type_metadata_class: "record",
      type_metadata_subclass: metadataType.subClass.toLocaleLowerCase(),
      metadata_id__not_in: [trackId.raw.toString()],
      track_license__some: isLicense
        ? {
            country_code__in: ["ZZ", "KR"],
            copyright: true,
            neighboring: true
          }
        : undefined,
      metadata_genre_relation__some: {
        genre__some: {
          genre_id__in: genreIds.map(({ raw }) => raw)
        }
      }
    },
    skip: !genreIds.length
  });
  const { isLoaded } = useLoaded(loading);
  useEffect(() => {
    if (data) {
      const { metadata } = data;
      setRelationMetadataTrack(trackId, metadata);
    }
  }, [data]);

  return { isLoaded };
};
