import { Icon, IconName } from "App/Atomics/Icon";
import { Range } from "App/Atomics/Range";
import { Color } from "Constants/Color";
import { useMemo } from "react";
import styled from "styled-components";
import { Player, usePlayer } from "Utils/artistscardPlayer";

type Direction = "left" | "right";
type Props = Styleable & {
  readonly direction?: Direction;
};

export const VolumeSlider = ({ style, className, direction = "right" }: Props) => {
  const { volume, isMuted } = usePlayer();

  const onChangeVolume = (vol: number): void => {
    if (isMuted) {
      Player.unmute();
    }
    Player.volume(vol / 100);
  };

  const onToggleMute = () => {
    Player.toggleMute();
  };

  const iconNameByVolume: IconName = useMemo(() => {
    switch (true) {
      case isMuted:
      case volume <= 0:
        return "volume-0";
      case volume < 0.5:
        return "volume-1";
      default:
        return "volume-2";
    }
  }, [volume, isMuted]);

  const toggleMute = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onToggleMute();
  };

  return (
    <Layout style={style} className={className}>
      <Content>
        <Show className="show">{direction === "left" && <StyledRange value={isMuted ? 0 : volume * 100} onChange={onChangeVolume} />}</Show>
        <IconWrap onClick={toggleMute}>
          <VolumeIcon className="volume-icon" name={iconNameByVolume} />
        </IconWrap>
        <Show className="show">
          {direction === "right" && <StyledRange value={isMuted ? 0 : volume * 100} onChange={onChangeVolume} />}
        </Show>
      </Content>
    </Layout>
  );
};

const Layout = styled.div``;
const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    .show {
      opacity: 1;
    }
  }
`;

const IconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;
  margin: 0 2px;
  padding: 2px;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }
`;
const VolumeIcon = styled(Icon)`
  width: 1.2rem;
  height: 1.2rem;
  fill: ${Color.Ac_White};
`;

const StyledRange = styled(Range)``;

const Show = styled.div`
  opacity: 0;
`;
