import { createStore } from "Lib/store";

const createInitialState = () => ({
  isLoadedPlaylistTrack: false as boolean,
  isLoadedPlaylist: false as boolean
});

export const {
  useSelector: usePlaylistDetailSelector,
  action: PlaylistDetailAction,
  ...PlaylistDetailStore
} = createStore("PlaylistDetail", createInitialState, {
  setIsLoadedPlaylistTrack(state, isLoadedPlaylistTrack: boolean) {
    state.isLoadedPlaylistTrack = isLoadedPlaylistTrack;
  },
  setIsLoadedPlaylist(state, isLoadedPlaylist: boolean) {
    state.isLoadedPlaylist = isLoadedPlaylist;
  }
});
