import { Edge } from "Constants/Edge";
import { EDGE_FRAGMENT } from "GraphQL/Fragment/EdgeFragment";
import { ProductPrice, ProductPriceWhereInput } from "GraphQL/Schema";
import { gql, useQuery } from "Lib/apollo-client";

export const LOAD_PRODUCT_PRICE = gql`
  ${EDGE_FRAGMENT}
  query LOAD_PRODUCT_PRICE($first: Int, $page: Int, $where: ProductPriceWhereInput) {
    productPrice: product_price(first: $first, page: $page, where: $where) {
      id
      type
      title
      description
      approver_id
      is_approve
      price
      created_at
      updated_at
      target
    }
    edge(node: ProductPrice, is_force: true, where: { product_price: $where }, first: $first, page: $page) {
      ...EdgeFragment
    }
  }
`;

type Result = {
  readonly productPrice: ProductPrice[];
  readonly edge: Edge;
};

type Args = {
  readonly first?: number;
  readonly page?: number;
  readonly where?: ProductPriceWhereInput;
};

export const useLoadProductPrice = ({ first, page, where }: Args) => {
  const { data, loading } = useQuery<Result>(LOAD_PRODUCT_PRICE, {
    variables: {
      first,
      page,
      where
    },
    fetchPolicy: "no-cache"
  });

  return { loading, data };
};
