import { Color } from "Constants/Color";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import styled from "styled-components";

export const SelectTagContentLoader = () => {
  return (
    <SkeletonTheme baseColor={Color.Ac_Shadow} highlightColor={Color.Ac_Gray0}>
      <Layout>
        <Skeleton width={100} height={30} borderRadius={32} />
      </Layout>
    </SkeletonTheme>
  );
};

const Layout = styled.div`
  margin: 0.3rem;
`;
