import { setPlaylist } from "App/Common/setPlaylist";
import { BASIC_FIRST_COUNT } from "Constants/Count";
import { useLoadPlaylist } from "GraphQL/Queries/useLoadPlaylist";
import { PlaylistOrderByInput, PlaylistType } from "GraphQL/Schema";
import { useEffect } from "react";
import { MyPlaylistAction, MyPlaylistStore } from "../Store";

export const useMyPlaylists = () => {
  const { data, loading, fetchMore } = useLoadPlaylist({
    first: BASIC_FIRST_COUNT,
    where: {
      type__in: [PlaylistType.My, PlaylistType.Curation]
    },
    orderBy: [PlaylistOrderByInput.IdDesc]
  });

  useEffect(() => {
    if (data) {
      const { playlist: responsePlaylist, edge } = data;
      const playlistMs = setPlaylist(responsePlaylist);
      const playlist = playlistMs.map(playlistM => playlistM.id);
      MyPlaylistStore.dispatch(MyPlaylistAction.setMyPlaylist(playlist));
      MyPlaylistStore.dispatch(MyPlaylistAction.setEdge(edge));
    }
  }, [data]);

  useEffect(() => {
    MyPlaylistStore.dispatch(MyPlaylistAction.setIsLoadedMyPlaylist(!loading));
  }, [loading]);

  return { fetchMore };
};
