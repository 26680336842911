import { GenreNodeStore } from "App/Store/GenreNodeStore";
import { Genre } from "GraphQL/Schema";
import { GenreKind } from "Models/GenreModel/GenreKind";
import { GenreID, GenreModel, MetdataKind } from "Models/GenreModel/GenreModel";

export const setMood = (genres: Genre[], metdataKind: MetdataKind) => {
  for (const genre of genres) {
    const { genre_id, genre_title, weight } = genre;

    const genreId = new GenreID(genre_id);
    const genreNode = GenreNodeStore.get(genreId);

    if (genreNode) {
      GenreNodeStore.update(genreId, node => {
        node.metdataKind = [...new Set([...node.metdataKind, metdataKind])] as MetdataKind[];
      });
    } else {
      const genreM = GenreModel.form({
        id: genre_id,
        title:
          genre_title?.map(({ language_code, value }) => ({
            languageCode: language_code!,
            value: value!
          })) ?? [],
        weight,
        kind: genre.type_kind! as GenreKind,
        metdataKind: [metdataKind],
        childGenreIds: []
      });

      GenreNodeStore.set(genreId, genreM);
    }
  }
};
