import { createNodeStore } from "Lib/node-store";
import { AccountID } from "Models/AccountModel/AccountID";
import { AccountModel } from "Models/AccountModel/AccountModel";

export const {
  useStore: useAccountNodeStore,
  useNode: useAccountNode,
  useNodeList: useAccountNodeList,
  ...AccountNodeStore
} = createNodeStore<AccountID, AccountModel>();
