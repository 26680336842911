import { Color } from "Constants/Color";
import { Size } from "Constants/Size";
import { ReactNode, useMemo } from "react";
import styled from "styled-components";

export enum ButtonSize {
  Xsmall = "xsmall",
  Small = "small",
  Medium = "medium",
  Large = "large"
}

export enum ButtonColor {
  Basic = "basic",
  Primary = "primary",
  PrimaryNotFill = "primary_not_fill",
  OutRed = "out_red",
  Transparent = "transparent",
  White = "white",
  None = "none",
  NoneGray = "none-gray",
  Gray = "gray",
  Disable = "disable",
  PrimaryTransperent = "PrimaryTransperent"
}

export type ButtonProps = Styleable & {
  readonly icon?: ReactNode;
  readonly type?: "button" | "submit";
  readonly disabled?: boolean;
  readonly color?: ButtonColor;
  readonly size?: ButtonSize;
  readonly children: ReactNode;
  readonly onClick?: () => void;
};

export const Button = ({
  style,
  className,
  type = "button",
  icon,
  disabled = false,
  color: buttonColor = ButtonColor.Transparent,
  size = ButtonSize.Medium,
  children,
  ...props
}: ButtonProps) => {
  const [backgroundColor, color, borderColor, hoverColor, hoverBorderColor] = useMemo(() => {
    switch (buttonColor) {
      case ButtonColor.Primary:
        return [Color.AC_Carrot, Color.Ac_White, Color.AC_Carrot, Color.Ac_White, Color.AC_Carrot];
      case ButtonColor.PrimaryNotFill:
        return [Color.Ac_White, Color.AC_Carrot, Color.AC_Carrot, Color.AC_Carrot, Color.AC_Carrot];
      case ButtonColor.Gray:
        return [Color.Ac_Gray4, Color.Ac_Black, Color.Ac_Gray4, Color.Ac_Gray4, Color.Ac_Gray4];
      case ButtonColor.White:
        return [Color.Ac_White, Color.Ac_Black, Color.Ac_Gray4, Color.Ac_Gray4, Color.Ac_Gray4];
      case ButtonColor.Transparent:
        return [Color.Transparent, Color.Ac_White, Color.Ac_Gray1, Color.Ac_White, Color.Ac_White];
      case ButtonColor.Disable:
        return [Color.Ac_Button_disable, Color.Ac_Gray5, Color.Transparent, Color.Ac_Gray5, Color.Ac_Button_disable];
      case ButtonColor.NoneGray:
        return [Color.Transparent, Color.Ac_Gray0, Color.Transparent, Color.Ac_Gray0, Color.Transparent];
      case ButtonColor.OutRed:
        return [Color.Transparent, Color.Ac_Red1, Color.Ac_Red1, Color.Ac_Red1, Color.Ac_Red1];
      case ButtonColor.PrimaryTransperent:
        return [Color.Transparent, Color.Ac_White, Color.AC_Carrot, Color.AC_Carrot, Color.AC_Carrot];
      case ButtonColor.Basic:
        return [Color.Transparent, Color.Ac_Gray1, Color.Ac_Gray1, Color.Ac_White, Color.Ac_White];
      case ButtonColor.None:
        return [Color.Transparent, Color.Ac_Black, Color.Transparent, Color.Transparent, Color.Transparent];
    }
  }, [buttonColor]);

  return (
    <Layout
      type={type}
      color={color}
      hoverBorderColor={hoverBorderColor}
      style={{ backgroundColor, color, borderColor, ...style }}
      className={className}
      size={size}
      isDisabled={disabled}
      disabled={disabled}
      hoverColor={hoverColor}
      {...props}
    >
      {icon ? <Left>{icon}</Left> : null}
      {children}
    </Layout>
  );
};

const Layout = styled.button<{ size: ButtonSize; hoverColor: string; isDisabled: boolean; color: string; hoverBorderColor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.color}!important;
  font-size: 1.125vw;
  padding: ${({ size }) => (size === ButtonSize.Xsmall || size === ButtonSize.Small ? "8px 16px" : "12px 20px")};
  border-width: 2px;
  border-style: solid;
  border-radius: 3px;

  .fill_hover_color,
  .stroke_hover_color {
    transition: all 0.25s;
  }

  transition: all 0.25s;

  &:hover {
    border-color: ${props => props.hoverBorderColor}!important;
    color: ${props => props.hoverColor}!important;

    .fill_hover_color {
      fill: ${props => props.hoverColor}!important;
    }
    .stroke_hover_color {
      stroke: ${props => props.hoverColor}!important;
    }
  }

  font-size: ${({ size }) =>
    size === ButtonSize.Xsmall
      ? Size.FONT_X_SMALL_PX
      : size === ButtonSize.Small
      ? Size.FONT_SMALL_PX
      : size === ButtonSize.Medium
      ? Size.FONT_DEFAULT_PX
      : Size.FONT_LARGE_PX};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  user-select: none;
`;
const Left = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`;
