import { Color } from "Constants/Color";
import { DESKTOP_MODE } from "Constants/Size";
import styled from "styled-components";

const Layout = styled.div`
  width: 100%;

  border-radius: 10px;
  margin-bottom: 8px;
  background: ${Color.Ac_Shadow};
  padding: 25px 20px;

  @media ${DESKTOP_MODE} {
    padding: 30px 40px;
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  @media ${DESKTOP_MODE} {
    margin-bottom: 20px;
  }
`;

export const BuyCheckStyle = { Layout, Title };
