import { TrackTagInforamtion } from "App/Features/ContentLoaders/TrackTagInformation";
import { TrackTagInformationContentLoader } from "App/Features/ContentLoaders/TrackTagInformationContentLoader";
import { TrackID } from "Models/TrackModel";
import styled from "styled-components";

interface Props {
  readonly isLoadedTrack: boolean;
  readonly id: TrackID;
}

export const TrackTagInformationForm = ({ id, isLoadedTrack }: Props) => {
  return (
    <Layout>{isLoadedTrack ? <TrackTagInforamtion id={id} isLoadedTrack={isLoadedTrack} /> : <TrackTagInformationContentLoader />}</Layout>
  );
};

const Layout = styled.div``;
