import { Icon } from "App/Atomics/Icon";
import { Typography } from "App/Atomics/Typography";
import { PlayerButton } from "App/Features/Buttons/PlayerButton";
import { useCartItemNode } from "App/Store/CartItemNodeStore";
import { useFileRuleNode } from "App/Store/FileRuleNodeStore";
import { useGradeNode } from "App/Store/GradeNodeStore";
import { useProductPriceNode } from "App/Store/ProductPriceNodeStore";
import { useTrackNode } from "App/Store/TrackNodeStore";
import { Color } from "Constants/Color";
import { DESKTOP_MODE } from "Constants/Size";
import { useRemoveCartItemNoCache } from "GraphQL/Mutations/useRemoveCartItemNoCache";
import { CartItemID } from "Models/CartItemModel";
import { TrackID } from "Models/TrackModel";
import styled from "styled-components";

type Props = {
  readonly id: CartItemID;
};

export const CartItem = ({ id }: Props) => {
  const cartItem = useCartItemNode(id);

  const track = useTrackNode(cartItem?.track!);
  const grade = useGradeNode(track?.grade);
  const licensePrice = useProductPriceNode(grade?.productPrice);

  const fileRule = useFileRuleNode(track?.fileRule);
  const price = (licensePrice?.price ?? 0) + (fileRule?.amount ?? 0);

  const { removeCartItem, isLoaded } = useRemoveCartItemNoCache();
  const removeToCartItem = async (id: TrackID) => {
    if (!isLoaded) {
      return;
    }
    try {
      await removeCartItem({
        variables: {
          trackId: id.raw!
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout>
      <Track>
        <FlexAudioContainer>
          <ButtonContainer>
            <PlayerButton id={track?.id!} />
          </ButtonContainer>
        </FlexAudioContainer>
        <FlexRightContainer>
          <FlexRightTopContainer>
            <FlexInformationContainer>
              <TrackTitleLayout>
                <TrackTitleContainer>
                  <TrackTitle>{track?.title}</TrackTitle>
                </TrackTitleContainer>
              </TrackTitleLayout>
            </FlexInformationContainer>
            <FlexBuyContainer>
              <Price>
                <CoinIcon name="coin" />
                {price.toLocaleString()}
              </Price>
            </FlexBuyContainer>
            <FlexCloseContainer>
              <StatusIconContainer onClick={() => removeToCartItem(track?.id!)}>
                {isLoaded && <CloseIcon name="close" />}
                {!isLoaded && <CloseIcon name="loading" />}
              </StatusIconContainer>
            </FlexCloseContainer>
          </FlexRightTopContainer>
        </FlexRightContainer>
      </Track>
    </Layout>
  );
};

const Layout = styled.div`
  &:not(:first-child) {
    border-top: 1px solid ${Color.Ac_Gray};
  }
  &:last-child {
    border-bottom: 1px solid ${Color.Ac_Gray};
  }
`;

const FlexRightTopContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
`;

const FlexAudioContainer = styled.div`
  flex: 0 0 54px;
`;

const FlexRightContainer = styled.div`
  flex: 1 0 10px;
  min-width: 0;
  flex-direction: column;
  display: flex;
  align-items: center;
`;

const FlexInformationContainer = styled.div`
  flex: 5 0 50%;
  min-width: 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TrackTitleLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex: 9 0 50px;
  font-size: 0.9rem;
  min-width: 0;
`;

const TrackTitleContainer = styled.div`
  margin-right: 0.625rem;
`;

const TrackTitle = styled.span`
  font-weight: bold;
  white-space: normal;
  word-break: normal;
  font-size: 0.8rem;
  @media ${DESKTOP_MODE} {
    font-size: 0.9rem;
  }
`;

const Track = styled.div`
  padding: 1rem 0;
  display: flex;
  align-items: center;
`;

const ButtonContainer = styled.div`
  width: 40px;
  height: 40px;
`;

const FlexCloseContainer = styled.div`
  flex: 1 0 20px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const StatusIconContainer = styled.div`
  height: 1.5rem;
  min-width: 1.5rem;

  padding: 0 5px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    path,
    rect {
      fill: ${Color.Ac_White};
    }
  }
`;

const CloseIcon = styled(Icon)`
  width: 1.25rem;

  path {
    fill: ${Color.Ac_Gray1};
  }

  rect {
    fill: rgba(0, 0, 0, 0) !important;
  }
`;

const FlexBuyContainer = styled.div`
  flex: 1 0 50px;
  margin: 0 0.5rem;
  display: flex;
  align-items: center;
`;

const Price = styled(Typography)`
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  align-items: center;
`;

const CoinIcon = styled(Icon)`
  width: 1.8rem;
  height: 1.8rem;
  margin-left: 0.25rem;
`;
