import { ComponentType } from "react";
import styled from "styled-components";
import { TextAutoComplete } from "App/Atomics/AutoComplete";
import { components, OptionProps } from "react-select";
import { Color } from "Constants/Color";
import { findUserList } from "./services/findUserList";
import { useAuth } from "Utils/useAuth";

type Props = Pick<
  Parameters<typeof TextAutoComplete>[0],
  | "style"
  | "className"
  | "classNamePrefix"
  | "isDisabled"
  | "defaultValue"
  | "defaultOptions"
  | "onChange"
  | "onBlur"
  | "autoFocus"
  | "placeholder"
> & { typeUserClass?: string };

export const UserTextAutoComplete = ({
  style,
  className,
  classNamePrefix,
  isDisabled,
  defaultValue,
  onChange,
  onBlur,
  autoFocus,
  placeholder = "유저 이메일을 입력해주세요."
}: Props) => {
  const { token } = useAuth();

  return (
    <TextAutoComplete
      placeholder={placeholder}
      isDisabled={isDisabled}
      defaultValue={defaultValue}
      delay={200}
      style={style}
      className={className}
      classNamePrefix={classNamePrefix}
      autoFocus={autoFocus}
      onChange={onChange}
      onBlur={onBlur}
      loadInfoList={keyword => findUserList(keyword, token?.access_token!)}
      components={{ Option }}
    />
  );
};

const Option: ComponentType<OptionProps<any>> = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <OptionWrapper>
        <span className="id">{props.data.id}</span>
        <span className="name">{props.data.name}</span>
      </OptionWrapper>
    </components.Option>
  );
};

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  .id {
    font-size: 9px;
    color: ${Color.Ac_Gray0};
  }
  .name {
    font-size: 13px;
    font-weight: 500;
    color: ${Color.Ac_White};
  }
  .extra {
    font-size: 10px;
    font-weight: 400;
    color: ${Color.Ac_Apricot};
  }
`;
