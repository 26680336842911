import { usePlaylistNodeStore } from "App/Store/PlaylistNodeStore";
import styled from "styled-components";
import { useAuth } from "Utils/useAuth";
import { CurationItem } from "./CurationItem";

export const CurationContent = () => {
  const auth = useAuth();
  const curationList = usePlaylistNodeStore(store =>
    store
      .values()
      .filter(({ userId }) => userId.raw === auth.userId)
      .map(({ id }) => id)
  );
  return (
    <Layout>
      {curationList.map(id => (
        <CurationItem key={id.key} id={id} />
      ))}
    </Layout>
  );
};

const Layout = styled.tbody``;
