import { AccountAction, AccountStore } from "App/Store/AccountStore";
import { useAuth } from "Utils/useAuth";
import { useApolloClient } from "Lib/apollo-client";
import { TrackActivityNodeStore } from "App/Store/TrackActivity";
import { PlaylistNodeStore } from "App/Store/PlaylistNodeStore";
import { LicenseNodeStore } from "App/Store/LicenseNodeStore";

export const useSignOut = () => {
  const auth = useAuth();
  const client = useApolloClient();
  const signOut = async () => {
    try {
      auth.clear();
      AccountStore.dispatch(AccountAction.clear());
      LicenseNodeStore.clear();
      TrackActivityNodeStore.clear();
      PlaylistNodeStore.clear();

      await client.resetStore();
    } catch (error) {
      console.log(error);
    }
  };
  return signOut;
};
