import { BasicTemplate } from "App/Templates/BasicTemplate";
import { Color } from "Constants/Color";
import { ReactElement } from "react";
import styled from "styled-components";
import { useLoadPayoneqInfo } from "./hooks/useLoadPayoneqInfo";
import { PointChargeForm } from "./PointChargeForm";

export const PointCharge = (): ReactElement => {
  useLoadPayoneqInfo();

  return (
    <BasicTemplate isMenu={false} isSidebar={false}>
      <Layout>
        <Container>
          <PointChargeForm />
        </Container>
      </Layout>
    </BasicTemplate>
  );
};

const Layout = styled.div`
  width: 100%;
  background-color: ${Color.Ac_Black};
  min-height: 100vh;
`;
const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 60px 30px;
`;
