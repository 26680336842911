import { DependencyList, RefObject, useEffect, useState } from "react";

export type Handler = (entry: IntersectionObserverEntry) => void | Promise<void>;

export const createIntersectionObserverHook = <E extends Element = Element>(option: IntersectionObserverInit = {}) => {
  const elementMap = new Map<E, Set<Handler>>();

  const callback = async (entries: readonly IntersectionObserverEntry[]): Promise<void> => {
    for (const entry of entries) {
      const handlerSet = elementMap.get(entry.target as E);
      if (handlerSet) {
        for (const handler of handlerSet) {
          await handler(entry);
        }
        break;
      }
    }
  };
  const observer = new IntersectionObserver(callback, option);

  const useIntersectionEffect = (ref: RefObject<E>, handler: Handler, dependencyList: DependencyList): void => {
    useEffect(() => {
      const { current } = ref;
      if (!current) {
        return;
      }

      if (!elementMap.has(current)) {
        elementMap.set(current, new Set());
      }

      elementMap.get(current)!.add(handler);
      observer.observe(current);

      return () => {
        const handlerSet = elementMap.get(current);
        if (handlerSet) {
          handlerSet.delete(handler);
          if (!handlerSet.size) {
            elementMap.delete(current);
          }
        }
        observer.unobserve(current);
      };
    }, [ref.current, ...dependencyList]);
  };

  const useIntersection = (ref: RefObject<E>): readonly [boolean, null | IntersectionObserverEntry] => {
    const [entry, setEntry] = useState<null | IntersectionObserverEntry>(null);

    useIntersectionEffect(ref, setEntry, []);

    return [entry?.isIntersecting ?? false, entry];
  };

  return { useIntersectionEffect, useIntersection };
};

export const { useIntersection, useIntersectionEffect } = createIntersectionObserverHook();
