import { Typography, TypoSize } from "App/Atomics/Typography";
import { Color } from "Constants/Color";
import { useToggle } from "Lib/use-toggle";
import { noop } from "lodash-es";
import { memo, ReactNode } from "react";
import styled from "styled-components";

export const Tree = memo<{
  readonly name: string;
  readonly defaultOption?: boolean;
  readonly children?: ReactNode;
  readonly id: string;
  readonly isDisable?: boolean;
  onChange?: (name: string, isCheck: boolean) => void;
}>(({ name, id, defaultOption = false, isDisable = false, children, onChange = noop }) => {
  const searchStatus = (id: string, isCheck: boolean) => {
    onChange(id, !isCheck);
  };

  const isShow = useToggle(false);

  return (
    <Layout>
      <TitleContainer onClick={() => (isDisable ? isShow.toggle() : searchStatus(id, defaultOption))} isDisable={isDisable}>
        {isDisable ? <Minuse>-</Minuse> : <Checkbox isCheck={defaultOption} />}

        <Title size={TypoSize.F14} color={Color.Ac_White}>
          {name}
        </Title>
      </TitleContainer>
      <Content show={isShow.isToggled}>{children}</Content>
    </Layout>
  );
});

const Layout = styled.div``;

const Content = styled.div<{ show: boolean }>`
  padding-left: 22px;

  transition: max-height 0.3s ${props => (props.show ? "cubic-bezier(1, 0, 1, 0)" : "cubic-bezier(0,1,0,1)")};
  max-height: ${props => (props.show ? "99999px" : "0px")};
  overflow: hidden;
`;

const TitleContainer = styled.div<{ isDisable: boolean }>`
  padding: 7px 0;
  cursor: pointer;
  user-select: none;
  display: flex;
`;

const Title = styled(Typography)`
  word-wrap: break-word;
  white-space: normal;
`;

const Checkbox = styled.span<{ isCheck: boolean }>`
  margin-right: 8px;
  margin-top: 0.15rem;
  width: 13px;
  height: 13px;
  border: 2px solid ${Color.Ac_White};
  padding: 2px;
  display: inline-flex;

  ${props =>
    props.isCheck &&
    `&:after {
    content: "";
    background: ${Color.Ac_White};
    width: 100%;
  }`}
`;

const Minuse = styled.span`
  margin-right: 8px;
  font-weight: bold;
  font-size: 14px;
`;
