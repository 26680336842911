import { WIDE_MODE } from "Constants/Size";
import styled from "styled-components";
import { PlayerButtonGroup } from "./PlayerButtonGroup";

export const CenterController = () => {
  return (
    <Layout>
      <PlayerButtonGroup />
    </Layout>
  );
};

const Layout = styled.div`
  position: absolute;
  top: 0;

  display: flex;
  align-items: center;
  height: 100%;
  left: auto;
  right: 0;
  @media ${WIDE_MODE} {
    left: 50%;
    transform: translateX(-50%);
  }
`;
