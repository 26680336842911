import { Info } from "App/Atomics/Input/Select/TextSelect";
import { createStore } from "Lib/store";

export enum Section {
  First,
  Second,
  Third
}

const createInitialState = () => ({
  section: Section.First,
  userInfo: null as Info | null,
  amount: 0 as number
});

export const {
  useSelector: useCachargePointSelector,
  action: CachargePointAction,
  ...CachargePointStore
} = createStore("CachargePoint", createInitialState, {
  setSection(state, section: Section) {
    state.section = section;
  },
  setAmount(state, amount: number) {
    state.amount = amount;
  },
  setUserInfo(state, userInfo: Info) {
    state.userInfo = userInfo;
  },
  clear(state) {
    state.section = Section.First;
    state.userInfo = null;
    state.amount = 0;
  }
});
