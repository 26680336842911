import { Button, ButtonColor, ButtonSize } from "App/Atomics/Button";
import { Icon } from "App/Atomics/Icon";
import { PlayerButton } from "App/Features/Buttons/PlayerButton";
import { Clipboard } from "App/Molecules/Clipboard";
import { useAccountSelector } from "App/Store/AccountStore";
import { useArtistNode } from "App/Store/ArtistNodeStore";
import { GenreNodeStore } from "App/Store/GenreNodeStore";
import { ModalAction, ModalStore, ModalType } from "App/Store/ModalStore";
import { useTrackNode } from "App/Store/TrackNodeStore";
import { configs } from "Config";
import { Color } from "Constants/Color";
import { artistDetailPath, HOME, trackDetailPath } from "Constants/Routes";
import { MAX_MOBILE_MODE, WIDE_MODE, X_WIDE_MODE } from "Constants/Size";
import { useCurrentLanguage } from "Lib/localization";
import { isGuestAccountModel } from "Models/AccountModel/AccountModel";
import { GenreID } from "Models/GenreModel/GenreModel";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useTrackInfo } from "Utils/useTrackInfo";
import { AddMemo, Composer, Memo, View } from "Constants/Locale";
import { secondsToTime } from "Utils/format";
import { PlaylistID, TrackItem } from "Models/PlaylistModel";
import { useRemovePlaylistTrack } from "GraphQL/Mutations/useRemovePlaylistTrack";
import { FavoriteButton } from "App/Features/Buttons/FavoriteButton";
import { useTrackActivityNodeStore } from "App/Store/TrackActivity";
import { TrackActivityType, TypeMetadataSubclass } from "GraphQL/Schema";
import { TrackBuyMobileButton } from "App/Features/Buttons/TrackBuyMobileButton";
import { BuyButtonInfo } from "App/Molecules/BuyButtonInfo";
import { PlaylistItemID } from "Models/PlaylistItemModel";

type Props = {
  readonly trackItem: TrackItem;
  readonly playlistId: PlaylistID;
  readonly isDetail?: boolean;
};

export const Playlists = ({ isDetail = false, trackItem, playlistId }: Props) => {
  const navigate = useNavigate();
  const { removePlaylistTrack } = useRemovePlaylistTrack();

  const track = useTrackNode(trackItem.id!);
  const artist = useArtistNode(track?.artist ?? null);
  const isGuest = useAccountSelector(store => isGuestAccountModel(store.current));
  const activity = useTrackActivityNodeStore(store => store.values()).filter(activity => activity.track.raw === trackItem.id?.raw);

  const { isInfo } = useTrackInfo();
  const langugeCode = useCurrentLanguage();

  const moveToArtistDetail = (id: number) => navigate(artistDetailPath.path({ id: id }));
  const moveToTrackDetail = (id: number) => navigate(trackDetailPath.path({ id: id }));

  const openLogin = () => ModalStore.dispatch(ModalAction.replace(ModalType.SIGNIN));

  const removePlaylist = async () => {
    try {
      await removePlaylistTrack({
        variables: {
          playlistId: playlistId.raw,
          trackId: trackItem.id.raw
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const openTrackDownload = () => {
    if (isGuest) {
      openLogin();
      return;
    }
    ModalStore.dispatch(ModalAction.open(ModalType.TRACK_DOWNLOAD, { track: track?.id, purchaseActivity: purchaseActivity }));
  };

  const searchToTrack = (id: GenreID) => {
    const genre = GenreNodeStore.get(id);
    navigate({
      pathname: HOME,
      search: `?${genre?.kind}s=${genre?.id.raw}`
    });
  };

  const openTrackLicense = () => {
    ModalStore.dispatch(ModalAction.open(ModalType.TRACK_LICENSE, { track: track?.id }));
  };

  const openModifyPlaylistItem = (playlistItemId: PlaylistItemID, description: string) => {
    ModalStore.dispatch(ModalAction.open(ModalType.MODIFY_PLAYLIST_ITEM, { playlistItemId, description }));
  };
  const isShow = isDetail && isInfo;
  const moveToTrackDetailUrl = `${configs.urls.current}${trackDetailPath.path({ id: trackItem.id?.raw })}`;
  const likeActivity = activity.find(({ type }) => type === TrackActivityType.Like)?.id ?? null;
  const purchaseActivity = activity.find(({ type }) => type === TrackActivityType.Purchase)?.id ?? null;

  if (!track) {
    return <></>;
  }

  return (
    <Layout>
      <Track>
        <FlexAudioContainer>
          <ButtonContainer>
            <PlayerButton id={trackItem.id!} />
          </ButtonContainer>
        </FlexAudioContainer>
        <FlexRightContainer>
          <FlexRightTopContainer>
            <FlexInformationContainer>
              <TrackTitleLayout>
                <TrackTitleContainer>
                  <TrackTitle onClick={() => moveToTrackDetail(track.id.raw)}>{track.title}</TrackTitle>
                </TrackTitleContainer>
                {artist?.name && track.subClass === TypeMetadataSubclass.Bgm && (
                  <ArtistContainer>
                    <Composer />: <Artist onClick={() => moveToArtistDetail(artist.id.raw)}>{artist.name}</Artist>
                  </ArtistContainer>
                )}
                <TrackDescriptionContainer onClick={() => openModifyPlaylistItem(trackItem.item_id, trackItem.description)}>
                  <TrackDescription>
                    {trackItem.description ? `${Memo.message()}: ${trackItem.description}` : `${AddMemo.message()}`}
                  </TrackDescription>
                  <CustomIcon name="change-pen" />
                </TrackDescriptionContainer>
              </TrackTitleLayout>
              <DurationLayout>{secondsToTime(track.duration)}</DurationLayout>
              <BpmLayout>{!!track.bpm ? track.bpm : "-"}</BpmLayout>
            </FlexInformationContainer>
            <FlexLicenseContainer onClick={openTrackLicense}>
              <LicenseButton size={ButtonSize.Small} color={ButtonColor.Disable}>
                <View />
              </LicenseButton>
            </FlexLicenseContainer>
            <FlexStatusContainer>
              <FavoriteButton likeActivity={likeActivity} track={track.id} isReloadPlaylist={false} />
              <StatusIconContainer onClick={removePlaylist}>
                <StatusIcon name="remove-music" />
              </StatusIconContainer>
              <LinkIconContainer>
                <Clipboard text={moveToTrackDetailUrl}>
                  <StatusIcon name="link" />
                </Clipboard>
              </LinkIconContainer>
              <DownloadIconContainer onClick={openTrackDownload}>
                <DownloadButton>
                  <DownLoadIcon name="download" />
                </DownloadButton>
              </DownloadIconContainer>
              <TrackBuyMobileButton id={track.id} />
            </FlexStatusContainer>

            <FlexBuyContainer>
              <BuyButtonInfo trackId={track.id} />
            </FlexBuyContainer>
          </FlexRightTopContainer>

          <TrackDetailContainer isDetail={isShow}>
            <Padding />
            {track.genreList.map(id => {
              const genre = GenreNodeStore.get(new GenreID(id.raw));
              if (genre) {
                return (
                  <SelectButton key={id.key} onClick={() => searchToTrack(id)}>
                    <SelectText>{GenreNodeStore.get(new GenreID(id.raw))?.title.text(langugeCode)}</SelectText>
                  </SelectButton>
                );
              }
              return null;
            })}
          </TrackDetailContainer>
        </FlexRightContainer>
      </Track>
    </Layout>
  );
};

const Layout = styled.div`
  border-top: 1px solid ${Color.Ac_Gray};

  &:last-child {
    border-bottom: 1px solid ${Color.Ac_Gray};
  }
`;

const TrackDetailContainer = styled.div<{ isDetail: boolean }>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  transition: all 0.3s;

  transition: max-height 0.3s ${props => (props.isDetail ? "cubic-bezier(1, 0, 1, 0)" : "cubic-bezier(0,1,0,1)")};
  max-height: ${props => (props.isDetail ? "99999px" : "0px")};
  overflow: hidden;
`;

const Padding = styled.div`
  width: 100%;
  padding-top: 1rem;
`;

const FlexRightTopContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
`;

const SimpeButton = styled(Button)`
  height: 30px;
  margin: 0.3rem;
  border-radius: 2rem;
  color: ${Color.Ac_White}!important;
  &:hover {
    border: 2px solid ${Color.Ac_White}!important;
  }
`;

const SelectButton = styled(SimpeButton)`
  padding: 0 1rem;
`;

const SelectText = styled.span`
  font-size: 0.75rem;

  line-height: 1;
  font-weight: bold;
`;

const FlexAudioContainer = styled.div`
  margin-right: 1rem;
  flex: 0 0 54px;
`;

const FlexRightContainer = styled.div`
  flex: 1 0 10px;
  min-width: 0;
  flex-direction: column;
  display: flex;
  align-items: center;
`;

const FlexInformationContainer = styled.div`
  margin-right: 1rem;
  flex: 5 0 50%;
  min-width: 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TrackTitleLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex: 9 0 50px;
  font-size: 0.9rem;
  min-width: 0;
`;

const DurationLayout = styled.div`
  margin-right: 0.625rem;
  flex: 1 0 40px;
  font-size: 0.9rem;
  text-align: center;

  @media ${X_WIDE_MODE} {
    display: block;
  }
  display: none;
`;

const BpmLayout = styled.div`
  margin-right: 0.625rem;
  flex: 1 0 40px;
  font-size: 0.9rem;
  text-align: center;
  @media ${X_WIDE_MODE} {
    display: block;
  }
  display: none;
`;

const TrackTitleContainer = styled.div`
  margin-right: 0.625rem;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TrackDescriptionContainer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0.25rem;
  display: flex;
  align-items: center;
`;

const TrackTitle = styled.span`
  font-size: 1rem;
  font-weight: bold;

  cursor: pointer;
  &:hover {
    color: ${Color.Ac_Apricot};
  }
`;

const TrackDescription = styled.span`
  font-size: 0.8rem;
  color: ${Color.Ac_LightPurple};
  cursor: pointer;
`;

const FlexStatusContainer = styled.div`
  min-width: 0;

  @media ${WIDE_MODE} {
    margin-right: 1rem;
    flex: 1 0 140px;
    display: flex;
  }

  display: flex;
  align-items: center;
`;

const StatusIconContainer = styled.div`
  height: 30px;
  min-width: 30px;

  padding: 0 5px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    path,
    rect,
    polygon {
      fill: ${Color.Ac_White};
    }
  }
`;

const StatusIcon = styled(Icon)`
  width: 2rem;

  path {
    fill: ${Color.Ac_Gray1};
  }

  rect {
    fill: rgba(0, 0, 0, 0) !important;
  }
`;

const DownLoadIcon = styled(Icon)`
  width: 2rem;
  path,
  rect {
    fill: ${Color.Ac_Gray1};
  }
`;

const ArtistContainer = styled.div`
  font-size: 0.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${Color.Ac_Gray1};
`;
const Artist = styled.span`
  cursor: pointer;
  &:hover {
    color: ${Color.Ac_Apricot};
  }
`;

const FlexBuyContainer = styled.div`
  flex: 1 0 140px;

  @media ${WIDE_MODE} {
    display: block;
  }
  display: none;
`;

const Track = styled.div`
  padding: 25px;
  display: flex;
`;

const DownloadButton = styled.span`
  display: flex !important;
`;

const ButtonContainer = styled.div`
  width: 45px;
  height: 45px;
`;

const LinkIconContainer = styled(StatusIconContainer)`
  @media ${MAX_MOBILE_MODE} {
    display: none;
  }
  display: flex;
`;

const LicenseButton = styled(Button)`
  border-radius: 0.25rem;
`;

const DownloadIconContainer = styled(StatusIconContainer)`
  @media ${WIDE_MODE} {
    display: flex;
  }
  display: none;
`;

const FlexLicenseContainer = styled.div`
  flex: 0 0 100px;

  align-items: center;
  justify-content: center;

  @media ${WIDE_MODE} {
    display: flex;
  }
  display: none;
`;

const CustomIcon = styled(Icon)`
  width: 1rem;
  height: 1rem;
  cursor: pointer;

  path {
    fill: ${Color.Ac_LightPurple};
  }
`;
