import { configs } from "Config";
import { Metadata } from "GraphQL/Schema";
import { ApolloClient, createHttpLink, gql, InMemoryCache } from "Lib/apollo-client";

const UPSERT_METADATA = gql`
  query UPSERT_METADATA($licenseDate: DateTime) {
    metadata(
      where: { type_metadata_class: "record", type_metadata_subclass: "track", track_license__some: { license_date: $licenseDate } }
      first: 2147483647
    ) {
      metadata_id
    }
  }
`;

type Result = {
  readonly metadata: Metadata[];
};

type Args = {
  readonly licenseDate?: Date;
};

export const upsertMetadata = async ({ licenseDate }: Args) => {
  const httpLink = createHttpLink({
    uri: configs.urls.service
  });

  const client = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache()
  });

  const { data, error } = await client.query<Result, Args>({
    query: UPSERT_METADATA,
    variables: {
      licenseDate
    }
  });

  return { data, error };
};
