import { Icon } from "App/Atomics/Icon";
import { Input } from "App/Atomics/Input";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { BuyCheckAction, BuyCheckStore, useBuyCheckSelector } from "App/Pages/PointCharge/Store";
import { BuyCheckStyle } from "App/Pages/PointCharge/Style";
import { Color } from "Constants/Color";
import { PointChargeText } from "Constants/Locale";
import { DESKTOP_MODE } from "Constants/Size";
import { ReactElement } from "react";
import styled from "styled-components";

export const ShippingMethod = (): ReactElement => {
  const { amount } = useBuyCheckSelector(store => store.current);

  const setAmount = (value: string) => {
    const amount = value.split(",").join("");
    if (!isNaN(+amount)) {
      BuyCheckStore.dispatch(BuyCheckAction.setAmount(+amount));
    }
  };

  const setAmountZore = () => {
    BuyCheckStore.dispatch(BuyCheckAction.setAmount(1000));
  };

  const addAmount = (amount: number) => {
    BuyCheckStore.dispatch(BuyCheckAction.addAmount(+amount));
  };

  return (
    <Layout>
      <BuyCheckStyle.Title>
        <Typography size={TypoSize.F15} weight={TypoWeight.Bold}>
          <PointChargeText />
        </Typography>
      </BuyCheckStyle.Title>

      <AmountContainer>
        <AmountInput value={amount.toLocaleString()} type="text" onChange={setAmount}></AmountInput>
        <CloseButton onClick={setAmountZore}>
          <CloseIcon name="close"></CloseIcon>
        </CloseButton>
      </AmountContainer>

      <Box>
        <BoxItem onClick={() => addAmount(1000)}>
          <Typography weight={TypoWeight.Bold}>+1천원</Typography>
        </BoxItem>
        <BoxItem onClick={() => addAmount(10000)}>
          <Typography weight={TypoWeight.Bold}>+1만원</Typography>
        </BoxItem>
        <BoxItem onClick={() => addAmount(50000)}>
          <Typography weight={TypoWeight.Bold}>+5만원</Typography>
        </BoxItem>
        <BoxItem onClick={() => addAmount(100000)}>
          <Typography weight={TypoWeight.Bold}>+10만원</Typography>
        </BoxItem>
      </Box>
    </Layout>
  );
};

const Layout = styled.div``;

const AmountContainer = styled.div`
  position: relative;
`;

const AmountInput = styled(Input.Text)`
  background-color: rgba(0, 0, 0, 0);
  border: 0px;
  border-radius: 0;
  border-bottom: 2px solid ${Color.AC_Carrot};
  padding: 10px 5px;
  &::placeholder {
    color: ${Color.Ac_White};
  }
  &:hover {
    border-bottom: 2px solid ${Color.AC_Carrot};
  }

  color: ${Color.Ac_White}!important;
  font-size: 2rem !important;
  font-weight: bold !important;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  background-color: ${Color.Ac_Gray};
  padding: 0.4rem;
  border-radius: 1rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CloseIcon = styled(Icon)`
  width: 1rem;
  height: 1rem;
`;

const Box = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media ${DESKTOP_MODE} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const BoxItem = styled.div`
  display: flex;
  user-select: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${Color.AC_Carrot};
  }

  padding: 1.25rem 0;
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin: 1rem 0;

  &:not(:first-child) {
    border-left: 0;
  }
  &:last-child {
    display: none;
  }
  @media ${DESKTOP_MODE} {
    &:last-child {
      display: flex;
    }
  }
`;
