import { TrackList } from "App/Features/List/TrackList";
import { useRelationMetadataTrack } from "App/Pages/TrackDetail/hooks/useRelationMetadataTrack";
import { useTrackNode } from "App/Store/TrackNodeStore";
import { Color } from "Constants/Color";
import { MoreSong, MoreSongDesciprtion } from "Constants/Locale";
import { TrackID } from "Models/TrackModel";
import styled from "styled-components";

type Props = {
  readonly id: TrackID;
};

export const MoreTrackList = ({ id }: Props) => {
  const track = useTrackNode(id);
  const { isLoaded } = useRelationMetadataTrack(id, track?.genreList ?? []);
  const trakcList = track?.relationTrackList ?? [];

  if (!isLoaded || !trakcList) return null;

  return (
    <Layout>
      {!!track?.relationTrackList.length && (
        <>
          <InfoContainer>
            <Title>
              <MoreSong />
            </Title>
            <Description>
              <MoreSongDesciprtion />
            </Description>
          </InfoContainer>
          <TrackList trackList={trakcList} isLoadedTrack={isLoaded} />
        </>
      )}
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
  & > * {
    border-top: none !important;
    border-bottom: 1px solid ${Color.Ac_Gray};
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
`;

const Title = styled.h2`
  font-size: 3rem;
  white-space: pre-wrap;
  text-align: center;
  margin: 50px 0;
`;

const Description = styled.p`
  font-size: 20px;
  white-space: pre-wrap;
  text-align: center;
  color: ${Color.Ac_Gray3};
`;
