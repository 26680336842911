import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { Color } from "Constants/Color";
import styled from "styled-components";

export const GradeHeader = () => {
  return (
    <Layout>
      <Container>
        <Row>
          <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
            ID
          </Typography>
        </Row>
        <Row>
          <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
            등급
          </Typography>
        </Row>
        <Row>
          <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
            가격 타이틀
          </Typography>
        </Row>
        <Row>
          <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
            가격 금액
          </Typography>
        </Row>
        <Row>
          <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
            생성 날짜
          </Typography>
        </Row>
        <Row>
          <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
            수정 날짜
          </Typography>
        </Row>
        <ButtonRaw>
          <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
            버튼
          </Typography>
        </ButtonRaw>
      </Container>
    </Layout>
  );
};

const Layout = styled.thead`
  flex-wrap: nowrap;
  white-space: nowrap;
`;

const Container = styled.tr`
  border-top: 2px solid rgba(255, 255, 255, 0.6);
  border-bottom: 2px solid rgba(255, 255, 255, 0.6);
`;
const Row = styled.th`
  padding: 1rem 2rem;
  text-align: center;
  background-color: ${Color.Ac_Gray};
  &:not(:last-child) {
    border-right: 1px dashed ${Color.Ac_Gray0};
  }
`;

const ButtonRaw = styled(Row)`
  width: 100px;
`;
