import styled from "styled-components";
import { Form } from "./Form";

export const LibraryFavorites = () => {
  return (
    <Layout>
      <Form />
    </Layout>
  );
};

const Layout = styled.div``;
