import { WatchQueryFetchPolicy } from "@apollo/client";
import { setUserWallet } from "App/Common/setUserWallet";
import { AccountAction, AccountStore, useAccountSelector } from "App/Store/AccountStore";
import { useLoadUserWallet } from "GraphQL/Queries/useLoadUserWallet";
import { isGuestAccountModel } from "Models/AccountModel/AccountModel";
import { useLayoutEffect } from "react";

interface Props {
  fetchPolicy?: WatchQueryFetchPolicy;
}

export const useWallet = ({ fetchPolicy }: Props) => {
  const isLogin = !useAccountSelector(store => isGuestAccountModel(store.current));
  const { data, loading } = useLoadUserWallet({
    skip: !isLogin,
    watchQueryFetchPolicy: fetchPolicy
  });

  useLayoutEffect(() => {
    if (!isLogin) {
      AccountStore.dispatch(AccountAction.walletClear());
      return;
    }
    if (data) {
      setUserWallet(data.userWallet);
    }
  }, [data, isLogin]);

  return { loading };
};
