import { PlayerAction, PlayerStore, usePlayerSelector } from "App/Store/PlayerStore";
import { Color } from "Constants/Color";
import { Size } from "Constants/Size";
import styled from "styled-components";
import { usePlaylist } from "Utils/artistscardPlayer";
import { CenterController } from "./CenterController";
import { LeftController } from "./LeftController";
import { PlayerView } from "./PlayerView";
import { RightController } from "./RightController";

// import { useHandleKeyboardPlay } from "./useHandleKeyboardPlay";
import { WaveController } from "./WaveController";

export const PlayerBar = () => {
  // useHandleKeyboardPlay();
  const playlist = usePlaylist();

  const { isVisiblePlayer } = usePlayerSelector(store => store);
  const togglePlayer = () => PlayerStore.dispatch(PlayerAction.toggle());
  return (
    <Layout>
      <PlayerView visiblity={!!playlist.length && isVisiblePlayer} />
      <Controller isVisible={!!playlist.length}>
        <WaveController />
        <LeftController />
        <CenterController />
        <RightController isVisiblePlayer={isVisiblePlayer} onTogglePlayer={togglePlayer} />
      </Controller>
    </Layout>
  );
};

const Layout = styled.div``;

const Controller = styled.div<{ isVisible: boolean }>`
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0;
  overflow: hidden;
  transition: height 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 900;
  background-color: ${Color.Ac_Shadow};

  ${props =>
    props.isVisible &&
    `
    overflow: initial;
    height: ${Size.PLAYER_BAR_HEIGHT_PX};
  `}
`;
