import { createStore } from "Lib/store";
import { PlaylistID } from "Models/PlaylistModel";

const createInitialState = () => ({
  playlists: [] as PlaylistID[],
  isLoadedPlaylist: false as boolean
});

export const {
  useSelector: usePlaylistModalSelector,
  action: PlaylistModalAction,
  ...PlaylistModalStore
} = createStore("PlaylistModal", createInitialState, {
  setPlaylist(state, playlists: PlaylistID[]) {
    state.playlists = [...playlists];
  },
  setIsLoadedPlaylist(state, isLoadedPlaylist: boolean) {
    state.isLoadedPlaylist = isLoadedPlaylist;
  }
});
