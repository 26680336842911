import { Icon } from "App/Atomics/Icon";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { useAccountSelector } from "App/Store/AccountStore";
import { Color } from "Constants/Color";
import { Size } from "Constants/Size";
import { ReactElement } from "react";
import styled from "styled-components";
import { ModalAction, ModalStore, ModalType } from "App/Store/ModalStore";
import {
  AccountAuth,
  AccountEmailUpdateError,
  AccountInfoManage,
  Change,
  ChangePassword,
  Edit,
  EditProfile,
  UserVerifyEmail,
  VerificationCompleted,
  Withdrawal
} from "Constants/Locale";
import { useAuth } from "Utils/useAuth";
import { Toast } from "Lib/toast";

export const Form = (): ReactElement => {
  const account = useAccountSelector(store => store.current);
  const wallet = useAccountSelector(store => store.wallet);
  const { token } = useAuth();
  const openUpdatePassword = () => ModalStore.dispatch(ModalAction.open(ModalType.UPDATE_PASSWORD));
  const openUpdateProfile = () => ModalStore.dispatch(ModalAction.open(ModalType.UPDATE_PROFILE));
  const openDeleteAccount = () => ModalStore.dispatch(ModalAction.open(ModalType.DELETE_ACCOUNT));
  const isVerification = useAccountSelector(store => store.current.isVerification);

  const openUserVerify = () => {
    if (!isVerification) {
      const accessToken = token?.access_token;
      const refreshToken = token?.refresh_token;
      ModalStore.dispatch(ModalAction.replace(ModalType.USER_VERIFY, { accessToken, refreshToken }));
      return;
    }

    Toast.warning(AccountEmailUpdateError.message());
  };

  const openHistoryPointModal = () => ModalStore.dispatch(ModalAction.open(ModalType.HISTORY_POINT));

  return (
    <Layout>
      <Title size={TypoSize.F24} weight={TypoWeight.Bold}>
        <AccountInfoManage />
      </Title>
      <EmailBox>
        <EmailContainer>
          <Email weight={TypoWeight.Bold} size={TypoSize.F18}>
            {account.email}
          </Email>
        </EmailContainer>
        <IconButton onClick={openHistoryPointModal}>
          <AccountIcon name="coin" />
          <Typography weight={TypoWeight.Bold}>{wallet?.balance.toLocaleString()}원</Typography>
        </IconButton>
      </EmailBox>

      <RowGroup>
        <Typography weight={TypoWeight.Bold}>
          <UserVerifyEmail />
        </Typography>
        {!isVerification ? (
          <IconButton onClick={openUserVerify}>
            <IconText size={TypoSize.F15} color={Color.AC_Carrot}>
              <AccountAuth />
            </IconText>
            <Icon name="arrow-next" fill={Color.AC_Carrot} />
          </IconButton>
        ) : (
          <IconButton>
            <IconText size={TypoSize.F15} color={Color.AC_Carrot}>
              <VerificationCompleted />
            </IconText>
            <Icon name="check" fill={Color.AC_Carrot} />
          </IconButton>
        )}
      </RowGroup>

      <RowGroup>
        <Typography weight={TypoWeight.Bold}>
          <EditProfile />
        </Typography>
        <IconButton onClick={openUpdateProfile}>
          <IconText size={TypoSize.F15} color={Color.AC_Carrot}>
            <Edit />
          </IconText>
          <Icon name="arrow-next" fill={Color.AC_Carrot} />
        </IconButton>
      </RowGroup>

      <RowGroup>
        <Typography weight={TypoWeight.Bold}>
          <ChangePassword />
        </Typography>
        <IconButton onClick={openUpdatePassword}>
          <IconText size={TypoSize.F15} color={Color.AC_Carrot}>
            <Change />
          </IconText>
          <Icon name="arrow-next" fill={Color.AC_Carrot} />
        </IconButton>
      </RowGroup>

      <RightGroup>
        <TextButton color={Color.Ac_Gray2} size={TypoSize.F14} onClick={openDeleteAccount}>
          <Withdrawal />
        </TextButton>
      </RightGroup>
    </Layout>
  );
};

const Layout = styled.div`
  width: 900px;
  height: calc(100vh - ${Size.HEADER_DESKTOP_HEIGHT_PX});
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const Title = styled(Typography)`
  margin-bottom: 20px;
`;
const AccountIcon = styled(Icon)`
  margin-right: 8px;
`;
const EmailBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${Color.Ac_Gray};
  padding: 30px;
`;
const IconButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const RowGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;

  & + & {
    border-top: 1px solid ${Color.Ac_Gray};
  }
`;
const RightGroup = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 30px 10px;
  border-top: 1px solid ${Color.Ac_Gray};
`;

const IconText = styled(Typography)`
  margin-top: 1.5px;
`;
const TextButton = styled(Typography)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const EmailContainer = styled.div`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Email = styled(Typography)`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;
