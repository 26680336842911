import { AccountNodeStore } from "App/Store/AccountNodeStore";
import { PayletterBillNodeStore } from "App/Store/PayletterBillNodeStore";
import { PointTransactionNodeStore } from "App/Store/PointTransactionNodeStore";
import { PointTransaction, User, UserTier } from "GraphQL/Schema";
import { AccountID } from "Models/AccountModel/AccountID";
import { AccountModel } from "Models/AccountModel/AccountModel";
import { AccountType } from "Models/AccountModel/AccountType";
import { FALLBACK_LANGUAGE_CODE } from "Models/LocalizationModel";
import { PayletterBillID } from "Models/PayletterBillModel";
import { PointTransactionID, PointTransactionModel } from "Models/PointTransactionModel";

export const setPointTransaction = (pointTransactionList: PointTransaction[]) => {
  const pointTransactionMs = pointTransactionList.map(pointTransaction => {
    const pointTransactionM = PointTransactionModel.form({
      id: pointTransaction.id,
      amount: pointTransaction.amount,
      createAt: pointTransaction.created_at,
      eventType: pointTransaction.event_type,
      requestUserId: new AccountID(pointTransaction.request_user_id),
      targetUserId: new AccountID(pointTransaction.target_user_id),
      transactionType: pointTransaction.transaction_type,
      payletterBill: pointTransaction?.payletter_bill ? new PayletterBillID(pointTransaction?.payletter_bill.id) : null,
      purchaseHistory: null
    });

    if (pointTransaction.user) {
      setUser(pointTransaction.user);
    }

    if (pointTransaction.requester) {
      setUser(pointTransaction.requester);
    }

    if (pointTransaction?.payletter_bill) {
      PayletterBillNodeStore.set(new PayletterBillID(pointTransaction?.payletter_bill.id), {
        id: new PayletterBillID(pointTransaction?.payletter_bill.id),
        isCancel: pointTransaction?.payletter_bill.is_cancel
      });
    }
    PointTransactionNodeStore.set(new PointTransactionID(pointTransaction.id), pointTransactionM);
    return pointTransactionM;
  });

  return pointTransactionMs;
};

const setUser = (user: User) => {
  const { id, email: userEmail, name, tier, is_verification } = user;
  const accountType = tier === UserTier.Admin || tier === UserTier.Staff ? AccountType.Admin : AccountType.Normal;
  const accountModel = AccountModel.withType({
    id: id!,
    type: accountType,
    displayName: [{ languageCode: FALLBACK_LANGUAGE_CODE, value: name! }],
    email: userEmail!,
    tier: tier!,
    isVerification: is_verification
  });

  AccountNodeStore.set(new AccountID(id), accountModel);
};
