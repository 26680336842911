import { Image, ImageSize } from "App/Atomics/Image";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { PayoneqNodeStore } from "App/Store/PayoneqNodeStore";
import { Color } from "Constants/Color";
import { currentLanguage } from "Lib/localization";
import { PayoneqID } from "Models/PayoneqModel";
import { ReactElement } from "react";
import styled from "styled-components";

type Props = {
  readonly id: PayoneqID;
  readonly isCurrent?: boolean;
  readonly onClick: () => void;
};

export const PayButton = ({ id, isCurrent, onClick }: Props): ReactElement => {
  const payoneqModel = PayoneqNodeStore.get(id)!;
  const language = currentLanguage();
  return (
    <Layout aria-current={isCurrent} onClick={onClick}>
      <Typography
        size={TypoSize.F12}
        color={isCurrent ? Color.AC_Carrot : Color.Ac_White}
        weight={isCurrent ? TypoWeight.Bold : TypoWeight.Regular}
      >
        {payoneqModel.title.text(language)}
      </Typography>
      {payoneqModel.image && <StyledIcon id={payoneqModel.image} size={ImageSize.PAYMENT} />}
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  padding: 15px;
  user-select: none;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.01);
  }

  &[aria-current="true"] {
    border-color: ${Color.AC_Carrot};
  }
`;

const StyledIcon = styled(Image)`
  width: 45px;
  height: 35px;
`;
