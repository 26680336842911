import { Button, ButtonColor, ButtonSize } from "App/Atomics/Button";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { useAccountNode } from "App/Store/AccountNodeStore";
import { useCurationManagementNode } from "App/Store/CurationManagementStore";
import { Color } from "Constants/Color";
import { useRefundCuration } from "GraphQL/Mutations/useRefundCuration";
import { Toast } from "Lib/toast";
import { CurationManagementID } from "Models/CurationManagementModel";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Style } from "../../Style";

type Props = {
  id: CurationManagementID;
};

export const CurationManagementDetail = ({ id }: Props) => {
  const curationManagement = useCurationManagementNode(id);
  const playlist = curationManagement?.playlist;
  const user = useAccountNode(curationManagement?.user);
  const { loading, refundCuration, data } = useRefundCuration();
  const navigate = useNavigate();
  const back = () => {
    navigate(-1);
  };

  const tryToRefundCuration = () => {
    if (!window.confirm("정말로 환불 하시겠습니까?")) {
      return;
    }
    if (!playlist?.raw) {
      Toast.error("잘못된 접근입니다.(playlist id없음)");
      return;
    }
    refundCuration({
      variables: {
        playlistId: playlist.raw
      }
    });
  };

  useEffect(() => {
    if (data) {
      Toast.success("환불 되었습니다.");
      back();
    }
  }, [data]);

  return (
    <Layout>
      <Typography weight={TypoWeight.Bold} color={Color.Ac_White} size={TypoSize.F24}>
        큐레이션에 연결된 유저
      </Typography>

      <Container>
        <>
          <Style.Row>
            <Style.Caption>유저 이메일</Style.Caption>
            <Style.InputText defaultValue={user?.email ?? "-"} isDisabled />
          </Style.Row>
          <Style.Row>
            <Style.Caption>유저 이름</Style.Caption>
            <Style.InputText defaultValue={user?.displayName.text() ?? "-"} isDisabled />
          </Style.Row>
          <Style.Row>
            <Style.Caption>유저 회사</Style.Caption>
            <Style.InputText defaultValue={user?.company ?? "-"} isDisabled />
          </Style.Row>
          <ButtonGroup>
            <SubmitButton size={ButtonSize.Small} color={ButtonColor.Disable} onClick={back}>
              뒤로가기
            </SubmitButton>
            <SubmitButton
              size={ButtonSize.Small}
              disabled={loading}
              color={!loading ? ButtonColor.PrimaryNotFill : ButtonColor.Disable}
              onClick={tryToRefundCuration}
            >
              환불 요청
            </SubmitButton>
          </ButtonGroup>
        </>
      </Container>
    </Layout>
  );
};

const Layout = styled.div``;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
`;
const SubmitButton = styled(Button)`
  width: 100px;
  margin: 0 0.25rem;
`;
const Container = styled.div`
  border-radius: 1rem;
  margin: 2rem 0;
`;
