import {DependencyList, useMemo} from 'react';
import debounce from 'debounce-promise';

export const useDebouncedCallback = <F extends (...args: readonly any[]) => any>(
  timeout: number,
  callback: F,
  dependencies: DependencyList = [],
) => {
  const createCallback = () => debounce(callback, timeout);
  return useMemo(createCallback, dependencies);
};
