import { Button, ButtonColor, ButtonSize } from "App/Atomics/Button";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { useAccountNode } from "App/Store/AccountNodeStore";
import { usePayletterBillNode } from "App/Store/PayletterBillNodeStore";

import { usePointTransactionNode } from "App/Store/PointTransactionNodeStore";
import { Color } from "Constants/Color";
import {
  PointTransactionAdmin,
  PointTransactionAdminCancel,
  PointTransactionPayletter,
  PointTransactionPayletterCancel,
  PointTransactionProduct,
  PointTransactionProductCancel
} from "Constants/Locale";
import { adminHistoryPointEditPath } from "Constants/Routes";
import { EventType, TransactionType } from "GraphQL/Schema";
import { PointTransactionID } from "Models/PointTransactionModel";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { formatDate } from "Utils/format";

type Props = {
  readonly id: PointTransactionID;
};

export const HistoryPointItem = ({ id }: Props) => {
  const historyPoint = usePointTransactionNode(id);
  const targetUser = useAccountNode(historyPoint?.targetUserId);
  const requestUser = useAccountNode(historyPoint?.requestUserId);
  const payleterBill = usePayletterBillNode(historyPoint?.payletterBill);

  const navigate = useNavigate();

  const moveToHistoryPointDetail = (id: PointTransactionID) => {
    navigate(adminHistoryPointEditPath.path({ id: id.raw }));
  };

  if (!historyPoint) {
    return null;
  }

  return (
    <Layout>
      <Raw>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          {historyPoint.id.raw}
        </Typography>
      </Raw>
      <Raw>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          {requestUser?.email} ({requestUser?.displayName.text() ?? "-"})
        </Typography>
      </Raw>
      <Raw>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          {targetUser?.email} ({targetUser?.displayName.text() ?? "-"})
        </Typography>
      </Raw>
      <Raw>
        <AmountText
          size={TypoSize.F14}
          weight={TypoWeight.Bold}
          color={historyPoint.transactionType === TransactionType.Deposit ? Color.AC_Blue : Color.Ac_Red1}
        >
          {historyPoint.transactionType === TransactionType.Deposit ? "+" : "-"}
          {historyPoint.amount.toLocaleString()}원
        </AmountText>
      </Raw>
      <Raw>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          {historyPoint?.eventType === EventType.Admin && historyPoint.transactionType === TransactionType.Deposit && (
            <PointTransactionAdmin />
          )}
          {historyPoint?.eventType === EventType.Admin && historyPoint.transactionType !== TransactionType.Deposit && (
            <PointTransactionAdminCancel />
          )}
          {historyPoint?.eventType === EventType.AdminCancel && <PointTransactionAdminCancel />}
          {historyPoint?.eventType === EventType.Payletter && <PointTransactionPayletter />}
          {historyPoint?.eventType === EventType.PayletterCancel && <PointTransactionPayletterCancel />}
          {historyPoint?.eventType === EventType.Product && <PointTransactionProduct />}
          {historyPoint?.eventType === EventType.ProductCancel && <PointTransactionProductCancel />}
        </Typography>
      </Raw>
      <Raw>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          {!payleterBill ? "환불 불가능" : payleterBill.isCancel ? "환불됨" : "환불 가능"}
        </Typography>
      </Raw>
      <Raw>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          {formatDate(historyPoint.createAt)}
        </Typography>
      </Raw>
      <Raw>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          <Button size={ButtonSize.Xsmall} color={ButtonColor.Primary} onClick={() => moveToHistoryPointDetail(id)}>
            상세
          </Button>
        </Typography>
      </Raw>
    </Layout>
  );
};

const Layout = styled.tr`
  padding: 1rem 1rem;
  border-bottom: 1px solid ${Color.Ac_Gray0};
`;

const Raw = styled.td`
  padding: 1rem 2rem;
  text-align: center;

  &:not(:last-child) {
    border-right: 1px dashed ${Color.Ac_Gray0};
  }
`;

const AmountText = styled(Typography)``;
