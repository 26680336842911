import { StringID } from "Utils/id";
import { LocalizationModel, LocalizationPayload } from "./LocalizationModel";
import { immerable } from "immer";
import { MetdataKind } from "./GenreModel/GenreModel";

export class RoleID extends StringID {
  constructor(id: string) {
    super("role", id);
  }
}

export type RolePlayload = {
  readonly id: string;
  readonly title: LocalizationPayload;
  readonly kind: MetdataKind[];
};

export class RoleModel {
  static form({ id, title, kind }: RolePlayload) {
    return new RoleModel(new RoleID(id), LocalizationModel.from(title), kind);
  }
  readonly [immerable] = true;
  constructor(readonly id: RoleID, readonly title: LocalizationModel, readonly kind: MetdataKind[]) {}
}
