import { Edge } from "Constants/Edge";
import { createStore } from "Lib/store";
import { PurchaseHistoryID } from "Models/PurchaseHistoryModel";
import { PurchaseItemID } from "Models/PurchaseItemModel";

const createInitialState = () => ({
  isLoadedPurchaseHistory: false as boolean,
  isLoadedPurchaseItem: false as boolean,
  purchaseHistoryList: [] as PurchaseHistoryID[],
  purchaseItemList: [] as PurchaseItemID[],
  edge: null as Edge | null
});

export const {
  useSelector: usePurchaseHistorySelector,
  action: PurchaseHistoryAction,
  ...PurchaseHistoryStore
} = createStore("PurchaseHistory", createInitialState, {
  clear(state) {
    state.edge = null;
    state.purchaseHistoryList = [];
    state.purchaseItemList = [];
    state.isLoadedPurchaseHistory = false;
    state.isLoadedPurchaseItem = false;
  },
  setIsLoadedPurchaseHistory(state, isLoadedPurchaseHistory: boolean) {
    state.isLoadedPurchaseHistory = isLoadedPurchaseHistory;
  },
  setIsLoadedPurchaseItem(state, isLoadedPurchaseItem: boolean) {
    state.isLoadedPurchaseItem = isLoadedPurchaseItem;
  },
  setPurchaseHistoryList(state, purchaseHistoryList: PurchaseHistoryID[]) {
    state.purchaseHistoryList = purchaseHistoryList;
  },
  addPurchaseItemList(state, purchaseItemList: PurchaseItemID[]) {
    state.purchaseItemList.push(...purchaseItemList);
  },
  setEdge(state, edge: Edge) {
    state.edge = edge;
  }
});
