import { setFileRule } from "App/Common/setFileRule";
import { MAX_FIRST_COUNT } from "Constants/Count";
import { useLoadFileRule } from "GraphQL/Queries/useLoadFileRule";

import { useEffect } from "react";

export const useFileRuleList = () => {
  const { data, loading } = useLoadFileRule({ first: MAX_FIRST_COUNT });
  useEffect(() => {
    if (data) {
      setFileRule(data.fileRule);
    }
  }, [data]);

  return { loading };
};
