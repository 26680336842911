import { useSearchParams } from "react-router-dom";

export const useSearch = () => {
  const [query, setSearch] = useSearchParams();

  const getQueryData = (): Record<string, string[]> => {
    // item -> ["genres", "a", "b", "c"]
    return [...query].reduce((arr: Record<string, string[]>, item: string[]) => {
      if (item[0] === "page") return arr;
      if (arr[item[0]]) {
        arr[item[0]].push(item[1]);
      } else {
        arr[item[0]] = [item[1]];
      }
      return arr;
    }, {});
  };

  const removeAllQuery = (key: string) => {
    const queryAll = getQueryData();
    delete queryAll[key];

    setSearch(queryAll);
  };

  const removeQuery = (key: string, value: string) => {
    const queryAll = getQueryData();
    const queryList = queryAll[key];
    const removedQuery = queryList.filter((query: string) => query !== value.replaceAll("+", " "));
    queryAll[key] = removedQuery;

    setSearch(queryAll);
  };

  const appendQuery = (key: string, value: string) => {
    const queryAll = getQueryData();
    const queryList = queryAll[key];
    if (!queryList) {
      setSearch({ ...queryAll, [key]: value });
      return;
    }

    queryList.push(value);
    setSearch(queryAll);
  };

  const setQuery = (key: string, value: string | string[]) => {
    const queryAll = getQueryData();

    setSearch({ ...queryAll, [key]: value });
  };

  return { query, removeQuery, removeAllQuery, appendQuery, setQuery };
};
