import { gql, useMutation } from "Lib/apollo-client";

const UPDATE_DURATION = gql`
  mutation UPDATE_DURATION($duration: Int, $target: Int) {
    updateDuration: update_track_duration_temporary(duration_temporary: $duration, target: $target)
  }
`;

type Result = {
  readonly updateDuration: Boolean;
};

type Args = {
  readonly duration: number;
  readonly target: number;
};

export const useUpdateDuration = () => {
  const [fetch, { loading, data, error }] = useMutation<Result, Args>(UPDATE_DURATION, {
    fetchPolicy: "no-cache"
  });

  return { updateDuration: fetch, loading, data, error };
};
