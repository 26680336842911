import { Button, ButtonColor, ButtonSize } from "App/Atomics/Button";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { useGradeNode } from "App/Store/GradeNodeStore";
import { useProductPriceNode } from "App/Store/ProductPriceNodeStore";
import { Color } from "Constants/Color";
import { adminGradeEditPath } from "Constants/Routes";
import { GradeID } from "Models/GradeModel";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { formatDate } from "Utils/format";

type Props = {
  readonly id: GradeID;
};

export const GradeItem = ({ id }: Props) => {
  const grade = useGradeNode(id);
  const productPrice = useProductPriceNode(grade?.productPrice);

  const navigate = useNavigate();
  const moveToGradeEdit = (id: number) => {
    navigate(adminGradeEditPath.path({ id }));
  };

  if (!grade) {
    return null;
  }

  return (
    <Layout>
      <Raw>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          {grade.id.raw}
        </Typography>
      </Raw>
      <Raw>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          {grade.grade}
        </Typography>
      </Raw>
      <Raw>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          {productPrice?.title}
        </Typography>
      </Raw>
      <Raw>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          {productPrice?.price.toLocaleString()}
        </Typography>
      </Raw>

      <Raw>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          {formatDate(grade.createdAt)}
        </Typography>
      </Raw>
      <Raw>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          {formatDate(grade.updatedAt)}
        </Typography>
      </Raw>
      <Raw>
        <ButtonGroup>
          <Button size={ButtonSize.Xsmall} color={ButtonColor.Primary} onClick={() => moveToGradeEdit(id.raw)}>
            수정
          </Button>
        </ButtonGroup>
      </Raw>
    </Layout>
  );
};

const Layout = styled.tr`
  padding: 1rem 1rem;
  border-bottom: 1px solid ${Color.Ac_Gray0};
`;

const Raw = styled.td`
  padding: 1rem 2rem;
  text-align: center;

  &:not(:last-child) {
    border-right: 1px dashed ${Color.Ac_Gray0};
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;

  button {
    margin: 0 0.25rem;
  }
`;
