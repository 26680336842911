import { ReactElement, useState } from "react";
import styled from "styled-components";
import { useSearch } from "Lib/use-search-query";
import { Dropdown, DropdownList } from "App/Atomics/Dropdown";
import { Color } from "Constants/Color";
import { Icon } from "App/Atomics/Icon";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { hideAll } from "tippy.js";
import { Latest, Oldest } from "Constants/Locale";
export const TrackOrder = (): ReactElement => {
  const { removeAllQuery, setQuery, query } = useSearch();
  const [isShowDropdown, setIsShowDropdown] = useState(false);

  const setQueryValue = (key: string, value: string | null) => {
    if (!value) {
      removeAllQuery(key);
      return;
    }

    setQuery(key, value);
    hideAll();
  };

  const order = query.get("order") || "metadata_id__DESC";

  const orderDesc = () => setQueryValue("order", "metadata_id__DESC");
  const orderAsc = () => setQueryValue("order", "metadata_id__ASC");

  return (
    <Dropdown.Layout
      trigger="click"
      placement={"bottom-start"}
      onShow={() => setIsShowDropdown(true)}
      onHide={() => setIsShowDropdown(false)}
      content={
        <MenuWrap>
          <MenuItem>
            <Typography
              color={order === "metadata_id__DESC" ? Color.AC_Carrot : Color.Ac_White}
              className="menu-item"
              weight={TypoWeight.Bold}
              size={TypoSize.F13}
              onClick={orderDesc}
            >
              <Latest />
            </Typography>
          </MenuItem>
          <MenuItem>
            <Typography
              color={order === "metadata_id__ASC" ? Color.AC_Carrot : Color.Ac_White}
              className="menu-item"
              weight={TypoWeight.Bold}
              size={TypoSize.F13}
              onClick={orderAsc}
            >
              <Oldest />
            </Typography>
          </MenuItem>
        </MenuWrap>
      }
    >
      <Layout>
        <OrderText size={TypoSize.F12} weight={TypoWeight.Bold}>
          {order === "metadata_id__DESC" && <Latest />}
          {order === "metadata_id__ASC" && <Oldest />}
        </OrderText>
        <DropdownIcon name="dropdown-down" className={isShowDropdown ? "dropdown-show" : ""} />
      </Layout>
    </Dropdown.Layout>
  );
};

const Layout = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  border: 2px solid ${Color.Ac_Gray1};
  border-radius: 2rem;
  height: 30px;
  padding: 0 0.5rem 0 1.3rem;
  span {
    color: ${Color.Ac_Gray1};
  }
  path {
    fill: ${Color.Ac_Gray1};
  }
  &:hover {
    border: 2px solid ${Color.Ac_White};
    span {
      color: ${Color.Ac_White};
    }
    path {
      fill: ${Color.Ac_White};
    }
  }
`;

const OrderText = styled(Typography)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 0.2rem;
`;

const MenuWrap = styled(DropdownList)`
  padding: 20px 15px 15px;
  background-color: ${Color.Ac_Charcoal};
  overflow: hidden;
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  & + & {
    margin-top: 15px;
  }
  .menu-item-select {
    color: ${Color.AC_Carrot} !important;
  }
  &:hover {
    .menu-item {
      color: ${Color.AC_Carrot} !important;
    }
    .menu-icon {
      fill: ${Color.AC_Carrot} !important;
    }
  }
`;

const DropdownIcon = styled(Icon)`
  transition: transform 0.2s ease-in-out;
  &.dropdown-show {
    transform: rotate(-180deg);
  }
`;
