import { Button } from "App/Atomics/Button";
import { useGenreNode } from "App/Store/GenreNodeStore";
import { Color } from "Constants/Color";
import { HOME } from "Constants/Routes";
import { useCurrentLanguage } from "Lib/localization";
import { GenreKind } from "Models/GenreModel/GenreKind";
import { GenreID } from "Models/GenreModel/GenreModel";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

interface Props {
  id: GenreID;
}

export const GenreButton = ({ id }: Props) => {
  const navigate = useNavigate();
  const langugeCode = useCurrentLanguage();
  const genre = useGenreNode(id);

  const searchToTrack = (id: GenreID, kind: GenreKind) => {
    navigate({
      pathname: HOME,
      search: `?${kind}s=${id.raw}`
    });
  };

  if (!genre) return <></>;

  return (
    <SelectButton onClick={() => searchToTrack(id, genre.kind)}>
      <SelectText>{genre.title.text(langugeCode)}</SelectText>
    </SelectButton>
  );
};

const SimpeButton = styled(Button)`
  height: 30px;
  margin: 0.3rem;
  border-radius: 2rem;
  color: ${Color.Ac_White}!important;
  &:hover {
    border: 2px solid ${Color.Ac_White}!important;
  }
`;

const SelectButton = styled(SimpeButton)`
  padding: 0 1rem;
`;

const SelectText = styled.span`
  font-size: 0.75rem;

  line-height: 1;
  font-weight: bold;
`;
