import { Button } from "App/Atomics/Button";
import { Typography } from "App/Atomics/Typography";
import { AdminTemplate } from "App/Templates/AdminTemplate";
import { Color } from "Constants/Color";
import { CCL_COMPANY_IDS, EFFECT_COMPANY_IDS, PUBLIC_DOMAIN_COMPANY_IDS, TRACK_COMPANY_IDS } from "Constants/CompanyIds";
import { useUpdateDuration } from "GraphQL/Mutations/useUpdateDuration";
import { useImperativeMetadata } from "GraphQL/Queries/useImperativeMetadata";

import { useImperativeMetadataUrl } from "GraphQL/Queries/useImperativeMetadataUrl";
import { useImperativeTrack } from "GraphQL/Queries/useImperativeTrack";
import { TrackOrderByInput } from "GraphQL/Schema";
import { useToggle } from "Lib/use-toggle";

import styled from "styled-components";
import { TypeMetadataSubclass } from "Utils/useSubclass";

export const DurationUpdate = () => {
  const { updateDuration } = useUpdateDuration();
  const { loadTrack } = useImperativeTrack();
  const { loadMetadataUrl } = useImperativeMetadataUrl();

  const loading = useToggle();

  const getDuration = async (url: string) => {
    return new Promise((resolve, reject) => {
      try {
        const audioContext = new window.AudioContext();
        const request = new XMLHttpRequest();

        request.onreadystatechange = () => {
          if (request.readyState === 4) {
            if (request.status === 0) {
              reject(new Error("error"));
            }
          }
        };
        request.open("GET", url, true);
        request.responseType = "arraybuffer";
        request.onload = function () {
          audioContext.decodeAudioData(
            request.response,
            function (buffer) {
              let duration = buffer.duration;
              resolve(duration);
            },
            error => {
              console.log(error);
              reject(new Error("error"));
            }
          );
        };

        request.send();
      } catch (error) {
        reject(new Error("error"));
      }
    });
  };

  const onUpdateDuration = async (orderBy: TrackOrderByInput) => {
    loading.on();

    while (true) {
      const trackList = await loadTrack({
        where: {
          duration_temporary__exist: false
        },
        first: 50,
        orderBy: [orderBy]
      });
      const trackIds = trackList.data.track.map(track => track.id);
      const metadataUrls = await loadMetadataUrl({ ids: trackIds });
      await Promise.all(
        await metadataUrls.data.metadata.map(async metadata => {
          try {
            if (metadata.metadata_url?.length) {
              const url =
                metadata?.metadata_url?.find(a => a.type_url === "mp3high")?.url ??
                metadata?.metadata_url?.find(a => a.type_url === "wav")?.url ??
                metadata?.metadata_url![0]?.url;

              if (url) {
                try {
                  const duration = await getDuration(url);
                  if (!isNaN(Number(duration))) {
                    await updateDuration({ variables: { target: +metadata.metadata_id, duration: Math.ceil(Number(duration)) } });
                  }
                } catch (error) {
                  await updateDuration({ variables: { duration: 0, target: +metadata.metadata_id } });
                }
              }
            } else {
              if (metadata.metadata_id) {
                await updateDuration({ variables: { duration: 0, target: +metadata.metadata_id } });
              } else {
                console.log("metdata 없음");
              }
            }
          } catch (error) {
            await updateDuration({ variables: { duration: 0, target: +metadata.metadata_id } });
          }
        })
      );

      if (!trackList.data.edge.hasNextPage) {
        break;
      }
    }

    loading.off();
  };

  const { loadMetdata } = useImperativeMetadata();

  const toggle = useToggle();
  const onLoadMetdata = async (companyIds: string[], subClassic: TypeMetadataSubclass) => {
    let page = 1;
    toggle.on();
    while (true) {
      const data = await loadMetdata({
        first: 500,
        page: page,
        where: {
          type_metadata_class: "record",
          type_metadata_subclass: subClassic,
          metadata_company_relation__some: {
            company__some: {
              company_id__in: companyIds.map(companyIds => companyIds.toString())
            }
          }
        }
      });

      if (!data.data.edge.hasNextPage) {
        break;
      }
      page += 1;
    }
    toggle.off();
  };

  return (
    <AdminTemplate>
      <Layout>
        <Typography>재상시간업데이트</Typography>
        {loading.isToggled && <Typography color={Color.Ac_White}>진행중</Typography>}
        {!loading.isToggled && <Button onClick={() => onUpdateDuration(TrackOrderByInput.IdAsc)}>재생시간 업데이트1 하기</Button>}
        {!loading.isToggled && <Button onClick={() => onUpdateDuration(TrackOrderByInput.IdDesc)}>재생시간 업데이트2 하기</Button>}
        <Container>
          <Typography>메타데이터업데이트</Typography>
          {loading.isToggled && <>로딩중</>}
          {!loading.isToggled && (
            <>
              <Button onClick={() => onLoadMetdata(TRACK_COMPANY_IDS, TypeMetadataSubclass.Track)}>bgm</Button>
              <Button onClick={() => onLoadMetdata(EFFECT_COMPANY_IDS, TypeMetadataSubclass.Effect)}>effect</Button>
              <Button onClick={() => onLoadMetdata(CCL_COMPANY_IDS, TypeMetadataSubclass.Track)}>ccl</Button>
              <Button onClick={() => onLoadMetdata(PUBLIC_DOMAIN_COMPANY_IDS, TypeMetadataSubclass.Track)}>public domain</Button>
            </>
          )}
        </Container>
      </Layout>
    </AdminTemplate>
  );
};

const Layout = styled.div``;

const Container = styled.div``;
