import { CartTrackList } from "App/Features/List/CartTrackList";
import { useCartItemNodeStore } from "App/Store/CartItemNodeStore";
import styled from "styled-components";

type Props = {
  readonly isLoadedCartItem: boolean;
};

export const CartTrackForm = ({ isLoadedCartItem }: Props) => {
  const cartItemList = useCartItemNodeStore(store => store.keys());

  return (
    <Layout>
      <CartTrackList cartItems={cartItemList} isLoadedCartTrack={isLoadedCartItem} />
    </Layout>
  );
};

const Layout = styled.div``;
