import { Info } from "App/Atomics/AutoComplete/TextAutoComplete";
import { Button, ButtonColor, ButtonSize } from "App/Atomics/Button";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { UserTextAutoComplete } from "App/Molecules/AutoCompletes/User";
import { useProductPriceNodeStore } from "App/Store/ProductPriceNodeStore";
import { Color } from "Constants/Color";
import { ProductPriceType } from "GraphQL/Schema";
import styled from "styled-components";
import { CurationUserAction, CurationUserStore, Section, useCurationUserSelector } from "../../Store/CurationUserStore";
import { SelectStyle, Style } from "../../Style";

export const First = () => {
  const { userInfo, productPrice } = useCurationUserSelector(store => store);

  const next = () => CurationUserStore.dispatch(CurationUserAction.setSection(Section.Second));
  const setUserInfo = (info: Info) => CurationUserStore.dispatch(CurationUserAction.setUserInfo(info));
  const setProductPrice = (info: Info) => CurationUserStore.dispatch(CurationUserAction.setProductPrice(info));
  const isDisable = !userInfo?.id || !productPrice?.id;

  const productPriceList = useProductPriceNodeStore(store =>
    store
      .values()
      .filter(({ type }) => type === ProductPriceType.Curation)
      .map(store => ({ id: store.id.raw.toString(), name: store.title! }))
  ) as Info[];

  return (
    <Layout>
      <Typography weight={TypoWeight.Bold} color={Color.Ac_White} size={TypoSize.F24}>
        큐레이션 전송을 위해 아래 정보를 입력해주세요.
      </Typography>
      <Container>
        <Style.Row>
          <Style.Caption>이메일*</Style.Caption>
          <Style.SubCaption>-큐레이션 한개당 한 계정만 연결 할 수 있습니다</Style.SubCaption>
          <UserTextAutoComplete
            defaultValue={userInfo}
            className="autocomplete"
            style={SelectStyle}
            onChange={info => {
              if (info) {
                setUserInfo(info);
              }
            }}
          />
        </Style.Row>
        <Style.Row>
          <Style.Caption>상품*</Style.Caption>
          <Style.SubCaption>-결제할 상품을 선택하세요</Style.SubCaption>
          <Style.TextSelect
            optionList={productPriceList}
            placeholder="가격정보 선택"
            isSearchable={false}
            style={SelectStyle}
            defaultValue={productPriceList.find(({ id }) => id === productPrice?.id)}
            onChange={info => info && setProductPrice(info)}
          />
        </Style.Row>
        <ButtonGroup>
          <SubmitButton
            size={ButtonSize.Small}
            color={isDisable ? ButtonColor.Disable : ButtonColor.Primary}
            disabled={isDisable}
            onClick={next}
          >
            다음
          </SubmitButton>
        </ButtonGroup>
      </Container>
    </Layout>
  );
};

const Layout = styled.div`
  max-width: 500px;
  width: 100%;
  margin: 4rem auto;
`;

const Container = styled.div`
  margin-top: 2rem;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
`;
const SubmitButton = styled(Button)`
  width: 100px;
`;
