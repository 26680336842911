import { setProductPrice } from "App/Common/setProductPrice";
import { ProductPriceNodeStore } from "App/Store/ProductPriceNodeStore";
import { MAX_FIRST_COUNT } from "Constants/Count";
import { useLoadProductPrice } from "GraphQL/Queries/useLoadProductPrice";
import { ProductPriceType } from "GraphQL/Schema";
import { useLoaded } from "Lib/use-loaded";
import { useEffect } from "react";

export const useProductPrice = () => {
  const { data, loading } = useLoadProductPrice({
    first: MAX_FIRST_COUNT,
    where: {
      is_approve: true,
      type: ProductPriceType.Curation
    }
  });
  const { isLoaded } = useLoaded(loading);

  useEffect(() => {
    ProductPriceNodeStore.clear();
    if (data) {
      const { productPrice } = data;
      setProductPrice(productPrice);
    }
  }, [data]);

  return { isLoaded };
};
