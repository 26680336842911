import { createDuck } from "./store";

export const createSeekActions = () => {
  const createInitialState = () => ({ time: null as null | number });

  return createDuck({
    namespace: "Seek",
    createInitialState,
    reducers: {
      set(state, time: null | number) {
        state.time = time === null ? null : Math.max(time, 0);
      }
    }
  });
};
