/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { ID } from "Utils/id";
import immer, { Draft, enableMapSet } from "immer";
import { DependencyList, useEffect, useMemo, useState } from "react";
import { deepEqual } from "../deep-equal";
import { createNodeMap, ReadonlyNodeMap } from "./createNodeMap";
import { unstable_batchedUpdates } from "react-dom";

enableMapSet();

export function createNodeStore<Key extends ID, Node extends {}>() {
  const nodeMap = createNodeMap<Key, Node>();

  type KeyNode = readonly [Key, null | Node];

  const verboseSubscriptions = new Set<(nodeMap: ReadonlyNodeMap<Key, Node>) => void>();
  const directSubscriptions = new Map<string, Set<(node: null | Node) => void>>();

  const useStore = <T>(select: (nodeMap: ReadonlyNodeMap<Key, Node>) => T, depdencyList: DependencyList = []): T => {
    const [current, setCurrent] = useState(() => select(nodeMap));

    useEffect(() => setCurrent(select(nodeMap)), depdencyList);

    useEffect(() => {
      let local = current;
      const next = (nodeMap: ReadonlyNodeMap<Key, Node>): void => {
        const next = select(nodeMap);
        if (!deepEqual(local, next)) {
          setCurrent(() => next);
          local = next;
        }
      };
      verboseSubscriptions.add(next);
      return () => {
        verboseSubscriptions.delete(next);
      };
    }, depdencyList);

    return current;
  };

  const useNode = (key: null | Key = null, depdencyList: DependencyList = []): null | Node => {
    const [node, setNode] = useState(() => key && nodeMap.get(key));

    useEffect(() => setNode(key && nodeMap.get(key)), [key, ...depdencyList]);

    useEffect(() => {
      if (key !== null) {
        if (!directSubscriptions.has(key.key)) {
          directSubscriptions.set(key.key, new Set());
        }
        const subscriptions = directSubscriptions.get(key.key)!;
        subscriptions.add(setNode);
        return () => {
          subscriptions.delete(setNode);
        };
      }
    }, [key, ...depdencyList]);

    return node;
  };

  const EMPTY: readonly Key[] = [];

  const useNodeList = (keyList: null | readonly Key[] = null, dependencyList: DependencyList = []): readonly Node[] => {
    const currentKeyList = keyList ?? EMPTY;

    const [nodes, setNodes] = useState(() => new Map(currentKeyList.map(key => [key.key, nodeMap.get(key)])));
    const nodeList = useMemo(() => Array.from(nodes.values()).filter((node): node is Node => node !== null), [nodes]);

    useEffect(() => setNodes(new Map(currentKeyList.map(key => [key.key, nodeMap.get(key)]))), [currentKeyList, ...dependencyList]);

    useEffect(() => {
      const unsubscribeList = currentKeyList.map(key => {
        if (!directSubscriptions.has(key.key)) {
          directSubscriptions.set(key.key, new Set());
        }
        const subscriptions = directSubscriptions.get(key.key)!;
        const next = (node: null | Node): void => setNodes(nodes => new Map(nodes.set(key.key, node)));
        subscriptions.add(next);
        return () => {
          subscriptions.delete(next);
        };
      });
      return () => {
        for (const unsubscribe of unsubscribeList) {
          unsubscribe();
        }
      };
    }, [currentKeyList, ...dependencyList]);

    return nodeList;
  };

  const dispatch = (nodeMap: ReadonlyNodeMap<Key, Node>, keyNodeList: readonly KeyNode[]): void => {
    unstable_batchedUpdates(() => {
      dispatchVerbose(nodeMap);
      dispatchDirectList(keyNodeList);
    });
  };
  const dispatchVerbose = (nodeMap: ReadonlyNodeMap<Key, Node>): void => {
    for (const subscribe of verboseSubscriptions) {
      subscribe(nodeMap);
    }
  };
  const dispatchDirectList = (keyNode: readonly KeyNode[]): void => {
    for (const [key, node] of keyNode) {
      dispatchDirect(key, node);
    }
  };
  const dispatchDirect = (key: Key, node: null | Node): void => {
    const subscriptions = directSubscriptions.get(key.key) ?? null;
    if (subscriptions !== null) {
      for (const subscribe of subscriptions) {
        subscribe(node);
      }
    }
  };

  const count = (): number => nodeMap.count();
  const keys = (): readonly Key[] => nodeMap.keys();
  const values = (): readonly Node[] => nodeMap.values();
  const has = (key: Key): boolean => nodeMap.has(key);
  const hasList = (keyList: readonly Key[]): boolean => {
    for (const key of keyList) {
      if (!nodeMap.has(key)) {
        return false;
      }
    }
    return true;
  };
  const get = (key: Key): null | Node => nodeMap.get(key);
  const getList = (keyList: readonly Key[]): readonly Node[] => keyList.filter(key => nodeMap.has(key)).map(key => nodeMap.get(key)!);
  const set = (key: Key, node: Node): void => setList([[key, node]]);
  const setList = (keyNodeList: readonly (readonly [Key, Node])[]): void => {
    const shouldDispatchKeyNodeList: KeyNode[] = [];
    for (const [key, node] of keyNodeList) {
      const previousNode = nodeMap.get(key);
      if (!deepEqual(previousNode, node)) {
        nodeMap.set(key, node);
        shouldDispatchKeyNodeList.push([key, node]);
      }
    }
    dispatch(nodeMap, shouldDispatchKeyNodeList);
  };
  const update = (key: Key, updater: (node: Draft<Node>) => void): void => updateList([key], updater);
  const updateList = (keyList: readonly Key[], updater: (node: Draft<Node>) => void): void => {
    const shouldDispatchKeyNodeList: KeyNode[] = [];
    for (const key of keyList) {
      const node = nodeMap.get(key);
      if (node !== null) {
        const next = immer(node, updater);
        if (!deepEqual(node, next)) {
          nodeMap.set(key, next);
          shouldDispatchKeyNodeList.push([key, next]);
        }
      }
    }
    dispatch(nodeMap, shouldDispatchKeyNodeList);
  };
  const remove = (key: Key): void => {
    if (nodeMap.has(key)) {
      nodeMap.remove(key);
      dispatch(nodeMap, [[key, null]]);
    }
  };
  const removeList = (keyList: readonly Key[]): void => {
    const shouldDispatchKeyList: Key[] = [];
    for (const key of keyList) {
      if (nodeMap.has(key)) {
        nodeMap.remove(key);
        shouldDispatchKeyList.push(key);
      }
    }
    dispatch(
      nodeMap,
      shouldDispatchKeyList.map(key => [key, null])
    );
  };
  const clear = (): void => {
    if (nodeMap.count() !== 0) {
      nodeMap.clear();
      dispatch(nodeMap, []);
    }
  };

  return {
    useStore,
    useNode,
    useNodeList,
    count,
    keys,
    values,
    has,
    hasList,
    get,
    getList,
    set,
    setList,
    update,
    updateList,
    remove,
    removeList,
    clear
  };
}
