import { TrackContentLoader } from "App/Features/ContentLoaders/TrackContentLoader";
import { Playlists } from "App/Features/Items/PlayLists";
import { useTrackActivity } from "App/hooks/useTrackActivity";
import { PlaylistID, TrackItem } from "Models/PlaylistModel";
import styled from "styled-components";

interface Props {
  readonly playlist: PlaylistID;
  readonly trackList: TrackItem[];
  readonly isLoadedTrack?: boolean;
  readonly isDetail?: boolean;
}

export const PlaylistTrackList = ({ playlist, trackList, isDetail = true, isLoadedTrack = true }: Props) => {
  const { isLoaded: isLoadedTrackActivity } = useTrackActivity({
    trackList: trackList.map(({ id }) => id),
    isLoadedTrack: isLoadedTrack
  });

  const isLoaded = isLoadedTrack && isLoadedTrackActivity;
  return (
    <Layout>
      {!isLoaded && (
        <>
          {[...Array(20)].map((_, key) => (
            <TrackContentLoader key={key} isDetail={isDetail} />
          ))}
        </>
      )}
      {isLoaded && (
        <>
          {trackList.map(trackId => (
            <Playlists trackItem={trackId} key={trackId.id.key} playlistId={playlist} isDetail={isDetail} />
          ))}
        </>
      )}
    </Layout>
  );
};

const Layout = styled.div``;
