import { Button, ButtonColor, ButtonSize } from "App/Atomics/Button";
import { Icon } from "App/Atomics/Icon";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { useProductPriceNode } from "App/Store/ProductPriceNodeStore";
import { Color } from "Constants/Color";
import { useLoadPlaylistDetail } from "GraphQL/Queries/useLoadPlaylistDetail";
import { useLoadUser } from "GraphQL/Queries/useLoadUser";
import { ProductPriceID } from "Models/ProductPriceModel";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { CurationUserAction, CurationUserStore, Section, useCurationUserSelector } from "../../Store/CurationUserStore";
import { Style } from "../../Style";

export const Second = () => {
  const { id } = useParams();
  const back = () => CurationUserStore.dispatch(CurationUserAction.setSection(Section.First));
  const { userInfo, productPrice } = useCurationUserSelector(store => store);

  const { data: playlistData, loading: playlistLoading } = useLoadPlaylistDetail({
    id: +id!
  });

  const trackCount = playlistData?.playlist[0].playlist_item?.length ?? 0;

  const productPriceModel = useProductPriceNode(new ProductPriceID(+productPrice?.id!));

  const { data: userData, loading: loaduser } = useLoadUser({
    where: {
      id: +userInfo?.id!
    }
  });

  const next = () => CurationUserStore.dispatch(CurationUserAction.setSection(Section.Third));

  return (
    <Layout>
      <Typography weight={TypoWeight.Bold} color={Color.Ac_White} size={TypoSize.F24}>
        큐레이션 연결할 유저의 정보가 맞는지 확인해주세요
      </Typography>

      <Container>
        {(!!loaduser || playlistLoading) && (
          <LoadingContainer>
            <Loading name="loading" />
          </LoadingContainer>
        )}
        {!loaduser && !playlistLoading && (
          <>
            <Style.Row>
              <Style.Caption>유저 이메일</Style.Caption>
              <Style.InputText defaultValue={userData?.user[0].email} isDisabled />
            </Style.Row>
            <Style.Row>
              <Style.Caption>유저 이름</Style.Caption>
              <Style.InputText defaultValue={userData?.user[0].name ?? "-"} isDisabled />
            </Style.Row>
            <Style.Row>
              <Style.Caption>유저 회사</Style.Caption>
              <Style.InputText defaultValue={userData?.user[0].company ?? "-"} isDisabled />
            </Style.Row>
            <Style.Row>
              <Style.Caption>큐레이션 금액</Style.Caption>
              <Style.InputText defaultValue={((productPriceModel?.price ?? 0) * trackCount).toLocaleString() ?? "-"} isDisabled />
            </Style.Row>

            <Style.Row>
              <Style.Caption>소지 금액</Style.Caption>
              <Style.InputText defaultValue={userData?.user[0].user_wallet?.balance.toLocaleString() + "원"} isDisabled />
            </Style.Row>
            <ButtonGroup>
              <SubmitButton size={ButtonSize.Small} color={ButtonColor.PrimaryNotFill} onClick={back}>
                뒤로가기
              </SubmitButton>
              <SubmitButton size={ButtonSize.Small} color={ButtonColor.Primary} onClick={next}>
                다음
              </SubmitButton>
            </ButtonGroup>
          </>
        )}
      </Container>
    </Layout>
  );
};

const Layout = styled.div``;
const Loading = styled(Icon)`
  width: 1.25rem;
  height: 1.25rem;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
`;
const SubmitButton = styled(Button)`
  width: 100px;
  margin: 0 0.25rem;
`;
const Container = styled.div`
  border-radius: 1rem;
  margin: 2rem 0;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
`;
