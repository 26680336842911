import { gql } from "@apollo/client";
import { PLAYLIST_FRAGMENT } from "GraphQL/Fragment/PlaylistFragment";

export const LOAD_ALL_PLAYLIST = gql`
  ${PLAYLIST_FRAGMENT}
  query LOAD_PLAYLIST($where: BgmPlaylistWhereInput) {
    playlist(where: $where) {
      ...PlaylistFragment
    }
  }
`;
