import { ButtonColor, ButtonSize } from "App/Atomics/Button";
import { Icon } from "App/Atomics/Icon";
import { ModalAction, ModalProps, ModalStore } from "App/Store/ModalStore";
import { Color } from "Constants/Color";
import { Add, AddToPlaylist, CreateNewPlaylist, PlaylistModalAdd, Title, TitleErrorNull } from "Constants/Locale";
import { useCreatePlaylist } from "GraphQL/Mutations/useCreatePlaylist";
import { useNoCacheAddPlaylistTrack } from "GraphQL/Mutations/useNoCacheAddPlaylistTrack";
import { Modal } from "Lib/modal";
import { Toast } from "Lib/toast";
import { useToggle } from "Lib/use-toggle";
import { TrackID } from "Models/TrackModel";
import { useState } from "react";
import styled from "styled-components";
import { PlaylistItem } from "./PlaylistItem";
import { usePlaylistModalSelector } from "./Store";
import { PaylistForm } from "./style";
import { usePlaylists } from "./usePlaylists";

type Props = {
  readonly props: ModalProps;
  readonly isOpen: boolean;
};

export const PlaylistsModal = ({ props, isOpen }: Props) => {
  const trackIds = props.tracks as TrackID[];
  usePlaylists();
  const { playlists, isLoadedPlaylist } = usePlaylistModalSelector(store => store);

  const [title, setTitle] = useState<string>("");
  const createPlaylistToggle = useToggle();
  const { createPlaylist, loading: createLoading } = useCreatePlaylist();
  const { addPlaylistTrack, loading: addLoading } = useNoCacheAddPlaylistTrack();
  const onRequestClose = () => ModalStore.dispatch(ModalAction.closeAll());

  const tryToCreatePlaylist = async () => {
    if (!title) {
      Toast.info(TitleErrorNull.message());
      return;
    }
    try {
      const { data } = await createPlaylist({
        variables: {
          title: title
        }
      });

      for (const trackId of trackIds) {
        await addPlaylistTrack({
          variables: {
            playlistId: +data?.createPlaylist.id!,
            trackId: trackId.raw
          }
        });
      }

      Toast.success(PlaylistModalAdd.message());
      onRequestClose();
    } catch (error) {
      console.log(error);
    }
  };

  if (!isLoadedPlaylist) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Layout>
        <HeaderContainer>
          <Header>
            <AddToPlaylist />
          </Header>
          <Close name="close" onClick={onRequestClose} />
        </HeaderContainer>
        {!!playlists.length && (
          <PlaylistContainer>
            {playlists.map(id => (
              <PlaylistItem id={id} trackIds={trackIds} key={id.key} onClose={onRequestClose} />
            ))}
          </PlaylistContainer>
        )}
        <FooterContainer>
          {!createPlaylistToggle.isToggled && (
            <Footer onClick={createPlaylistToggle.on}>
              <Plus name="plus" />
              <FooterText>
                <CreateNewPlaylist />
              </FooterText>
            </Footer>
          )}
          {createPlaylistToggle.isToggled && (
            <Footer>
              <PaylistForm.Layout onSubmit={e => e.preventDefault()}>
                <PaylistForm.LabelBox>
                  <PaylistForm.Label>
                    <Title />
                  </PaylistForm.Label>
                  <PaylistForm.Input
                    type="text"
                    placeholder={TitleErrorNull.message()}
                    defaultValue={title}
                    onChange={setTitle}
                    isDisabled={createLoading || addLoading}
                  />
                </PaylistForm.LabelBox>
                <PaylistForm.ButtonGroup>
                  {!createLoading && !addLoading && (
                    <PaylistForm.TextButton color={ButtonColor.Primary} size={ButtonSize.Xsmall} onClick={tryToCreatePlaylist}>
                      <Add />
                    </PaylistForm.TextButton>
                  )}

                  {(createLoading || addLoading) && (
                    <PaylistForm.TextButton color={ButtonColor.Primary} size={ButtonSize.Xsmall} disabled>
                      <Loading name="loading" />
                    </PaylistForm.TextButton>
                  )}
                </PaylistForm.ButtonGroup>
              </PaylistForm.Layout>
            </Footer>
          )}
        </FooterContainer>
      </Layout>
    </Modal>
  );
};

const Layout = styled.div`
  min-width: 250px;
  background-color: ${Color.Ac_Shadow};

  pointer-events: auto;

  display: flex;
  flex-direction: column;
  border-radius: 0.25rem;
`;

const HeaderContainer = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid ${Color.Ac_Gray0};
`;

const Header = styled.span`
  font-size: 0.875rem;
  font-weight: bold;
`;

const Close = styled(Icon)`
  width: 1rem;
  height: 1rem;
  cursor: pointer;
`;

const Plus = styled(Icon)`
  width: 1rem;
  height: 1rem;
  line {
    stroke: ${Color.Ac_White};
  }
`;

const Footer = styled.div`
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const FooterContainer = styled.div`
  padding: 1rem;
`;

const FooterText = styled.span`
  font-size: 0.875rem;
  margin-left: 1rem;
`;

const PlaylistContainer = styled.div`
  border-bottom: 1px solid ${Color.Ac_Gray0};
  padding: 1rem 0;
  max-height: 160px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const Loading = styled(Icon)`
  margin: 0 6px;
  width: 54.7px;
  height: 20px;
`;
