import { Icon } from "App/Atomics/Icon";
import { useAccountSelector } from "App/Store/AccountStore";
import { ModalAction, ModalStore, ModalType } from "App/Store/ModalStore";
import { useTrackActivityNodeStore } from "App/Store/TrackActivity";
import { useTrackNode } from "App/Store/TrackNodeStore";
import { Color } from "Constants/Color";
import { WIDE_MODE } from "Constants/Size";
import { useAddCartItem } from "GraphQL/Mutations/useAddCartItem";
import { useRemoveCartItem } from "GraphQL/Mutations/useRemoveCartItem";
import { TrackActivityType } from "GraphQL/Schema";
import { Toast } from "Lib/toast";
import { isGuestAccountModel } from "Models/AccountModel/AccountModel";
import { TrackID } from "Models/TrackModel";
import styled from "styled-components";

type Props = {
  readonly id?: TrackID;
};

export const TrackBuyMobileButton = ({ id }: Props) => {
  const { addCartItem, isLoaded: isLoadedAddCart } = useAddCartItem();
  const { removeCartItem, isLoaded: isLoadedRemoveCart } = useRemoveCartItem({});

  const track = useTrackNode(id);

  const activity = useTrackActivityNodeStore(store => store.values()).filter(activity => activity.track.raw === id?.raw);

  const cartActivity = activity.find(({ type }) => type === TrackActivityType.Cart);
  const purhcaseActivity = activity.find(({ type }) => type === TrackActivityType.Purchase);

  const isGuest = useAccountSelector(store => isGuestAccountModel(store.current));

  const openLicenseModal = (trackId: TrackID) => ModalStore.dispatch(ModalAction.open(ModalType.LICENSE, { license: { track: trackId } }));
  const openToSignin = () => ModalStore.dispatch(ModalAction.replace(ModalType.SIGNIN));

  const addCart = async (id: TrackID) => {
    if (isGuest) {
      openToSignin();
      return;
    }
    if (!isLoadedAddCart) {
      return;
    }
    try {
      await addCartItem({ variables: { trackId: id.raw } });
    } catch (error) {
      Toast.warning("이미 장바구니에 담긴 트랙입니다");
    }
  };

  const tryToRemoveCartItem = async (id: TrackID) => {
    if (isGuest) {
      openToSignin();
      return;
    }
    if (!isLoadedRemoveCart) {
      return;
    }
    try {
      await removeCartItem({
        variables: {
          trackId: id.raw
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  if (!track) return null;

  return (
    <CartIconContainer isCart={!!cartActivity}>
      {!purhcaseActivity && (
        <>
          {!cartActivity && <CartIcon name="cart" onClick={() => addCart(id!)} />}
          {cartActivity && <CloseIcon name="cart" onClick={() => tryToRemoveCartItem(id!)} />}
        </>
      )}

      {purhcaseActivity && <LicenseIcon name="pdf" onClick={() => openLicenseModal(id!)} />}
    </CartIconContainer>
  );
};

const CartIconContainer = styled.div<{ isCart: boolean }>`
  @media ${WIDE_MODE} {
    display: none;
  }

  height: 30px;
  min-width: 40px;
  padding: 0 5px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    path,
    rect {
      fill: ${props => (!props.isCart ? `${Color.Ac_White}` : `${Color.AC_Carrot}`)};
    }
  }
`;

const CartIcon = styled(Icon)`
  path {
    fill: ${Color.Ac_Gray1};
  }
  rect {
    fill: rgba(0, 0, 0, 0) !important;
  }
`;

const LicenseIcon = styled(Icon)`
  path,
  rect,
  polygon {
    fill: ${Color.Ac_Gray1};
  }
`;

const CloseIcon = styled(Icon)`
  path {
    fill: ${Color.AC_Carrot};
  }
  rect {
    fill: rgba(0, 0, 0, 0) !important;
  }
`;
