import { BASIC_FIRST_COUNT } from "Constants/Count";
import { Edge } from "Constants/Edge";
import { GraphqlType } from "Constants/GraphqlType";
import { EDGE_FRAGMENT } from "GraphQL/Fragment/EdgeFragment";
import { TRACK_FRAGMENT } from "GraphQL/Fragment/TrackFragment";
import { Metadata, MetadataWhereInput } from "GraphQL/Schema";
import { gql, useQuery } from "Lib/apollo-client";
import { MetdataKind } from "Models/GenreModel/GenreModel";
import { MetadataType } from "Utils/useSubclass";

const LOAD_CACHE_TRACK = gql`
  ${EDGE_FRAGMENT}
  ${TRACK_FRAGMENT}
  query LOAD_CACHE_TRACK($where: MetadataWhereInput, $first: Int, $page: Int, $orderBy: [MetadataOrderByInput]) {
    metadata(where: $where, first: $first, page: $page, orderBy: $orderBy, group: true) {
      metadata_id
      metadata_url {
        url
      }
      track {
        ...TrackFragment
      }
    }
    edge(node: Metadata, where: { metadata: $where }, first: $first, page: $page) {
      ...EdgeFragment
    }
  }
`;

type Result = {
  readonly metadata: Metadata[];
  readonly edge: Edge;
};

type Args = {
  readonly where?: MetadataWhereInput;
  readonly first?: number;
  readonly page?: number;
  readonly orderBy?: string[];
};

type Props = {
  readonly metadataType: MetadataType;
  readonly skip?: boolean;
};

export const useLoadCacheTrack = ({ skip, metadataType }: Props) => {
  const isLicense = metadataType.kind === MetdataKind.Pd || metadataType.kind === MetdataKind.Ccl;

  const { data, loading } = useQuery<Result, Args>(LOAD_CACHE_TRACK, {
    variables: {
      first: BASIC_FIRST_COUNT,
      page: 1,
      orderBy: ["metadata_id__DESC"],
      where: {
        type_metadata_class: "record",
        type_metadata_subclass: metadataType.subClass.toLocaleLowerCase(),
        track_license__some: isLicense
          ? {
              country_code__in: ["ZZ", "KR"],
              copyright: true,
              neighboring: true
            }
          : undefined,
        metadata_company_relation__some: {
          company__some: {
            company_id__in: metadataType.companyIds.map(companyIds => companyIds.toString())
          }
        }
      }
    },
    skip: skip,
    context: {
      serviceName: GraphqlType.Cache
    }
  });

  return { loading, data };
};
