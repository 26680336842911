import styled from "styled-components";
import { GradeContent } from "./GradeContent";
import { GradeHeader } from "./GradeHeader";

export const Form = () => {
  return (
    <Layout>
      <Scroll>
        <Table>
          <GradeHeader />
          <GradeContent />
        </Table>
        {/* <PriceFooter edge={edge} /> */}
      </Scroll>
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
  height: 100%;
`;

const Scroll = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;
