import { useRole } from "App/hooks/useRole";
import { MetdataKind } from "Models/GenreModel/GenreModel";

export const useRoleList = () => {
  const { loading: TrackLoading } = useRole({ kind: MetdataKind.Track });
  const { loading: EffectLoading } = useRole({ kind: MetdataKind.Effect });
  const { loading: PdLoading } = useRole({ kind: MetdataKind.Pd });
  const { loading: CclLoading } = useRole({ kind: MetdataKind.Ccl });

  const loading = TrackLoading || EffectLoading || PdLoading || CclLoading;

  return { loading };
};
