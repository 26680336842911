import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { Color } from "Constants/Color";
import { ChoosePaymentMethod, ProductPurchaseNotes } from "Constants/Locale";
import { DESKTOP_MODE } from "Constants/Size";
import styled from "styled-components";
import { SelectContent } from "./SelectContent";

export const Left = () => {
  return (
    <Layout>
      <HeaderLayout>
        <Typography weight={TypoWeight.Bold}>
          <ChoosePaymentMethod />
        </Typography>
      </HeaderLayout>
      <MainContent>
        <Content>
          <SelectContent />
        </Content>
        <FooterLayout>
          <SubTitle weight={TypoWeight.Bold} size={TypoSize.F16}>
            <ProductPurchaseNotes />
          </SubTitle>
          <List>
            <Item>
              <Typography size={TypoSize.F14} color={Color.Ac_Gray0}>
                모든 가격은 부가가치세가 포함된 가격이며 추가로 결제되는 금액은 없습니다.
              </Typography>
            </Item>
            <Item>
              <Typography size={TypoSize.F14} color={Color.Ac_Gray0}>
                포인트 충전 후 거래이력이 없는 경우, 환불 문의일로부터 7일 이내에 전체 금액에 대한 결제 취소가 가능합니다.
              </Typography>
            </Item>
            <Item>
              <Typography size={TypoSize.F14} color={Color.Ac_Gray0}>
                포인트 충전 후 거래이력이 있는 경우, 환불이 불가능합니다.
              </Typography>
            </Item>
            <Item>
              <Typography size={TypoSize.F14} color={Color.Ac_Gray0}>
                충전 포인트의 부분 환불은 불가능합니다.
              </Typography>
            </Item>
            <Item>
              <Typography size={TypoSize.F14} color={Color.Ac_Gray0}>
                구입하신 음원은 횟수와 용도에 제한없이 평생 이용 가능합니다.
              </Typography>
            </Item>
            <Item>
              <Typography size={TypoSize.F14} color={Color.Ac_Gray0}>
                이미 구입하신 음원에 대한 환불은 불가능합니다.
              </Typography>
            </Item>
          </List>
        </FooterLayout>
      </MainContent>
    </Layout>
  );
};
const Layout = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;
  border-radius: 1rem;
  margin-right: 0;
  margin-bottom: 1rem;
  @media ${DESKTOP_MODE} {
    margin-right: 2rem;
    margin-bottom: 0;
  }
  background-color: ${Color.Ac_Shadow};
`;

const MainContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const HeaderLayout = styled.div`
  padding: 1.5rem;
  border-bottom: 1px solid ${Color.Ac_Gray};
`;
const SubTitle = styled(Typography)``;

const Content = styled.div`
  padding: 1.5rem;
`;

const FooterLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  padding: 2rem 1.5rem 3rem;
  flex: 1;
`;

const List = styled.ul`
  padding-top: 1rem;
`;
const Item = styled.li`
  white-space: normal;
  padding-left: 10px;
  position: relative;
  &::before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 4px;
    height: 4px;
    display: block;
    content: "";
    border-radius: 50%;
    background-color: #888;
    opacity: 1;
  }
`;
