import { Icon } from "App/Atomics/Icon";
import { AccountContentLoader } from "App/Features/ContentLoaders/AccountContentLoader";
import { HeaderLanguageInfo } from "App/Molecules/HeaderLanguageInfo";
import { HeaderUserInfo } from "App/Molecules/HeaderUserInfo";
import { useAccountSelector } from "App/Store/AccountStore";
import { ModalAction, ModalStore, ModalType } from "App/Store/ModalStore";
import { Color } from "Constants/Color";
import { AccountLogin, AccountSignup, AvailabilityTab, Cart } from "Constants/Locale";
import { CART, HOME, LICENSE, SUPPORT } from "Constants/Routes";
import { isGuestAccountModel } from "Models/AccountModel/AccountModel";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useCartCount } from "Utils/AccountManager/hooks/useCartCount";
import { useDesktop } from "Utils/useDesktop";
import { useWallet } from "../../hooks/useWallet";

export const Top = () => {
  const navigate = useNavigate();
  const isDesktop = useDesktop();

  const isLogin = !useAccountSelector(store => isGuestAccountModel(store.current));
  const { cartCount } = useAccountSelector(store => store);
  const { loading: walletLoading } = useWallet({});
  const { loading: cartLoading } = useCartCount();

  const moveToCart = () => navigate(CART);
  const moveToHome = () => navigate(HOME);
  const moveToSupport = () => navigate(SUPPORT);
  const moveToLicense = () => navigate(LICENSE);

  const openSidebarMenu = () => {
    ModalStore.dispatch(ModalAction.open(ModalType.SIDEBAR_MENU));
  };
  const openAuthModal = () => {
    ModalStore.dispatch(ModalAction.open(ModalType.SIGNIN));
  };

  const loaded = !(walletLoading || cartLoading);

  return (
    <Layout>
      <MenuContainer>
        <LogoContainer>
          <LogoIcon name="logo" onClick={moveToHome} />
        </LogoContainer>
        {isDesktop && (
          <MenuList>
            {loaded && !isLogin && (
              <>
                <Menu onClick={openAuthModal}>
                  <AccountLogin /> / <AccountSignup />
                </Menu>
                <Menu onClick={moveToSupport}>FAQ</Menu>
                <Menu onClick={moveToLicense}>
                  <AvailabilityTab />
                </Menu>
              </>
            )}

            {loaded && isLogin && (
              <>
                <Menu onClick={moveToCart}>
                  <CartContainer>
                    {!!cartCount && <CartCount>{cartCount}</CartCount>}
                    <CartIcon name="cart" />
                  </CartContainer>
                  <Cart />
                </Menu>
                <Menu onClick={moveToSupport}>FAQ</Menu>
                <Menu onClick={moveToLicense}>
                  <AvailabilityTab />
                </Menu>
                <Menu>
                  <HeaderUserInfo />
                </Menu>
              </>
            )}

            {!loaded && (
              <>
                <Menu>
                  <AccountContentLoader />
                </Menu>
                <Menu>
                  <AccountContentLoader />
                </Menu>
                <Menu>
                  <AccountContentLoader />
                </Menu>
                <Menu>
                  <AccountContentLoader />
                </Menu>
              </>
            )}
            <HeaderLanguageInfo />
          </MenuList>
        )}
        {!isDesktop && (
          <HamburgerMenu onClick={openSidebarMenu}>
            <HamburgerIcon name="hamburger"></HamburgerIcon>
          </HamburgerMenu>
        )}
      </MenuContainer>
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
  height: 72px;

  display: flex;
  align-items: center;
  padding: 0 20px;
  background-color: ${Color.Ac_White};
`;

const MenuContainer = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
`;

const MenuList = styled.div`
  display: flex;
  align-items: center;
`;

const LogoIcon = styled(Icon)`
  width: 14.5rem;
  cursor: pointer;
`;

const Menu = styled.span`
  font-size: 0.875rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  color: ${Color.Ac_Black};
  line-height: 1;
  padding: 0 1rem;

  cursor: pointer;
`;

const HamburgerMenu = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
`;

const CartContainer = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.25rem;
`;

const CartIcon = styled(Icon)`
  path {
    fill: ${Color.Ac_Black};
  }
  width: 1.5rem;
  height: 1.5rem;
`;

const CartCount = styled.div`
  position: relative;
  top: -5px;
  left: 10px;
  color: ${Color.Ac_White};
  padding: 0.1rem 0.2rem;
  min-width: 0.8rem;
  height: 0.8rem;
  background-color: ${Color.RED_6};
  border-radius: 2rem;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.5rem;
`;

const HamburgerIcon = styled(Icon)`
  width: 1.25rem;
  height: 1.25rem;
`;
