import { useImageNode } from "App/Store/ImageNodeStore";
import { configs } from "Config";
import { Color } from "Constants/Color";
import { noop } from "lodash-es";
import { ImageID } from "Models/ImageModel";
import styled from "styled-components";

export enum ImageSize {
  M = "&size=640x360",
  L = "&size=960x540",
  XL = "&size=1600x900",
  CONCERT_CARD = "&size=600x800",
  PROFILE = "&size=300x300",
  MAGAZINE = "&size=500x500",
  DESKTOP_BANNER = "&size=1920x480",
  MAIN_SUB_BANNER = "&size=700x200",
  SUB_BANNER = "&size=1120x240",
  MOBILE_SUB_BANNER = "&size=750x160",
  MOBILE_BANNER = "&size=750x1000",
  ARTIST_DETAIL = "&size=375x500",
  LOGO = "&size=50x50",
  PAYMENT = "&size=45x35"
}

export enum ImageFormat {
  JPEG = "format=jpeg",
  PNG = "format=png",
  WEBP = "format=webp"
}

type Props = Styleable & {
  id: ImageID;
  size?: ImageSize;
  original?: boolean;
  format?: ImageFormat;
  blur?: number;
  onLoad?: () => void;
  onClick?: () => void;
};

export const Image = ({ id, size, format = ImageFormat.PNG, original, onLoad = noop, ...props }: Props) => {
  const imageNode = useImageNode(id);
  if (!imageNode) {
    return <Empty />;
  }
  return (
    <img
      src={formatImageSource({ source: imageNode.source, format, size, original })}
      alt={imageNode.description}
      onLoad={onLoad}
      {...props}
    />
  );
};

const formatImageSource = ({
  source,
  format,
  size,

  original
}: {
  source: string;
  format: ImageFormat;
  size?: ImageSize;
  original?: boolean;
}) => {
  const fullUrl = source.includes("http") ? source : `${configs.urls.image}/${source}`;
  const mark = !fullUrl.includes("?") ? "?" : "&";
  return `${fullUrl}${mark}${format}${!size ? "" : size}${!original ? "" : "&original=true"}`;
};

const Empty = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${Color.Ac_Black};
  overflow: "hidden";
`;
