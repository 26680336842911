import { AccountAction, AccountStore } from "App/Store/AccountStore";
import { UserWallet } from "GraphQL/Schema";
import { AccountID } from "Models/AccountModel/AccountID";
import { WalletModel } from "Models/WalletModal";

export const setUserWallet = (userWallet: UserWallet) => {
  if (userWallet) {
    const userWalletM = WalletModel.form({
      balance: userWallet.balance,
      createdAt: userWallet.created_at,
      eventPoint: userWallet.event_point,
      id: userWallet.id,
      userId: new AccountID(userWallet.user_id),
      updatedAt: userWallet.updated_at
    });
    AccountStore.dispatch(AccountAction.wallet(userWalletM));
  }
};
