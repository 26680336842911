export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export class Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Array: any;
  DateTime: any;
  Json: any;
  Long: any;
  TimeStamp: any;
  UUID: any;
  Upload: any;
}

export class AccessRecordChildInput {
  connect?: InputMaybe<AccessRecordWhereUniqueInput>;
}

export class AccessRecordCreateInput {
  target_id?: InputMaybe<Scalars["String"]>;
  target_table: TargetTableInPut;
}

export enum AccessRecordOrderByInput {
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC"
}

export class AccessRecordUpdateInput {
  id?: InputMaybe<Scalars["UUID"]>;
}

export class AccessRecordWhereInput {
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["ID"]>;
  target_id?: InputMaybe<Scalars["String"]>;
  target_table?: InputMaybe<Scalars["String"]>;
  uid?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
}

export class AccessRecordWhereUniqueInput {
  id?: InputMaybe<Scalars["ID"]>;
}

/**
 * {
 *   "name":"access_record",
 *   "id": "id"
 * }
 */
export class Access_Record {
  /** 현재 로우 생성일 */
  createdAt?: Maybe<Scalars["DateTime"]>;
  /** 데이터의 고유키 */
  id: Scalars["UUID"];
  /** 신청하는 데이터의 테이블 ID */
  target_id: Scalars["String"];
  /** 신청하는 데이터의 테이블명 */
  target_table: Scalars["String"];
  /** 신청하는 본인 ID */
  uid?: Maybe<Scalars["String"]>;
  /** 현재 로우 수정일 */
  updatedAt?: Maybe<Scalars["DateTime"]>;
}

export class Aggregate {
  count: Scalars["Int"];
}

export class AllowTableModel {
  allow: Scalars["String"];
  id?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  key?: InputMaybe<Scalars["String"]>;
  range?: InputMaybe<Array<InputMaybe<AllowTableRange>>>;
  table: Scalars["String"];
}

export class AllowTableRange {
  column: Scalars["String"];
  value: Scalars["String"];
}

export class AnyArgs {
  after?: InputMaybe<Scalars["Int"]>;
  before?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  is_force?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<Scalars["String"]>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where: AnyWhere;
}

export class AnyWhere {
  id?: InputMaybe<Scalars["Int"]>;
  target_user_id?: InputMaybe<Scalars["Int"]>;
  user_id?: InputMaybe<Scalars["Int"]>;
}

/**
 * {
 *   "name":"artist",
 *   "id": "artist_id" ,
 *   "type" : "Int",
 *   "artist_self_relation": { "fk" : "element_metadata_id" , "pk" : "artist_id" , "name": "artist_role_relation"},
 *   "artist_self_relation_element_artist": { "fk" : "list_metadata_id" , "pk" : "artist_id" ,"name": "artist_role_relation"},
 *   "artist_self_relation_org_artist": { "fk" : "element_metadata_id" , "pk" : "artist_id" , "name": "artist_role_relation"}
 * }
 */
export class Artist {
  artist_company_relation?: Maybe<Array<Maybe<Artist_Company_Relation>>>;
  artist_genre_relation?: Maybe<Array<Maybe<Artist_Genre_Relation>>>;
  artist_history?: Maybe<Array<Artist_History>>;
  artist_id: Scalars["ID"];
  artist_role_relation?: Maybe<Array<Artist_Role_Relation>>;
  artist_self_relation?: Maybe<Array<Artist_Self_Relation>>;
  artist_self_relation_element_artist?: Maybe<Array<Artist_Self_Relation>>;
  artist_self_relation_org_artist?: Maybe<Array<Artist_Self_Relation>>;
  artist_title?: Maybe<Array<Maybe<Artist_Title>>>;
  artist_url?: Maybe<Array<Artist_Url>>;
  memo?: Maybe<Scalars["String"]>;
  metadata_artist_relation?: Maybe<Array<Metadata_Artist_Relation>>;
  name: Scalars["String"];
  type_artist_class: Scalars["String"];
  valid_check?: Maybe<ValidType>;
  weight?: Maybe<Scalars["Int"]>;
}

/**
 * {
 *   "name":"artist",
 *   "id": "artist_id" ,
 *   "type" : "Int",
 *   "artist_self_relation": { "fk" : "element_metadata_id" , "pk" : "artist_id" , "name": "artist_role_relation"},
 *   "artist_self_relation_element_artist": { "fk" : "list_metadata_id" , "pk" : "artist_id" ,"name": "artist_role_relation"},
 *   "artist_self_relation_org_artist": { "fk" : "element_metadata_id" , "pk" : "artist_id" , "name": "artist_role_relation"}
 * }
 */
export class ArtistArtist_Company_RelationArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Artist_Company_RelationOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Artist_Company_RelationWhereInput>;
}

/**
 * {
 *   "name":"artist",
 *   "id": "artist_id" ,
 *   "type" : "Int",
 *   "artist_self_relation": { "fk" : "element_metadata_id" , "pk" : "artist_id" , "name": "artist_role_relation"},
 *   "artist_self_relation_element_artist": { "fk" : "list_metadata_id" , "pk" : "artist_id" ,"name": "artist_role_relation"},
 *   "artist_self_relation_org_artist": { "fk" : "element_metadata_id" , "pk" : "artist_id" , "name": "artist_role_relation"}
 * }
 */
export class ArtistArtist_Genre_RelationArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Artist_Genre_RelationOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Artist_Genre_RelationWhereInput>;
}

/**
 * {
 *   "name":"artist",
 *   "id": "artist_id" ,
 *   "type" : "Int",
 *   "artist_self_relation": { "fk" : "element_metadata_id" , "pk" : "artist_id" , "name": "artist_role_relation"},
 *   "artist_self_relation_element_artist": { "fk" : "list_metadata_id" , "pk" : "artist_id" ,"name": "artist_role_relation"},
 *   "artist_self_relation_org_artist": { "fk" : "element_metadata_id" , "pk" : "artist_id" , "name": "artist_role_relation"}
 * }
 */
export class ArtistArtist_HistoryArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Artist_HistoryOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Artist_HistoryWhereInput>;
}

/**
 * {
 *   "name":"artist",
 *   "id": "artist_id" ,
 *   "type" : "Int",
 *   "artist_self_relation": { "fk" : "element_metadata_id" , "pk" : "artist_id" , "name": "artist_role_relation"},
 *   "artist_self_relation_element_artist": { "fk" : "list_metadata_id" , "pk" : "artist_id" ,"name": "artist_role_relation"},
 *   "artist_self_relation_org_artist": { "fk" : "element_metadata_id" , "pk" : "artist_id" , "name": "artist_role_relation"}
 * }
 */
export class ArtistArtist_Role_RelationArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Artist_Role_RelationOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Artist_Role_RelationWhereInput>;
}

/**
 * {
 *   "name":"artist",
 *   "id": "artist_id" ,
 *   "type" : "Int",
 *   "artist_self_relation": { "fk" : "element_metadata_id" , "pk" : "artist_id" , "name": "artist_role_relation"},
 *   "artist_self_relation_element_artist": { "fk" : "list_metadata_id" , "pk" : "artist_id" ,"name": "artist_role_relation"},
 *   "artist_self_relation_org_artist": { "fk" : "element_metadata_id" , "pk" : "artist_id" , "name": "artist_role_relation"}
 * }
 */
export class ArtistArtist_Self_RelationArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Artist_Self_RelationOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Artist_Self_RelationWhereInput>;
}

/**
 * {
 *   "name":"artist",
 *   "id": "artist_id" ,
 *   "type" : "Int",
 *   "artist_self_relation": { "fk" : "element_metadata_id" , "pk" : "artist_id" , "name": "artist_role_relation"},
 *   "artist_self_relation_element_artist": { "fk" : "list_metadata_id" , "pk" : "artist_id" ,"name": "artist_role_relation"},
 *   "artist_self_relation_org_artist": { "fk" : "element_metadata_id" , "pk" : "artist_id" , "name": "artist_role_relation"}
 * }
 */
export class ArtistArtist_Self_Relation_Element_ArtistArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Artist_Self_RelationOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Artist_Self_RelationWhereInput>;
}

/**
 * {
 *   "name":"artist",
 *   "id": "artist_id" ,
 *   "type" : "Int",
 *   "artist_self_relation": { "fk" : "element_metadata_id" , "pk" : "artist_id" , "name": "artist_role_relation"},
 *   "artist_self_relation_element_artist": { "fk" : "list_metadata_id" , "pk" : "artist_id" ,"name": "artist_role_relation"},
 *   "artist_self_relation_org_artist": { "fk" : "element_metadata_id" , "pk" : "artist_id" , "name": "artist_role_relation"}
 * }
 */
export class ArtistArtist_Self_Relation_Org_ArtistArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Artist_Self_RelationOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Artist_Self_RelationWhereInput>;
}

/**
 * {
 *   "name":"artist",
 *   "id": "artist_id" ,
 *   "type" : "Int",
 *   "artist_self_relation": { "fk" : "element_metadata_id" , "pk" : "artist_id" , "name": "artist_role_relation"},
 *   "artist_self_relation_element_artist": { "fk" : "list_metadata_id" , "pk" : "artist_id" ,"name": "artist_role_relation"},
 *   "artist_self_relation_org_artist": { "fk" : "element_metadata_id" , "pk" : "artist_id" , "name": "artist_role_relation"}
 * }
 */
export class ArtistArtist_TitleArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Artist_TitleOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Artist_TitleWhereInput>;
}

/**
 * {
 *   "name":"artist",
 *   "id": "artist_id" ,
 *   "type" : "Int",
 *   "artist_self_relation": { "fk" : "element_metadata_id" , "pk" : "artist_id" , "name": "artist_role_relation"},
 *   "artist_self_relation_element_artist": { "fk" : "list_metadata_id" , "pk" : "artist_id" ,"name": "artist_role_relation"},
 *   "artist_self_relation_org_artist": { "fk" : "element_metadata_id" , "pk" : "artist_id" , "name": "artist_role_relation"}
 * }
 */
export class ArtistArtist_UrlArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Artist_UrlOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Artist_UrlWhereInput>;
}

/**
 * {
 *   "name":"artist",
 *   "id": "artist_id" ,
 *   "type" : "Int",
 *   "artist_self_relation": { "fk" : "element_metadata_id" , "pk" : "artist_id" , "name": "artist_role_relation"},
 *   "artist_self_relation_element_artist": { "fk" : "list_metadata_id" , "pk" : "artist_id" ,"name": "artist_role_relation"},
 *   "artist_self_relation_org_artist": { "fk" : "element_metadata_id" , "pk" : "artist_id" , "name": "artist_role_relation"}
 * }
 */
export class ArtistMetadata_Artist_RelationArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Metadata_Artist_RelationOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Metadata_Artist_RelationWhereInput>;
}

export class ArtistChildInput {
  connect?: InputMaybe<ArtistWhereUniqueInput>;
}

export class ArtistCreateInput {
  artist_company_relation?: InputMaybe<Artist_Company_RelationCreateManyWithoutMetadataInput>;
  artist_genre_relation?: InputMaybe<Artist_Genre_RelationCreateManyInput>;
  artist_history?: InputMaybe<Artist_HistoryCreateManyWithoutArtistInput>;
  artist_role_relation?: InputMaybe<Artist_Role_RelationCreateManyWithoutArtistInput>;
  artist_self_relation_element_artist?: InputMaybe<Artist_Self_RelationCreateManyWithoutElement_ArtistInput>;
  artist_self_relation_org_artist?: InputMaybe<Artist_Self_RelationCreateManyWithoutOrg_ArtistInput>;
  artist_title?: InputMaybe<Artist_TitleCreateManyWithoutArtistInput>;
  artist_url?: InputMaybe<Artist_UrlCreateManyWithoutArtistInput>;
  memo?: InputMaybe<Scalars["String"]>;
  metadata_artist_relation?: InputMaybe<Metadata_Artist_RelationCreateManyWithoutArtistInput>;
  name: Scalars["String"];
  type_artist_class: Scalars["String"];
  valid_check?: InputMaybe<ValidType>;
  weight?: InputMaybe<Scalars["Int"]>;
}

export enum ArtistOrderByInput {
  ArtistIdAsc = "artist_id__ASC",
  ArtistIdDesc = "artist_id__DESC",
  CreatedAtAsc = "createdAt__ASC",
  CreatedAtDesc = "createdAt__DESC",
  NameAsc = "name__ASC",
  NameDesc = "name__DESC",
  TypeArtistClassAsc = "type_artist_class__ASC",
  TypeArtistClassDesc = "type_artist_class__DESC",
  UpdatedAtAsc = "updatedAt__ASC",
  UpdatedAtDesc = "updatedAt__DESC",
  ValidCheckAsc = "valid_check__ASC",
  ValidCheckDesc = "valid_check__DESC",
  WeightAsc = "weight__ASC",
  WeightDesc = "weight__DESC"
}

export class ArtistUpdateInput {
  _onlySearch?: InputMaybe<Scalars["Boolean"]>;
  artist_company_relation?: InputMaybe<Artist_Company_RelationUpdateManyWithoutMetadataInput>;
  artist_genre_relation?: InputMaybe<Artist_Genre_RelationUpdateManyInput>;
  artist_history?: InputMaybe<Artist_HistoryUpdateManyWithoutArtistInput>;
  artist_role_relation?: InputMaybe<Artist_Role_RelationUpdateManyWithoutArtistInput>;
  artist_self_relation_element_artist?: InputMaybe<Artist_Self_RelationUpdateManyWithoutElement_ArtistInput>;
  artist_self_relation_org_artist?: InputMaybe<Artist_Self_RelationUpdateManyWithoutOrg_ArtistInput>;
  artist_title?: InputMaybe<Artist_TitleUpdateManyWithoutArtistInput>;
  artist_url?: InputMaybe<Artist_UrlUpdateManyWithoutArtistInput>;
  memo?: InputMaybe<Scalars["String"]>;
  metadata_artist_relation?: InputMaybe<Metadata_Artist_RelationUpdateManyWithoutArtistInput>;
  name?: InputMaybe<Scalars["String"]>;
  type_artist_class?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
  weight?: InputMaybe<Scalars["Int"]>;
}

export class ArtistWhereInput {
  AND?: InputMaybe<Array<ArtistWhereInput>>;
  NOT?: InputMaybe<Array<ArtistWhereInput>>;
  OR?: InputMaybe<Array<ArtistWhereInput>>;
  artist_company_relation__lateral?: InputMaybe<Scalars["Boolean"]>;
  artist_company_relation__some?: InputMaybe<Artist_Company_RelationWhereInput>;
  artist_genre_relation__lateral?: InputMaybe<Scalars["Boolean"]>;
  artist_genre_relation__some?: InputMaybe<Artist_Genre_RelationWhereInput>;
  artist_history__lateral?: InputMaybe<Scalars["Boolean"]>;
  artist_history__some?: InputMaybe<Artist_HistoryWhereInput>;
  artist_id?: InputMaybe<Scalars["ID"]>;
  artist_id__gt?: InputMaybe<Scalars["ID"]>;
  artist_id__gte?: InputMaybe<Scalars["ID"]>;
  artist_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  artist_id__lt?: InputMaybe<Scalars["ID"]>;
  artist_id__lte?: InputMaybe<Scalars["ID"]>;
  artist_id__not_in?: InputMaybe<Array<Scalars["ID"]>>;
  artist_role_relation__lateral?: InputMaybe<Scalars["Boolean"]>;
  artist_role_relation__some?: InputMaybe<Artist_Role_RelationWhereInput>;
  artist_self_relation_element_artist__some?: InputMaybe<Artist_Self_RelationWhereInput>;
  artist_self_relation_org_artist__some?: InputMaybe<Artist_Self_RelationWhereInput>;
  artist_title__lateral?: InputMaybe<Scalars["Boolean"]>;
  artist_title__some?: InputMaybe<Artist_TitleWhereInput>;
  artist_url__lateral?: InputMaybe<Scalars["Boolean"]>;
  artist_url__some?: InputMaybe<Artist_UrlWhereInput>;
  memo?: InputMaybe<Scalars["String"]>;
  memo__exist?: InputMaybe<Scalars["Boolean"]>;
  metadata_artist_relation__lateral?: InputMaybe<Scalars["Boolean"]>;
  metadata_artist_relation__some?: InputMaybe<Metadata_Artist_RelationWhereInput>;
  name?: InputMaybe<Scalars["String"]>;
  name__contains?: InputMaybe<Scalars["String"]>;
  name__ends_with?: InputMaybe<Scalars["String"]>;
  name__in?: InputMaybe<Array<Scalars["String"]>>;
  name__not?: InputMaybe<Scalars["String"]>;
  name__not_contains?: InputMaybe<Scalars["String"]>;
  name__not_ends_with?: InputMaybe<Scalars["String"]>;
  name__not_in?: InputMaybe<Array<Scalars["String"]>>;
  name__not_starts_with?: InputMaybe<Scalars["String"]>;
  name__starts_with?: InputMaybe<Scalars["String"]>;
  type_artist_class?: InputMaybe<Scalars["String"]>;
  type_artist_class__contains?: InputMaybe<Scalars["String"]>;
  type_artist_class__ends_with?: InputMaybe<Scalars["String"]>;
  type_artist_class__in?: InputMaybe<Array<Scalars["String"]>>;
  type_artist_class__not?: InputMaybe<Scalars["String"]>;
  type_artist_class__not_contains?: InputMaybe<Scalars["String"]>;
  type_artist_class__not_ends_with?: InputMaybe<Scalars["String"]>;
  type_artist_class__not_in?: InputMaybe<Array<Scalars["String"]>>;
  type_artist_class__not_starts_with?: InputMaybe<Scalars["String"]>;
  type_artist_class__starts_with?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
  valid_check__contains?: InputMaybe<ValidType>;
  valid_check__in?: InputMaybe<Array<ValidType>>;
  valid_check__not?: InputMaybe<ValidType>;
  valid_check__not_contains?: InputMaybe<ValidType>;
  valid_check__not_in?: InputMaybe<Array<ValidType>>;
  weight?: InputMaybe<Scalars["Int"]>;
  weight__gt?: InputMaybe<Scalars["Int"]>;
  weight__gte?: InputMaybe<Scalars["Int"]>;
  weight__in?: InputMaybe<Array<Scalars["Int"]>>;
  weight__lt?: InputMaybe<Scalars["Int"]>;
  weight__lte?: InputMaybe<Scalars["Int"]>;
  weight__not?: InputMaybe<Scalars["Int"]>;
  weight__not_in?: InputMaybe<Array<Scalars["Int"]>>;
}

export class ArtistWhereUniqueInput {
  artist_id?: InputMaybe<Scalars["ID"]>;
}

/**
 * {
 *   "name":"artist_company_relation",
 *   "id": "id",
 *   "artist": { "fk" : "artist_id" , "pk" : "artist_id" },
 *   "company": { "fk" : "company_id" , "pk" : "company_id" }
 * }
 */
export class Artist_Company_Relation {
  artist: Array<Maybe<Artist>>;
  artist_id?: Maybe<Scalars["ID"]>;
  company: Array<Maybe<Company>>;
  company_id?: Maybe<Scalars["ID"]>;
  exposure_order: Scalars["Int"];
  id: Scalars["ID"];
  valid_check?: Maybe<ValidType>;
}

export class Artist_Company_RelationCreateManyWithoutCompanyInput {
  create?: InputMaybe<Array<Artist_Company_RelationCreateWithoutCompanyInput>>;
}

export class Artist_Company_RelationCreateManyWithoutMetadataInput {
  create?: InputMaybe<Array<Artist_Company_RelationCreateWithoutMetadataInput>>;
}

export class Artist_Company_RelationCreateWithoutCompanyInput {
  artist: ArtistChildInput;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class Artist_Company_RelationCreateWithoutMetadataInput {
  company: CompanyChildInput;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  valid_check?: InputMaybe<ValidType>;
}

export enum Artist_Company_RelationOrderByInput {
  CreatedAtAsc = "createdAt__ASC",
  CreatedAtDesc = "createdAt__DESC",
  ExposureOrderAsc = "exposure_order__ASC",
  ExposureOrderDesc = "exposure_order__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  UpdatedAtAsc = "updatedAt__ASC",
  UpdatedAtDesc = "updatedAt__DESC",
  ValidCheckAsc = "valid_check__ASC",
  ValidCheckDesc = "valid_check__DESC"
}

export class Artist_Company_RelationUpdateInput {
  artist?: InputMaybe<ArtistChildInput>;
  company?: InputMaybe<CompanyChildInput>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  metadata?: InputMaybe<MetadataChildInput>;
  valid_check?: InputMaybe<ValidType>;
}

export class Artist_Company_RelationUpdateManyWithoutCompanyInput {
  create?: InputMaybe<Array<Artist_Company_RelationCreateWithoutCompanyInput>>;
  update?: InputMaybe<Array<Artist_Company_RelationUpdateWithWhereUniqueWithoutCompanyInput>>;
}

export class Artist_Company_RelationUpdateManyWithoutMetadataInput {
  create?: InputMaybe<Array<Artist_Company_RelationCreateWithoutMetadataInput>>;
  update?: InputMaybe<Array<Artist_Company_RelationUpdateWithWhereUniqueWithoutMetadataInput>>;
}

export class Artist_Company_RelationUpdateWithWhereUniqueWithoutCompanyInput {
  data: Artist_Company_RelationUpdateWithoutCompanyDataInput;
  where: UuidWhereUniqueInput;
}

export class Artist_Company_RelationUpdateWithWhereUniqueWithoutMetadataInput {
  data: Artist_Company_RelationUpdateWithoutMetadataDataInput;
  where: UuidWhereUniqueInput;
}

export class Artist_Company_RelationUpdateWithoutCompanyDataInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class Artist_Company_RelationUpdateWithoutMetadataDataInput {
  company?: InputMaybe<CompanyChildInput>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class Artist_Company_RelationWhereInput {
  AND?: InputMaybe<Array<Artist_Company_RelationWhereInput>>;
  NOT?: InputMaybe<Array<Artist_Company_RelationWhereInput>>;
  OR?: InputMaybe<Array<Artist_Company_RelationWhereInput>>;
  artist__lateral?: InputMaybe<Scalars["Boolean"]>;
  artist__some?: InputMaybe<ArtistWhereInput>;
  artist_id?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  artist_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  artist_id__not_include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  company__lateral?: InputMaybe<Scalars["Boolean"]>;
  company__some?: InputMaybe<CompanyWhereInput>;
  company_id?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  company_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  company_id__not_include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  exposure_order__gt?: InputMaybe<Scalars["Int"]>;
  exposure_order__gte?: InputMaybe<Scalars["Int"]>;
  exposure_order__in?: InputMaybe<Array<Scalars["Int"]>>;
  exposure_order__lt?: InputMaybe<Scalars["Int"]>;
  exposure_order__lte?: InputMaybe<Scalars["Int"]>;
  exposure_order__not?: InputMaybe<Scalars["Int"]>;
  exposure_order__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__contains?: InputMaybe<Scalars["UUID"]>;
  id__exist?: InputMaybe<Scalars["Boolean"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  id__not?: InputMaybe<Scalars["UUID"]>;
  id__not_contains?: InputMaybe<Scalars["UUID"]>;
  id__not_in?: InputMaybe<Array<Scalars["UUID"]>>;
  valid_check?: InputMaybe<ValidType>;
  valid_check__contains?: InputMaybe<ValidType>;
  valid_check__in?: InputMaybe<Array<ValidType>>;
  valid_check__not?: InputMaybe<ValidType>;
  valid_check__not_contains?: InputMaybe<ValidType>;
  valid_check__not_in?: InputMaybe<Array<ValidType>>;
}

/**
 * {
 *   "name":"artist_genre_relation",
 *   "id": "id",
 *   "artist": { "fk" : "artist_id" , "pk" : "artist_id" },
 *   "genre": { "fk" : "genre_id" , "pk" : "genre_id" }
 * }
 */
export class Artist_Genre_Relation {
  artist: Array<Maybe<Artist>>;
  artist_id?: Maybe<Scalars["ID"]>;
  exposure_order: Scalars["Int"];
  genre: Array<Maybe<Genre>>;
  genre_id?: Maybe<Scalars["ID"]>;
  id: Scalars["UUID"];
  valid_check?: Maybe<ValidType>;
}

export class Artist_Genre_RelationCreateInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  genre: GenreChildInput;
  valid_check?: InputMaybe<ValidType>;
}

export class Artist_Genre_RelationCreateManyInput {
  create?: InputMaybe<Array<Artist_Genre_RelationCreateInput>>;
}

export enum Artist_Genre_RelationOrderByInput {
  CreatedAtAsc = "createdAt__ASC",
  CreatedAtDesc = "createdAt__DESC",
  ExposureOrderAsc = "exposure_order__ASC",
  ExposureOrderDesc = "exposure_order__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  UpdatedAtAsc = "updatedAt__ASC",
  UpdatedAtDesc = "updatedAt__DESC",
  ValidCheckAsc = "valid_check__ASC",
  ValidCheckDesc = "valid_check__DESC"
}

export class Artist_Genre_RelationUpdateInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  genre?: InputMaybe<GenreChildInput>;
  valid_check?: InputMaybe<ValidType>;
}

export class Artist_Genre_RelationUpdateManyInput {
  create?: InputMaybe<Array<Artist_Genre_RelationCreateInput>>;
  update?: InputMaybe<Array<Artist_Genre_RelationUpdateWithWhereUniqueInput>>;
}

export class Artist_Genre_RelationUpdateWithWhereUniqueInput {
  data: Artist_Genre_RelationUpdateInput;
  where: UuidWhereUniqueInput;
}

export class Artist_Genre_RelationWhereInput {
  AND?: InputMaybe<Array<Artist_Genre_RelationWhereInput>>;
  NOT?: InputMaybe<Array<Artist_Genre_RelationWhereInput>>;
  OR?: InputMaybe<Array<Artist_Genre_RelationWhereInput>>;
  artist__lateral?: InputMaybe<Scalars["Boolean"]>;
  artist__some?: InputMaybe<ArtistWhereInput>;
  artist_id?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  artist_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  artist_id__not_include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  exposure_order__gt?: InputMaybe<Scalars["Int"]>;
  exposure_order__gte?: InputMaybe<Scalars["Int"]>;
  exposure_order__in?: InputMaybe<Array<Scalars["Int"]>>;
  exposure_order__lt?: InputMaybe<Scalars["Int"]>;
  exposure_order__lte?: InputMaybe<Scalars["Int"]>;
  exposure_order__not?: InputMaybe<Scalars["Int"]>;
  exposure_order__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  genre__lateral?: InputMaybe<Scalars["Boolean"]>;
  genre__some?: InputMaybe<GenreWhereInput>;
  genre_id?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  genre_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  genre_id__not_include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__contains?: InputMaybe<Scalars["UUID"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  id__not?: InputMaybe<Scalars["UUID"]>;
  id__not_contains?: InputMaybe<Scalars["UUID"]>;
  id__not_in?: InputMaybe<Array<Scalars["UUID"]>>;
  valid_check?: InputMaybe<ValidType>;
  valid_check__contains?: InputMaybe<ValidType>;
  valid_check__in?: InputMaybe<Array<ValidType>>;
  valid_check__not?: InputMaybe<ValidType>;
  valid_check__not_contains?: InputMaybe<ValidType>;
  valid_check__not_in?: InputMaybe<Array<ValidType>>;
}

/**
 * {
 *   "name":"artist_history",
 *   "id": "id",
 *   "artist": { "fk" : "artist_id" , "pk" : "artist_id" }
 * }
 */
export class Artist_History {
  artist: Array<Maybe<Artist>>;
  artist_id?: Maybe<Scalars["ID"]>;
  birth_date?: Maybe<Scalars["String"]>;
  birth_place?: Maybe<Scalars["String"]>;
  death_country_code?: Maybe<Country>;
  death_date?: Maybe<Scalars["String"]>;
  death_place?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  is_birth_inaccurate?: Maybe<Scalars["Int"]>;
  is_birth_place_inaccurate?: Maybe<Scalars["Int"]>;
  is_death_inaccurate?: Maybe<Scalars["Int"]>;
  is_death_place_inaccurate?: Maybe<Scalars["Int"]>;
  valid_check?: Maybe<ValidType>;
}

export class Artist_HistoryCreateInput {
  artist: ArtistChildInput;
  birth_date?: InputMaybe<Scalars["String"]>;
  birth_place?: InputMaybe<Scalars["String"]>;
  death_country_code?: InputMaybe<CountryChildInput>;
  death_date?: InputMaybe<Scalars["String"]>;
  death_place?: InputMaybe<Scalars["String"]>;
  is_birth_inaccurate?: InputMaybe<Scalars["Int"]>;
  is_birth_place_inaccurate?: InputMaybe<Scalars["Int"]>;
  is_death_inaccurate?: InputMaybe<Scalars["Int"]>;
  is_death_place_inaccurate?: InputMaybe<Scalars["Int"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class Artist_HistoryCreateManyWithoutArtistInput {
  create?: InputMaybe<Array<Artist_HistoryCreateWithoutArtistInput>>;
}

export class Artist_HistoryCreateWithoutArtistInput {
  birth_date?: InputMaybe<Scalars["String"]>;
  birth_place?: InputMaybe<Scalars["String"]>;
  death_country_code?: InputMaybe<CountryChildInput>;
  death_date?: InputMaybe<Scalars["String"]>;
  death_place?: InputMaybe<Scalars["String"]>;
  is_birth_inaccurate?: InputMaybe<Scalars["Int"]>;
  is_birth_place_inaccurate?: InputMaybe<Scalars["Int"]>;
  is_death_inaccurate?: InputMaybe<Scalars["Int"]>;
  is_death_place_inaccurate?: InputMaybe<Scalars["Int"]>;
  valid_check?: InputMaybe<ValidType>;
}

export enum Artist_HistoryOrderByInput {
  BirthDateAsc = "birth_date__ASC",
  BirthDateDesc = "birth_date__DESC",
  BirthPlaceAsc = "birth_place__ASC",
  BirthPlaceDesc = "birth_place__DESC",
  CreatedAtAsc = "createdAt__ASC",
  CreatedAtDesc = "createdAt__DESC",
  DeathDateAsc = "death_date__ASC",
  DeathDateDesc = "death_date__DESC",
  DeathPlaceAsc = "death_place__ASC",
  DeathPlaceDesc = "death_place__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  IsBirthInaccurateAsc = "is_birth_inaccurate__ASC",
  IsBirthInaccurateDesc = "is_birth_inaccurate__DESC",
  IsBirthPlaceInaccurateAsc = "is_birth_place_inaccurate_ASC",
  IsBirthPlaceInaccurateDesc = "is_birth_place_inaccurate_DESC",
  IsDeathInaccurateAsc = "is_death_inaccurate__ASC",
  IsDeathInaccurateDesc = "is_death_inaccurate__DESC",
  IsDeathPlaceInaccurateAsc = "is_death_place_inaccurate__ASC",
  IsDeathPlaceInaccurateDesc = "is_death_place_inaccurate__DESC",
  UpdatedAtAsc = "updatedAt__ASC",
  UpdatedAtDesc = "updatedAt__DESC",
  ValidCheckAsc = "valid_check__ASC",
  ValidCheckDesc = "valid_check__DESC"
}

export class Artist_HistoryUpdateInput {
  artist?: InputMaybe<ArtistChildInput>;
  birth_date?: InputMaybe<Scalars["String"]>;
  birth_place?: InputMaybe<Scalars["String"]>;
  death_country_code?: InputMaybe<CountryChildInput>;
  death_date?: InputMaybe<Scalars["String"]>;
  death_place?: InputMaybe<Scalars["String"]>;
  is_birth_inaccurate?: InputMaybe<Scalars["Int"]>;
  is_birth_place_inaccurate?: InputMaybe<Scalars["Int"]>;
  is_death_inaccurate?: InputMaybe<Scalars["Int"]>;
  is_death_place_inaccurate?: InputMaybe<Scalars["Int"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class Artist_HistoryUpdateManyWithoutArtistInput {
  create?: InputMaybe<Array<Artist_HistoryCreateWithoutArtistInput>>;
  update?: InputMaybe<Array<Artist_HistoryUpdateWithWhereUniqueWithoutArtistInput>>;
}

export class Artist_HistoryUpdateWithWhereUniqueWithoutArtistInput {
  data: Artist_HistoryUpdateWithoutArtistDataInput;
  where: UuidWhereUniqueInput;
}

export class Artist_HistoryUpdateWithoutArtistDataInput {
  birth_date?: InputMaybe<Scalars["String"]>;
  birth_place?: InputMaybe<Scalars["String"]>;
  death_country_code?: InputMaybe<CountryChildInput>;
  death_date?: InputMaybe<Scalars["String"]>;
  death_place?: InputMaybe<Scalars["String"]>;
  is_birth_inaccurate?: InputMaybe<Scalars["Int"]>;
  is_birth_place_inaccurate?: InputMaybe<Scalars["Int"]>;
  is_death_inaccurate?: InputMaybe<Scalars["Int"]>;
  is_death_place_inaccurate?: InputMaybe<Scalars["Int"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class Artist_HistoryWhereInput {
  AND?: InputMaybe<Array<Artist_HistoryWhereInput>>;
  NOT?: InputMaybe<Array<Artist_HistoryWhereInput>>;
  OR?: InputMaybe<Array<Artist_HistoryWhereInput>>;
  artist__lateral?: InputMaybe<Scalars["Boolean"]>;
  artist__some?: InputMaybe<ArtistWhereInput>;
  artist_id?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  artist_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  artist_id__not_include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  birth_date?: InputMaybe<Scalars["String"]>;
  birth_date__contains?: InputMaybe<Scalars["String"]>;
  birth_date__ends_with?: InputMaybe<Scalars["String"]>;
  birth_date__in?: InputMaybe<Array<Scalars["String"]>>;
  birth_date__not?: InputMaybe<Scalars["String"]>;
  birth_date__not_contains?: InputMaybe<Scalars["String"]>;
  birth_date__not_ends_with?: InputMaybe<Scalars["String"]>;
  birth_date__not_in?: InputMaybe<Array<Scalars["String"]>>;
  birth_date__not_starts_with?: InputMaybe<Scalars["String"]>;
  birth_date__starts_with?: InputMaybe<Scalars["String"]>;
  birth_place?: InputMaybe<Scalars["String"]>;
  birth_place__contains?: InputMaybe<Scalars["String"]>;
  birth_place__ends_with?: InputMaybe<Scalars["String"]>;
  birth_place__in?: InputMaybe<Array<Scalars["String"]>>;
  birth_place__not?: InputMaybe<Scalars["String"]>;
  birth_place__not_contains?: InputMaybe<Scalars["String"]>;
  birth_place__not_ends_with?: InputMaybe<Scalars["String"]>;
  birth_place__not_in?: InputMaybe<Array<Scalars["String"]>>;
  birth_place__not_starts_with?: InputMaybe<Scalars["String"]>;
  birth_place__starts_with?: InputMaybe<Scalars["String"]>;
  death_country_code?: InputMaybe<CountryWhereInput>;
  death_date?: InputMaybe<Scalars["String"]>;
  death_date__contains?: InputMaybe<Scalars["String"]>;
  death_date__ends_with?: InputMaybe<Scalars["String"]>;
  death_date__in?: InputMaybe<Array<Scalars["String"]>>;
  death_date__not?: InputMaybe<Scalars["String"]>;
  death_date__not_contains?: InputMaybe<Scalars["String"]>;
  death_date__not_ends_with?: InputMaybe<Scalars["String"]>;
  death_date__not_in?: InputMaybe<Array<Scalars["String"]>>;
  death_date__not_starts_with?: InputMaybe<Scalars["String"]>;
  death_date__starts_with?: InputMaybe<Scalars["String"]>;
  death_place?: InputMaybe<Scalars["String"]>;
  death_place__contains?: InputMaybe<Scalars["String"]>;
  death_place__ends_with?: InputMaybe<Scalars["String"]>;
  death_place__in?: InputMaybe<Array<Scalars["String"]>>;
  death_place__not?: InputMaybe<Scalars["String"]>;
  death_place__not_contains?: InputMaybe<Scalars["String"]>;
  death_place__not_ends_with?: InputMaybe<Scalars["String"]>;
  death_place__not_in?: InputMaybe<Array<Scalars["String"]>>;
  death_place__not_starts_with?: InputMaybe<Scalars["String"]>;
  death_place__starts_with?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  is_birth_inaccurate?: InputMaybe<Scalars["Int"]>;
  is_birth_inaccurate__gt?: InputMaybe<Scalars["Int"]>;
  is_birth_inaccurate__gte?: InputMaybe<Scalars["Int"]>;
  is_birth_inaccurate__in?: InputMaybe<Array<Scalars["Int"]>>;
  is_birth_inaccurate__lt?: InputMaybe<Scalars["Int"]>;
  is_birth_inaccurate__lte?: InputMaybe<Scalars["Int"]>;
  is_birth_inaccurate__not?: InputMaybe<Scalars["Int"]>;
  is_birth_inaccurate__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  is_birth_place_inaccurate?: InputMaybe<Scalars["Int"]>;
  is_birth_place_inaccurate_gt?: InputMaybe<Scalars["Int"]>;
  is_birth_place_inaccurate_gte?: InputMaybe<Scalars["Int"]>;
  is_birth_place_inaccurate_in?: InputMaybe<Array<Scalars["Int"]>>;
  is_birth_place_inaccurate_lt?: InputMaybe<Scalars["Int"]>;
  is_birth_place_inaccurate_lte?: InputMaybe<Scalars["Int"]>;
  is_birth_place_inaccurate_not?: InputMaybe<Scalars["Int"]>;
  is_birth_place_inaccurate_not_in?: InputMaybe<Array<Scalars["Int"]>>;
  is_death_inaccurate?: InputMaybe<Scalars["Int"]>;
  is_death_inaccurate__gt?: InputMaybe<Scalars["Int"]>;
  is_death_inaccurate__gte?: InputMaybe<Scalars["Int"]>;
  is_death_inaccurate__in?: InputMaybe<Array<Scalars["Int"]>>;
  is_death_inaccurate__lt?: InputMaybe<Scalars["Int"]>;
  is_death_inaccurate__lte?: InputMaybe<Scalars["Int"]>;
  is_death_inaccurate__not?: InputMaybe<Scalars["Int"]>;
  is_death_inaccurate__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  is_death_place_inaccurate?: InputMaybe<Scalars["Int"]>;
  is_death_place_inaccurate__gt?: InputMaybe<Scalars["Int"]>;
  is_death_place_inaccurate__gte?: InputMaybe<Scalars["Int"]>;
  is_death_place_inaccurate__in?: InputMaybe<Array<Scalars["Int"]>>;
  is_death_place_inaccurate__lt?: InputMaybe<Scalars["Int"]>;
  is_death_place_inaccurate__lte?: InputMaybe<Scalars["Int"]>;
  is_death_place_inaccurate__not?: InputMaybe<Scalars["Int"]>;
  is_death_place_inaccurate__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  valid_check?: InputMaybe<ValidType>;
  valid_check__contains?: InputMaybe<ValidType>;
  valid_check__in?: InputMaybe<Array<ValidType>>;
  valid_check__not?: InputMaybe<ValidType>;
  valid_check__not_contains?: InputMaybe<ValidType>;
  valid_check__not_in?: InputMaybe<Array<ValidType>>;
}

/**
 * {
 *   "name":"artist_role_relation",
 *   "id": "id",
 *   "artist": { "fk" : "artist_id" , "pk" : "artist_id" },
 *   "role": { "fk" : "role_id" , "pk" : "role_id" }
 * }
 */
export class Artist_Role_Relation {
  artist: Array<Maybe<Artist>>;
  artist_id?: Maybe<Scalars["ID"]>;
  exposure_order: Scalars["Int"];
  id: Scalars["UUID"];
  role: Array<Maybe<Role>>;
  role_id?: Maybe<Scalars["ID"]>;
  valid_check?: Maybe<ValidType>;
}

export class Artist_Role_RelationCreateManyWithoutArtistInput {
  create?: InputMaybe<Array<Artist_Role_RelationCreateWithoutArtistInput>>;
}

export class Artist_Role_RelationCreateManyWithoutRoleInput {
  create?: InputMaybe<Array<Artist_Role_RelationCreateWithoutRoleInput>>;
}

export class Artist_Role_RelationCreateWithoutArtistInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  role: RoleChildInput;
  valid_check?: InputMaybe<ValidType>;
}

export class Artist_Role_RelationCreateWithoutRoleInput {
  artist: ArtistChildInput;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  valid_check?: InputMaybe<ValidType>;
}

export enum Artist_Role_RelationOrderByInput {
  CreatedAtAsc = "createdAt__ASC",
  CreatedAtDesc = "createdAt__DESC",
  ExposureOrderAsc = "exposure_order__ASC",
  ExposureOrderDesc = "exposure_order__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  UpdatedAtAsc = "updatedAt__ASC",
  UpdatedAtDesc = "updatedAt__DESC",
  ValidCheckAsc = "valid_check__ASC",
  ValidCheckDesc = "valid_check__DESC"
}

export class Artist_Role_RelationUpdateInput {
  artist?: InputMaybe<ArtistChildInput>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  role?: InputMaybe<RoleChildInput>;
  valid_check?: InputMaybe<ValidType>;
}

export class Artist_Role_RelationUpdateManyWithoutArtistInput {
  create?: InputMaybe<Array<Artist_Role_RelationCreateWithoutArtistInput>>;
  update?: InputMaybe<Array<Artist_Role_RelationUpdateWithWhereUniqueWithoutArtistInput>>;
}

export class Artist_Role_RelationUpdateManyWithoutRoleInput {
  create?: InputMaybe<Array<Artist_Role_RelationCreateWithoutRoleInput>>;
  update?: InputMaybe<Array<Artist_Role_RelationUpdateWithWhereUniqueWithoutRoleInput>>;
}

export class Artist_Role_RelationUpdateWithWhereUniqueWithoutArtistInput {
  data: Artist_Role_RelationUpdateWithoutArtistDataInput;
  where: UuidWhereUniqueInput;
}

export class Artist_Role_RelationUpdateWithWhereUniqueWithoutRoleInput {
  data: Artist_Role_RelationUpdateWithoutRoleDataInput;
  where: UuidWhereUniqueInput;
}

export class Artist_Role_RelationUpdateWithoutArtistDataInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  role?: InputMaybe<RoleChildInput>;
  valid_check?: InputMaybe<ValidType>;
}

export class Artist_Role_RelationUpdateWithoutRoleDataInput {
  artist?: InputMaybe<ArtistChildInput>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class Artist_Role_RelationWhereInput {
  AND?: InputMaybe<Array<Artist_Role_RelationWhereInput>>;
  NOT?: InputMaybe<Array<Artist_Role_RelationWhereInput>>;
  OR?: InputMaybe<Array<Artist_Role_RelationWhereInput>>;
  artist__lateral?: InputMaybe<Scalars["Boolean"]>;
  artist__some?: InputMaybe<ArtistWhereInput>;
  artist_id?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  artist_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  artist_id__not_include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  exposure_order___gt?: InputMaybe<Scalars["Int"]>;
  exposure_order___gte?: InputMaybe<Scalars["Int"]>;
  exposure_order___in?: InputMaybe<Array<Scalars["Int"]>>;
  exposure_order___lt?: InputMaybe<Scalars["Int"]>;
  exposure_order___lte?: InputMaybe<Scalars["Int"]>;
  exposure_order___not?: InputMaybe<Scalars["Int"]>;
  exposure_order___not_in?: InputMaybe<Array<Scalars["Int"]>>;
  exposure_order__gt?: InputMaybe<Scalars["Int"]>;
  exposure_order__gte?: InputMaybe<Scalars["Int"]>;
  exposure_order__in?: InputMaybe<Array<Scalars["Int"]>>;
  exposure_order__lt?: InputMaybe<Scalars["Int"]>;
  exposure_order__lte?: InputMaybe<Scalars["Int"]>;
  exposure_order__not?: InputMaybe<Scalars["Int"]>;
  exposure_order__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__contains?: InputMaybe<Scalars["UUID"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  id__not?: InputMaybe<Scalars["UUID"]>;
  id__not_contains?: InputMaybe<Scalars["UUID"]>;
  id__not_in?: InputMaybe<Array<Scalars["UUID"]>>;
  role__lateral?: InputMaybe<Scalars["Boolean"]>;
  role__some?: InputMaybe<RoleWhereInput>;
  role_id?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  role_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  role_id__not_include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  valid_check?: InputMaybe<ValidType>;
  valid_check__contains?: InputMaybe<ValidType>;
  valid_check__in?: InputMaybe<Array<ValidType>>;
  valid_check__not?: InputMaybe<ValidType>;
  valid_check__not_contains?: InputMaybe<ValidType>;
  valid_check__not_in?: InputMaybe<Array<ValidType>>;
}

/**
 * {
 *   "name":"artist_self_relation",
 *   "id": "id",
 *   "artist": { "fk" : "element_artist_id" , "pk" : "artist_id" },
 *   "artist_self_relation_element_artist": {"id":"element_artist_id", "fk" : "artist_id" , "pk" : "org_artist_id", "table":"artist"},
 *   "artist_self_relation_org_artist": {"id":"org_artist_id", "fk" : "artist_id" , "pk" : "element_artist_id", "table":"artist"}
 * }
 */
export class Artist_Self_Relation {
  artist_self_relations_element_artist: Array<Maybe<Artist>>;
  artist_self_relations_org_artist: Array<Maybe<Artist>>;
  element_artist_id?: Maybe<Scalars["ID"]>;
  exposure_order: Scalars["Int"];
  id: Scalars["UUID"];
  org_artist_id?: Maybe<Scalars["ID"]>;
  type_function: Scalars["String"];
  valid_check?: Maybe<ValidType>;
}

export class Artist_Self_RelationCreateManyWithoutElement_ArtistInput {
  create?: InputMaybe<Array<Artist_Self_RelationCreateWithoutElement_ArtistInput>>;
}

export class Artist_Self_RelationCreateManyWithoutOrg_ArtistInput {
  create?: InputMaybe<Array<Artist_Self_RelationCreateWithoutOrg_ArtistInput>>;
}

export class Artist_Self_RelationCreateWithoutElement_ArtistInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  org_artist: ArtistChildInput;
  type_function: Scalars["String"];
  valid_check?: InputMaybe<ValidType>;
}

export class Artist_Self_RelationCreateWithoutOrg_ArtistInput {
  element_artist: ArtistChildInput;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  type_function: Scalars["String"];
  valid_check?: InputMaybe<ValidType>;
}

export enum Artist_Self_RelationOrderByInput {
  CreatedAtAsc = "createdAt__ASC",
  CreatedAtDesc = "createdAt__DESC",
  ExposureOrderAsc = "exposure_order__ASC",
  ExposureOrderDesc = "exposure_order__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  TypeFunctionAsc = "type_function__ASC",
  TypeFunctionDesc = "type_function__DESC",
  UpdatedAtAsc = "updatedAt__ASC",
  UpdatedAtDesc = "updatedAt__DESC",
  ValidCheckAsc = "valid_check__ASC",
  ValidCheckDesc = "valid_check__DESC"
}

export class Artist_Self_RelationUpdateInput {
  element_artist?: InputMaybe<ArtistChildInput>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  org_artist?: InputMaybe<ArtistChildInput>;
  type_function?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class Artist_Self_RelationUpdateManyWithoutElement_ArtistInput {
  create?: InputMaybe<Array<Artist_Self_RelationCreateWithoutElement_ArtistInput>>;
  update?: InputMaybe<Array<Artist_Self_RelationUpdateWithWhereUniqueWithoutElement_ArtistInput>>;
}

export class Artist_Self_RelationUpdateManyWithoutOrg_ArtistInput {
  create?: InputMaybe<Array<Artist_Self_RelationCreateWithoutOrg_ArtistInput>>;
  update?: InputMaybe<Array<Artist_Self_RelationUpdateWithWhereUniqueWithoutOrg_ArtistInput>>;
}

export class Artist_Self_RelationUpdateWithWhereUniqueWithoutElement_ArtistInput {
  data: Artist_Self_RelationUpdateWithoutElement_ArtistDataInput;
  where: UuidWhereUniqueInput;
}

export class Artist_Self_RelationUpdateWithWhereUniqueWithoutOrg_ArtistInput {
  data: Artist_Self_RelationUpdateWithoutOrg_ArtistDataInput;
  where: UuidWhereUniqueInput;
}

export class Artist_Self_RelationUpdateWithoutElement_ArtistDataInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  org_artist?: InputMaybe<ArtistChildInput>;
  type_function?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class Artist_Self_RelationUpdateWithoutOrg_ArtistDataInput {
  element_artist?: InputMaybe<ArtistChildInput>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  type_function?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class Artist_Self_RelationWhereInput {
  AND?: InputMaybe<Array<Artist_Self_RelationWhereInput>>;
  NOT?: InputMaybe<Array<Artist_Self_RelationWhereInput>>;
  OR?: InputMaybe<Array<Artist_Self_RelationWhereInput>>;
  element_artist__some?: InputMaybe<ArtistWhereInput>;
  element_artist_id?: InputMaybe<Scalars["ID"]>;
  element_artist_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  exposure_order__gt?: InputMaybe<Scalars["Int"]>;
  exposure_order__gte?: InputMaybe<Scalars["Int"]>;
  exposure_order__in?: InputMaybe<Array<Scalars["Int"]>>;
  exposure_order__lt?: InputMaybe<Scalars["Int"]>;
  exposure_order__lte?: InputMaybe<Scalars["Int"]>;
  exposure_order__not?: InputMaybe<Scalars["Int"]>;
  exposure_order__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  org_artist__some?: InputMaybe<ArtistWhereInput>;
  org_artist_id?: InputMaybe<Scalars["ID"]>;
  org_artist_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  type_function?: InputMaybe<Scalars["String"]>;
  type_function__contains?: InputMaybe<Scalars["String"]>;
  type_function__ends_with?: InputMaybe<Scalars["String"]>;
  type_function__in?: InputMaybe<Array<Scalars["String"]>>;
  type_function__not?: InputMaybe<Scalars["String"]>;
  type_function__not_contains?: InputMaybe<Scalars["String"]>;
  type_function__not_ends_with?: InputMaybe<Scalars["String"]>;
  type_function__not_in?: InputMaybe<Array<Scalars["String"]>>;
  type_function__not_starts_with?: InputMaybe<Scalars["String"]>;
  type_function__starts_with?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
  valid_check__contains?: InputMaybe<ValidType>;
  valid_check__in?: InputMaybe<Array<ValidType>>;
  valid_check__not?: InputMaybe<ValidType>;
  valid_check__not_contains?: InputMaybe<ValidType>;
  valid_check__not_in?: InputMaybe<Array<ValidType>>;
}

/**
 * {
 *   "name":"artist_title",
 *   "id": "id",
 *   "artist": { "fk" : "artist_id" , "pk" : "artist_id" },
 *   "language": { "fk" : "language_code" , "pk" : "language_code" }
 * }
 */
export class Artist_Title {
  artist: Array<Maybe<Artist>>;
  artist_id?: Maybe<Scalars["ID"]>;
  exposure_order: Scalars["Int"];
  id: Scalars["UUID"];
  language?: Maybe<Array<Maybe<Language>>>;
  language_code?: Maybe<Scalars["ID"]>;
  tsvec_words?: Maybe<Scalars["String"]>;
  type_artist_title: Scalars["String"];
  valid_check?: Maybe<ValidType>;
  value: Scalars["String"];
}

/**
 * {
 *   "name":"artist_title",
 *   "id": "id",
 *   "artist": { "fk" : "artist_id" , "pk" : "artist_id" },
 *   "language": { "fk" : "language_code" , "pk" : "language_code" }
 * }
 */
export class Artist_TitleLanguageArgs {
  where?: InputMaybe<LanguageWhereInput>;
}

export class Artist_TitleCreateManyWithoutArtistInput {
  create?: InputMaybe<Array<Artist_TitleCreateWithoutArtistInput>>;
}

export class Artist_TitleCreateWithoutArtistInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  language: LanguageChildInput;
  type_artist_title: Scalars["String"];
  valid_check?: InputMaybe<ValidType>;
  value: Scalars["String"];
}

export enum Artist_TitleOrderByInput {
  CreatedAtAsc = "createdAt__ASC",
  CreatedAtDesc = "createdAt__DESC",
  ExposureOrderAsc = "exposure_order__ASC",
  ExposureOrderDesc = "exposure_order__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  TypeArtistTitleAsc = "type_artist_title__ASC",
  TypeArtistTitleDesc = "type_artist_title__DESC",
  UpdatedAtAsc = "updatedAt__ASC",
  UpdatedAtDesc = "updatedAt__DESC",
  ValidCheckAsc = "valid_check__ASC",
  ValidCheckDesc = "valid_check__DESC",
  ValueAsc = "value__ASC",
  ValueDesc = "value__DESC",
  ValueSimilarity = "value__SIMILARITY"
}

export class Artist_TitleUpdateInput {
  artist?: InputMaybe<ArtistChildInput>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  language?: InputMaybe<LanguageChildInput>;
  type_artist_title?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
  value?: InputMaybe<Scalars["String"]>;
}

export class Artist_TitleUpdateManyWithoutArtistInput {
  create?: InputMaybe<Array<Artist_TitleCreateWithoutArtistInput>>;
  update?: InputMaybe<Array<Artist_TitleUpdateWithWhereUniqueWithoutArtistInput>>;
}

export class Artist_TitleUpdateWithWhereUniqueWithoutArtistInput {
  data: Artist_TitleUpdateWithoutArtistDataInput;
  where: UuidWhereUniqueInput;
}

export class Artist_TitleUpdateWithoutArtistDataInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  language?: InputMaybe<LanguageChildInput>;
  type_artist_title?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
  value?: InputMaybe<Scalars["String"]>;
}

export class Artist_TitleWhereInput {
  AND?: InputMaybe<Array<Artist_TitleWhereInput>>;
  NOT?: InputMaybe<Array<Artist_TitleWhereInput>>;
  OR?: InputMaybe<Array<Artist_TitleWhereInput>>;
  artist__lateral?: InputMaybe<Scalars["Boolean"]>;
  artist__some?: InputMaybe<ArtistWhereInput>;
  artist_id?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  artist_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  artist_id__not_include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  exposure_order__gt?: InputMaybe<Scalars["Int"]>;
  exposure_order__gte?: InputMaybe<Scalars["Int"]>;
  exposure_order__in?: InputMaybe<Array<Scalars["Int"]>>;
  exposure_order__lt?: InputMaybe<Scalars["Int"]>;
  exposure_order__lte?: InputMaybe<Scalars["Int"]>;
  exposure_order__not?: InputMaybe<Scalars["Int"]>;
  exposure_order__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  language__lateral?: InputMaybe<Scalars["Boolean"]>;
  language__some?: InputMaybe<LanguageWhereInput>;
  language_code?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  language_code__in?: InputMaybe<Array<Scalars["ID"]>>;
  language_code__not_include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  tsvec_words__search?: InputMaybe<Scalars["String"]>;
  type_artist_title?: InputMaybe<Scalars["String"]>;
  type_artist_title__contains?: InputMaybe<Scalars["String"]>;
  type_artist_title__ends_with?: InputMaybe<Scalars["String"]>;
  type_artist_title__in?: InputMaybe<Array<Scalars["String"]>>;
  type_artist_title__not?: InputMaybe<Scalars["String"]>;
  type_artist_title__not_contains?: InputMaybe<Scalars["String"]>;
  type_artist_title__not_ends_with?: InputMaybe<Scalars["String"]>;
  type_artist_title__not_in?: InputMaybe<Array<Scalars["String"]>>;
  type_artist_title__not_starts_with?: InputMaybe<Scalars["String"]>;
  type_artist_title__starts_with?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
  valid_check__contains?: InputMaybe<ValidType>;
  valid_check__in?: InputMaybe<Array<ValidType>>;
  valid_check__not?: InputMaybe<ValidType>;
  valid_check__not_contains?: InputMaybe<ValidType>;
  valid_check__not_in?: InputMaybe<Array<ValidType>>;
  value?: InputMaybe<Scalars["String"]>;
  value__contains?: InputMaybe<Scalars["String"]>;
  value__ends_with?: InputMaybe<Scalars["String"]>;
  value__in?: InputMaybe<Array<Scalars["String"]>>;
  value__not?: InputMaybe<Scalars["String"]>;
  value__not_contains?: InputMaybe<Scalars["String"]>;
  value__not_ends_with?: InputMaybe<Scalars["String"]>;
  value__not_in?: InputMaybe<Array<Scalars["String"]>>;
  value__not_starts_with?: InputMaybe<Scalars["String"]>;
  value__starts_with?: InputMaybe<Scalars["String"]>;
}

/**
 * {
 *   "name":"artist_url",
 *   "id": "id",
 *   "artist": { "fk" : "artist_id" , "pk" : "artist_id" }
 * }
 */
export class Artist_Url {
  artist: Array<Maybe<Artist>>;
  artist_id?: Maybe<Scalars["ID"]>;
  exposure_order?: Maybe<Scalars["Int"]>;
  id: Scalars["UUID"];
  type_url: Scalars["String"];
  url: Scalars["String"];
  valid_check?: Maybe<ValidType>;
}

export class Artist_UrlCreateManyWithoutArtistInput {
  create?: InputMaybe<Array<Artist_UrlCreateWithoutArtistInput>>;
}

export class Artist_UrlCreateWithoutArtistInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  type_url: Scalars["String"];
  url: Scalars["String"];
  valid_check?: InputMaybe<ValidType>;
}

export enum Artist_UrlOrderByInput {
  CreatedAtAsc = "createdAt__ASC",
  CreatedAtDesc = "createdAt__DESC",
  ExposureOrderAsc = "exposure_order__ASC",
  ExposureOrderDesc = "exposure_order__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  TypeUrlAsc = "type_url__ASC",
  TypeUrlDesc = "type_url__DESC",
  UpdatedAtAsc = "updatedAt__ASC",
  UpdatedAtDesc = "updatedAt__DESC",
  UrlAsc = "url__ASC",
  UrlDesc = "url__DESC",
  ValidCheckAsc = "valid_check__ASC",
  ValidCheckDesc = "valid_check__DESC"
}

export class Artist_UrlUpdateInput {
  artist?: InputMaybe<ArtistChildInput>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  type_url?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class Artist_UrlUpdateManyWithoutArtistInput {
  create?: InputMaybe<Array<Artist_UrlCreateWithoutArtistInput>>;
  update?: InputMaybe<Array<Artist_UrlUpdateWithWhereUniqueWithoutArtistInput>>;
}

export class Artist_UrlUpdateWithWhereUniqueWithoutArtistInput {
  data: Artist_UrlUpdateWithoutArtistDataInput;
  where: UuidWhereUniqueInput;
}

export class Artist_UrlUpdateWithoutArtistDataInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  type_url?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class Artist_UrlWhereInput {
  AND?: InputMaybe<Array<Artist_UrlWhereInput>>;
  NOT?: InputMaybe<Array<Artist_UrlWhereInput>>;
  OR?: InputMaybe<Array<Artist_UrlWhereInput>>;
  artist__lateral?: InputMaybe<Scalars["Boolean"]>;
  artist__some?: InputMaybe<ArtistWhereInput>;
  artist_id?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  artist_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  artist_id__not_include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  exposure_order__gt?: InputMaybe<Scalars["Int"]>;
  exposure_order__gte?: InputMaybe<Scalars["Int"]>;
  exposure_order__in?: InputMaybe<Array<Scalars["Int"]>>;
  exposure_order__lt?: InputMaybe<Scalars["Int"]>;
  exposure_order__lte?: InputMaybe<Scalars["Int"]>;
  exposure_order__not?: InputMaybe<Scalars["Int"]>;
  exposure_order__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__exist?: InputMaybe<Scalars["Boolean"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  type_url?: InputMaybe<Scalars["String"]>;
  type_url__contains?: InputMaybe<Scalars["String"]>;
  type_url__ends_with?: InputMaybe<Scalars["String"]>;
  type_url__in?: InputMaybe<Array<Scalars["String"]>>;
  type_url__not?: InputMaybe<Scalars["String"]>;
  type_url__not_contains?: InputMaybe<Scalars["String"]>;
  type_url__not_ends_with?: InputMaybe<Scalars["String"]>;
  type_url__not_in?: InputMaybe<Array<Scalars["String"]>>;
  type_url__not_starts_with?: InputMaybe<Scalars["String"]>;
  type_url__starts_with?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
  url__contains?: InputMaybe<Scalars["String"]>;
  url__ends_with?: InputMaybe<Scalars["String"]>;
  url__in?: InputMaybe<Array<Scalars["String"]>>;
  url__not?: InputMaybe<Scalars["String"]>;
  url__not_contains?: InputMaybe<Scalars["String"]>;
  url__not_ends_with?: InputMaybe<Scalars["String"]>;
  url__not_in?: InputMaybe<Array<Scalars["String"]>>;
  url__not_starts_with?: InputMaybe<Scalars["String"]>;
  url__starts_with?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
  valid_check__contains?: InputMaybe<ValidType>;
  valid_check__in?: InputMaybe<Array<ValidType>>;
  valid_check__not?: InputMaybe<ValidType>;
  valid_check__not_contains?: InputMaybe<ValidType>;
  valid_check__not_in?: InputMaybe<Array<ValidType>>;
}

export class Assets {
  accounts_payable?: Maybe<Scalars["Int"]>;
  cumulative_amount?: Maybe<Scalars["Int"]>;
  deposit_amount?: Maybe<Scalars["Int"]>;
  paid_amount?: Maybe<Scalars["Int"]>;
}

export class BatchPayload {
  count: Scalars["Long"];
}

export class BgmPlaylistWhereInput {
  id?: InputMaybe<Scalars["Int"]>;
  id__gt?: InputMaybe<Scalars["Int"]>;
  id__gte?: InputMaybe<Scalars["Int"]>;
  id__in?: InputMaybe<Array<Scalars["Int"]>>;
  id__lt?: InputMaybe<Scalars["Int"]>;
  id__lte?: InputMaybe<Scalars["Int"]>;
  playlist_item__some?: InputMaybe<PlaylistItemWhereInput>;
  title?: InputMaybe<Scalars["String"]>;
  title__starts_with?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<PlaylistType>;
  type__in?: InputMaybe<Array<PlaylistType>>;
  user_id?: InputMaybe<Scalars["Int"]>;
}

export class Bill {
  conditionPricePerMembership?: Maybe<Bill_Detail>;
  conditionPricePerPlayingTracks?: Maybe<Bill_Detail>;
  conditionPricePerSales?: Maybe<Bill_Detail>;
  currency?: Maybe<CurrencyType>;
  price?: Maybe<Scalars["Float"]>;
  product?: Maybe<Scalars["String"]>;
  service?: Maybe<ServiceType>;
  specificPlayedTracksEachCountPerMonth?: Maybe<Array<Maybe<TracksPerCount>>>;
  toalplayedtrackscountpermonth?: Maybe<Scalars["Int"]>;
}

export class BillWhereUniqueInput {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  created_at__between?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  user_id?: InputMaybe<Scalars["Int"]>;
}

export class Bill_Detail {
  calculation?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  totalamount?: Maybe<Scalars["Int"]>;
  type?: Maybe<Scalars["Int"]>;
}

/**
 * {
 *   "name":"broadcast_billing",
 *   "id": "id"
 * }
 */
export class Broadcast_Billing {
  artist_id: Scalars["Int"];
  billing_date: Scalars["TimeStamp"];
  currency: CurrencyType;
  id?: Maybe<Scalars["UUID"]>;
  is_free?: Maybe<Scalars["Boolean"]>;
  service: ServiceType;
  steam_id: Scalars["String"];
  total_amount: Scalars["Float"];
  total_audience: Scalars["Int"];
  total_coin: Scalars["Int"];
  total_ticket?: Maybe<Scalars["Int"]>;
}

export class Broadcast_BillingCreateInput {
  artist_id: Scalars["Int"];
  billing_date: Scalars["TimeStamp"];
  currency: CurrencyType;
  is_free?: InputMaybe<Scalars["Boolean"]>;
  service: ServiceType;
  steam_id: Scalars["String"];
  total_amount: Scalars["Float"];
  total_audience: Scalars["Int"];
  total_coin: Scalars["Int"];
  total_ticket?: InputMaybe<Scalars["Int"]>;
}

/**
 * {
 *   "name":"buckets",
 *   "id": "id"
 * }
 */
export class Buckets {
  Policy: Scalars["String"];
  X_Amz_Algorithm: Scalars["String"];
  X_Amz_Credential: Scalars["String"];
  X_Amz_Date: Scalars["String"];
  X_Amz_Signature: Scalars["String"];
  bucket: Scalars["String"];
  invalidateToken?: Maybe<Scalars["String"]>;
  key: Scalars["String"];
  url: Scalars["String"];
}

export enum CacheControlScope {
  Private = "PRIVATE",
  Public = "PUBLIC"
}

/**
 * {
 *   "name":"calling_record",
 *   "id": "id"
 * }
 */
export class Calling_Record {
  id?: Maybe<Scalars["ID"]>;
  target_table?: Maybe<Scalars["String"]>;
  timestamp?: Maybe<Scalars["TimeStamp"]>;
  uid?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
}

export class Cart {
  cart_item?: Maybe<Array<Maybe<CartItem>>>;
  created_at: Scalars["DateTime"];
  id: Scalars["Int"];
  item_total_amount: Scalars["Int"];
  item_total_count: Scalars["Int"];
  updated_at: Scalars["DateTime"];
  user?: Maybe<User>;
  user_id: Scalars["Int"];
}

export class CartCart_ItemArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
}

export class CartItem {
  cart?: Maybe<Array<Cart>>;
  cart_id: Scalars["Int"];
  created_at: Scalars["DateTime"];
  id: Scalars["Int"];
  track?: Maybe<Array<Track>>;
  track_id: Scalars["Int"];
  user_id: Scalars["Int"];
}

export class CartItemCartArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<CartOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CartWhereInput>;
}

export class CartItemTrackArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
}

export enum CartItemOrderByInput {
  CreatedAtAsc = "created_at__ASC",
  CreatedAtDesc = "created_at__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC"
}

export class CartItemWhereInput {
  cart_id?: InputMaybe<Scalars["Int"]>;
  cart_id__in?: InputMaybe<Array<Scalars["Int"]>>;
  id?: InputMaybe<Scalars["Int"]>;
  id__gt?: InputMaybe<Scalars["Int"]>;
  id__gte?: InputMaybe<Scalars["Int"]>;
  id__in?: InputMaybe<Array<Scalars["Int"]>>;
  id__lt?: InputMaybe<Scalars["Int"]>;
  id__lte?: InputMaybe<Scalars["Int"]>;
  track_id?: InputMaybe<Scalars["Int"]>;
  track_id__in?: InputMaybe<Array<Scalars["Int"]>>;
  user_id?: InputMaybe<Scalars["Int"]>;
  user_id__in?: InputMaybe<Array<Scalars["Int"]>>;
}

export enum CartOrderByInput {
  CreatedAtAsc = "created_at__ASC",
  CreatedAtDesc = "created_at__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC"
}

export class CartWhereInput {
  id?: InputMaybe<Scalars["Int"]>;
  id__in?: InputMaybe<Array<Scalars["Int"]>>;
  user_id?: InputMaybe<Scalars["Int"]>;
}

export enum CategoryOfGoods {
  Collection = "Collection",
  Concert = "Concert",
  Fashion = "Fashion",
  Package = "Package",
  Stationery = "Stationery"
}

/**
 * {
 *   "name":"channel_donate_billing",
 *   "id": "id"
 * }
 */
export class Channel_Donate_Billing {
  artist_id: Scalars["Int"];
  billing_date: Scalars["TimeStamp"];
  currency: CurrencyType;
  id?: Maybe<Scalars["UUID"]>;
  service: ServiceType;
  total_amount: Scalars["Float"];
  total_coin: Scalars["Int"];
}

export class Channel_Donate_BillingCreateInput {
  artist_id: Scalars["Int"];
  billing_date: Scalars["TimeStamp"];
  currency: CurrencyType;
  service: ServiceType;
  total_amount: Scalars["Float"];
  total_coin: Scalars["Int"];
}

/**
 * {
 *   "name":"comment",
 *   "id": "id",
 *   "users": { "fk" : "users_id" , "pk" : "users_id" }
 * }
 */
export class Comment {
  depth: Scalars["Int"];
  id: Scalars["UUID"];
  inner_order: Scalars["Int"];
  parent_id: Scalars["UUID"];
  root_id: Scalars["UUID"];
  target_id: Scalars["ID"];
  target_table: TargetTableInPut;
  timestamp?: Maybe<Scalars["DateTime"]>;
  users: Array<Maybe<Users>>;
  valid_check?: Maybe<ValidType>;
  value: Scalars["String"];
}

export class CommentCreateInput {
  parent_id?: InputMaybe<Scalars["UUID"]>;
  target_id: Scalars["ID"];
  target_table: TargetTableInPut;
  value: Scalars["String"];
}

export enum CommentOrderByInput {
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  TimestampAsc = "timestamp__ASC",
  TimestampDesc = "timestamp__DESC",
  UsersIdAsc = "users_id_ASC",
  UsersIdDesc = "users_id_DESC"
}

export class CommentUniqueWhereInput {
  id?: InputMaybe<Scalars["UUID"]>;
}

export class CommentUpdateInput {
  valid_check?: InputMaybe<ValidType>;
  value: Scalars["String"];
}

export class CommentWhereInput {
  depth?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  id__not?: InputMaybe<Scalars["UUID"]>;
  id__not_in?: InputMaybe<Array<Scalars["UUID"]>>;
  inner_order?: InputMaybe<Scalars["Int"]>;
  parent_id?: InputMaybe<Scalars["UUID"]>;
  root_id?: InputMaybe<Scalars["UUID"]>;
  target_id?: InputMaybe<Scalars["ID"]>;
  target_table?: InputMaybe<TargetTableInPut>;
  timestamp?: InputMaybe<Scalars["DateTime"]>;
  timestamp__gt?: InputMaybe<Scalars["DateTime"]>;
  timestamp__gte?: InputMaybe<Scalars["DateTime"]>;
  timestamp__in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  timestamp__lt?: InputMaybe<Scalars["DateTime"]>;
  timestamp__lte?: InputMaybe<Scalars["DateTime"]>;
  valid_check?: InputMaybe<ValidType>;
  valid_check__in?: InputMaybe<Array<InputMaybe<ValidType>>>;
  valid_check__not?: InputMaybe<ValidType>;
  valid_check__not_in?: InputMaybe<Array<InputMaybe<ValidType>>>;
}

export class Commodity {
  barcode: Scalars["String"];
  commodity_id: Scalars["Int"];
  commodity_url?: Maybe<Array<Commodity_Url>>;
  count?: Maybe<Aggregate>;
  created_at: Scalars["DateTime"];
  currency?: Maybe<Scalars["String"]>;
  display_price?: Maybe<Scalars["Int"]>;
  exchange_price?: Maybe<Scalars["Float"]>;
  goods: Array<Maybe<Goods>>;
  goods_id: Scalars["ID"];
  offer_id?: Maybe<Scalars["UUID"]>;
  option?: Maybe<Goods_Subdata_Unique>;
  option_id?: Maybe<Scalars["UUID"]>;
  price?: Maybe<Scalars["Int"]>;
  price__max?: Maybe<Scalars["Int"]>;
  price__min?: Maybe<Scalars["Int"]>;
  quality?: Maybe<QualityOfCommodity>;
  status?: Maybe<StatusOfCommodity>;
  user_id?: Maybe<Scalars["Int"]>;
  user_name?: Maybe<Scalars["String"]>;
  value: Scalars["String"];
}

export class CommodityCommodity_UrlArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<Commodity_UrlOrderByInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Commodity_UrlWhereInput>;
}

export class CommodityGoodsArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<GoodsOrderByInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<GoodsWhereInput>;
}

export class CommodityChildInput {
  connect?: InputMaybe<CommodityWhereUniqueInput>;
}

export class CommodityCreateInput {
  barcode?: InputMaybe<Scalars["String"]>;
  commodity_url?: InputMaybe<Commodity_UrlCreateManyWithoutCommodityInput>;
  created_at: Scalars["DateTime"];
  display_price?: InputMaybe<Scalars["Int"]>;
  goods: GoodsChildInput;
  offer_id?: InputMaybe<Scalars["UUID"]>;
  option_id?: InputMaybe<Scalars["UUID"]>;
  price?: InputMaybe<Scalars["Int"]>;
  quality?: InputMaybe<QualityOfCommodity>;
  status?: InputMaybe<StatusOfCommodity>;
  user_id?: InputMaybe<Scalars["Int"]>;
  user_name?: InputMaybe<Scalars["String"]>;
  value: Scalars["String"];
}

export enum CommodityOrderByInput {
  BarcodeAsc = "barcode__ASC",
  BarcodeDesc = "barcode__DESC",
  CommodityIdAsc = "commodity_id__ASC",
  CommodityIdDesc = "commodity_id__DESC",
  CreatedAtAsc = "created_at__ASC",
  CreatedAtDesc = "created_at__DESC",
  GoodsIdAsc = "goods_id__ASC",
  GoodsIdDesc = "goods_id__DESC",
  PriceAsc = "price__ASC",
  PriceDesc = "price__DESC",
  QualityAsc = "quality__ASC",
  QualityDesc = "quality__DESC",
  ValueAsc = "value__ASC",
  ValueDesc = "value__DESC"
}

export class CommodityUpdateInput {
  _onlySearch?: InputMaybe<Scalars["Boolean"]>;
  barcode?: InputMaybe<Scalars["String"]>;
  commodity_url?: InputMaybe<Commodity_UrlUpdateManyInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  display_price?: InputMaybe<Scalars["Int"]>;
  goods?: InputMaybe<GoodsChildInput>;
  offer_id?: InputMaybe<Scalars["UUID"]>;
  option_id?: InputMaybe<Scalars["UUID"]>;
  price?: InputMaybe<Scalars["Int"]>;
  quality?: InputMaybe<QualityOfCommodity>;
  status?: InputMaybe<StatusOfCommodity>;
  user_id?: InputMaybe<Scalars["Int"]>;
  user_name?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
}

export class CommodityUpsertWhereUniqueInput {
  commodity_id?: InputMaybe<Scalars["ID"]>;
  display_price?: InputMaybe<Scalars["Int"]>;
  goods_id: Scalars["Int"];
  option_id?: InputMaybe<Scalars["UUID"]>;
  price: Scalars["Int"];
  quality: QualityOfCommodity;
  quantity?: InputMaybe<Scalars["Int"]>;
}

export class CommodityWhereInput {
  AND?: InputMaybe<Array<CommodityWhereInput>>;
  NOT?: InputMaybe<Array<CommodityWhereInput>>;
  OR?: InputMaybe<Array<CommodityWhereInput>>;
  barcode?: InputMaybe<Scalars["String"]>;
  barcode__in?: InputMaybe<Array<Scalars["String"]>>;
  barcode__not?: InputMaybe<Scalars["String"]>;
  barcode__not_in?: InputMaybe<Array<Scalars["String"]>>;
  commodity_id?: InputMaybe<Scalars["ID"]>;
  commodity_id__gt?: InputMaybe<Scalars["ID"]>;
  commodity_id__gte?: InputMaybe<Scalars["ID"]>;
  commodity_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  commodity_id__lt?: InputMaybe<Scalars["ID"]>;
  commodity_id__lte?: InputMaybe<Scalars["ID"]>;
  commodity_id__not_in?: InputMaybe<Array<Scalars["ID"]>>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  created_at__between?: InputMaybe<Array<Scalars["DateTime"]>>;
  created_at__gt?: InputMaybe<Scalars["DateTime"]>;
  created_at__gte?: InputMaybe<Scalars["DateTime"]>;
  created_at__lt?: InputMaybe<Scalars["DateTime"]>;
  created_at__lte?: InputMaybe<Scalars["DateTime"]>;
  goods__lateral?: InputMaybe<Scalars["Boolean"]>;
  goods__some?: InputMaybe<GoodsWhereInput>;
  goods_id?: InputMaybe<Scalars["ID"]>;
  goods_id__gt?: InputMaybe<Scalars["ID"]>;
  goods_id__gte?: InputMaybe<Scalars["ID"]>;
  goods_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  goods_id__lt?: InputMaybe<Scalars["ID"]>;
  goods_id__lte?: InputMaybe<Scalars["ID"]>;
  goods_id__not_in?: InputMaybe<Array<Scalars["ID"]>>;
  goods_subdata_unique__lateral?: InputMaybe<Scalars["Boolean"]>;
  goods_subdata_unique__some?: InputMaybe<Goods_Subdata_UniqueWhereInput>;
  goods_url__lateral?: InputMaybe<Scalars["Boolean"]>;
  goods_url__some?: InputMaybe<Goods_UrlWhereInput>;
  offer_id?: InputMaybe<Scalars["UUID"]>;
  offer_id__in?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  option_id?: InputMaybe<Scalars["UUID"]>;
  option_id__in?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  price?: InputMaybe<Scalars["Int"]>;
  price__gt?: InputMaybe<Scalars["Int"]>;
  price__gte?: InputMaybe<Scalars["Int"]>;
  price__in?: InputMaybe<Array<Scalars["Int"]>>;
  price__lt?: InputMaybe<Scalars["Int"]>;
  price__lte?: InputMaybe<Scalars["Int"]>;
  price__not?: InputMaybe<Scalars["Int"]>;
  price__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  quality?: InputMaybe<QualityOfCommodity>;
  quality__in?: InputMaybe<Array<QualityOfCommodity>>;
  quality__not?: InputMaybe<QualityOfCommodity>;
  quality__not_in?: InputMaybe<Array<QualityOfCommodity>>;
  status?: InputMaybe<StatusOfCommodity>;
  status__in?: InputMaybe<Array<InputMaybe<StatusOfCommodity>>>;
  status__not?: InputMaybe<StatusOfCommodity>;
  status__not_in?: InputMaybe<Array<InputMaybe<StatusOfCommodity>>>;
  user_id?: InputMaybe<Scalars["Int"]>;
  value?: InputMaybe<Scalars["String"]>;
}

export class CommodityWhereUniqueInput {
  commodity_id?: InputMaybe<Scalars["ID"]>;
  user_id?: InputMaybe<Scalars["Int"]>;
}

/**
 * {
 *   "name":"commodity_url",
 *   "id": "commodity_id"
 * }
 */
export class Commodity_Url {
  commodity: Array<Maybe<Commodity>>;
  commodity_id?: Maybe<Scalars["ID"]>;
  exposure_order: Scalars["Int"];
  id: Scalars["UUID"];
  type_url: Scalars["String"];
  url: Scalars["String"];
}

export class Commodity_UrlCreateManyWithoutCommodityInput {
  create?: InputMaybe<Array<Commodity_UrlCreateWithoutCommodityInput>>;
}

export class Commodity_UrlCreateWithoutCommodityInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  type_url: Scalars["String"];
  url: Scalars["String"];
}

export enum Commodity_UrlOrderByInput {
  CommodityIdAsc = "commodity_id__ASC",
  CommodityIdDesc = "commodity_id__DESC",
  ExposureOrderAsc = "exposure_order__ASC",
  ExposureOrderDesc = "exposure_order__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  TypeUrlAsc = "type_url__ASC",
  TypeUrlDesc = "type_url__DESC",
  UrlAsc = "url__ASC",
  UrlDesc = "url__DESC"
}

export class Commodity_UrlUpdateInput {
  commodity?: InputMaybe<CommodityChildInput>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  type_url?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
}

export class Commodity_UrlUpdateManyInput {
  create?: InputMaybe<Array<Commodity_UrlCreateWithoutCommodityInput>>;
  update?: InputMaybe<Array<Commodity_UrlUpdateWithWhereUniqueWithoutCommodityInput>>;
}

export class Commodity_UrlUpdateWithWhereUniqueWithoutCommodityInput {
  data: Commodity_UrlUpdateWithoutArtistDataInput;
  where: UuidWhereUniqueInput;
}

export class Commodity_UrlUpdateWithoutArtistDataInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  type_url?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
}

export class Commodity_UrlWhereInput {
  AND?: InputMaybe<Array<Commodity_UrlWhereInput>>;
  NOT?: InputMaybe<Array<Commodity_UrlWhereInput>>;
  OR?: InputMaybe<Array<Commodity_UrlWhereInput>>;
  commodity__lateral?: InputMaybe<Scalars["Boolean"]>;
  commodity__some?: InputMaybe<CommodityWhereInput>;
  commodity_id__not_include?: InputMaybe<Array<Scalars["ID"]>>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  exposure_order__gt?: InputMaybe<Scalars["Int"]>;
  exposure_order__gte?: InputMaybe<Scalars["Int"]>;
  exposure_order__in?: InputMaybe<Array<Scalars["Int"]>>;
  exposure_order__lt?: InputMaybe<Scalars["Int"]>;
  exposure_order__lte?: InputMaybe<Scalars["Int"]>;
  exposure_order__not?: InputMaybe<Scalars["Int"]>;
  exposure_order__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__exist?: InputMaybe<Scalars["Boolean"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  type_url?: InputMaybe<Scalars["String"]>;
  type_url__contains?: InputMaybe<Scalars["String"]>;
  type_url__ends_with?: InputMaybe<Scalars["String"]>;
  type_url__in?: InputMaybe<Array<Scalars["String"]>>;
  type_url__not?: InputMaybe<Scalars["String"]>;
  type_url__not_contains?: InputMaybe<Scalars["String"]>;
  type_url__not_ends_with?: InputMaybe<Scalars["String"]>;
  type_url__not_in?: InputMaybe<Array<Scalars["String"]>>;
  type_url__not_starts_with?: InputMaybe<Scalars["String"]>;
  type_url__starts_with?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
  url__contains?: InputMaybe<Scalars["String"]>;
  url__ends_with?: InputMaybe<Scalars["String"]>;
  url__in?: InputMaybe<Array<Scalars["String"]>>;
  url__not?: InputMaybe<Scalars["String"]>;
  url__not_contains?: InputMaybe<Scalars["String"]>;
  url__not_ends_with?: InputMaybe<Scalars["String"]>;
  url__not_in?: InputMaybe<Array<Scalars["String"]>>;
  url__not_starts_with?: InputMaybe<Scalars["String"]>;
  url__starts_with?: InputMaybe<Scalars["String"]>;
}

/**
 * {
 *   "name":"company",
 *   "id": "company_id" ,
 *   "type" : "Int"
 * }
 */
export class Company {
  comapny_url: Array<Maybe<Company_Url>>;
  company_id: Scalars["ID"];
  company_title?: Maybe<Array<Company_Title>>;
  company_url: Array<Maybe<Company_Url>>;
  company_users_relation: Array<Maybe<Company_Users_Relation>>;
  display_name?: Maybe<Scalars["String"]>;
  group_path?: Maybe<Scalars["String"]>;
  license?: Maybe<Scalars["String"]>;
  metadata_company_relation?: Maybe<Array<Metadata_Company_Relation>>;
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  valid_check?: Maybe<ValidType>;
  weight?: Maybe<Scalars["Int"]>;
}

/**
 * {
 *   "name":"company",
 *   "id": "company_id" ,
 *   "type" : "Int"
 * }
 */
export class CompanyComapny_UrlArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Company_UrlOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Company_UrlWhereInput>;
}

/**
 * {
 *   "name":"company",
 *   "id": "company_id" ,
 *   "type" : "Int"
 * }
 */
export class CompanyCompany_TitleArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Company_TitleOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Company_TitleWhereInput>;
}

/**
 * {
 *   "name":"company",
 *   "id": "company_id" ,
 *   "type" : "Int"
 * }
 */
export class CompanyCompany_UrlArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Company_UrlOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Company_UrlWhereInput>;
}

/**
 * {
 *   "name":"company",
 *   "id": "company_id" ,
 *   "type" : "Int"
 * }
 */
export class CompanyCompany_Users_RelationArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Company_Users_RelationOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Company_Users_RelationWhereInput>;
}

/**
 * {
 *   "name":"company",
 *   "id": "company_id" ,
 *   "type" : "Int"
 * }
 */
export class CompanyMetadata_Company_RelationArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Metadata_Company_RelationOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Metadata_Company_RelationWhereInput>;
}

export class CompanyChildInput {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
}

export class CompanyCreateInput {
  company_title?: InputMaybe<Company_TitleCreateManyWithoutCompanyInput>;
  company_url?: InputMaybe<Company_UrlCreateManyInput>;
  company_users_relation?: InputMaybe<Company_Users_RelationCreateManyInput>;
  display_name?: InputMaybe<Scalars["String"]>;
  group_path?: InputMaybe<Scalars["String"]>;
  license?: InputMaybe<Scalars["String"]>;
  metadata_company_relation?: InputMaybe<Metadata_Company_RelationCreateManyWithoutCompanyInput>;
  name?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
  weight?: InputMaybe<Scalars["Int"]>;
}

export enum CompanyOrderByInput {
  CompanyIdAsc = "company_id__ASC",
  CompanyIdDesc = "company_id__DESC",
  CreatedAtAsc = "createdAt__ASC",
  CreatedAtDesc = "createdAt__DESC",
  DisplayNameAsc = "display_name__ASC",
  DisplayNameDesc = "display_name__DESC",
  NameAsc = "name__ASC",
  NameDesc = "name__DESC",
  UpdatedAtAsc = "updatedAt__ASC",
  UpdatedAtDesc = "updatedAt__DESC",
  ValidCheckAsc = "valid_check__ASC",
  ValidCheckDesc = "valid_check__DESC"
}

export class CompanyUpdateInput {
  _onlySearch?: InputMaybe<Scalars["Boolean"]>;
  company_title?: InputMaybe<Company_TitleUpdateManyWithoutCompanyInput>;
  company_url?: InputMaybe<Company_UrlUpdateManyInput>;
  company_users_relation?: InputMaybe<Company_Users_RelationUpdateManyInput>;
  display_name?: InputMaybe<Scalars["String"]>;
  metadata_company_relation?: InputMaybe<Metadata_Company_RelationUpdateManyWithoutCompanyInput>;
  name?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class CompanyUpdateManyMutationInput {
  display_name?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class CompanyWhereInput {
  AND?: InputMaybe<Array<CompanyWhereInput>>;
  NOT?: InputMaybe<Array<CompanyWhereInput>>;
  OR?: InputMaybe<Array<CompanyWhereInput>>;
  company_id?: InputMaybe<Scalars["ID"]>;
  company_id__gt?: InputMaybe<Scalars["ID"]>;
  company_id__gte?: InputMaybe<Scalars["ID"]>;
  company_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  company_id__lt?: InputMaybe<Scalars["ID"]>;
  company_id__lte?: InputMaybe<Scalars["ID"]>;
  company_id__not_in?: InputMaybe<Array<Scalars["ID"]>>;
  company_title__lateral?: InputMaybe<Scalars["Boolean"]>;
  company_title__some?: InputMaybe<Company_TitleWhereInput>;
  company_url__lateral?: InputMaybe<Scalars["Boolean"]>;
  company_url__some?: InputMaybe<Company_UrlWhereInput>;
  company_users_relation__some?: InputMaybe<Company_Users_RelationWhereInput>;
  display_name?: InputMaybe<Scalars["String"]>;
  display_name__contains?: InputMaybe<Scalars["String"]>;
  display_name__ends_with?: InputMaybe<Scalars["String"]>;
  display_name__in?: InputMaybe<Array<Scalars["String"]>>;
  display_name__not?: InputMaybe<Scalars["String"]>;
  display_name__not_contains?: InputMaybe<Scalars["String"]>;
  display_name__not_ends_with?: InputMaybe<Scalars["String"]>;
  display_name__not_in?: InputMaybe<Array<Scalars["String"]>>;
  display_name__not_starts_with?: InputMaybe<Scalars["String"]>;
  display_name__starts_with?: InputMaybe<Scalars["String"]>;
  metadata_company_relation__lateral?: InputMaybe<Scalars["Boolean"]>;
  metadata_company_relation__some?: InputMaybe<Metadata_Company_RelationWhereInput>;
  name?: InputMaybe<Scalars["String"]>;
  name__contains?: InputMaybe<Scalars["String"]>;
  name__ends_with?: InputMaybe<Scalars["String"]>;
  name__in?: InputMaybe<Array<Scalars["String"]>>;
  name__not?: InputMaybe<Scalars["String"]>;
  name__not_contains?: InputMaybe<Scalars["String"]>;
  name__not_ends_with?: InputMaybe<Scalars["String"]>;
  name__not_in?: InputMaybe<Array<Scalars["String"]>>;
  name__not_starts_with?: InputMaybe<Scalars["String"]>;
  name__starts_with?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  type__contains?: InputMaybe<Scalars["String"]>;
  type__ends_with?: InputMaybe<Scalars["String"]>;
  type__in?: InputMaybe<Array<Scalars["String"]>>;
  type__not?: InputMaybe<Scalars["String"]>;
  type__not_contains?: InputMaybe<Scalars["String"]>;
  type__not_ends_with?: InputMaybe<Scalars["String"]>;
  type__not_in?: InputMaybe<Array<Scalars["String"]>>;
  type__not_starts_with?: InputMaybe<Scalars["String"]>;
  type__starts_with?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
  valid_check__contains?: InputMaybe<ValidType>;
  valid_check__in?: InputMaybe<Array<ValidType>>;
  valid_check__not?: InputMaybe<ValidType>;
  valid_check__not_contains?: InputMaybe<ValidType>;
  valid_check__not_in?: InputMaybe<Array<ValidType>>;
  weight?: InputMaybe<Scalars["Int"]>;
}

export class CompanyWhereUniqueInput {
  company_id?: InputMaybe<Scalars["ID"]>;
}

/**
 * {
 *   "name":"company_self_relation",
 *   "id": "id",
 *   "company": { "fk" : "parent_id" , "pk" : "company_id" },
 *   "company_self_relation_child": {"id":"child_id", "fk" : "company_id" , "pk" : "child_id", "table":"company"},
 *   "company_self_relation_parent": {"id":"parent_id", "fk" : "company_id" , "pk" : "parent_id", "table":"company"}
 * }
 */
export class Company_Self_Relation {
  company_self_relation_child: Array<Maybe<Company>>;
  company_self_relation_parent: Array<Maybe<Company>>;
  id: Scalars["UUID"];
  type?: Maybe<Scalars["String"]>;
}

/**
 * {
 *   "name":"company_title",
 *   "id": "id",
 *   "company": { "fk" : "company_id" , "pk" : "company_id" },
 *   "language": { "fk" : "language_code" , "pk" : "language_code" }
 * }
 */
export class Company_Title {
  company?: Maybe<Array<Company>>;
  company_id?: Maybe<Scalars["ID"]>;
  exposure_order: Scalars["Int"];
  id: Scalars["UUID"];
  language?: Maybe<Array<Maybe<Language>>>;
  language_code?: Maybe<Scalars["ID"]>;
  tsvec_words?: Maybe<Scalars["String"]>;
  type_company_title: Scalars["String"];
  valid_check?: Maybe<ValidType>;
  value: Scalars["String"];
}

/**
 * {
 *   "name":"company_title",
 *   "id": "id",
 *   "company": { "fk" : "company_id" , "pk" : "company_id" },
 *   "language": { "fk" : "language_code" , "pk" : "language_code" }
 * }
 */
export class Company_TitleCompanyArgs {
  where?: InputMaybe<CompanyWhereInput>;
}

/**
 * {
 *   "name":"company_title",
 *   "id": "id",
 *   "company": { "fk" : "company_id" , "pk" : "company_id" },
 *   "language": { "fk" : "language_code" , "pk" : "language_code" }
 * }
 */
export class Company_TitleLanguageArgs {
  where?: InputMaybe<LanguageWhereInput>;
}

export class Company_TitleCreateManyWithoutCompanyInput {
  create?: InputMaybe<Array<Company_TitleCreateWithoutCompanyInput>>;
}

export class Company_TitleCreateWithoutCompanyInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  language: LanguageChildInput;
  type_company_title: Scalars["String"];
  valid_check?: InputMaybe<ValidType>;
  value: Scalars["String"];
}

export enum Company_TitleOrderByInput {
  CreatedAtAsc = "createdAt__ASC",
  CreatedAtDesc = "createdAt__DESC",
  ExposureOrderAsc = "exposure_order__ASC",
  ExposureOrderDesc = "exposure_order__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  TypeCompanyTitleAsc = "type_company_title__ASC",
  TypeCompanyTitleDesc = "type_company_title__DESC",
  UpdatedAtAsc = "updatedAt__ASC",
  UpdatedAtDesc = "updatedAt__DESC",
  ValidCheckAsc = "valid_check__ASC",
  ValidCheckDesc = "valid_check__DESC",
  ValueAsc = "value__ASC",
  ValueDesc = "value__DESC",
  ValueSimilarity = "value__SIMILARITY"
}

export class Company_TitleUpdateInput {
  company?: InputMaybe<CompanyChildInput>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  language?: InputMaybe<LanguageChildInput>;
  type_company_title?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
  value?: InputMaybe<Scalars["String"]>;
}

export class Company_TitleUpdateManyWithoutCompanyInput {
  create?: InputMaybe<Array<Company_TitleCreateWithoutCompanyInput>>;
  update?: InputMaybe<Array<Company_TitleUpdateWithWhereUniqueWithoutCompanyInput>>;
}

export class Company_TitleUpdateWithWhereUniqueWithoutCompanyInput {
  data: Company_TitleUpdateWithoutCompanyDataInput;
  where: UuidWhereUniqueInput;
}

export class Company_TitleUpdateWithoutCompanyDataInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  language?: InputMaybe<LanguageChildInput>;
  type_company_title?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
  value?: InputMaybe<Scalars["String"]>;
}

export class Company_TitleWhereInput {
  AND?: InputMaybe<Array<Company_TitleWhereInput>>;
  NOT?: InputMaybe<Array<Company_TitleWhereInput>>;
  OR?: InputMaybe<Array<Company_TitleWhereInput>>;
  company__lateral?: InputMaybe<Scalars["Boolean"]>;
  company__some?: InputMaybe<CompanyWhereInput>;
  company_id?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  company_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  company_id__not_include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  exposure_order__gt?: InputMaybe<Scalars["Int"]>;
  exposure_order__gte?: InputMaybe<Scalars["Int"]>;
  exposure_order__in?: InputMaybe<Array<Scalars["Int"]>>;
  exposure_order__lt?: InputMaybe<Scalars["Int"]>;
  exposure_order__lte?: InputMaybe<Scalars["Int"]>;
  exposure_order__not?: InputMaybe<Scalars["Int"]>;
  exposure_order__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  language__lateral?: InputMaybe<Scalars["Boolean"]>;
  language__some?: InputMaybe<LanguageWhereInput>;
  language_code?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  language_code__in?: InputMaybe<Array<Scalars["ID"]>>;
  language_code__not_include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  tsvec_words__search?: InputMaybe<Scalars["String"]>;
  type_company_title?: InputMaybe<Scalars["String"]>;
  type_company_title__contains?: InputMaybe<Scalars["String"]>;
  type_company_title__ends_with?: InputMaybe<Scalars["String"]>;
  type_company_title__in?: InputMaybe<Array<Scalars["String"]>>;
  type_company_title__not?: InputMaybe<Scalars["String"]>;
  type_company_title__not_contains?: InputMaybe<Scalars["String"]>;
  type_company_title__not_ends_with?: InputMaybe<Scalars["String"]>;
  type_company_title__not_in?: InputMaybe<Array<Scalars["String"]>>;
  type_company_title__not_starts_with?: InputMaybe<Scalars["String"]>;
  type_company_title__starts_with?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
  valid_check__contains?: InputMaybe<ValidType>;
  valid_check__in?: InputMaybe<Array<ValidType>>;
  valid_check__not?: InputMaybe<ValidType>;
  valid_check__not_contains?: InputMaybe<ValidType>;
  valid_check__not_in?: InputMaybe<Array<ValidType>>;
  value?: InputMaybe<Scalars["String"]>;
  value__contains?: InputMaybe<Scalars["String"]>;
  value__ends_with?: InputMaybe<Scalars["String"]>;
  value__in?: InputMaybe<Array<Scalars["String"]>>;
  value__not?: InputMaybe<Scalars["String"]>;
  value__not_contains?: InputMaybe<Scalars["String"]>;
  value__not_ends_with?: InputMaybe<Scalars["String"]>;
  value__not_in?: InputMaybe<Array<Scalars["String"]>>;
  value__not_starts_with?: InputMaybe<Scalars["String"]>;
  value__starts_with?: InputMaybe<Scalars["String"]>;
}

/**
 * {
 *   "name":"company_url",
 *   "id": "id",
 *   "company": { "fk" : "company_id" , "pk" : "company_id" }
 * }
 */
export class Company_Url {
  company?: Maybe<Array<Company>>;
  company_id?: Maybe<Scalars["ID"]>;
  exposure_order?: Maybe<Scalars["Int"]>;
  genre_id?: Maybe<Scalars["ID"]>;
  id: Scalars["UUID"];
  type_url: Scalars["String"];
  url: Scalars["String"];
  valid_check?: Maybe<ValidType>;
}

/**
 * {
 *   "name":"company_url",
 *   "id": "id",
 *   "company": { "fk" : "company_id" , "pk" : "company_id" }
 * }
 */
export class Company_UrlCompanyArgs {
  where?: InputMaybe<CompanyWhereInput>;
}

export class Company_UrlCreateInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  type_url: Scalars["String"];
  url: Scalars["String"];
  valid_check?: InputMaybe<ValidType>;
}

export class Company_UrlCreateManyInput {
  create?: InputMaybe<Array<Company_UrlCreateInput>>;
}

export enum Company_UrlOrderByInput {
  CreatedAtAsc = "createdAt__ASC",
  CreatedAtDesc = "createdAt__DESC",
  ExposureOrderAsc = "exposure_order__ASC",
  ExposureOrderDesc = "exposure_order__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  TypeUrlAsc = "type_url__ASC",
  TypeUrlDesc = "type_url__DESC",
  UpdatedAtAsc = "updatedAt__ASC",
  UpdatedAtDesc = "updatedAt__DESC",
  UrlAsc = "url__ASC",
  UrlDesc = "url__DESC",
  ValidCheckAsc = "valid_check__ASC",
  ValidCheckDesc = "valid_check__DESC"
}

export class Company_UrlUpdateInput {
  company?: InputMaybe<CompanyChildInput>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  type_url?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class Company_UrlUpdateManyInput {
  create?: InputMaybe<Array<Company_UrlCreateInput>>;
  update?: InputMaybe<Array<Company_UrlUpdateWithWhereUniqueInput>>;
}

export class Company_UrlUpdateWithWhereUniqueInput {
  data: Company_UrlUpdateInput;
  where: UuidWhereUniqueInput;
}

export class Company_UrlWhereInput {
  AND?: InputMaybe<Array<Company_UrlWhereInput>>;
  NOT?: InputMaybe<Array<Company_UrlWhereInput>>;
  OR?: InputMaybe<Array<Company_UrlWhereInput>>;
  company__lateral?: InputMaybe<Scalars["Boolean"]>;
  company__some?: InputMaybe<CompanyWhereInput>;
  company_id?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  company_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  company_id__not_include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  exposure_order__gt?: InputMaybe<Scalars["Int"]>;
  exposure_order__gte?: InputMaybe<Scalars["Int"]>;
  exposure_order__in?: InputMaybe<Array<Scalars["Int"]>>;
  exposure_order__lt?: InputMaybe<Scalars["Int"]>;
  exposure_order__lte?: InputMaybe<Scalars["Int"]>;
  exposure_order__not?: InputMaybe<Scalars["Int"]>;
  exposure_order__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  type_url?: InputMaybe<Scalars["String"]>;
  type_url__contains?: InputMaybe<Scalars["String"]>;
  type_url__ends_with?: InputMaybe<Scalars["String"]>;
  type_url__in?: InputMaybe<Array<Scalars["String"]>>;
  type_url__not?: InputMaybe<Scalars["String"]>;
  type_url__not_contains?: InputMaybe<Scalars["String"]>;
  type_url__not_ends_with?: InputMaybe<Scalars["String"]>;
  type_url__not_in?: InputMaybe<Array<Scalars["String"]>>;
  type_url__not_starts_with?: InputMaybe<Scalars["String"]>;
  type_url__starts_with?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
  url__contains?: InputMaybe<Scalars["String"]>;
  url__ends_with?: InputMaybe<Scalars["String"]>;
  url__in?: InputMaybe<Array<Scalars["String"]>>;
  url__not?: InputMaybe<Scalars["String"]>;
  url__not_contains?: InputMaybe<Scalars["String"]>;
  url__not_ends_with?: InputMaybe<Scalars["String"]>;
  url__not_in?: InputMaybe<Array<Scalars["String"]>>;
  url__not_starts_with?: InputMaybe<Scalars["String"]>;
  url__starts_with?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
  valid_check__contains?: InputMaybe<ValidType>;
  valid_check__in?: InputMaybe<Array<ValidType>>;
  valid_check__not?: InputMaybe<ValidType>;
  valid_check__not_contains?: InputMaybe<ValidType>;
  valid_check__not_in?: InputMaybe<Array<ValidType>>;
}

/**
 * {
 *   "name":"company_users_relation",
 *   "id": "id",
 *   "company": { "fk" : "company_id" , "pk" : "company_id" },
 *   "users": { "fk" : "users_id" , "pk" : "users_id" }
 * }
 */
export class Company_Users_Relation {
  company: Array<Maybe<Company>>;
  company_id?: Maybe<Scalars["ID"]>;
  id: Scalars["UUID"];
  is_owner: Scalars["Int"];
  users: Array<Maybe<Users>>;
  users_id?: Maybe<Scalars["ID"]>;
}

export class Company_Users_RelationCreateInput {
  company: CompanyChildInput;
  is_owner: Scalars["Int"];
  users_id: Scalars["Int"];
}

export class Company_Users_RelationCreateManyInput {
  create?: InputMaybe<Array<Company_Users_RelationCreateInput>>;
}

export enum Company_Users_RelationOrderByInput {
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  IsOwnerAsc = "is_owner__ASC",
  IsOwnerDesc = "is_owner__DESC",
  UsersIdAsc = "users_id__ASC",
  UsersIdDesc = "users_id__DESC"
}

export class Company_Users_RelationUpdateInput {
  company?: InputMaybe<CompanyChildInput>;
  is_owner?: InputMaybe<Scalars["Int"]>;
  users_id?: InputMaybe<Scalars["Int"]>;
}

export class Company_Users_RelationUpdateManyInput {
  create?: InputMaybe<Array<Company_Users_RelationCreateInput>>;
  update?: InputMaybe<Array<Company_Users_RelationUpdateWithWhereUniqueInput>>;
}

export class Company_Users_RelationUpdateWithWhereUniqueInput {
  data: Company_Users_RelationUpdateInput;
  where: UuidWhereUniqueInput;
}

export class Company_Users_RelationWhereInput {
  AND?: InputMaybe<Array<Company_Users_RelationWhereInput>>;
  NOT?: InputMaybe<Array<Company_Users_RelationWhereInput>>;
  OR?: InputMaybe<Array<Company_Users_RelationWhereInput>>;
  company__lateral?: InputMaybe<Scalars["Boolean"]>;
  company__some?: InputMaybe<CompanyWhereInput>;
  company_id?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  company_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  company_id__not_include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__contains?: InputMaybe<Scalars["UUID"]>;
  id__exist?: InputMaybe<Scalars["Boolean"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  id__not?: InputMaybe<Scalars["UUID"]>;
  id__not_contains?: InputMaybe<Scalars["UUID"]>;
  id__not_in?: InputMaybe<Array<Scalars["UUID"]>>;
  is_owner?: InputMaybe<Scalars["Int"]>;
  users__some?: InputMaybe<UsersWhereInput>;
  users_id?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  users_id__in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  users_id__not_include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
}

/**
 * {
 *   "name":"contract_bill",
 *   "function":"get_play_total_count",
 *   "id": "id",
 *   "users": { "fk" : "users_id" , "pk" : "user_id" }
 * }
 */
export class Contract_Bill {
  bill?: Maybe<Scalars["Json"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["ID"]>;
  remarks?: Maybe<Scalars["String"]>;
  total_amount?: Maybe<Scalars["Int"]>;
  users: Array<Maybe<Users>>;
}

export enum Contract_BillOrderByInput {
  CreatedAtAsc = "created_at__ASC",
  CreatedAtDesc = "created_at__DESC"
}

export class Country {
  artist_histories?: Maybe<Array<Artist_History>>;
  calling_code?: Maybe<Scalars["String"]>;
  country_code: Scalars["ID"];
  country_code_alpha_3: Scalars["String"];
  country_code_numeric?: Maybe<Scalars["Int"]>;
  is_eu: Scalars["Int"];
  language_code?: Maybe<Language>;
  name: Scalars["String"];
  valid_check?: Maybe<Scalars["String"]>;
}

export class CountryArtist_HistoriesArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Artist_HistoryOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Artist_HistoryWhereInput>;
}

export class CountryChildInput {
  connect?: InputMaybe<CountryWhereUniqueInput>;
}

export class CountryWhereInput {
  AND?: InputMaybe<Array<CountryWhereInput>>;
  NOT?: InputMaybe<Array<CountryWhereInput>>;
  OR?: InputMaybe<Array<CountryWhereInput>>;
  country_code?: InputMaybe<Scalars["ID"]>;
  country_code__in?: InputMaybe<Array<Scalars["ID"]>>;
  country_code_alpha_3?: InputMaybe<Scalars["String"]>;
  country_code_alpha_3country_code_alpha_3__contains?: InputMaybe<Scalars["String"]>;
  country_code_alpha_3country_code_alpha_3__ends_with?: InputMaybe<Scalars["String"]>;
  country_code_alpha_3country_code_alpha_3__in?: InputMaybe<Array<Scalars["String"]>>;
  country_code_alpha_3country_code_alpha_3__not?: InputMaybe<Scalars["String"]>;
  country_code_alpha_3country_code_alpha_3__not_contains?: InputMaybe<Scalars["String"]>;
  country_code_alpha_3country_code_alpha_3__not_ends_with?: InputMaybe<Scalars["String"]>;
  country_code_alpha_3country_code_alpha_3__not_in?: InputMaybe<Array<Scalars["String"]>>;
  country_code_alpha_3country_code_alpha_3__not_starts_with?: InputMaybe<Scalars["String"]>;
  country_code_alpha_3country_code_alpha_3__starts_with?: InputMaybe<Scalars["String"]>;
  country_code_numeric?: InputMaybe<Scalars["Int"]>;
  country_code_numeric__gt?: InputMaybe<Scalars["Int"]>;
  country_code_numeric__gte?: InputMaybe<Scalars["Int"]>;
  country_code_numeric__in?: InputMaybe<Array<Scalars["Int"]>>;
  country_code_numeric__lt?: InputMaybe<Scalars["Int"]>;
  country_code_numeric__lte?: InputMaybe<Scalars["Int"]>;
  country_code_numeric__not?: InputMaybe<Scalars["Int"]>;
  country_code_numeric__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  is_eu?: InputMaybe<Scalars["Int"]>;
  is_eu__gt?: InputMaybe<Scalars["Int"]>;
  is_eu__gte?: InputMaybe<Scalars["Int"]>;
  is_eu__in?: InputMaybe<Array<Scalars["Int"]>>;
  is_eu__lt?: InputMaybe<Scalars["Int"]>;
  is_eu__lte?: InputMaybe<Scalars["Int"]>;
  is_eu__not?: InputMaybe<Scalars["Int"]>;
  is_eu__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  language_code?: InputMaybe<LanguageWhereInput>;
  name?: InputMaybe<Scalars["String"]>;
  name__contains?: InputMaybe<Scalars["String"]>;
  name__ends_with?: InputMaybe<Scalars["String"]>;
  name__in?: InputMaybe<Array<Scalars["String"]>>;
  name__not?: InputMaybe<Scalars["String"]>;
  name__not_contains?: InputMaybe<Scalars["String"]>;
  name__not_ends_with?: InputMaybe<Scalars["String"]>;
  name__not_in?: InputMaybe<Array<Scalars["String"]>>;
  name__not_starts_with?: InputMaybe<Scalars["String"]>;
  name__starts_with?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<Scalars["String"]>;
  valid_check__contains?: InputMaybe<ValidType>;
  valid_check__in?: InputMaybe<Array<ValidType>>;
  valid_check__not?: InputMaybe<ValidType>;
  valid_check__not_contains?: InputMaybe<ValidType>;
  valid_check__not_in?: InputMaybe<Array<ValidType>>;
}

export class CountryWhereUniqueInput {
  country_code?: InputMaybe<Scalars["ID"]>;
}

export class CreateInvalidationWhereInput {
  invalidateToken: Scalars["String"];
  mediaType?: InputMaybe<MediaType>;
}

export class CreateLicenseInput {
  company?: InputMaybe<Scalars["String"]>;
  json?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  track_id: Scalars["Int"];
}

export class CurationManagement {
  created_at: Scalars["DateTime"];
  id: Scalars["Int"];
  is_owner: Scalars["Boolean"];
  playlist: Playlist;
  playlist_id: Scalars["Int"];
  product_price: ProductPrice;
  product_price_id: Scalars["Int"];
  updated_at: Scalars["DateTime"];
  user: User;
  user_id: Scalars["Int"];
}

export enum CurationManagementOrderByInput {
  CreatedAtAsc = "created_at__ASC",
  CreatedAtDesc = "created_at__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  PlaylistIdAsc = "playlist_id__ASC",
  PlaylistIdDesc = "playlist_id__DESC"
}

export class CurationManagementWhereInput {
  id?: InputMaybe<Scalars["Int"]>;
  id__gt?: InputMaybe<Scalars["Int"]>;
  id__gte?: InputMaybe<Scalars["Int"]>;
  id__in?: InputMaybe<Array<Scalars["Int"]>>;
  id__lt?: InputMaybe<Scalars["Int"]>;
  id__lte?: InputMaybe<Scalars["Int"]>;
  is_approve?: InputMaybe<Scalars["Boolean"]>;
  playlist_id?: InputMaybe<Scalars["Int"]>;
  playlist_id__in?: InputMaybe<Array<Scalars["Int"]>>;
  user_id?: InputMaybe<Scalars["Int"]>;
}

export enum CurrencyCode {
  Krw = "KRW",
  Usd = "USD"
}

export enum CurrencyType {
  Aed = "AED",
  Aud = "AUD",
  Bhd = "BHD",
  Bnd = "BND",
  Brl = "BRL",
  Cad = "CAD",
  Chf = "CHF",
  Cny = "CNY",
  Dkk = "DKK",
  Eur = "EUR",
  Gbp = "GBP",
  Hkd = "HKD",
  Idr = "IDR",
  Jpy = "JPY",
  Krw = "KRW",
  Kwd = "KWD",
  Myr = "MYR",
  Nok = "NOK",
  Nzd = "NZD",
  Php = "PHP",
  Rub = "RUB",
  Sar = "SAR",
  Sek = "SEK",
  Sgd = "SGD",
  Thb = "THB",
  Twd = "TWD",
  Usd = "USD",
  Vnd = "VND"
}

export class DecideFromCommodityResponse {
  flag?: Maybe<Scalars["Boolean"]>;
  item?: Maybe<Array<Maybe<Commodity>>>;
}

export class DecideFromCommodityUpdateInput {
  status?: InputMaybe<StatusOfCommodity>;
}

export class DeleteInput {
  book: BookType;
  company_id?: InputMaybe<Scalars["ID"]>;
  fileType?: InputMaybe<FileType>;
  filename: Scalars["String"];
  path?: InputMaybe<PathType>;
}

export enum EventType {
  Admin = "ADMIN",
  AdminCancel = "ADMIN_CANCEL",
  Payletter = "PAYLETTER",
  PayletterCancel = "PAYLETTER_CANCEL",
  Product = "PRODUCT",
  ProductCancel = "PRODUCT_CANCEL"
}

export enum FileAction {
  Insert = "INSERT",
  Remove = "REMOVE",
  Update = "UPDATE"
}

export class FileRule {
  description: Scalars["String"];
  id: Scalars["Int"];
  mp3_price?: Maybe<ProductPrice>;
  mp3_price_id: Scalars["Int"];
  target: TypeMetadataSubclass;
  track?: Maybe<Array<Maybe<Track>>>;
  type: FileRuleType;
  wav_price?: Maybe<ProductPrice>;
  wav_price_id?: Maybe<Scalars["Int"]>;
}

export enum FileRuleOrderByInput {
  CreatedAtAsc = "created_at__ASC",
  CreatedAtDesc = "created_at__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC"
}

export enum FileRuleType {
  All = "ALL",
  Mp3 = "MP3"
}

export class FileRuleWhereInput {
  id?: InputMaybe<Scalars["Int"]>;
  id__in?: InputMaybe<Array<Scalars["Int"]>>;
  target?: InputMaybe<TypeMetadataSubclass>;
  target__in?: InputMaybe<Array<TypeMetadataSubclass>>;
  type?: InputMaybe<FileRuleType>;
  type__in?: InputMaybe<Array<FileRuleType>>;
}

export class FileWhereInput {
  action: FileAction;
  /** Master user only */
  company_id?: InputMaybe<Scalars["ID"]>;
  filename: Scalars["String"];
  license?: InputMaybe<Scalars["String"]>;
  path?: InputMaybe<PathType>;
  target_id?: InputMaybe<Scalars["ID"]>;
  target_table?: InputMaybe<TargetTableInPut>;
}

/**
 * {
 *   "name":"genre",
 *   "id": "genre_id" ,
 *   "type" : "Int",
 *   "genre_self_relation": { "fk" : "parent_id" , "pk" : "genre_id" ,"name": "genre_self_relation"},
 *   "genre_self_relation_parent": { "fk" : "parent_id" , "pk" : "genre_id" ,"name": "genre_self_relation"},
 *   "genre_self_relation_child": { "fk" : "child_id" , "pk" : "genre_id" , "name": "genre_self_relation"}
 * }
 */
export class Genre {
  detail?: Maybe<Scalars["String"]>;
  expose: Scalars["Int"];
  genre_id: Scalars["ID"];
  genre_self_relation?: Maybe<Array<Genre_Self_Relation>>;
  genre_self_relation_child?: Maybe<Array<Genre_Self_Relation>>;
  genre_self_relation_parent?: Maybe<Array<Genre_Self_Relation>>;
  genre_title?: Maybe<Array<Genre_Title>>;
  genre_url: Array<Maybe<Genre_Url>>;
  metadata_genre_relation?: Maybe<Array<Metadata_Genre_Relation>>;
  name: Scalars["String"];
  playlist_genre_relation: Array<Maybe<Playlist_Genre_Relation>>;
  type_kind?: Maybe<Scalars["String"]>;
  valid_check?: Maybe<ValidType>;
  weight: Scalars["Int"];
}

/**
 * {
 *   "name":"genre",
 *   "id": "genre_id" ,
 *   "type" : "Int",
 *   "genre_self_relation": { "fk" : "parent_id" , "pk" : "genre_id" ,"name": "genre_self_relation"},
 *   "genre_self_relation_parent": { "fk" : "parent_id" , "pk" : "genre_id" ,"name": "genre_self_relation"},
 *   "genre_self_relation_child": { "fk" : "child_id" , "pk" : "genre_id" , "name": "genre_self_relation"}
 * }
 */
export class GenreGenre_Self_RelationArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Genre_Self_RelationOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Genre_Self_RelationWhereInput>;
}

/**
 * {
 *   "name":"genre",
 *   "id": "genre_id" ,
 *   "type" : "Int",
 *   "genre_self_relation": { "fk" : "parent_id" , "pk" : "genre_id" ,"name": "genre_self_relation"},
 *   "genre_self_relation_parent": { "fk" : "parent_id" , "pk" : "genre_id" ,"name": "genre_self_relation"},
 *   "genre_self_relation_child": { "fk" : "child_id" , "pk" : "genre_id" , "name": "genre_self_relation"}
 * }
 */
export class GenreGenre_Self_Relation_ChildArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Genre_Self_RelationOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Genre_Self_RelationWhereInput>;
}

/**
 * {
 *   "name":"genre",
 *   "id": "genre_id" ,
 *   "type" : "Int",
 *   "genre_self_relation": { "fk" : "parent_id" , "pk" : "genre_id" ,"name": "genre_self_relation"},
 *   "genre_self_relation_parent": { "fk" : "parent_id" , "pk" : "genre_id" ,"name": "genre_self_relation"},
 *   "genre_self_relation_child": { "fk" : "child_id" , "pk" : "genre_id" , "name": "genre_self_relation"}
 * }
 */
export class GenreGenre_Self_Relation_ParentArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Genre_Self_RelationOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Genre_Self_RelationWhereInput>;
}

/**
 * {
 *   "name":"genre",
 *   "id": "genre_id" ,
 *   "type" : "Int",
 *   "genre_self_relation": { "fk" : "parent_id" , "pk" : "genre_id" ,"name": "genre_self_relation"},
 *   "genre_self_relation_parent": { "fk" : "parent_id" , "pk" : "genre_id" ,"name": "genre_self_relation"},
 *   "genre_self_relation_child": { "fk" : "child_id" , "pk" : "genre_id" , "name": "genre_self_relation"}
 * }
 */
export class GenreGenre_TitleArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Genre_TitleOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Genre_TitleWhereInput>;
}

/**
 * {
 *   "name":"genre",
 *   "id": "genre_id" ,
 *   "type" : "Int",
 *   "genre_self_relation": { "fk" : "parent_id" , "pk" : "genre_id" ,"name": "genre_self_relation"},
 *   "genre_self_relation_parent": { "fk" : "parent_id" , "pk" : "genre_id" ,"name": "genre_self_relation"},
 *   "genre_self_relation_child": { "fk" : "child_id" , "pk" : "genre_id" , "name": "genre_self_relation"}
 * }
 */
export class GenreGenre_UrlArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Genre_UrlOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Genre_UrlWhereInput>;
}

/**
 * {
 *   "name":"genre",
 *   "id": "genre_id" ,
 *   "type" : "Int",
 *   "genre_self_relation": { "fk" : "parent_id" , "pk" : "genre_id" ,"name": "genre_self_relation"},
 *   "genre_self_relation_parent": { "fk" : "parent_id" , "pk" : "genre_id" ,"name": "genre_self_relation"},
 *   "genre_self_relation_child": { "fk" : "child_id" , "pk" : "genre_id" , "name": "genre_self_relation"}
 * }
 */
export class GenreMetadata_Genre_RelationArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Metadata_Genre_RelationOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Metadata_Genre_RelationWhereInput>;
}

/**
 * {
 *   "name":"genre",
 *   "id": "genre_id" ,
 *   "type" : "Int",
 *   "genre_self_relation": { "fk" : "parent_id" , "pk" : "genre_id" ,"name": "genre_self_relation"},
 *   "genre_self_relation_parent": { "fk" : "parent_id" , "pk" : "genre_id" ,"name": "genre_self_relation"},
 *   "genre_self_relation_child": { "fk" : "child_id" , "pk" : "genre_id" , "name": "genre_self_relation"}
 * }
 */
export class GenrePlaylist_Genre_RelationArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Playlist_Genre_RelationOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Playlist_Genre_RelationWhereInput>;
}

export class GenreChildInput {
  connect?: InputMaybe<GenreWhereUniqueInput>;
}

export class GenreCreateInput {
  detail?: InputMaybe<Scalars["String"]>;
  expose?: InputMaybe<Scalars["Int"]>;
  genre_?: InputMaybe<Genre_TitleCreateManyWithoutGenreInput>;
  genre_self_relation_child?: InputMaybe<Genre_Self_Relation_ChildCreate_Input>;
  genre_self_relation_parent?: InputMaybe<Genre_Self_Relation_ParentCreate_Input>;
  genre_title?: InputMaybe<Genre_TitleCreateManyWithoutGenreInput>;
  genre_url?: InputMaybe<Genre_UrlCreateManyInput>;
  metadata_genre_relation?: InputMaybe<Genre_Metadata_RelationCreateManyWithoutMetadataInput>;
  name: Scalars["String"];
  type_kind?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
  weight?: InputMaybe<Scalars["Int"]>;
}

export enum GenreOrderByInput {
  CreatedAtAsc = "createdAt__ASC",
  CreatedAtDesc = "createdAt__DESC",
  ExposeAsc = "expose__ASC",
  ExposeDesc = "expose__DESC",
  GenreIdAsc = "genre_id__ASC",
  GenreIdDesc = "genre_id__DESC",
  NameAsc = "name__ASC",
  NameDesc = "name__DESC",
  UpdatedAtAsc = "updatedAt__ASC",
  UpdatedAtDesc = "updatedAt__DESC",
  ValidCheckAsc = "valid_check__ASC",
  ValidCheckDesc = "valid_check__DESC"
}

export class GenreUpdateInput {
  _onlySearch?: InputMaybe<Scalars["Boolean"]>;
  detail?: InputMaybe<Scalars["String"]>;
  expose?: InputMaybe<Scalars["Int"]>;
  genre_self_relation_child?: InputMaybe<Genre_Self_Relation_ChildUpdate_Input>;
  genre_self_relation_parent?: InputMaybe<Genre_Self_Relation_ChildUpdate_Input>;
  genre_title?: InputMaybe<Genre_TitleUpdateManyWithoutGenreInput>;
  genre_url?: InputMaybe<Genre_UrlUpdateManyInput>;
  metadata_genre_relation?: InputMaybe<Metadata_Genre_RelationUpdateManyWithoutGenreInput>;
  name?: InputMaybe<Scalars["String"]>;
  type_kind?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
  weight?: InputMaybe<Scalars["Int"]>;
}

export class GenreUpdateManyMutationInput {
  name?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class GenreWhereInput {
  AND?: InputMaybe<Array<GenreWhereInput>>;
  NOT?: InputMaybe<Array<GenreWhereInput>>;
  OR?: InputMaybe<Array<GenreWhereInput>>;
  detail?: InputMaybe<Scalars["String"]>;
  detail__contains?: InputMaybe<Scalars["String"]>;
  detail__ends_with?: InputMaybe<Scalars["String"]>;
  detail__in?: InputMaybe<Array<Scalars["String"]>>;
  detail__not?: InputMaybe<Scalars["String"]>;
  detail__not_contains?: InputMaybe<Scalars["String"]>;
  detail__not_ends_with?: InputMaybe<Scalars["String"]>;
  detail__not_in?: InputMaybe<Array<Scalars["String"]>>;
  detail__not_starts_with?: InputMaybe<Scalars["String"]>;
  detail__starts_with?: InputMaybe<Scalars["String"]>;
  expose?: InputMaybe<Scalars["Int"]>;
  expose__contains?: InputMaybe<Scalars["Int"]>;
  expose__gt?: InputMaybe<Scalars["Int"]>;
  expose__gte?: InputMaybe<Scalars["Int"]>;
  expose__in?: InputMaybe<Array<Scalars["Int"]>>;
  expose__lt?: InputMaybe<Scalars["Int"]>;
  expose__lte?: InputMaybe<Scalars["Int"]>;
  expose__not?: InputMaybe<Scalars["Int"]>;
  expose__not_contains?: InputMaybe<Scalars["Int"]>;
  expose__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  genre_id?: InputMaybe<Scalars["ID"]>;
  genre_id__exist?: InputMaybe<Scalars["Boolean"]>;
  genre_id__gt?: InputMaybe<Scalars["ID"]>;
  genre_id__gte?: InputMaybe<Scalars["ID"]>;
  genre_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  genre_id__lt?: InputMaybe<Scalars["ID"]>;
  genre_id__lte?: InputMaybe<Scalars["ID"]>;
  genre_id__not?: InputMaybe<Scalars["ID"]>;
  genre_id__not_in?: InputMaybe<Array<Scalars["ID"]>>;
  genre_self_relation_child__some?: InputMaybe<Genre_Self_RelationWhereInput>;
  genre_self_relation_parent__some?: InputMaybe<Genre_Self_RelationWhereInput>;
  genre_title__lateral?: InputMaybe<Scalars["Boolean"]>;
  genre_title__some?: InputMaybe<Genre_TitleWhereInput>;
  genre_url__lateral?: InputMaybe<Scalars["Boolean"]>;
  genre_url__some?: InputMaybe<Genre_UrlWhereInput>;
  metadata_genre_relation__lateral?: InputMaybe<Scalars["Boolean"]>;
  metadata_genre_relation__some?: InputMaybe<Metadata_Genre_RelationWhereInput>;
  name?: InputMaybe<Scalars["String"]>;
  name__contains?: InputMaybe<Scalars["String"]>;
  name__ends_with?: InputMaybe<Scalars["String"]>;
  name__in?: InputMaybe<Array<Scalars["String"]>>;
  name__not?: InputMaybe<Scalars["String"]>;
  name__not_contains?: InputMaybe<Scalars["String"]>;
  name__not_ends_with?: InputMaybe<Scalars["String"]>;
  name__not_in?: InputMaybe<Array<Scalars["String"]>>;
  name__not_starts_with?: InputMaybe<Scalars["String"]>;
  name__starts_with?: InputMaybe<Scalars["String"]>;
  type_kind?: InputMaybe<Scalars["String"]>;
  type_kind__contains?: InputMaybe<Scalars["String"]>;
  type_kind__ends_with?: InputMaybe<Scalars["String"]>;
  type_kind__in?: InputMaybe<Array<Scalars["String"]>>;
  type_kind__not?: InputMaybe<Scalars["String"]>;
  type_kind__not_contains?: InputMaybe<Scalars["String"]>;
  type_kind__not_ends_with?: InputMaybe<Scalars["String"]>;
  type_kind__not_in?: InputMaybe<Array<Scalars["String"]>>;
  type_kind__not_starts_with?: InputMaybe<Scalars["String"]>;
  type_kind__starts_with?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
  valid_check__contains?: InputMaybe<ValidType>;
  valid_check__in?: InputMaybe<Array<ValidType>>;
  valid_check__not?: InputMaybe<ValidType>;
  valid_check__not_contains?: InputMaybe<ValidType>;
  valid_check__not_in?: InputMaybe<Array<ValidType>>;
  weight?: InputMaybe<Scalars["Int"]>;
  weight__contains?: InputMaybe<Scalars["Int"]>;
  weight__gt?: InputMaybe<Scalars["Int"]>;
  weight__gte?: InputMaybe<Scalars["Int"]>;
  weight__in?: InputMaybe<Array<Scalars["Int"]>>;
  weight__lt?: InputMaybe<Scalars["Int"]>;
  weight__lte?: InputMaybe<Scalars["Int"]>;
  weight__not?: InputMaybe<Scalars["Int"]>;
  weight__not_contains?: InputMaybe<Scalars["Int"]>;
  weight__not_in?: InputMaybe<Array<Scalars["Int"]>>;
}

export class GenreWhereUniqueInput {
  genre_id?: InputMaybe<Scalars["ID"]>;
}

export class Genre_Metadata_RelationCreateManyWithoutMetadataInput {
  create?: InputMaybe<Array<Genre_Metadata_RelationCreateWithoutMetadataInput>>;
}

export class Genre_Metadata_RelationCreateWithoutMetadataInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  metadata: MetadataChildInput;
  valid_check?: InputMaybe<ValidType>;
}

/**
 * {
 *   "name":"genre_self_relation",
 *   "id": "id",
 *   "genre": { "fk" : "parent_id" , "pk" : "genre_id" },
 *   "genre_self_relation_child": {"id":"child_id", "fk" : "genre_id" , "pk" : "child_id", "table":"genre"},
 *   "genre_self_relation_parent": {"id":"parent_id", "fk" : "genre_id" , "pk" : "parent_id", "table":"genre"}
 * }
 */
export class Genre_Self_Relation {
  child_id?: Maybe<Scalars["ID"]>;
  genre_self_relation_child: Array<Maybe<Genre>>;
  genre_self_relation_parent: Array<Maybe<Genre>>;
  id: Scalars["UUID"];
  parent_id?: Maybe<Scalars["ID"]>;
  type?: Maybe<Scalars["String"]>;
}

export enum Genre_Self_RelationOrderByInput {
  CreatedAtAsc = "createdAt__ASC",
  CreatedAtDesc = "createdAt__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  TypeAsc = "type__ASC",
  TypeDesc = "type__DESC",
  UpdatedAtAsc = "updatedAt__ASC",
  UpdatedAtDesc = "updatedAt__DESC"
}

export class Genre_Self_RelationUpdateInput {
  child?: InputMaybe<GenreChildInput>;
  parent?: InputMaybe<GenreChildInput>;
  type?: InputMaybe<Scalars["String"]>;
}

export class Genre_Self_RelationWhereInput {
  AND?: InputMaybe<Array<Genre_Self_RelationWhereInput>>;
  NOT?: InputMaybe<Array<Genre_Self_RelationWhereInput>>;
  OR?: InputMaybe<Array<Genre_Self_RelationWhereInput>>;
  child?: InputMaybe<Scalars["String"]>;
  child_id?: InputMaybe<Scalars["ID"]>;
  child_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  genre__lateral?: InputMaybe<Scalars["Boolean"]>;
  genre__some?: InputMaybe<GenreWhereInput>;
  genre_self_relation_child__some?: InputMaybe<GenreWhereInput>;
  genre_self_relation_parent__some?: InputMaybe<GenreWhereInput>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  parent?: InputMaybe<Scalars["String"]>;
  parent_id?: InputMaybe<Scalars["ID"]>;
  parent_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  type?: InputMaybe<Scalars["String"]>;
  type__contains?: InputMaybe<Scalars["String"]>;
  type__ends_with?: InputMaybe<Scalars["String"]>;
  type__in?: InputMaybe<Array<Scalars["String"]>>;
  type__not?: InputMaybe<Scalars["String"]>;
  type__not_contains?: InputMaybe<Scalars["String"]>;
  type__not_ends_with?: InputMaybe<Scalars["String"]>;
  type__not_in?: InputMaybe<Array<Scalars["String"]>>;
  type__not_starts_with?: InputMaybe<Scalars["String"]>;
  type__starts_with?: InputMaybe<Scalars["String"]>;
}

export class Genre_Self_Relation_ChildCreate_Input {
  create?: InputMaybe<Array<Genre_Self_Relation_Child_Input>>;
}

export class Genre_Self_Relation_ChildUpdate_Input {
  create?: InputMaybe<Array<Genre_Self_Relation_Child_Input>>;
  update?: InputMaybe<Array<Genre_Self_Relation_Child_Input>>;
}

export class Genre_Self_Relation_Child_Input {
  genre_self_relation_parent: GenreChildInput;
  type?: InputMaybe<Scalars["String"]>;
}

export class Genre_Self_Relation_ParentCreate_Input {
  create?: InputMaybe<Array<Genre_Self_Relation_Parent_Input>>;
}

export class Genre_Self_Relation_ParentUpdate_Input {
  create?: InputMaybe<Array<Genre_Self_Relation_Parent_Input>>;
  update?: InputMaybe<Array<Genre_Self_Relation_Parent_Input>>;
}

export class Genre_Self_Relation_Parent_Input {
  genre_self_relation_child: GenreChildInput;
  type?: InputMaybe<Scalars["String"]>;
}

/**
 * {
 *   "name":"genre_title",
 *   "id": "id",
 *   "genre": { "fk" : "genre_id" , "pk" : "genre_id" },
 *   "language": { "fk" : "language_code" , "pk" : "language_code" }
 * }
 */
export class Genre_Title {
  exposure_order: Scalars["Int"];
  genre?: Maybe<Array<Maybe<Genre>>>;
  genre_id?: Maybe<Scalars["ID"]>;
  id: Scalars["UUID"];
  language?: Maybe<Array<Maybe<Language>>>;
  language_code?: Maybe<Scalars["ID"]>;
  tsvec_words?: Maybe<Scalars["String"]>;
  type_genre_title: Scalars["String"];
  valid_check?: Maybe<ValidType>;
  value: Scalars["String"];
}

/**
 * {
 *   "name":"genre_title",
 *   "id": "id",
 *   "genre": { "fk" : "genre_id" , "pk" : "genre_id" },
 *   "language": { "fk" : "language_code" , "pk" : "language_code" }
 * }
 */
export class Genre_TitleLanguageArgs {
  where?: InputMaybe<LanguageWhereInput>;
}

export class Genre_TitleCreateManyWithoutGenreInput {
  create?: InputMaybe<Array<Genre_TitleCreateWithoutGenreInput>>;
}

export class Genre_TitleCreateWithoutGenreInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  language?: InputMaybe<LanguageChildInput>;
  type_genre_title: Scalars["String"];
  valid_check?: InputMaybe<ValidType>;
  value: Scalars["String"];
}

export enum Genre_TitleOrderByInput {
  CreatedAtAsc = "createdAt__ASC",
  CreatedAtDesc = "createdAt__DESC",
  ExposureOrderAsc = "exposure_order__ASC",
  ExposureOrderDesc = "exposure_order__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  TypeGenreTitleAsc = "type_genre_title__ASC",
  TypeGenreTitleDesc = "type_genre_title__DESC",
  UpdatedAtAsc = "updatedAt__ASC",
  UpdatedAtDesc = "updatedAt__DESC",
  ValidCheckAsc = "valid_check__ASC",
  ValidCheckDesc = "valid_check__DESC",
  ValueAsc = "value__ASC",
  ValueDesc = "value__DESC",
  ValueSimilarity = "value__SIMILARITY"
}

export class Genre_TitleUpdateInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  genre?: InputMaybe<GenreChildInput>;
  language?: InputMaybe<LanguageChildInput>;
  type_genre_title?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
  value?: InputMaybe<Scalars["String"]>;
}

export class Genre_TitleUpdateManyWithoutGenreInput {
  create?: InputMaybe<Array<Genre_TitleCreateWithoutGenreInput>>;
  update?: InputMaybe<Array<Genre_TitleUpdateWithWhereUniqueWithoutGenreInput>>;
}

export class Genre_TitleUpdateWithWhereUniqueWithoutGenreInput {
  data: Genre_TitleUpdateWithoutGenreDataInput;
  where: UuidWhereUniqueInput;
}

export class Genre_TitleUpdateWithoutGenreDataInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  language?: InputMaybe<LanguageChildInput>;
  type_genre_title?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
  value?: InputMaybe<Scalars["String"]>;
}

export class Genre_TitleWhereInput {
  AND?: InputMaybe<Array<Genre_TitleWhereInput>>;
  NOT?: InputMaybe<Array<Genre_TitleWhereInput>>;
  OR?: InputMaybe<Array<Genre_TitleWhereInput>>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  exposure_order__gt?: InputMaybe<Scalars["Int"]>;
  exposure_order__gte?: InputMaybe<Scalars["Int"]>;
  exposure_order__in?: InputMaybe<Array<Scalars["Int"]>>;
  exposure_order__lt?: InputMaybe<Scalars["Int"]>;
  exposure_order__lte?: InputMaybe<Scalars["Int"]>;
  exposure_order__not?: InputMaybe<Scalars["Int"]>;
  exposure_order__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  genre__lateral?: InputMaybe<Scalars["Boolean"]>;
  genre__some?: InputMaybe<GenreWhereInput>;
  genre_id?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  genre_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  genre_id__not_include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  language__lateral?: InputMaybe<Scalars["Boolean"]>;
  language__some?: InputMaybe<LanguageWhereInput>;
  tsvec_words__search?: InputMaybe<Scalars["String"]>;
  type_genre_title?: InputMaybe<Scalars["String"]>;
  type_genre_title__contains?: InputMaybe<Scalars["String"]>;
  type_genre_title__ends_with?: InputMaybe<Scalars["String"]>;
  type_genre_title__in?: InputMaybe<Array<Scalars["String"]>>;
  type_genre_title__not?: InputMaybe<Scalars["String"]>;
  type_genre_title__not_contains?: InputMaybe<Scalars["String"]>;
  type_genre_title__not_ends_with?: InputMaybe<Scalars["String"]>;
  type_genre_title__not_in?: InputMaybe<Array<Scalars["String"]>>;
  type_genre_title__not_starts_with?: InputMaybe<Scalars["String"]>;
  type_genre_title__starts_with?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
  valid_check__contains?: InputMaybe<ValidType>;
  valid_check__in?: InputMaybe<Array<ValidType>>;
  valid_check__not?: InputMaybe<ValidType>;
  valid_check__not_contains?: InputMaybe<ValidType>;
  valid_check__not_in?: InputMaybe<Array<ValidType>>;
  value?: InputMaybe<Scalars["String"]>;
  value__contains?: InputMaybe<Scalars["String"]>;
  value__ends_with?: InputMaybe<Scalars["String"]>;
  value__in?: InputMaybe<Array<Scalars["String"]>>;
  value__not?: InputMaybe<Scalars["String"]>;
  value__not_contains?: InputMaybe<Scalars["String"]>;
  value__not_ends_with?: InputMaybe<Scalars["String"]>;
  value__not_in?: InputMaybe<Array<Scalars["String"]>>;
  value__not_starts_with?: InputMaybe<Scalars["String"]>;
  value__starts_with?: InputMaybe<Scalars["String"]>;
}

/**
 * {
 *   "name":"genre_url",
 *   "id": "id",
 *   "genre": { "fk" : "genre_id" , "pk" : "genre_id" }
 * }
 */
export class Genre_Url {
  exposure_order?: Maybe<Scalars["Int"]>;
  genre?: Maybe<Array<Maybe<Genre>>>;
  genre_id?: Maybe<Scalars["ID"]>;
  id: Scalars["UUID"];
  type_url: Scalars["String"];
  url: Scalars["String"];
  valid_check?: Maybe<ValidType>;
}

export class Genre_UrlCreateInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  type_url: Scalars["String"];
  url: Scalars["String"];
  valid_check?: InputMaybe<ValidType>;
}

export class Genre_UrlCreateManyInput {
  create?: InputMaybe<Array<Genre_UrlCreateInput>>;
}

export enum Genre_UrlOrderByInput {
  CreatedAtAsc = "createdAt__ASC",
  CreatedAtDesc = "createdAt__DESC",
  ExposureOrderAsc = "exposure_order__ASC",
  ExposureOrderDesc = "exposure_order__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  TypeUrlAsc = "type_url__ASC",
  TypeUrlDesc = "type_url__DESC",
  UpdatedAtAsc = "updatedAt__ASC",
  UpdatedAtDesc = "updatedAt__DESC",
  UrlAsc = "url__ASC",
  UrlDesc = "url__DESC",
  ValidCheckAsc = "valid_check__ASC",
  ValidCheckDesc = "valid_check__DESC"
}

export class Genre_UrlUpdateInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  genre?: InputMaybe<GenreChildInput>;
  type_url?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class Genre_UrlUpdateManyInput {
  create?: InputMaybe<Array<Genre_UrlCreateInput>>;
  update?: InputMaybe<Array<Genre_UrlUpdateWithWhereUniqueInput>>;
}

export class Genre_UrlUpdateWithWhereUniqueInput {
  data: Genre_UrlUpdateInput;
  where: UuidWhereUniqueInput;
}

export class Genre_UrlWhereInput {
  AND?: InputMaybe<Array<Genre_UrlWhereInput>>;
  NOT?: InputMaybe<Array<Genre_UrlWhereInput>>;
  OR?: InputMaybe<Array<Genre_UrlWhereInput>>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  exposure_order__gt?: InputMaybe<Scalars["Int"]>;
  exposure_order__gte?: InputMaybe<Scalars["Int"]>;
  exposure_order__in?: InputMaybe<Array<Scalars["Int"]>>;
  exposure_order__lt?: InputMaybe<Scalars["Int"]>;
  exposure_order__lte?: InputMaybe<Scalars["Int"]>;
  exposure_order__not?: InputMaybe<Scalars["Int"]>;
  exposure_order__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  genre__lateral?: InputMaybe<Scalars["Boolean"]>;
  genre__some?: InputMaybe<GenreWhereInput>;
  genre_id?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  genre_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  genre_id__not_include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  type_url?: InputMaybe<Scalars["String"]>;
  type_url__contains?: InputMaybe<Scalars["String"]>;
  type_url__ends_with?: InputMaybe<Scalars["String"]>;
  type_url__in?: InputMaybe<Array<Scalars["String"]>>;
  type_url__not?: InputMaybe<Scalars["String"]>;
  type_url__not_contains?: InputMaybe<Scalars["String"]>;
  type_url__not_ends_with?: InputMaybe<Scalars["String"]>;
  type_url__not_in?: InputMaybe<Array<Scalars["String"]>>;
  type_url__not_starts_with?: InputMaybe<Scalars["String"]>;
  type_url__starts_with?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
  url__contains?: InputMaybe<Scalars["String"]>;
  url__ends_with?: InputMaybe<Scalars["String"]>;
  url__in?: InputMaybe<Array<Scalars["String"]>>;
  url__not?: InputMaybe<Scalars["String"]>;
  url__not_contains?: InputMaybe<Scalars["String"]>;
  url__not_ends_with?: InputMaybe<Scalars["String"]>;
  url__not_in?: InputMaybe<Array<Scalars["String"]>>;
  url__not_starts_with?: InputMaybe<Scalars["String"]>;
  url__starts_with?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
  valid_check__contains?: InputMaybe<ValidType>;
  valid_check__in?: InputMaybe<Array<ValidType>>;
  valid_check__not?: InputMaybe<ValidType>;
  valid_check__not_contains?: InputMaybe<ValidType>;
  valid_check__not_in?: InputMaybe<Array<ValidType>>;
}

export class Goods {
  ask_price?: Maybe<Scalars["Float"]>;
  ask_price_update?: Maybe<Scalars["DateTime"]>;
  ask_quantity?: Maybe<Scalars["Int"]>;
  category?: Maybe<CategoryOfGoods>;
  commodity?: Maybe<Array<Maybe<Commodity>>>;
  created_at: Scalars["DateTime"];
  goods_artist_relation?: Maybe<Array<Goods_Artist_Relation>>;
  goods_company_relation?: Maybe<Array<Goods_Company_Relation>>;
  goods_id: Scalars["ID"];
  goods_structure?: Maybe<Array<Goods_Structure>>;
  goods_subdata_unique: Array<Maybe<Goods_Subdata_Unique>>;
  goods_title?: Maybe<Array<Goods_Title>>;
  goods_type: Goods_Type;
  goods_url?: Maybe<Array<Goods_Url>>;
  is_collection?: Maybe<Scalars["Boolean"]>;
  is_sale?: Maybe<Scalars["Boolean"]>;
  metadata_goods_relation?: Maybe<Array<Metadata_Goods_Relation>>;
  name: Scalars["String"];
  official_barcode?: Maybe<Scalars["String"]>;
  official_price?: Maybe<Scalars["Float"]>;
  type?: Maybe<TypeOfGoods>;
  type_id?: Maybe<Scalars["Int"]>;
  weight?: Maybe<Scalars["Int"]>;
}

export class GoodsCommodityArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<CommodityOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CommodityWhereInput>;
}

export class GoodsGoods_Artist_RelationArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<Goods_Artist_RelationOrderByInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Goods_Artist_RelationWhereInput>;
}

export class GoodsGoods_Company_RelationArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<Goods_Company_RelationOrderByInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Goods_Company_RelationWhereInput>;
}

export class GoodsGoods_StructureArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<Goods_StructureOrderByInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Goods_StructureWhereInput>;
}

export class GoodsGoods_Subdata_UniqueArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Goods_Subdata_UniqueOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Goods_Subdata_UniqueWhereInput>;
}

export class GoodsGoods_TitleArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<Goods_TitleOrderByInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Goods_TitleWhereInput>;
}

export class GoodsGoods_TypeArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<Goods_TypeOrderByInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Goods_TypeWhereInput>;
}

export class GoodsGoods_UrlArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<Goods_UrlOrderByInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Goods_UrlWhereInput>;
}

export class GoodsMetadata_Goods_RelationArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<Metadata_Goods_RelationOrderByInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Metadata_Goods_RelationWhereInput>;
}

export class GoodsChildInput {
  connect?: InputMaybe<GoodsWhereUniqueInput>;
}

export class GoodsCreateInput {
  ask_price?: InputMaybe<Scalars["Float"]>;
  ask_price_update?: InputMaybe<Scalars["DateTime"]>;
  ask_quantity?: InputMaybe<Scalars["Int"]>;
  category?: InputMaybe<CategoryOfGoods>;
  created_at: Scalars["DateTime"];
  goods_artist_relation?: InputMaybe<Goods_Artist_RelationCreateManyWithoutGoodsInput>;
  goods_company_relation?: InputMaybe<Goods_Company_RelationCreateManyWithoutGoodsInput>;
  goods_structure?: InputMaybe<Goods_StructureCreateManyInput>;
  goods_subdata_unique?: InputMaybe<Goods_Subdata_UniqueCreateManyInput>;
  goods_title?: InputMaybe<Goods_TitleCreateManyWithoutGoodsInput>;
  goods_url?: InputMaybe<Goods_UrlCreateManyWithoutGoodsInput>;
  is_collection?: InputMaybe<Scalars["Boolean"]>;
  is_sale?: InputMaybe<Scalars["Boolean"]>;
  metadata_goods_relation?: InputMaybe<Metadata_Goods_RelationCreateManyWithoutGoodsInput>;
  name: Scalars["String"];
  official_barcode?: InputMaybe<Scalars["String"]>;
  official_price?: InputMaybe<Scalars["Float"]>;
  type?: InputMaybe<TypeOfGoods>;
  type_id: Scalars["Int"];
  weight?: InputMaybe<Scalars["Int"]>;
}

export enum GoodsOrderByInput {
  AskPriceAsc = "ask_price__ASC",
  AskPriceDesc = "ask_price__DESC",
  CreatedAtAsc = "created_at__ASC",
  CreatedAtDesc = "created_at__DESC",
  GoodsIdAsc = "goods_id__ASC",
  GoodsIdDesc = "goods_id__DESC",
  IsSaleAsc = "is_sale__ASC",
  IsSaleDesc = "is_sale__DESC",
  NameAsc = "name__ASC",
  NameDesc = "name__DESC",
  OfficialPriceAsc = "official_price__ASC",
  OfficialPriceDesc = "official_price__DESC",
  TypeKindAsc = "type_kind__ASC",
  TypeKindDesc = "type_kind__DESC",
  WeightAsc = "weight__ASC",
  WeightDesc = "weight__DESC"
}

export class GoodsUpdateInput {
  _onlySearch?: InputMaybe<Scalars["Boolean"]>;
  ask_price?: InputMaybe<Scalars["Float"]>;
  ask_price_update?: InputMaybe<Scalars["DateTime"]>;
  ask_quantity?: InputMaybe<Scalars["Int"]>;
  category?: InputMaybe<CategoryOfGoods>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  goods_artist_relation?: InputMaybe<Goods_Artist_RelationUpdateManyWithoutGoodsInput>;
  goods_company_relation?: InputMaybe<Goods_Company_RelationUpdateManyWithoutGoodsInput>;
  goods_structure?: InputMaybe<Goods_StructureUpdateManyWithoutGoodsInput>;
  goods_subdata_unique?: InputMaybe<Goods_Subdata_UniqueUpdateManyInput>;
  goods_title?: InputMaybe<Goods_TitleUpdateManyWithoutGoodsInput>;
  goods_url?: InputMaybe<Goods_UrlUpdateManyInput>;
  is_collection?: InputMaybe<Scalars["Boolean"]>;
  is_sale?: InputMaybe<Scalars["Boolean"]>;
  metadata_goods_relation?: InputMaybe<Metadata_Goods_RelationUpdateManyWithoutGoodsInput>;
  name?: InputMaybe<Scalars["String"]>;
  official_barcode?: InputMaybe<Scalars["String"]>;
  official_price?: InputMaybe<Scalars["Float"]>;
  type?: InputMaybe<TypeOfGoods>;
  type_id?: InputMaybe<Scalars["Int"]>;
  weight?: InputMaybe<Scalars["Int"]>;
}

export class GoodsWhereInput {
  AND?: InputMaybe<Array<GoodsWhereInput>>;
  NOT?: InputMaybe<Array<GoodsWhereInput>>;
  OR?: InputMaybe<Array<GoodsWhereInput>>;
  ask_price?: InputMaybe<Scalars["Float"]>;
  ask_price__gt?: InputMaybe<Scalars["Float"]>;
  ask_price__gte?: InputMaybe<Scalars["Float"]>;
  ask_price__in?: InputMaybe<Array<Scalars["Float"]>>;
  ask_price__lt?: InputMaybe<Scalars["Float"]>;
  ask_price__lte?: InputMaybe<Scalars["Float"]>;
  ask_price__not?: InputMaybe<Scalars["Float"]>;
  ask_price__not_in?: InputMaybe<Array<Scalars["Float"]>>;
  ask_quantity?: InputMaybe<Scalars["Int"]>;
  ask_quantity__not?: InputMaybe<Scalars["Int"]>;
  category?: InputMaybe<CategoryOfGoods>;
  category__contains?: InputMaybe<CategoryOfGoods>;
  category__ends_with?: InputMaybe<CategoryOfGoods>;
  category__in?: InputMaybe<Array<CategoryOfGoods>>;
  category__not?: InputMaybe<CategoryOfGoods>;
  category__not_contains?: InputMaybe<CategoryOfGoods>;
  category__not_ends_with?: InputMaybe<CategoryOfGoods>;
  category__not_in?: InputMaybe<Array<CategoryOfGoods>>;
  category__not_starts_with?: InputMaybe<CategoryOfGoods>;
  category__starts_with?: InputMaybe<CategoryOfGoods>;
  commodity__lateral?: InputMaybe<Scalars["Boolean"]>;
  commodity__some?: InputMaybe<CommodityWhereInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  created_at__between?: InputMaybe<Array<Scalars["DateTime"]>>;
  created_at__gt?: InputMaybe<Scalars["DateTime"]>;
  created_at__gte?: InputMaybe<Scalars["DateTime"]>;
  created_at__lt?: InputMaybe<Scalars["DateTime"]>;
  created_at__lte?: InputMaybe<Scalars["DateTime"]>;
  goods_artist_relation__lateral?: InputMaybe<Scalars["Boolean"]>;
  goods_artist_relation__some?: InputMaybe<Goods_Artist_RelationWhereInput>;
  goods_company_relation__lateral?: InputMaybe<Scalars["Boolean"]>;
  goods_company_relation__some?: InputMaybe<Goods_Company_RelationWhereInput>;
  goods_id?: InputMaybe<Scalars["ID"]>;
  goods_id__gt?: InputMaybe<Scalars["ID"]>;
  goods_id__gte?: InputMaybe<Scalars["ID"]>;
  goods_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  goods_id__lt?: InputMaybe<Scalars["ID"]>;
  goods_id__lte?: InputMaybe<Scalars["ID"]>;
  goods_id__not_in?: InputMaybe<Array<Scalars["ID"]>>;
  goods_structure__lateral?: InputMaybe<Scalars["Boolean"]>;
  goods_structure__some?: InputMaybe<Goods_StructureWhereInput>;
  goods_subdata_unique__lateral?: InputMaybe<Scalars["Boolean"]>;
  goods_subdata_unique__some?: InputMaybe<Goods_Subdata_UniqueWhereInput>;
  goods_title__lateral?: InputMaybe<Scalars["Boolean"]>;
  goods_title__some?: InputMaybe<Goods_TitleWhereInput>;
  goods_type__some?: InputMaybe<Goods_TypeWhereInput>;
  goods_url__lateral?: InputMaybe<Scalars["Boolean"]>;
  goods_url__some?: InputMaybe<Goods_UrlWhereInput>;
  is_collection?: InputMaybe<Scalars["Boolean"]>;
  is_collection__not?: InputMaybe<Scalars["Boolean"]>;
  is_sale?: InputMaybe<Scalars["Boolean"]>;
  is_sale__not?: InputMaybe<Scalars["Boolean"]>;
  metadata_goods_relation__lateral?: InputMaybe<Scalars["Boolean"]>;
  metadata_goods_relation__some?: InputMaybe<Goods_Artist_RelationWhereInput>;
  name?: InputMaybe<Scalars["String"]>;
  name__contains?: InputMaybe<Scalars["String"]>;
  name__ends_with?: InputMaybe<Scalars["String"]>;
  name__in?: InputMaybe<Array<Scalars["String"]>>;
  name__not?: InputMaybe<Scalars["String"]>;
  name__not_contains?: InputMaybe<Scalars["String"]>;
  name__not_ends_with?: InputMaybe<Scalars["String"]>;
  name__not_in?: InputMaybe<Array<Scalars["String"]>>;
  name__not_starts_with?: InputMaybe<Scalars["String"]>;
  name__starts_with?: InputMaybe<Scalars["String"]>;
  official_barcode?: InputMaybe<Scalars["String"]>;
  official_barcode__contains?: InputMaybe<Scalars["String"]>;
  official_barcode__ends_with?: InputMaybe<Scalars["String"]>;
  official_barcode__exist?: InputMaybe<Scalars["Boolean"]>;
  official_barcode__in?: InputMaybe<Array<Scalars["String"]>>;
  official_barcode__not?: InputMaybe<Scalars["String"]>;
  official_barcode__not_contains?: InputMaybe<Scalars["String"]>;
  official_barcode__not_ends_with?: InputMaybe<Scalars["String"]>;
  official_barcode__not_in?: InputMaybe<Array<Scalars["String"]>>;
  official_barcode__not_starts_with?: InputMaybe<Scalars["String"]>;
  official_barcode__starts_with?: InputMaybe<Scalars["String"]>;
  official_price?: InputMaybe<Scalars["Float"]>;
  official_price__gt?: InputMaybe<Scalars["Float"]>;
  official_price__gte?: InputMaybe<Scalars["Float"]>;
  official_price__in?: InputMaybe<Array<Scalars["Float"]>>;
  official_price__lt?: InputMaybe<Scalars["Float"]>;
  official_price__lte?: InputMaybe<Scalars["Float"]>;
  official_price__not?: InputMaybe<Scalars["Float"]>;
  official_price__not_in?: InputMaybe<Array<Scalars["Float"]>>;
  type?: InputMaybe<TypeOfGoods>;
  type__contains?: InputMaybe<TypeOfGoods>;
  type__ends_with?: InputMaybe<TypeOfGoods>;
  type__in?: InputMaybe<Array<TypeOfGoods>>;
  type__not?: InputMaybe<TypeOfGoods>;
  type__not_contains?: InputMaybe<TypeOfGoods>;
  type__not_ends_with?: InputMaybe<TypeOfGoods>;
  type__not_in?: InputMaybe<Array<TypeOfGoods>>;
  type__not_starts_with?: InputMaybe<TypeOfGoods>;
  type__starts_with?: InputMaybe<TypeOfGoods>;
  type_id?: InputMaybe<Scalars["Int"]>;
  type_id__gt?: InputMaybe<Scalars["Int"]>;
  type_id__gte?: InputMaybe<Scalars["Int"]>;
  type_id__in?: InputMaybe<Array<Scalars["Int"]>>;
  type_id__lt?: InputMaybe<Scalars["Int"]>;
  type_id__lte?: InputMaybe<Scalars["Int"]>;
  type_id__not?: InputMaybe<Scalars["Int"]>;
  type_id__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  weight?: InputMaybe<Scalars["Int"]>;
  weight__gt?: InputMaybe<Scalars["Int"]>;
  weight__gte?: InputMaybe<Scalars["Int"]>;
  weight__in?: InputMaybe<Array<Scalars["Int"]>>;
  weight__lt?: InputMaybe<Scalars["Int"]>;
  weight__lte?: InputMaybe<Scalars["Int"]>;
  weight__not?: InputMaybe<Scalars["Int"]>;
  weight__not_in?: InputMaybe<Array<Scalars["Int"]>>;
}

export class GoodsWhereUniqueInput {
  goods_id?: InputMaybe<Scalars["ID"]>;
}

/**
 * {
 *   "name":"goods_artist_relation",
 *   "id": "id",
 *   "goods": { "fk" : "goods_id" , "pk" : "goods_id" },
 *   "artist": { "fk" : "artist_id" , "pk" : "artist_id" }
 * }
 */
export class Goods_Artist_Relation {
  Goods: Array<Maybe<Goods>>;
  artist: Array<Maybe<Artist>>;
  artist_id?: Maybe<Scalars["ID"]>;
  exposure_order: Scalars["Int"];
  goods: Array<Maybe<Goods>>;
  goods_id?: Maybe<Scalars["ID"]>;
  id: Scalars["UUID"];
}

export class Goods_Artist_RelationCreateInput {
  artist: ArtistChildInput;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  goods: GoodsChildInput;
}

export class Goods_Artist_RelationCreateManyWithoutGoodsInput {
  create?: InputMaybe<Array<Goods_Artist_RelationCreateWithoutGoodsInput>>;
}

export class Goods_Artist_RelationCreateWithoutGoodsInput {
  artist: ArtistChildInput;
  exposure_order?: InputMaybe<Scalars["Int"]>;
}

export enum Goods_Artist_RelationOrderByInput {
  ArtistIdAsc = "artist_id__ASC",
  ArtistIdDesc = "artist_id__DESC",
  CreatedAtAsc = "createdAt__ASC",
  CreatedAtDesc = "createdAt__DESC",
  ExposureOrderAsc = "exposure_order__ASC",
  ExposureOrderDesc = "exposure_order__DESC",
  GoodsIdAsc = "goods_id__ASC",
  GoodsIdDesc = "goods_id__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  UpdatedAtAsc = "updatedAt__ASC",
  UpdatedAtDesc = "updatedAt__DESC"
}

export class Goods_Artist_RelationUpdateInput {
  artist?: InputMaybe<ArtistChildInput>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  goods?: InputMaybe<GoodsChildInput>;
}

export class Goods_Artist_RelationUpdateManyWithoutGoodsInput {
  create?: InputMaybe<Array<Goods_Artist_RelationCreateWithoutGoodsInput>>;
  update?: InputMaybe<Array<Goods_Artist_RelationUpdateWithWhereUniqueWithoutGoodsInput>>;
}

export class Goods_Artist_RelationUpdateWhereInput {
  artist_id?: InputMaybe<Scalars["ID"]>;
  goods_id?: InputMaybe<Scalars["ID"]>;
  id?: InputMaybe<Scalars["UUID"]>;
}

export class Goods_Artist_RelationUpdateWithWhereUniqueWithoutGoodsInput {
  data: Goods_Artist_RelationUpdateWithoutGoodsInput;
  where: UuidWhereUniqueInput;
}

export class Goods_Artist_RelationUpdateWithoutGoodsInput {
  artist?: InputMaybe<ArtistChildInput>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
}

export class Goods_Artist_RelationWhereInput {
  AND?: InputMaybe<Array<Goods_Artist_RelationWhereInput>>;
  NOT?: InputMaybe<Array<Goods_Artist_RelationWhereInput>>;
  OR?: InputMaybe<Array<Goods_Artist_RelationWhereInput>>;
  artist__lateral?: InputMaybe<Scalars["Boolean"]>;
  artist__some?: InputMaybe<ArtistWhereInput>;
  artist_id?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  artist_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  artist_id__not_include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  exposure_order__gt?: InputMaybe<Scalars["Int"]>;
  exposure_order__gte?: InputMaybe<Scalars["Int"]>;
  exposure_order__in?: InputMaybe<Array<Scalars["Int"]>>;
  exposure_order__lt?: InputMaybe<Scalars["Int"]>;
  exposure_order__lte?: InputMaybe<Scalars["Int"]>;
  exposure_order__not?: InputMaybe<Scalars["Int"]>;
  exposure_order__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  goods__lateral?: InputMaybe<Scalars["Boolean"]>;
  goods__some?: InputMaybe<GoodsWhereInput>;
  goods_id?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  goods_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  goods_id__not_include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__exist?: InputMaybe<Scalars["Boolean"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  id__not?: InputMaybe<Scalars["UUID"]>;
  id__not_in?: InputMaybe<Array<Scalars["UUID"]>>;
}

/**
 * {
 *   "name":"goods_company_relation",
 *   "id": "id",
 *   "goods": { "fk" : "goods_id" , "pk" : "goods_id" },
 *   "company": { "fk" : "company_id" , "pk" : "company_id" }
 * }
 */
export class Goods_Company_Relation {
  company?: Maybe<Array<Maybe<Company>>>;
  company_id?: Maybe<Scalars["ID"]>;
  exposure_order: Scalars["Int"];
  goods: Array<Maybe<Goods>>;
  goods_id?: Maybe<Scalars["ID"]>;
  id: Scalars["UUID"];
}

export class Goods_Company_RelationCreateManyWithoutGoodsInput {
  create?: InputMaybe<Array<Goods_Company_RelationCreateWithoutGoodsInput>>;
}

export class Goods_Company_RelationCreateWithoutGoodsInput {
  company: CompanyChildInput;
  exposure_order?: InputMaybe<Scalars["Int"]>;
}

export enum Goods_Company_RelationOrderByInput {
  CreatedAtAsc = "createdAt__ASC",
  CreatedAtDesc = "createdAt__DESC",
  ExposureOrderAsc = "exposure_order__ASC",
  ExposureOrderDesc = "exposure_order__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  UpdatedAtAsc = "updatedAt__ASC",
  UpdatedAtDesc = "updatedAt__DESC"
}

export class Goods_Company_RelationUpdateInput {
  company?: InputMaybe<CompanyChildInput>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  goods?: InputMaybe<GoodsChildInput>;
}

export class Goods_Company_RelationUpdateManyWithoutGoodsInput {
  create?: InputMaybe<Array<Goods_Company_RelationCreateWithoutGoodsInput>>;
  update?: InputMaybe<Array<Goods_Company_RelationUpdateWithWhereUniqueWithoutGoodsInput>>;
}

export class Goods_Company_RelationUpdateWithWhereUniqueWithoutGoodsInput {
  data: Goods_Company_RelationUpdateWithoutGoodsInput;
  where: UuidWhereUniqueInput;
}

export class Goods_Company_RelationUpdateWithoutCompanyInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  goods?: InputMaybe<GoodsChildInput>;
}

export class Goods_Company_RelationUpdateWithoutGoodsInput {
  company?: InputMaybe<CompanyChildInput>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
}

export class Goods_Company_RelationWhereInput {
  AND?: InputMaybe<Array<Goods_Company_RelationWhereInput>>;
  NOT?: InputMaybe<Array<Goods_Company_RelationWhereInput>>;
  OR?: InputMaybe<Array<Goods_Company_RelationWhereInput>>;
  company__lateral?: InputMaybe<Scalars["Boolean"]>;
  company__some?: InputMaybe<CompanyWhereInput>;
  company_id?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  company_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  company_id__not_include?: InputMaybe<Array<Scalars["ID"]>>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  exposure_order__gt?: InputMaybe<Scalars["Int"]>;
  exposure_order__gte?: InputMaybe<Scalars["Int"]>;
  exposure_order__in?: InputMaybe<Array<Scalars["Int"]>>;
  exposure_order__lt?: InputMaybe<Scalars["Int"]>;
  exposure_order__lte?: InputMaybe<Scalars["Int"]>;
  exposure_order__not?: InputMaybe<Scalars["Int"]>;
  exposure_order__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  goods__lateral?: InputMaybe<Scalars["Boolean"]>;
  goods__some?: InputMaybe<GoodsWhereInput>;
  goods_id?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  goods_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  goods_id__not_include?: InputMaybe<Array<Scalars["ID"]>>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__contains?: InputMaybe<Scalars["UUID"]>;
  id__exist?: InputMaybe<Scalars["Boolean"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  id__not?: InputMaybe<Scalars["UUID"]>;
  id__not_contains?: InputMaybe<Scalars["UUID"]>;
  id__not_in?: InputMaybe<Array<Scalars["UUID"]>>;
}

/**
 * {
 *   "name":"goods_structure",
 *   "id": "id",
 *   "type": "Int",
 *   "goods": { "fk" : "goods_id" , "pk" : "goods_id" },
 *   "list": { "fk" : "root_id" , "pk" : "root_id" ,"name": "goods_structure"},
 *   "child": { "fk" : "parent_id" , "pk" : "id" },
 *   "parent": { "fk" : "id" , "pk" : "parent_id" }
 * }
 */
export class Goods_Structure {
  child?: Maybe<Array<Maybe<Goods_Structure>>>;
  depth: Scalars["Int"];
  goods: Array<Maybe<Goods>>;
  goods_id?: Maybe<Scalars["ID"]>;
  id: Scalars["ID"];
  inner_order: Scalars["Int"];
  list?: Maybe<Array<Maybe<Goods_Structure>>>;
  parent?: Maybe<Array<Maybe<Goods_Structure>>>;
  parent_id: Scalars["ID"];
  root_id?: Maybe<Scalars["ID"]>;
}

/**
 * {
 *   "name":"goods_structure",
 *   "id": "id",
 *   "type": "Int",
 *   "goods": { "fk" : "goods_id" , "pk" : "goods_id" },
 *   "list": { "fk" : "root_id" , "pk" : "root_id" ,"name": "goods_structure"},
 *   "child": { "fk" : "parent_id" , "pk" : "id" },
 *   "parent": { "fk" : "id" , "pk" : "parent_id" }
 * }
 */
export class Goods_StructureChildArgs {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Goods_StructureOrderByInput>>>;
  where?: InputMaybe<Goods_StructureWhereInput>;
}

/**
 * {
 *   "name":"goods_structure",
 *   "id": "id",
 *   "type": "Int",
 *   "goods": { "fk" : "goods_id" , "pk" : "goods_id" },
 *   "list": { "fk" : "root_id" , "pk" : "root_id" ,"name": "goods_structure"},
 *   "child": { "fk" : "parent_id" , "pk" : "id" },
 *   "parent": { "fk" : "id" , "pk" : "parent_id" }
 * }
 */
export class Goods_StructureListArgs {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Goods_StructureOrderByInput>>>;
  where?: InputMaybe<Goods_StructureWhereInput>;
}

export class Goods_StructureCreateInput {
  booking_order?: InputMaybe<Scalars["Int"]>;
  inner_order?: InputMaybe<Scalars["Int"]>;
  parent_id: Scalars["Int"];
}

export class Goods_StructureCreateManyInput {
  create?: InputMaybe<Array<Goods_StructureCreateInput>>;
}

export enum Goods_StructureOrderByInput {
  CreatedAtAsc = "createdAt__ASC",
  CreatedAtDesc = "createdAt__DESC",
  DepthAsc = "depth__ASC",
  DepthDesc = "depth__DESC",
  GoodsIdAsc = "goods_id__ASC",
  GoodsIdDesc = "goods_id__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  InnerOrderAsc = "inner_order__ASC",
  InnerOrderDesc = "inner_order__DESC",
  ParentIdAsc = "parent_id__ASC",
  ParentIdDesc = "parent_id__DESC",
  RootIdAsc = "root_id__ASC",
  RootIdDesc = "root_id__DESC",
  UpdatedAtAsc = "updatedAt__ASC",
  UpdatedAtDesc = "updatedAt__DESC"
}

export class Goods_StructureUpdateInput {
  booking_order?: InputMaybe<Scalars["Int"]>;
  depth?: InputMaybe<Scalars["Int"]>;
  goods?: InputMaybe<GoodsChildInput>;
  inner_order?: InputMaybe<Scalars["Int"]>;
  parent_id?: InputMaybe<Scalars["Int"]>;
  root_id?: InputMaybe<Scalars["Int"]>;
}

export class Goods_StructureUpdateManyWithoutGoodsInput {
  create?: InputMaybe<Array<Goods_StructureCreateInput>>;
  update?: InputMaybe<Array<Goods_StructureUpdateWithWhereUniqueInput>>;
}

export class Goods_StructureUpdateWithWhereUniqueInput {
  data: Goods_StructureUpdateWithoutGoodsInput;
  where: Goods_StructureWhereUniqueInput;
}

export class Goods_StructureUpdateWithoutGoodsInput {
  booking_order?: InputMaybe<Scalars["Int"]>;
  depth?: InputMaybe<Scalars["Int"]>;
  inner_order?: InputMaybe<Scalars["Int"]>;
  parent_id?: InputMaybe<Scalars["Int"]>;
  root_id?: InputMaybe<Scalars["Int"]>;
}

export class Goods_StructureWhereInput {
  AND?: InputMaybe<Array<Goods_StructureWhereInput>>;
  NOT?: InputMaybe<Array<Goods_StructureWhereInput>>;
  OR?: InputMaybe<Array<Goods_StructureWhereInput>>;
  depth?: InputMaybe<Scalars["Int"]>;
  depth__gt?: InputMaybe<Scalars["Int"]>;
  depth__gte?: InputMaybe<Scalars["Int"]>;
  depth__in?: InputMaybe<Array<Scalars["Int"]>>;
  depth__lt?: InputMaybe<Scalars["Int"]>;
  depth__lte?: InputMaybe<Scalars["Int"]>;
  depth__not?: InputMaybe<Scalars["Int"]>;
  depth__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  goods__lateral?: InputMaybe<Scalars["Boolean"]>;
  goods__some?: InputMaybe<GoodsWhereInput>;
  goods_id?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  goods_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  goods_id__not_include?: InputMaybe<Array<Scalars["ID"]>>;
  id?: InputMaybe<Scalars["ID"]>;
  id__in?: InputMaybe<Array<Scalars["ID"]>>;
  inner_order?: InputMaybe<Scalars["Int"]>;
  inner_order__gt?: InputMaybe<Scalars["Int"]>;
  inner_order__gte?: InputMaybe<Scalars["Int"]>;
  inner_order__in?: InputMaybe<Array<Scalars["Int"]>>;
  inner_order__lt?: InputMaybe<Scalars["Int"]>;
  inner_order__lte?: InputMaybe<Scalars["Int"]>;
  inner_order__not?: InputMaybe<Scalars["Int"]>;
  inner_order__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  list__lateral?: InputMaybe<Scalars["Boolean"]>;
  list__some?: InputMaybe<Goods_StructureWhereInput>;
  parent_id?: InputMaybe<Scalars["Int"]>;
  parent_id__gt?: InputMaybe<Scalars["Int"]>;
  parent_id__gte?: InputMaybe<Scalars["Int"]>;
  parent_id__in?: InputMaybe<Array<Scalars["Int"]>>;
  parent_id__lt?: InputMaybe<Scalars["Int"]>;
  parent_id__lte?: InputMaybe<Scalars["Int"]>;
  parent_id__not?: InputMaybe<Scalars["Int"]>;
  parent_id__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  root_id?: InputMaybe<Scalars["Int"]>;
  root_id__gt?: InputMaybe<Scalars["Int"]>;
  root_id__gte?: InputMaybe<Scalars["Int"]>;
  root_id__in?: InputMaybe<Array<Scalars["Int"]>>;
  root_id__lt?: InputMaybe<Scalars["Int"]>;
  root_id__lte?: InputMaybe<Scalars["Int"]>;
  root_id__not?: InputMaybe<Scalars["Int"]>;
  root_id__not_in?: InputMaybe<Array<Scalars["Int"]>>;
}

export class Goods_StructureWhereUniqueInput {
  id?: InputMaybe<Scalars["ID"]>;
}

export class Goods_Subdata_Unique {
  commodity?: Maybe<Array<Maybe<Commodity>>>;
  goods: Array<Maybe<Goods>>;
  goods_id?: Maybe<Scalars["ID"]>;
  id: Scalars["UUID"];
  type_subdata_category: Scalars["String"];
  type_subdata_field: Scalars["String"];
  value: Scalars["String"];
}

export class Goods_Subdata_UniqueCommodityArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<CommodityOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CommodityWhereInput>;
}

export class Goods_Subdata_UniqueChildInput {
  connect?: InputMaybe<Goods_Subdata_UniqueWhereUniqueInput>;
}

export class Goods_Subdata_UniqueCreateInput {
  type_subdata_category: Scalars["String"];
  type_subdata_field: Scalars["String"];
  value: Scalars["String"];
}

export class Goods_Subdata_UniqueCreateManyInput {
  create?: InputMaybe<Array<Goods_Subdata_UniqueCreateInput>>;
}

export enum Goods_Subdata_UniqueOrderByInput {
  CreatedAtAsc = "createdAt__ASC",
  CreatedAtDesc = "createdAt__DESC",
  GoodsIdAsc = "goods_id__ASC",
  GoodsIdDesc = "goods_id__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  TypeSubdataCategoryAsc = "type_subdata_category__ASC",
  TypeSubdataCategoryDesc = "type_subdata_category__DESC",
  TypeSubdataFieldAsc = "type_subdata_field__ASC",
  TypeSubdataFieldDesc = "type_subdata_field__DESC",
  UpdatedAtAsc = "updatedAt__ASC",
  UpdatedAtDesc = "updatedAt__DESC",
  ValueAsc = "value__ASC",
  ValueDesc = "value__DESC"
}

export class Goods_Subdata_UniqueUpdateInput {
  goods?: InputMaybe<GoodsChildInput>;
  type_subdata_category?: InputMaybe<Scalars["String"]>;
  type_subdata_field?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
}

export class Goods_Subdata_UniqueUpdateManyInput {
  create?: InputMaybe<Array<Goods_Subdata_UniqueCreateInput>>;
  update?: InputMaybe<Array<Goods_Subdata_UniqueUpdateWithWhereUniqueInput>>;
}

export class Goods_Subdata_UniqueUpdateWithWhereUniqueInput {
  data: Goods_Subdata_UniqueUpdateWithoutGoodsInput;
  where: UuidWhereUniqueInput;
}

export class Goods_Subdata_UniqueUpdateWithoutGoodsInput {
  type_subdata_category?: InputMaybe<Scalars["String"]>;
  type_subdata_field?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
}

export class Goods_Subdata_UniqueWhereInput {
  AND?: InputMaybe<Array<Goods_Subdata_UniqueWhereInput>>;
  NOT?: InputMaybe<Array<Goods_Subdata_UniqueWhereInput>>;
  OR?: InputMaybe<Array<Goods_Subdata_UniqueWhereInput>>;
  commodity__lateral?: InputMaybe<Scalars["Boolean"]>;
  commodity__some?: InputMaybe<CommodityWhereInput>;
  goods__lateral?: InputMaybe<Scalars["Boolean"]>;
  goods__some?: InputMaybe<GoodsWhereInput>;
  goods_id?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  goods_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  goods_id__not_include?: InputMaybe<Array<Scalars["ID"]>>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  type_subdata_category?: InputMaybe<Scalars["String"]>;
  type_subdata_category__contains?: InputMaybe<Scalars["String"]>;
  type_subdata_category__ends_with?: InputMaybe<Scalars["String"]>;
  type_subdata_category__in?: InputMaybe<Array<Scalars["String"]>>;
  type_subdata_category__not?: InputMaybe<Scalars["String"]>;
  type_subdata_category__not_contains?: InputMaybe<Scalars["String"]>;
  type_subdata_category__not_ends_with?: InputMaybe<Scalars["String"]>;
  type_subdata_category__not_in?: InputMaybe<Array<Scalars["String"]>>;
  type_subdata_category__not_starts_with?: InputMaybe<Scalars["String"]>;
  type_subdata_category__starts_with?: InputMaybe<Scalars["String"]>;
  type_subdata_field?: InputMaybe<Scalars["String"]>;
  type_subdata_field__contains?: InputMaybe<Scalars["String"]>;
  type_subdata_field__ends_with?: InputMaybe<Scalars["String"]>;
  type_subdata_field__in?: InputMaybe<Array<Scalars["String"]>>;
  type_subdata_field__not?: InputMaybe<Scalars["String"]>;
  type_subdata_field__not_contains?: InputMaybe<Scalars["String"]>;
  type_subdata_field__not_ends_with?: InputMaybe<Scalars["String"]>;
  type_subdata_field__not_in?: InputMaybe<Array<Scalars["String"]>>;
  type_subdata_field__not_starts_with?: InputMaybe<Scalars["String"]>;
  type_subdata_field__starts_with?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
  value__contains?: InputMaybe<Scalars["String"]>;
  value__ends_with?: InputMaybe<Scalars["String"]>;
  value__in?: InputMaybe<Array<Scalars["String"]>>;
  value__json_contains?: InputMaybe<Scalars["String"]>;
  value__json_filter?: InputMaybe<JsonFilter>;
  value__not?: InputMaybe<Scalars["String"]>;
  value__not_contains?: InputMaybe<Scalars["String"]>;
  value__not_ends_with?: InputMaybe<Scalars["String"]>;
  value__not_in?: InputMaybe<Array<Scalars["String"]>>;
  value__not_starts_with?: InputMaybe<Scalars["String"]>;
  value__starts_with?: InputMaybe<Scalars["String"]>;
}

export class Goods_Subdata_UniqueWhereUniqueInput {
  id?: InputMaybe<Scalars["UUID"]>;
}

/**
 * {
 *   "name":"goods_title",
 *   "id": "id",
 *   "goods": { "fk" : "goods_id" , "pk" : "goods_id" },
 *   "language": { "fk" : "language_code" , "pk" : "language_code" }
 * }
 */
export class Goods_Title {
  exposure_order: Scalars["Int"];
  goods: Array<Maybe<Goods>>;
  goods_id?: Maybe<Scalars["ID"]>;
  id: Scalars["UUID"];
  language?: Maybe<Array<Maybe<Language>>>;
  language_code?: Maybe<Scalars["ID"]>;
  tsvec_words?: Maybe<Scalars["String"]>;
  type_title: Scalars["String"];
  value: Scalars["String"];
}

/**
 * {
 *   "name":"goods_title",
 *   "id": "id",
 *   "goods": { "fk" : "goods_id" , "pk" : "goods_id" },
 *   "language": { "fk" : "language_code" , "pk" : "language_code" }
 * }
 */
export class Goods_TitleLanguageArgs {
  where?: InputMaybe<LanguageWhereUniqueInput>;
}

export class Goods_TitleCreateManyWithoutGoodsInput {
  create?: InputMaybe<Array<Goods_TitleCreateWithoutGoodsInput>>;
}

export class Goods_TitleCreateWithoutGoodsInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  language: LanguageChildInput;
  type_title: Scalars["String"];
  value: Scalars["String"];
}

export enum Goods_TitleOrderByInput {
  CreatedAtAsc = "createdAt__ASC",
  CreatedAtDesc = "createdAt__DESC",
  ExposureOrderAsc = "exposure_order__ASC",
  ExposureOrderDesc = "exposure_order__DESC",
  GoodsIdAsc = "goods_id__ASC",
  GoodsIdDesc = "goods_id__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  Rand = "rand",
  TypeTitleAsc = "type_title__ASC",
  TypeTitleDesc = "type_title__DESC",
  UpdatedAtAsc = "updatedAt__ASC",
  UpdatedAtDesc = "updatedAt__DESC",
  ValueAsc = "value__ASC",
  ValueDesc = "value__DESC",
  ValueSimilarity = "value__SIMILARITY"
}

export class Goods_TitleUpdateInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  goods?: InputMaybe<GoodsChildInput>;
  language?: InputMaybe<LanguageChildInput>;
  type_title?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
}

export class Goods_TitleUpdateManyWithoutGoodsInput {
  create?: InputMaybe<Array<Goods_TitleCreateWithoutGoodsInput>>;
  update?: InputMaybe<Array<Goods_TitleUpdateWithWhereUniqueWithoutGoodsInput>>;
}

export class Goods_TitleUpdateWithWhereUniqueWithoutGoodsInput {
  data: Goods_TitleUpdateWithoutGoodsInput;
  where: UuidWhereUniqueInput;
}

export class Goods_TitleUpdateWithoutGoodsInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  language?: InputMaybe<LanguageChildInput>;
  type_title?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
}

export class Goods_TitleWhereInput {
  AND?: InputMaybe<Array<Goods_TitleWhereInput>>;
  NOT?: InputMaybe<Array<Goods_TitleWhereInput>>;
  OR?: InputMaybe<Array<Goods_TitleWhereInput>>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  exposure_order__gt?: InputMaybe<Scalars["Int"]>;
  exposure_order__gte?: InputMaybe<Scalars["Int"]>;
  exposure_order__in?: InputMaybe<Array<Scalars["Int"]>>;
  exposure_order__lt?: InputMaybe<Scalars["Int"]>;
  exposure_order__lte?: InputMaybe<Scalars["Int"]>;
  exposure_order__not?: InputMaybe<Scalars["Int"]>;
  exposure_order__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  goods__lateral?: InputMaybe<Scalars["Boolean"]>;
  goods__some?: InputMaybe<GoodsWhereInput>;
  goods_id?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  goods_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  goods_id__not_include?: InputMaybe<Array<Scalars["ID"]>>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  language__lateral?: InputMaybe<Scalars["Boolean"]>;
  language__some?: InputMaybe<LanguageWhereInput>;
  language_code?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  language_code__in?: InputMaybe<Array<Scalars["ID"]>>;
  language_code__not_include?: InputMaybe<Array<Scalars["ID"]>>;
  selfByGoods__some?: InputMaybe<Goods_TitleWhereInput>;
  tsvec_words__search?: InputMaybe<Scalars["String"]>;
  tsvec_words__search_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  type_title?: InputMaybe<Scalars["String"]>;
  type_title__contains?: InputMaybe<Scalars["String"]>;
  type_title__ends_with?: InputMaybe<Scalars["String"]>;
  type_title__in?: InputMaybe<Array<Scalars["String"]>>;
  type_title__not?: InputMaybe<Scalars["String"]>;
  type_title__not_contains?: InputMaybe<Scalars["String"]>;
  type_title__not_ends_with?: InputMaybe<Scalars["String"]>;
  type_title__not_in?: InputMaybe<Array<Scalars["String"]>>;
  type_title__not_starts_with?: InputMaybe<Scalars["String"]>;
  type_title__starts_with?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
  value__contains?: InputMaybe<Scalars["String"]>;
  value__ends_with?: InputMaybe<Scalars["String"]>;
  value__in?: InputMaybe<Array<Scalars["String"]>>;
  value__not?: InputMaybe<Scalars["String"]>;
  value__not_contains?: InputMaybe<Scalars["String"]>;
  value__not_ends_with?: InputMaybe<Scalars["String"]>;
  value__not_in?: InputMaybe<Array<Scalars["String"]>>;
  value__not_starts_with?: InputMaybe<Scalars["String"]>;
  value__starts_with?: InputMaybe<Scalars["String"]>;
}

export class Goods_Type {
  category: Scalars["String"];
  cover_url?: Maybe<Scalars["String"]>;
  display_category?: Maybe<Scalars["String"]>;
  display_name?: Maybe<Scalars["String"]>;
  display_type?: Maybe<Scalars["String"]>;
  goods: Array<Maybe<Goods>>;
  id: Scalars["Int"];
  self?: Maybe<Array<Maybe<Goods_Type>>>;
  tag?: Maybe<Scalars["String"]>;
  type: Scalars["String"];
}

export class Goods_TypeSelfArgs {
  first?: InputMaybe<Scalars["Int"]>;
}

export enum Goods_TypeOrderByInput {
  CategoryAsc = "category__ASC",
  CategoryDesc = "category__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  TypeAsc = "type__ASC",
  TypeDesc = "type__DESC"
}

export class Goods_TypeWhereInput {
  AND?: InputMaybe<Array<Goods_UrlWhereInput>>;
  NOT?: InputMaybe<Array<Goods_UrlWhereInput>>;
  OR?: InputMaybe<Array<Goods_UrlWhereInput>>;
  category?: InputMaybe<Scalars["String"]>;
  category__contains?: InputMaybe<Scalars["String"]>;
  category__ends_with?: InputMaybe<Scalars["String"]>;
  category__in?: InputMaybe<Array<Scalars["String"]>>;
  category__not?: InputMaybe<Scalars["String"]>;
  category__not_contains?: InputMaybe<Scalars["String"]>;
  category__not_ends_with?: InputMaybe<Scalars["String"]>;
  category__not_in?: InputMaybe<Array<Scalars["String"]>>;
  category__not_starts_with?: InputMaybe<Scalars["String"]>;
  category__starts_with?: InputMaybe<Scalars["String"]>;
  goods__some?: InputMaybe<GoodsWhereInput>;
  id?: InputMaybe<Scalars["Int"]>;
  id__exist?: InputMaybe<Scalars["Boolean"]>;
  id__in?: InputMaybe<Array<Scalars["Int"]>>;
  id__not?: InputMaybe<Scalars["Int"]>;
  id__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  tag?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  type__contains?: InputMaybe<Scalars["String"]>;
  type__ends_with?: InputMaybe<Scalars["String"]>;
  type__in?: InputMaybe<Array<Scalars["String"]>>;
  type__not?: InputMaybe<Scalars["String"]>;
  type__not_contains?: InputMaybe<Scalars["String"]>;
  type__not_ends_with?: InputMaybe<Scalars["String"]>;
  type__not_in?: InputMaybe<Array<Scalars["String"]>>;
  type__not_starts_with?: InputMaybe<Scalars["String"]>;
  type__starts_with?: InputMaybe<Scalars["String"]>;
}

/**
 * {
 *   "name":"goods_url",
 *   "id": "id",
 *   "goods": { "fk" : "goods_id" , "pk" : "goods_id" },
 *   "list": { "fk" : "url" , "pk" : "url" }
 * }
 */
export class Goods_Url {
  exposure_order: Scalars["Int"];
  goods: Array<Maybe<Goods>>;
  goods_id?: Maybe<Scalars["ID"]>;
  id: Scalars["UUID"];
  list?: Maybe<Array<Maybe<Goods_Url>>>;
  type_url: Scalars["String"];
  url: Scalars["String"];
}

/**
 * {
 *   "name":"goods_url",
 *   "id": "id",
 *   "goods": { "fk" : "goods_id" , "pk" : "goods_id" },
 *   "list": { "fk" : "url" , "pk" : "url" }
 * }
 */
export class Goods_UrlListArgs {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<Goods_UrlOrderByInput>>;
  where?: InputMaybe<Goods_UrlWhereInput>;
}

export class Goods_UrlCreateManyWithoutGoodsInput {
  create?: InputMaybe<Array<Goods_UrlCreateWithoutGoodsInput>>;
}

export class Goods_UrlCreateWithoutGoodsInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  type_url: Scalars["String"];
  url: Scalars["String"];
}

export enum Goods_UrlOrderByInput {
  ExposureOrderAsc = "exposure_order__ASC",
  ExposureOrderDesc = "exposure_order__DESC",
  GoodsIdAsc = "goods_id__ASC",
  GoodsIdDesc = "goods_id__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  TypeUrlAsc = "type_url__ASC",
  TypeUrlDesc = "type_url__DESC",
  UrlAsc = "url__ASC",
  UrlDesc = "url__DESC"
}

export class Goods_UrlUpdateInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  goods?: InputMaybe<GoodsChildInput>;
  type_url?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
}

export class Goods_UrlUpdateManyInput {
  create?: InputMaybe<Array<Goods_UrlCreateWithoutGoodsInput>>;
  update?: InputMaybe<Array<Goods_UrlUpdateWithWhereUniqueWithoutGoodsInput>>;
}

export class Goods_UrlUpdateWithWhereUniqueWithoutGoodsInput {
  data: Goods_UrlUpdateWithoutArtistDataInput;
  where: UuidWhereUniqueInput;
}

export class Goods_UrlUpdateWithoutArtistDataInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  type_url?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
}

export class Goods_UrlWhereInput {
  AND?: InputMaybe<Array<Goods_UrlWhereInput>>;
  NOT?: InputMaybe<Array<Goods_UrlWhereInput>>;
  OR?: InputMaybe<Array<Goods_UrlWhereInput>>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  exposure_order__gt?: InputMaybe<Scalars["Int"]>;
  exposure_order__gte?: InputMaybe<Scalars["Int"]>;
  exposure_order__in?: InputMaybe<Array<Scalars["Int"]>>;
  exposure_order__lt?: InputMaybe<Scalars["Int"]>;
  exposure_order__lte?: InputMaybe<Scalars["Int"]>;
  exposure_order__not?: InputMaybe<Scalars["Int"]>;
  exposure_order__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  goods__lateral?: InputMaybe<Scalars["Boolean"]>;
  goods__some?: InputMaybe<GoodsWhereInput>;
  goods_id?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  goods_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  goods_id__not_include?: InputMaybe<Array<Scalars["ID"]>>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__exist?: InputMaybe<Scalars["Boolean"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  type_url?: InputMaybe<Scalars["String"]>;
  type_url__contains?: InputMaybe<Scalars["String"]>;
  type_url__ends_with?: InputMaybe<Scalars["String"]>;
  type_url__in?: InputMaybe<Array<Scalars["String"]>>;
  type_url__not?: InputMaybe<Scalars["String"]>;
  type_url__not_contains?: InputMaybe<Scalars["String"]>;
  type_url__not_ends_with?: InputMaybe<Scalars["String"]>;
  type_url__not_in?: InputMaybe<Array<Scalars["String"]>>;
  type_url__not_starts_with?: InputMaybe<Scalars["String"]>;
  type_url__starts_with?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
  url__contains?: InputMaybe<Scalars["String"]>;
  url__ends_with?: InputMaybe<Scalars["String"]>;
  url__in?: InputMaybe<Array<Scalars["String"]>>;
  url__not?: InputMaybe<Scalars["String"]>;
  url__not_contains?: InputMaybe<Scalars["String"]>;
  url__not_ends_with?: InputMaybe<Scalars["String"]>;
  url__not_in?: InputMaybe<Array<Scalars["String"]>>;
  url__not_starts_with?: InputMaybe<Scalars["String"]>;
  url__starts_with?: InputMaybe<Scalars["String"]>;
}

export class Grade {
  created_at: Scalars["DateTime"];
  grade: TrackGrade;
  id: Scalars["Int"];
  product_price: ProductPrice;
  product_price_id: Scalars["Int"];
  updated_at: Scalars["DateTime"];
}

export enum GradeOrderByInput {
  CreatedAtAsc = "created_at__ASC",
  CreatedAtDesc = "created_at__DESC",
  GradeAsc = "grade__ASC",
  GradeDesc = "grade__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC"
}

export class GradeWhereInput {
  grade?: InputMaybe<TrackGrade>;
  grade__in?: InputMaybe<Array<TrackGrade>>;
  id?: InputMaybe<Scalars["Int"]>;
  id__in?: InputMaybe<Array<Scalars["Int"]>>;
}

export class GrantedAccess {
  name?: Maybe<Scalars["String"]>;
  tables?: Maybe<Array<Maybe<GrantedTable>>>;
  user: Scalars["ID"];
}

export class GrantedAccessCounts {
  count?: Maybe<Scalars["Int"]>;
  valid_check?: Maybe<ValidType>;
}

export class GrantedTable {
  counts?: Maybe<Array<Maybe<GrantedAccessCounts>>>;
  table?: Maybe<Scalars["String"]>;
}

export enum IdOrderByInput {
  IdAsc = "id__ASC",
  IdDesc = "id__DESC"
}

export class IdUniqueInput {
  id?: InputMaybe<Scalars["UUID"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  id__not?: InputMaybe<Scalars["UUID"]>;
}

export class InsertInput {
  company_id?: InputMaybe<Scalars["ID"]>;
  filename: Scalars["String"];
  path?: InputMaybe<PathType>;
}

export class JsonFilter {
  key?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
}

export class JwtTokenSet {
  access_token: Scalars["String"];
  exp: Scalars["Int"];
  iat: Scalars["Int"];
  refresh_token: Scalars["String"];
}

/**
 * {
 *   "name":"language",
 *   "id": "language_code"
 * }
 */
export class Language {
  language_code: Scalars["ID"];
  language_title?: Maybe<Array<Maybe<Language_Title>>>;
  metadata_language_relation?: Maybe<Array<Metadata_Language_Relation>>;
  name?: Maybe<Scalars["String"]>;
  valid_check?: Maybe<ValidType>;
}

/**
 * {
 *   "name":"language",
 *   "id": "language_code"
 * }
 */
export class LanguageLanguage_TitleArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Language_TitleOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Language_TitleWhereInput>;
}

/**
 * {
 *   "name":"language",
 *   "id": "language_code"
 * }
 */
export class LanguageMetadata_Language_RelationArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Metadata_Language_RelationOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Metadata_Language_RelationWhereInput>;
}

export class LanguageChildInput {
  connect?: InputMaybe<LanguageWhereUniqueInput>;
}

export class LanguageCreateInput {
  language_code?: InputMaybe<Scalars["String"]>;
  language_title?: InputMaybe<Language_TitleCreateManyInput>;
  metadata_language_relation?: InputMaybe<Metadata_Language_RelationCreateManyWithoutLanguageInput>;
  name?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
}

export enum LanguageOrderByInput {
  CreatedAtAsc = "createdAt__ASC",
  CreatedAtDesc = "createdAt__DESC",
  LanguageCodeAsc = "language_code__ASC",
  LanguageCodeDesc = "language_code__DESC",
  NameAsc = "name__ASC",
  NameDesc = "name__DESC",
  UpdatedAtAsc = "updatedAt__ASC",
  UpdatedAtDesc = "updatedAt__DESC",
  ValidCheckAsc = "valid_check__ASC",
  ValidCheckDesc = "valid_check__DESC"
}

export class LanguageUpdateInput {
  _onlySearch?: InputMaybe<Scalars["Boolean"]>;
  language_title?: InputMaybe<Language_TitleUpdateManyWithoutLanguageInput>;
  metadata_language_relation?: InputMaybe<Metadata_Language_RelationUpdateManyWithoutLanguageInput>;
  name?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class LanguageWhereInput {
  AND?: InputMaybe<Array<LanguageWhereInput>>;
  NOT?: InputMaybe<Array<LanguageWhereInput>>;
  OR?: InputMaybe<Array<LanguageWhereInput>>;
  language_code?: InputMaybe<Scalars["ID"]>;
  language_code__in?: InputMaybe<Array<Scalars["ID"]>>;
  language_title__some?: InputMaybe<Language_TitleWhereInput>;
  metadata_language_relation__lateral?: InputMaybe<Scalars["Boolean"]>;
  metadata_language_relation__some?: InputMaybe<Metadata_Language_RelationWhereInput>;
  name?: InputMaybe<Scalars["String"]>;
  name__contains?: InputMaybe<Scalars["String"]>;
  name__ends_with?: InputMaybe<Scalars["String"]>;
  name__in?: InputMaybe<Array<Scalars["String"]>>;
  name__not?: InputMaybe<Scalars["String"]>;
  name__not_contains?: InputMaybe<Scalars["String"]>;
  name__not_ends_with?: InputMaybe<Scalars["String"]>;
  name__not_in?: InputMaybe<Array<Scalars["String"]>>;
  name__not_starts_with?: InputMaybe<Scalars["String"]>;
  name__starts_with?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
  valid_check__contains?: InputMaybe<ValidType>;
  valid_check__in?: InputMaybe<Array<ValidType>>;
  valid_check__not?: InputMaybe<ValidType>;
  valid_check__not_contains?: InputMaybe<ValidType>;
  valid_check__not_in?: InputMaybe<Array<ValidType>>;
}

export class LanguageWhereUniqueInput {
  language_code?: InputMaybe<Scalars["ID"]>;
}

/**
 * {
 *   "name":"language_title",
 *   "id": "id",
 *   "language": { "fk" : "language_code" , "pk" : "language_code" },
 *   "selfByDisplayLanguage": { "id":"display_code","fk" : "language_code" , "pk" : "display_code", "name": "language" },
 *   "display_code": { "id":"display_code","fk" : "language_code" , "pk" : "display_code", "name": "language" }
 * }
 */
export class Language_Title {
  display_code?: Maybe<Scalars["ID"]>;
  exposure_order: Scalars["Int"];
  id: Scalars["UUID"];
  language?: Maybe<Array<Maybe<Language>>>;
  language_code?: Maybe<Scalars["ID"]>;
  selfByDisplayLanguage?: Maybe<Array<Maybe<Language>>>;
  tsvec_words?: Maybe<Scalars["String"]>;
  type_title?: Maybe<Scalars["String"]>;
  value: Scalars["String"];
}

/**
 * {
 *   "name":"language_title",
 *   "id": "id",
 *   "language": { "fk" : "language_code" , "pk" : "language_code" },
 *   "selfByDisplayLanguage": { "id":"display_code","fk" : "language_code" , "pk" : "display_code", "name": "language" },
 *   "display_code": { "id":"display_code","fk" : "language_code" , "pk" : "display_code", "name": "language" }
 * }
 */
export class Language_TitleLanguageArgs {
  where?: InputMaybe<LanguageWhereInput>;
}

export class Language_TitleCreateInput {
  display_code: LanguageChildInput;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  type_title?: InputMaybe<Scalars["String"]>;
  value: Scalars["String"];
}

export class Language_TitleCreateManyInput {
  create?: InputMaybe<Array<Language_TitleCreateInput>>;
}

export enum Language_TitleOrderByInput {
  ExposureOrderAsc = "exposure_order__ASC",
  ExposureOrderDesc = "exposure_order__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  TypeTitleAsc = "type_title__ASC",
  TypeTitleDesc = "type_title__DESC",
  ValueAsc = "value__ASC",
  ValueDesc = "value__DESC",
  ValueSimilarity = "value__SIMILARITY"
}

export class Language_TitleUpdateInput {
  display_code?: InputMaybe<LanguageChildInput>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  type_title?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
}

export class Language_TitleUpdateManyWithoutLanguageInput {
  create?: InputMaybe<Array<Language_TitleCreateInput>>;
  update?: InputMaybe<Array<Language_TitleUpdateWithWhereUniqueInput>>;
}

export class Language_TitleUpdateWithWhereUniqueInput {
  data: Language_TitleUpdateInput;
  where: UuidWhereUniqueInput;
}

export class Language_TitleWhereInput {
  AND?: InputMaybe<Array<Language_TitleWhereInput>>;
  NOT?: InputMaybe<Array<Language_TitleWhereInput>>;
  OR?: InputMaybe<Array<Language_TitleWhereInput>>;
  display_some?: InputMaybe<LanguageChildInput>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  exposure_order__gt?: InputMaybe<Scalars["Int"]>;
  exposure_order__gte?: InputMaybe<Scalars["Int"]>;
  exposure_order__in?: InputMaybe<Array<Scalars["Int"]>>;
  exposure_order__lt?: InputMaybe<Scalars["Int"]>;
  exposure_order__lte?: InputMaybe<Scalars["Int"]>;
  exposure_order__not?: InputMaybe<Scalars["Int"]>;
  exposure_order__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  language__lateral?: InputMaybe<Scalars["Boolean"]>;
  language__some?: InputMaybe<LanguageWhereInput>;
  language_code?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  language_code__in?: InputMaybe<Array<Scalars["ID"]>>;
  language_code__not_include?: InputMaybe<Array<Scalars["ID"]>>;
  tsvec_words__search?: InputMaybe<Scalars["String"]>;
  type_title?: InputMaybe<Scalars["String"]>;
  type_title__contains?: InputMaybe<Scalars["String"]>;
  type_title__ends_with?: InputMaybe<Scalars["String"]>;
  type_title__in?: InputMaybe<Array<Scalars["String"]>>;
  type_title__not?: InputMaybe<Scalars["String"]>;
  type_title__not_contains?: InputMaybe<Scalars["String"]>;
  type_title__not_ends_with?: InputMaybe<Scalars["String"]>;
  type_title__not_in?: InputMaybe<Array<Scalars["String"]>>;
  type_title__not_starts_with?: InputMaybe<Scalars["String"]>;
  type_title__starts_with?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
  value__contains?: InputMaybe<Scalars["String"]>;
  value__ends_with?: InputMaybe<Scalars["String"]>;
  value__in?: InputMaybe<Array<Scalars["String"]>>;
  value__not?: InputMaybe<Scalars["String"]>;
  value__not_contains?: InputMaybe<Scalars["String"]>;
  value__not_ends_with?: InputMaybe<Scalars["String"]>;
  value__not_in?: InputMaybe<Array<Scalars["String"]>>;
  value__not_starts_with?: InputMaybe<Scalars["String"]>;
  value__starts_with?: InputMaybe<Scalars["String"]>;
}

export class License {
  company?: Maybe<Scalars["String"]>;
  created_at: Scalars["DateTime"];
  id: Scalars["String"];
  json?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  owner_id: Scalars["Int"];
  track: Track;
  track_id: Scalars["Int"];
  updated_at: Scalars["DateTime"];
  user: User;
}

export enum LicenseOrderByInput {
  CreatedAtAsc = "created_at__ASC",
  CreatedAtDesc = "created_at__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC"
}

export class LicenseWhereInput {
  id?: InputMaybe<Scalars["String"]>;
  id__gt?: InputMaybe<Scalars["Int"]>;
  id__gte?: InputMaybe<Scalars["Int"]>;
  id__in?: InputMaybe<Array<Scalars["String"]>>;
  id__lt?: InputMaybe<Scalars["Int"]>;
  id__lte?: InputMaybe<Scalars["Int"]>;
  id__starts_with?: InputMaybe<Scalars["String"]>;
  owner_id?: InputMaybe<Scalars["Int"]>;
  owner_id__in?: InputMaybe<Array<Scalars["Int"]>>;
  track_id?: InputMaybe<Scalars["Int"]>;
  user__some?: InputMaybe<UserWhereInput>;
}

export class LogInWhereInput {
  UserAccessToken?: InputMaybe<Scalars["String"]>;
  email: Scalars["ID"];
  password: Scalars["String"];
}

export class MediaConvertWhereInput {
  input?: InputMaybe<Array<Scalars["String"]>>;
  output: Scalars["String"];
}

export enum MediaType {
  Image = "image",
  Track = "track"
}

/**
 * {
 *   "name":"metadata",
 *   "id": "metadata_id" ,
 *   "type" : "Int",
 *   "tonality": { "fk" : "tonality_id" , "pk" : "tonality_id" },
 *   "metadata_self_relation": { "fk" : "list_metadata_id" , "pk" : "metadata_id" , "name": "metadata_self_relation"},
 *   "metadata_self_relations_element_metadata": { "fk" : "element_metadata_id" , "pk" : "metadata_id" ,"name": "metadata_self_relation"},
 *   "metadata_self_relations_list_metadata": { "fk" : "list_metadata_id" , "pk" : "metadata_id" , "name": "metadata_self_relation"}
 * }
 */
export class Metadata {
  Metadata_self_relation?: Maybe<Array<Maybe<Metadata_Self_Relation>>>;
  compatibility_v1_id?: Maybe<Scalars["Int"]>;
  metadata_artist_relation?: Maybe<Array<Maybe<Metadata_Artist_Relation>>>;
  metadata_company_relation?: Maybe<Array<Maybe<Metadata_Company_Relation>>>;
  metadata_genre_relation?: Maybe<Array<Maybe<Metadata_Genre_Relation>>>;
  metadata_goods_relation?: Maybe<Array<Metadata_Goods_Relation>>;
  metadata_id: Scalars["ID"];
  metadata_language_relation?: Maybe<Array<Metadata_Language_Relation>>;
  metadata_playlist_relation?: Maybe<Array<Metadata_Playlist_Relation>>;
  metadata_self_relations_element_metadata?: Maybe<Array<Maybe<Metadata_Self_Relation>>>;
  metadata_self_relations_list_metadata?: Maybe<Array<Maybe<Metadata_Self_Relation>>>;
  metadata_structure?: Maybe<Array<Maybe<Metadata_Structure>>>;
  metadata_subdata_unique?: Maybe<Array<Maybe<Metadata_Subdata_Unique>>>;
  metadata_title?: Maybe<Array<Maybe<Metadata_Title>>>;
  metadata_url?: Maybe<Array<Metadata_Url>>;
  no?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  tonality?: Maybe<Tonality>;
  tonalitys?: Maybe<Array<Maybe<Tonality>>>;
  track?: Maybe<Array<Maybe<Track>>>;
  track_information?: Maybe<Array<Maybe<Track_Information>>>;
  track_license?: Maybe<Array<Maybe<Track_License>>>;
  track_license_extra?: Maybe<Array<Maybe<Track_License_Extra>>>;
  track_play_count?: Maybe<Array<Maybe<Track_Play_Count>>>;
  type_metadata_class: Scalars["String"];
  type_metadata_subclass?: Maybe<Scalars["String"]>;
  valid_check?: Maybe<ValidType>;
}

/**
 * {
 *   "name":"metadata",
 *   "id": "metadata_id" ,
 *   "type" : "Int",
 *   "tonality": { "fk" : "tonality_id" , "pk" : "tonality_id" },
 *   "metadata_self_relation": { "fk" : "list_metadata_id" , "pk" : "metadata_id" , "name": "metadata_self_relation"},
 *   "metadata_self_relations_element_metadata": { "fk" : "element_metadata_id" , "pk" : "metadata_id" ,"name": "metadata_self_relation"},
 *   "metadata_self_relations_list_metadata": { "fk" : "list_metadata_id" , "pk" : "metadata_id" , "name": "metadata_self_relation"}
 * }
 */
export class MetadataMetadata_Self_RelationArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Metadata_Self_RelationOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Metadata_Self_RelationWhereInput>;
}

/**
 * {
 *   "name":"metadata",
 *   "id": "metadata_id" ,
 *   "type" : "Int",
 *   "tonality": { "fk" : "tonality_id" , "pk" : "tonality_id" },
 *   "metadata_self_relation": { "fk" : "list_metadata_id" , "pk" : "metadata_id" , "name": "metadata_self_relation"},
 *   "metadata_self_relations_element_metadata": { "fk" : "element_metadata_id" , "pk" : "metadata_id" ,"name": "metadata_self_relation"},
 *   "metadata_self_relations_list_metadata": { "fk" : "list_metadata_id" , "pk" : "metadata_id" , "name": "metadata_self_relation"}
 * }
 */
export class MetadataMetadata_Artist_RelationArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Metadata_Artist_RelationOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Metadata_Artist_RelationWhereInput>;
}

/**
 * {
 *   "name":"metadata",
 *   "id": "metadata_id" ,
 *   "type" : "Int",
 *   "tonality": { "fk" : "tonality_id" , "pk" : "tonality_id" },
 *   "metadata_self_relation": { "fk" : "list_metadata_id" , "pk" : "metadata_id" , "name": "metadata_self_relation"},
 *   "metadata_self_relations_element_metadata": { "fk" : "element_metadata_id" , "pk" : "metadata_id" ,"name": "metadata_self_relation"},
 *   "metadata_self_relations_list_metadata": { "fk" : "list_metadata_id" , "pk" : "metadata_id" , "name": "metadata_self_relation"}
 * }
 */
export class MetadataMetadata_Company_RelationArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Metadata_Company_RelationOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Metadata_Company_RelationWhereInput>;
}

/**
 * {
 *   "name":"metadata",
 *   "id": "metadata_id" ,
 *   "type" : "Int",
 *   "tonality": { "fk" : "tonality_id" , "pk" : "tonality_id" },
 *   "metadata_self_relation": { "fk" : "list_metadata_id" , "pk" : "metadata_id" , "name": "metadata_self_relation"},
 *   "metadata_self_relations_element_metadata": { "fk" : "element_metadata_id" , "pk" : "metadata_id" ,"name": "metadata_self_relation"},
 *   "metadata_self_relations_list_metadata": { "fk" : "list_metadata_id" , "pk" : "metadata_id" , "name": "metadata_self_relation"}
 * }
 */
export class MetadataMetadata_Genre_RelationArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Metadata_Genre_RelationOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Metadata_Genre_RelationWhereInput>;
}

/**
 * {
 *   "name":"metadata",
 *   "id": "metadata_id" ,
 *   "type" : "Int",
 *   "tonality": { "fk" : "tonality_id" , "pk" : "tonality_id" },
 *   "metadata_self_relation": { "fk" : "list_metadata_id" , "pk" : "metadata_id" , "name": "metadata_self_relation"},
 *   "metadata_self_relations_element_metadata": { "fk" : "element_metadata_id" , "pk" : "metadata_id" ,"name": "metadata_self_relation"},
 *   "metadata_self_relations_list_metadata": { "fk" : "list_metadata_id" , "pk" : "metadata_id" , "name": "metadata_self_relation"}
 * }
 */
export class MetadataMetadata_Goods_RelationArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<Metadata_Goods_RelationOrderByInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Metadata_Goods_RelationWhereInput>;
}

/**
 * {
 *   "name":"metadata",
 *   "id": "metadata_id" ,
 *   "type" : "Int",
 *   "tonality": { "fk" : "tonality_id" , "pk" : "tonality_id" },
 *   "metadata_self_relation": { "fk" : "list_metadata_id" , "pk" : "metadata_id" , "name": "metadata_self_relation"},
 *   "metadata_self_relations_element_metadata": { "fk" : "element_metadata_id" , "pk" : "metadata_id" ,"name": "metadata_self_relation"},
 *   "metadata_self_relations_list_metadata": { "fk" : "list_metadata_id" , "pk" : "metadata_id" , "name": "metadata_self_relation"}
 * }
 */
export class MetadataMetadata_Language_RelationArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Metadata_Language_RelationOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Metadata_Language_RelationWhereInput>;
}

/**
 * {
 *   "name":"metadata",
 *   "id": "metadata_id" ,
 *   "type" : "Int",
 *   "tonality": { "fk" : "tonality_id" , "pk" : "tonality_id" },
 *   "metadata_self_relation": { "fk" : "list_metadata_id" , "pk" : "metadata_id" , "name": "metadata_self_relation"},
 *   "metadata_self_relations_element_metadata": { "fk" : "element_metadata_id" , "pk" : "metadata_id" ,"name": "metadata_self_relation"},
 *   "metadata_self_relations_list_metadata": { "fk" : "list_metadata_id" , "pk" : "metadata_id" , "name": "metadata_self_relation"}
 * }
 */
export class MetadataMetadata_Playlist_RelationArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Metadata_Playlist_RelationOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Metadata_Playlist_RelationWhereInput>;
}

/**
 * {
 *   "name":"metadata",
 *   "id": "metadata_id" ,
 *   "type" : "Int",
 *   "tonality": { "fk" : "tonality_id" , "pk" : "tonality_id" },
 *   "metadata_self_relation": { "fk" : "list_metadata_id" , "pk" : "metadata_id" , "name": "metadata_self_relation"},
 *   "metadata_self_relations_element_metadata": { "fk" : "element_metadata_id" , "pk" : "metadata_id" ,"name": "metadata_self_relation"},
 *   "metadata_self_relations_list_metadata": { "fk" : "list_metadata_id" , "pk" : "metadata_id" , "name": "metadata_self_relation"}
 * }
 */
export class MetadataMetadata_Self_Relations_Element_MetadataArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Metadata_Self_RelationOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Metadata_Self_RelationWhereInput>;
}

/**
 * {
 *   "name":"metadata",
 *   "id": "metadata_id" ,
 *   "type" : "Int",
 *   "tonality": { "fk" : "tonality_id" , "pk" : "tonality_id" },
 *   "metadata_self_relation": { "fk" : "list_metadata_id" , "pk" : "metadata_id" , "name": "metadata_self_relation"},
 *   "metadata_self_relations_element_metadata": { "fk" : "element_metadata_id" , "pk" : "metadata_id" ,"name": "metadata_self_relation"},
 *   "metadata_self_relations_list_metadata": { "fk" : "list_metadata_id" , "pk" : "metadata_id" , "name": "metadata_self_relation"}
 * }
 */
export class MetadataMetadata_Self_Relations_List_MetadataArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Metadata_Self_RelationOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Metadata_Self_RelationWhereInput>;
}

/**
 * {
 *   "name":"metadata",
 *   "id": "metadata_id" ,
 *   "type" : "Int",
 *   "tonality": { "fk" : "tonality_id" , "pk" : "tonality_id" },
 *   "metadata_self_relation": { "fk" : "list_metadata_id" , "pk" : "metadata_id" , "name": "metadata_self_relation"},
 *   "metadata_self_relations_element_metadata": { "fk" : "element_metadata_id" , "pk" : "metadata_id" ,"name": "metadata_self_relation"},
 *   "metadata_self_relations_list_metadata": { "fk" : "list_metadata_id" , "pk" : "metadata_id" , "name": "metadata_self_relation"}
 * }
 */
export class MetadataMetadata_StructureArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Metadata_StructureOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Metadata_StructureWhereInput>;
}

/**
 * {
 *   "name":"metadata",
 *   "id": "metadata_id" ,
 *   "type" : "Int",
 *   "tonality": { "fk" : "tonality_id" , "pk" : "tonality_id" },
 *   "metadata_self_relation": { "fk" : "list_metadata_id" , "pk" : "metadata_id" , "name": "metadata_self_relation"},
 *   "metadata_self_relations_element_metadata": { "fk" : "element_metadata_id" , "pk" : "metadata_id" ,"name": "metadata_self_relation"},
 *   "metadata_self_relations_list_metadata": { "fk" : "list_metadata_id" , "pk" : "metadata_id" , "name": "metadata_self_relation"}
 * }
 */
export class MetadataMetadata_Subdata_UniqueArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Metadata_Subdata_UniqueOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Metadata_Subdata_UniqueWhereInput>;
}

/**
 * {
 *   "name":"metadata",
 *   "id": "metadata_id" ,
 *   "type" : "Int",
 *   "tonality": { "fk" : "tonality_id" , "pk" : "tonality_id" },
 *   "metadata_self_relation": { "fk" : "list_metadata_id" , "pk" : "metadata_id" , "name": "metadata_self_relation"},
 *   "metadata_self_relations_element_metadata": { "fk" : "element_metadata_id" , "pk" : "metadata_id" ,"name": "metadata_self_relation"},
 *   "metadata_self_relations_list_metadata": { "fk" : "list_metadata_id" , "pk" : "metadata_id" , "name": "metadata_self_relation"}
 * }
 */
export class MetadataMetadata_TitleArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Metadata_TitleOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Metadata_TitleWhereInput>;
}

/**
 * {
 *   "name":"metadata",
 *   "id": "metadata_id" ,
 *   "type" : "Int",
 *   "tonality": { "fk" : "tonality_id" , "pk" : "tonality_id" },
 *   "metadata_self_relation": { "fk" : "list_metadata_id" , "pk" : "metadata_id" , "name": "metadata_self_relation"},
 *   "metadata_self_relations_element_metadata": { "fk" : "element_metadata_id" , "pk" : "metadata_id" ,"name": "metadata_self_relation"},
 *   "metadata_self_relations_list_metadata": { "fk" : "list_metadata_id" , "pk" : "metadata_id" , "name": "metadata_self_relation"}
 * }
 */
export class MetadataMetadata_UrlArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Metadata_UrlOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Metadata_UrlWhereInput>;
}

/**
 * {
 *   "name":"metadata",
 *   "id": "metadata_id" ,
 *   "type" : "Int",
 *   "tonality": { "fk" : "tonality_id" , "pk" : "tonality_id" },
 *   "metadata_self_relation": { "fk" : "list_metadata_id" , "pk" : "metadata_id" , "name": "metadata_self_relation"},
 *   "metadata_self_relations_element_metadata": { "fk" : "element_metadata_id" , "pk" : "metadata_id" ,"name": "metadata_self_relation"},
 *   "metadata_self_relations_list_metadata": { "fk" : "list_metadata_id" , "pk" : "metadata_id" , "name": "metadata_self_relation"}
 * }
 */
export class MetadataTrack_InformationArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Track_InformationOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Track_InformationWhereInput>;
}

/**
 * {
 *   "name":"metadata",
 *   "id": "metadata_id" ,
 *   "type" : "Int",
 *   "tonality": { "fk" : "tonality_id" , "pk" : "tonality_id" },
 *   "metadata_self_relation": { "fk" : "list_metadata_id" , "pk" : "metadata_id" , "name": "metadata_self_relation"},
 *   "metadata_self_relations_element_metadata": { "fk" : "element_metadata_id" , "pk" : "metadata_id" ,"name": "metadata_self_relation"},
 *   "metadata_self_relations_list_metadata": { "fk" : "list_metadata_id" , "pk" : "metadata_id" , "name": "metadata_self_relation"}
 * }
 */
export class MetadataTrack_LicenseArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Track_LicenseOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Track_LicenseWhereUniqueInput>;
}

/**
 * {
 *   "name":"metadata",
 *   "id": "metadata_id" ,
 *   "type" : "Int",
 *   "tonality": { "fk" : "tonality_id" , "pk" : "tonality_id" },
 *   "metadata_self_relation": { "fk" : "list_metadata_id" , "pk" : "metadata_id" , "name": "metadata_self_relation"},
 *   "metadata_self_relations_element_metadata": { "fk" : "element_metadata_id" , "pk" : "metadata_id" ,"name": "metadata_self_relation"},
 *   "metadata_self_relations_list_metadata": { "fk" : "list_metadata_id" , "pk" : "metadata_id" , "name": "metadata_self_relation"}
 * }
 */
export class MetadataTrack_License_ExtraArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Track_License_ExtraOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Track_License_ExtraWhereUniqueInput>;
}

/**
 * {
 *   "name":"metadata",
 *   "id": "metadata_id" ,
 *   "type" : "Int",
 *   "tonality": { "fk" : "tonality_id" , "pk" : "tonality_id" },
 *   "metadata_self_relation": { "fk" : "list_metadata_id" , "pk" : "metadata_id" , "name": "metadata_self_relation"},
 *   "metadata_self_relations_element_metadata": { "fk" : "element_metadata_id" , "pk" : "metadata_id" ,"name": "metadata_self_relation"},
 *   "metadata_self_relations_list_metadata": { "fk" : "list_metadata_id" , "pk" : "metadata_id" , "name": "metadata_self_relation"}
 * }
 */
export class MetadataTrack_Play_CountArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Track_Play_CountOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Track_Play_CountWhereInput>;
}

export class MetadataChildInput {
  connect?: InputMaybe<MetadataWhereUniqueInput>;
}

export class MetadataCreateInput {
  compatibility_v1_id?: InputMaybe<Scalars["Int"]>;
  metadata_artist_relation?: InputMaybe<Metadata_Artist_RelationCreateManyWithoutMetadataInput>;
  metadata_company_relation?: InputMaybe<Metadata_Company_RelationCreateManyWithoutMetadataInput>;
  metadata_genre_relation?: InputMaybe<Metadata_Genre_RelationCreateManyWithoutMetadataInput>;
  metadata_language_relation?: InputMaybe<Metadata_Language_RelationCreateManyWithoutMetadataInput>;
  metadata_playlist_relation?: InputMaybe<Metadata_Playlist_RelationCreateManyWithoutMetadataInput>;
  metadata_self_relations_element_metadata?: InputMaybe<Metadata_Self_RelationCreateManyWithoutElement_MetadataInput>;
  metadata_self_relations_list_metadata?: InputMaybe<Metadata_Self_RelationCreateManyWithoutList_MetadataInput>;
  metadata_structure?: InputMaybe<Metadata_StructureCreateManyWithoutMetadataInput>;
  metadata_subdata_unique?: InputMaybe<Metadata_Subdata_UniqueCreateManyWithoutMetadataInput>;
  metadata_title?: InputMaybe<Metadata_TitleCreateManyWithoutMetadataInput>;
  metadata_url?: InputMaybe<Metadata_UrlCreateManyWithoutArtistInput>;
  no?: InputMaybe<Scalars["String"]>;
  title: Scalars["String"];
  tonality?: InputMaybe<TonalityCreateOneWithoutMetadataInput>;
  track_information?: InputMaybe<Track_InformationCreateManylistInput>;
  track_license?: InputMaybe<Track_LicenseCreateManyWithoutMetadataInput>;
  track_license_extra?: InputMaybe<Track_License_ExtraCreateManyWithoutMetadataInput>;
  type_metadata_class: Scalars["String"];
  type_metadata_subclass?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
}

export enum MetadataOrderByInput {
  CompatibilityV1IdAsc = "compatibility_v1_id__ASC",
  CompatibilityV1IdDesc = "compatibility_v1_id__DESC",
  CreatedAtAsc = "createdAt__ASC",
  CreatedAtDesc = "createdAt__DESC",
  MetadataIdAsc = "metadata_id__ASC",
  MetadataIdDesc = "metadata_id__DESC",
  NoAsc = "no__ASC",
  NoDesc = "no__DESC",
  Rand = "rand",
  TitleAsc = "title__ASC",
  TitleDesc = "title__DESC",
  TypeMetadataClassAsc = "type_metadata_class__ASC",
  TypeMetadataClassDesc = "type_metadata_class__DESC",
  TypeMetadataSubclassAsc = "type_metadata_subclass__ASC",
  TypeMetadataSubclassDesc = "type_metadata_subclass__DESC",
  UpdatedAtAsc = "updatedAt__ASC",
  UpdatedAtDesc = "updatedAt__DESC",
  ValidCheckAsc = "valid_check__ASC",
  ValidCheckDesc = "valid_check__DESC"
}

export enum MetadataRightType {
  Production = "production",
  Publication = "publication",
  RightCompany = "rightCompany"
}

export class MetadataUpdateInput {
  _onlySearch?: InputMaybe<Scalars["Boolean"]>;
  compatibility_v1_id?: InputMaybe<Scalars["Int"]>;
  metadata_artist_relation?: InputMaybe<Metadata_Artist_RelationUpdateManyWithoutMetadataInput>;
  metadata_company_relation?: InputMaybe<Metadata_Company_RelationUpdateManyWithoutMetadataInput>;
  metadata_genre_relation?: InputMaybe<Metadata_Genre_RelationUpdateManyWithoutMetadataInput>;
  metadata_language_relation?: InputMaybe<Metadata_Language_RelationUpdateManyWithoutMetadataInput>;
  metadata_playlist_relation?: InputMaybe<Metadata_Playlist_RelationUpdateManyWithoutMetadataInput>;
  metadata_self_relations_element_metadata?: InputMaybe<Metadata_Self_RelationUpdateManyWithoutElement_MetadataInput>;
  metadata_self_relations_list_metadata?: InputMaybe<Metadata_Self_RelationUpdateManyWithoutList_MetadataInput>;
  metadata_structure?: InputMaybe<Metadata_StructureUpdateManyWithoutMetadataInput>;
  metadata_subdata_unique?: InputMaybe<Metadata_Subdata_UniqueUpdateManyWithoutMetadataInput>;
  metadata_title?: InputMaybe<Metadata_TitleUpdateManyWithoutMetadataInput>;
  metadata_url?: InputMaybe<Metadata_UrlUpdateManyWithoutArtistInput>;
  no?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  tonality?: InputMaybe<TonalityUpdateOneWithoutMetadataInput>;
  track_information?: InputMaybe<Track_InformationUpdateManylistInput>;
  track_license?: InputMaybe<Track_LicenseUpdateManyWithoutMetadataInput>;
  track_license_extra?: InputMaybe<Track_License_ExtraUpdateManyWithoutMetadataInput>;
  type_metadata_class?: InputMaybe<Scalars["String"]>;
  type_metadata_subclass?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class MetadataUpdateManyDataInput {
  compatibility_v1_id?: InputMaybe<Scalars["Int"]>;
  no?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  type_metadata_class?: InputMaybe<Scalars["String"]>;
  type_metadata_subclass?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class MetadataUpdateManyMutationInput {
  compatibility_v1_id?: InputMaybe<Scalars["Int"]>;
  no?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  type_metadata_class?: InputMaybe<Scalars["String"]>;
  type_metadata_subclass?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class MetadataWhereInput {
  AND?: InputMaybe<Array<MetadataWhereInput>>;
  NOT?: InputMaybe<Array<MetadataWhereInput>>;
  OR?: InputMaybe<Array<MetadataWhereInput>>;
  compatibility_v1_id?: InputMaybe<Scalars["Int"]>;
  compatibility_v1_id__exist?: InputMaybe<Scalars["Boolean"]>;
  compatibility_v1_id__gt?: InputMaybe<Scalars["Int"]>;
  compatibility_v1_id__gte?: InputMaybe<Scalars["Int"]>;
  compatibility_v1_id__in?: InputMaybe<Array<Scalars["Int"]>>;
  compatibility_v1_id__lt?: InputMaybe<Scalars["Int"]>;
  compatibility_v1_id__lte?: InputMaybe<Scalars["Int"]>;
  compatibility_v1_id__not?: InputMaybe<Scalars["Int"]>;
  compatibility_v1_id__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  metadata_artist_relation__lateral?: InputMaybe<Scalars["Boolean"]>;
  metadata_artist_relation__some?: InputMaybe<Metadata_Artist_RelationWhereInput>;
  metadata_company_relation__lateral?: InputMaybe<Scalars["Boolean"]>;
  metadata_company_relation__some?: InputMaybe<Metadata_Company_RelationWhereInput>;
  metadata_genre_relation__lateral?: InputMaybe<Scalars["Boolean"]>;
  metadata_genre_relation__some?: InputMaybe<Metadata_Genre_RelationWhereInput>;
  metadata_goods_relation__lateral?: InputMaybe<Scalars["Boolean"]>;
  metadata_goods_relation__some?: InputMaybe<Goods_Artist_RelationWhereInput>;
  metadata_id?: InputMaybe<Scalars["ID"]>;
  metadata_id__gt?: InputMaybe<Scalars["ID"]>;
  metadata_id__gte?: InputMaybe<Scalars["ID"]>;
  metadata_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  metadata_id__lt?: InputMaybe<Scalars["ID"]>;
  metadata_id__lte?: InputMaybe<Scalars["ID"]>;
  metadata_id__not_in?: InputMaybe<Array<Scalars["ID"]>>;
  metadata_language_relation__lateral?: InputMaybe<Scalars["Boolean"]>;
  metadata_language_relation__some?: InputMaybe<Metadata_Language_RelationWhereInput>;
  metadata_playlist_relation__lateral?: InputMaybe<Scalars["Boolean"]>;
  metadata_playlist_relation__some?: InputMaybe<Metadata_Playlist_RelationWhereInput>;
  metadata_self_relations_element_metadata__some?: InputMaybe<Metadata_Self_RelationWhereInput>;
  metadata_self_relations_list_metadata__some?: InputMaybe<Metadata_Self_RelationWhereInput>;
  metadata_structure__lateral?: InputMaybe<Scalars["Boolean"]>;
  metadata_structure__some?: InputMaybe<Metadata_StructureWhereInput>;
  metadata_subdata_unique__lateral?: InputMaybe<Scalars["Boolean"]>;
  metadata_subdata_unique__some?: InputMaybe<Metadata_Subdata_UniqueWhereInput>;
  metadata_title__lateral?: InputMaybe<Scalars["Boolean"]>;
  metadata_title__some?: InputMaybe<Metadata_TitleWhereInput>;
  metadata_url__lateral?: InputMaybe<Scalars["Boolean"]>;
  metadata_url__some?: InputMaybe<Metadata_UrlWhereInput>;
  no?: InputMaybe<Scalars["String"]>;
  no__in?: InputMaybe<Array<Scalars["String"]>>;
  no__not?: InputMaybe<Scalars["String"]>;
  no__not_in?: InputMaybe<Array<Scalars["String"]>>;
  title?: InputMaybe<Scalars["String"]>;
  title__contains?: InputMaybe<Scalars["String"]>;
  title__ends_with?: InputMaybe<Scalars["String"]>;
  title__in?: InputMaybe<Array<Scalars["String"]>>;
  title__not?: InputMaybe<Scalars["String"]>;
  title__not_contains?: InputMaybe<Scalars["String"]>;
  title__not_ends_with?: InputMaybe<Scalars["String"]>;
  title__not_in?: InputMaybe<Array<Scalars["String"]>>;
  title__not_starts_with?: InputMaybe<Scalars["String"]>;
  title__starts_with?: InputMaybe<Scalars["String"]>;
  tonality?: InputMaybe<TonalityWhereInput>;
  track_information__lateral?: InputMaybe<Scalars["Boolean"]>;
  track_information__some?: InputMaybe<Track_InformationWhereInput>;
  track_license__lateral?: InputMaybe<Scalars["Boolean"]>;
  track_license__some?: InputMaybe<Track_LicenseWhereUniqueInput>;
  track_license_extra__lateral?: InputMaybe<Scalars["Boolean"]>;
  track_license_extra__some?: InputMaybe<Track_License_ExtraWhereUniqueInput>;
  type_metadata_class?: InputMaybe<Scalars["String"]>;
  type_metadata_class__contains?: InputMaybe<Scalars["String"]>;
  type_metadata_class__ends_with?: InputMaybe<Scalars["String"]>;
  type_metadata_class__in?: InputMaybe<Array<Scalars["String"]>>;
  type_metadata_class__not?: InputMaybe<Scalars["String"]>;
  type_metadata_class__not_contains?: InputMaybe<Scalars["String"]>;
  type_metadata_class__not_ends_with?: InputMaybe<Scalars["String"]>;
  type_metadata_class__not_in?: InputMaybe<Array<Scalars["String"]>>;
  type_metadata_class__not_starts_with?: InputMaybe<Scalars["String"]>;
  type_metadata_class__starts_with?: InputMaybe<Scalars["String"]>;
  type_metadata_subclass?: InputMaybe<Scalars["String"]>;
  type_metadata_subclass__contains?: InputMaybe<Scalars["String"]>;
  type_metadata_subclass__ends_with?: InputMaybe<Scalars["String"]>;
  type_metadata_subclass__in?: InputMaybe<Array<Scalars["String"]>>;
  type_metadata_subclass__not?: InputMaybe<Scalars["String"]>;
  type_metadata_subclass__not_contains?: InputMaybe<Scalars["String"]>;
  type_metadata_subclass__not_ends_with?: InputMaybe<Scalars["String"]>;
  type_metadata_subclass__not_in?: InputMaybe<Array<Scalars["String"]>>;
  type_metadata_subclass__not_starts_with?: InputMaybe<Scalars["String"]>;
  type_metadata_subclass__starts_with?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
  valid_check__contains?: InputMaybe<ValidType>;
  valid_check__in?: InputMaybe<Array<ValidType>>;
  valid_check__not?: InputMaybe<ValidType>;
  valid_check__not_contains?: InputMaybe<ValidType>;
  valid_check__not_in?: InputMaybe<Array<ValidType>>;
}

export class MetadataWhereUniqueInput {
  compatibility_v1_id?: InputMaybe<Scalars["ID"]>;
  metadata_id?: InputMaybe<Scalars["ID"]>;
  metadata_id__in?: InputMaybe<Array<Scalars["ID"]>>;
}

/**
 * {
 *   "name":"metadata_artist_relation",
 *   "id": "id",
 *   "metadata": { "fk" : "metadata_id" , "pk" : "metadata_id" },
 *   "artist": { "fk" : "artist_id" , "pk" : "artist_id" },
 *   "role": { "fk" : "role_id" , "pk" : "role_id" },
 *   "character": { "id" : "character_id" , "fk" : "role_id" , "pk" : "character_id" }
 * }
 */
export class Metadata_Artist_Relation {
  artist: Array<Maybe<Artist>>;
  artist_id?: Maybe<Scalars["ID"]>;
  character?: Maybe<Array<Maybe<Role>>>;
  character_id?: Maybe<Scalars["ID"]>;
  exposure_order: Scalars["Int"];
  id: Scalars["UUID"];
  metadata: Array<Maybe<Metadata>>;
  metadata_id?: Maybe<Scalars["ID"]>;
  role: Array<Maybe<Role>>;
  role_id?: Maybe<Scalars["ID"]>;
  valid_check?: Maybe<ValidType>;
}

/**
 * {
 *   "name":"metadata_artist_relation",
 *   "id": "id",
 *   "metadata": { "fk" : "metadata_id" , "pk" : "metadata_id" },
 *   "artist": { "fk" : "artist_id" , "pk" : "artist_id" },
 *   "role": { "fk" : "role_id" , "pk" : "role_id" },
 *   "character": { "id" : "character_id" , "fk" : "role_id" , "pk" : "character_id" }
 * }
 */
export class Metadata_Artist_RelationRoleArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<RoleOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RoleWhereInput>;
}

export class Metadata_Artist_RelationCreateInput {
  artist: ArtistChildInput;
  character: RoleChildInput;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  metadata: MetadataChildInput;
  role: RoleChildInput;
  valid_check?: InputMaybe<ValidType>;
}

export class Metadata_Artist_RelationCreateManyWithoutArtistInput {
  create?: InputMaybe<Array<Metadata_Artist_RelationCreateWithoutArtistInput>>;
}

export class Metadata_Artist_RelationCreateManyWithoutMetadataInput {
  create?: InputMaybe<Array<Metadata_Artist_RelationCreateWithoutMetadataInput>>;
}

export class Metadata_Artist_RelationCreateManyWithoutRoleInput {
  create?: InputMaybe<Array<Metadata_Artist_RelationCreateWithoutRoleInput>>;
}

export class Metadata_Artist_RelationCreateWithoutArtistInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  metadata: MetadataChildInput;
  role: RoleChildInput;
  valid_check?: InputMaybe<ValidType>;
}

export class Metadata_Artist_RelationCreateWithoutMetadataInput {
  artist: ArtistChildInput;
  character?: InputMaybe<RoleChildInput>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  role: RoleChildInput;
  valid_check?: InputMaybe<ValidType>;
}

export class Metadata_Artist_RelationCreateWithoutRoleInput {
  artist: ArtistChildInput;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  metadata: MetadataChildInput;
  valid_check?: InputMaybe<ValidType>;
}

export enum Metadata_Artist_RelationOrderByInput {
  CreatedAtAsc = "createdAt__ASC",
  CreatedAtDesc = "createdAt__DESC",
  ExposureOrderAsc = "exposure_order__ASC",
  ExposureOrderDesc = "exposure_order__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  UpdatedAtAsc = "updatedAt__ASC",
  UpdatedAtDesc = "updatedAt__DESC",
  ValidCheckAsc = "valid_check__ASC",
  ValidCheckDesc = "valid_check__DESC"
}

export class Metadata_Artist_RelationUpdateInput {
  artist?: InputMaybe<ArtistChildInput>;
  character?: InputMaybe<RoleChildInput>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  metadata?: InputMaybe<MetadataChildInput>;
  role?: InputMaybe<RoleChildInput>;
  valid_check?: InputMaybe<ValidType>;
}

export class Metadata_Artist_RelationUpdateManyWithoutArtistInput {
  create?: InputMaybe<Array<Metadata_Artist_RelationCreateWithoutArtistInput>>;
  update?: InputMaybe<Array<Metadata_Artist_RelationUpdateWithWhereUniqueWithoutArtistInput>>;
}

export class Metadata_Artist_RelationUpdateManyWithoutMetadataInput {
  create?: InputMaybe<Array<Metadata_Artist_RelationCreateWithoutMetadataInput>>;
  update?: InputMaybe<Array<Metadata_Artist_RelationUpdateWithWhereUniqueWithoutMetadataInput>>;
}

export class Metadata_Artist_RelationUpdateManyWithoutRoleInput {
  create?: InputMaybe<Array<Metadata_Artist_RelationCreateWithoutRoleInput>>;
  update?: InputMaybe<Array<Metadata_Artist_RelationUpdateWithWhereUniqueWithoutRoleInput>>;
}

export class Metadata_Artist_RelationUpdateWhereInput {
  artist_id?: InputMaybe<Scalars["ID"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  metadata_id?: InputMaybe<Scalars["ID"]>;
  role_id?: InputMaybe<Scalars["ID"]>;
}

export class Metadata_Artist_RelationUpdateWithWhereUniqueWithoutArtistInput {
  data: Metadata_Artist_RelationUpdateWithoutArtistDataInput;
  where: UuidWhereUniqueInput;
}

export class Metadata_Artist_RelationUpdateWithWhereUniqueWithoutMetadataInput {
  data: Metadata_Artist_RelationUpdateWithoutMetadataDataInput;
  where: UuidWhereUniqueInput;
}

export class Metadata_Artist_RelationUpdateWithWhereUniqueWithoutRoleInput {
  data: Metadata_Artist_RelationUpdateWithoutRoleDataInput;
  where: UuidWhereUniqueInput;
}

export class Metadata_Artist_RelationUpdateWithoutArtistDataInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  role?: InputMaybe<RoleChildInput>;
  valid_check?: InputMaybe<ValidType>;
}

export class Metadata_Artist_RelationUpdateWithoutMetadataDataInput {
  artist?: InputMaybe<ArtistChildInput>;
  character?: InputMaybe<RoleChildInput>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  role?: InputMaybe<RoleChildInput>;
  valid_check?: InputMaybe<ValidType>;
}

export class Metadata_Artist_RelationUpdateWithoutRoleDataInput {
  artist?: InputMaybe<ArtistChildInput>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class Metadata_Artist_RelationWhereInput {
  AND?: InputMaybe<Array<Metadata_Artist_RelationWhereInput>>;
  NOT?: InputMaybe<Array<Metadata_Artist_RelationWhereInput>>;
  OR?: InputMaybe<Array<Metadata_Artist_RelationWhereInput>>;
  artist__lateral?: InputMaybe<Scalars["Boolean"]>;
  artist__some?: InputMaybe<ArtistWhereInput>;
  artist_id?: InputMaybe<Scalars["ID"]>;
  artist_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  character__lateral?: InputMaybe<Scalars["Boolean"]>;
  character__some?: InputMaybe<RoleWhereInput>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  exposure_order__gt?: InputMaybe<Scalars["Int"]>;
  exposure_order__gte?: InputMaybe<Scalars["Int"]>;
  exposure_order__in?: InputMaybe<Array<Scalars["Int"]>>;
  exposure_order__lt?: InputMaybe<Scalars["Int"]>;
  exposure_order__lte?: InputMaybe<Scalars["Int"]>;
  exposure_order__not?: InputMaybe<Scalars["Int"]>;
  exposure_order__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__exist?: InputMaybe<Scalars["Boolean"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  id__not?: InputMaybe<Scalars["UUID"]>;
  id__not_in?: InputMaybe<Array<Scalars["UUID"]>>;
  metadata__lateral?: InputMaybe<Scalars["Boolean"]>;
  metadata__some?: InputMaybe<MetadataWhereInput>;
  metadata_id?: InputMaybe<Scalars["ID"]>;
  metadata_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  role__lateral?: InputMaybe<Scalars["Boolean"]>;
  role__some?: InputMaybe<RoleWhereInput>;
  role_id?: InputMaybe<Scalars["ID"]>;
  role_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  valid_check?: InputMaybe<ValidType>;
  valid_check__contains?: InputMaybe<ValidType>;
  valid_check__in?: InputMaybe<Array<ValidType>>;
  valid_check__not?: InputMaybe<ValidType>;
  valid_check__not_contains?: InputMaybe<ValidType>;
  valid_check__not_in?: InputMaybe<Array<ValidType>>;
}

/**
 * {
 *   "name":"metadata_company_relation",
 *   "id": "id",
 *   "metadata": { "fk" : "metadata_id" , "pk" : "metadata_id" },
 *   "company": { "fk" : "company_id" , "pk" : "company_id" }
 * }
 */
export class Metadata_Company_Relation {
  company?: Maybe<Array<Maybe<Company>>>;
  company_id?: Maybe<Scalars["ID"]>;
  exposure_order: Scalars["Int"];
  id: Scalars["UUID"];
  metadata: Array<Maybe<Metadata>>;
  metadata_id?: Maybe<Scalars["ID"]>;
  type_kind?: Maybe<MetadataRightType>;
  valid_check?: Maybe<ValidType>;
}

export class Metadata_Company_RelationCreateManyWithoutCompanyInput {
  create?: InputMaybe<Array<Metadata_Company_RelationCreateWithoutCompanyInput>>;
}

export class Metadata_Company_RelationCreateManyWithoutMetadataInput {
  create?: InputMaybe<Array<Metadata_Company_RelationCreateWithoutMetadataInput>>;
}

export class Metadata_Company_RelationCreateWithoutCompanyInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  metadata: MetadataChildInput;
  valid_check?: InputMaybe<ValidType>;
}

export class Metadata_Company_RelationCreateWithoutMetadataInput {
  company: CompanyChildInput;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  type_kind?: InputMaybe<MetadataRightType>;
  valid_check?: InputMaybe<ValidType>;
}

export enum Metadata_Company_RelationOrderByInput {
  CreatedAtAsc = "createdAt__ASC",
  CreatedAtDesc = "createdAt__DESC",
  ExposureOrderAsc = "exposure_order__ASC",
  ExposureOrderDesc = "exposure_order__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  UpdatedAtAsc = "updatedAt__ASC",
  UpdatedAtDesc = "updatedAt__DESC",
  ValidCheckAsc = "valid_check__ASC",
  ValidCheckDesc = "valid_check__DESC"
}

export class Metadata_Company_RelationUpdateInput {
  company?: InputMaybe<CompanyChildInput>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  metadata?: InputMaybe<MetadataChildInput>;
  type_kind?: InputMaybe<MetadataRightType>;
  valid_check?: InputMaybe<ValidType>;
}

export class Metadata_Company_RelationUpdateManyWithoutCompanyInput {
  create?: InputMaybe<Array<Metadata_Company_RelationCreateWithoutCompanyInput>>;
  update?: InputMaybe<Array<Metadata_Company_RelationUpdateWithWhereUniqueWithoutCompanyInput>>;
}

export class Metadata_Company_RelationUpdateManyWithoutMetadataInput {
  create?: InputMaybe<Array<Metadata_Company_RelationCreateWithoutMetadataInput>>;
  update?: InputMaybe<Array<Metadata_Company_RelationUpdateWithWhereUniqueWithoutMetadataInput>>;
}

export class Metadata_Company_RelationUpdateWithWhereUniqueWithoutCompanyInput {
  data: Metadata_Company_RelationUpdateWithoutCompanyDataInput;
  where: UuidWhereUniqueInput;
}

export class Metadata_Company_RelationUpdateWithWhereUniqueWithoutMetadataInput {
  data: Metadata_Company_RelationUpdateWithoutMetadataDataInput;
  where: UuidWhereUniqueInput;
}

export class Metadata_Company_RelationUpdateWithoutCompanyDataInput {
  company?: InputMaybe<CompanyChildInput>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  metadata?: InputMaybe<MetadataChildInput>;
  type_kind?: InputMaybe<MetadataRightType>;
  valid_check?: InputMaybe<ValidType>;
}

export class Metadata_Company_RelationUpdateWithoutMetadataDataInput {
  company?: InputMaybe<CompanyChildInput>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  type_kind?: InputMaybe<MetadataRightType>;
  valid_check?: InputMaybe<ValidType>;
}

export class Metadata_Company_RelationWhereInput {
  AND?: InputMaybe<Array<Metadata_Company_RelationWhereInput>>;
  NOT?: InputMaybe<Array<Metadata_Company_RelationWhereInput>>;
  OR?: InputMaybe<Array<Metadata_Company_RelationWhereInput>>;
  company__lateral?: InputMaybe<Scalars["Boolean"]>;
  company__some?: InputMaybe<CompanyWhereInput>;
  company_id?: InputMaybe<Scalars["ID"]>;
  company_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  company_id__not_include?: InputMaybe<Scalars["ID"]>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  exposure_order__gt?: InputMaybe<Scalars["Int"]>;
  exposure_order__gte?: InputMaybe<Scalars["Int"]>;
  exposure_order__in?: InputMaybe<Array<Scalars["Int"]>>;
  exposure_order__lt?: InputMaybe<Scalars["Int"]>;
  exposure_order__lte?: InputMaybe<Scalars["Int"]>;
  exposure_order__not?: InputMaybe<Scalars["Int"]>;
  exposure_order__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__contains?: InputMaybe<Scalars["UUID"]>;
  id__exist?: InputMaybe<Scalars["Boolean"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  id__not?: InputMaybe<Scalars["UUID"]>;
  id__not_contains?: InputMaybe<Scalars["UUID"]>;
  id__not_in?: InputMaybe<Array<Scalars["UUID"]>>;
  metadata__lateral?: InputMaybe<Scalars["Boolean"]>;
  metadata__some?: InputMaybe<MetadataWhereInput>;
  metadata_id?: InputMaybe<Scalars["ID"]>;
  metadata_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  metadata_id__not_include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  type_kind?: InputMaybe<MetadataRightType>;
  type_kind__in?: InputMaybe<Array<InputMaybe<MetadataRightType>>>;
  valid_check?: InputMaybe<ValidType>;
  valid_check__contains?: InputMaybe<ValidType>;
  valid_check__in?: InputMaybe<Array<ValidType>>;
  valid_check__not?: InputMaybe<ValidType>;
  valid_check__not_contains?: InputMaybe<ValidType>;
  valid_check__not_in?: InputMaybe<Array<ValidType>>;
}

/**
 * {
 *   "name":"metadata_donate_billing",
 *   "id": "id"
 * }
 */
export class Metadata_Donate_Billing {
  artist_id: Scalars["Int"];
  billing_date: Scalars["TimeStamp"];
  currency: CurrencyType;
  id?: Maybe<Scalars["UUID"]>;
  metadata_id: Scalars["Int"];
  service: ServiceType;
  total_amount: Scalars["Float"];
  total_coin: Scalars["Int"];
}

export class Metadata_Donate_BillingCreateInput {
  artist_id: Scalars["Int"];
  billing_date: Scalars["TimeStamp"];
  currency: CurrencyType;
  metadata_id: Scalars["Int"];
  service: ServiceType;
  total_amount: Scalars["Float"];
  total_coin: Scalars["Int"];
}

/**
 * {
 *   "name":"metadata_genre_relation",
 *   "id": "id",
 *   "metadata": { "fk" : "metadata_id" , "pk" : "metadata_id" },
 *   "genre": { "fk" : "genre_id" , "pk" : "genre_id" },
 *   "selfById": { "fk" : "id" , "pk" : "id","name": "metadata_genre_relation" },
 *   "selfByMetadata": { "fk" : "metadata_id" , "pk" : "metadata_id","name": "metadata_genre_relation" },
 *   "selfByGenre": { "fk" : "genre_id" , "pk" : "genre_id","name": "metadata_genre_relation" }
 * }
 */
export class Metadata_Genre_Relation {
  exposure_order: Scalars["Int"];
  genre: Array<Maybe<Genre>>;
  genre_id?: Maybe<Scalars["ID"]>;
  id: Scalars["UUID"];
  metadata: Array<Maybe<Metadata>>;
  metadata_id?: Maybe<Scalars["ID"]>;
  valid_check?: Maybe<ValidType>;
}

/**
 * {
 *   "name":"metadata_genre_relation",
 *   "id": "id",
 *   "metadata": { "fk" : "metadata_id" , "pk" : "metadata_id" },
 *   "genre": { "fk" : "genre_id" , "pk" : "genre_id" },
 *   "selfById": { "fk" : "id" , "pk" : "id","name": "metadata_genre_relation" },
 *   "selfByMetadata": { "fk" : "metadata_id" , "pk" : "metadata_id","name": "metadata_genre_relation" },
 *   "selfByGenre": { "fk" : "genre_id" , "pk" : "genre_id","name": "metadata_genre_relation" }
 * }
 */
export class Metadata_Genre_RelationGenreArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<GenreOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<GenreWhereInput>;
}

export class Metadata_Genre_RelationCreateManyWithoutMetadataInput {
  create?: InputMaybe<Array<Metadata_Genre_RelationCreateWithoutMetadataInput>>;
}

export class Metadata_Genre_RelationCreateWithoutGenreInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  metadata: MetadataChildInput;
  valid_check?: InputMaybe<ValidType>;
}

export class Metadata_Genre_RelationCreateWithoutMetadataInput {
  auto_tag_check?: InputMaybe<Scalars["Boolean"]>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  genre: GenreChildInput;
  valid_check?: InputMaybe<ValidType>;
}

export enum Metadata_Genre_RelationOrderByInput {
  CreatedAtAsc = "createdAt__ASC",
  CreatedAtDesc = "createdAt__DESC",
  ExposureOrderAsc = "exposure_order__ASC",
  ExposureOrderDesc = "exposure_order__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  UpdatedAtAsc = "updatedAt__ASC",
  UpdatedAtDesc = "updatedAt__DESC",
  ValidCheckAsc = "valid_check__ASC",
  ValidCheckDesc = "valid_check__DESC"
}

export class Metadata_Genre_RelationUpdateInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  genre?: InputMaybe<GenreChildInput>;
  metadata?: InputMaybe<MetadataChildInput>;
  valid_check?: InputMaybe<ValidType>;
}

export class Metadata_Genre_RelationUpdateManyWithoutGenreInput {
  create?: InputMaybe<Array<Metadata_Genre_RelationCreateWithoutGenreInput>>;
  update?: InputMaybe<Array<Metadata_Genre_RelationUpdateWithWhereUniqueWithoutMetadataInput>>;
}

export class Metadata_Genre_RelationUpdateManyWithoutMetadataInput {
  create?: InputMaybe<Array<Metadata_Genre_RelationCreateWithoutMetadataInput>>;
  update?: InputMaybe<Array<Metadata_Genre_RelationUpdateWithWhereUniqueWithoutMetadataInput>>;
}

export class Metadata_Genre_RelationUpdateWithWhereUniqueWithoutMetadataInput {
  data: Metadata_Genre_RelationUpdateWithoutMetadataDataInput;
  where: UuidWhereUniqueInput;
}

export class Metadata_Genre_RelationUpdateWithoutMetadataDataInput {
  auto_tag_check?: InputMaybe<Scalars["Boolean"]>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  genre?: InputMaybe<GenreChildInput>;
  metadata?: InputMaybe<MetadataChildInput>;
  valid_check?: InputMaybe<ValidType>;
}

export class Metadata_Genre_RelationWhereInput {
  AND?: InputMaybe<Array<Metadata_Genre_RelationWhereInput>>;
  NOT?: InputMaybe<Array<Metadata_Genre_RelationWhereInput>>;
  OR?: InputMaybe<Array<Metadata_Genre_RelationWhereInput>>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  exposure_order__gt?: InputMaybe<Scalars["Int"]>;
  exposure_order__gte?: InputMaybe<Scalars["Int"]>;
  exposure_order__in?: InputMaybe<Array<Scalars["Int"]>>;
  exposure_order__lt?: InputMaybe<Scalars["Int"]>;
  exposure_order__lte?: InputMaybe<Scalars["Int"]>;
  exposure_order__not?: InputMaybe<Scalars["Int"]>;
  exposure_order__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  genre__lateral?: InputMaybe<Scalars["Boolean"]>;
  genre__some?: InputMaybe<GenreWhereInput>;
  genre_id?: InputMaybe<Scalars["ID"]>;
  genre_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  genre_id__not_include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__contains?: InputMaybe<Scalars["UUID"]>;
  id__exist?: InputMaybe<Scalars["Boolean"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  id__not?: InputMaybe<Scalars["UUID"]>;
  id__not_contains?: InputMaybe<Scalars["UUID"]>;
  id__not_in?: InputMaybe<Array<Scalars["UUID"]>>;
  metadata__lateral?: InputMaybe<Scalars["Boolean"]>;
  metadata__some?: InputMaybe<MetadataWhereInput>;
  metadata_id?: InputMaybe<Scalars["ID"]>;
  metadata_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  metadata_id__not_include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  selfById__some?: InputMaybe<Metadata_Genre_RelationWhereInput>;
  selfByMetadata__some?: InputMaybe<Metadata_Genre_RelationWhereInput>;
  selfBygenre__some?: InputMaybe<Metadata_Genre_RelationWhereInput>;
  valid_check?: InputMaybe<ValidType>;
  valid_check__contains?: InputMaybe<ValidType>;
  valid_check__in?: InputMaybe<Array<ValidType>>;
  valid_check__not?: InputMaybe<ValidType>;
  valid_check__not_contains?: InputMaybe<ValidType>;
  valid_check__not_in?: InputMaybe<Array<ValidType>>;
}

/**
 * {
 *   "name":"metadata_goods_relation",
 *   "id": "id",
 *   "goods": { "fk" : "goods_id" , "pk" : "goods_id" },
 *   "metadata": { "fk" : "metadata_id" , "pk" : "metadata_id" }
 * }
 */
export class Metadata_Goods_Relation {
  exposure_order: Scalars["Int"];
  goods: Array<Maybe<Goods>>;
  goods_id?: Maybe<Scalars["ID"]>;
  id: Scalars["UUID"];
  metadata: Array<Maybe<Metadata>>;
  metadata_id?: Maybe<Scalars["ID"]>;
}

export class Metadata_Goods_RelationCreateInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  goods: GoodsChildInput;
  metadata: MetadataChildInput;
}

export class Metadata_Goods_RelationCreateManyWithoutGoodsInput {
  create?: InputMaybe<Array<Metadata_Goods_RelationCreateWithoutGoodsInput>>;
}

export class Metadata_Goods_RelationCreateWithoutGoodsInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  metadata: MetadataChildInput;
}

export enum Metadata_Goods_RelationOrderByInput {
  CreatedAtAsc = "createdAt__ASC",
  CreatedAtDesc = "createdAt__DESC",
  ExposureOrderAsc = "exposure_order__ASC",
  ExposureOrderDesc = "exposure_order__DESC",
  GoodsIdAsc = "goods_id__ASC",
  GoodsIdDesc = "goods_id__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  MetadataIdAsc = "metadata_id__ASC",
  MetadataIdDesc = "metadata_id__DESC",
  UpdatedAtAsc = "updatedAt__ASC",
  UpdatedAtDesc = "updatedAt__DESC"
}

export class Metadata_Goods_RelationUpdateInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  goods?: InputMaybe<GoodsChildInput>;
  metadata?: InputMaybe<MetadataChildInput>;
}

export class Metadata_Goods_RelationUpdateManyWithoutGoodsInput {
  create?: InputMaybe<Array<Metadata_Goods_RelationCreateWithoutGoodsInput>>;
  update?: InputMaybe<Array<Metadata_Goods_RelationUpdateWithWhereUniqueWithoutGoodsInput>>;
}

export class Metadata_Goods_RelationUpdateWhereInput {
  goods_id?: InputMaybe<Scalars["ID"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  metadata_id?: InputMaybe<Scalars["ID"]>;
}

export class Metadata_Goods_RelationUpdateWithWhereUniqueWithoutGoodsInput {
  data: Metadata_Goods_RelationUpdateWithoutGoodsInput;
  where: UuidWhereUniqueInput;
}

export class Metadata_Goods_RelationUpdateWithoutGoodsInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  metadata?: InputMaybe<MetadataChildInput>;
}

export class Metadata_Goods_RelationWhereInput {
  AND?: InputMaybe<Array<Metadata_Goods_RelationWhereInput>>;
  NOT?: InputMaybe<Array<Metadata_Goods_RelationWhereInput>>;
  OR?: InputMaybe<Array<Metadata_Goods_RelationWhereInput>>;
  artist__lateral?: InputMaybe<Scalars["Boolean"]>;
  artist__some?: InputMaybe<ArtistWhereInput>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  exposure_order__gt?: InputMaybe<Scalars["Int"]>;
  exposure_order__gte?: InputMaybe<Scalars["Int"]>;
  exposure_order__in?: InputMaybe<Array<Scalars["Int"]>>;
  exposure_order__lt?: InputMaybe<Scalars["Int"]>;
  exposure_order__lte?: InputMaybe<Scalars["Int"]>;
  exposure_order__not?: InputMaybe<Scalars["Int"]>;
  exposure_order__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  goods__lateral?: InputMaybe<Scalars["Boolean"]>;
  goods__some?: InputMaybe<GoodsWhereInput>;
  goods_id?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  goods_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  goods_id__not_include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__exist?: InputMaybe<Scalars["Boolean"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  id__not?: InputMaybe<Scalars["UUID"]>;
  id__not_in?: InputMaybe<Array<Scalars["UUID"]>>;
  metadata_id?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  metadata_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  metadata_id__not_include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
}

/**
 * {
 *   "name":"metadata_language_relation",
 *   "id": "id",
 *   "language": { "fk" : "language_code" , "pk" : "language_code" },
 *   "metadata": { "fk" : "metadata_id" , "pk" : "metadata_id" }
 * }
 */
export class Metadata_Language_Relation {
  exposure_order: Scalars["Int"];
  id: Scalars["UUID"];
  language?: Maybe<Array<Maybe<Language>>>;
  language_code?: Maybe<Scalars["ID"]>;
  metadata?: Maybe<Array<Maybe<Metadata>>>;
  metadata_id?: Maybe<Scalars["ID"]>;
  type_kind?: Maybe<Scalars["String"]>;
}

export class Metadata_Language_RelationCreateInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  language: LanguageChildInput;
  metadata: MetadataChildInput;
}

export class Metadata_Language_RelationCreateManyWithoutLanguageInput {
  create?: InputMaybe<Array<Metadata_Language_RelationCreateWithoutLanguageInput>>;
}

export class Metadata_Language_RelationCreateManyWithoutMetadataInput {
  create?: InputMaybe<Array<Metadata_Language_RelationCreateWithoutMetadataInput>>;
}

export class Metadata_Language_RelationCreateWithoutLanguageInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  metadata?: InputMaybe<MetadataChildInput>;
  type_kind?: InputMaybe<Scalars["String"]>;
}

export class Metadata_Language_RelationCreateWithoutMetadataInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  language: LanguageChildInput;
  type_kind?: InputMaybe<Scalars["String"]>;
}

export enum Metadata_Language_RelationOrderByInput {
  ExposureOrderAsc = "exposure_order__ASC",
  ExposureOrderDesc = "exposure_order__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  LanguageCodeAsc = "language_code__ASC",
  LanguageCodeDesc = "language_code__DESC",
  MetadataIdAsc = "metadata_id__ASC",
  MetadataIdDesc = "metadata_id__DESC",
  TimestampAsc = "timestamp__ASC",
  TimestampDesc = "timestamp__DESC"
}

export class Metadata_Language_RelationUpdateInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  language?: InputMaybe<LanguageChildInput>;
  metadata?: InputMaybe<MetadataChildInput>;
  type_kind?: InputMaybe<Scalars["String"]>;
}

export class Metadata_Language_RelationUpdateManyWithoutLanguageInput {
  create?: InputMaybe<Array<Metadata_Language_RelationCreateWithoutLanguageInput>>;
  update?: InputMaybe<Array<Metadata_Language_RelationUpdateWithWhereUniqueWithoutLanguageInput>>;
}

export class Metadata_Language_RelationUpdateManyWithoutMetadataInput {
  create?: InputMaybe<Array<Metadata_Language_RelationCreateWithoutMetadataInput>>;
  update?: InputMaybe<Array<Metadata_Language_RelationUpdateWithWhereUniqueWithoutMetadataInput>>;
}

export class Metadata_Language_RelationUpdateWithWhereUniqueWithoutLanguageInput {
  data: Metadata_Language_RelationUpdateWithoutLanguageDataInput;
  where: UuidWhereUniqueInput;
}

export class Metadata_Language_RelationUpdateWithWhereUniqueWithoutMetadataInput {
  data: Metadata_Language_RelationUpdateWithoutMetadataDataInput;
  where: UuidWhereUniqueInput;
}

export class Metadata_Language_RelationUpdateWithoutLanguageDataInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  metadata?: InputMaybe<MetadataChildInput>;
  type_kind?: InputMaybe<Scalars["String"]>;
}

export class Metadata_Language_RelationUpdateWithoutMetadataDataInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  language: LanguageChildInput;
  type_kind?: InputMaybe<Scalars["String"]>;
}

export class Metadata_Language_RelationWhereInput {
  AND?: InputMaybe<Array<Metadata_Language_RelationWhereInput>>;
  NOT?: InputMaybe<Array<Metadata_Language_RelationWhereInput>>;
  OR?: InputMaybe<Array<Metadata_Language_RelationWhereInput>>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  exposure_order__gt?: InputMaybe<Scalars["Int"]>;
  exposure_order__gte?: InputMaybe<Scalars["Int"]>;
  exposure_order__in?: InputMaybe<Array<Scalars["Int"]>>;
  exposure_order__lt?: InputMaybe<Scalars["Int"]>;
  exposure_order__lte?: InputMaybe<Scalars["Int"]>;
  exposure_order__not?: InputMaybe<Scalars["Int"]>;
  exposure_order__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  id__not?: InputMaybe<Scalars["UUID"]>;
  id__not_in?: InputMaybe<Array<Scalars["UUID"]>>;
  language__lateral?: InputMaybe<Scalars["Boolean"]>;
  language__some?: InputMaybe<LanguageWhereInput>;
  language_code?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  language_code__in?: InputMaybe<Array<Scalars["ID"]>>;
  language_code__not_include?: InputMaybe<Array<Scalars["ID"]>>;
  metadata__lateral?: InputMaybe<Scalars["Boolean"]>;
  metadata__some?: InputMaybe<MetadataWhereInput>;
  metadata_id?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  metadata_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  metadata_id__not_include?: InputMaybe<Array<Scalars["ID"]>>;
  type_kind?: InputMaybe<Scalars["String"]>;
  type_kind__in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  valid_check?: InputMaybe<ValidType>;
  valid_check__in?: InputMaybe<Array<InputMaybe<ValidType>>>;
  valid_check__not?: InputMaybe<ValidType>;
  valid_check__not_in?: InputMaybe<Array<InputMaybe<ValidType>>>;
}

/**
 * {
 *   "name":"metadata_organization_relation",
 *   "id": "id",
 *   "metadata": { "fk" : "metadata_id" , "pk" : "metadata_id" }
 * }
 */
export class Metadata_Organization_Relation {
  artist_id: Scalars["ID"];
  id: Scalars["ID"];
  metadata_id: Scalars["ID"];
  org_id: Scalars["Int"];
  valid_check?: Maybe<ValidType>;
}

export enum Metadata_Organization_RelationOrderByInput {
  ArtistIdAsc = "artist_id__ASC",
  ArtistIdDesc = "artist_id__DESC",
  CreatedAtAsc = "createdAt__ASC",
  CreatedAtDesc = "createdAt__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  MetadataIdAsc = "metadata_id__ASC",
  MetadataIdDesc = "metadata_id__DESC",
  OrgIdAsc = "org_id__ASC",
  OrgIdDesc = "org_id__DESC",
  UpdatedAtAsc = "updatedAt__ASC",
  UpdatedAtDesc = "updatedAt__DESC",
  ValidCheckAsc = "valid_check__ASC",
  ValidCheckDesc = "valid_check__DESC"
}

export class Metadata_Organization_RelationUpdateInput {
  artist_id?: InputMaybe<Scalars["ID"]>;
  metadata_id?: InputMaybe<Scalars["ID"]>;
  org_id?: InputMaybe<Scalars["ID"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class Metadata_Organization_RelationWhereInput {
  AND?: InputMaybe<Array<Metadata_Organization_RelationWhereInput>>;
  NOT?: InputMaybe<Array<Metadata_Organization_RelationWhereInput>>;
  OR?: InputMaybe<Array<Metadata_Organization_RelationWhereInput>>;
  artist_id?: InputMaybe<Scalars["ID"]>;
  artist_id__gt?: InputMaybe<Scalars["ID"]>;
  artist_id__gte?: InputMaybe<Scalars["ID"]>;
  artist_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  artist_id__lt?: InputMaybe<Scalars["ID"]>;
  artist_id__lte?: InputMaybe<Scalars["ID"]>;
  artist_id__not?: InputMaybe<Scalars["ID"]>;
  artist_id__not_in?: InputMaybe<Array<Scalars["ID"]>>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  metadata_id?: InputMaybe<Scalars["ID"]>;
  metadata_id__gt?: InputMaybe<Scalars["ID"]>;
  metadata_id__gte?: InputMaybe<Scalars["ID"]>;
  metadata_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  metadata_id__lt?: InputMaybe<Scalars["ID"]>;
  metadata_id__lte?: InputMaybe<Scalars["ID"]>;
  metadata_id__not?: InputMaybe<Scalars["ID"]>;
  metadata_id__not_in?: InputMaybe<Array<Scalars["ID"]>>;
  org_id?: InputMaybe<Scalars["ID"]>;
  org_id__gt?: InputMaybe<Scalars["ID"]>;
  org_id__gte?: InputMaybe<Scalars["Int"]>;
  org_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  org_id__lt?: InputMaybe<Scalars["ID"]>;
  org_id__lte?: InputMaybe<Scalars["ID"]>;
  org_id__not?: InputMaybe<Scalars["ID"]>;
  org_id__not_in?: InputMaybe<Array<Scalars["ID"]>>;
  valid_check?: InputMaybe<ValidType>;
  valid_check__contains?: InputMaybe<ValidType>;
  valid_check__in?: InputMaybe<Array<ValidType>>;
  valid_check__not?: InputMaybe<ValidType>;
  valid_check__not_contains?: InputMaybe<ValidType>;
  valid_check__not_in?: InputMaybe<Array<ValidType>>;
}

export class Metadata_Organization_RelationWhereUniqueInput {
  id?: InputMaybe<Scalars["UUID"]>;
}

/**
 * {
 *   "name":"metadata_playlist_relation",
 *   "id": "id",
 *   "playlist": { "fk" : "playlist_id" , "pk" : "playlist_id" },
 *   "metadata": { "fk" : "metadata_id" , "pk" : "metadata_id" },
 *   "playlist_users_relation": { "fk" : "playlist_id" , "pk" : "playlist_id" }
 * }
 */
export class Metadata_Playlist_Relation {
  exposure_order: Scalars["Int"];
  id: Scalars["UUID"];
  metadata?: Maybe<Array<Maybe<Metadata>>>;
  metadata_id?: Maybe<Scalars["ID"]>;
  playlist?: Maybe<Array<Maybe<Playlist>>>;
  playlist_id?: Maybe<Scalars["ID"]>;
  playlist_users_relation?: Maybe<Array<Maybe<Playlist_Users_Relation>>>;
  timestamp?: Maybe<Scalars["DateTime"]>;
  valid_check?: Maybe<ValidType>;
}

export class Metadata_Playlist_RelationCreateInput {
  booking_order?: InputMaybe<Scalars["Int"]>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  metadata: MetadataChildInput;
  playlist: PlaylistChildInput;
}

export class Metadata_Playlist_RelationCreateManyWithoutMetadataInput {
  create?: InputMaybe<Array<Metadata_Playlist_RelationCreateWithoutMetadataInput>>;
}

export class Metadata_Playlist_RelationCreateManyWithoutPlaylistInput {
  create?: InputMaybe<Array<Metadata_Playlist_RelationCreateWithoutPlaylistInput>>;
}

export class Metadata_Playlist_RelationCreateWithoutMetadataInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  playlist: PlaylistChildInput;
}

export class Metadata_Playlist_RelationCreateWithoutPlaylistInput {
  booking_order?: InputMaybe<Scalars["Int"]>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  metadata?: InputMaybe<MetadataChildInput>;
}

export enum Metadata_Playlist_RelationOrderByInput {
  ExposureOrderAsc = "exposure_order__ASC",
  ExposureOrderDesc = "exposure_order__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  MetadataIdAsc = "metadata_id__ASC",
  MetadataIdDesc = "metadata_id__DESC",
  PlaylistIdAsc = "playlist_id__ASC",
  PlaylistIdDesc = "playlist_id__DESC",
  TimestampAsc = "timestamp__ASC",
  TimestampDesc = "timestamp__DESC"
}

export class Metadata_Playlist_RelationUpdateInput {
  booking_order?: InputMaybe<Scalars["Int"]>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  metadata?: InputMaybe<MetadataChildInput>;
  playlist?: InputMaybe<PlaylistChildInput>;
  timestamp?: InputMaybe<Scalars["DateTime"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class Metadata_Playlist_RelationUpdateManyWithoutMetadataInput {
  create?: InputMaybe<Array<Metadata_Playlist_RelationCreateWithoutMetadataInput>>;
  update?: InputMaybe<Array<Metadata_Playlist_RelationUpdateWithWhereUniqueWithoutMetadataInput>>;
}

export class Metadata_Playlist_RelationUpdateManyWithoutPlaylistInput {
  create?: InputMaybe<Array<Metadata_Playlist_RelationCreateWithoutPlaylistInput>>;
  update?: InputMaybe<Array<Metadata_Playlist_RelationUpdateWithWhereUniqueWithoutPlaylistInput>>;
}

export class Metadata_Playlist_RelationUpdateWithWhereUniqueWithoutMetadataInput {
  data: Metadata_Playlist_RelationUpdateWithoutMetadataDataInput;
  where: UuidWhereUniqueInput;
}

export class Metadata_Playlist_RelationUpdateWithWhereUniqueWithoutPlaylistInput {
  data: Metadata_Playlist_RelationUpdateWithoutPlaylistDataInput;
  where: UuidWhereUniqueInput;
}

export class Metadata_Playlist_RelationUpdateWithoutMetadataDataInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  playlist: PlaylistChildInput;
  valid_check?: InputMaybe<ValidType>;
}

export class Metadata_Playlist_RelationUpdateWithoutPlaylistDataInput {
  booking_order?: InputMaybe<Scalars["Int"]>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  metadata?: InputMaybe<MetadataChildInput>;
  timestamp?: InputMaybe<Scalars["DateTime"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class Metadata_Playlist_RelationWhereInput {
  AND?: InputMaybe<Array<Metadata_Playlist_RelationWhereInput>>;
  NOT?: InputMaybe<Array<Metadata_Playlist_RelationWhereInput>>;
  OR?: InputMaybe<Array<Metadata_Playlist_RelationWhereInput>>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  exposure_order__gt?: InputMaybe<Scalars["Int"]>;
  exposure_order__gte?: InputMaybe<Scalars["Int"]>;
  exposure_order__in?: InputMaybe<Array<Scalars["Int"]>>;
  exposure_order__lt?: InputMaybe<Scalars["Int"]>;
  exposure_order__lte?: InputMaybe<Scalars["Int"]>;
  exposure_order__not?: InputMaybe<Scalars["Int"]>;
  exposure_order__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  id__not?: InputMaybe<Scalars["UUID"]>;
  id__not_in?: InputMaybe<Array<Scalars["UUID"]>>;
  metadata__lateral?: InputMaybe<Scalars["Boolean"]>;
  metadata__some?: InputMaybe<MetadataWhereInput>;
  metadata_id?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  metadata_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  metadata_id__not_include?: InputMaybe<Array<Scalars["ID"]>>;
  playlist__lateral?: InputMaybe<Scalars["Boolean"]>;
  playlist__some?: InputMaybe<PlaylistWhereInput>;
  playlist_id?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  playlist_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  playlist_id__not_include?: InputMaybe<Array<Scalars["ID"]>>;
  playlist_users_relation__some?: InputMaybe<Playlist_Users_RelationWhereInput>;
  valid_check?: InputMaybe<ValidType>;
  valid_check__in?: InputMaybe<Array<InputMaybe<ValidType>>>;
  valid_check__not?: InputMaybe<ValidType>;
  valid_check__not_in?: InputMaybe<Array<InputMaybe<ValidType>>>;
}

/**
 * {
 *   "name":"metadata_self_relation",
 *   "id": "id",
 *   "metadata": { "fk" : "list_metadata_id", "pk" : "metadata_id" },
 *   "metadata_self_relations_element_metadata": { "id":"element_metadata_id", "fk" : "metadata_id" , "pk" : "element_metadata_id", "table":"metadata"},
 *   "metadata_self_relations_list_metadata": { "id":"list_metadata_id", "fk" : "metadata_id" , "pk" : "list_metadata_id", "table":"metadata"}
 * }
 */
export class Metadata_Self_Relation {
  element_metadata_id?: Maybe<Scalars["ID"]>;
  exposure_order: Scalars["Int"];
  id: Scalars["UUID"];
  list_metadata_id?: Maybe<Scalars["ID"]>;
  metadata_self_relations_element_metadata: Array<Maybe<Metadata>>;
  metadata_self_relations_list_metadata: Array<Maybe<Metadata>>;
  type_function: Scalars["String"];
  valid_check?: Maybe<ValidType>;
}

export class Metadata_Self_RelationCreateManyWithoutElement_MetadataInput {
  create?: InputMaybe<Array<Metadata_Self_RelationCreateWithoutElement_MetadataInput>>;
}

export class Metadata_Self_RelationCreateManyWithoutList_MetadataInput {
  create?: InputMaybe<Array<Metadata_Self_RelationCreateWithoutList_MetadataInput>>;
}

export class Metadata_Self_RelationCreateWithoutElement_MetadataInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  metadata_self_relations_list_metadata: MetadataChildInput;
  type_function: Scalars["String"];
  valid_check?: InputMaybe<ValidType>;
}

export class Metadata_Self_RelationCreateWithoutList_MetadataInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  metadata_self_relations_element_metadata: MetadataChildInput;
  type_function: Scalars["String"];
  valid_check?: InputMaybe<ValidType>;
}

export enum Metadata_Self_RelationOrderByInput {
  CreatedAtAsc = "createdAt__ASC",
  CreatedAtDesc = "createdAt__DESC",
  ExposureOrderAsc = "exposure_order__ASC",
  ExposureOrderDesc = "exposure_order__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  TypeFunctionAsc = "type_function__ASC",
  TypeFunctionDesc = "type_function__DESC",
  UpdatedAtAsc = "updatedAt__ASC",
  UpdatedAtDesc = "updatedAt__DESC",
  ValidCheckAsc = "valid_check__ASC",
  ValidCheckDesc = "valid_check__DESC"
}

export class Metadata_Self_RelationUpdateInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  metadata_self_relations_element_metadata?: InputMaybe<MetadataChildInput>;
  metadata_self_relations_list_metadata?: InputMaybe<MetadataChildInput>;
  type_function?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class Metadata_Self_RelationUpdateManyWithoutElement_MetadataInput {
  create?: InputMaybe<Array<Metadata_Self_RelationCreateWithoutElement_MetadataInput>>;
  update?: InputMaybe<Array<Metadata_Self_RelationUpdateWithWhereUniqueWithoutElement_MetadataInput>>;
}

export class Metadata_Self_RelationUpdateManyWithoutList_MetadataInput {
  create?: InputMaybe<Array<Metadata_Self_RelationCreateWithoutList_MetadataInput>>;
  update?: InputMaybe<Array<Metadata_Self_RelationUpdateWithWhereUniqueWithoutList_MetadataInput>>;
}

export class Metadata_Self_RelationUpdateWithWhereUniqueWithoutElement_MetadataInput {
  data: Metadata_Self_RelationUpdateWithoutElement_MetadataDataInput;
  where: UuidWhereUniqueInput;
}

export class Metadata_Self_RelationUpdateWithWhereUniqueWithoutList_MetadataInput {
  data: Metadata_Self_RelationUpdateWithoutList_MetadataDataInput;
  where: UuidWhereUniqueInput;
}

export class Metadata_Self_RelationUpdateWithoutElement_MetadataDataInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  list_metadata?: InputMaybe<MetadataChildInput>;
  type_function?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class Metadata_Self_RelationUpdateWithoutList_MetadataDataInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  metadata_self_relations_element_metadata?: InputMaybe<MetadataChildInput>;
  type_function?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class Metadata_Self_RelationWhereInput {
  AND?: InputMaybe<Array<Metadata_Self_RelationWhereInput>>;
  NOT?: InputMaybe<Array<Metadata_Self_RelationWhereInput>>;
  OR?: InputMaybe<Array<Metadata_Self_RelationWhereInput>>;
  element_metadata_id?: InputMaybe<Scalars["ID"]>;
  element_metadata_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  exposure_order__gt?: InputMaybe<Scalars["Int"]>;
  exposure_order__gte?: InputMaybe<Scalars["Int"]>;
  exposure_order__in?: InputMaybe<Array<Scalars["Int"]>>;
  exposure_order__lt?: InputMaybe<Scalars["Int"]>;
  exposure_order__lte?: InputMaybe<Scalars["Int"]>;
  exposure_order__not?: InputMaybe<Scalars["Int"]>;
  exposure_order__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__exist?: InputMaybe<Scalars["Boolean"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  list_metadata_id?: InputMaybe<Scalars["ID"]>;
  list_metadata_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  metadata_self_relations_element_metadata__some?: InputMaybe<MetadataWhereInput>;
  metadata_self_relations_list_metadata__some?: InputMaybe<MetadataWhereInput>;
  type_function?: InputMaybe<Scalars["String"]>;
  type_function__contains?: InputMaybe<Scalars["String"]>;
  type_function__ends_with?: InputMaybe<Scalars["String"]>;
  type_function__exist?: InputMaybe<Scalars["Boolean"]>;
  type_function__in?: InputMaybe<Array<Scalars["String"]>>;
  type_function__not?: InputMaybe<Scalars["String"]>;
  type_function__not_contains?: InputMaybe<Scalars["String"]>;
  type_function__not_ends_with?: InputMaybe<Scalars["String"]>;
  type_function__not_in?: InputMaybe<Array<Scalars["String"]>>;
  type_function__not_starts_with?: InputMaybe<Scalars["String"]>;
  type_function__starts_with?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
  valid_check__contains?: InputMaybe<ValidType>;
  valid_check__in?: InputMaybe<Array<ValidType>>;
  valid_check__not?: InputMaybe<ValidType>;
  valid_check__not_contains?: InputMaybe<ValidType>;
  valid_check__not_in?: InputMaybe<Array<ValidType>>;
}

/**
 * {
 *   "name":"metadata_structure",
 *   "id": "structure_id",
 *   "type": "Int",
 *   "metadata": { "fk" : "metadata_id" , "pk" : "metadata_id" },
 *   "list": { "fk" : "structure_root_id" , "pk" : "structure_root_id" ,"name": "metadata_structure"},
 *   "child": { "fk" : "parent_id" , "pk" : "structure_id" },
 *   "parent": { "fk" : "structure_id" , "pk" : "parent_id" }
 * }
 */
export class Metadata_Structure {
  child?: Maybe<Array<Maybe<Metadata_Structure>>>;
  depth: Scalars["Int"];
  inner_order: Scalars["Int"];
  list?: Maybe<Array<Maybe<Metadata_Structure>>>;
  metadata: Array<Maybe<Metadata>>;
  metadata_id?: Maybe<Scalars["ID"]>;
  parent?: Maybe<Array<Maybe<Metadata_Structure>>>;
  parent_id: Scalars["ID"];
  structure_id: Scalars["ID"];
  structure_root_id?: Maybe<Scalars["ID"]>;
  valid_check?: Maybe<ValidType>;
}

/**
 * {
 *   "name":"metadata_structure",
 *   "id": "structure_id",
 *   "type": "Int",
 *   "metadata": { "fk" : "metadata_id" , "pk" : "metadata_id" },
 *   "list": { "fk" : "structure_root_id" , "pk" : "structure_root_id" ,"name": "metadata_structure"},
 *   "child": { "fk" : "parent_id" , "pk" : "structure_id" },
 *   "parent": { "fk" : "structure_id" , "pk" : "parent_id" }
 * }
 */
export class Metadata_StructureChildArgs {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Metadata_StructureOrderByInput>>>;
  where?: InputMaybe<Metadata_StructureWhereInput>;
}

/**
 * {
 *   "name":"metadata_structure",
 *   "id": "structure_id",
 *   "type": "Int",
 *   "metadata": { "fk" : "metadata_id" , "pk" : "metadata_id" },
 *   "list": { "fk" : "structure_root_id" , "pk" : "structure_root_id" ,"name": "metadata_structure"},
 *   "child": { "fk" : "parent_id" , "pk" : "structure_id" },
 *   "parent": { "fk" : "structure_id" , "pk" : "parent_id" }
 * }
 */
export class Metadata_StructureListArgs {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Metadata_StructureOrderByInput>>>;
  where?: InputMaybe<Metadata_StructureWhereInput>;
}

export class Metadata_StructureCreateManyWithoutMetadataInput {
  create?: InputMaybe<Array<Metadata_StructureCreateWithoutMetadataInput>>;
}

export class Metadata_StructureCreateWithoutMetadataInput {
  booking_order?: InputMaybe<Scalars["Int"]>;
  inner_order?: InputMaybe<Scalars["Int"]>;
  parent_id: Scalars["Int"];
  valid_check?: InputMaybe<ValidType>;
}

export enum Metadata_StructureOrderByInput {
  CreatedAtAsc = "createdAt__ASC",
  CreatedAtDesc = "createdAt__DESC",
  DepthAsc = "depth__ASC",
  DepthDesc = "depth__DESC",
  InnerOrderAsc = "inner_order__ASC",
  InnerOrderDesc = "inner_order__DESC",
  ParentIdAsc = "parent_id__ASC",
  ParentIdDesc = "parent_id__DESC",
  StructureIdAsc = "structure_id__ASC",
  StructureIdDesc = "structure_id__DESC",
  StructureRootIdAsc = "structure_root_id__ASC",
  StructureRootIdDesc = "structure_root_id__DESC",
  UpdatedAtAsc = "updatedAt__ASC",
  UpdatedAtDesc = "updatedAt__DESC",
  ValidCheckAsc = "valid_check__ASC",
  ValidCheckDesc = "valid_check__DESC"
}

export class Metadata_StructureUpdateInput {
  booking_order?: InputMaybe<Scalars["Int"]>;
  depth?: InputMaybe<Scalars["Int"]>;
  inner_order?: InputMaybe<Scalars["Int"]>;
  parent_id?: InputMaybe<Scalars["Int"]>;
  structure_root_id?: InputMaybe<Scalars["Int"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class Metadata_StructureUpdateManyWithoutMetadataInput {
  create?: InputMaybe<Array<Metadata_StructureCreateWithoutMetadataInput>>;
  update?: InputMaybe<Array<Metadata_StructureUpdateWithWhereUniqueWithoutMetadataInput>>;
}

export class Metadata_StructureUpdateWithWhereUniqueWithoutMetadataInput {
  data: Metadata_StructureUpdateWithoutMetadataDataInput;
  where: Metadata_StructureWhereUniqueInput;
}

export class Metadata_StructureUpdateWithoutMetadataDataInput {
  booking_order?: InputMaybe<Scalars["Int"]>;
  depth?: InputMaybe<Scalars["Int"]>;
  inner_order?: InputMaybe<Scalars["Int"]>;
  parent_id?: InputMaybe<Scalars["Int"]>;
  structure_root_id?: InputMaybe<Scalars["Int"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class Metadata_StructureWhereInput {
  AND?: InputMaybe<Array<Metadata_StructureWhereInput>>;
  NOT?: InputMaybe<Array<Metadata_StructureWhereInput>>;
  OR?: InputMaybe<Array<Metadata_StructureWhereInput>>;
  depth?: InputMaybe<Scalars["Int"]>;
  depth__gt?: InputMaybe<Scalars["Int"]>;
  depth__gte?: InputMaybe<Scalars["Int"]>;
  depth__in?: InputMaybe<Array<Scalars["Int"]>>;
  depth__lt?: InputMaybe<Scalars["Int"]>;
  depth__lte?: InputMaybe<Scalars["Int"]>;
  depth__not?: InputMaybe<Scalars["Int"]>;
  depth__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  inner_order?: InputMaybe<Scalars["Int"]>;
  inner_order__gt?: InputMaybe<Scalars["Int"]>;
  inner_order__gte?: InputMaybe<Scalars["Int"]>;
  inner_order__in?: InputMaybe<Array<Scalars["Int"]>>;
  inner_order__lt?: InputMaybe<Scalars["Int"]>;
  inner_order__lte?: InputMaybe<Scalars["Int"]>;
  inner_order__not?: InputMaybe<Scalars["Int"]>;
  inner_order__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  list__lateral?: InputMaybe<Scalars["Boolean"]>;
  list__some?: InputMaybe<Metadata_StructureWhereInput>;
  metadata__lateral?: InputMaybe<Scalars["Boolean"]>;
  metadata__some?: InputMaybe<MetadataWhereInput>;
  metadata_id?: InputMaybe<Scalars["ID"]>;
  metadata_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  metadata_id__not_include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  parent_id?: InputMaybe<Scalars["Int"]>;
  parent_id__gt?: InputMaybe<Scalars["Int"]>;
  parent_id__gte?: InputMaybe<Scalars["Int"]>;
  parent_id__in?: InputMaybe<Array<Scalars["Int"]>>;
  parent_id__lt?: InputMaybe<Scalars["Int"]>;
  parent_id__lte?: InputMaybe<Scalars["Int"]>;
  parent_id__not?: InputMaybe<Scalars["Int"]>;
  parent_id__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  structure_id?: InputMaybe<Scalars["ID"]>;
  structure_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  structure_root_id?: InputMaybe<Scalars["Int"]>;
  structure_root_id__gt?: InputMaybe<Scalars["Int"]>;
  structure_root_id__gte?: InputMaybe<Scalars["Int"]>;
  structure_root_id__in?: InputMaybe<Array<Scalars["Int"]>>;
  structure_root_id__lt?: InputMaybe<Scalars["Int"]>;
  structure_root_id__lte?: InputMaybe<Scalars["Int"]>;
  structure_root_id__not?: InputMaybe<Scalars["Int"]>;
  structure_root_id__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  valid_check?: InputMaybe<ValidType>;
  valid_check__contains?: InputMaybe<ValidType>;
  valid_check__in?: InputMaybe<Array<ValidType>>;
  valid_check__not?: InputMaybe<ValidType>;
  valid_check__not_contains?: InputMaybe<ValidType>;
  valid_check__not_in?: InputMaybe<Array<ValidType>>;
}

export class Metadata_StructureWhereUniqueInput {
  structure_id?: InputMaybe<Scalars["ID"]>;
}

/**
 * {
 *   "name":"metadata_subdata_unique",
 *   "id": "id",
 *   "metadata": { "fk" : "metadata_id" , "pk" : "metadata_id" }
 * }
 */
export class Metadata_Subdata_Unique {
  id: Scalars["UUID"];
  metadata: Array<Maybe<Metadata>>;
  metadata_id?: Maybe<Scalars["ID"]>;
  type_subdata_category: Scalars["String"];
  type_subdata_field: Scalars["String"];
  valid_check?: Maybe<ValidType>;
  value: Scalars["String"];
}

export class Metadata_Subdata_UniqueCreateManyWithoutMetadataInput {
  create?: InputMaybe<Array<Metadata_Subdata_UniqueCreateWithoutMetadataInput>>;
}

export class Metadata_Subdata_UniqueCreateWithoutMetadataInput {
  type_subdata_category: Scalars["String"];
  type_subdata_field: Scalars["String"];
  valid_check?: InputMaybe<ValidType>;
  value: Scalars["String"];
}

export enum Metadata_Subdata_UniqueOrderByInput {
  CreatedAtAsc = "createdAt__ASC",
  CreatedAtDesc = "createdAt__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  TypeSubdataCategoryAsc = "type_subdata_category__ASC",
  TypeSubdataCategoryDesc = "type_subdata_category__DESC",
  TypeSubdataFieldAsc = "type_subdata_field__ASC",
  TypeSubdataFieldDesc = "type_subdata_field__DESC",
  UpdatedAtAsc = "updatedAt__ASC",
  UpdatedAtDesc = "updatedAt__DESC",
  ValidCheckAsc = "valid_check__ASC",
  ValidCheckDesc = "valid_check__DESC",
  ValueAsc = "value__ASC",
  ValueDesc = "value__DESC"
}

export class Metadata_Subdata_UniqueUpdateInput {
  metadata?: InputMaybe<MetadataChildInput>;
  type_subdata_category?: InputMaybe<Scalars["String"]>;
  type_subdata_field?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
  value?: InputMaybe<Scalars["String"]>;
}

export class Metadata_Subdata_UniqueUpdateManyWithoutMetadataInput {
  create?: InputMaybe<Array<Metadata_Subdata_UniqueCreateWithoutMetadataInput>>;
  update?: InputMaybe<Array<Metadata_Subdata_UniqueUpdateWithWhereUniqueWithoutMetadataInput>>;
}

export class Metadata_Subdata_UniqueUpdateWithWhereUniqueWithoutMetadataInput {
  data: Metadata_Subdata_UniqueUpdateWithoutMetadataDataInput;
  where: UuidWhereUniqueInput;
}

export class Metadata_Subdata_UniqueUpdateWithoutMetadataDataInput {
  type_subdata_category?: InputMaybe<Scalars["String"]>;
  type_subdata_field?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
  value?: InputMaybe<Scalars["String"]>;
}

export class Metadata_Subdata_UniqueWhereInput {
  AND?: InputMaybe<Array<Metadata_Subdata_UniqueWhereInput>>;
  NOT?: InputMaybe<Array<Metadata_Subdata_UniqueWhereInput>>;
  OR?: InputMaybe<Array<Metadata_Subdata_UniqueWhereInput>>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  metadata__lateral?: InputMaybe<Scalars["Boolean"]>;
  metadata__some?: InputMaybe<MetadataWhereInput>;
  metadata_id?: InputMaybe<Scalars["ID"]>;
  metadata_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  metadata_id__not_include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  type_subdata_category?: InputMaybe<Scalars["String"]>;
  type_subdata_category__contains?: InputMaybe<Scalars["String"]>;
  type_subdata_category__ends_with?: InputMaybe<Scalars["String"]>;
  type_subdata_category__in?: InputMaybe<Array<Scalars["String"]>>;
  type_subdata_category__not?: InputMaybe<Scalars["String"]>;
  type_subdata_category__not_contains?: InputMaybe<Scalars["String"]>;
  type_subdata_category__not_ends_with?: InputMaybe<Scalars["String"]>;
  type_subdata_category__not_in?: InputMaybe<Array<Scalars["String"]>>;
  type_subdata_category__not_starts_with?: InputMaybe<Scalars["String"]>;
  type_subdata_category__starts_with?: InputMaybe<Scalars["String"]>;
  type_subdata_field?: InputMaybe<Scalars["String"]>;
  type_subdata_field__contains?: InputMaybe<Scalars["String"]>;
  type_subdata_field__ends_with?: InputMaybe<Scalars["String"]>;
  type_subdata_field__in?: InputMaybe<Array<Scalars["String"]>>;
  type_subdata_field__not?: InputMaybe<Scalars["String"]>;
  type_subdata_field__not_contains?: InputMaybe<Scalars["String"]>;
  type_subdata_field__not_ends_with?: InputMaybe<Scalars["String"]>;
  type_subdata_field__not_in?: InputMaybe<Array<Scalars["String"]>>;
  type_subdata_field__not_starts_with?: InputMaybe<Scalars["String"]>;
  type_subdata_field__starts_with?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
  valid_check__contains?: InputMaybe<ValidType>;
  valid_check__in?: InputMaybe<Array<ValidType>>;
  valid_check__not?: InputMaybe<ValidType>;
  valid_check__not_contains?: InputMaybe<ValidType>;
  valid_check__not_in?: InputMaybe<Array<ValidType>>;
  value?: InputMaybe<Scalars["String"]>;
  value__contains?: InputMaybe<Scalars["String"]>;
  value__ends_with?: InputMaybe<Scalars["String"]>;
  value__in?: InputMaybe<Array<Scalars["String"]>>;
  value__json_contains?: InputMaybe<Scalars["String"]>;
  value__json_filter?: InputMaybe<JsonFilter>;
  value__not?: InputMaybe<Scalars["String"]>;
  value__not_contains?: InputMaybe<Scalars["String"]>;
  value__not_ends_with?: InputMaybe<Scalars["String"]>;
  value__not_in?: InputMaybe<Array<Scalars["String"]>>;
  value__not_starts_with?: InputMaybe<Scalars["String"]>;
  value__starts_with?: InputMaybe<Scalars["String"]>;
}

/**
 * {
 *   "name":"metadata_title",
 *   "id": "id",
 *   "metadata": { "fk" : "metadata_id" , "pk" : "metadata_id" },
 *   "language": { "fk" : "language_code" , "pk" : "language_code" },
 *   "selfByMetadata": { "fk" : "metadata_id" , "pk" : "metadata_id", "name": "metadata_title" }
 * }
 */
export class Metadata_Title {
  exposure_order: Scalars["Int"];
  id: Scalars["UUID"];
  language?: Maybe<Array<Maybe<Language>>>;
  language_code?: Maybe<Scalars["ID"]>;
  metadata: Array<Maybe<Metadata>>;
  metadata_id?: Maybe<Scalars["ID"]>;
  selfByMetadata?: Maybe<Array<Maybe<Metadata_Title>>>;
  tsvec_words?: Maybe<Scalars["String"]>;
  type_metadata_title: Scalars["String"];
  valid_check?: Maybe<ValidType>;
  value: Scalars["String"];
}

/**
 * {
 *   "name":"metadata_title",
 *   "id": "id",
 *   "metadata": { "fk" : "metadata_id" , "pk" : "metadata_id" },
 *   "language": { "fk" : "language_code" , "pk" : "language_code" },
 *   "selfByMetadata": { "fk" : "metadata_id" , "pk" : "metadata_id", "name": "metadata_title" }
 * }
 */
export class Metadata_TitleLanguageArgs {
  where?: InputMaybe<LanguageWhereUniqueInput>;
}

export class Metadata_TitleCreateManyWithoutMetadataInput {
  create?: InputMaybe<Array<Metadata_TitleCreateWithoutMetadataInput>>;
}

export class Metadata_TitleCreateWithoutMetadataInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  language: LanguageChildInput;
  type_metadata_title: Scalars["String"];
  valid_check?: InputMaybe<ValidType>;
  value: Scalars["String"];
}

export enum Metadata_TitleOrderByInput {
  CreatedAtAsc = "createdAt__ASC",
  CreatedAtDesc = "createdAt__DESC",
  ExposureOrderAsc = "exposure_order__ASC",
  ExposureOrderDesc = "exposure_order__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  Rand = "rand",
  TypeMetadataTitleAsc = "type_metadata_title__ASC",
  TypeMetadataTitleDesc = "type_metadata_title__DESC",
  UpdatedAtAsc = "updatedAt__ASC",
  UpdatedAtDesc = "updatedAt__DESC",
  ValidCheckAsc = "valid_check__ASC",
  ValidCheckDesc = "valid_check__DESC",
  ValueAsc = "value__ASC",
  ValueDesc = "value__DESC",
  ValueSimilarity = "value__SIMILARITY"
}

export class Metadata_TitleUpdateInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  language?: InputMaybe<LanguageChildInput>;
  metadata?: InputMaybe<MetadataChildInput>;
  type_metadata_title?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
  value?: InputMaybe<Scalars["String"]>;
}

export class Metadata_TitleUpdateManyWithoutMetadataInput {
  create?: InputMaybe<Array<Metadata_TitleCreateWithoutMetadataInput>>;
  update?: InputMaybe<Array<Metadata_TitleUpdateWithWhereUniqueWithoutMetadataInput>>;
}

export class Metadata_TitleUpdateWithWhereUniqueWithoutMetadataInput {
  data: Metadata_TitleUpdateWithoutMetadataDataInput;
  where: UuidWhereUniqueInput;
}

export class Metadata_TitleUpdateWithoutMetadataDataInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  language?: InputMaybe<LanguageChildInput>;
  type_metadata_title?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
  value?: InputMaybe<Scalars["String"]>;
}

export class Metadata_TitleWhereInput {
  AND?: InputMaybe<Array<Metadata_TitleWhereInput>>;
  NOT?: InputMaybe<Array<Metadata_TitleWhereInput>>;
  OR?: InputMaybe<Array<Metadata_TitleWhereInput>>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  exposure_order__gt?: InputMaybe<Scalars["Int"]>;
  exposure_order__gte?: InputMaybe<Scalars["Int"]>;
  exposure_order__in?: InputMaybe<Array<Scalars["Int"]>>;
  exposure_order__lt?: InputMaybe<Scalars["Int"]>;
  exposure_order__lte?: InputMaybe<Scalars["Int"]>;
  exposure_order__not?: InputMaybe<Scalars["Int"]>;
  exposure_order__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  language__lateral?: InputMaybe<Scalars["Boolean"]>;
  language__some?: InputMaybe<LanguageWhereInput>;
  language_code?: InputMaybe<Scalars["ID"]>;
  language_code__in?: InputMaybe<Array<Scalars["ID"]>>;
  language_code__not_include?: InputMaybe<Scalars["ID"]>;
  metadata__lateral?: InputMaybe<Scalars["Boolean"]>;
  metadata__some?: InputMaybe<MetadataWhereInput>;
  metadata_id?: InputMaybe<Scalars["ID"]>;
  metadata_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  metadata_id__not_include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  selfByMetadata__some?: InputMaybe<Metadata_TitleWhereInput>;
  tsvec_words__search?: InputMaybe<Scalars["String"]>;
  tsvec_words__search_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  type_metadata_title?: InputMaybe<Scalars["String"]>;
  type_metadata_title__contains?: InputMaybe<Scalars["String"]>;
  type_metadata_title__ends_with?: InputMaybe<Scalars["String"]>;
  type_metadata_title__in?: InputMaybe<Array<Scalars["String"]>>;
  type_metadata_title__not?: InputMaybe<Scalars["String"]>;
  type_metadata_title__not_contains?: InputMaybe<Scalars["String"]>;
  type_metadata_title__not_ends_with?: InputMaybe<Scalars["String"]>;
  type_metadata_title__not_in?: InputMaybe<Array<Scalars["String"]>>;
  type_metadata_title__not_starts_with?: InputMaybe<Scalars["String"]>;
  type_metadata_title__starts_with?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
  valid_check__contains?: InputMaybe<ValidType>;
  valid_check__in?: InputMaybe<Array<ValidType>>;
  valid_check__not?: InputMaybe<ValidType>;
  valid_check__not_contains?: InputMaybe<ValidType>;
  valid_check__not_in?: InputMaybe<Array<ValidType>>;
  value?: InputMaybe<Scalars["String"]>;
  value__contains?: InputMaybe<Scalars["String"]>;
  value__ends_with?: InputMaybe<Scalars["String"]>;
  value__in?: InputMaybe<Array<Scalars["String"]>>;
  value__not?: InputMaybe<Scalars["String"]>;
  value__not_contains?: InputMaybe<Scalars["String"]>;
  value__not_ends_with?: InputMaybe<Scalars["String"]>;
  value__not_in?: InputMaybe<Array<Scalars["String"]>>;
  value__not_starts_with?: InputMaybe<Scalars["String"]>;
  value__starts_with?: InputMaybe<Scalars["String"]>;
}

/**
 * {
 *   "name":"metadata_url",
 *   "id": "id",
 *   "metadata": { "fk" : "metadata_id" , "pk" : "metadata_id" }
 * }
 */
export class Metadata_Url {
  exposure_order: Scalars["Int"];
  id: Scalars["UUID"];
  metadata: Array<Maybe<Metadata>>;
  metadata_id?: Maybe<Scalars["ID"]>;
  source?: Maybe<Scalars["String"]>;
  thumbnail?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  type_url: Scalars["String"];
  url: Scalars["String"];
  valid_check?: Maybe<ValidType>;
}

export class Metadata_UrlCreateManyWithoutArtistInput {
  create?: InputMaybe<Array<Metadata_UrlCreateWithoutArtistInput>>;
}

export class Metadata_UrlCreateWithoutArtistInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  source?: InputMaybe<Scalars["String"]>;
  thumbnail?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  type_url: Scalars["String"];
  url: Scalars["String"];
  valid_check?: InputMaybe<ValidType>;
}

export enum Metadata_UrlOrderByInput {
  ExposureOrderAsc = "exposure_order__ASC",
  ExposureOrderDesc = "exposure_order__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  TypeUrlAsc = "type_url__ASC",
  TypeUrlDesc = "type_url__DESC",
  UrlAsc = "url__ASC",
  UrlDesc = "url__DESC",
  ValidCheckAsc = "valid_check__ASC",
  ValidCheckDesc = "valid_check__DESC"
}

export class Metadata_UrlUpdateInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  metadata?: InputMaybe<MetadataChildInput>;
  type_url?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class Metadata_UrlUpdateManyWithoutArtistInput {
  create?: InputMaybe<Array<Metadata_UrlCreateWithoutArtistInput>>;
  update?: InputMaybe<Array<Metadata_UrlUpdateWithWhereUniqueWithoutArtistInput>>;
}

export class Metadata_UrlUpdateWithWhereUniqueWithoutArtistInput {
  data: Metadata_UrlUpdateWithoutArtistDataInput;
  where: UuidWhereUniqueInput;
}

export class Metadata_UrlUpdateWithoutArtistDataInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  source?: InputMaybe<Scalars["String"]>;
  thumbnail?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  type_url?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class Metadata_UrlWhereInput {
  AND?: InputMaybe<Array<Metadata_UrlWhereInput>>;
  NOT?: InputMaybe<Array<Metadata_UrlWhereInput>>;
  OR?: InputMaybe<Array<Metadata_UrlWhereInput>>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  exposure_order__gt?: InputMaybe<Scalars["Int"]>;
  exposure_order__gte?: InputMaybe<Scalars["Int"]>;
  exposure_order__in?: InputMaybe<Array<Scalars["Int"]>>;
  exposure_order__lt?: InputMaybe<Scalars["Int"]>;
  exposure_order__lte?: InputMaybe<Scalars["Int"]>;
  exposure_order__not?: InputMaybe<Scalars["Int"]>;
  exposure_order__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__exist?: InputMaybe<Scalars["Boolean"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  metadata__lateral?: InputMaybe<Scalars["Boolean"]>;
  metadata__some?: InputMaybe<MetadataWhereInput>;
  metadata_id?: InputMaybe<Scalars["ID"]>;
  metadata_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  metadata_id__not_include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  source?: InputMaybe<Scalars["String"]>;
  source__contains?: InputMaybe<Scalars["String"]>;
  source__ends_with?: InputMaybe<Scalars["String"]>;
  source__in?: InputMaybe<Array<Scalars["String"]>>;
  source__not?: InputMaybe<Scalars["String"]>;
  source__not_contains?: InputMaybe<Scalars["String"]>;
  source__not_ends_with?: InputMaybe<Scalars["String"]>;
  source__not_in?: InputMaybe<Array<Scalars["String"]>>;
  source__not_starts_with?: InputMaybe<Scalars["String"]>;
  source__starts_with?: InputMaybe<Scalars["String"]>;
  thumbnail?: InputMaybe<Scalars["String"]>;
  thumbnail__contains?: InputMaybe<Scalars["String"]>;
  thumbnail__ends_with?: InputMaybe<Scalars["String"]>;
  thumbnail__in?: InputMaybe<Array<Scalars["String"]>>;
  thumbnail__not?: InputMaybe<Scalars["String"]>;
  thumbnail__not_contains?: InputMaybe<Scalars["String"]>;
  thumbnail__not_ends_with?: InputMaybe<Scalars["String"]>;
  thumbnail__not_in?: InputMaybe<Array<Scalars["String"]>>;
  thumbnail__not_starts_with?: InputMaybe<Scalars["String"]>;
  thumbnail__starts_with?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  title__contains?: InputMaybe<Scalars["String"]>;
  title__ends_with?: InputMaybe<Scalars["String"]>;
  title__in?: InputMaybe<Array<Scalars["String"]>>;
  title__not?: InputMaybe<Scalars["String"]>;
  title__not_contains?: InputMaybe<Scalars["String"]>;
  title__not_ends_with?: InputMaybe<Scalars["String"]>;
  title__not_in?: InputMaybe<Array<Scalars["String"]>>;
  title__not_starts_with?: InputMaybe<Scalars["String"]>;
  title__starts_with?: InputMaybe<Scalars["String"]>;
  type_url?: InputMaybe<Scalars["String"]>;
  type_url__contains?: InputMaybe<Scalars["String"]>;
  type_url__ends_with?: InputMaybe<Scalars["String"]>;
  type_url__in?: InputMaybe<Array<Scalars["String"]>>;
  type_url__not?: InputMaybe<Scalars["String"]>;
  type_url__not_contains?: InputMaybe<Scalars["String"]>;
  type_url__not_ends_with?: InputMaybe<Scalars["String"]>;
  type_url__not_in?: InputMaybe<Array<Scalars["String"]>>;
  type_url__not_starts_with?: InputMaybe<Scalars["String"]>;
  type_url__starts_with?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
  url__contains?: InputMaybe<Scalars["String"]>;
  url__ends_with?: InputMaybe<Scalars["String"]>;
  url__in?: InputMaybe<Array<Scalars["String"]>>;
  url__not?: InputMaybe<Scalars["String"]>;
  url__not_contains?: InputMaybe<Scalars["String"]>;
  url__not_ends_with?: InputMaybe<Scalars["String"]>;
  url__not_in?: InputMaybe<Array<Scalars["String"]>>;
  url__not_starts_with?: InputMaybe<Scalars["String"]>;
  url__starts_with?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
  valid_check__contains?: InputMaybe<ValidType>;
  valid_check__in?: InputMaybe<Array<ValidType>>;
  valid_check__not?: InputMaybe<ValidType>;
  valid_check__not_contains?: InputMaybe<ValidType>;
  valid_check__not_in?: InputMaybe<Array<ValidType>>;
}

export class Mutation {
  approve_price: Scalars["Boolean"];
  cancel: PayletterBill;
  concatTrack?: Maybe<Scalars["Boolean"]>;
  createAccess_record?: Maybe<Access_Record>;
  createArtist: Artist;
  createBroadcast_billing?: Maybe<Broadcast_Billing>;
  createChannel_donate_billing?: Maybe<Channel_Donate_Billing>;
  createComment: Comment;
  createCommodity: Commodity;
  createCompany: Company;
  createGenre: Genre;
  createGoods: Goods;
  createInvalidation?: Maybe<Scalars["Boolean"]>;
  createLanguage: Language;
  createMetadata: Metadata;
  createMetadata_donate_billing?: Maybe<Metadata_Donate_Billing>;
  createPlaylist: Playlist;
  createRole: Role;
  createStaff?: Maybe<User>;
  createTonality: Tonality;
  createTrack_play_count: Track_Play_Count;
  createUrl: Scalars["String"];
  createUser?: Maybe<Users>;
  createUsers_billing?: Maybe<Users_Billing>;
  create_curation: Playlist;
  create_file_rule?: Maybe<FileRule>;
  create_grade?: Maybe<Grade>;
  create_license: License;
  create_playlist: Playlist;
  create_product_price: ProductPrice;
  decideCommodity?: Maybe<DecideFromCommodityResponse>;
  deleteAccess_record?: Maybe<Scalars["Boolean"]>;
  deleteAccount?: Maybe<Scalars["Boolean"]>;
  deleteArtist?: Maybe<Array<Maybe<Artist>>>;
  deleteArtist_genre_relation?: Maybe<Array<Maybe<Artist_Genre_Relation>>>;
  deleteArtist_history?: Maybe<Array<Maybe<Artist_History>>>;
  deleteArtist_role_relation?: Maybe<Array<Maybe<Artist_Role_Relation>>>;
  deleteArtist_self_relation_element_artist?: Maybe<Array<Maybe<Artist_Self_Relation>>>;
  deleteArtist_self_relation_org_artist?: Maybe<Array<Maybe<Artist_Self_Relation>>>;
  deleteArtist_title?: Maybe<Array<Maybe<Artist_Title>>>;
  deleteArtist_url?: Maybe<Array<Maybe<Artist_Url>>>;
  deleteComment?: Maybe<Comment>;
  deleteCommodity?: Maybe<Array<Maybe<Commodity>>>;
  deleteCommodity_url?: Maybe<Array<Maybe<Commodity_Url>>>;
  deleteCompany?: Maybe<Array<Maybe<Company>>>;
  deleteCompany_title?: Maybe<Array<Maybe<Company_Title>>>;
  deleteCompany_url?: Maybe<Array<Maybe<Company_Url>>>;
  deleteCompany_users_relation?: Maybe<Array<Maybe<Company_Users_Relation>>>;
  deleteFile?: Maybe<Scalars["Boolean"]>;
  deleteGenre?: Maybe<Array<Maybe<Genre>>>;
  deleteGenre_self_relation_child?: Maybe<Array<Maybe<Genre_Self_Relation>>>;
  deleteGenre_self_relation_parent?: Maybe<Array<Maybe<Genre_Self_Relation>>>;
  deleteGenre_title?: Maybe<Array<Maybe<Genre_Title>>>;
  deleteGenre_url?: Maybe<Array<Maybe<Genre_Url>>>;
  deleteGoods?: Maybe<Array<Maybe<Goods>>>;
  deleteGoods_artist_relation?: Maybe<Array<Maybe<Goods_Artist_Relation>>>;
  deleteGoods_company_relation?: Maybe<Array<Maybe<Goods_Company_Relation>>>;
  deleteGoods_structure?: Maybe<Array<Maybe<Goods_Structure>>>;
  deleteGoods_subdata_unique?: Maybe<Array<Maybe<Goods_Subdata_Unique>>>;
  deleteGoods_title?: Maybe<Array<Maybe<Goods_Title>>>;
  deleteGoods_url?: Maybe<Array<Maybe<Goods_Url>>>;
  deleteLanguage?: Maybe<Array<Maybe<Language>>>;
  deleteLanguage_title?: Maybe<Array<Maybe<Language_Title>>>;
  deleteMetadata?: Maybe<Array<Maybe<Metadata>>>;
  deleteMetadata_artist_relation?: Maybe<Array<Maybe<Metadata_Artist_Relation>>>;
  deleteMetadata_company_relation?: Maybe<Array<Maybe<Metadata_Company_Relation>>>;
  deleteMetadata_genre_relation?: Maybe<Array<Maybe<Metadata_Genre_Relation>>>;
  deleteMetadata_goods_relation?: Maybe<Array<Maybe<Metadata_Goods_Relation>>>;
  deleteMetadata_language_relation?: Maybe<Array<Maybe<Metadata_Language_Relation>>>;
  deleteMetadata_organization_relation?: Maybe<Array<Maybe<Metadata_Organization_Relation>>>;
  deleteMetadata_playlist_relation?: Maybe<Array<Maybe<Metadata_Playlist_Relation>>>;
  deleteMetadata_self_relations_element_metadata?: Maybe<Array<Maybe<Metadata_Self_Relation>>>;
  deleteMetadata_self_relations_list_metadata?: Maybe<Array<Maybe<Metadata_Self_Relation>>>;
  deleteMetadata_structure?: Maybe<Array<Maybe<Metadata_Structure>>>;
  deleteMetadata_subdata_unique?: Maybe<Array<Maybe<Metadata_Subdata_Unique>>>;
  deleteMetadata_title?: Maybe<Array<Maybe<Metadata_Title>>>;
  deleteMetadata_url?: Maybe<Array<Maybe<Metadata_Url>>>;
  deletePlaylist?: Maybe<Array<Maybe<Playlist>>>;
  deletePlaylist_genre_relation?: Maybe<Array<Maybe<Playlist_Genre_Relation>>>;
  deletePlaylist_title?: Maybe<Array<Maybe<Playlist_Title>>>;
  deletePlaylist_url?: Maybe<Array<Maybe<Playlist_Url>>>;
  deletePlaylist_users_relation?: Maybe<Array<Maybe<Playlist_Users_Relation>>>;
  deleteRole?: Maybe<Array<Maybe<Role>>>;
  deleteRole_self_relations_element_role?: Maybe<Array<Maybe<Role_Self_Relation>>>;
  deleteRole_self_relations_list_role?: Maybe<Array<Maybe<Role_Self_Relation>>>;
  deleteRole_title?: Maybe<Array<Maybe<Role_Title>>>;
  deleteRole_url?: Maybe<Array<Maybe<Role_Url>>>;
  deleteTonality?: Maybe<Array<Maybe<Tonality>>>;
  deleteTrack_information?: Maybe<Array<Maybe<Track_Information>>>;
  deleteTrack_license?: Maybe<Array<Maybe<Track_License>>>;
  deleteTrack_license_extra?: Maybe<Array<Maybe<Track_License_Extra>>>;
  deposit_point_by_admin: Scalars["Boolean"];
  like_track: TrackActivity;
  modify_access?: Maybe<Scalars["Boolean"]>;
  pay: PaymentSuccessResponse;
  pay_global: PaymentGlobalSuccessResponse;
  purchase_all_cart_items: PurchaseHistory;
  push_cart_item: TrackActivity;
  push_cart_items: Array<Maybe<TrackActivity>>;
  push_playlist_item: PlaylistItem;
  refund_curation: Scalars["Boolean"];
  refund_tracks_by_history?: Maybe<Array<Maybe<Scalars["Int"]>>>;
  remove_cart_item?: Maybe<Scalars["Int"]>;
  remove_playlist: Scalars["Boolean"];
  remove_playlist_item?: Maybe<Scalars["Int"]>;
  replace_tracks_in_playlist: Array<Maybe<PlaylistItem>>;
  resetUser?: Maybe<Scalars["Boolean"]>;
  sendResetPasswordEmail?: Maybe<Scalars["Boolean"]>;
  send_curation: Scalars["Boolean"];
  set_access?: Maybe<Scalars["Boolean"]>;
  singUp?: Maybe<User>;
  singleUpload?: Maybe<Buckets>;
  unapproved_price: Scalars["Boolean"];
  unlike_track: RemoveResponse;
  updateAccess_record?: Maybe<Scalars["Boolean"]>;
  updateArtist?: Maybe<Artist>;
  updateArtist_genre_relation?: Maybe<Artist_Genre_Relation>;
  updateArtist_history?: Maybe<Artist_History>;
  updateArtist_role_relation?: Maybe<Artist_Role_Relation>;
  updateArtist_self_relation_element_artist?: Maybe<Artist_Self_Relation>;
  updateArtist_self_relation_org_artist?: Maybe<Artist_Self_Relation>;
  updateArtist_title?: Maybe<Artist_Title>;
  updateArtist_url?: Maybe<Artist_Url>;
  updateComment?: Maybe<Comment>;
  updateCommodity?: Maybe<Commodity>;
  updateCommodity_url?: Maybe<Commodity_Url>;
  updateCompany?: Maybe<Company>;
  updateCompany_title?: Maybe<Company_Title>;
  updateCompany_url?: Maybe<Company_Url>;
  updateCompany_users_relation?: Maybe<Company_Users_Relation>;
  updateEmail?: Maybe<User>;
  updateFile?: Maybe<Buckets>;
  updateGenre?: Maybe<Genre>;
  updateGenre_self_relation_child?: Maybe<Genre_Self_Relation>;
  updateGenre_self_relation_parent?: Maybe<Genre_Self_Relation>;
  updateGenre_title?: Maybe<Genre_Title>;
  updateGenre_url?: Maybe<Genre_Url>;
  updateGoods?: Maybe<Goods>;
  updateGoods_artist_relation?: Maybe<Goods_Artist_Relation>;
  updateGoods_company_relation?: Maybe<Goods_Company_Relation>;
  updateGoods_structure?: Maybe<Goods_Structure>;
  updateGoods_subdata_unique?: Maybe<Goods_Subdata_Unique>;
  updateGoods_title?: Maybe<Goods_Title>;
  updateGoods_url?: Maybe<Goods_Url>;
  updateLanguage?: Maybe<Language>;
  updateLanguage_title?: Maybe<Language_Title>;
  updateMetadata?: Maybe<Metadata>;
  updateMetadata_artist_relation?: Maybe<Metadata_Artist_Relation>;
  updateMetadata_artist_relations?: Maybe<Array<Maybe<Metadata_Artist_Relation>>>;
  updateMetadata_company_relation?: Maybe<Metadata_Company_Relation>;
  updateMetadata_genre_relation?: Maybe<Metadata_Genre_Relation>;
  updateMetadata_goods_relation?: Maybe<Metadata_Goods_Relation>;
  updateMetadata_language_relation?: Maybe<Metadata_Language_Relation>;
  updateMetadata_organization_relation?: Maybe<Metadata_Organization_Relation>;
  updateMetadata_playlist_relation?: Maybe<Metadata_Playlist_Relation>;
  updateMetadata_self_relations_element_metadata?: Maybe<Metadata_Self_Relation>;
  updateMetadata_self_relations_list_metadata?: Maybe<Metadata_Self_Relation>;
  updateMetadata_structure?: Maybe<Metadata_Structure>;
  updateMetadata_subdata_unique?: Maybe<Metadata_Subdata_Unique>;
  updateMetadata_title?: Maybe<Metadata_Title>;
  updateMetadata_url?: Maybe<Metadata_Url>;
  updatePassword?: Maybe<User>;
  updatePasswordByEmailAccess?: Maybe<User>;
  updatePlaylist?: Maybe<Playlist>;
  updatePlaylist_genre_relation?: Maybe<Playlist_Genre_Relation>;
  updatePlaylist_title?: Maybe<Playlist_Title>;
  updatePlaylist_url?: Maybe<Playlist_Url>;
  updatePlaylist_users_relation?: Maybe<Playlist_Users_Relation>;
  updateRole?: Maybe<Role>;
  updateRole_self_relations_element_role?: Maybe<Role_Self_Relation>;
  updateRole_self_relations_list_role?: Maybe<Role_Self_Relation>;
  updateRole_title?: Maybe<Role_Title>;
  updateRole_url?: Maybe<Role_Url>;
  updateTonality?: Maybe<Tonality>;
  updateTrack_information?: Maybe<Track_Information>;
  updateTrack_license?: Maybe<Track_License>;
  updateTrack_license_extra?: Maybe<Track_License_Extra>;
  updateUser?: Maybe<Scalars["Boolean"]>;
  update_grade_price?: Maybe<Grade>;
  update_playlist: Playlist;
  update_playlist_item: PlaylistItem;
  update_price: ProductPrice;
  update_track_duration_temporary?: Maybe<Scalars["Boolean"]>;
  update_user_info: User;
  uploadFile?: Maybe<Buckets>;
  verifyUser?: Maybe<Scalars["Boolean"]>;
  withdrawal_point_by_admin: Scalars["Boolean"];
  ytvDownload?: Maybe<Scalars["Boolean"]>;
}

export class MutationApprove_PriceArgs {
  product_price_id: Scalars["Int"];
}

export class MutationCancelArgs {
  bill_id: Scalars["Int"];
}

export class MutationConcatTrackArgs {
  where?: InputMaybe<MediaConvertWhereInput>;
}

export class MutationCreateAccess_RecordArgs {
  data: AccessRecordCreateInput;
}

export class MutationCreateArtistArgs {
  data: ArtistCreateInput;
}

export class MutationCreateBroadcast_BillingArgs {
  data: Broadcast_BillingCreateInput;
}

export class MutationCreateChannel_Donate_BillingArgs {
  data: Channel_Donate_BillingCreateInput;
}

export class MutationCreateCommentArgs {
  data: CommentCreateInput;
}

export class MutationCreateCommodityArgs {
  data: CommodityCreateInput;
}

export class MutationCreateCompanyArgs {
  data: CompanyCreateInput;
}

export class MutationCreateGenreArgs {
  data: GenreCreateInput;
}

export class MutationCreateGoodsArgs {
  data: GoodsCreateInput;
}

export class MutationCreateInvalidationArgs {
  where?: InputMaybe<CreateInvalidationWhereInput>;
}

export class MutationCreateLanguageArgs {
  data: LanguageCreateInput;
}

export class MutationCreateMetadataArgs {
  data: MetadataCreateInput;
}

export class MutationCreateMetadata_Donate_BillingArgs {
  data: Metadata_Donate_BillingCreateInput;
}

export class MutationCreatePlaylistArgs {
  data: PlaylistCreateInput;
}

export class MutationCreateRoleArgs {
  data: RoleCreateInput;
}

export class MutationCreateStaffArgs {
  payload: SignUpPayload;
}

export class MutationCreateTonalityArgs {
  data: TonalityCreateInput;
}

export class MutationCreateTrack_Play_CountArgs {
  data: Track_Play_CountCreate;
}

export class MutationCreateUrlArgs {
  data: CreateUrlInput;
}

export class MutationCreateUserArgs {
  data: UserCreateInput;
}

export class MutationCreateUsers_BillingArgs {
  data: Users_BillingCreateInput;
}

export class MutationCreate_CurationArgs {
  description?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
}

export class MutationCreate_File_RuleArgs {
  description: Scalars["String"];
  mp3_price_id: Scalars["Int"];
  target: TypeMetadataSubclass;
  wav_price_id?: InputMaybe<Scalars["Int"]>;
}

export class MutationCreate_GradeArgs {
  grade: TrackGrade;
  product_price_id: Scalars["Int"];
}

export class MutationCreate_LicenseArgs {
  input?: InputMaybe<CreateLicenseInput>;
}

export class MutationCreate_PlaylistArgs {
  description?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
}

export class MutationCreate_Product_PriceArgs {
  description?: InputMaybe<Scalars["String"]>;
  price: Scalars["Int"];
  target: TypeMetadataSubclass;
  title: Scalars["String"];
  type: ProductPriceType;
}

export class MutationDecideCommodityArgs {
  data: DecideFromCommodityUpdateInput;
  where: CommodityUpsertWhereUniqueInput;
}

export class MutationDeleteAccess_RecordArgs {
  where: AccessRecordWhereUniqueInput;
}

export class MutationDeleteAccountArgs {
  password: Scalars["String"];
}

export class MutationDeleteArtistArgs {
  where: ArtistWhereUniqueInput;
}

export class MutationDeleteArtist_Genre_RelationArgs {
  where: UuidWhereDeleteInput;
}

export class MutationDeleteArtist_HistoryArgs {
  where: UuidWhereDeleteInput;
}

export class MutationDeleteArtist_Role_RelationArgs {
  where: UuidWhereDeleteInput;
}

export class MutationDeleteArtist_Self_Relation_Element_ArtistArgs {
  where: UuidWhereDeleteInput;
}

export class MutationDeleteArtist_Self_Relation_Org_ArtistArgs {
  where: UuidWhereDeleteInput;
}

export class MutationDeleteArtist_TitleArgs {
  where: UuidWhereDeleteInput;
}

export class MutationDeleteArtist_UrlArgs {
  where: UuidWhereDeleteInput;
}

export class MutationDeleteCommentArgs {
  where: CommentUniqueWhereInput;
}

export class MutationDeleteCommodityArgs {
  where: CommodityWhereUniqueInput;
}

export class MutationDeleteCommodity_UrlArgs {
  where: UuidWhereDeleteInput;
}

export class MutationDeleteCompanyArgs {
  where: CompanyWhereUniqueInput;
}

export class MutationDeleteCompany_TitleArgs {
  where: UuidWhereUniqueInput;
}

export class MutationDeleteCompany_UrlArgs {
  where: UuidWhereDeleteInput;
}

export class MutationDeleteCompany_Users_RelationArgs {
  where: UuidWhereUniqueInput;
}

export class MutationDeleteFileArgs {
  where?: InputMaybe<DeleteInput>;
}

export class MutationDeleteGenreArgs {
  where: GenreWhereUniqueInput;
}

export class MutationDeleteGenre_Self_Relation_ChildArgs {
  where: UuidWhereDeleteInput;
}

export class MutationDeleteGenre_Self_Relation_ParentArgs {
  where: UuidWhereDeleteInput;
}

export class MutationDeleteGenre_TitleArgs {
  where: UuidWhereDeleteInput;
}

export class MutationDeleteGenre_UrlArgs {
  where: UuidWhereDeleteInput;
}

export class MutationDeleteGoodsArgs {
  where: GoodsWhereUniqueInput;
}

export class MutationDeleteGoods_Artist_RelationArgs {
  where: UuidWhereDeleteInput;
}

export class MutationDeleteGoods_Company_RelationArgs {
  where: UuidWhereDeleteInput;
}

export class MutationDeleteGoods_StructureArgs {
  where: Goods_StructureWhereUniqueInput;
}

export class MutationDeleteGoods_Subdata_UniqueArgs {
  where: UuidWhereDeleteInput;
}

export class MutationDeleteGoods_TitleArgs {
  where: UuidWhereDeleteInput;
}

export class MutationDeleteGoods_UrlArgs {
  where: UuidWhereDeleteInput;
}

export class MutationDeleteLanguageArgs {
  where: LanguageWhereUniqueInput;
}

export class MutationDeleteLanguage_TitleArgs {
  where: UuidWhereDeleteInput;
}

export class MutationDeleteMetadataArgs {
  where: MetadataWhereUniqueInput;
}

export class MutationDeleteMetadata_Artist_RelationArgs {
  where: UuidWhereDeleteInput;
}

export class MutationDeleteMetadata_Company_RelationArgs {
  where: UuidWhereDeleteInput;
}

export class MutationDeleteMetadata_Genre_RelationArgs {
  where: UuidWhereDeleteInput;
}

export class MutationDeleteMetadata_Goods_RelationArgs {
  where: UuidWhereDeleteInput;
}

export class MutationDeleteMetadata_Language_RelationArgs {
  where: UuidWhereDeleteInput;
}

export class MutationDeleteMetadata_Organization_RelationArgs {
  where: UuidWhereDeleteInput;
}

export class MutationDeleteMetadata_Playlist_RelationArgs {
  where: UuidWhereDeleteInput;
}

export class MutationDeleteMetadata_Self_Relations_Element_MetadataArgs {
  where: UuidWhereUniqueInput;
}

export class MutationDeleteMetadata_Self_Relations_List_MetadataArgs {
  where: UuidWhereDeleteInput;
}

export class MutationDeleteMetadata_StructureArgs {
  where: Metadata_StructureWhereUniqueInput;
}

export class MutationDeleteMetadata_Subdata_UniqueArgs {
  where: UuidWhereDeleteInput;
}

export class MutationDeleteMetadata_TitleArgs {
  where: UuidWhereDeleteInput;
}

export class MutationDeleteMetadata_UrlArgs {
  where: UuidWhereDeleteInput;
}

export class MutationDeletePlaylistArgs {
  where: PlaylistWhereUniqueInput;
}

export class MutationDeletePlaylist_Genre_RelationArgs {
  where: UuidWhereDeleteInput;
}

export class MutationDeletePlaylist_TitleArgs {
  where: UuidWhereDeleteInput;
}

export class MutationDeletePlaylist_UrlArgs {
  where: UuidWhereDeleteInput;
}

export class MutationDeletePlaylist_Users_RelationArgs {
  where: UuidWhereDeleteInput;
}

export class MutationDeleteRoleArgs {
  where: RoleWhereUniqueInput;
}

export class MutationDeleteRole_Self_Relations_Element_RoleArgs {
  where: UuidWhereUniqueInput;
}

export class MutationDeleteRole_Self_Relations_List_RoleArgs {
  where: UuidWhereDeleteInput;
}

export class MutationDeleteRole_TitleArgs {
  where: UuidWhereDeleteInput;
}

export class MutationDeleteRole_UrlArgs {
  where: UuidWhereDeleteInput;
}

export class MutationDeleteTonalityArgs {
  where: TonalityWhereUniqueInput;
}

export class MutationDeleteTrack_InformationArgs {
  where: UuidWhereDeleteInput;
}

export class MutationDeleteTrack_LicenseArgs {
  where: UuidWhereDeleteInput;
}

export class MutationDeleteTrack_License_ExtraArgs {
  where: UuidWhereDeleteInput;
}

export class MutationDeposit_Point_By_AdminArgs {
  amount: Scalars["Int"];
  target_user_id: Scalars["Int"];
}

export class MutationLike_TrackArgs {
  track_id: Scalars["Int"];
}

export class MutationModify_AccessArgs {
  where: SetAccessWhereInput;
}

export class MutationPayArgs {
  amount: Scalars["Int"];
  pgcode: Scalars["String"];
}

export class MutationPay_GlobalArgs {
  amount: Scalars["Int"];
  pgcode: Scalars["String"];
}

export class MutationPush_Cart_ItemArgs {
  track_id: Scalars["Int"];
}

export class MutationPush_Cart_ItemsArgs {
  track_id: Array<Scalars["Int"]>;
}

export class MutationPush_Playlist_ItemArgs {
  playlist_id: Scalars["Int"];
  track_id: Scalars["Int"];
}

export class MutationRefund_CurationArgs {
  playlist_id: Scalars["Int"];
}

export class MutationRefund_Tracks_By_HistoryArgs {
  purchase_history_id: Scalars["Int"];
}

export class MutationRemove_Cart_ItemArgs {
  track_id: Scalars["Int"];
}

export class MutationRemove_PlaylistArgs {
  playlist_id: Scalars["Int"];
}

export class MutationRemove_Playlist_ItemArgs {
  playlist_id: Scalars["Int"];
  track_id: Scalars["Int"];
}

export class MutationReplace_Tracks_In_PlaylistArgs {
  playlist_id: Scalars["Int"];
  track_id: Array<InputMaybe<Scalars["Int"]>>;
}

export class MutationResetUserArgs {
  data: UserResetInput;
  where: UsersWhereUniqueInput;
}

export class MutationSendResetPasswordEmailArgs {
  email: Scalars["String"];
}

export class MutationSend_CurationArgs {
  playlist_id: Scalars["Int"];
  price_id: Scalars["Int"];
  to_user_id: Scalars["Int"];
}

export class MutationSet_AccessArgs {
  where: SetAccessWhereInput;
}

export class MutationSingUpArgs {
  payload: SignUpPayload;
}

export class MutationSingleUploadArgs {
  where?: InputMaybe<FileWhereInput>;
}

export class MutationUnapproved_PriceArgs {
  product_price_id: Scalars["Int"];
}

export class MutationUnlike_TrackArgs {
  track_id: Scalars["Int"];
}

export class MutationUpdateAccess_RecordArgs {
  where: AccessRecordUpdateInput;
}

export class MutationUpdateArtistArgs {
  data: ArtistUpdateInput;
  where: ArtistWhereUniqueInput;
}

export class MutationUpdateArtist_Genre_RelationArgs {
  data: Artist_Genre_RelationUpdateInput;
  where: Artist_Genre_RelationWhereInput;
}

export class MutationUpdateArtist_HistoryArgs {
  data: Artist_HistoryUpdateInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdateArtist_Role_RelationArgs {
  data: Artist_Role_RelationUpdateInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdateArtist_Self_Relation_Element_ArtistArgs {
  data: Artist_Self_RelationUpdateInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdateArtist_Self_Relation_Org_ArtistArgs {
  data: Artist_Self_RelationUpdateInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdateArtist_TitleArgs {
  data: Artist_TitleUpdateInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdateArtist_UrlArgs {
  data: Artist_UrlUpdateInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdateCommentArgs {
  data: CommentUpdateInput;
  where: CommentUniqueWhereInput;
}

export class MutationUpdateCommodityArgs {
  data: CommodityUpdateInput;
  where: CommodityWhereUniqueInput;
}

export class MutationUpdateCommodity_UrlArgs {
  data: Commodity_UrlUpdateInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdateCompanyArgs {
  data: CompanyUpdateInput;
  where: CompanyWhereUniqueInput;
}

export class MutationUpdateCompany_TitleArgs {
  data: Company_TitleUpdateInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdateCompany_UrlArgs {
  data: Company_UrlUpdateInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdateCompany_Users_RelationArgs {
  data: Company_Users_RelationUpdateManyInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdateEmailArgs {
  email: Scalars["String"];
}

export class MutationUpdateFileArgs {
  where?: InputMaybe<UpdateInput>;
}

export class MutationUpdateGenreArgs {
  data: GenreUpdateInput;
  where: GenreWhereUniqueInput;
}

export class MutationUpdateGenre_Self_Relation_ChildArgs {
  data: Genre_Self_RelationUpdateInput;
  where?: InputMaybe<UuidWhereUniqueInput>;
}

export class MutationUpdateGenre_Self_Relation_ParentArgs {
  data: Genre_Self_RelationUpdateInput;
  where?: InputMaybe<UuidWhereUniqueInput>;
}

export class MutationUpdateGenre_TitleArgs {
  data: Genre_TitleUpdateInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdateGenre_UrlArgs {
  data: Genre_UrlUpdateInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdateGoodsArgs {
  data: GoodsUpdateInput;
  where: GoodsWhereUniqueInput;
}

export class MutationUpdateGoods_Artist_RelationArgs {
  data: Goods_Artist_RelationUpdateInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdateGoods_Company_RelationArgs {
  data: Goods_Company_RelationUpdateInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdateGoods_StructureArgs {
  data: Goods_StructureUpdateInput;
  where: Goods_StructureWhereUniqueInput;
}

export class MutationUpdateGoods_Subdata_UniqueArgs {
  data: Goods_Subdata_UniqueUpdateInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdateGoods_TitleArgs {
  data: Goods_TitleUpdateInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdateGoods_UrlArgs {
  data: Goods_UrlUpdateInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdateLanguageArgs {
  data: LanguageUpdateInput;
  where: LanguageWhereUniqueInput;
}

export class MutationUpdateLanguage_TitleArgs {
  data: Language_TitleUpdateInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdateMetadataArgs {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
}

export class MutationUpdateMetadata_Artist_RelationArgs {
  data: Metadata_Artist_RelationUpdateInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdateMetadata_Artist_RelationsArgs {
  data: Metadata_Artist_RelationUpdateInput;
  where: Metadata_Artist_RelationUpdateWhereInput;
}

export class MutationUpdateMetadata_Company_RelationArgs {
  data: Metadata_Company_RelationUpdateInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdateMetadata_Genre_RelationArgs {
  data: Metadata_Genre_RelationUpdateInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdateMetadata_Goods_RelationArgs {
  data: Metadata_Goods_RelationUpdateInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdateMetadata_Language_RelationArgs {
  data: Metadata_Language_RelationUpdateInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdateMetadata_Organization_RelationArgs {
  data: Metadata_Organization_RelationUpdateInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdateMetadata_Playlist_RelationArgs {
  data: Metadata_Playlist_RelationUpdateInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdateMetadata_Self_Relations_Element_MetadataArgs {
  data: Metadata_Self_RelationUpdateInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdateMetadata_Self_Relations_List_MetadataArgs {
  data: Metadata_Self_RelationUpdateInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdateMetadata_StructureArgs {
  data: Metadata_StructureUpdateInput;
  where: Metadata_StructureWhereUniqueInput;
}

export class MutationUpdateMetadata_Subdata_UniqueArgs {
  data: Metadata_Subdata_UniqueUpdateInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdateMetadata_TitleArgs {
  data: Metadata_TitleUpdateInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdateMetadata_UrlArgs {
  data: Metadata_UrlUpdateInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdatePasswordArgs {
  password: Scalars["String"];
}

export class MutationUpdatePasswordByEmailAccessArgs {
  password: Scalars["String"];
}

export class MutationUpdatePlaylistArgs {
  data: PlaylistUpdateInput;
  where: PlaylistWhereUniqueInput;
}

export class MutationUpdatePlaylist_Genre_RelationArgs {
  data: Playlist_Genre_RelationUpdateInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdatePlaylist_TitleArgs {
  data: Playlist_TitleUpdateInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdatePlaylist_UrlArgs {
  data: Playlist_UrlUpdateInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdatePlaylist_Users_RelationArgs {
  data: Playlist_Users_RelationUpdateInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdateRoleArgs {
  data: RoleUpdateInput;
  where: RoleWhereUniqueInput;
}

export class MutationUpdateRole_Self_Relations_Element_RoleArgs {
  data: Role_Self_RelationUpdateInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdateRole_Self_Relations_List_RoleArgs {
  data: Role_Self_RelationUpdateInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdateRole_TitleArgs {
  data: Role_TitleUpdateInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdateRole_UrlArgs {
  data: Role_UrlUpdateInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdateTonalityArgs {
  data: TonalityUpdateInput;
  where: TonalityWhereUniqueInput;
}

export class MutationUpdateTrack_InformationArgs {
  data: Track_InformationUpdatelistDataInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdateTrack_LicenseArgs {
  data: Track_LicenseUpdateInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdateTrack_License_ExtraArgs {
  data: Track_License_ExtraUpdateInput;
  where: UuidWhereUniqueInput;
}

export class MutationUpdateUserArgs {
  data: UserUpdateInput;
  where: UserWhereInput;
}

export class MutationUpdate_Grade_PriceArgs {
  grade_id: Scalars["Int"];
  product_price_id: Scalars["Int"];
}

export class MutationUpdate_PlaylistArgs {
  input: UpdatePlaylistInput;
}

export class MutationUpdate_Playlist_ItemArgs {
  input: UpdatePlaylistItemInput;
}

export class MutationUpdate_PriceArgs {
  description: Scalars["String"];
  where: UpdatePriceWhere;
}

export class MutationUpdate_Track_Duration_TemporaryArgs {
  duration_temporary?: InputMaybe<Scalars["Int"]>;
  target?: InputMaybe<Scalars["Int"]>;
}

export class MutationUpdate_User_InfoArgs {
  company?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
}

export class MutationUploadFileArgs {
  where?: InputMaybe<InsertInput>;
}

export class MutationWithdrawal_Point_By_AdminArgs {
  amount: Scalars["Int"];
  target_user_id: Scalars["Int"];
}

export class MutationYtvDownloadArgs {
  where?: InputMaybe<YtvWhereInput>;
}

export enum MutationType {
  Created = "CREATED",
  Deleted = "DELETED",
  Updated = "UPDATED"
}

export enum NodeType {
  CartItem = "CartItem",
  CurationManagement = "CurationManagement",
  Genre = "Genre",
  GenreTitle = "Genre_title",
  License = "License",
  Metadata = "Metadata",
  MetadataArtistRelation = "Metadata_Artist_Relation",
  MetadataGenreRelation = "Metadata_Genre_Relation",
  MetadataTitle = "Metadata_title",
  PayoneqInformation = "PayoneqInformation",
  Playlist = "Playlist",
  PointTransaction = "PointTransaction",
  ProductPrice = "ProductPrice",
  PurchaseHistory = "PurchaseHistory",
  PurchaseItem = "PurchaseItem",
  Role = "Role",
  RoleTitle = "Role_title",
  Track = "Track"
}

/**
 * {
 *   "name":"PageInfo"
 *   "id": ""
 * }
 */
export class PageInfo {
  currentPage?: Maybe<Scalars["Int"]>;
  endCursor?: Maybe<Scalars["String"]>;
  hasNextPage: Scalars["Boolean"];
  hasPreviousPage: Scalars["Boolean"];
  pageCount?: Maybe<Scalars["Int"]>;
  startCursor?: Maybe<Scalars["String"]>;
}

export class PageInput {
  artist?: InputMaybe<ArtistWhereInput>;
  artist_history?: InputMaybe<Artist_HistoryWhereInput>;
  artist_role_relation?: InputMaybe<Artist_Role_RelationWhereInput>;
  artist_self_relation?: InputMaybe<Artist_Self_RelationWhereInput>;
  artist_self_relations_element_artist?: InputMaybe<Artist_Self_RelationWhereInput>;
  artist_self_relations_org_artist?: InputMaybe<Artist_Self_RelationWhereInput>;
  artist_title?: InputMaybe<Artist_TitleWhereInput>;
  artist_url?: InputMaybe<Artist_UrlWhereInput>;
  commodity?: InputMaybe<CommodityWhereInput>;
  commodity_url?: InputMaybe<Commodity_UrlWhereInput>;
  company?: InputMaybe<CompanyWhereInput>;
  company_title?: InputMaybe<Company_TitleWhereInput>;
  genre?: InputMaybe<GenreWhereInput>;
  genre_self_relation?: InputMaybe<Genre_Self_RelationWhereInput>;
  genre_title?: InputMaybe<Genre_TitleWhereInput>;
  goods?: InputMaybe<GoodsWhereInput>;
  goods_structure?: InputMaybe<Goods_StructureWhereInput>;
  goods_title?: InputMaybe<Goods_TitleWhereInput>;
  goods_url?: InputMaybe<Goods_UrlWhereInput>;
  language?: InputMaybe<LanguageWhereInput>;
  language_title?: InputMaybe<Language_TitleWhereInput>;
  metadata?: InputMaybe<MetadataWhereInput>;
  metadata_artist_relation?: InputMaybe<Metadata_Artist_RelationWhereInput>;
  metadata_company_relation?: InputMaybe<Metadata_Company_RelationWhereInput>;
  metadata_genre_relation?: InputMaybe<Metadata_Genre_RelationWhereInput>;
  metadata_language_relation?: InputMaybe<Metadata_Language_RelationWhereInput>;
  metadata_playlist_relation?: InputMaybe<Metadata_Playlist_RelationWhereInput>;
  metadata_self_relation?: InputMaybe<Metadata_Self_RelationWhereInput>;
  metadata_structure?: InputMaybe<Metadata_StructureWhereInput>;
  metadata_subdata_unique?: InputMaybe<Metadata_Subdata_UniqueWhereInput>;
  metadata_title?: InputMaybe<Metadata_TitleWhereInput>;
  metadata_url?: InputMaybe<Metadata_UrlWhereInput>;
  playlist?: InputMaybe<PlaylistWhereInput>;
  playlist_title?: InputMaybe<Playlist_TitleWhereInput>;
  playlist_users_relation?: InputMaybe<Playlist_Users_RelationWhereInput>;
  role?: InputMaybe<RoleWhereInput>;
  role_self_relation?: InputMaybe<Role_Self_RelationWhereInput>;
  role_title?: InputMaybe<Role_TitleWhereInput>;
  tonality?: InputMaybe<TonalityWhereInput>;
  track_license?: InputMaybe<Track_LicenseWhereUniqueInput>;
  track_license_extra?: InputMaybe<Track_License_ExtraWhereUniqueInput>;
  track_play_count?: InputMaybe<Play_CountWhereUniqueInput>;
  users?: InputMaybe<UsersWhereInput>;
}

export class PageOrder {
  artist?: InputMaybe<Array<ArtistOrderByInput>>;
  artist_history?: InputMaybe<Array<Artist_HistoryOrderByInput>>;
  artist_role_relation?: InputMaybe<Array<Artist_Role_RelationOrderByInput>>;
  artist_self_relation?: InputMaybe<Array<Artist_Self_RelationOrderByInput>>;
  artist_self_relations_element_artist?: InputMaybe<Array<Artist_Self_RelationOrderByInput>>;
  artist_self_relations_org_artist?: InputMaybe<Array<Artist_Self_RelationOrderByInput>>;
  artist_title?: InputMaybe<Array<Artist_TitleOrderByInput>>;
  artist_url?: InputMaybe<Array<Artist_UrlOrderByInput>>;
  cart_item?: InputMaybe<Array<CartItemOrderByInput>>;
  commodity?: InputMaybe<Array<CommodityOrderByInput>>;
  commodity_url?: InputMaybe<Array<Commodity_UrlOrderByInput>>;
  company?: InputMaybe<Array<CompanyOrderByInput>>;
  company_title?: InputMaybe<Array<Company_TitleOrderByInput>>;
  curation_management?: InputMaybe<Array<CurationManagementOrderByInput>>;
  genre?: InputMaybe<Array<GenreOrderByInput>>;
  genre_self_relation?: InputMaybe<Array<Genre_Self_RelationOrderByInput>>;
  genre_title?: InputMaybe<Array<Genre_TitleOrderByInput>>;
  goods?: InputMaybe<Array<GoodsOrderByInput>>;
  goods_structure?: InputMaybe<Array<Goods_StructureOrderByInput>>;
  goods_title?: InputMaybe<Array<Goods_TitleOrderByInput>>;
  goods_url?: InputMaybe<Array<Goods_UrlOrderByInput>>;
  language?: InputMaybe<Array<LanguageOrderByInput>>;
  language_title?: InputMaybe<Array<Language_TitleOrderByInput>>;
  license?: InputMaybe<Array<LicenseOrderByInput>>;
  metadata?: InputMaybe<Array<MetadataOrderByInput>>;
  metadata_artist_relation?: InputMaybe<Array<Metadata_Artist_RelationOrderByInput>>;
  metadata_company_relation?: InputMaybe<Array<Metadata_Company_RelationOrderByInput>>;
  metadata_genre_relation?: InputMaybe<Array<Metadata_Genre_RelationOrderByInput>>;
  metadata_language_relation?: InputMaybe<Array<Metadata_Language_RelationOrderByInput>>;
  metadata_playlist_relation?: InputMaybe<Array<Metadata_Playlist_RelationOrderByInput>>;
  metadata_self_relation?: InputMaybe<Array<Metadata_Self_RelationOrderByInput>>;
  metadata_structure?: InputMaybe<Array<Metadata_StructureOrderByInput>>;
  metadata_subdata_unique?: InputMaybe<Array<Metadata_Subdata_UniqueOrderByInput>>;
  metadata_title?: InputMaybe<Array<Metadata_TitleOrderByInput>>;
  metadata_url?: InputMaybe<Array<Metadata_UrlOrderByInput>>;
  payoneq_information?: InputMaybe<Array<InputMaybe<PayoneqInformationOrderByInput>>>;
  playlist?: InputMaybe<Array<PlaylistOrderByInput>>;
  playlist_title?: InputMaybe<Array<Playlist_TitleOrderByInput>>;
  playlist_users_relation?: InputMaybe<Array<Playlist_Users_RelationOrderByInput>>;
  point_transaction?: InputMaybe<Array<PointTransactionOrderByInput>>;
  product_price?: InputMaybe<Array<ProductPriceOrderByInput>>;
  purchase_history?: InputMaybe<Array<PurchaseHistoryOrderByInput>>;
  purchase_item?: InputMaybe<Array<PurchaseItemOrderByInput>>;
  role?: InputMaybe<Array<RoleOrderByInput>>;
  role_self_relation?: InputMaybe<Array<Role_Self_RelationOrderByInput>>;
  role_title?: InputMaybe<Array<Role_TitleOrderByInput>>;
  tonality?: InputMaybe<Array<TonalityOrderByInput>>;
  track?: InputMaybe<Array<TrackOrderByInput>>;
  track_license?: InputMaybe<Array<Track_LicenseWhereUniqueInput>>;
  track_license_extra?: InputMaybe<Array<Track_License_ExtraWhereUniqueInput>>;
  track_play_count?: InputMaybe<Array<Play_CountWhereUniqueInput>>;
  users?: InputMaybe<Array<UsersOrderByInput>>;
}

export class PageWhere {
  cart_item?: InputMaybe<CartItemWhereInput>;
  curation_management?: InputMaybe<CurationManagementWhereInput>;
  genre?: InputMaybe<GenreWhereInput>;
  genre_title?: InputMaybe<Genre_TitleWhereInput>;
  license?: InputMaybe<LicenseWhereInput>;
  metadata?: InputMaybe<MetadataWhereInput>;
  metadata_artist_relation?: InputMaybe<Metadata_Artist_RelationWhereInput>;
  metadata_genre_relation?: InputMaybe<Metadata_Genre_RelationWhereInput>;
  metadata_title?: InputMaybe<Metadata_TitleWhereInput>;
  payoneq_information?: InputMaybe<PayoneqInformationkWhereInput>;
  playlist?: InputMaybe<BgmPlaylistWhereInput>;
  point_transaction?: InputMaybe<PointTransactionWhereInput>;
  product_price?: InputMaybe<ProductPriceWhereInput>;
  purchase_history?: InputMaybe<PurchaseHistoryWhereInput>;
  purchase_item?: InputMaybe<PurchaseItemWhereInput>;
  role?: InputMaybe<RoleWhereInput>;
  role_title?: InputMaybe<Role_TitleWhereInput>;
  track?: InputMaybe<TrackWhereInput>;
}

export class PayletterBill {
  amount: Scalars["Int"];
  cid: Scalars["String"];
  currency: CurrencyCode;
  id: Scalars["Int"];
  is_cancel: Scalars["Boolean"];
  pgcode: Scalars["String"];
  point_transaction?: Maybe<Array<Maybe<PointTransaction>>>;
  product_name: Scalars["String"];
  service_type: PayletterServiceType;
  tid: Scalars["String"];
  transaction_date: Scalars["String"];
  user_id: Scalars["Int"];
}

export enum PayletterBillOrderByInput {
  CountAsc = "count__ASC",
  CountDesc = "count__DESC",
  CreatedAtAsc = "created_at__ASC",
  CreatedAtDesc = "created_at__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  TypeAsc = "type__ASC",
  TypeDesc = "type__DESC",
  UserIdAsc = "user_id__ASC",
  UserIdDesc = "user_id__DESC"
}

export class PayletterBillWhereInput {
  currency?: InputMaybe<CurrencyCode>;
  id?: InputMaybe<Scalars["Int"]>;
  id__in?: InputMaybe<Array<Scalars["Int"]>>;
  tid?: InputMaybe<Scalars["Int"]>;
  tid__in?: InputMaybe<Array<Scalars["Int"]>>;
  user_id?: InputMaybe<Scalars["Int"]>;
  user_id__in?: InputMaybe<Array<Scalars["Int"]>>;
}

export enum PayletterServiceType {
  Global = "GLOBAL",
  Local = "LOCAL"
}

export class PaymentGlobalSuccessResponse {
  mobile_url: Scalars["String"];
  online_url: Scalars["String"];
  token: Scalars["Float"];
}

export class PaymentSuccessResponse {
  mobile_url: Scalars["String"];
  online_url: Scalars["String"];
  token: Scalars["Float"];
}

export class PayoneqInformation {
  icon?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  is_enable: Scalars["Boolean"];
  name: Scalars["String"];
  name_en: Scalars["String"];
  pgcode: Scalars["String"];
  service_type: PayoneqServiceType;
}

export enum PayoneqInformationOrderByInput {
  CreatedAtAsc = "created_at__ASC",
  CreatedAtDesc = "created_at__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC"
}

export class PayoneqInformationkWhereInput {
  id?: InputMaybe<Scalars["Int"]>;
  id__in?: InputMaybe<Array<Scalars["Int"]>>;
  is_enable?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  name__in?: InputMaybe<Array<Scalars["String"]>>;
  name_en?: InputMaybe<Scalars["String"]>;
  name_en__in?: InputMaybe<Array<Scalars["String"]>>;
  pgcode?: InputMaybe<Scalars["String"]>;
  pgcode__in?: InputMaybe<Array<Scalars["String"]>>;
  service_type?: InputMaybe<PayoneqServiceType>;
}

export enum PayoneqServiceType {
  Global = "GLOBAL",
  Local = "LOCAL"
}

export enum PlayType {
  Down = "DOWN",
  Streaming = "STREAMING"
}

/**
 * {
 *   "name":"get_play_count",
 *   "function":"get_play_count",
 *   "id": "id"
 * }
 */
export class Play_Count {
  play_count: Scalars["Int"];
  product: Scalars["String"];
  track: Scalars["String"];
}

export class Play_CountWhereUniqueInput {
  company_id?: InputMaybe<Scalars["ID"]>;
  end_date: Scalars["DateTime"];
  price_ids: Scalars["ID"];
  product_ids: Scalars["ID"];
  service: ServiceType;
  start_date: Scalars["DateTime"];
}

/**
 * {
 *   "name":"contract_bill",
 *   "function":"get_play_total_count",
 *   "id": "id"
 * }
 */
export class Play_Total_Count {
  bill?: Maybe<Bill>;
  created_at?: Maybe<Scalars["DateTime"]>;
}

/**
 * {
 *   "name":"playlist",
 *   "id": "playlist_id" ,
 *   "type" : "Int"
 * }
 */
export class Playlist {
  created_at: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  kind?: Maybe<Scalars["String"]>;
  metadata_playlist_relation?: Maybe<Array<Metadata_Playlist_Relation>>;
  name?: Maybe<Scalars["String"]>;
  playlist_genre_relation?: Maybe<Array<Playlist_Genre_Relation>>;
  /** 데이터의 고유키 */
  playlist_id: Scalars["ID"];
  playlist_item?: Maybe<Array<Maybe<PlaylistItem>>>;
  playlist_title?: Maybe<Array<Playlist_Title>>;
  playlist_url?: Maybe<Array<Playlist_Url>>;
  playlist_users_relation?: Maybe<Array<Playlist_Users_Relation>>;
  price?: Maybe<Scalars["Int"]>;
  remark?: Maybe<Scalars["String"]>;
  service?: Maybe<ServiceType>;
  title: Scalars["String"];
  type: PlaylistType;
  updated_at: Scalars["DateTime"];
  user: User;
  user_id: Scalars["Int"];
  /** 데이터 상태값 : DONE 값은 검증된 데이터 */
  valid_check?: Maybe<ValidType>;
}

/**
 * {
 *   "name":"playlist",
 *   "id": "playlist_id" ,
 *   "type" : "Int"
 * }
 */
export class PlaylistMetadata_Playlist_RelationArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Metadata_Playlist_RelationOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Metadata_Playlist_RelationWhereInput>;
}

/**
 * {
 *   "name":"playlist",
 *   "id": "playlist_id" ,
 *   "type" : "Int"
 * }
 */
export class PlaylistPlaylist_Genre_RelationArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Playlist_Genre_RelationOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Playlist_Genre_RelationWhereInput>;
}

/**
 * {
 *   "name":"playlist",
 *   "id": "playlist_id" ,
 *   "type" : "Int"
 * }
 */
export class PlaylistPlaylist_ItemArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<PlaylistItemOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<PlaylistItemWhereInput>;
}

/**
 * {
 *   "name":"playlist",
 *   "id": "playlist_id" ,
 *   "type" : "Int"
 * }
 */
export class PlaylistPlaylist_TitleArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Playlist_TitleOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Playlist_TitleWhereInput>;
}

/**
 * {
 *   "name":"playlist",
 *   "id": "playlist_id" ,
 *   "type" : "Int"
 * }
 */
export class PlaylistPlaylist_UrlArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Playlist_UrlOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Playlist_UrlWhereInput>;
}

/**
 * {
 *   "name":"playlist",
 *   "id": "playlist_id" ,
 *   "type" : "Int"
 * }
 */
export class PlaylistPlaylist_Users_RelationArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Playlist_Users_RelationOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Playlist_Users_RelationWhereInput>;
}

export class PlaylistChildInput {
  connect?: InputMaybe<PlaylistWhereUniqueInput>;
}

export class PlaylistCreateInput {
  kind?: InputMaybe<Scalars["String"]>;
  metadata_playlist_relation?: InputMaybe<Metadata_Playlist_RelationCreateManyWithoutPlaylistInput>;
  name?: InputMaybe<Scalars["String"]>;
  playlist_genre_relation?: InputMaybe<Playlist_Genre_RelationCreateManyWithoutPlaylistInput>;
  playlist_title?: InputMaybe<Playlist_TitleCreateManyWithoutPlaylistInput>;
  playlist_url?: InputMaybe<Playlist_UrlCreateManyWithoutPlaylistInput>;
  playlist_users_relation?: InputMaybe<Playlist_Users_RelationCreateManyWithoutPlaylistInput>;
  price?: InputMaybe<Scalars["Int"]>;
  remark?: InputMaybe<Scalars["String"]>;
  service?: InputMaybe<ServiceType>;
}

export class PlaylistItem {
  created_at: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  playlist?: Maybe<Array<Playlist>>;
  playlist_id: Scalars["Int"];
  track: Track;
  track_id: Scalars["Int"];
}

export class PlaylistItemPlaylistArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<PlaylistOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<BgmPlaylistWhereInput>;
}

export enum PlaylistItemOrderByInput {
  CreatedAtAsc = "created_at__ASC",
  CreatedAtDesc = "created_at__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC"
}

export class PlaylistItemWhereInput {
  id?: InputMaybe<Scalars["Int"]>;
  id__in?: InputMaybe<Array<Scalars["Int"]>>;
  playlist__some?: InputMaybe<BgmPlaylistWhereInput>;
  playlist_id?: InputMaybe<Scalars["Int"]>;
  playlist_id__in?: InputMaybe<Array<Scalars["Int"]>>;
  track_id?: InputMaybe<Scalars["Int"]>;
  track_id__in?: InputMaybe<Array<Scalars["Int"]>>;
}

export enum PlaylistOrderByInput {
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  KindAsc = "kind__ASC",
  KindDesc = "kind__DESC",
  NameAsc = "name__ASC",
  NameDesc = "name__DESC",
  PlaylistIdAsc = "playlist_id__ASC",
  PlaylistIdDesc = "playlist_id__DESC",
  PriceAsc = "price__ASC",
  PriceDesc = "price__DESC",
  RemarkAsc = "remark__ASC",
  RemarkDesc = "remark__DESC",
  ServiceAsc = "service__ASC",
  ServiceDesc = "service__DESC",
  ValidCheckAsc = "valid_check__ASC",
  ValidCheckDesc = "valid_check__DESC"
}

export enum PlaylistType {
  Curation = "CURATION",
  Like = "LIKE",
  My = "MY"
}

export class PlaylistUpdateInput {
  _onlySearch?: InputMaybe<Scalars["Boolean"]>;
  kind?: InputMaybe<Scalars["String"]>;
  metadata_playlist_relation?: InputMaybe<Metadata_Playlist_RelationUpdateManyWithoutPlaylistInput>;
  name?: InputMaybe<Scalars["String"]>;
  playlist_genre_relation?: InputMaybe<Playlist_Genre_RelationUpdateManyWithoutPlaylistInput>;
  playlist_title?: InputMaybe<Playlist_TitleUpdateManyWithoutPlaylistInput>;
  playlist_url?: InputMaybe<Playlist_UrlUpdateManyWithoutPlaylistInput>;
  playlist_users_relation?: InputMaybe<Playlist_Users_RelationUpdateManyWithoutPlaylistInput>;
  price?: InputMaybe<Scalars["Int"]>;
  remark?: InputMaybe<Scalars["String"]>;
  service?: InputMaybe<ServiceType>;
  valid_check?: InputMaybe<ValidType>;
}

export class PlaylistWhereInput {
  AND?: InputMaybe<Array<PlaylistWhereInput>>;
  NOT?: InputMaybe<Array<PlaylistWhereInput>>;
  OR?: InputMaybe<Array<PlaylistWhereInput>>;
  kind?: InputMaybe<Scalars["String"]>;
  kind__contains?: InputMaybe<Scalars["String"]>;
  kind__ends_with?: InputMaybe<Scalars["String"]>;
  kind__in?: InputMaybe<Array<Scalars["String"]>>;
  kind__not?: InputMaybe<Scalars["String"]>;
  kind__not_contains?: InputMaybe<Scalars["String"]>;
  kind__not_ends_with?: InputMaybe<Scalars["String"]>;
  kind__not_in?: InputMaybe<Array<Scalars["String"]>>;
  kind__not_starts_with?: InputMaybe<Scalars["String"]>;
  kind__starts_with?: InputMaybe<Scalars["String"]>;
  metadata_playlist_relation__lateral?: InputMaybe<Scalars["Boolean"]>;
  metadata_playlist_relation__some?: InputMaybe<Metadata_Playlist_RelationWhereInput>;
  name?: InputMaybe<Scalars["String"]>;
  name__contains?: InputMaybe<Scalars["String"]>;
  name__ends_with?: InputMaybe<Scalars["String"]>;
  name__in?: InputMaybe<Array<Scalars["String"]>>;
  name__not?: InputMaybe<Scalars["String"]>;
  name__not_contains?: InputMaybe<Scalars["String"]>;
  name__not_ends_with?: InputMaybe<Scalars["String"]>;
  name__not_in?: InputMaybe<Array<Scalars["String"]>>;
  name__not_starts_with?: InputMaybe<Scalars["String"]>;
  name__starts_with?: InputMaybe<Scalars["String"]>;
  playlist_genre_relation__lateral?: InputMaybe<Scalars["Boolean"]>;
  playlist_genre_relation__some?: InputMaybe<Playlist_Genre_RelationWhereInput>;
  playlist_id?: InputMaybe<Scalars["ID"]>;
  playlist_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  playlist_id__not?: InputMaybe<Scalars["ID"]>;
  playlist_id__not_in?: InputMaybe<Array<Scalars["ID"]>>;
  playlist_title__lateral?: InputMaybe<Scalars["Boolean"]>;
  playlist_title__some?: InputMaybe<Playlist_TitleWhereInput>;
  playlist_ur__lateral?: InputMaybe<Scalars["Boolean"]>;
  playlist_url__some?: InputMaybe<Playlist_UrlWhereInput>;
  playlist_users_relation__lateral?: InputMaybe<Scalars["Boolean"]>;
  playlist_users_relation__some?: InputMaybe<Playlist_Users_RelationWhereInput>;
  service?: InputMaybe<ServiceType>;
  service__contains?: InputMaybe<ServiceType>;
  service__ends_with?: InputMaybe<ServiceType>;
  service__in?: InputMaybe<Array<ServiceType>>;
  service__not?: InputMaybe<ServiceType>;
  service__not_contains?: InputMaybe<ServiceType>;
  service__not_ends_with?: InputMaybe<ServiceType>;
  service__not_in?: InputMaybe<Array<ServiceType>>;
  service__not_starts_with?: InputMaybe<ServiceType>;
  service__starts_with?: InputMaybe<ServiceType>;
  valid_check?: InputMaybe<ValidType>;
  valid_check__contains?: InputMaybe<ValidType>;
  valid_check__in?: InputMaybe<Array<ValidType>>;
  valid_check__not?: InputMaybe<ValidType>;
  valid_check__not_contains?: InputMaybe<ValidType>;
  valid_check__not_in?: InputMaybe<Array<ValidType>>;
}

export class PlaylistWhereUniqueInput {
  playlist_id?: InputMaybe<Scalars["ID"]>;
}

/**
 * {
 *   "name":"playlist_genre_relation",
 *   "id": "id",
 *   "playlist": { "fk" : "playlist_id" , "pk" : "playlist_id" },
 *   "genre": { "fk" : "genre_id" , "pk" : "genre_id" }
 * }
 */
export class Playlist_Genre_Relation {
  genre?: Maybe<Array<Maybe<Genre>>>;
  id: Scalars["UUID"];
  is_onwer?: Maybe<Scalars["Boolean"]>;
  order: Scalars["Int"];
  playlist?: Maybe<Array<Maybe<Playlist>>>;
  playlist_id?: Maybe<Scalars["ID"]>;
  users_id?: Maybe<Scalars["ID"]>;
  valid_check?: Maybe<ValidType>;
}

export class Playlist_Genre_RelationCreateInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  genre: GenreChildInput;
  is_onwer?: InputMaybe<Scalars["Boolean"]>;
  playlist: PlaylistChildInput;
}

export class Playlist_Genre_RelationCreateManyWithoutPlaylistInput {
  create?: InputMaybe<Array<Playlist_Genre_RelationCreateWithoutPlaylistInput>>;
}

export class Playlist_Genre_RelationCreateWithoutPlaylistInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  genre: GenreChildInput;
  is_onwer?: InputMaybe<Scalars["Boolean"]>;
  valid_check?: InputMaybe<ValidType>;
}

export enum Playlist_Genre_RelationOrderByInput {
  ExposureOrderAsc = "exposure_order__ASC",
  ExposureOrderDesc = "exposure_order__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  IsOnwerAsc = "is_onwer__ASC",
  IsOnwerDesc = "is_onwer__DESC"
}

export class Playlist_Genre_RelationUpdateInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  genre?: InputMaybe<GenreChildInput>;
  is_onwer?: InputMaybe<Scalars["Boolean"]>;
  playlist?: InputMaybe<PlaylistChildInput>;
  valid_check?: InputMaybe<ValidType>;
}

export class Playlist_Genre_RelationUpdateManyWithoutPlaylistInput {
  create?: InputMaybe<Array<Playlist_Genre_RelationCreateWithoutPlaylistInput>>;
  update?: InputMaybe<Array<Playlist_Genre_RelationUpdateWithWhereUniqueWithoutPlaylistInput>>;
}

export class Playlist_Genre_RelationUpdateWithWhereUniqueWithoutPlaylistInput {
  data: Playlist_Genre_RelationUpdateWithoutPlaylistDataInput;
  where: UuidWhereUniqueInput;
}

export class Playlist_Genre_RelationUpdateWithoutPlaylistDataInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  genre: GenreChildInput;
  is_onwer?: InputMaybe<Scalars["Boolean"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class Playlist_Genre_RelationWhereInput {
  AND?: InputMaybe<Array<Playlist_Genre_RelationWhereInput>>;
  NOT?: InputMaybe<Array<Playlist_Genre_RelationWhereInput>>;
  OR?: InputMaybe<Array<Playlist_Genre_RelationWhereInput>>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  exposure_order__gt?: InputMaybe<Scalars["Int"]>;
  exposure_order__gte?: InputMaybe<Scalars["Int"]>;
  exposure_order__in?: InputMaybe<Array<Scalars["Int"]>>;
  exposure_order__lt?: InputMaybe<Scalars["Int"]>;
  exposure_order__lte?: InputMaybe<Scalars["Int"]>;
  exposure_order__not?: InputMaybe<Scalars["Int"]>;
  exposure_order__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  genre__lateral?: InputMaybe<Scalars["Boolean"]>;
  genre__some?: InputMaybe<GenreWhereInput>;
  genre_id?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  genre_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  genre_id__not_include?: InputMaybe<Array<Scalars["ID"]>>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  id__not?: InputMaybe<Scalars["UUID"]>;
  id__not_in?: InputMaybe<Array<Scalars["UUID"]>>;
  is_onwer?: InputMaybe<Scalars["Boolean"]>;
  is_onwer__in?: InputMaybe<Array<Scalars["Boolean"]>>;
  is_onwer__not?: InputMaybe<Scalars["Boolean"]>;
  is_onwer__not_in?: InputMaybe<Array<Scalars["Boolean"]>>;
  playlist__lateral?: InputMaybe<Scalars["Boolean"]>;
  playlist__some?: InputMaybe<PlaylistWhereInput>;
  playlist_id?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  playlist_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  playlist_id__not_include?: InputMaybe<Array<Scalars["ID"]>>;
  valid_check?: InputMaybe<ValidType>;
  valid_check__in?: InputMaybe<Array<InputMaybe<ValidType>>>;
  valid_check__not_in?: InputMaybe<Array<InputMaybe<ValidType>>>;
}

/**
 * {
 *   "name":"playlist_title",
 *   "id": "id",
 *   "playlist": { "fk" : "playlist_id" , "pk" : "playlist_id" },
 *   "language": { "fk" : "language_code" , "pk" : "language_code" }
 * }
 */
export class Playlist_Title {
  exposure_order: Scalars["Int"];
  id: Scalars["UUID"];
  language?: Maybe<Array<Maybe<Language>>>;
  language_code?: Maybe<Scalars["ID"]>;
  playlist: Array<Maybe<Playlist>>;
  playlist_id?: Maybe<Scalars["ID"]>;
  tsvec_words?: Maybe<Scalars["String"]>;
  type_title?: Maybe<Scalars["String"]>;
  value: Scalars["String"];
}

/**
 * {
 *   "name":"playlist_title",
 *   "id": "id",
 *   "playlist": { "fk" : "playlist_id" , "pk" : "playlist_id" },
 *   "language": { "fk" : "language_code" , "pk" : "language_code" }
 * }
 */
export class Playlist_TitleLanguageArgs {
  where?: InputMaybe<LanguageWhereInput>;
}

export class Playlist_TitleCreateInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  language: LanguageChildInput;
  playlist: PlaylistChildInput;
  type_title?: InputMaybe<Scalars["String"]>;
  value: Scalars["String"];
}

export class Playlist_TitleCreateManyWithoutPlaylistInput {
  create?: InputMaybe<Array<Playlist_TitleCreateWithoutPlaylistInput>>;
}

export class Playlist_TitleCreateWithoutPlaylistInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  language: LanguageChildInput;
  type_title?: InputMaybe<Scalars["String"]>;
  value: Scalars["String"];
}

export enum Playlist_TitleOrderByInput {
  ExposureOrderAsc = "exposure_order__ASC",
  ExposureOrderDesc = "exposure_order__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  TypeTitleAsc = "type_title__ASC",
  TypeTitleDesc = "type_title__DESC",
  ValueAsc = "value__ASC",
  ValueDesc = "value__DESC",
  ValueSimilarity = "value__SIMILARITY"
}

export class Playlist_TitleUpdateInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  language?: InputMaybe<LanguageChildInput>;
  playlist?: InputMaybe<PlaylistChildInput>;
  type_title?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
}

export class Playlist_TitleUpdateManyWithoutPlaylistInput {
  create?: InputMaybe<Array<Playlist_TitleCreateWithoutPlaylistInput>>;
  update?: InputMaybe<Array<Playlist_TitleUpdateWithWhereUniqueWithoutPlaylistInput>>;
}

export class Playlist_TitleUpdateWithWhereUniqueWithoutPlaylistInput {
  data: Playlist_TitleUpdateWithoutPlaylistDataInput;
  where: UuidWhereUniqueInput;
}

export class Playlist_TitleUpdateWithoutPlaylistDataInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  language?: InputMaybe<LanguageChildInput>;
  type_title?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
}

export class Playlist_TitleWhereInput {
  AND?: InputMaybe<Array<Playlist_TitleWhereInput>>;
  NOT?: InputMaybe<Array<Playlist_TitleWhereInput>>;
  OR?: InputMaybe<Array<Playlist_TitleWhereInput>>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  exposure_order__gt?: InputMaybe<Scalars["Int"]>;
  exposure_order__gte?: InputMaybe<Scalars["Int"]>;
  exposure_order__in?: InputMaybe<Array<Scalars["Int"]>>;
  exposure_order__lt?: InputMaybe<Scalars["Int"]>;
  exposure_order__lte?: InputMaybe<Scalars["Int"]>;
  exposure_order__not?: InputMaybe<Scalars["Int"]>;
  exposure_order__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  language__lateral?: InputMaybe<Scalars["Boolean"]>;
  language__some?: InputMaybe<LanguageWhereInput>;
  language_code?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  language_code__in?: InputMaybe<Array<Scalars["ID"]>>;
  language_code__not_include?: InputMaybe<Array<Scalars["ID"]>>;
  playlist__lateral?: InputMaybe<Scalars["Boolean"]>;
  playlist__some?: InputMaybe<PlaylistWhereInput>;
  playlist_id?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  playlist_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  playlist_id__not_include?: InputMaybe<Array<Scalars["ID"]>>;
  tsvec_words__search?: InputMaybe<Scalars["String"]>;
  type_title?: InputMaybe<Scalars["String"]>;
  type_title__contains?: InputMaybe<Scalars["String"]>;
  type_title__ends_with?: InputMaybe<Scalars["String"]>;
  type_title__in?: InputMaybe<Array<Scalars["String"]>>;
  type_title__not?: InputMaybe<Scalars["String"]>;
  type_title__not_contains?: InputMaybe<Scalars["String"]>;
  type_title__not_ends_with?: InputMaybe<Scalars["String"]>;
  type_title__not_in?: InputMaybe<Array<Scalars["String"]>>;
  type_title__not_starts_with?: InputMaybe<Scalars["String"]>;
  type_title__starts_with?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
  value__contains?: InputMaybe<Scalars["String"]>;
  value__ends_with?: InputMaybe<Scalars["String"]>;
  value__in?: InputMaybe<Array<Scalars["String"]>>;
  value__not?: InputMaybe<Scalars["String"]>;
  value__not_contains?: InputMaybe<Scalars["String"]>;
  value__not_ends_with?: InputMaybe<Scalars["String"]>;
  value__not_in?: InputMaybe<Array<Scalars["String"]>>;
  value__not_starts_with?: InputMaybe<Scalars["String"]>;
  value__starts_with?: InputMaybe<Scalars["String"]>;
}

/**
 * {
 *   "name":"playlist_url",
 *   "id": "id",
 *   "playlist": { "fk" : "playlist_id" , "pk" : "playlist_id" }
 * }
 */
export class Playlist_Url {
  exposure_order: Scalars["Int"];
  id: Scalars["UUID"];
  playlist?: Maybe<Array<Maybe<Playlist>>>;
  playlist_id?: Maybe<Scalars["ID"]>;
  type_url: Scalars["String"];
  url: Scalars["String"];
  valid_check?: Maybe<ValidType>;
}

export class Playlist_UrlCreateManyWithoutPlaylistInput {
  create?: InputMaybe<Array<Playlist_UrlCreateWithoutPlaylistInput>>;
}

export class Playlist_UrlCreateWithoutPlaylistInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  type_url: Scalars["String"];
  url: Scalars["String"];
  valid_check?: InputMaybe<ValidType>;
}

export enum Playlist_UrlOrderByInput {
  CreatedAtAsc = "createdAt__ASC",
  CreatedAtDesc = "createdAt__DESC",
  ExposureOrderAsc = "exposure_order__ASC",
  ExposureOrderDesc = "exposure_order__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  TypeUrlAsc = "type_url__ASC",
  TypeUrlDesc = "type_url__DESC",
  UpdatedAtAsc = "updatedAt__ASC",
  UpdatedAtDesc = "updatedAt__DESC",
  UrlAsc = "url__ASC",
  UrlDesc = "url__DESC",
  ValidCheckAsc = "valid_check__ASC",
  ValidCheckDesc = "valid_check__DESC"
}

export class Playlist_UrlUpdateInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  playlist?: InputMaybe<PlaylistChildInput>;
  type_url?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class Playlist_UrlUpdateManyWithoutPlaylistInput {
  create?: InputMaybe<Array<Playlist_UrlCreateWithoutPlaylistInput>>;
  update?: InputMaybe<Array<Playlist_UrlUpdateWithWhereUniqueWithoutPlaylistInput>>;
}

export class Playlist_UrlUpdateWithWhereUniqueWithoutPlaylistInput {
  data: Playlist_UrlUpdateWithoutPlaylistDataInput;
  where: UuidWhereUniqueInput;
}

export class Playlist_UrlUpdateWithoutPlaylistDataInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  type_url?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class Playlist_UrlWhereInput {
  AND?: InputMaybe<Array<Playlist_UrlWhereInput>>;
  NOT?: InputMaybe<Array<Playlist_UrlWhereInput>>;
  OR?: InputMaybe<Array<Playlist_UrlWhereInput>>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  exposure_order__gt?: InputMaybe<Scalars["Int"]>;
  exposure_order__gte?: InputMaybe<Scalars["Int"]>;
  exposure_order__in?: InputMaybe<Array<Scalars["Int"]>>;
  exposure_order__lt?: InputMaybe<Scalars["Int"]>;
  exposure_order__lte?: InputMaybe<Scalars["Int"]>;
  exposure_order__not?: InputMaybe<Scalars["Int"]>;
  exposure_order__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  playlist__lateral?: InputMaybe<Scalars["Boolean"]>;
  playlist__some?: InputMaybe<PlaylistWhereInput>;
  playlist_id?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  playlist_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  playlist_id__not_include?: InputMaybe<Array<Scalars["ID"]>>;
  type_url?: InputMaybe<Scalars["String"]>;
  type_url__contains?: InputMaybe<Scalars["String"]>;
  type_url__ends_with?: InputMaybe<Scalars["String"]>;
  type_url__in?: InputMaybe<Array<Scalars["String"]>>;
  type_url__not?: InputMaybe<Scalars["String"]>;
  type_url__not_contains?: InputMaybe<Scalars["String"]>;
  type_url__not_ends_with?: InputMaybe<Scalars["String"]>;
  type_url__not_in?: InputMaybe<Array<Scalars["String"]>>;
  type_url__not_starts_with?: InputMaybe<Scalars["String"]>;
  type_url__starts_with?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
  url__contains?: InputMaybe<Scalars["String"]>;
  url__ends_with?: InputMaybe<Scalars["String"]>;
  url__in?: InputMaybe<Array<Scalars["String"]>>;
  url__not?: InputMaybe<Scalars["String"]>;
  url__not_contains?: InputMaybe<Scalars["String"]>;
  url__not_ends_with?: InputMaybe<Scalars["String"]>;
  url__not_in?: InputMaybe<Array<Scalars["String"]>>;
  url__not_starts_with?: InputMaybe<Scalars["String"]>;
  url__starts_with?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
  valid_check__contains?: InputMaybe<ValidType>;
  valid_check__in?: InputMaybe<Array<ValidType>>;
  valid_check__not?: InputMaybe<ValidType>;
  valid_check__not_contains?: InputMaybe<ValidType>;
  valid_check__not_in?: InputMaybe<Array<ValidType>>;
}

/**
 * {
 *   "name":"playlist_users_relation",
 *   "id": "id",
 *   "playlist": { "fk" : "playlist_id" , "pk" : "playlist_id" },
 *   "users": { "fk" : "users_id" , "pk" : "users_id" },
 *   "metadata_playlist_relation": { "fk" : "playlist_id" , "pk" : "playlist_id" }
 * }
 */
export class Playlist_Users_Relation {
  id: Scalars["UUID"];
  is_onwer?: Maybe<Scalars["Boolean"]>;
  order: Scalars["Int"];
  playlist: Array<Maybe<Playlist>>;
  playlist_id?: Maybe<Scalars["ID"]>;
  users: Array<Maybe<Users>>;
  users_id?: Maybe<Scalars["ID"]>;
  valid_check?: Maybe<ValidType>;
  visible_field?: Maybe<Scalars["Array"]>;
}

export class Playlist_Users_RelationCreateInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  is_onwer?: InputMaybe<Scalars["Boolean"]>;
  playlist: PlaylistChildInput;
  users: UsersChildInput;
}

export class Playlist_Users_RelationCreateManyWithoutPlaylistInput {
  create?: InputMaybe<Array<Playlist_Users_RelationCreateWithoutPlaylistInput>>;
}

export class Playlist_Users_RelationCreateWithoutPlaylistInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  is_onwer?: InputMaybe<Scalars["Boolean"]>;
  users: UsersChildInput;
  valid_check?: InputMaybe<ValidType>;
  visible_field?: InputMaybe<Scalars["Array"]>;
}

export enum Playlist_Users_RelationOrderByInput {
  ExposureOrderAsc = "exposure_order__ASC",
  ExposureOrderDesc = "exposure_order__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  IsOnwerAsc = "is_onwer__ASC",
  IsOnwerDesc = "is_onwer__DESC"
}

export class Playlist_Users_RelationUpdateInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  is_onwer?: InputMaybe<Scalars["Boolean"]>;
  playlist?: InputMaybe<PlaylistChildInput>;
  users?: InputMaybe<UsersChildInput>;
  valid_check?: InputMaybe<ValidType>;
  visible_field?: InputMaybe<Scalars["Array"]>;
}

export class Playlist_Users_RelationUpdateManyWithoutPlaylistInput {
  create?: InputMaybe<Array<Playlist_Users_RelationCreateWithoutPlaylistInput>>;
  update?: InputMaybe<Array<Playlist_Users_RelationUpdateWithWhereUniqueWithoutPlaylistInput>>;
}

export class Playlist_Users_RelationUpdateWithWhereUniqueWithoutPlaylistInput {
  data: Playlist_Users_RelationUpdateWithoutPlaylistDataInput;
  where: UuidWhereUniqueInput;
}

export class Playlist_Users_RelationUpdateWithoutPlaylistDataInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  is_onwer?: InputMaybe<Scalars["Boolean"]>;
  users: UsersChildInput;
  valid_check?: InputMaybe<ValidType>;
  visible_field?: InputMaybe<Scalars["Array"]>;
}

export class Playlist_Users_RelationWhereInput {
  AND?: InputMaybe<Array<Playlist_Users_RelationWhereInput>>;
  NOT?: InputMaybe<Array<Playlist_Users_RelationWhereInput>>;
  OR?: InputMaybe<Array<Playlist_Users_RelationWhereInput>>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  exposure_order__gt?: InputMaybe<Scalars["Int"]>;
  exposure_order__gte?: InputMaybe<Scalars["Int"]>;
  exposure_order__in?: InputMaybe<Array<Scalars["Int"]>>;
  exposure_order__lt?: InputMaybe<Scalars["Int"]>;
  exposure_order__lte?: InputMaybe<Scalars["Int"]>;
  exposure_order__not?: InputMaybe<Scalars["Int"]>;
  exposure_order__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  id__not?: InputMaybe<Scalars["UUID"]>;
  id__not_in?: InputMaybe<Array<Scalars["UUID"]>>;
  is_onwer?: InputMaybe<Scalars["Boolean"]>;
  is_onwer__in?: InputMaybe<Array<Scalars["Boolean"]>>;
  is_onwer__not?: InputMaybe<Scalars["Boolean"]>;
  is_onwer__not_in?: InputMaybe<Array<Scalars["Boolean"]>>;
  playlist__lateral?: InputMaybe<Scalars["Boolean"]>;
  playlist__some?: InputMaybe<PlaylistWhereInput>;
  playlist_id?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  playlist_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  playlist_id__not_include?: InputMaybe<Array<Scalars["ID"]>>;
  users__some?: InputMaybe<UsersWhereInput>;
  users_id?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  users_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  users_id__not_include?: InputMaybe<Array<Scalars["ID"]>>;
  valid_check?: InputMaybe<ValidType>;
  valid_check__in?: InputMaybe<Array<InputMaybe<ValidType>>>;
  valid_check__not_in?: InputMaybe<Array<InputMaybe<ValidType>>>;
}

export class PointTransaction {
  amount: Scalars["Int"];
  bill_id?: Maybe<Scalars["Int"]>;
  created_at: Scalars["DateTime"];
  event_type: EventType;
  id: Scalars["Int"];
  payletter_bill?: Maybe<PayletterBill>;
  purchase_history?: Maybe<PurchaseHistory>;
  request_user_id: Scalars["Int"];
  requester: User;
  target_user_id: Scalars["Int"];
  transaction_type: TransactionType;
  updated_at: Scalars["DateTime"];
  user: User;
}

export enum PointTransactionOrderByInput {
  CreatedAtAsc = "created_at__ASC",
  CreatedAtDesc = "created_at__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC"
}

export class PointTransactionWhereInput {
  event_type?: InputMaybe<EventType>;
  event_type__in?: InputMaybe<Array<EventType>>;
  id?: InputMaybe<Scalars["Int"]>;
  id__gt?: InputMaybe<Scalars["Int"]>;
  id__gte?: InputMaybe<Scalars["Int"]>;
  id__in?: InputMaybe<Array<Scalars["Int"]>>;
  id__lt?: InputMaybe<Scalars["Int"]>;
  id__lte?: InputMaybe<Scalars["Int"]>;
  request_user_id?: InputMaybe<Scalars["Int"]>;
  request_user_id__in?: InputMaybe<Array<Scalars["Int"]>>;
  target_user_id?: InputMaybe<Scalars["Int"]>;
  target_user_id__in?: InputMaybe<Array<Scalars["Int"]>>;
  transaction_type?: InputMaybe<TransactionType>;
  transaction_type__in?: InputMaybe<Array<TransactionType>>;
  user__some?: InputMaybe<UserWhereInput>;
}

export class ProductPrice {
  approver_id: Scalars["Int"];
  created_at: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  is_approve: Scalars["Boolean"];
  price: Scalars["Int"];
  target: TypeMetadataSubclass;
  title: Scalars["String"];
  type: ProductPriceType;
  updated_at: Scalars["DateTime"];
}

export enum ProductPriceOrderByInput {
  CreatedAtAsc = "created_at__ASC",
  CreatedAtDesc = "created_at__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  TargetAsc = "target__ASC",
  TargetDesc = "target__DESC",
  TypeAsc = "type__ASC",
  TypeDesc = "type__DESC"
}

export enum ProductPriceType {
  Curation = "CURATION",
  Mp3 = "MP3",
  Track = "TRACK",
  Wav = "WAV"
}

export class ProductPriceWhereInput {
  id?: InputMaybe<Scalars["Int"]>;
  id__gt?: InputMaybe<Scalars["Int"]>;
  id__gte?: InputMaybe<Scalars["Int"]>;
  id__in?: InputMaybe<Array<Scalars["Int"]>>;
  id__lt?: InputMaybe<Scalars["Int"]>;
  id__lte?: InputMaybe<Scalars["Int"]>;
  id__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  is_approve?: InputMaybe<Scalars["Boolean"]>;
  target?: InputMaybe<TypeMetadataSubclass>;
  target__in?: InputMaybe<Array<TypeMetadataSubclass>>;
  title?: InputMaybe<Scalars["String"]>;
  title__starts_with?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<ProductPriceType>;
  type__in?: InputMaybe<Array<ProductPriceType>>;
}

export enum ProductType {
  Curation = "CURATION",
  Track = "TRACK"
}

export class PurchaseHistory {
  created_at: Scalars["DateTime"];
  id: Scalars["Int"];
  is_cancel: Scalars["Boolean"];
  item_total_amount: Scalars["Int"];
  item_total_count: Scalars["Int"];
  point_transaction?: Maybe<Array<Maybe<PointTransaction>>>;
  purchase_item?: Maybe<Array<Maybe<PurchaseItem>>>;
  updated_at: Scalars["DateTime"];
  user_id: Scalars["Int"];
}

export class PurchaseHistoryPurchase_ItemArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<PurchaseItemOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<PurchaseItemWhereInput>;
}

export enum PurchaseHistoryOrderByInput {
  CreatedAtAsc = "created_at__ASC",
  CreatedAtDesc = "created_at__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC"
}

export class PurchaseHistoryWhereInput {
  id?: InputMaybe<Scalars["Int"]>;
  id__gt?: InputMaybe<Scalars["Int"]>;
  id__gte?: InputMaybe<Scalars["Int"]>;
  id__in?: InputMaybe<Array<Scalars["Int"]>>;
  id__lt?: InputMaybe<Scalars["Int"]>;
  id__lte?: InputMaybe<Scalars["Int"]>;
  purchase_item__some?: InputMaybe<PurchaseItemWhereInput>;
  user_id?: InputMaybe<Scalars["Int"]>;
  user_id__in?: InputMaybe<Array<Scalars["Int"]>>;
}

export class PurchaseItem {
  created_at: Scalars["DateTime"];
  curation?: Maybe<Playlist>;
  file_rule_id?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  is_cancel: Scalars["Boolean"];
  mp3_price?: Maybe<ProductPrice>;
  mp3_price_id?: Maybe<Scalars["Int"]>;
  product_id: Scalars["Int"];
  product_price: ProductPrice;
  product_price_id: Scalars["Int"];
  product_type: ProductType;
  purchase_history: PurchaseHistory;
  purchase_history_id: Scalars["Int"];
  track?: Maybe<Track>;
  updated_at: Scalars["DateTime"];
  user_id: Scalars["Int"];
  wav_price?: Maybe<ProductPrice>;
  wav_price_id?: Maybe<Scalars["Int"]>;
}

export enum PurchaseItemOrderByInput {
  CreatedAtAsc = "created_at__ASC",
  CreatedAtDesc = "created_at__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC"
}

export class PurchaseItemWhereInput {
  id?: InputMaybe<Scalars["Int"]>;
  id__gt?: InputMaybe<Scalars["Int"]>;
  id__gte?: InputMaybe<Scalars["Int"]>;
  id__in?: InputMaybe<Array<Scalars["Int"]>>;
  id__lt?: InputMaybe<Scalars["Int"]>;
  id__lte?: InputMaybe<Scalars["Int"]>;
  product_id?: InputMaybe<Scalars["Int"]>;
  product_id__in?: InputMaybe<Array<Scalars["Int"]>>;
  product_type?: InputMaybe<ProductType>;
  purchase_history_id?: InputMaybe<Scalars["Int"]>;
}

export enum QualityOfCommodity {
  A = "A",
  B = "B",
  New = "NEW",
  S = "S"
}

export class Query {
  accessTokenRefresh: JwtTokenSet;
  access_records: Array<Maybe<Access_Record>>;
  artist: Array<Maybe<Artist>>;
  artist_company_relation: Array<Maybe<Artist_Company_Relation>>;
  artist_genre_relation: Array<Maybe<Artist_Genre_Relation>>;
  artist_history: Array<Maybe<Artist_History>>;
  artist_role_relation: Array<Maybe<Artist_Role_Relation>>;
  artist_self_relation: Array<Maybe<Artist_Self_Relation>>;
  artist_self_relations_element_artist?: Maybe<Artist_Self_Relation>;
  artist_self_relations_org_artist?: Maybe<Artist_Self_Relation>;
  artist_title: Array<Maybe<Artist_Title>>;
  artist_url: Array<Maybe<Artist_Url>>;
  broadcast_billing?: Maybe<Array<Maybe<Broadcast_Billing>>>;
  calling_record: Array<Maybe<Calling_Record>>;
  cart: Cart;
  cart_item?: Maybe<Array<Maybe<CartItem>>>;
  channel_donate_billing?: Maybe<Array<Maybe<Channel_Donate_Billing>>>;
  comment?: Maybe<Array<Comment>>;
  commodity?: Maybe<Array<Maybe<Commodity>>>;
  commodity_url?: Maybe<Array<Commodity_Url>>;
  company: Array<Maybe<Company>>;
  company_title: Array<Maybe<Company_Title>>;
  company_url: Array<Maybe<Company_Url>>;
  company_users_relation: Array<Maybe<Company_Users_Relation>>;
  count?: Maybe<Aggregate>;
  curation_management?: Maybe<Array<Maybe<CurationManagement>>>;
  edge?: Maybe<PageInfo>;
  file_rule: Array<FileRule>;
  find_granted_access?: Maybe<Array<GrantedAccess>>;
  genre: Array<Maybe<Genre>>;
  genre_self_relation: Array<Maybe<Genre_Self_Relation>>;
  genre_self_relation_child?: Maybe<Genre_Self_Relation>;
  genre_self_relation_parent?: Maybe<Genre_Self_Relation>;
  genre_title: Array<Maybe<Genre_Title>>;
  genre_url: Array<Maybe<Genre_Url>>;
  getCount?: Maybe<Aggregate>;
  get_account?: Maybe<Array<Maybe<Contract_Bill>>>;
  get_totalAccount?: Maybe<Assets>;
  goods?: Maybe<Array<Maybe<Goods>>>;
  goods_artist_relation?: Maybe<Array<Goods_Artist_Relation>>;
  goods_company_relation?: Maybe<Array<Goods_Company_Relation>>;
  goods_structure?: Maybe<Array<Goods_Structure>>;
  goods_subdata_unique: Array<Maybe<Goods_Subdata_Unique>>;
  goods_title?: Maybe<Array<Goods_Title>>;
  goods_type?: Maybe<Array<Maybe<Goods_Type>>>;
  goods_url?: Maybe<Array<Goods_Url>>;
  grade: Array<Grade>;
  idIsExist?: Maybe<Scalars["Boolean"]>;
  invalidate_cache_all: Scalars["Boolean"];
  language: Array<Maybe<Language>>;
  language_title: Array<Maybe<Language_Title>>;
  license?: Maybe<Array<Maybe<License>>>;
  logIn?: Maybe<Users>;
  logOut?: Maybe<Scalars["Boolean"]>;
  metadata?: Maybe<Array<Maybe<Metadata>>>;
  metadata_artist_relation: Array<Maybe<Metadata_Artist_Relation>>;
  metadata_company_relation: Array<Maybe<Metadata_Company_Relation>>;
  metadata_donate_billing?: Maybe<Array<Maybe<Metadata_Donate_Billing>>>;
  metadata_genre_relation: Array<Maybe<Metadata_Genre_Relation>>;
  metadata_goods_relation?: Maybe<Array<Metadata_Goods_Relation>>;
  metadata_language_relation: Array<Maybe<Metadata_Language_Relation>>;
  metadata_organization_relation: Array<Maybe<Metadata_Organization_Relation>>;
  metadata_playlist_relation: Array<Maybe<Metadata_Playlist_Relation>>;
  metadata_self_relation: Array<Maybe<Metadata_Self_Relation>>;
  metadata_self_relations_element_metadata?: Maybe<Metadata_Self_Relation>;
  metadata_self_relations_list_metadata?: Maybe<Metadata_Self_Relation>;
  metadata_structure: Array<Maybe<Metadata_Structure>>;
  metadata_structuresDownHillScan?: Maybe<Array<Maybe<Metadata_Structure>>>;
  metadata_structuresUpHillScan?: Maybe<Array<Maybe<Metadata_Structure>>>;
  metadata_subdata_unique: Array<Maybe<Metadata_Subdata_Unique>>;
  metadata_title: Array<Maybe<Metadata_Title>>;
  metadata_url: Array<Maybe<Metadata_Url>>;
  payletter_bill?: Maybe<Array<Maybe<PayletterBill>>>;
  payoneq_information?: Maybe<Array<Maybe<PayoneqInformation>>>;
  play_count?: Maybe<Array<Maybe<Play_Count>>>;
  playlist: Array<Maybe<Playlist>>;
  playlist_genre_relation: Array<Maybe<Playlist_Genre_Relation>>;
  playlist_item?: Maybe<Array<Maybe<PlaylistItem>>>;
  playlist_title: Array<Maybe<Playlist_Title>>;
  playlist_url: Array<Maybe<Playlist_Url>>;
  playlist_users_relation: Array<Maybe<Playlist_Users_Relation>>;
  point_transaction?: Maybe<Array<Maybe<PointTransaction>>>;
  product_price: Array<ProductPrice>;
  purchase_history?: Maybe<Array<Maybe<PurchaseHistory>>>;
  purchase_item?: Maybe<Array<Maybe<PurchaseItem>>>;
  refreshToken?: Maybe<Users>;
  role: Array<Maybe<Role>>;
  role_self_relation: Array<Maybe<Role_Self_Relation>>;
  role_self_relation_element_role?: Maybe<Array<Role_Self_Relation>>;
  role_self_relation_list_role?: Maybe<Array<Role_Self_Relation>>;
  role_title: Array<Maybe<Role_Title>>;
  role_url: Array<Maybe<Role_Url>>;
  row_records: Array<Maybe<Row_Record>>;
  signIn: JwtTokenSet;
  tonality: Array<Maybe<Tonality>>;
  track?: Maybe<Array<Maybe<Track>>>;
  track_activity?: Maybe<Array<Maybe<TrackActivity>>>;
  track_information: Array<Maybe<Track_Information>>;
  track_license: Array<Maybe<Track_License>>;
  track_license_extra: Array<Maybe<Track_License_Extra>>;
  track_play_count?: Maybe<Array<Maybe<Track_Play_Count>>>;
  user?: Maybe<Array<Maybe<User>>>;
  userInfo?: Maybe<User>;
  user_wallet?: Maybe<UserWallet>;
  users?: Maybe<Array<Maybe<Users>>>;
  ytvDownload?: Maybe<Scalars["Boolean"]>;
}

export class QueryAccess_RecordsArgs {
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<AccessRecordOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AccessRecordWhereInput>;
}

export class QueryArtistArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<ArtistOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ArtistWhereInput>;
}

export class QueryArtist_Company_RelationArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Artist_Company_RelationOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Artist_Company_RelationWhereInput>;
}

export class QueryArtist_Genre_RelationArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Artist_Genre_RelationOrderByInput>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Artist_Genre_RelationWhereInput>;
}

export class QueryArtist_HistoryArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Artist_HistoryOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Artist_HistoryWhereInput>;
}

export class QueryArtist_Role_RelationArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Artist_Role_RelationOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Artist_Role_RelationWhereInput>;
}

export class QueryArtist_Self_RelationArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Artist_Self_RelationOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Artist_Self_RelationWhereInput>;
}

export class QueryArtist_Self_Relations_Element_ArtistArgs {
  after?: InputMaybe<Scalars["Int"]>;
  page?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<UuidWhereUniqueInput>;
}

export class QueryArtist_Self_Relations_Org_ArtistArgs {
  after?: InputMaybe<Scalars["Int"]>;
  page?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<UuidWhereUniqueInput>;
}

export class QueryArtist_TitleArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Artist_TitleOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Artist_TitleWhereInput>;
}

export class QueryArtist_UrlArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Artist_UrlOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Artist_UrlWhereInput>;
}

export class QueryBroadcast_BillingArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<IdOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<IdUniqueInput>;
}

export class QueryCalling_RecordArgs {
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<RowRecordOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RowRecordWhereInput>;
}

export class QueryCartArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
}

export class QueryCart_ItemArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<CartItemOrderByInput>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CartItemWhereInput>;
}

export class QueryChannel_Donate_BillingArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<IdOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<IdUniqueInput>;
}

export class QueryCommentArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<CommentOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CommentWhereInput>;
}

export class QueryCommodityArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<CommodityOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CommodityWhereInput>;
}

export class QueryCommodity_UrlArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<Commodity_UrlOrderByInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Commodity_UrlWhereInput>;
}

export class QueryCompanyArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<CompanyOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CompanyWhereInput>;
}

export class QueryCompany_TitleArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Company_TitleOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Company_TitleWhereInput>;
}

export class QueryCompany_UrlArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Company_UrlOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Company_UrlWhereInput>;
}

export class QueryCompany_Users_RelationArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Company_Users_RelationOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Company_Users_RelationWhereInput>;
}

export class QueryCountArgs {
  node: NodeType;
  where?: InputMaybe<PageWhere>;
}

export class QueryCuration_ManagementArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  groupla?: InputMaybe<Scalars["Boolean"]>;
  is_force?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<CurationManagementOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CurationManagementWhereInput>;
}

export class QueryEdgeArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  is_force?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  node: NodeType;
  orderBy?: InputMaybe<PageOrder>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<PageWhere>;
}

export class QueryFile_RuleArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FileRuleOrderByInput>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<FileRuleWhereInput>;
}

export class QueryFind_Granted_AccessArgs {
  where?: InputMaybe<TaskListWhereInput>;
}

export class QueryGenreArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<GenreOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<GenreWhereInput>;
}

export class QueryGenre_Self_RelationArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Genre_Self_RelationOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Genre_Self_RelationWhereInput>;
}

export class QueryGenre_Self_Relation_ChildArgs {
  after?: InputMaybe<Scalars["Int"]>;
  page?: InputMaybe<Scalars["Int"]>;
  where: UuidWhereUniqueInput;
}

export class QueryGenre_Self_Relation_ParentArgs {
  after?: InputMaybe<Scalars["Int"]>;
  page?: InputMaybe<Scalars["Int"]>;
  where: UuidWhereUniqueInput;
}

export class QueryGenre_TitleArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Genre_TitleOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Genre_TitleWhereInput>;
}

export class QueryGenre_UrlArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Genre_UrlOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Genre_UrlWhereInput>;
}

export class QueryGetCountArgs {
  group?: InputMaybe<Scalars["Boolean"]>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  is_force?: InputMaybe<Scalars["Boolean"]>;
  node: NodeType;
  where?: InputMaybe<PageWhere>;
}

export class QueryGet_AccountArgs {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Contract_BillOrderByInput>>>;
  where?: InputMaybe<Users_BillingWhereInput>;
}

export class QueryGet_TotalAccountArgs {
  where?: InputMaybe<UsersWhereInput>;
}

export class QueryGoodsArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<GoodsOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<GoodsWhereInput>;
}

export class QueryGoods_Artist_RelationArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<Goods_Artist_RelationOrderByInput>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Goods_Artist_RelationWhereInput>;
}

export class QueryGoods_Company_RelationArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<Goods_Company_RelationOrderByInput>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Goods_Company_RelationWhereInput>;
}

export class QueryGoods_StructureArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<Goods_StructureOrderByInput>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Goods_StructureWhereInput>;
}

export class QueryGoods_Subdata_UniqueArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Goods_Subdata_UniqueOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Goods_Subdata_UniqueWhereInput>;
}

export class QueryGoods_TitleArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<Goods_TitleOrderByInput>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Goods_TitleWhereInput>;
}

export class QueryGoods_TypeArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<Goods_TypeOrderByInput>>;
  page?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Goods_TypeWhereInput>;
}

export class QueryGoods_UrlArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<Goods_UrlOrderByInput>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Goods_UrlWhereInput>;
}

export class QueryGradeArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<GradeOrderByInput>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<GradeWhereInput>;
}

export class QueryIdIsExistArgs {
  where: UserDataWhereInput;
}

export class QueryLanguageArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<LanguageOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<LanguageWhereInput>;
}

export class QueryLanguage_TitleArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Language_TitleOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Language_TitleWhereInput>;
}

export class QueryLicenseArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  is_force?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<LicenseOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<LicenseWhereInput>;
}

export class QueryLogInArgs {
  where: LogInWhereInput;
}

export class QueryMetadataArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<MetadataOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<MetadataWhereInput>;
}

export class QueryMetadata_Artist_RelationArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Metadata_Artist_RelationOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Metadata_Artist_RelationWhereInput>;
}

export class QueryMetadata_Company_RelationArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Metadata_Company_RelationOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Metadata_Company_RelationWhereInput>;
}

export class QueryMetadata_Donate_BillingArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<IdOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<IdUniqueInput>;
}

export class QueryMetadata_Genre_RelationArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Metadata_Genre_RelationOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Metadata_Genre_RelationWhereInput>;
}

export class QueryMetadata_Goods_RelationArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<Metadata_Goods_RelationOrderByInput>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Metadata_Goods_RelationWhereInput>;
}

export class QueryMetadata_Language_RelationArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Metadata_Language_RelationOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Metadata_Language_RelationWhereInput>;
}

export class QueryMetadata_Organization_RelationArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Metadata_Organization_RelationOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Metadata_Organization_RelationWhereInput>;
}

export class QueryMetadata_Playlist_RelationArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Metadata_Playlist_RelationOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Metadata_Playlist_RelationWhereInput>;
}

export class QueryMetadata_Self_RelationArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Metadata_Self_RelationOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Metadata_Self_RelationWhereInput>;
}

export class QueryMetadata_Self_Relations_Element_MetadataArgs {
  after?: InputMaybe<Scalars["Int"]>;
  page?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<UuidWhereUniqueInput>;
}

export class QueryMetadata_Self_Relations_List_MetadataArgs {
  after?: InputMaybe<Scalars["Int"]>;
  page?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<UuidWhereUniqueInput>;
}

export class QueryMetadata_StructureArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Metadata_StructureOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Metadata_StructureWhereInput>;
}

export class QueryMetadata_StructuresDownHillScanArgs {
  after?: InputMaybe<Scalars["Int"]>;
  page?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Metadata_StructureWhereUniqueInput>;
}

export class QueryMetadata_StructuresUpHillScanArgs {
  after?: InputMaybe<Scalars["Int"]>;
  page?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Metadata_StructureWhereUniqueInput>;
}

export class QueryMetadata_Subdata_UniqueArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Metadata_Subdata_UniqueOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Metadata_Subdata_UniqueWhereInput>;
}

export class QueryMetadata_TitleArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Metadata_TitleOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Metadata_TitleWhereInput>;
}

export class QueryMetadata_UrlArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Metadata_UrlOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Metadata_UrlWhereInput>;
}

export class QueryPayletter_BillArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  is_force?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<PayletterBillOrderByInput>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<PayletterBillWhereInput>;
}

export class QueryPayoneq_InformationArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<PayoneqInformationOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<PayoneqInformationkWhereInput>;
}

export class QueryPlay_CountArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Play_CountWhereUniqueInput>;
}

export class QueryPlaylistArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  is_force?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<PlaylistOrderByInput>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<BgmPlaylistWhereInput>;
}

export class QueryPlaylist_Genre_RelationArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Playlist_Genre_RelationOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Playlist_Genre_RelationWhereInput>;
}

export class QueryPlaylist_ItemArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<PlaylistItemOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<PlaylistItemWhereInput>;
}

export class QueryPlaylist_TitleArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Playlist_TitleOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Playlist_TitleWhereInput>;
}

export class QueryPlaylist_UrlArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Playlist_UrlOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Playlist_UrlWhereInput>;
}

export class QueryPlaylist_Users_RelationArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Playlist_Users_RelationOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Playlist_Users_RelationWhereInput>;
}

export class QueryPoint_TransactionArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  is_force?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<PointTransactionOrderByInput>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<PointTransactionWhereInput>;
}

export class QueryProduct_PriceArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ProductPriceOrderByInput>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ProductPriceWhereInput>;
}

export class QueryPurchase_HistoryArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  is_force?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<PurchaseHistoryOrderByInput>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<PurchaseHistoryWhereInput>;
}

export class QueryPurchase_ItemArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  is_force?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<PurchaseItemOrderByInput>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<PurchaseItemWhereInput>;
}

export class QueryRoleArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<RoleOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RoleWhereInput>;
}

export class QueryRole_Self_RelationArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Role_Self_RelationOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Role_Self_RelationWhereInput>;
}

export class QueryRole_TitleArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Role_TitleOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Role_TitleWhereInput>;
}

export class QueryRole_UrlArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Role_UrlOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Role_UrlWhereInput>;
}

export class QueryRow_RecordsArgs {
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<RowRecordOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RowRecordWhereInput>;
}

export class QuerySignInArgs {
  payload: SignInPayload;
}

export class QueryTonalityArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<TonalityOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TonalityWhereInput>;
}

export class QueryTrackArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<TrackOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TrackWhereInput>;
}

export class QueryTrack_ActivityArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TrackActivityOrderByInput>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TrackActivityWhereInput>;
}

export class QueryTrack_InformationArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Track_InformationOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Track_InformationWhereInput>;
}

export class QueryTrack_LicenseArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Track_LicenseOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Track_LicenseWhereUniqueInput>;
}

export class QueryTrack_License_ExtraArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Track_License_ExtraOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Track_License_ExtraWhereUniqueInput>;
}

export class QueryTrack_Play_CountArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Track_Play_CountOrderByInput>>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Track_Play_CountWhereInput>;
}

export class QueryUserArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  group?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<UserOrderByInput>>;
  page?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<UserWhereInput>;
}

export class QueryUsersArgs {
  where: UsersWhereInput;
}

export class RelationWhereUniqueInput {
  id?: InputMaybe<Scalars["UUID"]>;
}

export class RemoveResponse {
  id?: Maybe<Scalars["Int"]>;
}

/**
 * {
 *   "name":"role",
 *   "id": "role_id" ,
 *   "type" : "Int",
 *   "role_self_relation": { "fk" : "list_role_id" , "pk" : "role_id" ,"name": "role_self_relation"},
 *   "role_self_relation_element_role": { "fk" : "list_role_id" , "pk" : "role_id" ,"name": "role_self_relation"},
 *   "role_self_relation_list_role": { "fk" : "element_role_id" , "pk" : "role_id" , "name": "role_self_relation"},
 *   "characters": { "fk" : "character_id" , "pk" : "role_id" , "name": "metadata_artist_relation"}
 * }
 */
export class Role {
  /** 자식테이블 */
  artist_role_relation?: Maybe<Array<Artist_Role_Relation>>;
  expose: Scalars["Int"];
  /** 자식테이블 */
  metadata_artist_relation?: Maybe<Array<Metadata_Artist_Relation>>;
  /** [악기 / 역활 / 배역 ] 의 이름 */
  name: Scalars["String"];
  /** [악기 / 역활 ] 의 사용(참여) 한 대수 */
  role_count?: Maybe<Scalars["Int"]>;
  /** 데이터의 고유키 */
  role_id: Scalars["ID"];
  /** 자식테이블 */
  role_self_relation?: Maybe<Array<Role_Self_Relation>>;
  /** 자식테이블 */
  role_self_relation_element_role?: Maybe<Array<Role_Self_Relation>>;
  /** 자식테이블 */
  role_self_relation_list_role?: Maybe<Array<Role_Self_Relation>>;
  /** 자식테이블 */
  role_title?: Maybe<Array<Role_Title>>;
  /** 노출 강도 : 낮을수록 데이터의 중요한 데이터 (orderby 칼럼 활용) */
  role_url?: Maybe<Array<Role_Url>>;
  /** [악기 / 역활 / 배역 ] 의 구분자 */
  type_role_class: Scalars["String"];
  /** 데이터 상태값 : DONE 값은 검증된 데이터 */
  valid_check?: Maybe<ValidType>;
}

/**
 * {
 *   "name":"role",
 *   "id": "role_id" ,
 *   "type" : "Int",
 *   "role_self_relation": { "fk" : "list_role_id" , "pk" : "role_id" ,"name": "role_self_relation"},
 *   "role_self_relation_element_role": { "fk" : "list_role_id" , "pk" : "role_id" ,"name": "role_self_relation"},
 *   "role_self_relation_list_role": { "fk" : "element_role_id" , "pk" : "role_id" , "name": "role_self_relation"},
 *   "characters": { "fk" : "character_id" , "pk" : "role_id" , "name": "metadata_artist_relation"}
 * }
 */
export class RoleArtist_Role_RelationArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Artist_Role_RelationOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Artist_Role_RelationWhereInput>;
}

/**
 * {
 *   "name":"role",
 *   "id": "role_id" ,
 *   "type" : "Int",
 *   "role_self_relation": { "fk" : "list_role_id" , "pk" : "role_id" ,"name": "role_self_relation"},
 *   "role_self_relation_element_role": { "fk" : "list_role_id" , "pk" : "role_id" ,"name": "role_self_relation"},
 *   "role_self_relation_list_role": { "fk" : "element_role_id" , "pk" : "role_id" , "name": "role_self_relation"},
 *   "characters": { "fk" : "character_id" , "pk" : "role_id" , "name": "metadata_artist_relation"}
 * }
 */
export class RoleMetadata_Artist_RelationArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Metadata_Artist_RelationOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Metadata_Artist_RelationWhereInput>;
}

/**
 * {
 *   "name":"role",
 *   "id": "role_id" ,
 *   "type" : "Int",
 *   "role_self_relation": { "fk" : "list_role_id" , "pk" : "role_id" ,"name": "role_self_relation"},
 *   "role_self_relation_element_role": { "fk" : "list_role_id" , "pk" : "role_id" ,"name": "role_self_relation"},
 *   "role_self_relation_list_role": { "fk" : "element_role_id" , "pk" : "role_id" , "name": "role_self_relation"},
 *   "characters": { "fk" : "character_id" , "pk" : "role_id" , "name": "metadata_artist_relation"}
 * }
 */
export class RoleRole_Self_RelationArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Role_Self_RelationOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Role_Self_RelationWhereInput>;
}

/**
 * {
 *   "name":"role",
 *   "id": "role_id" ,
 *   "type" : "Int",
 *   "role_self_relation": { "fk" : "list_role_id" , "pk" : "role_id" ,"name": "role_self_relation"},
 *   "role_self_relation_element_role": { "fk" : "list_role_id" , "pk" : "role_id" ,"name": "role_self_relation"},
 *   "role_self_relation_list_role": { "fk" : "element_role_id" , "pk" : "role_id" , "name": "role_self_relation"},
 *   "characters": { "fk" : "character_id" , "pk" : "role_id" , "name": "metadata_artist_relation"}
 * }
 */
export class RoleRole_Self_Relation_Element_RoleArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Role_Self_RelationOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Role_Self_RelationWhereInput>;
}

/**
 * {
 *   "name":"role",
 *   "id": "role_id" ,
 *   "type" : "Int",
 *   "role_self_relation": { "fk" : "list_role_id" , "pk" : "role_id" ,"name": "role_self_relation"},
 *   "role_self_relation_element_role": { "fk" : "list_role_id" , "pk" : "role_id" ,"name": "role_self_relation"},
 *   "role_self_relation_list_role": { "fk" : "element_role_id" , "pk" : "role_id" , "name": "role_self_relation"},
 *   "characters": { "fk" : "character_id" , "pk" : "role_id" , "name": "metadata_artist_relation"}
 * }
 */
export class RoleRole_Self_Relation_List_RoleArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Role_Self_RelationOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Role_Self_RelationWhereInput>;
}

/**
 * {
 *   "name":"role",
 *   "id": "role_id" ,
 *   "type" : "Int",
 *   "role_self_relation": { "fk" : "list_role_id" , "pk" : "role_id" ,"name": "role_self_relation"},
 *   "role_self_relation_element_role": { "fk" : "list_role_id" , "pk" : "role_id" ,"name": "role_self_relation"},
 *   "role_self_relation_list_role": { "fk" : "element_role_id" , "pk" : "role_id" , "name": "role_self_relation"},
 *   "characters": { "fk" : "character_id" , "pk" : "role_id" , "name": "metadata_artist_relation"}
 * }
 */
export class RoleRole_TitleArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Role_TitleOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Role_TitleWhereInput>;
}

/**
 * {
 *   "name":"role",
 *   "id": "role_id" ,
 *   "type" : "Int",
 *   "role_self_relation": { "fk" : "list_role_id" , "pk" : "role_id" ,"name": "role_self_relation"},
 *   "role_self_relation_element_role": { "fk" : "list_role_id" , "pk" : "role_id" ,"name": "role_self_relation"},
 *   "role_self_relation_list_role": { "fk" : "element_role_id" , "pk" : "role_id" , "name": "role_self_relation"},
 *   "characters": { "fk" : "character_id" , "pk" : "role_id" , "name": "metadata_artist_relation"}
 * }
 */
export class RoleRole_UrlArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Role_UrlOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Role_UrlWhereInput>;
}

export class RoleChildInput {
  connect?: InputMaybe<RoleWhereUniqueInput>;
}

export class RoleCreateInput {
  artist_role_relation?: InputMaybe<Artist_Role_RelationCreateManyWithoutRoleInput>;
  expose?: InputMaybe<Scalars["Int"]>;
  metadata_artist_relation?: InputMaybe<Metadata_Artist_RelationCreateManyWithoutRoleInput>;
  name: Scalars["String"];
  role_count?: InputMaybe<Scalars["Int"]>;
  role_self_relation_element_role?: InputMaybe<Role_Self_RelationCreateManyWithoutElement_RoleInput>;
  role_self_relation_list_role?: InputMaybe<Role_Self_RelationCreateManyWithoutList_RoleInput>;
  role_title?: InputMaybe<Role_TitleCreateManyWithoutRoleInput>;
  type_role_class: Scalars["String"];
  valid_check?: InputMaybe<ValidType>;
}

export class RoleCreateWithoutRole_Self_Relations_Element_RolesInput {
  artist_role_relation?: InputMaybe<Artist_Role_RelationCreateManyWithoutRoleInput>;
  expose?: InputMaybe<Scalars["Int"]>;
  metadata_artist_relation?: InputMaybe<Metadata_Artist_RelationCreateManyWithoutRoleInput>;
  name: Scalars["String"];
  role_count?: InputMaybe<Scalars["Int"]>;
  role_self_relations_list_role?: InputMaybe<Role_Self_RelationCreateManyWithoutList_RoleInput>;
  role_title?: InputMaybe<Role_TitleCreateManyWithoutRoleInput>;
  type_role_class: Scalars["String"];
  valid_check?: InputMaybe<ValidType>;
}

export class RoleCreateWithoutRole_Self_Relations_List_RolesInput {
  artist_role_relation?: InputMaybe<Artist_Role_RelationCreateManyWithoutRoleInput>;
  expose?: InputMaybe<Scalars["Int"]>;
  metadata_artist_relation?: InputMaybe<Metadata_Artist_RelationCreateManyWithoutRoleInput>;
  name: Scalars["String"];
  role_count?: InputMaybe<Scalars["Int"]>;
  role_self_relations_element_role?: InputMaybe<Role_Self_RelationCreateManyWithoutElement_RoleInput>;
  role_title?: InputMaybe<Role_TitleCreateManyWithoutRoleInput>;
  type_role_class: Scalars["String"];
  valid_check?: InputMaybe<ValidType>;
}

export enum RoleOrderByInput {
  CreatedAtAsc = "createdAt__ASC",
  CreatedAtDesc = "createdAt__DESC",
  ExposeAsc = "expose__ASC",
  ExposeDesc = "expose__DESC",
  NameAsc = "name__ASC",
  NameDesc = "name__DESC",
  RoleCountAsc = "role_count__ASC",
  RoleCountDesc = "role_count__DESC",
  RoleIdAsc = "role_id__ASC",
  RoleIdDesc = "role_id__DESC",
  TypeRoleClassAsc = "type_role_class__ASC",
  TypeRoleClassDesc = "type_role_class__DESC",
  UpdatedAtAsc = "updatedAt__ASC",
  UpdatedAtDesc = "updatedAt__DESC",
  ValidCheckAsc = "valid_check__ASC",
  ValidCheckDesc = "valid_check__DESC"
}

export class RoleUpdateInput {
  _onlySearch?: InputMaybe<Scalars["Boolean"]>;
  artist_role_relation?: InputMaybe<Artist_Role_RelationUpdateManyWithoutRoleInput>;
  expose?: InputMaybe<Scalars["Int"]>;
  metadata_artist_relation?: InputMaybe<Metadata_Artist_RelationUpdateManyWithoutRoleInput>;
  name?: InputMaybe<Scalars["String"]>;
  role_count?: InputMaybe<Scalars["Int"]>;
  role_self_relation_element_role?: InputMaybe<Role_Self_RelationUpdateManyWithoutElement_RoleInput>;
  role_self_relation_list_role?: InputMaybe<Role_Self_RelationUpdateManyWithoutList_RoleInput>;
  role_title?: InputMaybe<Role_TitleUpdateManyWithoutRoleInput>;
  role_url?: InputMaybe<Role_UrlUpdateManyInput>;
  type_role_class?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class RoleUpdateOneRequiredWithoutRole_Self_Relations_Element_RolesInput {
  connect?: InputMaybe<RoleWhereUniqueInput>;
  create?: InputMaybe<RoleCreateWithoutRole_Self_Relations_Element_RolesInput>;
  update?: InputMaybe<RoleUpdateWithoutRole_Self_Relations_Element_RolesDataInput>;
}

export class RoleUpdateOneRequiredWithoutRole_Self_Relations_List_RolesInput {
  connect?: InputMaybe<RoleWhereUniqueInput>;
  create?: InputMaybe<RoleCreateWithoutRole_Self_Relations_List_RolesInput>;
  update?: InputMaybe<RoleUpdateWithoutRole_Self_Relations_List_RolesDataInput>;
}

export class RoleUpdateWithoutRole_Self_Relations_Element_RolesDataInput {
  artist_role_relation?: InputMaybe<Artist_Role_RelationUpdateManyWithoutRoleInput>;
  expose?: InputMaybe<Scalars["Int"]>;
  metadata_artist_relation?: InputMaybe<Metadata_Artist_RelationUpdateManyWithoutRoleInput>;
  name?: InputMaybe<Scalars["String"]>;
  role_count?: InputMaybe<Scalars["Int"]>;
  role_self_relations_list_role?: InputMaybe<Role_Self_RelationUpdateManyWithoutList_RoleInput>;
  role_title?: InputMaybe<Role_TitleUpdateManyWithoutRoleInput>;
  type_role_class?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class RoleUpdateWithoutRole_Self_Relations_List_RolesDataInput {
  artist_role_relation?: InputMaybe<Artist_Role_RelationUpdateManyWithoutRoleInput>;
  expose?: InputMaybe<Scalars["Int"]>;
  metadata_artist_relation?: InputMaybe<Metadata_Artist_RelationUpdateManyWithoutRoleInput>;
  name?: InputMaybe<Scalars["String"]>;
  role_count?: InputMaybe<Scalars["Int"]>;
  role_self_relations_element_role?: InputMaybe<Role_Self_RelationUpdateManyWithoutElement_RoleInput>;
  role_title?: InputMaybe<Role_TitleUpdateManyWithoutRoleInput>;
  type_role_class?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class RoleWhereInput {
  AND?: InputMaybe<Array<RoleWhereInput>>;
  NOT?: InputMaybe<Array<RoleWhereInput>>;
  OR?: InputMaybe<Array<RoleWhereInput>>;
  artist_role_relation__lateral?: InputMaybe<Scalars["Boolean"]>;
  artist_role_relation__some?: InputMaybe<Artist_Role_RelationWhereInput>;
  expose?: InputMaybe<Scalars["Int"]>;
  expose__gt?: InputMaybe<Scalars["Int"]>;
  expose__gte?: InputMaybe<Scalars["Int"]>;
  expose__in?: InputMaybe<Array<Scalars["Int"]>>;
  expose__lt?: InputMaybe<Scalars["Int"]>;
  expose__lte?: InputMaybe<Scalars["Int"]>;
  expose__not?: InputMaybe<Scalars["Int"]>;
  expose__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  metadata_artist_relation__lateral?: InputMaybe<Scalars["Boolean"]>;
  metadata_artist_relation__some?: InputMaybe<Metadata_Artist_RelationWhereInput>;
  name?: InputMaybe<Scalars["String"]>;
  name__contains?: InputMaybe<Scalars["String"]>;
  name__ends_with?: InputMaybe<Scalars["String"]>;
  name__in?: InputMaybe<Array<Scalars["String"]>>;
  name__not?: InputMaybe<Scalars["String"]>;
  name__not_contains?: InputMaybe<Scalars["String"]>;
  name__not_ends_with?: InputMaybe<Scalars["String"]>;
  name__not_in?: InputMaybe<Array<Scalars["String"]>>;
  name__not_starts_with?: InputMaybe<Scalars["String"]>;
  name__starts_with?: InputMaybe<Scalars["String"]>;
  role_count?: InputMaybe<Scalars["Int"]>;
  role_count__gt?: InputMaybe<Scalars["Int"]>;
  role_count__gte?: InputMaybe<Scalars["Int"]>;
  role_count__in?: InputMaybe<Array<Scalars["Int"]>>;
  role_count__lt?: InputMaybe<Scalars["Int"]>;
  role_count__lte?: InputMaybe<Scalars["Int"]>;
  role_count__not?: InputMaybe<Scalars["Int"]>;
  role_count__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  role_id?: InputMaybe<Scalars["ID"]>;
  role_id__gt?: InputMaybe<Scalars["ID"]>;
  role_id__gte?: InputMaybe<Scalars["ID"]>;
  role_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  role_id__lt?: InputMaybe<Scalars["ID"]>;
  role_id__lte?: InputMaybe<Scalars["ID"]>;
  role_id__not_in?: InputMaybe<Array<Scalars["ID"]>>;
  role_self_relation_element_role__some?: InputMaybe<Role_Self_RelationWhereInput>;
  role_self_relation_list_role__some?: InputMaybe<Role_Self_RelationWhereInput>;
  role_title__lateral?: InputMaybe<Scalars["Boolean"]>;
  role_title__some?: InputMaybe<Role_TitleWhereInput>;
  role_url__lateral?: InputMaybe<Scalars["Boolean"]>;
  role_url__some?: InputMaybe<Role_UrlWhereInput>;
  type_role_class?: InputMaybe<Scalars["String"]>;
  type_role_class__contains?: InputMaybe<Scalars["String"]>;
  type_role_class__ends_with?: InputMaybe<Scalars["String"]>;
  type_role_class__in?: InputMaybe<Array<Scalars["String"]>>;
  type_role_class__not?: InputMaybe<Scalars["String"]>;
  type_role_class__not_contains?: InputMaybe<Scalars["String"]>;
  type_role_class__not_ends_with?: InputMaybe<Scalars["String"]>;
  type_role_class__not_in?: InputMaybe<Array<Scalars["String"]>>;
  type_role_class__not_starts_with?: InputMaybe<Scalars["String"]>;
  type_role_class__starts_with?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
  valid_check__contains?: InputMaybe<ValidType>;
  valid_check__in?: InputMaybe<Array<ValidType>>;
  valid_check__not?: InputMaybe<ValidType>;
  valid_check__not_contains?: InputMaybe<ValidType>;
  valid_check__not_in?: InputMaybe<Array<ValidType>>;
}

export class RoleWhereUniqueInput {
  role_id?: InputMaybe<Scalars["ID"]>;
}

/**
 * {
 *   "name":"role_self_relation",
 *   "id": "id",
 *   "role": { "fk" : "list_role_id" , "pk" : "role_id" },
 *   "role_self_relation_element_role": {"id":"element_role_id", "fk" : "role_id" , "pk" : "list_role_id", "table":"role"},
 *   "role_self_relation_list_role": {"id":"list_role_id", "fk" : "role_id" , "pk" : "element_role_id", "table":"role"}
 * }
 */
export class Role_Self_Relation {
  element_role_id?: Maybe<Scalars["ID"]>;
  exposure_order: Scalars["Int"];
  id: Scalars["UUID"];
  list_role_id?: Maybe<Scalars["ID"]>;
  role_self_relation_element_role: Array<Maybe<Role>>;
  role_self_relation_list_role: Array<Maybe<Role>>;
  type_function: Scalars["String"];
  valid_check?: Maybe<ValidType>;
}

export class Role_Self_RelationCreateManyWithoutElement_RoleInput {
  create?: InputMaybe<Array<Role_Self_RelationCreateWithoutElement_RoleInput>>;
}

export class Role_Self_RelationCreateManyWithoutList_RoleInput {
  create?: InputMaybe<Array<Role_Self_RelationCreateWithoutList_RoleInput>>;
}

export class Role_Self_RelationCreateWithoutElement_RoleInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  role_self_relation_list_role: RoleChildInput;
  type_function: Scalars["String"];
  valid_check?: InputMaybe<ValidType>;
}

export class Role_Self_RelationCreateWithoutList_RoleInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  role_self_relation_element_role: RoleChildInput;
  type_function: Scalars["String"];
  valid_check?: InputMaybe<ValidType>;
}

export enum Role_Self_RelationOrderByInput {
  CreatedAtAsc = "createdAt__ASC",
  CreatedAtDesc = "createdAt__DESC",
  ExposureOrderAsc = "exposure_order__ASC",
  ExposureOrderDesc = "exposure_order__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  TypeFunctionAsc = "type_function__ASC",
  TypeFunctionDesc = "type_function__DESC",
  UpdatedAtAsc = "updatedAt__ASC",
  UpdatedAtDesc = "updatedAt__DESC",
  ValidCheckAsc = "valid_check__ASC",
  ValidCheckDesc = "valid_check__DESC"
}

export class Role_Self_RelationUpdateInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  role_self_relation_element_role?: InputMaybe<RoleChildInput>;
  role_self_relation_list_role?: InputMaybe<RoleChildInput>;
  type_function?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class Role_Self_RelationUpdateManyWithoutElement_RoleInput {
  create?: InputMaybe<Array<Role_Self_RelationCreateWithoutElement_RoleInput>>;
  update?: InputMaybe<Array<Role_Self_RelationUpdateWithWhereUniqueWithoutElement_RoleInput>>;
}

export class Role_Self_RelationUpdateManyWithoutList_RoleInput {
  create?: InputMaybe<Array<Role_Self_RelationCreateWithoutList_RoleInput>>;
  update?: InputMaybe<Array<Role_Self_RelationUpdateWithWhereUniqueWithoutList_RoleInput>>;
}

export class Role_Self_RelationUpdateWithWhereUniqueWithoutElement_RoleInput {
  data: Role_Self_RelationUpdateWithoutElement_RoleDataInput;
  where: UuidWhereUniqueInput;
}

export class Role_Self_RelationUpdateWithWhereUniqueWithoutList_RoleInput {
  data: Role_Self_RelationUpdateWithoutList_RoleDataInput;
  where: UuidWhereUniqueInput;
}

export class Role_Self_RelationUpdateWithoutElement_RoleDataInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  role_self_relation_list_role?: InputMaybe<RoleUpdateOneRequiredWithoutRole_Self_Relations_List_RolesInput>;
  type_function?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class Role_Self_RelationUpdateWithoutList_RoleDataInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  role_self_relation_element_role?: InputMaybe<RoleUpdateOneRequiredWithoutRole_Self_Relations_Element_RolesInput>;
  type_function?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class Role_Self_RelationWhereInput {
  AND?: InputMaybe<Array<Role_Self_RelationWhereInput>>;
  NOT?: InputMaybe<Array<Role_Self_RelationWhereInput>>;
  OR?: InputMaybe<Array<Role_Self_RelationWhereInput>>;
  element_role_id?: InputMaybe<Scalars["ID"]>;
  element_role_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  exposure_order__gt?: InputMaybe<Scalars["Int"]>;
  exposure_order__gte?: InputMaybe<Scalars["Int"]>;
  exposure_order__in?: InputMaybe<Array<Scalars["Int"]>>;
  exposure_order__lt?: InputMaybe<Scalars["Int"]>;
  exposure_order__lte?: InputMaybe<Scalars["Int"]>;
  exposure_order__not?: InputMaybe<Scalars["Int"]>;
  exposure_order__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  list_role_id?: InputMaybe<Scalars["ID"]>;
  list_role_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  role__lateral?: InputMaybe<Scalars["Boolean"]>;
  role__some?: InputMaybe<RoleWhereInput>;
  role_self_relations_element_role__some?: InputMaybe<RoleWhereInput>;
  role_self_relations_list_role__some?: InputMaybe<RoleWhereInput>;
  type_function?: InputMaybe<Scalars["String"]>;
  type_function__contains?: InputMaybe<Scalars["String"]>;
  type_function__ends_with?: InputMaybe<Scalars["String"]>;
  type_function__in?: InputMaybe<Array<Scalars["String"]>>;
  type_function__not?: InputMaybe<Scalars["String"]>;
  type_function__not_contains?: InputMaybe<Scalars["String"]>;
  type_function__not_ends_with?: InputMaybe<Scalars["String"]>;
  type_function__not_in?: InputMaybe<Array<Scalars["String"]>>;
  type_function__not_starts_with?: InputMaybe<Scalars["String"]>;
  type_function__starts_with?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
  valid_check__contains?: InputMaybe<ValidType>;
  valid_check__in?: InputMaybe<Array<ValidType>>;
  valid_check__not?: InputMaybe<ValidType>;
  valid_check__not_contains?: InputMaybe<ValidType>;
  valid_check__not_in?: InputMaybe<Array<ValidType>>;
}

/**
 * {
 *   "name":"role_title",
 *   "id": "id",
 *   "role": { "fk" : "role_id" , "pk" : "role_id" },
 *   "language": { "fk" : "language_code" , "pk" : "language_code" }
 * }
 */
export class Role_Title {
  exposure_order: Scalars["Int"];
  id: Scalars["UUID"];
  language?: Maybe<Array<Maybe<Language>>>;
  language_code?: Maybe<Scalars["ID"]>;
  role: Array<Maybe<Role>>;
  role_id?: Maybe<Scalars["ID"]>;
  tsvec_words?: Maybe<Scalars["String"]>;
  type_role_title?: Maybe<Scalars["String"]>;
  valid_check?: Maybe<ValidType>;
  value: Scalars["String"];
}

/**
 * {
 *   "name":"role_title",
 *   "id": "id",
 *   "role": { "fk" : "role_id" , "pk" : "role_id" },
 *   "language": { "fk" : "language_code" , "pk" : "language_code" }
 * }
 */
export class Role_TitleLanguageArgs {
  where?: InputMaybe<LanguageWhereInput>;
}

export class Role_TitleCreateInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  language: LanguageChildInput;
  role: RoleChildInput;
  type_role_title?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
  value: Scalars["String"];
}

export class Role_TitleCreateManyWithoutRoleInput {
  create?: InputMaybe<Array<Role_TitleCreateWithoutRoleInput>>;
}

export class Role_TitleCreateWithoutRoleInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  language: LanguageChildInput;
  type_role_title?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
  value: Scalars["String"];
}

export enum Role_TitleOrderByInput {
  CreatedAtAsc = "createdAt__ASC",
  CreatedAtDesc = "createdAt__DESC",
  ExposureOrderAsc = "exposure_order__ASC",
  ExposureOrderDesc = "exposure_order__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  TypeRoleTitleAsc = "type_role_title__ASC",
  TypeRoleTitleDesc = "type_role_title__DESC",
  UpdatedAtAsc = "updatedAt__ASC",
  UpdatedAtDesc = "updatedAt__DESC",
  ValidCheckAsc = "valid_check__ASC",
  ValidCheckDesc = "valid_check__DESC",
  ValueAsc = "value__ASC",
  ValueDesc = "value__DESC",
  ValueSimilarity = "value__SIMILARITY"
}

export class Role_TitleUpdateInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  language?: InputMaybe<LanguageChildInput>;
  role?: InputMaybe<RoleChildInput>;
  type_role_title?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
  value?: InputMaybe<Scalars["String"]>;
}

export class Role_TitleUpdateManyWithoutRoleInput {
  create?: InputMaybe<Array<Role_TitleCreateWithoutRoleInput>>;
  update?: InputMaybe<Array<Role_TitleUpdateWithWhereUniqueWithoutRoleInput>>;
}

export class Role_TitleUpdateWithWhereUniqueWithoutRoleInput {
  data: Role_TitleUpdateWithoutRoleDataInput;
  where: UuidWhereUniqueInput;
}

export class Role_TitleUpdateWithoutRoleDataInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  language?: InputMaybe<LanguageChildInput>;
  type_role_title?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
  value?: InputMaybe<Scalars["String"]>;
}

export class Role_TitleWhereInput {
  AND?: InputMaybe<Array<Role_TitleWhereInput>>;
  NOT?: InputMaybe<Array<Role_TitleWhereInput>>;
  OR?: InputMaybe<Array<Role_TitleWhereInput>>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  exposure_order__gt?: InputMaybe<Scalars["Int"]>;
  exposure_order__gte?: InputMaybe<Scalars["Int"]>;
  exposure_order__in?: InputMaybe<Array<Scalars["Int"]>>;
  exposure_order__lt?: InputMaybe<Scalars["Int"]>;
  exposure_order__lte?: InputMaybe<Scalars["Int"]>;
  exposure_order__not?: InputMaybe<Scalars["Int"]>;
  exposure_order__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  language__lateral?: InputMaybe<Scalars["Boolean"]>;
  language__some?: InputMaybe<LanguageWhereInput>;
  language_code?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  language_code__in?: InputMaybe<Array<Scalars["ID"]>>;
  language_code__not_include?: InputMaybe<Array<Scalars["ID"]>>;
  role__lateral?: InputMaybe<Scalars["Boolean"]>;
  role__some?: InputMaybe<RoleWhereInput>;
  role_id?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  role_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  role_id__not_include?: InputMaybe<Array<Scalars["ID"]>>;
  tsvec_words__search?: InputMaybe<Scalars["String"]>;
  type_role_title?: InputMaybe<Scalars["String"]>;
  type_role_title__contains?: InputMaybe<Scalars["String"]>;
  type_role_title__ends_with?: InputMaybe<Scalars["String"]>;
  type_role_title__in?: InputMaybe<Array<Scalars["String"]>>;
  type_role_title__not?: InputMaybe<Scalars["String"]>;
  type_role_title__not_contains?: InputMaybe<Scalars["String"]>;
  type_role_title__not_ends_with?: InputMaybe<Scalars["String"]>;
  type_role_title__not_in?: InputMaybe<Array<Scalars["String"]>>;
  type_role_title__not_starts_with?: InputMaybe<Scalars["String"]>;
  type_role_title__starts_with?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
  valid_check__contains?: InputMaybe<ValidType>;
  valid_check__in?: InputMaybe<Array<ValidType>>;
  valid_check__not?: InputMaybe<ValidType>;
  valid_check__not_contains?: InputMaybe<ValidType>;
  valid_check__not_in?: InputMaybe<Array<ValidType>>;
  value?: InputMaybe<Scalars["String"]>;
  value__contains?: InputMaybe<Scalars["String"]>;
  value__ends_with?: InputMaybe<Scalars["String"]>;
  value__in?: InputMaybe<Array<Scalars["String"]>>;
  value__not?: InputMaybe<Scalars["String"]>;
  value__not_contains?: InputMaybe<Scalars["String"]>;
  value__not_ends_with?: InputMaybe<Scalars["String"]>;
  value__not_in?: InputMaybe<Array<Scalars["String"]>>;
  value__not_starts_with?: InputMaybe<Scalars["String"]>;
  value__starts_with?: InputMaybe<Scalars["String"]>;
}

/**
 * {
 *   "name":"role_url",
 *   "id": "id",
 *   "role": { "fk" : "role_id" , "pk" : "role_id" }
 * }
 */
export class Role_Url {
  exposure_order: Scalars["Int"];
  id: Scalars["UUID"];
  role: Array<Maybe<Role>>;
  role_id?: Maybe<Scalars["ID"]>;
  type_url: Scalars["String"];
  url: Scalars["String"];
  valid_check?: Maybe<ValidType>;
}

export class Role_UrlCreateInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  type_url: Scalars["String"];
  url: Scalars["String"];
  valid_check?: InputMaybe<ValidType>;
}

export class Role_UrlCreateManyInput {
  create?: InputMaybe<Array<Role_UrlCreateInput>>;
}

export enum Role_UrlOrderByInput {
  ExposureOrderAsc = "exposure_order__ASC",
  ExposureOrderDesc = "exposure_order__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  TypeUrlAsc = "type_url__ASC",
  TypeUrlDesc = "type_url__DESC",
  UrlAsc = "url__ASC",
  UrlDesc = "url__DESC",
  ValidCheckAsc = "valid_check__ASC",
  ValidCheckDesc = "valid_check__DESC"
}

export class Role_UrlUpdateDataInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  type_url?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class Role_UrlUpdateInput {
  exposure_order?: InputMaybe<Scalars["Int"]>;
  role?: InputMaybe<RoleChildInput>;
  type_url?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class Role_UrlUpdateManyInput {
  create?: InputMaybe<Array<Role_UrlCreateInput>>;
  update?: InputMaybe<Array<Role_UrlUpdateWithWhereUniqueInput>>;
}

export class Role_UrlUpdateWithWhereUniqueInput {
  data: Role_UrlUpdateDataInput;
  where: UuidWhereUniqueInput;
}

export class Role_UrlWhereInput {
  AND?: InputMaybe<Array<Role_UrlWhereInput>>;
  NOT?: InputMaybe<Array<Role_UrlWhereInput>>;
  OR?: InputMaybe<Array<Role_UrlWhereInput>>;
  exposure_order?: InputMaybe<Scalars["Int"]>;
  exposure_order__gt?: InputMaybe<Scalars["Int"]>;
  exposure_order__gte?: InputMaybe<Scalars["Int"]>;
  exposure_order__in?: InputMaybe<Array<Scalars["Int"]>>;
  exposure_order__lt?: InputMaybe<Scalars["Int"]>;
  exposure_order__lte?: InputMaybe<Scalars["Int"]>;
  exposure_order__not?: InputMaybe<Scalars["Int"]>;
  exposure_order__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  role__lateral?: InputMaybe<Scalars["Boolean"]>;
  role__some?: InputMaybe<RoleWhereInput>;
  role_id?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  role_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  role_id__not_include?: InputMaybe<Array<Scalars["ID"]>>;
  type_url?: InputMaybe<Scalars["String"]>;
  type_url__contains?: InputMaybe<Scalars["String"]>;
  type_url__ends_with?: InputMaybe<Scalars["String"]>;
  type_url__in?: InputMaybe<Array<Scalars["String"]>>;
  type_url__not?: InputMaybe<Scalars["String"]>;
  type_url__not_contains?: InputMaybe<Scalars["String"]>;
  type_url__not_ends_with?: InputMaybe<Scalars["String"]>;
  type_url__not_in?: InputMaybe<Array<Scalars["String"]>>;
  type_url__not_starts_with?: InputMaybe<Scalars["String"]>;
  type_url__starts_with?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
  url__contains?: InputMaybe<Scalars["String"]>;
  url__ends_with?: InputMaybe<Scalars["String"]>;
  url__in?: InputMaybe<Array<Scalars["String"]>>;
  url__not?: InputMaybe<Scalars["String"]>;
  url__not_contains?: InputMaybe<Scalars["String"]>;
  url__not_ends_with?: InputMaybe<Scalars["String"]>;
  url__not_in?: InputMaybe<Array<Scalars["String"]>>;
  url__not_starts_with?: InputMaybe<Scalars["String"]>;
  url__starts_with?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
  valid_check__contains?: InputMaybe<ValidType>;
  valid_check__in?: InputMaybe<Array<ValidType>>;
  valid_check__not?: InputMaybe<ValidType>;
  valid_check__not_contains?: InputMaybe<ValidType>;
  valid_check__not_in?: InputMaybe<Array<ValidType>>;
}

export enum RowRecordOrderByInput {
  ChangedOnAsc = "changed_on__ASC",
  ChangedOnDesc = "changed_on__DESC",
  OperateAsc = "operate__ASC",
  OperateDesc = "operate__DESC",
  TimestampAsc = "timestamp__ASC",
  TimestampDesc = "timestamp__DESC"
}

export class RowRecordWhereInput {
  changed_on?: InputMaybe<Scalars["DateTime"]>;
  operate?: InputMaybe<Scalars["String"]>;
  target_id?: InputMaybe<Scalars["String"]>;
  target_table?: InputMaybe<Scalars["String"]>;
  timestamp?: InputMaybe<Scalars["DateTime"]>;
  timestamp__gt?: InputMaybe<Scalars["DateTime"]>;
  timestamp__gte?: InputMaybe<Scalars["DateTime"]>;
  timestamp__lt?: InputMaybe<Scalars["DateTime"]>;
  timestamp__lte?: InputMaybe<Scalars["DateTime"]>;
  uid?: InputMaybe<Scalars["String"]>;
}

/**
 * {
 *   "name":"row_record",
 *   "id": "id"
 * }
 */
export class Row_Record {
  changed_on?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["ID"]>;
  operate?: Maybe<Scalars["String"]>;
  target_id?: Maybe<Scalars["String"]>;
  target_table?: Maybe<Scalars["String"]>;
  timestamp?: Maybe<Scalars["TimeStamp"]>;
  uid?: Maybe<Scalars["String"]>;
}

export enum ServiceType {
  Artistscard = "ARTISTSCARD",
  Cm = "CM",
  Coverlala = "COVERLALA",
  External = "EXTERNAL",
  M4S = "M4S"
}

export class SetAccessWhereInput {
  allowTable?: InputMaybe<Array<InputMaybe<AllowTableModel>>>;
  email: Scalars["ID"];
  owner?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  tier: TierType;
}

export class SignInPayload {
  email: Scalars["String"];
  password: Scalars["String"];
}

export class SignUpPayload {
  email: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  password: Scalars["String"];
}

export enum StatusOfCommodity {
  Arrive = "Arrive",
  BeforeShipping = "BeforeShipping",
  Confirm = "Confirm",
  Event = "Event",
  InStore = "InStore",
  Prepare = "Prepare",
  Preparing = "Preparing",
  Ready = "Ready",
  Shipping = "Shipping"
}

export enum Target {
  Artist = "Artist",
  Company = "Company",
  Genre = "Genre",
  Metadata = "Metadata",
  Role = "Role"
}

export class TaskList {
  id?: Maybe<Array<Scalars["String"]>>;
  total: Scalars["Int"];
}

export class TaskListWhereInput {
  email?: InputMaybe<Scalars["ID"]>;
  email__in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  email__starts_with?: InputMaybe<Scalars["ID"]>;
}

export enum TierType {
  /** 고객 */
  C = "C",
  /** Master */
  M = "M",
  /** service */
  N = "N",
  /** Onwer */
  O = "O",
  /** 파트너 */
  P = "P",
  /** 비활성화계정 */
  Remove = "REMOVE",
  /** 알바 */
  S = "S"
}

export class Tokens {
  access_token: Scalars["String"];
  expiredDate: Scalars["String"];
  payload_token?: Maybe<Scalars["String"]>;
  refresh_user_token: Scalars["String"];
  user_token: Scalars["String"];
}

/**
 * {
 *   "name":"tonality",
 *   "id": "tonality_id",
 *   "metadata": { "fk" : "metadata_id" , "pk" : "metadata_id" }
 * }
 */
export class Tonality {
  metadata?: Maybe<Array<Metadata>>;
  name: Scalars["String"];
  tonality_id: Scalars["ID"];
  valid_check?: Maybe<ValidType>;
}

/**
 * {
 *   "name":"tonality",
 *   "id": "tonality_id",
 *   "metadata": { "fk" : "metadata_id" , "pk" : "metadata_id" }
 * }
 */
export class TonalityMetadataArgs {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<MetadataOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<MetadataWhereInput>;
}

export class TonalityCreateInput {
  metadata?: InputMaybe<MetadataChildInput>;
  name?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class TonalityCreateOneWithoutMetadataInput {
  connect?: InputMaybe<TonalityWhereUniqueInput>;
  create?: InputMaybe<TonalityCreateWithoutMetadataInput>;
}

export class TonalityCreateWithoutMetadataInput {
  name?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
}

export enum TonalityOrderByInput {
  CreatedAtAsc = "createdAt__ASC",
  CreatedAtDesc = "createdAt__DESC",
  NameAsc = "name__ASC",
  NameDesc = "name__DESC",
  TonalityIdAsc = "tonality_id__ASC",
  TonalityIdDesc = "tonality_id__DESC",
  UpdatedAtAsc = "updatedAt__ASC",
  UpdatedAtDesc = "updatedAt__DESC",
  ValidCheckAsc = "valid_check__ASC",
  ValidCheckDesc = "valid_check__DESC"
}

export class TonalityUpdateInput {
  metadata?: InputMaybe<MetadataChildInput>;
  name?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class TonalityUpdateOneWithoutMetadataInput {
  connect?: InputMaybe<TonalityWhereUniqueInput>;
  create?: InputMaybe<TonalityCreateWithoutMetadataInput>;
  update?: InputMaybe<TonalityUpdateWithoutMetadataDataInput>;
}

export class TonalityUpdateWithoutMetadataDataInput {
  name?: InputMaybe<Scalars["String"]>;
  valid_check?: InputMaybe<ValidType>;
}

export class TonalityWhereInput {
  AND?: InputMaybe<Array<TonalityWhereInput>>;
  NOT?: InputMaybe<Array<TonalityWhereInput>>;
  OR?: InputMaybe<Array<TonalityWhereInput>>;
  name?: InputMaybe<Scalars["String"]>;
  name__contains?: InputMaybe<Scalars["String"]>;
  name__ends_with?: InputMaybe<Scalars["String"]>;
  name__in?: InputMaybe<Array<Scalars["String"]>>;
  name__not?: InputMaybe<Scalars["String"]>;
  name__not_contains?: InputMaybe<Scalars["String"]>;
  name__not_ends_with?: InputMaybe<Scalars["String"]>;
  name__not_in?: InputMaybe<Array<Scalars["String"]>>;
  name__not_starts_with?: InputMaybe<Scalars["String"]>;
  name__starts_with?: InputMaybe<Scalars["String"]>;
  tonality_id?: InputMaybe<Scalars["ID"]>;
  tonality_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  valid_check?: InputMaybe<ValidType>;
  valid_check__contains?: InputMaybe<ValidType>;
  valid_check__in?: InputMaybe<Array<ValidType>>;
  valid_check__not?: InputMaybe<ValidType>;
  valid_check__not_contains?: InputMaybe<ValidType>;
  valid_check__not_in?: InputMaybe<Array<ValidType>>;
}

export class TonalityWhereUniqueInput {
  tonality_id?: InputMaybe<Scalars["ID"]>;
}

export class Track {
  artist_id?: Maybe<Scalars["Int"]>;
  artist_name?: Maybe<Scalars["String"]>;
  bpm?: Maybe<Scalars["Int"]>;
  cart_item?: Maybe<Array<Maybe<CartItem>>>;
  company_id?: Maybe<Scalars["String"]>;
  created_at: Scalars["DateTime"];
  duration?: Maybe<Scalars["Int"]>;
  duration_temporary?: Maybe<Scalars["Int"]>;
  file_rule: FileRule;
  file_rule_id: Scalars["Int"];
  genre_id: Array<Scalars["ID"]>;
  grade?: Maybe<Array<Grade>>;
  grade_id: Scalars["Int"];
  id: Scalars["Int"];
  license?: Maybe<Array<Maybe<License>>>;
  purchase_item?: Maybe<Array<Maybe<PurchaseItem>>>;
  role_id: Array<Scalars["ID"]>;
  title: Scalars["String"];
  track_activity?: Maybe<Array<Maybe<TrackActivity>>>;
  type_metadata_subclass: TypeMetadataSubclass;
  updated_at: Scalars["DateTime"];
}

export class TrackCart_ItemArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<CartItemOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CartItemWhereInput>;
}

export class TrackLicenseArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<LicenseOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<LicenseWhereInput>;
}

export class TrackPurchase_ItemArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<PurchaseItemOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<PurchaseItemWhereInput>;
}

export class TrackTrack_ActivityArgs {
  after?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<InputMaybe<TrackActivityOrderByInput>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TrackActivityWhereInput>;
}

export class TrackActivity {
  created_at?: Maybe<Scalars["DateTime"]>;
  id: Scalars["Int"];
  track: Track;
  track_id: Scalars["Int"];
  type: TrackActivityType;
  updated_at: Scalars["DateTime"];
  user: User;
  user_id: Scalars["Int"];
}

export enum TrackActivityOrderByInput {
  CreatedAtAsc = "created_at__ASC",
  CreatedAtDesc = "created_at__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  TrackIdAsc = "track_id__ASC",
  TrackIdDesc = "track_id__DESC"
}

export enum TrackActivityType {
  Cart = "CART",
  Like = "LIKE",
  Purchase = "PURCHASE"
}

export class TrackActivityWhereInput {
  id?: InputMaybe<Scalars["Int"]>;
  id__gt?: InputMaybe<Scalars["Int"]>;
  id__gte?: InputMaybe<Scalars["Int"]>;
  id__in?: InputMaybe<Array<Scalars["Int"]>>;
  id__lt?: InputMaybe<Scalars["Int"]>;
  id__lte?: InputMaybe<Scalars["Int"]>;
  track_id?: InputMaybe<Scalars["Int"]>;
  track_id__in?: InputMaybe<Array<Scalars["Int"]>>;
  type?: InputMaybe<TrackActivityType>;
  type__in?: InputMaybe<Array<TrackActivityType>>;
  user_id?: InputMaybe<Scalars["Int"]>;
}

export enum TrackGrade {
  A = "A",
  B = "B",
  C = "C",
  Other = "OTHER",
  Sfx = "SFX"
}

export enum TrackOrderByInput {
  ArtistIdAsc = "artist_id__ASC",
  ArtistIdDesc = "artist_id__DESC",
  CreatedAtAsc = "created_at__ASC",
  CreatedAtDesc = "created_at__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC"
}

export class TrackWhereInput {
  AND?: InputMaybe<Array<TrackWhereInput>>;
  NOT?: InputMaybe<Array<TrackWhereInput>>;
  OR?: InputMaybe<Array<TrackWhereInput>>;
  artist_id?: InputMaybe<Scalars["Int"]>;
  artist_id__gt?: InputMaybe<Scalars["Int"]>;
  artist_id__gte?: InputMaybe<Scalars["Int"]>;
  artist_id__in?: InputMaybe<Array<Scalars["Int"]>>;
  artist_id__lt?: InputMaybe<Scalars["Int"]>;
  artist_id__lte?: InputMaybe<Scalars["Int"]>;
  artist_id__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  company_id?: InputMaybe<Scalars["String"]>;
  company_id__in?: InputMaybe<Array<Scalars["String"]>>;
  company_id__not?: InputMaybe<Scalars["String"]>;
  company_id__not_in?: InputMaybe<Array<Scalars["String"]>>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  created_at__gte?: InputMaybe<Scalars["DateTime"]>;
  duration_temporary__exist?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["Int"]>;
  id__gt?: InputMaybe<Scalars["Int"]>;
  id__gte?: InputMaybe<Scalars["Int"]>;
  id__in?: InputMaybe<Array<Scalars["Int"]>>;
  id__lt?: InputMaybe<Scalars["Int"]>;
  id__lte?: InputMaybe<Scalars["Int"]>;
  id__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  title?: InputMaybe<Scalars["String"]>;
  title__contains?: InputMaybe<Scalars["String"]>;
  title__ends_with?: InputMaybe<Scalars["String"]>;
  title__in?: InputMaybe<Array<Scalars["String"]>>;
  title__not_contains?: InputMaybe<Scalars["String"]>;
  title__not_ends_with?: InputMaybe<Scalars["String"]>;
  title__not_in?: InputMaybe<Array<Scalars["String"]>>;
  title__not_starts_with?: InputMaybe<Scalars["String"]>;
  title__starts_with?: InputMaybe<Scalars["String"]>;
}

/**
 * {
 *   "name":"track_information",
 *   "id": "id",
 *   "metadata": { "fk" : "metadata_id" , "pk" : "metadata_id" }
 * }
 */
export class Track_Information {
  channel?: Maybe<Scalars["Int"]>;
  duration?: Maybe<Scalars["Int"]>;
  extra_data?: Maybe<Scalars["String"]>;
  grade?: Maybe<GradeType>;
  id: Scalars["UUID"];
  metadata?: Maybe<Array<Maybe<Metadata>>>;
  metadata_id: Scalars["ID"];
  sample_bitrate?: Maybe<Scalars["Int"]>;
  tempo?: Maybe<Scalars["Float"]>;
  time_signature?: Maybe<Scalars["String"]>;
  tonality?: Maybe<Scalars["String"]>;
  voice_gender?: Maybe<GenderType>;
}

export class Track_InformationCreateManylistInput {
  create?: InputMaybe<Array<Track_InformationCreatelistInput>>;
}

export class Track_InformationCreatelistInput {
  channel?: InputMaybe<Scalars["Int"]>;
  duration?: InputMaybe<Scalars["Int"]>;
  extra_data?: InputMaybe<Scalars["String"]>;
  grade?: InputMaybe<GradeType>;
  sample_bitrate?: InputMaybe<Scalars["Int"]>;
  tempo?: InputMaybe<Scalars["Float"]>;
  time_signature?: InputMaybe<Scalars["String"]>;
  tonality?: InputMaybe<Scalars["String"]>;
  voice_gender?: InputMaybe<GenderType>;
}

export enum Track_InformationOrderByInput {
  ChannelAsc = "channel__ASC",
  ChannelDesc = "channel__DESC",
  DurationAsc = "duration__ASC",
  DurationDesc = "duration__DESC",
  GradeAsc = "grade__ASC",
  GradeDesc = "grade__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  MetadataIdAsc = "metadata_id__ASC",
  MetadataIdDesc = "metadata_id__DESC",
  SampleBitrateAsc = "sample_bitrate__ASC",
  SampleBitrateDesc = "sample_bitrate__DESC"
}

export class Track_InformationUpdateInput {
  channel?: InputMaybe<Scalars["Int"]>;
  duration?: InputMaybe<Scalars["Int"]>;
  extra_data?: InputMaybe<Scalars["String"]>;
  grade?: InputMaybe<GradeType>;
  sample_bitrate?: InputMaybe<Scalars["Int"]>;
  tempo?: InputMaybe<Scalars["Float"]>;
  time_signature?: InputMaybe<Scalars["String"]>;
  tonality?: InputMaybe<Scalars["String"]>;
  voice_gender?: InputMaybe<GenderType>;
}

export class Track_InformationUpdateManylistInput {
  create?: InputMaybe<Array<Track_InformationCreatelistInput>>;
  update?: InputMaybe<Array<Track_InformationUpdateWithWhereUniquelistInput>>;
}

export class Track_InformationUpdateWithWhereUniquelistInput {
  data: Track_InformationUpdatelistDataInput;
  where: UuidWhereUniqueInput;
}

export class Track_InformationUpdatelistDataInput {
  channel?: InputMaybe<Scalars["Int"]>;
  duration?: InputMaybe<Scalars["Int"]>;
  extra_data?: InputMaybe<Scalars["String"]>;
  grade?: InputMaybe<GradeType>;
  sample_bitrate?: InputMaybe<Scalars["Int"]>;
  tempo?: InputMaybe<Scalars["Float"]>;
  time_signature?: InputMaybe<Scalars["String"]>;
  tonality?: InputMaybe<Scalars["String"]>;
  voice_gender?: InputMaybe<GenderType>;
}

export class Track_InformationWhereInput {
  AND?: InputMaybe<Array<Track_InformationWhereInput>>;
  NOT?: InputMaybe<Array<Track_InformationWhereInput>>;
  OR?: InputMaybe<Array<Track_InformationWhereInput>>;
  channel?: InputMaybe<Scalars["Int"]>;
  channel__gt?: InputMaybe<Scalars["Int"]>;
  channel__gte?: InputMaybe<Scalars["Int"]>;
  channel__in?: InputMaybe<Array<Scalars["Int"]>>;
  channel__lt?: InputMaybe<Scalars["Int"]>;
  channel__lte?: InputMaybe<Scalars["Int"]>;
  channel__not?: InputMaybe<Scalars["Int"]>;
  channel__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  duration?: InputMaybe<Scalars["Int"]>;
  duration__between?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  duration__gt?: InputMaybe<Scalars["Int"]>;
  duration__gte?: InputMaybe<Scalars["Int"]>;
  duration__in?: InputMaybe<Array<Scalars["Int"]>>;
  duration__lt?: InputMaybe<Scalars["Int"]>;
  duration__lte?: InputMaybe<Scalars["Int"]>;
  duration__not?: InputMaybe<Scalars["Int"]>;
  duration__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  extra_data?: InputMaybe<Scalars["String"]>;
  extra_data__in?: InputMaybe<Array<Scalars["String"]>>;
  extra_data__not?: InputMaybe<Scalars["String"]>;
  extra_data__not_in?: InputMaybe<Array<Scalars["String"]>>;
  grade?: InputMaybe<GradeType>;
  grade__exist?: InputMaybe<Scalars["Boolean"]>;
  grade__in?: InputMaybe<Array<GradeType>>;
  grade__not?: InputMaybe<GradeType>;
  grade__not_in?: InputMaybe<Array<GradeType>>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  metadata__lateral?: InputMaybe<Scalars["Boolean"]>;
  metadata__some?: InputMaybe<MetadataWhereInput>;
  metadata_id?: InputMaybe<Scalars["ID"]>;
  metadata_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  metadata_id__not_include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  sample_bitrate?: InputMaybe<Scalars["Int"]>;
  sample_bitrate__gt?: InputMaybe<Scalars["Int"]>;
  sample_bitrate__gte?: InputMaybe<Scalars["Int"]>;
  sample_bitrate__in?: InputMaybe<Array<Scalars["Int"]>>;
  sample_bitrate__lt?: InputMaybe<Scalars["Int"]>;
  sample_bitrate__lte?: InputMaybe<Scalars["Int"]>;
  sample_bitrate__not?: InputMaybe<Scalars["Int"]>;
  sample_bitrate__not_in?: InputMaybe<Array<Scalars["Int"]>>;
  tempo?: InputMaybe<Scalars["Float"]>;
  tempo__between?: InputMaybe<Array<InputMaybe<Scalars["Float"]>>>;
  tempo__gt?: InputMaybe<Scalars["Float"]>;
  tempo__gte?: InputMaybe<Scalars["Float"]>;
  tempo__in?: InputMaybe<Array<Scalars["Float"]>>;
  tempo__lt?: InputMaybe<Scalars["Float"]>;
  tempo__lte?: InputMaybe<Scalars["Float"]>;
  tempo__not?: InputMaybe<Scalars["Float"]>;
  tempo__not_in?: InputMaybe<Array<Scalars["Float"]>>;
  time_signature?: InputMaybe<Scalars["String"]>;
  time_signature__in?: InputMaybe<Array<Scalars["String"]>>;
  time_signature__not?: InputMaybe<Scalars["String"]>;
  time_signature__not_in?: InputMaybe<Array<Scalars["String"]>>;
  tonality?: InputMaybe<Scalars["String"]>;
  tonality__in?: InputMaybe<Array<Scalars["String"]>>;
  tonality__not?: InputMaybe<Scalars["String"]>;
  tonality__not_in?: InputMaybe<Array<Scalars["String"]>>;
  voice_gender?: InputMaybe<GenderType>;
  voice_gender__in?: InputMaybe<Array<GenderType>>;
  voice_gender__not?: InputMaybe<GenderType>;
  voice_gender__not_in?: InputMaybe<Array<GenderType>>;
}

/**
 * {
 *   "name":"track_license",
 *   "id": "id",
 *   "metadata": { "fk" : "metadata_id" , "pk" : "metadata_id" }
 * }
 */
export class Track_License {
  copyright?: Maybe<Scalars["Boolean"]>;
  country_code: Scalars["String"];
  created_at?: Maybe<Scalars["DateTime"]>;
  id: Scalars["UUID"];
  is_service?: Maybe<Scalars["Int"]>;
  license_date?: Maybe<Scalars["DateTime"]>;
  metadata: Array<Maybe<Metadata>>;
  metadata_id?: Maybe<Scalars["ID"]>;
  neighboring?: Maybe<Scalars["Boolean"]>;
  notice?: Maybe<Scalars["String"]>;
  publish_date?: Maybe<Scalars["DateTime"]>;
  type_track: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTime"]>;
  v1_id?: Maybe<Scalars["Int"]>;
  valid_check?: Maybe<ValidType>;
  weight: Scalars["Int"];
}

export class Track_LicenseCreateManyWithoutMetadataInput {
  create?: InputMaybe<Array<Track_LicenseCreateWithoutMetadataInput>>;
}

export class Track_LicenseCreateWithoutMetadataInput {
  copyright?: InputMaybe<Scalars["Boolean"]>;
  country_code: Scalars["String"];
  create_At?: InputMaybe<Scalars["DateTime"]>;
  is_service?: InputMaybe<Scalars["Int"]>;
  license_date?: InputMaybe<Scalars["DateTime"]>;
  neighboring?: InputMaybe<Scalars["Boolean"]>;
  notice?: InputMaybe<Scalars["String"]>;
  publish_date?: InputMaybe<Scalars["DateTime"]>;
  type_track: Scalars["String"];
  update_At?: InputMaybe<Scalars["DateTime"]>;
  v1_id?: InputMaybe<Scalars["Int"]>;
  valid_check?: InputMaybe<ValidType>;
  weight?: InputMaybe<Scalars["Int"]>;
}

export enum Track_LicenseOrderByInput {
  CreatedAtAsc = "createdAt__ASC",
  CreatedAtDesc = "createdAt__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  IsServiceAsc = "is_service__ASC",
  IsServiceDesc = "is_service__DESC",
  LicenseDateAsc = "license_date__ASC",
  LicenseDateDesc = "license_date__DESC",
  MetadataIdAsc = "metadata_id__ASC",
  MetadataIdDesc = "metadata_id__DESC",
  UpdatedAtAsc = "updatedAt__ASC",
  UpdatedAtDesc = "updatedAt__DESC",
  ValidCheckAsc = "valid_check__ASC",
  ValidCheckDesc = "valid_check__DESC",
  WeightAsc = "weight__ASC",
  WeightDesc = "weight__DESC"
}

export class Track_LicenseUpdateInput {
  copyright?: InputMaybe<Scalars["Boolean"]>;
  country_code?: InputMaybe<Scalars["String"]>;
  create_At?: InputMaybe<Scalars["DateTime"]>;
  is_service?: InputMaybe<Scalars["Int"]>;
  license_date?: InputMaybe<Scalars["DateTime"]>;
  metadata?: InputMaybe<MetadataChildInput>;
  neighboring?: InputMaybe<Scalars["Boolean"]>;
  notice?: InputMaybe<Scalars["String"]>;
  publish_date?: InputMaybe<Scalars["DateTime"]>;
  type_track?: InputMaybe<Scalars["String"]>;
  update_At?: InputMaybe<Scalars["DateTime"]>;
  v1_id?: InputMaybe<Scalars["Int"]>;
  valid_check?: InputMaybe<ValidType>;
  weight?: InputMaybe<Scalars["Int"]>;
}

export class Track_LicenseUpdateManyWithoutMetadataInput {
  create?: InputMaybe<Array<Track_LicenseCreateWithoutMetadataInput>>;
  update?: InputMaybe<Array<Track_LicenseUpdatesInput>>;
}

export class Track_LicenseUpdatesInput {
  data: Track_LicenseUpdateInput;
  where: UuidWhereUniqueInput;
}

export class Track_LicenseWhereUniqueInput {
  copyright?: InputMaybe<Scalars["Boolean"]>;
  country_code?: InputMaybe<Scalars["String"]>;
  country_code__in?: InputMaybe<Array<Scalars["String"]>>;
  country_code__not?: InputMaybe<Scalars["String"]>;
  country_code__not_in?: InputMaybe<Array<Scalars["String"]>>;
  create_At?: InputMaybe<Scalars["DateTime"]>;
  create_At__gt?: InputMaybe<Scalars["DateTime"]>;
  create_At__gte?: InputMaybe<Scalars["DateTime"]>;
  create_At__lt?: InputMaybe<Scalars["DateTime"]>;
  create_At__lte?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  id__not?: InputMaybe<Scalars["UUID"]>;
  is_service?: InputMaybe<Scalars["Int"]>;
  is_service__gt?: InputMaybe<Scalars["Int"]>;
  is_service__gte?: InputMaybe<Scalars["Int"]>;
  is_service__in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  is_service__lt?: InputMaybe<Scalars["Int"]>;
  is_service__lte?: InputMaybe<Scalars["Int"]>;
  license_date?: InputMaybe<Scalars["DateTime"]>;
  license_date__gt?: InputMaybe<Scalars["DateTime"]>;
  license_date__gte?: InputMaybe<Scalars["DateTime"]>;
  license_date__lt?: InputMaybe<Scalars["DateTime"]>;
  license_date__lte?: InputMaybe<Scalars["DateTime"]>;
  metadata__lateral?: InputMaybe<Scalars["Boolean"]>;
  metadata__some?: InputMaybe<MetadataWhereInput>;
  neighboring?: InputMaybe<Scalars["Boolean"]>;
  type_track?: InputMaybe<Scalars["String"]>;
  type_track__in?: InputMaybe<Array<Scalars["String"]>>;
  type_track__not?: InputMaybe<Scalars["String"]>;
  type_track__not_in?: InputMaybe<Array<Scalars["String"]>>;
  update_At?: InputMaybe<Scalars["DateTime"]>;
  update_At__gt?: InputMaybe<Scalars["DateTime"]>;
  update_At__gte?: InputMaybe<Scalars["DateTime"]>;
  update_At__lt?: InputMaybe<Scalars["DateTime"]>;
  update_At__lte?: InputMaybe<Scalars["DateTime"]>;
  v1_id?: InputMaybe<Scalars["Int"]>;
  v1_id__in?: InputMaybe<Array<Scalars["Int"]>>;
  v1_id__not?: InputMaybe<Scalars["Int"]>;
  valid_check?: InputMaybe<ValidType>;
  valid_check__contains?: InputMaybe<ValidType>;
  valid_check__in?: InputMaybe<Array<ValidType>>;
  valid_check__not?: InputMaybe<ValidType>;
  valid_check__not_contains?: InputMaybe<ValidType>;
  valid_check__not_in?: InputMaybe<Array<ValidType>>;
  weight?: InputMaybe<Scalars["Int"]>;
}

/**
 * {
 *   "name":"track_license_extra",
 *   "id": "id",
 *   "metadata": { "fk" : "metadata_id" , "pk" : "metadata_id" }
 * }
 */
export class Track_License_Extra {
  data?: Maybe<Scalars["Json"]>;
  first_edition?: Maybe<Scalars["Int"]>;
  id: Scalars["UUID"];
  metadata: Array<Maybe<Metadata>>;
  metadata_id?: Maybe<Scalars["ID"]>;
  place?: Maybe<Scalars["String"]>;
  publish_year?: Maybe<Scalars["Int"]>;
  record_year?: Maybe<Scalars["Int"]>;
}

export class Track_License_ExtraCreateManyWithoutMetadataInput {
  create?: InputMaybe<Array<Track_License_ExtraCreateWithoutMetadataInput>>;
}

export class Track_License_ExtraCreateWithoutMetadataInput {
  data?: InputMaybe<Scalars["Json"]>;
  first_edition?: InputMaybe<Scalars["Int"]>;
  place?: InputMaybe<Scalars["String"]>;
  publish_year?: InputMaybe<Scalars["Int"]>;
  record_year?: InputMaybe<Scalars["Int"]>;
}

export enum Track_License_ExtraOrderByInput {
  FirstEditionAsc = "first_edition__ASC",
  FirstEditionDesc = "first_edition__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  PublishYearAsc = "publish_year__ASC",
  PublishYearDesc = "publish_year__DESC",
  RecordYearAsc = "record_year__ASC",
  RecordYearDesc = "record_year__DESC"
}

export class Track_License_ExtraUpdateInput {
  data?: InputMaybe<Scalars["Json"]>;
  first_edition?: InputMaybe<Scalars["Int"]>;
  metadata?: InputMaybe<MetadataChildInput>;
  place?: InputMaybe<Scalars["String"]>;
  publish_year?: InputMaybe<Scalars["Int"]>;
  record_year?: InputMaybe<Scalars["Int"]>;
}

export class Track_License_ExtraUpdateManyWithoutMetadataInput {
  create?: InputMaybe<Array<Track_License_ExtraCreateWithoutMetadataInput>>;
  update?: InputMaybe<Array<Track_License_ExtraUpdateWithWhereUniqueWithoutMetadataInput>>;
}

export class Track_License_ExtraUpdateWithWhereUniqueWithoutMetadataInput {
  data: Track_License_ExtraUpdateInput;
  where: UuidWhereUniqueInput;
}

export class Track_License_ExtraWhereUniqueInput {
  id?: InputMaybe<Scalars["UUID"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  id__not?: InputMaybe<Scalars["UUID"]>;
  metadata__lateral?: InputMaybe<Scalars["Boolean"]>;
  metadata__some?: InputMaybe<MetadataWhereInput>;
  publish_year?: InputMaybe<Scalars["Int"]>;
  publish_year__in?: InputMaybe<Array<Scalars["Int"]>>;
  publish_year__not?: InputMaybe<Scalars["Int"]>;
  record_year?: InputMaybe<Scalars["String"]>;
  record_year__in?: InputMaybe<Array<Scalars["String"]>>;
  record_year__not?: InputMaybe<Scalars["String"]>;
}

/**
 * {
 *   "name":"track_play_count",
 *   "id": "id",
 *   "metadata": { "fk" : "metadata_id" , "pk" : "metadata_id" }
 * }
 */
export class Track_Play_Count {
  id: Scalars["UUID"];
  metadata?: Maybe<Array<Maybe<Metadata>>>;
  metadata_id: Scalars["ID"];
  price: Scalars["Float"];
  price_id: Scalars["Int"];
  product_id: Scalars["Int"];
  service: ServiceType;
  time_stamp: Scalars["DateTime"];
  type_action: Scalars["String"];
  user_id: Scalars["String"];
}

export class Track_Play_CountCreate {
  metadata_id: Scalars["ID"];
  price: Scalars["Float"];
  price_id: Scalars["Int"];
  product_id: Scalars["Int"];
  service: ServiceType;
  time_stamp: Scalars["DateTime"];
  type_action: Scalars["String"];
  user_id: Scalars["String"];
}

export class Track_Play_CountCreateManyWithoutMetadataInput {
  create?: InputMaybe<Array<Track_Play_CountCreateWithoutMetadataInput>>;
}

export class Track_Play_CountCreateWithoutMetadataInput {
  price: Scalars["Float"];
  price_id: Scalars["Int"];
  product_id: Scalars["Int"];
  service: Scalars["String"];
  time_stamp: Scalars["DateTime"];
  type_action: Scalars["String"];
  user_id: Scalars["String"];
}

export enum Track_Play_CountOrderByInput {
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  MetadataIdAsc = "metadata_id__ASC",
  MetadataIdDesc = "metadata_id__DESC",
  ServiceAsc = "service__ASC",
  ServiceDesc = "service__DESC",
  TimeStampAsc = "time_stamp__ASC",
  TimeStampDesc = "time_stamp__DESC"
}

export class Track_Play_CountWhereInput {
  AND?: InputMaybe<Array<Track_Play_CountWhereInput>>;
  NOT?: InputMaybe<Array<Track_Play_CountWhereInput>>;
  OR?: InputMaybe<Array<Track_Play_CountWhereInput>>;
  id?: InputMaybe<Scalars["UUID"]>;
  id__exist?: InputMaybe<Scalars["Boolean"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
  metadata__lateral?: InputMaybe<Scalars["Boolean"]>;
  metadata__some?: InputMaybe<MetadataWhereInput>;
  metadata_id?: InputMaybe<Scalars["ID"]>;
  metadata_id__in?: InputMaybe<Array<Scalars["ID"]>>;
  metadata_id__not_include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  price?: InputMaybe<Scalars["Float"]>;
  price_id?: InputMaybe<Scalars["Int"]>;
  product_id?: InputMaybe<Scalars["Int"]>;
  service?: InputMaybe<ServiceType>;
  service__in?: InputMaybe<Array<InputMaybe<ServiceType>>>;
  service__not?: InputMaybe<ServiceType>;
  service__not_in?: InputMaybe<Array<InputMaybe<ServiceType>>>;
  time_stamp?: InputMaybe<Scalars["DateTime"]>;
  time_stamp__gt?: InputMaybe<Scalars["DateTime"]>;
  time_stamp__gte?: InputMaybe<Scalars["DateTime"]>;
  time_stamp__lt?: InputMaybe<Scalars["DateTime"]>;
  time_stamp__lte?: InputMaybe<Scalars["DateTime"]>;
  type_action?: InputMaybe<Scalars["String"]>;
  user_id?: InputMaybe<Scalars["String"]>;
}

export class Track_Play_Total_CountWhereUniqueInput {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  created_at__between?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
}

export class TracksPerCount {
  count: Scalars["Int"];
  metadata_id: Scalars["Int"];
  title: Scalars["String"];
}

export enum TransactionType {
  Deposit = "DEPOSIT",
  Withdrawal = "WITHDRAWAL"
}

export enum TypeMetadataSubclass {
  Bgm = "BGM",
  Effect = "EFFECT"
}

export enum TypeOfGoods {
  Album = "Album",
  Badge = "Badge",
  Bag = "Bag",
  Clothes = "Clothes",
  Doll = "Doll",
  Etc = "ETC",
  EarPhoneCase = "EarPhoneCase",
  Figure = "Figure",
  Flag = "Flag",
  Griptok = "Griptok",
  Hat = "Hat",
  KeyRing = "KeyRing",
  LightStick = "LightStick",
  Magazine = "Magazine",
  Mug = "Mug",
  Package = "Package",
  Pants = "Pants",
  PhoneCase = "PhoneCase",
  PhotoBook = "PhotoBook",
  PhotoCard = "PhotoCard",
  PostCard = "PostCard",
  Poster = "Poster",
  Pouch = "Pouch",
  Print = "Print",
  Rug = "Rug",
  Scarf = "Scarf",
  Shirt = "Shirt",
  Slogan = "Slogan",
  Sticker = "Sticker",
  Ticket = "Ticket",
  Tumbler = "Tumbler"
}

export class UuidWhereDeleteInput {
  id?: InputMaybe<Scalars["UUID"]>;
  id__in?: InputMaybe<Array<Scalars["UUID"]>>;
}

export class UuidWhereUniqueInput {
  id?: InputMaybe<Scalars["UUID"]>;
}

export class UpdateCartItemInput {
  product_price_id: Scalars["Int"];
}

export class UpdateInput {
  company_id?: InputMaybe<Scalars["ID"]>;
  filename: Scalars["String"];
  path?: InputMaybe<PathType>;
}

export class UpdatePlaylistInput {
  description?: InputMaybe<Scalars["String"]>;
  playlist_id: Scalars["Int"];
  title?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<PlaylistType>;
}

export class UpdatePlaylistItemInput {
  description?: InputMaybe<Scalars["String"]>;
  playlist_item_id: Scalars["Int"];
}

export class UpdatePriceWhere {
  product_price_id: Scalars["Int"];
}

export class User {
  cart?: Maybe<Cart>;
  cart_item?: Maybe<Array<Maybe<CartItem>>>;
  company?: Maybe<Scalars["String"]>;
  created_at: Scalars["DateTime"];
  curation_management?: Maybe<Array<Maybe<CurationManagement>>>;
  email: Scalars["String"];
  id: Scalars["Int"];
  is_verification: Scalars["Boolean"];
  name?: Maybe<Scalars["String"]>;
  playlist?: Maybe<Array<Maybe<Playlist>>>;
  purchase_item?: Maybe<Array<Maybe<PurchaseItem>>>;
  tier: UserTier;
  token?: Maybe<JwtTokenSet>;
  track_activity?: Maybe<Array<Maybe<TrackActivity>>>;
  updated_at: Scalars["DateTime"];
  user_wallet?: Maybe<UserWallet>;
}

export class UserCreateInput {
  channel?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  nickname?: InputMaybe<Scalars["String"]>;
  password: Scalars["String"];
}

export class UserDataWhereInput {
  channel?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["ID"]>;
}

export enum UserOrderByInput {
  CountAsc = "count__ASC",
  CountDesc = "count__DESC",
  CreatedAtAsc = "created_at__ASC",
  CreatedAtDesc = "created_at__DESC",
  IdAsc = "id__ASC",
  IdDesc = "id__DESC",
  TypeAsc = "type__ASC",
  TypeDesc = "type__DESC",
  UserIdAsc = "user_id__ASC",
  UserIdDesc = "user_id__DESC"
}

export class UserResetInput {
  password?: InputMaybe<Scalars["String"]>;
}

export enum UserTier {
  Admin = "ADMIN",
  Normal = "NORMAL",
  Staff = "STAFF"
}

export class UserUpdateInput {
  _onlySearch?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  nickname?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
}

export class UserWallet {
  balance: Scalars["Int"];
  created_at: Scalars["DateTime"];
  event_point: Scalars["Int"];
  id: Scalars["Int"];
  updated_at: Scalars["DateTime"];
  user: User;
  user_id: Scalars["Int"];
}

export class UserWhereInput {
  email?: InputMaybe<Scalars["String"]>;
  email__starts_with?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  id__in?: InputMaybe<Array<Scalars["Int"]>>;
  password?: InputMaybe<Scalars["String"]>;
}

/**
 * {
 *   "name":"users",
 *   "id": "users_id"
 * }
 */
export class Users {
  email: Scalars["ID"];
  flag?: Maybe<Scalars["Boolean"]>;
  jsonWebToken?: Maybe<Tokens>;
  name?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  users_id: Scalars["Int"];
}

export class UsersChildInput {
  connect?: InputMaybe<UsersWhereUniqueInput>;
}

export enum UsersOrderByInput {
  UsersIdAsc = "users_id_ASC",
  UsersIdDesc = "users_id_DESC"
}

export class UsersWhereInput {
  email?: InputMaybe<Scalars["ID"]>;
  users_id?: InputMaybe<Scalars["Int"]>;
}

export class UsersWhereUniqueInput {
  email?: InputMaybe<Scalars["ID"]>;
  users_id?: InputMaybe<Scalars["ID"]>;
}

/**
 * {
 *   "name":"users_billing",
 *   "id": "id"
 * }
 */
export class Users_Billing {
  billing_date: Scalars["TimeStamp"];
  currency: CurrencyType;
  id?: Maybe<Scalars["ID"]>;
  paid_users_count: Scalars["Int"];
  price_id?: Maybe<Scalars["Int"]>;
  product_id: Scalars["Int"];
  service: ServiceType;
  tax?: Maybe<Scalars["Int"]>;
  users_count: Scalars["Int"];
}

export class Users_BillingCreateInput {
  billing_date: Scalars["TimeStamp"];
  currency: CurrencyType;
  paid_users_count: Scalars["Int"];
  price_id?: InputMaybe<Scalars["Int"]>;
  product_id: Scalars["Int"];
  service: ServiceType;
  tax?: InputMaybe<Scalars["Int"]>;
  users_count: Scalars["Int"];
}

export class Users_BillingUpdateInput {
  remarks?: InputMaybe<Scalars["String"]>;
}

export class Users_BillingWhereInput {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  created_at__between?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  id?: InputMaybe<Scalars["ID"]>;
  users__some?: InputMaybe<UsersWhereInput>;
}

export enum ValidType {
  /** 데이터 불량으로 차단된 데이터 */
  Ban = "BAN",
  /** 검증된 데이터 */
  Done = "DONE",
  Etc = "ETC",
  /** 검증진행중인 데이터 */
  Modify = "MODIFY",
  /** 심사에 탈락된 데이터 */
  Remove = "REMOVE",
  /** 원본데이터 V1 - from 원천데이터 */
  V1 = "V1",
  /** 원본데이터 V2 - from sam */
  V2 = "V2",
  /** deafult */
  V3 = "V3",
  /** 원천소스가 출처가 아티스츠카드 아닌경우 */
  Vc = "VC"
}

export class YtvWhereInput {
  action?: InputMaybe<FileAction>;
  cover: Scalars["String"];
  file: Scalars["String"];
  groupPath: Scalars["String"];
  serial: Scalars["String"];
  url: Scalars["String"];
}

export enum BookType {
  Booking = "booking",
  Immediate = "immediate"
}

export class CreateUrlInput {
  url: Scalars["String"];
}

export enum FileType {
  File = "FILE",
  Folder = "FOLDER"
}

export enum GenderType {
  Female = "Female",
  Male = "Male"
}

export enum GradeType {
  A = "A",
  B = "B",
  C = "C",
  Sfx = "SFX"
}

export enum PathType {
  Clouds = "clouds",
  Faces = "faces",
  Images = "images",
  Imgs = "imgs",
  Test = "test"
}

export enum TargetTableInPut {
  Artist = "artist",
  Commodity = "commodity",
  Company = "company",
  Genre = "genre",
  Goods = "goods",
  GoodsSubdataUnique = "goods_subdata_unique",
  Language = "language",
  Metadata = "metadata",
  MetadataPlaylistRelation = "metadata_playlist_relation",
  Playlist = "playlist",
  Role = "role",
  Users = "users"
}
