import { BASIC_FIRST_COUNT } from "Constants/Count";
import { PlaylistOrderByInput, ProductType } from "GraphQL/Schema";
import { Lottie } from "Lib/lottie";
import { useIntersection } from "Lib/use-intersection-observer";
import { useRef, useState } from "react";
import styled from "styled-components";
import { useAsyncEffect } from "use-async-effekt";
import { usePurchaseItem } from "../hooks/usePurchaseItem";
import { usePurchaseHistorySelector } from "../Store";
import { Content } from "./Content";
import LoadingLottie from "Assets/Lottie/loading.json";

export const PurchaseHistoryItems = () => {
  const { fetchMore } = usePurchaseItem();

  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const [observer] = useIntersection(ref);
  const { edge } = usePurchaseHistorySelector(store => store);
  const { isLoadedPurchaseItem } = usePurchaseHistorySelector(store => store);

  useAsyncEffect(async () => {
    if (!edge || isLoadingMore) return;
    const { hasNextPage, endCursor } = edge;

    if (observer && hasNextPage) {
      try {
        setIsLoadingMore(true);
        await fetchMore({
          variables: {
            first: BASIC_FIRST_COUNT,
            where: { id__lt: +endCursor!, product_type: ProductType.Track },
            orderBy: [PlaylistOrderByInput.IdDesc]
          }
        });
        setIsLoadingMore(false);
      } catch (error) {
        console.log("구매 히스토리 가져오기 실패!");
      }
    }
  }, [observer]);

  return (
    <Layout>
      {!isLoadedPurchaseItem && (
        <LoadingContainer>
          <Lottie data={LoadingLottie} loop playing />
        </LoadingContainer>
      )}

      {isLoadedPurchaseItem && (
        <>
          <Content />
        </>
      )}
      {isLoadingMore && (
        <LoadingContainer>
          <Lottie data={LoadingLottie} loop playing />
        </LoadingContainer>
      )}
      <Observer ref={ref} />
    </Layout>
  );
};

const Layout = styled.div``;
const Observer = styled.div``;
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 150px;
`;
