import { setUser } from "App/Common/setUser";
import { UserNodeStore } from "App/Store/UserNodeStore";
import { useLoadUserAdmin } from "GraphQL/Queries/useLoadUserAdmin";
import { useLoaded } from "Lib/use-loaded";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export const useUserList = () => {
  const [searchParams] = useSearchParams();
  const keyword = searchParams.get("keyword") || "";

  const { data, loading } = useLoadUserAdmin({ where: { email__starts_with: keyword }, first: 500 });
  const { isLoaded } = useLoaded(loading);
  useEffect(() => {
    UserNodeStore.clear();
    if (data) {
      setUser(data.user);
    }
  }, [data]);

  return { isLoaded };
};
