import { Button, ButtonSize } from "App/Atomics/Button";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { Color } from "Constants/Color";
import { AccountAuthConfirmSuccess, CommonBack } from "Constants/Locale";
import { HOME } from "Constants/Routes";
import { useNavigate } from "Lib/use-router";
import styled from "styled-components";

export const SuccessForm = () => {
  const navigate = useNavigate();
  const goBack = (): void => navigate(HOME);

  return (
    <Layout>
      <Title>
        <Typography color={Color.Ac_White} weight={TypoWeight.Bold} size={TypoSize.F24}>
          <AccountAuthConfirmSuccess />
        </Typography>
      </Title>
      <StyledButton size={ButtonSize.Medium} onClick={goBack}>
        <CommonBack />
      </StyledButton>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 343px;
  overflow: hidden;
`;
const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  white-space: normal;
  text-align: center;
`;

const StyledButton = styled(Button)`
  width: 160px;
  font-size: 14px;
  margin-bottom: 30px;
`;
