import { immerable } from "immer";
import { NumericID } from "Utils/id";

export class UserID extends NumericID {
  constructor(id: number) {
    super("user", id);
  }
}

export type UserPlayload = {
  readonly id: number;
  readonly email: string;
  readonly name: string;
  readonly company: string;
  readonly balance: number;
};

export class UserModel {
  static form({ id, balance, company, email, name }: UserPlayload) {
    return new UserModel(new UserID(id), email, name, company, balance);
  }

  constructor(readonly id: UserID, readonly email: string, readonly name: string, readonly company: string, readonly balance: number) {}
  readonly [immerable] = true;
}
