import { setPlaylistDetail } from "App/Common/setPlaylistDetail";
import { MAX_FIRST_COUNT } from "Constants/Count";
import { useLoadPlaylistDetail } from "GraphQL/Queries/useLoadPlaylistDetail";
import { PlaylistType } from "GraphQL/Schema";
import { useLoaded } from "Lib/use-loaded";
import { useEffect } from "react";
import { LibraryFavoritesAction, LibraryFavoritesStore } from "../Store";

export const useLikePlaylistDetail = () => {
  const { data, loading } = useLoadPlaylistDetail({
    type: [PlaylistType.Like],
    first: MAX_FIRST_COUNT
  });

  useEffect(() => {
    if (data) {
      const { playlist } = data;
      setPlaylistDetail(playlist);
    }
  }, [data]);
  const { isLoaded } = useLoaded(loading);
  useEffect(() => {
    LibraryFavoritesStore.dispatch(LibraryFavoritesAction.setIsLoadedLibraryFavorites(isLoaded));
  }, [isLoaded]);
};
