import { NumericID } from "Utils/id";
import { immerable } from "immer";
import { PlaylistID } from "./PlaylistModel";
import { AccountID } from "./AccountModel/AccountID";
import { Time } from "Lib/time";

export class CurationManagementID extends NumericID {
  constructor(id: number) {
    super("curationManagement", id);
  }
}

export type CurationManagementPlayload = {
  readonly id: number;
  readonly playlist: PlaylistID;
  readonly isOwner: boolean;
  readonly user: AccountID;
  readonly createAt: string;
};

export class CurationManagementModel {
  static form({ id, playlist, isOwner, user, createAt }: CurationManagementPlayload) {
    return new CurationManagementModel(new CurationManagementID(id), playlist, isOwner, user, Time(createAt));
  }

  constructor(
    readonly id: CurationManagementID,
    readonly playlist: PlaylistID,
    readonly isOwner: boolean,
    readonly user: AccountID,
    readonly createAt: Time
  ) {}
  readonly [immerable] = true;
}
