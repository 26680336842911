import { Color } from "Constants/Color";
import { useRef } from "react";
import styled from "styled-components";
import { useAudioWave } from "Utils/artistscardPlayer";

export const WaveController = () => {
  const ref = useRef<HTMLDivElement>(null);
  useAudioWave(ref);

  return (
    <Layout>
      <WaveContainer ref={ref} />
    </Layout>
  );
};

const Layout = styled.div`
  position: absolute;
  top: -50px;
  width: 100%;
  height: auto;
  background-color: ${Color.Ac_Charcoal};
`;

const WaveContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;
