import { Info } from "App/Atomics/AutoComplete/TextAutoComplete";
import { loadUserFind } from "GraphQL/Queries/loadUserFind";

export const findUserList = async (keyword: string, token: string): Promise<readonly Info[]> => {
  try {
    const data = await loadUserFind({
      where: {
        email__starts_with: keyword
      },
      token
    });
    if (data.data) {
      return data.data.user.map(item => {
        return { id: item.id.toString(), name: item.email };
      });
    }
  } catch (error) {}
  return [];
};
