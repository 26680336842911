import { createStore } from "Lib/store";

const createInitialState = () => ({
  isLoadedCache: false
});

export const {
  useSelector: useLoadingSelector,
  action: LoadingAction,
  ...LoadingStore
} = createStore("Loading", createInitialState, {
  clear(state) {
    state.isLoadedCache = false;
  },
  setIsLoadedCache(state, isLoadedCache: boolean) {
    state.isLoadedCache = isLoadedCache;
  }
});
