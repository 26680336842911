import { Color } from "Constants/Color";
import {
  Availability,
  LicensePurposeOfUse1,
  LicensePurposeOfUse10,
  LicensePurposeOfUse11,
  LicensePurposeOfUse2,
  LicensePurposeOfUse3,
  LicensePurposeOfUse4,
  LicensePurposeOfUse5,
  LicensePurposeOfUse6,
  LicensePurposeOfUse7,
  LicensePurposeOfUse8,
  LicensePurposeOfUse9,
  PurposeOfUse
} from "Constants/Locale";
import styled from "styled-components";

export const Chart = () => {
  return (
    <Layout>
      <thead>
        <tr>
          <Th>
            <PurposeOfUse />
          </Th>
          <Th>
            <Availability />
          </Th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <Td>
            <LicensePurposeOfUse1 />
          </Td>
          <Td>
            <Agree>O</Agree>
          </Td>
        </tr>
        <tr>
          <Td>
            <LicensePurposeOfUse2 />
          </Td>
          <Td>
            <Agree>O</Agree>
          </Td>
        </tr>
        <tr>
          <Td>
            <LicensePurposeOfUse3 />
          </Td>
          <Td>
            <Agree>O</Agree>
          </Td>
        </tr>
        <tr>
          <Td>
            <LicensePurposeOfUse4 />
          </Td>
          <Td>
            <Agree>O</Agree>
          </Td>
        </tr>
        <tr>
          <Td>
            <LicensePurposeOfUse5 />
          </Td>
          <Td>
            <Agree>O</Agree>
          </Td>
        </tr>
        <tr>
          <Td>
            <LicensePurposeOfUse6 />
          </Td>
          <Td>
            <Agree>O</Agree>
          </Td>
        </tr>
        <tr>
          <Td>
            <LicensePurposeOfUse7 />
          </Td>
          <Td>
            <Agree>O</Agree>
          </Td>
        </tr>
        <tr>
          <Td>
            <LicensePurposeOfUse8 />
          </Td>
          <Td>
            <Agree>O</Agree>
          </Td>
        </tr>
        <tr>
          <Td>
            <LicensePurposeOfUse9 />
          </Td>
          <Td>
            <Disagree>X</Disagree>
          </Td>
        </tr>
        <tr>
          <Td>
            <LicensePurposeOfUse10 />
          </Td>
          <Td>
            <Disagree>X</Disagree>
          </Td>
        </tr>
        <tr>
          <Td>
            <LicensePurposeOfUse11 />
          </Td>
          <Td>
            <Disagree>X</Disagree>
          </Td>
        </tr>
      </tbody>
    </Layout>
  );
};

const Agree = styled.span`
  color: ${Color.AC_Carrot};
`;
const Disagree = styled.span`
  color: ${Color.Ac_Gray1};
`;

const Layout = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 4rem;
`;

const Td = styled.td`
  padding: 30px;
  border: 1px solid ${Color.Ac_Gray6};
  font-size: 0.8rem;
  color: ${Color.Ac_Gray3};

  &:first-child {
    border-left: 0;
  }
  &:last-child {
    text-align: center;
    border-right: 0;
    font-weight: bold;
  }
`;

const Th = styled.th`
  padding: 20px 30px;
  border: 1px solid ${Color.Ac_Gray6};
  font-size: 0.9rem;
  color: ${Color.Ac_Gray3};
  border-top: 0;
  &:first-child {
    border-left: 0;
    text-align: left;
  }
  &:last-child {
    border-right: 0;
  }
`;
