import { Icon } from "App/Atomics/Icon";
import { PlayerButton } from "App/Features/Buttons/PlayerButton";
import { BuyButtonInfo } from "App/Molecules/BuyButtonInfo";
import { useAccountSelector } from "App/Store/AccountStore";
import { useArtistNode } from "App/Store/ArtistNodeStore";
import { ModalAction, ModalStore, ModalType } from "App/Store/ModalStore";
import { useTrackActivityNodeStore } from "App/Store/TrackActivity";
import { useTrackNode } from "App/Store/TrackNodeStore";
import { Color } from "Constants/Color";
import { artistDetailPath } from "Constants/Routes";
import { DESKTOP_MODE, WIDE_MODE, X_WIDE_MODE } from "Constants/Size";
import { useLikeTrack } from "GraphQL/Mutations/useLikeTrack";
import { useUnlikeTrack } from "GraphQL/Mutations/useUnlikeTrack";
import { TrackActivityType } from "GraphQL/Schema";
import { isGuestAccountModel } from "Models/AccountModel/AccountModel";
import { TrackID } from "Models/TrackModel";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { secondsToTime } from "Utils/format";

type Props = {
  readonly id: TrackID;
};

export const TrackInformation = ({ id }: Props) => {
  const navigate = useNavigate();

  const { likeTrack } = useLikeTrack({});
  const { unlikeTrack } = useUnlikeTrack({});

  const track = useTrackNode(id);
  const artist = useArtistNode(track?.artist);
  const isGuest = useAccountSelector(store => isGuestAccountModel(store.current));
  const activity = useTrackActivityNodeStore(store => store.values()).filter(activity => activity.track?.raw === id?.raw);
  const purchaseActivity = activity.find(({ type }) => type === TrackActivityType.Purchase)?.id ?? null;

  const likeActivity = activity.find(({ type }) => type === TrackActivityType.Like)?.id ?? null;

  const openTrackDownload = () => {
    if (isGuest) {
      openLogin();
      return;
    }
    ModalStore.dispatch(ModalAction.open(ModalType.TRACK_DOWNLOAD, { track: id, purchaseActivity: purchaseActivity }));
  };

  const openPlaylist = () => {
    if (isGuest) {
      openLogin();
      return;
    }
    ModalStore.dispatch(ModalAction.open(ModalType.PLAYLIST, { track: id }));
  };
  const openLogin = () => ModalStore.dispatch(ModalAction.replace(ModalType.SIGNIN));

  const openTrackLicense = () => {
    ModalStore.dispatch(ModalAction.open(ModalType.TRACK_LICENSE, { track: track?.id }));
  };

  const moveToArtistDetail = (id: number) => navigate(artistDetailPath.path({ id: id }));
  const tryToLikeTrack = (id: number) => {
    if (isGuest) {
      openLogin();
      return;
    }
    likeTrack({ variables: { trackId: id } });
  };

  const tryToUnLikeTrack = (id: number) => {
    if (isGuest) {
      openLogin();
      return;
    }
    unlikeTrack({ variables: { trackId: id } });
  };

  if (!track) return null;

  return (
    <Layout>
      <CenterBox>
        <ButtonContainer>
          <PlayerButton id={id} />
        </ButtonContainer>
        <Title>{track?.title}</Title>
        <Artist onClick={() => moveToArtistDetail(artist?.id!.raw!)}>{artist?.name}</Artist>

        <BuyButtonInfo trackId={track?.id!} />
      </CenterBox>
      <TabList>
        <TabListContainer>
          <Tab>
            <TabHeader>{secondsToTime(track?.duration ?? 0)}</TabHeader>
            <TabSubFooter>MINUTES</TabSubFooter>
          </Tab>
          <Tab>
            <TabHeader>{!!track.bpm ? track.bpm : "-"}</TabHeader>
            <TabSubFooter>BPM</TabSubFooter>
          </Tab>
          <DownloadTag onClick={openTrackDownload}>
            <TabHeader>
              <DownLoadIcon name="download" />
            </TabHeader>
            <TabSubFooter>DOWNLOAD</TabSubFooter>
          </DownloadTag>
          {!!likeActivity && (
            <HoverTab onClick={() => tryToUnLikeTrack(id!.raw)}>
              <TabHeader>
                <StatusIcon name="like-fill" />
              </TabHeader>
              <TabSubFooter>FAVORITE</TabSubFooter>
            </HoverTab>
          )}
          {!likeActivity && (
            <HoverTab onClick={() => tryToLikeTrack(id!.raw)}>
              <TabHeader>
                <StatusIcon name="like" />
              </TabHeader>
              <TabSubFooter>FAVORITE</TabSubFooter>
            </HoverTab>
          )}
          <HoverTab onClick={openPlaylist}>
            <TabHeader>
              <StatusIcon name="add-playlist" />
            </TabHeader>
            <TabSubFooter>PLAYLIST</TabSubFooter>
          </HoverTab>
          <HoverTab onClick={openTrackLicense}>
            <TabHeader>
              <LicenseIcon name="question" />
            </TabHeader>
            <TabSubFooter>LICENSE</TabSubFooter>
          </HoverTab>
        </TabListContainer>
      </TabList>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${DESKTOP_MODE} {
    padding: 50px;
  }

  @media ${WIDE_MODE} {
    padding: 60px;
  }

  @media ${X_WIDE_MODE} {
    padding: 70px;
  }

  padding: 30px;

  position: relative;
`;

const CenterBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
`;

const Title = styled.h2`
  width: 100%;
  text-align: center;
  font-size: 3rem;
  margin-top: 1rem;
  margin-bottom: 1.25rem;
  white-space: normal;
  word-break: break-all;
`;

const Artist = styled.p`
  font-size: 1rem;
  width: 100%;
  color: ${Color.Ac_Gray1};
  text-align: center;
  margin-bottom: 20px;
  cursor: pointer;
  &:hover {
    color: ${Color.Ac_Apricot};
    text-decoration: underline;
  }
`;

const TabList = styled.div`
  position: relative;
  margin-top: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const TabListContainer = styled.div`
  width: 100%;
  display: flex;

  max-width: 1280px;

  @media ${DESKTOP_MODE} {
    flex-direction: row;
  }
  flex-direction: column;
`;

const Tab = styled.div`
  display: flex;
  height: 90px;
  flex: 1 1 0%;
  padding: 0 10px;
  flex-direction: column;
  align-items: center;

  margin-bottom: 2rem;
  @media ${DESKTOP_MODE} {
    margin-bottom: 0;
  }

  &:not(:last-child) {
    @media ${DESKTOP_MODE} {
      border-right: 1px solid ${Color.Ac_Gray};
    }
    border-right: 0px;
  }
`;

const HoverTab = styled(Tab)`
  display: flex;
  height: 90px;
  flex: 1 1 0%;
  padding: 0 10px;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  &:not(:last-child) {
    @media ${DESKTOP_MODE} {
      border-right: 1px solid ${Color.Ac_Gray};
    }
    border-right: 0px;
  }

  &:hover {
    background: ${Color.Ac_Gray};
  }
`;

const DownloadTag = styled(HoverTab)`
  display: none;
  @media ${DESKTOP_MODE} {
    display: flex;
  }
`;

const TabHeader = styled.div`
  height: 60px;
  font-size: 2rem;
  display: flex;
  align-items: center;
`;

const TabSubFooter = styled.div`
  font-size: 0.75rem;
  font-weight: bold;
  color: ${Color.Ac_Gray1};
`;

const StatusIcon = styled(Icon)`
  width: 2rem;
  height: 2rem;

  path {
    fill: ${Color.Ac_White};
  }

  rect {
    fill: rgba(0, 0, 0, 0) !important;
  }
`;

const LicenseIcon = styled(Icon)`
  width: 2rem;
  height: 2rem;

  path,
  rect,
  polygon {
    fill: ${Color.Ac_White};
  }
`;

const DownLoadIcon = styled(Icon)`
  width: 2rem;
  height: 2rem;
  path,
  rect {
    fill: ${Color.Ac_White};
  }
`;
const ButtonContainer = styled.div`
  width: 60px;
  height: 60px;
`;
