export const configs = {
  isProduction: process.env.REACT_APP_BUILD_MODE! === "production",
  isTest: process.env.REACT_APP_BUILD_MODE! === "test",
  firebaseAuth: initializeFirebaseAuth(),
  urls: initializeUrls()
};

function initializeUrls() {
  return {
    current: process.env.REACT_APP_URL_CURRENT!,
    instagramLink: process.env.REACT_APP_URL_INSTAGRAM!,
    youtubeLink: process.env.REACT_APP_URL_YOUTUBE!,
    language: process.env.REACT_APP_URL_LANGUAGE!,
    service: process.env.REACT_APP_URL_SERVICE!,
    serviceHealth: process.env.REACT_APP_URL_SERVICE_HEALTH,
    paymentHealth: process.env.REACT_APP_URL_PAYMENY_HEALTH,
    payment: process.env.REACT_APP_URL_PAYMENT!,
    image: process.env.REACT_APP_URL_IMAGE!
  };
}

function initializeFirebaseAuth() {
  return {
    apiKey: process.env.REACT_APP_FIREBASE_AUTH_API_KEY!,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_AUTH_DOMAIN!,
    databaseURL: process.env.REACT_APP_FIREBASE_AUTH_DATABASE_URL!,
    projectId: process.env.REACT_APP_FIREBASE_AUTH_PROJECT_ID!,
    storageBucket: process.env.REACT_APP_FIREBASE_AUTH_STORAGE_BUCKET!,
    messagingSenderId: process.env.REACT_APP_FIREBASE_AUTH_MESSAGING_SENDER_ID!,
    appId: process.env.REACT_APP_FIREBASE_AUTH_APP_ID!,
    measurementId: process.env.REACT_APP_FIREBASE_AUTH_MEASUREMENT_ID!
  };
}
