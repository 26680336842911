import { ProductPriceType, TypeMetadataSubclass, User } from "GraphQL/Schema";
import { gql, useMutation } from "Lib/apollo-client";

const CREATE_PRODUCT_PRICE = gql`
  mutation CREATE_PRODUCT_PRICE(
    $title: String!
    $price: Int!
    $type: ProductPriceType!
    $description: String
    $target: TypeMetadataSubclass!
  ) {
    createProductPrice: create_product_price(title: $title, price: $price, type: $type, description: $description, target: $target) {
      id
    }
  }
`;

type Result = {
  readonly createPrice: User;
};

type Args = {
  readonly title: string;
  readonly target: TypeMetadataSubclass;
  readonly price: number;
  readonly type?: ProductPriceType;
  readonly description?: string;
};

export const useCreatePrice = () => {
  const [fetch, { loading, data, error }] = useMutation<Result, Args>(CREATE_PRODUCT_PRICE);

  return { createPrice: fetch, loading, data, error };
};
