import { BasicTemplate } from "App/Templates/BasicTemplate";
import { Size, WIDE_MODE } from "Constants/Size";
import styled from "styled-components";
import { Form } from "./Form";

export const Account = () => {
  return (
    <BasicTemplate isSidebar={false} isMenu={false}>
      <Layout>
        <Form />
      </Layout>
    </BasicTemplate>
  );
};

const Layout = styled.div`
  margin-top: ${Size.HEADER_DESKTOP_HEIGHT_PX};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 4%;
  @media ${WIDE_MODE} {
    padding: 0 60px;
  }
`;
