import { setPointTransaction } from "App/Common/setPointTransaction";
import { PointTransactionNodeStore } from "App/Store/PointTransactionNodeStore";
import { useLoadPointTransaction } from "GraphQL/Queries/useLoadPointTransaction";
import { useLoaded } from "Lib/use-loaded";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export const usePointTransactionDetail = () => {
  const { id } = useParams();
  const { data, loading } = useLoadPointTransaction({
    where: {
      id: +id!
    },
    isForce: true
  });

  const { isLoaded } = useLoaded(loading);

  useEffect(() => {
    PointTransactionNodeStore.clear();
    if (data) {
      const { pointTransaction } = data;
      console.log(pointTransaction);
      setPointTransaction(pointTransaction);
    }
  }, [data]);

  return { isLoaded };
};
