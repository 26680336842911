import { configs } from "Config";
import { Time } from "Lib/time";
import { noop } from "lodash";
import { integrateReduxDevtools } from "./integrateReduxDevtools";
import diff from "jest-diff";
import { InternalAction } from "../store/combineReducers";

export const createStoreLogger = (namespace: string): (<S>(previous: S, next: S, action: InternalAction<any>) => void) => {
  if (configs.isProduction || configs.isTest) {
    return noop;
  }

  const send = integrateReduxDevtools(namespace)?.send ?? noop;

  return <S extends Object>(previous: S, next: S, action: InternalAction<any>) => {
    console.groupCollapsed(
      `%c${namespace}.dispatch(%c${action.namespace}.${action.key}(%c${action.payload.length ? "..." : ""}%c)%c)\n%c${Time().format(
        "[in] YYYY[년] MM[월] DD[일] HH[시] mm[분] ss[초]"
      )}`,
      "color: hsl(0 36% 36%);",
      "color: hsl(0 64% 50%);",
      "color: hsl(0 36% 36%);",
      "color: hsl(0 64% 50%);",
      "color: hsl(0 36% 36%);",
      "color: hsl(0 16% 64%);"
    );

    if (action.payload.length) {
      console.groupCollapsed("%cPayload", "font-weight: bolder;");
      console.log(action.payload);
      console.groupEnd();
    }

    console.groupCollapsed("%cPrevious", "font-weight: bolder;");
    console.log(previous);
    console.groupEnd();

    console.groupCollapsed("%cNext", "font-weight: bolder;");
    console.log(next);
    console.groupEnd();

    {
      const diffLineList = diff(previous, next, { aAnnotation: "Previous", bAnnotation: "Next" })?.split("\n") ?? [];
      if (diffLineList.length > 255) {
        console.log(`%cDiff (lines: ${diffLineList.length}, skipped)`, "font-weight: bolder;");
      } else {
        const lineList: string[] = [];
        const styleList: string[] = [];
        for (const line of diffLineList) {
          if (line.startsWith("-")) {
            lineList.push(`%c${line}%c`);
            styleList.push("color: red;", "color: grey");
          } else if (line.startsWith("+")) {
            lineList.push(`%c${line}%c`);
            styleList.push("color: green;", "color: grey;");
          } else {
            lineList.push(line);
          }
        }
        if (styleList.length) {
          console.groupCollapsed(`%cDiff (lines: ${lineList.length})`, "font-weight: bolder;");
          console.log(lineList.join("\n"), ...styleList);
          console.groupEnd();
        }
      }
    }

    console.groupCollapsed("Stacktrace");
    console.trace();
    console.groupEnd();

    console.groupEnd();

    send(action.type, next);
  };
};
