import { LOAD_PRODUCT_PRICE } from "GraphQL/Queries/useLoadProductPrice";
import { gql, useMutation } from "Lib/apollo-client";

const APPROVE_PRICE = gql`
  mutation APPROVE_PRICE($productPriceId: Int!) {
    approvePrice: approve_price(product_price_id: $productPriceId)
  }
`;

type Result = {
  readonly approvePrice: boolean;
};

type Args = {
  readonly productPriceId: number;
};

export const useApprovePrice = () => {
  const [fetch, { loading, error }] = useMutation<Result, Args>(APPROVE_PRICE, {
    refetchQueries: [LOAD_PRODUCT_PRICE]
  });

  return { approvePrice: fetch, loading, error };
};
