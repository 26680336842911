import { Aggregate } from "GraphQL/Schema";
import { gql, useQuery } from "Lib/apollo-client";

export const LOAD_CART_COUNT = gql`
  query LOAD_CART_COUNT {
    cartCount: getCount(node: CartItem) {
      count
    }
  }
`;

type Result = {
  readonly cartCount: Aggregate;
};

type Args = {
  readonly first?: number;
  readonly isSkip?: boolean;
};

export const useLoadCartCount = ({ isSkip }: Args) => {
  const { data, loading } = useQuery<Result, Args>(LOAD_CART_COUNT, {
    skip: isSkip,
    context: {
      headers: {
        endpoint: "child"
      }
    }
  });

  return { loading, data };
};
