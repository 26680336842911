import { setCurationManagementDetail } from "App/Common/setCurationManagementDetail";
import { useLoadCurationManagementDetail } from "GraphQL/Queries/useLoadCurationManagementDetail";

import { useLoaded } from "Lib/use-loaded";
import { useEffect } from "react";

export const useCurationManagementDetail = (id: number) => {
  const { data, loading } = useLoadCurationManagementDetail({
    where: {
      id
    }
  });
  const { isLoaded } = useLoaded(loading);

  useEffect(() => {
    if (data) {
      const { curationManagement } = data;
      setCurationManagementDetail(curationManagement);
    }
  }, [data]);

  return { isLoaded };
};
