import { setProductPrice } from "App/Common/setProductPrice";
import { ProductPriceNodeStore } from "App/Store/ProductPriceNodeStore";
import { Edge } from "Constants/Edge";
import { useLoadProductPrice } from "GraphQL/Queries/useLoadProductPrice";
import { TypeMetadataSubclass } from "GraphQL/Schema";
import { useLoaded } from "Lib/use-loaded";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const useProductPrice = () => {
  const [edge, setEdge] = useState<Edge | null>(null);
  const [search] = useSearchParams();
  const page = +(search.get("page") || 1);
  const target = (search.get("target") as TypeMetadataSubclass) ?? TypeMetadataSubclass.Bgm;
  const { data, loading } = useLoadProductPrice({
    first: 20,
    page,
    where: {
      target: target
    }
  });
  const { isLoaded } = useLoaded(loading);

  useEffect(() => {
    ProductPriceNodeStore.clear();
    if (data) {
      const { productPrice, edge } = data;
      setProductPrice(productPrice);
      setEdge(edge);
    }
  }, [data]);

  return { isLoaded, edge };
};
