import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { configs } from "Config";
import { HttpClient } from "Lib/http-client";
export { GraphQLClientProvider } from "./GraphQLClientProvider";

export const LanguageClient = HttpClient.create({ baseUrl: configs.urls.language! });
export const serviceHealthClient = HttpClient.create({ baseUrl: configs.urls.serviceHealth! });
export const paymentHealthClient = HttpClient.create({ baseUrl: configs.urls.paymentHealth! });

const httpLink = new HttpLink({ uri: configs.urls.payment });
// const httpLink = new HttpLink({ uri: configs.urls.payment, useGETForQueries: true });
// const persistedQueriesLink = createPersistedQueryLink({ sha256, useGETForHashedQueries: true });

export const paymentClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: httpLink
});
