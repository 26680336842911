import { useUserNodeStore } from "App/Store/UserNodeStore";
import styled from "styled-components";
import { UserItem } from "./UserItem";

export const UserContent = () => {
  const userList = useUserNodeStore(store => store.keys());

  return (
    <Layout>
      {userList.map(id => (
        <UserItem key={id.key} id={id} />
      ))}
    </Layout>
  );
};

const Layout = styled.tbody``;
