import { Typography, TypoSize } from "App/Atomics/Typography";
import { Color } from "Constants/Color";
import { Duration, License, Title, UppercaseBpm } from "Constants/Locale";
import { DESKTOP_MODE, MAX_MOBILE_MODE, WIDE_MODE, X_WIDE_MODE } from "Constants/Size";
import styled from "styled-components";

export const TrackHeader = () => {
  return (
    <Layout>
      <FlexAudioContainer></FlexAudioContainer>
      <FlexRightContainer>
        <FlexInformationContainer>
          <TrackTitleLayout>
            <Title />
          </TrackTitleLayout>
          <DurationLayout>
            <Duration />
          </DurationLayout>
          <BpmLayout>
            <UppercaseBpm />
          </BpmLayout>
        </FlexInformationContainer>
        <FlexLicenseContainer>
          <Typography color={Color.Ac_Gray1} size={TypoSize.F15}>
            <License />
          </Typography>
        </FlexLicenseContainer>
        <FlexStatusContainer>
          <PlaylistIconContainer />
          <StatusIconContainer />
          <StatusIconContainer />
          <StatusIconContainer />
          <StatusIconContainer />
          <CartIconContainer />
        </FlexStatusContainer>

        <FlexBuyContainer />
      </FlexRightContainer>
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
  flex-wrap: nowrap;
  white-space: nowrap;
  padding: 25px;
  font-size: 0.75rem;
  color: ${Color.Ac_Gray1};
  display: none;
  @media ${DESKTOP_MODE} {
    display: flex;
  }
`;

const FlexAudioContainer = styled.div`
  margin-right: 1rem;
  flex: 0 0 54px;
`;

const FlexRightContainer = styled.div`
  flex: 1 0 10px;
  min-width: 0;

  display: flex;
  align-items: center;
`;

const FlexInformationContainer = styled.div`
  margin-right: 1rem;
  flex: 5 0 50%;

  display: flex;
  align-items: center;
`;

const TrackTitleLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex: 9 0 50px;
  font-size: 0.9rem;
  min-width: 0;
`;

const DurationLayout = styled.div`
  margin-right: 0.625rem;
  flex: 1 0 40px;
  font-size: 0.9rem;
  text-align: center;

  @media ${X_WIDE_MODE} {
    display: block;
  }
  display: none;
`;

const BpmLayout = styled.div`
  margin-right: 0.625rem;
  flex: 1 0 40px;
  font-size: 0.9rem;
  text-align: center;

  @media ${X_WIDE_MODE} {
    display: block;
  }
  display: none;
`;

const FlexStatusContainer = styled.div`
  margin-right: 1rem;
  flex: 1 0 140px;

  display: flex;
  align-items: center;
`;

const StatusIconContainer = styled.div`
  height: 30px;
  min-width: 30px;

  padding: 0 5px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const FlexBuyContainer = styled.div`
  flex: 1 0 140px;

  @media ${WIDE_MODE} {
    display: block;
  }
  display: none;
`;

const FlexLicenseContainer = styled.div`
  flex: 0 0 100px;

  align-items: center;
  justify-content: center;

  @media ${WIDE_MODE} {
    display: flex;
  }
  display: none;
`;

const CartIconContainer = styled(StatusIconContainer)`
  @media ${WIDE_MODE} {
    display: none;
  }
  display: flex;
`;

const PlaylistIconContainer = styled(StatusIconContainer)`
  @media ${MAX_MOBILE_MODE} {
    display: none;
  }
  display: flex;
`;
