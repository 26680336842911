import { useLocation as useReactRouterLocation } from "react-router-dom";
export {
  NavLink,
  Navigate,
  useMatch,
  useSearchParams,
  useResolvedPath,
  useParams,
  useNavigate,
  createSearchParams,
  Outlet
} from "react-router-dom";
export const useLocation = () => useReactRouterLocation();
