import { currentLanguage, fallbackLanguage, useCurrentLanguage } from "Lib/localization";
import { LOADING, useLoading } from "Lib/use-loading";
import { loadLocaleInformation } from "Locale/localeLocaleInformation";
import { ReactNode } from "react";
import { useSearchParams } from "react-router-dom";
import { useAsyncEffect } from "use-async-effekt";

type Props = {
  children: ReactNode;
};

export const LocaltionManager = ({ children }: Props) => {
  const loading = useLoading(LOADING);
  const [searchParams] = useSearchParams();
  const defaultLangauge = useCurrentLanguage();
  const language = searchParams.get("lang") ?? defaultLangauge;

  const setLanguage = async () => {
    const information = await loadLocaleInformation();
    if (information) {
      const cureentLocales = information.locales.find(({ code }) => code === information.currentLanguage)?.locales;
      const fallbackLocales = information.locales.find(({ code }) => code === information.fallbackLanguage)?.locales;

      currentLanguage(information.currentLanguage, cureentLocales!);
      fallbackLanguage(information.fallbackLanguage, fallbackLocales!);
    }
  };

  useAsyncEffect(async () => {
    loading.setLoading();
    await setLanguage();
    loading.setLoaded();
  }, [language]);

  if (loading.isLoading) return <></>;

  return <>{children}</>;
};
