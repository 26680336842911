import { useMood } from "App/hooks/useMood";
import { MetdataKind } from "Models/GenreModel/GenreModel";

export const useMoodList = () => {
  const { loading: effectLoading } = useMood({ kind: MetdataKind.Effect });
  const { loading: cclLoading } = useMood({ kind: MetdataKind.Ccl });
  const { loading: pdLoading } = useMood({ kind: MetdataKind.Pd });
  const { loading: trackLoading } = useMood({ kind: MetdataKind.Track });

  const loading = effectLoading || cclLoading || pdLoading || trackLoading;

  return { loading };
};
