import { EdgePagination } from "App/Molecules/EdgePagination";
import { Edge } from "Constants/Edge";
import { useSearch } from "Lib/use-search-query";
import styled from "styled-components";

type Props = {
  readonly edge: Edge | null;
};

export const PurchaseHistoryFooter = ({ edge }: Props) => {
  const { setQuery } = useSearch();

  const goTo = (page: number) => {
    window.scrollTo({ top: 0 });
    setQuery("page", page.toString());
  };

  return (
    <Layout>
      <EdgePagination edge={edge} goTo={goTo} />
    </Layout>
  );
};

const Layout = styled.div`
  margin: 5rem 0;
`;
