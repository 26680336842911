import { Color } from "Constants/Color";
import React from "react";
import { useRef } from "react";
import styled, { css } from "styled-components";

type Props = Styleable & {
  readonly min?: number;
  readonly max?: number;
  readonly step?: number;
  readonly defaultValue?: number;
  readonly value?: number;
  readonly onChange: (vol: number) => void;
};

export const Range = ({ style, className, min = 0, max = 100, step = 1, defaultValue, value, onChange }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const onChangeRange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = +e.target.value;
    onChange(inputValue);
  };
  return (
    <Layout style={style} className={className}>
      <InputRange
        ref={inputRef}
        type="range"
        min={min}
        max={max}
        step={step}
        defaultValue={defaultValue}
        value={value}
        onChange={onChangeRange}
        onClick={e => e.stopPropagation()}
        percent={value ?? 100}
      />
    </Layout>
  );
};
const Layout = styled.div`
  max-width: 80px;
`;

const ThumbStyle = css`
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  background-color: ${Color.Ac_White};
  box-shadow: 0px 1px 1px ${Color.Ac_Black};
  cursor: pointer;
`;

const InputRange = styled.input<{ percent: number }>`
  -webkit-appearance: none;
  width: 100%;
  height: 2px;
  background: linear-gradient(
    90deg,
    ${Color.Ac_White} ${props => props.percent}%,
    rgba(214, 214, 214, 0.1) ${props => props.percent}%
  ) !important;
  border-radius: 12px;
  outline: none;
  cursor: pointer;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    ${ThumbStyle};
  }
  &::-moz-range-thumb {
    ${ThumbStyle};
  }
`;
