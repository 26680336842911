import { PurchaseItemNodeStore } from "App/Store/PurchaseItemNodeStore";
import { PurchaseItem } from "GraphQL/Schema";
import { PurchaseHistoryID } from "Models/PurchaseHistoryModel";
import { PurchaseItemID, PurchaseItemModel } from "Models/PurchaseItemModel";
import { TrackID } from "Models/TrackModel";
import { setTrack } from "./setTrack";

export const setPurchaseItem = (purchaseItems: PurchaseItem[]) => {
  const purchaseItemMs = purchaseItems.map(purchaseItem => {
    const track = purchaseItem.track!;
    console.log(track);
    const purchaseItemM = PurchaseItemModel.form({
      id: purchaseItem.id,
      createdAt: purchaseItem.created_at,
      updatedAt: purchaseItem.updated_at,
      price: purchaseItem.product_price.price,
      purchaseHistoryId: new PurchaseHistoryID(purchaseItem.purchase_history_id),
      type: purchaseItem.product_type,
      track: new TrackID(track.id)
    });

    setTrack([track]);
    PurchaseItemNodeStore.set(new PurchaseItemID(purchaseItem.id), purchaseItemM);
    return purchaseItemM;
  });

  return purchaseItemMs;
};
