import { GradeNodeStore } from "App/Store/GradeNodeStore";
import { Grade } from "GraphQL/Schema";
import { GradeID, GradeModel } from "Models/GradeModel";
import { ProductPriceID } from "Models/ProductPriceModel";
import { setProductPrice } from "./setProductPrice";

export const setGrade = (gradeList: Grade[]) => {
  for (const grade of gradeList) {
    const gradeM = GradeModel.form({
      id: grade.id,
      grade: grade.grade,
      createdAt: grade.created_at,
      updatedAt: grade.updated_at,
      productPrice: new ProductPriceID(grade.product_price_id)
    });

    if (grade.product_price) {
      setProductPrice([grade.product_price]);
    }
    GradeNodeStore.set(new GradeID(grade.id), gradeM);
  }
};
