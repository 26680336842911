import { Button, ButtonColor, ButtonSize } from "App/Atomics/Button";
import { Icon } from "App/Atomics/Icon";
import { Input } from "App/Atomics/Input";
import { ModalAction, ModalStore } from "App/Store/ModalStore";
import { Color } from "Constants/Color";
import { Create, CreatePlaylist, Description, SucessCreatePlaylist, TitleErrorNull } from "Constants/Locale";
import { useCreatePlaylist } from "GraphQL/Mutations/useCreatePlaylist";
import { Modal } from "Lib/modal";
import { Toast } from "Lib/toast";
import { useRef } from "react";
import styled from "styled-components";
import { Title as TitleLocale } from "Constants/Locale";
type Props = {
  readonly isOpen: boolean;
};

export const AddPlayListModal = ({ isOpen }: Props) => {
  const { createPlaylist, loading } = useCreatePlaylist();

  const onRequestClose = () => ModalStore.dispatch(ModalAction.closeAll());
  const inputEmail = useRef<HTMLInputElement>(null);
  const textAreaDescription = useRef<HTMLTextAreaElement>(null);

  const onAddPlayList = async () => {
    const title = inputEmail.current?.value;
    const description = textAreaDescription.current?.value;
    if (!title?.trim()) {
      Toast.primary(TitleErrorNull.message());
      return;
    }

    await createPlaylist({
      variables: {
        title,
        description
      }
    });
    Toast.success(SucessCreatePlaylist.message());
    onRequestClose();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Layout>
        <HeaderLayout>
          <Title>
            <CreatePlaylist />
          </Title>
          <CrossIcon name="close" onClick={onRequestClose}></CrossIcon>
        </HeaderLayout>
        <CustomInput ref={inputEmail} type="text" placeholder={TitleLocale.message()} />
        <CustomTextArea ref={textAreaDescription} placeholder={Description.message()}></CustomTextArea>
        <ButtonContainer>
          {!loading && (
            <Button style={{ width: "100%" }} size={ButtonSize.Small} color={ButtonColor.Primary} onClick={onAddPlayList}>
              <Create />
            </Button>
          )}

          {loading && (
            <Button style={{ width: "100%" }} size={ButtonSize.Small} color={ButtonColor.Disable}>
              <Loading name="loading" />
            </Button>
          )}
        </ButtonContainer>
      </Layout>
    </Modal>
  );
};

const Layout = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;
  background-color: ${Color.Ac_Charcoal};
  padding: 30px;
  overflow: hidden;
  pointer-events: auto;

  display: flex;
  flex-direction: column;
`;

const CrossIcon = styled(Icon)`
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
`;

const Title = styled.h2`
  font-size: 1.5rem;
`;

const CustomInput = styled(Input.Text)`
  background-color: ${Color.Ac_Shadow};
  border-color: ${Color.Ac_Gray0};

  &::placeholder {
    color: ${Color.Ac_Gray0};
  }
  color: ${Color.Ac_White}!important;
`;

const CustomTextArea = styled(Input.TextArea)`
  background-color: ${Color.Ac_Shadow};
  border-color: ${Color.Ac_Gray0};

  margin-top: 1rem;

  &::placeholder {
    color: ${Color.Ac_Gray0};
  }
  color: ${Color.Ac_White}!important;
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;

const HeaderLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Loading = styled(Icon)`
  margin: 0 6px;
  width: 54.7px;
  height: 20px;
`;
