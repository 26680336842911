import { Color } from "Constants/Color";
import { Duration, Title, UppercaseBpm } from "Constants/Locale";
import { X_WIDE_MODE } from "Constants/Size";
import styled from "styled-components";

export const PlayerHeader = () => {
  return (
    <Layout>
      <FlexAudioContainer></FlexAudioContainer>
      <FlexRightContainer>
        <FlexInformationContainer>
          <TrackTitleLayout>
            <Title />
          </TrackTitleLayout>
          <DurationLayout>
            <Duration />
          </DurationLayout>
          <BpmLayout>
            <UppercaseBpm />
          </BpmLayout>
        </FlexInformationContainer>
        <FlexStatusContainer>
          <StatusIconContainer />
          <StatusIconContainer />
          <StatusIconContainer />
          <StatusIconContainer />
          <StatusIconContainer />
        </FlexStatusContainer>
        <FlexBuyContainer />
        <FlexCloseContainer />
      </FlexRightContainer>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-wrap: nowrap;
  padding: 25px;
  white-space: nowrap;
  font-size: 0.75rem;
  color: ${Color.Ac_Gray1};
`;

const FlexAudioContainer = styled.div`
  margin-right: 1rem;
  flex: 0 0 54px;
`;

const FlexRightContainer = styled.div`
  flex: 1 0 10px;
  min-width: 0;

  display: flex;
  align-items: center;
`;

const FlexInformationContainer = styled.div`
  margin-right: 1rem;
  flex: 5 0 50%;

  display: flex;
  align-items: center;
`;

const TrackTitleLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex: 9 0 50px;
  font-size: 0.9rem;
  min-width: 0;
`;

const DurationLayout = styled.div`
  margin-right: 0.625rem;
  flex: 1 0 40px;
  font-size: 0.9rem;
  text-align: center;

  @media ${X_WIDE_MODE} {
    display: block;
  }
  display: none;
`;

const BpmLayout = styled.div`
  margin-right: 0.625rem;
  flex: 1 0 40px;
  font-size: 0.9rem;
  text-align: center;

  @media ${X_WIDE_MODE} {
    display: block;
  }
  display: none;
`;

const FlexStatusContainer = styled.div`
  margin-right: 1rem;
  flex: 1 0 200px;

  display: flex;
  align-items: center;
`;

const StatusIconContainer = styled.div`
  height: 30px;
  min-width: 30px;

  padding: 0 5px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const FlexBuyContainer = styled.div`
  flex: 1 0 140px;
`;

const FlexCloseContainer = styled.div`
  flex: 1 0 20px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
