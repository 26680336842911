import { Icon } from "App/Atomics/Icon";
import { SidebarAction, SidebarStore } from "App/Store/SidebarStore";
import { Color } from "Constants/Color";
import { ADMIN_HOME, HOME } from "Constants/Routes";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useSignOut } from "Utils/useSignout";

export const Header = () => {
  const signOut = useSignOut();
  const logout = async () => {
    signOut();
  };

  const moveToMainHome = () => {
    navigate(HOME);
  };

  const toggleSidebar = () => SidebarStore.dispatch(SidebarAction.toggleAdmin());
  const navigate = useNavigate();
  const moveToHome = () => navigate(ADMIN_HOME);
  return (
    <Layout>
      <Left>
        <HamContainer onClick={toggleSidebar}>
          <Ham name="hamburger-bar" />
          <Ham name="hamburger-bar" />
          <Ham name="hamburger-bar" />
        </HamContainer>
        <Logo name="logo-white" onClick={moveToHome} />
      </Left>
      <Right>
        <GetOutOfHere onClick={moveToMainHome}>홈으로 돌아가기</GetOutOfHere>
        <Logout onClick={logout}>LOGOUT</Logout>
      </Right>
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  justify-content: space-between;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
`;

const HamContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.55rem 0.25rem;
  border-radius: 1rem;
  margin-right: 0.25rem;
  cursor: pointer;
  user-select: none;
  &:hover {
    path {
      fill: #fff;
    }
  }
  &:active {
    background-color: ${Color.Ac_Gray0};
  }
  transition: all 0.15s;
`;
const Ham = styled(Icon)`
  width: 20px;
  height: 5px;
  path {
    fill: ${Color.GRAY_3};
  }
`;

const Logo = styled(Icon)`
  width: 12rem;
  height: auto;
  cursor: pointer;
`;

const Logout = styled.span`
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    color: ${Color.GRAY_3};
  }
`;

const Right = styled.div`
  display: flex;
`;

const GetOutOfHere = styled.div`
  margin-right: 1rem;
  font-weight: bold;
  cursor: pointer;
`;
