import { WatchQueryFetchPolicy } from "@apollo/client";
import { Edge } from "Constants/Edge";
import { EDGE_FRAGMENT } from "GraphQL/Fragment/EdgeFragment";
import { TRACK_FRAGMENT } from "GraphQL/Fragment/TrackFragment";
import { PurchaseItem, PurchaseItemWhereInput } from "GraphQL/Schema";
import { gql, useQuery } from "Lib/apollo-client";

export const LOAD_PURCHASE_ITEM = gql`
  ${TRACK_FRAGMENT}
  ${EDGE_FRAGMENT}
  query LOAD_PURCHASE_ITEM($where: PurchaseItemWhereInput, $first: Int, $page: Int) {
    purchaseItem: purchase_item(where: $where, first: $first, page: $page, orderBy: id__DESC) {
      id
      purchase_history_id
      product_type
      created_at
      updated_at
      product_price {
        id
        price
      }
      track {
        ...TrackFragment
      }
    }
    edge(node: PurchaseItem, where: { purchase_item: $where }, first: $first, page: $page, orderBy: { purchase_item: id__DESC }) {
      ...EdgeFragment
    }
  }
`;

type Result = {
  readonly purchaseItem: PurchaseItem[];
  readonly edge: Edge;
};

type Args = {
  readonly first?: number;
  readonly page?: number;
  readonly where?: PurchaseItemWhereInput;
  readonly fetchPolicy?: WatchQueryFetchPolicy;
};

export const useLoadPurchaseItem = ({ first, page, where, fetchPolicy = "network-only" }: Args) => {
  const { data, loading, fetchMore } = useQuery<Result, Args>(LOAD_PURCHASE_ITEM, {
    variables: {
      first,
      page,
      where
    },
    fetchPolicy: fetchPolicy
  });

  return { loading, data, fetchMore };
};
