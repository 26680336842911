import { AdminTemplate } from "App/Templates/AdminTemplate";
import { useEffect } from "react";
import styled from "styled-components";
import { Form } from "./Form";
import { CachargePointAction, CachargePointStore } from "./Store/CechargePointStore";

export const CechargePoint = () => {
  useEffect(() => {
    return () => {
      CachargePointStore.dispatch(CachargePointAction.clear());
    };
  }, []);

  return (
    <AdminTemplate>
      <Layout>
        <Form />
      </Layout>
    </AdminTemplate>
  );
};

const Layout = styled.div``;
