import { Color } from "Constants/Color";
import { Description, ModifiedDate, Title, TrackCount } from "Constants/Locale";
import { DESKTOP_MODE, WIDE_MODE } from "Constants/Size";
import styled from "styled-components";

export const PlaylistHeader = () => {
  return (
    <Header>
      <PlayListNameContainer>
        <Title />
      </PlayListNameContainer>
      <PlayListDescriptionContainer>
        <Description />
      </PlayListDescriptionContainer>
      <ModifyDateContainer>
        <ModifiedDate />
      </ModifyDateContainer>
      <MusicCountContainer>
        <TrackCount />
      </MusicCountContainer>
      <EditContainer></EditContainer>
    </Header>
  );
};
const Header = styled.div`
  width: 100%;
  display: flex;
  padding: 0 30px 10px;

  border-bottom: 1px solid ${Color.Ac_Gray};
`;
const Container = styled.div`
  display: flex;
  align-items: center;

  font-size: 14px;
  font-weight: bold;
  color: ${Color.Ac_Gray0};
`;

const PlayListNameContainer = styled(Container)`
  flex: 3;
  padding-right: 5rem;
`;

const MusicCountContainer = styled(Container)`
  flex: 1;
  display: none;
  @media ${DESKTOP_MODE} {
    display: flex;
  }
`;

const PlayListDescriptionContainer = styled(Container)`
  flex: 1;
  display: none;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 2rem;
  @media ${WIDE_MODE} {
    display: flex;
  }
`;

const ModifyDateContainer = styled(Container)`
  flex: 1;
`;

const EditContainer = styled(Container)`
  flex: 0 0 45px;
  display: none;
  @media ${WIDE_MODE} {
    display: flex;
  }
`;
