import { TRACK_COMPANY_IDS } from "Constants/CompanyIds";
import { useCookies } from "Lib/cookie";
import { MetdataKind } from "Models/GenreModel/GenreModel";

const METADATA_TYPE = "METADATA_TYPE_V2";

export enum TypeMetadataSubclass {
  Track = "track",
  Effect = "effect"
}

export type MetadataType = {
  subClass: TypeMetadataSubclass;
  kind: MetdataKind;
  companyIds: string[];
};

type ReturnType = {
  readonly metadataType: MetadataType;
  readonly set: (isInfo: MetadataType) => void;
  readonly clear: () => void;
};

export const useSubclass = (): ReturnType => {
  const [cookies, setCookie, removeCookie] = useCookies([METADATA_TYPE]);
  const defaultMetadataType: MetadataType = {
    subClass: TypeMetadataSubclass.Track,
    kind: MetdataKind.Track,
    companyIds: TRACK_COMPANY_IDS
  };
  const metadataType = cookies[METADATA_TYPE] ? (cookies[METADATA_TYPE] as MetadataType) : defaultMetadataType;
  const set = (isInfo: MetadataType): void => setCookie(METADATA_TYPE, isInfo, { path: "/" });
  const clear = (): void => removeCookie(METADATA_TYPE, { path: "/" });
  return { metadataType, set, clear };
};
