import { Button, ButtonColor, ButtonSize } from "App/Atomics/Button";
import { Icon } from "App/Atomics/Icon";
import { ModalAction, ModalStore, ModalType } from "App/Store/ModalStore";
import { usePlaylistNode } from "App/Store/PlaylistNodeStore";
import { Color } from "Constants/Color";
import { AllPlay, DownloadCsv, FullDownload } from "Constants/Locale";
import { DESKTOP_MODE, WIDE_MODE, X_WIDE_MODE } from "Constants/Size";
import { PlaylistID } from "Models/PlaylistModel";
import styled from "styled-components";
import { Player } from "Utils/artistscardPlayer";
import { usePlaylistDetailSelector } from "../Store";

type Props = {
  readonly id: PlaylistID;
};

export const PlaylistInformation = ({ id }: Props) => {
  const playlist = usePlaylistNode(id);
  const isLoadedPlaylistTrack = usePlaylistDetailSelector(store => store.isLoadedPlaylistTrack);
  const trackList = playlist?.trackList.map(({ id }) => id) ?? [];

  const onPlay = () => {
    if (!trackList?.length) {
      return;
    }
    Player.insertListToLast(trackList);
    Player.current(trackList[0]);
  };

  const openModifyPlaylist = () => {
    ModalStore.dispatch(
      ModalAction.open(ModalType.MODIFY_PLAYLIST, {
        playlist: id
      })
    );
  };

  const openTrackDownloadModal = () => {
    ModalStore.dispatch(ModalAction.open(ModalType.TRACKS_DOWNLOAD, { tracks: trackList }));
  };

  const openCsvDownloadModal = () => {
    ModalStore.dispatch(ModalAction.open(ModalType.CSV_DOWNLOAD, { trackItems: playlist?.trackList }));
  };

  return (
    <Layout>
      <CenterBox>
        <Title>{playlist?.title}</Title>

        <EditButton
          onClick={e => {
            e.stopPropagation();
            openModifyPlaylist();
          }}
        >
          <CustomIcon name="change-pen" />
        </EditButton>

        <Description>{playlist?.desciprtion}</Description>

        <ButtonGroup>
          {isLoadedPlaylistTrack ? (
            <CustomButton color={ButtonColor.PrimaryNotFill} size={ButtonSize.Xsmall} onClick={openTrackDownloadModal}>
              <ButtonText>
                <FullDownload />
              </ButtonText>
            </CustomButton>
          ) : (
            <CustomButton color={ButtonColor.Disable} size={ButtonSize.Xsmall}>
              <ButtonText>
                <Loading name="loading" />
              </ButtonText>
            </CustomButton>
          )}
          {isLoadedPlaylistTrack ? (
            <CustomButton color={ButtonColor.Primary} size={ButtonSize.Xsmall} onClick={onPlay}>
              <ButtonText>
                <AllPlay />
              </ButtonText>
            </CustomButton>
          ) : (
            <CustomButton color={ButtonColor.Disable} size={ButtonSize.Xsmall}>
              <ButtonText>
                <Loading name="loading" />
              </ButtonText>
            </CustomButton>
          )}

          {isLoadedPlaylistTrack ? (
            <CustomButton color={ButtonColor.PrimaryNotFill} size={ButtonSize.Xsmall} onClick={openCsvDownloadModal}>
              <ButtonText>
                <DownloadCsv />
              </ButtonText>
            </CustomButton>
          ) : (
            <CustomButton color={ButtonColor.Disable} size={ButtonSize.Xsmall}>
              <ButtonText>
                <Loading name="loading" />
              </ButtonText>
            </CustomButton>
          )}
        </ButtonGroup>
      </CenterBox>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${DESKTOP_MODE} {
    padding: 50px;
  }

  @media ${WIDE_MODE} {
    padding: 60px;
  }

  @media ${X_WIDE_MODE} {
    padding: 70px;
  }

  padding: 30px;

  position: relative;
`;

const CenterBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
`;

const Title = styled.h2`
  font-size: 3rem;
  margin-bottom: 1.25rem;
  white-space: normal;
  text-align: center;
`;

const Description = styled.p`
  font-size: 1rem;
  color: ${Color.Ac_Gray1};
  margin-bottom: 20px;
  white-space: normal;
  word-break: break-all;
  text-align: center;
  cursor: pointer;
`;

const CustomButton = styled(Button)`
  height: 40px;
  border-radius: 1.5rem;
  border: none;
`;

const ButtonText = styled.span`
  font-size: 0.75rem;
  margin: 0 0.5rem;
  line-height: 1;
  font-weight: bold;
`;
const ButtonGroup = styled.div`
  margin-bottom: 1rem;
  display: flex;
  gap: 1rem;
`;
const EditButton = styled.div`
  cursor: pointer;
`;

const CustomIcon = styled(Icon)`
  width: 2rem;
  height: 2rem;

  &:hover {
    path {
      fill: ${Color.Ac_White};
    }
    rect {
      fill: ${Color.Ac_White};
    }
  }
  path {
    fill: ${Color.Ac_Gray1};
  }
  rect {
    fill: ${Color.Ac_Gray1};
  }
`;

const Loading = styled(Icon)`
  width: 1rem;
  height: 1rem;
`;
