import { createStore } from "Lib/store";
import { AccountModel, GUEST_ACCOUNT } from "Models/AccountModel/AccountModel";
import { CartModel } from "Models/CartModel";
import { WalletModel } from "Models/WalletModal";

const createInitialState = () => ({
  current: GUEST_ACCOUNT,
  wallet: null as WalletModel | null,
  cart: null as CartModel | null,
  cartCount: 0
});

export const {
  useSelector: useAccountSelector,
  action: AccountAction,
  ...AccountStore
} = createStore("Account", createInitialState, {
  clear(state) {
    state.current = GUEST_ACCOUNT;
    state.wallet = null;
    state.cart = null;
  },
  current(state, account: AccountModel) {
    state.current = account;
  },
  wallet(state, wallet: WalletModel) {
    state.wallet = wallet;
  },
  walletClear(state) {
    state.wallet = null;
  },
  CartClear(state) {
    state.cart = null;
  },
  setCart(state, cart: CartModel) {
    state.cart = cart;
  },
  setCartCount(state, cartCount: number) {
    state.cartCount = cartCount;
  }
});
