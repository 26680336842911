import { ID } from "Utils/id";

export type ReadonlyNodeMap<Key extends ID, Node> = {
  readonly count: () => number;
  readonly has: (id: Key) => boolean;
  readonly get: (id: Key) => null | Node;
  readonly set: (id: Key, node: Node) => void;
  readonly map: <T>(mapper: (node: Node) => T) => readonly T[];
  readonly filter: (predicate: (node: Node) => boolean) => readonly Node[];
  readonly filterMap: <T>(predicate: (node: Node) => boolean, mapper: (node: Node) => T) => readonly T[];
  readonly mapFilter: <T>(mapper: (node: Node) => T, predicate: (item: T) => boolean) => readonly T[];
  readonly forEach: (fn: (node: Node) => void) => void;
  readonly keys: () => readonly Key[];
  readonly values: () => readonly Node[];
};

export type NodeMap<Key extends ID, Node> = ReadonlyNodeMap<Key, Node> & {
  readonly remove: (key: Key) => void;
  readonly clear: () => void;
};

export const createNodeMap = <Key extends ID, Node>(): NodeMap<Key, Node> => {
  const keyMap = new Map<string, Key>();
  const nodeMap = new Map<string, Node>();

  const count = (): number => keyMap.size;
  const has = (id: Key): boolean => keyMap.has(id.key);
  const get = (id: Key): null | Node => nodeMap.get(id.key) ?? null;
  const set = (id: Key, node: Node): void => {
    keyMap.set(id.key, id);
    nodeMap.set(id.key, node);
  };
  const map = <T>(mapper: (node: Node) => T): readonly T[] => {
    const itemList: T[] = [];
    for (const node of nodeMap.values()) {
      itemList.push(mapper(node));
    }
    return itemList;
  };
  const filter = (predicate: (node: Node) => boolean): readonly Node[] => {
    const itemList: Node[] = [];
    for (const node of nodeMap.values()) {
      if (predicate(node)) {
        itemList.push(node);
      }
    }
    return itemList;
  };
  const filterMap = <T>(predicate: (node: Node) => boolean, mapper: (node: Node) => T): readonly T[] => {
    const itemList: T[] = [];
    for (const node of nodeMap.values()) {
      if (predicate(node)) {
        itemList.push(mapper(node));
      }
    }
    return itemList;
  };
  const mapFilter = <T>(mapper: (node: Node) => T, predicate: (item: T) => boolean): readonly T[] => {
    const itemList: T[] = [];
    for (const node of nodeMap.values()) {
      const item = mapper(node);
      if (predicate(item)) {
        itemList.push(item);
      }
    }
    return itemList;
  };
  const forEach = (fn: (node: Node) => void): void => {
    for (const key of keyMap.keys()) {
      fn(nodeMap.get(key)!);
    }
  };
  const remove = (id: Key): void => {
    keyMap.delete(id.key);
    nodeMap.delete(id.key);
  };
  const clear = (): void => {
    keyMap.clear();
    nodeMap.clear();
  };
  const keys = (): readonly Key[] => Array.from(keyMap.values());
  const values = (): readonly Node[] => Array.from(nodeMap.values());

  return {
    count,
    has,
    get,
    set,
    remove,
    clear,
    filter,
    filterMap,
    mapFilter,
    forEach,
    map,
    keys,
    values
  };
};
