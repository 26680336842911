import { ReactElement } from "react";
import { useParams } from "Lib/use-router";
import styled from "styled-components";
import { PlaylistInformation } from "../PlaylistInformation";
import { PlaylistID } from "Models/PlaylistModel";
import { PlaylistInformationContentLoader } from "App/Features/ContentLoaders/PlaylistInformationContentLoader";
import { useMyPlaylistDetail } from "../hooks/useMyPlaylistDetail";
import { PlayLists } from "../PlayLists";
import { usePlaylistDetailSelector } from "../Store";

export const Form = (): ReactElement => {
  const { id } = useParams();
  const playlistId = new PlaylistID(+id!);
  useMyPlaylistDetail({ id: +id! });
  const isLoadedPlaylist = usePlaylistDetailSelector(store => store.isLoadedPlaylist);

  return (
    <Layout>
      {isLoadedPlaylist ? <PlaylistInformation id={playlistId} /> : <PlaylistInformationContentLoader />}
      <PlayLists id={playlistId} />
    </Layout>
  );
};

const Layout = styled.div`
  position: relative;
`;
