import { Curation } from "Constants/Locale";
import styled from "styled-components";

export const Header = () => {
  return (
    <Layout>
      <HeaderLeft>
        <Title>
          <Curation />
        </Title>
      </HeaderLeft>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.h2`
  font-size: 1.25rem;
`;
