import { Button, ButtonColor, ButtonSize } from "App/Atomics/Button";
import { Icon } from "App/Atomics/Icon";
import { ModalAction, ModalStore, ModalType } from "App/Store/ModalStore";
import { AddPlaylist, Playlist } from "Constants/Locale";
import styled from "styled-components";

export const Header = () => {
  const openPlaylist = () => {
    ModalStore.dispatch(ModalAction.open(ModalType.ADD_PLAYLIST));
  };

  return (
    <Layout>
      <Container>
        <TitleContainer>
          <Title>
            <Playlist />
          </Title>
        </TitleContainer>
        <SelectButtonContainer>
          <SelectButton color={ButtonColor.Basic} size={ButtonSize.Xsmall} onClick={openPlaylist}>
            <SelectText>
              <AddPlaylist />
            </SelectText>
            <PlusIcon name="plus" />
          </SelectButton>
        </SelectButtonContainer>
      </Container>
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 30px;
`;

const TitleContainer = styled.div``;
const SelectButtonContainer = styled.div``;

const Title = styled.h2`
  font-size: 1.25rem;
`;

const SelectButton = styled(Button)`
  border-radius: 2rem;
`;

const SelectText = styled.span`
  font-size: 0.75rem;
  line-height: 1;
  font-weight: bold;
`;

const PlusIcon = styled(Icon)`
  width: 0.75rem;
  height: 0.75rem;
  margin-left: 0.25rem;
`;
