import { RangeSlider } from "App/Atomics/RangeSlider";
import { Color } from "Constants/Color";
import { UppercaseBpm } from "Constants/Locale";
import { useSearch } from "Lib/use-search-query";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { MenuItem } from "../MenuItem";

export const BpmMenu = () => {
  const { query, setQuery, removeAllQuery } = useSearch();
  const bpmParam = query.get("bpm");
  const defaultBpm = bpmParam ? bpmParam.split("-").map(Number) : [0, 300];

  const [bpm, setBpm] = useState<number[]>(defaultBpm);

  useEffect(() => {
    if (!bpmParam) {
      setBpm([0, 300]);
      return;
    }
    const firstBpm = bpmParam[0] ? bpm[0] : 0;
    const secondBpm = bpmParam[1] ? bpm[1] : 300;

    setBpm([firstBpm, secondBpm]);
  }, [bpmParam]);

  const setQueryValue = (key: string, value: string | null) => {
    if (!value) {
      removeAllQuery(key);
      return;
    }

    setQuery(key, value);
  };

  const setQueryBpm = (value: string | null) => setQueryValue("bpm", value);

  const firstBpm = bpm[0] !== 0 ? bpm[0].toString() : "";
  const secondBpm = bpm[1] !== 300 ? bpm[1].toString() : "";

  const subName = (firstBpm || secondBpm) && [firstBpm, secondBpm].join("-");

  return (
    <MenuItem name={UppercaseBpm.message()} subName={subName}>
      <RangeSlider value={bpm ?? [0, 300]} min={0} max={300} defaultValue={defaultBpm} onChange={setBpm} onAfterChange={setQueryBpm} />
      <RangeCountContainer>
        <RangeCount>0</RangeCount>
        <RangeCount>300</RangeCount>
      </RangeCountContainer>
    </MenuItem>
  );
};

const RangeCountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`;

const RangeCount = styled.span`
  color: ${Color.Ac_Gray0};
  font-size: 14px;
`;
