import { Dropdown, DropdownList } from "App/Atomics/Dropdown";
import { Icon } from "App/Atomics/Icon";
import { Color } from "Constants/Color";
import styled from "styled-components";
import { Button, ButtonColor, ButtonSize } from "App/Atomics/Button";
import { useAccountSelector } from "App/Store/AccountStore";
import { isGuestAccountModel } from "Models/AccountModel/AccountModel";
import { ModalAction, ModalStore, ModalType } from "App/Store/ModalStore";
import { AddToCart, GetLicense, License, Remove } from "Constants/Locale";
import { TrackID } from "Models/TrackModel";
import { useAddCartItem } from "GraphQL/Mutations/useAddCartItem";
import { useRemoveCartItem } from "GraphQL/Mutations/useRemoveCartItem";
import { useTrackNode } from "App/Store/TrackNodeStore";
import { useTrackActivityNodeStore } from "App/Store/TrackActivity";
import { TrackActivityType } from "GraphQL/Schema";
import { Toast } from "Lib/toast";
import { LicensePrice } from "./LicensePrice";

type Props = {
  trackId: TrackID;
};

export const BuyButtonInfo = ({ trackId }: Props) => {
  const { addCartItem, isLoaded: isLoadedAddCart } = useAddCartItem();
  const { removeCartItem, isLoaded: isLoadedRemoveCart } = useRemoveCartItem({});

  const track = useTrackNode(trackId);

  const activity = useTrackActivityNodeStore(store => store.values()).filter(activity => activity.track.raw === trackId?.raw);

  const cartActivity = activity.find(({ type }) => type === TrackActivityType.Cart);
  const purhcaseActivity = activity.find(({ type }) => type === TrackActivityType.Purchase);

  const isGuest = useAccountSelector(store => isGuestAccountModel(store.current));

  const openLicenseModal = (trackId: TrackID) => ModalStore.dispatch(ModalAction.open(ModalType.LICENSE, { license: { track: trackId } }));
  const openToSignin = () => ModalStore.dispatch(ModalAction.replace(ModalType.SIGNIN));

  const addCart = async (id: TrackID) => {
    if (isGuest) {
      openToSignin();
      return;
    }
    if (!isLoadedAddCart) {
      return;
    }
    try {
      await addCartItem({ variables: { trackId: id.raw } });
    } catch (error) {
      Toast.warning("이미 장바구니에 담긴 트랙입니다");
    }
  };

  const tryToRemoveCartItem = async (id: TrackID) => {
    if (isGuest) {
      openToSignin();
      return;
    }
    if (!isLoadedRemoveCart) {
      return;
    }
    try {
      await removeCartItem({
        variables: {
          trackId: id.raw
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  if (!track) return null;

  return (
    <Dropdown.Layout
      trigger="mouseenter focus"
      placement={"top"}
      content={
        <MenuWrap>
          <ProductPriceLayout>
            <HeaderLayout>
              <Header>
                <License />
              </Header>
            </HeaderLayout>
            <LicensePrice grade={track?.grade!} fileRule={track?.fileRule!} />
          </ProductPriceLayout>
        </MenuWrap>
      }
    >
      <Layout>
        {!purhcaseActivity && (
          <>
            <CartButton
              color={ButtonColor.Transparent}
              size={ButtonSize.Xsmall}
              onClick={() => (!cartActivity ? addCart(trackId!) : tryToRemoveCartItem(trackId!))}
            >
              <CustomIcon name={cartActivity ? "close" : "cart"} />
              <ButtonText>{!cartActivity ? <AddToCart /> : <Remove />}</ButtonText>
            </CartButton>
          </>
        )}

        {purhcaseActivity && (
          <BuyButton color={ButtonColor.Primary} size={ButtonSize.Xsmall} onClick={() => openLicenseModal(trackId!)}>
            <CustomIcon name="dropdown-down" />
            <ButtonText>
              <GetLicense />
            </ButtonText>
          </BuyButton>
        )}
      </Layout>
    </Dropdown.Layout>
  );
};

const Layout = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

const MenuWrap = styled(DropdownList)`
  padding: 0;
  background-color: ${Color.Ac_Shadow};
  overflow: hidden;
`;

const HeaderLayout = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${Color.Ac_Charcoal};
  padding: 0.5rem 1rem;
`;

const Header = styled.span`
  font-size: 0.8rem;
  font-weight: bold;
`;

const ProductPriceLayout = styled.div`
  width: 300px;
`;

const CartButton = styled(Button)`
  height: 40px;
  border-radius: 1.5rem;
  width: 100%;
`;

const BuyButton = styled(Button)`
  height: 40px;
  border-radius: 1.5rem;
  width: 100%;
`;

const CustomIcon = styled(Icon)`
  width: 1.2rem;

  path {
    fill: ${Color.Ac_White};
  }
`;

const ButtonText = styled.span`
  font-size: 0.75rem;
  margin: 0 0.5rem;
  line-height: 1;
  font-weight: bold;
`;
