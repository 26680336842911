import { TrackActivityNodeStore } from "App/Store/TrackActivity";
import { TrackActivity } from "GraphQL/Schema";
import { TrackActivityID, TrackActivityModel } from "Models/TrackActivity";
import { TrackID } from "Models/TrackModel";

type Props = {
  readonly trackActivityList: TrackActivity[];
};

export const setTrackActivity = ({ trackActivityList }: Props) => {
  for (const trackActivity of trackActivityList) {
    const TrackActivityM = TrackActivityModel.form({
      id: trackActivity.id,
      track: new TrackID(trackActivity.track_id),
      type: trackActivity.type,
      createAt: trackActivity.created_at
    });
    TrackActivityNodeStore.set(new TrackActivityID(trackActivity.id), TrackActivityM);
  }
};
