import { TRACK_FRAGMENT } from "GraphQL/Fragment/TrackFragment";
import { PlaylistItem } from "GraphQL/Schema";
import { gql, useMutation } from "Lib/apollo-client";
import { Toast } from "Lib/toast";
import { useEffect } from "react";

const ADD_PLAYLIST_TRACK = gql`
  ${TRACK_FRAGMENT}
  mutation ADD_PLAYLIST_TRACK($playlistId: Int!, $trackId: Int!) {
    addPlaylistTrack: push_playlist_item(playlist_id: $playlistId, track_id: $trackId) {
      id
      track_id
      playlist_id
      track {
        ...TrackFragment
      }
    }
  }
`;

type Result = {
  readonly addPlaylistTrack: PlaylistItem;
};

type Args = {
  readonly playlistId: number;
  readonly trackId?: number;
};

export const useNoCacheAddPlaylistTrack = () => {
  const [fetch, { loading, error }] = useMutation<Result, Args>(ADD_PLAYLIST_TRACK);

  useEffect(() => {
    if (error) {
      if (error?.graphQLErrors[0]?.message === "is already purchased curation") {
        Toast.error("환불 후 수정해주세요.");
      }
    }
  }, [error]);

  return { addPlaylistTrack: fetch, loading };
};
