import { Lottie } from "Lib/lottie";
import styled from "styled-components";
import LoadingLottie from "Assets/Lottie/loading.json";
import { CurationShareEmail, NoShareCuration } from "Constants/Locale";
import { CurationManagementID } from "Models/CurationManagementModel";
import { CurationItem } from "App/Features/Items/CurationItem";
import { Icon } from "App/Atomics/Icon";

interface Props {
  readonly isLoadedCuration: boolean;
  readonly isLoadedMoreCuration: boolean;
  readonly curationList: CurationManagementID[];
}

export const CurationList = ({ curationList, isLoadedCuration, isLoadedMoreCuration }: Props) => {
  return (
    <>
      {!isLoadedCuration && (
        <LoadingContainer>
          <Lottie data={LoadingLottie} loop playing />
        </LoadingContainer>
      )}

      {isLoadedCuration && !curationList.length && (
        <NotFoundContainer>
          <NotFoundIcon name="search" />
          <NotFound>
            <NoShareCuration />
            <br />
            <CurationShareEmail />
          </NotFound>
        </NotFoundContainer>
      )}

      {curationList.map(id => (
        <CurationItem key={id.key} id={id} />
      ))}

      {!isLoadedMoreCuration && (
        <LoadingContainer>
          <Lottie data={LoadingLottie} loop playing />
        </LoadingContainer>
      )}
    </>
  );
};

const NotFoundContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 10rem 0;
`;

const NotFound = styled.span`
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 1rem;
  text-align: center;
  white-space: normal;
`;

const NotFoundIcon = styled(Icon)`
  width: 2.5em;
  height: 2.5rem;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 150px;
`;
