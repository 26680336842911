import { Edge } from "Constants/Edge";
import styled from "styled-components";
import { HistoryPointContent } from "./HistoryPointContent";
import { HistoryPointFooter } from "./HistoryPointFooter";
import { HistoryPointHeader } from "./HistoryPointHeader";

type Props = {
  readonly edge: Edge | null;
};

export const Form = ({ edge }: Props) => {
  return (
    <Layout>
      <Scroll>
        <Table>
          <HistoryPointHeader />
          <HistoryPointContent />
        </Table>
      </Scroll>
      <HistoryPointFooter edge={edge} />
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
  height: 100%;
`;

const Scroll = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;
