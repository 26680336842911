import { Button, ButtonColor, ButtonSize } from "App/Atomics/Button";
import { Input } from "App/Atomics/Input";
import { ADMIN_PRICE_CREATE } from "Constants/Routes";
import { Size } from "Constants/Size";
import { TypeMetadataSubclass } from "GraphQL/Schema";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { SelectStyle } from "../../CechargePoint/Style";

const Layout = styled.div`
  margin: 1rem ${Size.MARGIN_LARGE_PX};

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Left = styled.div`
  display: flex;
  button {
    margin-right: 1rem;
  }
`;

const Right = styled.div``;

const CustomInput = styled(Input.TextSelect)`
  width: 150px;
`;

const metadataSubClassOption = [
  { id: TypeMetadataSubclass.Bgm, name: "BGM" },
  { id: TypeMetadataSubclass.Effect, name: "EFFECT" }
];

export const NavigationTab = () => {
  const navigate = useNavigate();

  const moveToPriceCreate = () => navigate(ADMIN_PRICE_CREATE);
  const [searchParams, setSearchParams] = useSearchParams();
  const target = (searchParams.get("target") as TypeMetadataSubclass) ?? TypeMetadataSubclass.Bgm;
  const onChangeServiceOption = ({ target }: { target: TypeMetadataSubclass }) => {
    setSearchParams({ target });
  };

  return (
    <Layout>
      <Left>
        <Button size={ButtonSize.Xsmall} color={ButtonColor.PrimaryNotFill} onClick={moveToPriceCreate}>
          금액 추가
        </Button>
      </Left>
      <Right>
        <CustomInput
          isDisabled={false}
          style={SelectStyle}
          optionList={metadataSubClassOption}
          defaultValue={metadataSubClassOption.find(({ id }) => id === target)}
          placeholder="종류"
          onChange={info => info && onChangeServiceOption({ target: info.id as TypeMetadataSubclass })}
        />
      </Right>
    </Layout>
  );
};
