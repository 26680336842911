import { ProductPriceNodeStore } from "App/Store/ProductPriceNodeStore";
import { ProductPrice } from "GraphQL/Schema";
import { ProductPriceID, ProductPriceModel } from "Models/ProductPriceModel";

export const setProductPrice = (productPriceList: ProductPrice[]) => {
  for (const productPrice of productPriceList) {
    const productPriceM = ProductPriceModel.form({
      id: productPrice.id,
      price: productPrice.price,
      target: productPrice.target,
      title: productPrice.title,
      description: productPrice.description ?? "-",
      createAt: productPrice.created_at,
      isApprove: productPrice.is_approve,
      type: productPrice.type,
      updateAt: productPrice.updated_at
    });

    ProductPriceNodeStore.set(new ProductPriceID(productPrice.id), productPriceM);
  }
};
