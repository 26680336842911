import { useProductPriceNodeStore } from "App/Store/ProductPriceNodeStore";
import styled from "styled-components";
import { PriceManagementItem } from "./PriceManagementItem";

export const PriceContent = () => {
  const priceManagementList = useProductPriceNodeStore(store => store.keys());
  return (
    <Layout>
      {priceManagementList.map(id => (
        <PriceManagementItem key={id.key} id={id} />
      ))}
    </Layout>
  );
};

const Layout = styled.tbody``;
