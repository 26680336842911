import { NumericID } from "Utils/id";
import { PlaylistID } from "./PlaylistModel";
import { TrackID } from "./TrackModel";

export class PlaylistItemID extends NumericID {
  constructor(id: number) {
    super("playlistItem", id);
  }
}

export type PlaylistItemPlayload = {
  readonly id: number;
  readonly playlist: PlaylistID;
  readonly track: TrackID;
};

export class PlaylistItemModel {
  static form({ id, playlist, track }: PlaylistItemPlayload) {
    return new PlaylistItemModel(new PlaylistItemID(id), playlist, track);
  }

  constructor(readonly id: PlaylistItemID, readonly playlist: PlaylistID, readonly track: TrackID) {}
}
