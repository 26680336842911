import { setPurchaseItem } from "App/Common/setPurchaseItem";
import { MAX_FIRST_COUNT } from "Constants/Count";
import { useLoadPurchaseItem } from "GraphQL/Queries/useLoadPurchaseItem";
import { useLoaded } from "Lib/use-loaded";
import { useEffect } from "react";

export const usePurchaseItemDetail = (id: number) => {
  const { data, loading } = useLoadPurchaseItem({
    first: MAX_FIRST_COUNT,
    where: {
      purchase_history_id: id
    }
  });

  const { isLoaded } = useLoaded(loading);

  useEffect(() => {
    if (data) {
      const { purchaseItem } = data;
      setPurchaseItem(purchaseItem);
    }
  }, [data]);

  return { isLoaded };
};
