import { EdgePagination } from "App/Molecules/EdgePagination";
import { Edge } from "Constants/Edge";
import { useSearch } from "Lib/use-search-query";
import styled from "styled-components";

type Props = {
  readonly edge: Edge | null;
};

export const LicenseFooter = ({ edge }: Props) => {
  const { setQuery } = useSearch();

  const goTo = (page: number) => {
    window.scrollTo({ top: 0 });
    setQuery("page", page.toString());
  };

  return <Layout>{edge && <EdgePagination edge={edge} goTo={goTo} />}</Layout>;
};

const Layout = styled.div`
  width: 100%;
  margin: 5rem 0;
  display: flex;
  justify-content: center;
`;
