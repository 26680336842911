import { Edge } from "Constants/Edge";
import { Aggregate, Metadata } from "GraphQL/Schema";
import { gql, useQuery } from "Lib/apollo-client";

const LOAD_METADATAS_URL = gql`
  query LOAD_METADATA_URL($ids: [ID!]) {
    metadata(where: { metadata_id__in: $ids }, first: 999) {
      metadata_id
      title
      metadata_url(where: { valid_check: V3 }) {
        type_url
        url
      }
    }
  }
`;

type Result = {
  readonly metadata: Metadata[];
  readonly edge: Edge;
  readonly getCount: Aggregate;
};

type Args = {
  readonly ids?: number[];
  readonly skip?: boolean;
};

export const useLoadMetadatasUrl = ({ ids, skip }: Args) => {
  const { data, loading } = useQuery<Result, Args>(LOAD_METADATAS_URL, {
    variables: {
      ids: ids
    },
    fetchPolicy: "network-only",
    skip
  });

  return { loading, data };
};
