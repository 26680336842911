import { CheckAdmin } from "App/Atomics/CheckAdmin";
import { CheckTier } from "App/Atomics/CheckTier";
import { RequesetAuth } from "App/Atomics/RequireAuth";
import { UnnecessaryAuth } from "App/Atomics/UnnecessaryAuth";
import {
  ACCOUNT,
  ADMIN_CECHARGE_POINT,
  ADMIN_CURATION,
  ADMIN_CURATION_CREATE,
  ADMIN_CURATION_EDIT,
  ADMIN_CURATION_MANAGEMENT,
  ADMIN_CURATION_TRACK,
  ADMIN_CURATION_USER,
  ADMIN_GRADE,
  ADMIN_GRADE_EDIT,
  ADMIN_HISTORY_POINT,
  ADMIN_HISTORY_POINT_EDIT,
  ADMIN_HOME,
  ADMIN_LICENSE,
  ADMIN_PRICE,
  ADMIN_PRICE_CREATE,
  ADMIN_PRICE_EDIT,
  ADMIN_STAFF_CREATE,
  ADMIN_SYNCHRONIZATION,
  ADMIN_UPDATE_DURATION,
  ADMIN_USER,
  ADMIN_WITHDRAWAL_POINT,
  ARTIST_DETAIL,
  BUY_POINT_CENCEL,
  BUY_POINT_FAIL,
  BUY_POINT_ORDER,
  BUY_POINT_SUCCESS,
  CART,
  COOKIE_POLICY,
  CURATION_DETAIL,
  FIND_PASSWORD,
  HOME,
  LEGAL_NOTICE,
  LIBRARY,
  LIBRARY_CURATION,
  LIBRARY_FAVORITES,
  LIBRARY_PLAYLIST,
  LIBRARY_PURCHASE_HISTORY,
  LIBRARY_PURCHASE_HISTORY_DETAIL,
  LIBRARY_PURCHASE_TRACK,
  LICENSE,
  MY,
  MY_CURATION,
  MY_FAVORITES,
  MY_PLAYLIST,
  NOT_FOUND,
  PAYMENT_COMPLETE,
  PLAYLIST_DETAIL,
  POINT_CHARGE,
  PRIVACY_POLICY,
  SIGN_FAIL,
  SIGN_RESET_FAIL,
  SIGN_RESET_PASSWRD,
  SIGN_SUCCESS,
  SUPPORT,
  TERM_OF_SERVICE,
  TRACK_DETAIL,
  VERIFY
} from "Constants/Routes";
import { Navigate, Route, Routes } from "react-router";
import { Account } from "./Account";
import {
  AdminCechargePoint,
  AdminCuration,
  AdminCurationCreate,
  AdminCurationEdit,
  AdminCurationTrack,
  AdminCurationUser,
  AdminDurationUpdate,
  AdminGrade,
  AdminGradeEdit,
  AdminHistoryPoint,
  AdminHistoryPointEdit,
  AdminHome,
  AdminLicense,
  AdminPrice,
  AdminPriceCreate,
  AdminPriceEdit,
  AdminStaffCreate,
  AdminUser,
  AdminWithdrawalPoint
} from "./Admin";
import { CurationManagement } from "./Admin/CurationManagement";
import { Synchronization } from "./Admin/Synchronization";
import { ArtistDetail } from "./ArtistDetail";
import { BuyCancelRedirect } from "./BuyCancelRedirect";
import { BuyFailRedirect } from "./BuyFailRedirect";
import { BuySuccessRedirect } from "./BuySuccessRedirect";
import { Cart } from "./Cart";
import { CookiePolicy } from "./CookiePolicy";
import { CurationDetail } from "./CurationDetail";
import { Home } from "./Home";
import { LegalNotice } from "./LegalNotice";
import { Library } from "./Library";
import { LibraryCuration } from "./Library/Form/LibraryCuration";
import { LibraryFavorites } from "./Library/Form/LibraryFavorites";
import { LibraryPlaylist } from "./Library/Form/LibraryPlayList";
import { PurchaseHistory } from "./Library/Form/PurchaseHistory";
import { PurchaseHistoryDetail } from "./Library/Form/PurchaseHistoryDetail";
import { PurchaseTrack as LibraryPurchaseTrack } from "./Library/Form/PurchaseTrack";
import { License } from "./License";
import { My } from "./My";
import { MyCuration } from "./My/MyCuration";
import { MyFavorites } from "./My/MyFavorites";
import { MyPlayList } from "./My/MyPlayList";
import { NotFound } from "./NotFound";
import { PaymentComplete } from "./PaymentComplete";
import { PayOrder } from "./PayOrder";
import { PlayListDetail } from "./PlayListDetail";
import { PointCharge } from "./PointCharge";
import { PrivacyPolicy } from "./PrivacyPolicy";
import { SignIn } from "./SignIn";
import { FailForm } from "./SignIn/Form/FailForm";
import { FindPasswordForm } from "./SignIn/Form/FindPasswordForm";
import { ResetPasswordForm } from "./SignIn/Form/ResetPasswordForm";
import { SuccessForm } from "./SignIn/Form/SuccessForm";
import { VerifyForm } from "./SignIn/Form/VerifyForm";
import { Support } from "./Support";
import { TermOfService } from "./TermOfService";
import { TrackDetail } from "./TrackDetail";

export const Pages = () => {
  return (
    <Routes>
      <Route path={HOME} element={<Home />} />
      <Route path={SUPPORT} element={<Support />} />
      <Route path={LICENSE} element={<License />} />
      <Route path={TRACK_DETAIL} element={<TrackDetail />} />
      <Route path={ARTIST_DETAIL} element={<ArtistDetail />} />
      <Route
        path={ACCOUNT}
        element={
          <RequesetAuth>
            <Account />
          </RequesetAuth>
        }
      />
      <Route
        path={CART}
        element={
          <RequesetAuth>
            <Cart />
          </RequesetAuth>
        }
      />
      <Route
        path={CURATION_DETAIL}
        element={
          <RequesetAuth>
            <CurationDetail />
          </RequesetAuth>
        }
      />
      <Route
        path={PLAYLIST_DETAIL}
        element={
          <RequesetAuth>
            <PlayListDetail />
          </RequesetAuth>
        }
      />
      <Route
        path={PAYMENT_COMPLETE}
        element={
          <RequesetAuth>
            <PaymentComplete />
          </RequesetAuth>
        }
      />
      <Route
        path={BUY_POINT_ORDER}
        element={
          <RequesetAuth>
            <PayOrder />
          </RequesetAuth>
        }
      />
      <Route
        path={POINT_CHARGE}
        element={
          <RequesetAuth>
            <PointCharge />
          </RequesetAuth>
        }
      />
      <Route path={BUY_POINT_SUCCESS} element={<BuySuccessRedirect />} />
      <Route path={BUY_POINT_FAIL} element={<BuyFailRedirect />} />
      <Route path={BUY_POINT_CENCEL} element={<BuyCancelRedirect />} />
      <Route path={PRIVACY_POLICY} element={<PrivacyPolicy />} />
      <Route path={TERM_OF_SERVICE} element={<TermOfService />} />
      <Route path={COOKIE_POLICY} element={<CookiePolicy />} />

      <Route path={LEGAL_NOTICE} element={<LegalNotice />} />
      <Route
        element={
          <UnnecessaryAuth>
            <SignIn />
          </UnnecessaryAuth>
        }
      >
        <Route path={FIND_PASSWORD} element={<FindPasswordForm />}></Route>
        <Route path={VERIFY} element={<VerifyForm />}></Route>
        <Route path={SIGN_SUCCESS} element={<SuccessForm />}></Route>
        <Route path={SIGN_FAIL} element={<FailForm />}></Route>
        <Route path={SIGN_RESET_FAIL} element={<FailForm />}></Route>
        <Route path={SIGN_RESET_PASSWRD} element={<ResetPasswordForm />}></Route>
      </Route>
      <Route
        path={MY}
        element={
          <RequesetAuth>
            <My />
          </RequesetAuth>
        }
      >
        <Route path={MY_FAVORITES} element={<MyFavorites />} />
        <Route path={MY_CURATION} element={<MyCuration />} />
        <Route path={MY_PLAYLIST} element={<MyPlayList />} />
      </Route>
      <Route
        path={LIBRARY}
        element={
          <RequesetAuth>
            <Library />
          </RequesetAuth>
        }
      >
        <Route path={LIBRARY_PURCHASE_HISTORY} element={<PurchaseHistory />} />
        <Route path={LIBRARY_PURCHASE_HISTORY_DETAIL} element={<PurchaseHistoryDetail />} />
        <Route path={LIBRARY_PLAYLIST} element={<LibraryPlaylist />} />

        <Route path={LIBRARY_PURCHASE_TRACK} element={<LibraryPurchaseTrack />} />
        <Route path={LIBRARY_FAVORITES} element={<LibraryFavorites />} />
        <Route path={LIBRARY_CURATION} element={<LibraryCuration />} />
      </Route>
      <Route element={<CheckAdmin />}>
        <Route path={ADMIN_HOME} element={<AdminHome />}></Route>
        <Route path={ADMIN_LICENSE} element={<AdminLicense />}></Route>
        <Route path={ADMIN_CURATION} element={<AdminCuration />}></Route>
        <Route path={ADMIN_CURATION_CREATE} element={<AdminCurationCreate />}></Route>
        <Route path={ADMIN_CURATION_EDIT} element={<AdminCurationEdit />}></Route>
        <Route path={ADMIN_CURATION_TRACK} element={<AdminCurationTrack />}></Route>
        <Route path={ADMIN_CURATION_USER} element={<AdminCurationUser />}></Route>
        <Route path={ADMIN_CURATION_MANAGEMENT} element={<CurationManagement />}></Route>
        <Route path={ADMIN_SYNCHRONIZATION} element={<Synchronization />}></Route>
        <Route element={<CheckTier />}>
          <Route path={ADMIN_CECHARGE_POINT} element={<AdminCechargePoint />}></Route>
          <Route path={ADMIN_STAFF_CREATE} element={<AdminStaffCreate />}></Route>
          <Route path={ADMIN_HISTORY_POINT} element={<AdminHistoryPoint />}></Route>
          <Route path={ADMIN_HISTORY_POINT_EDIT} element={<AdminHistoryPointEdit />}></Route>
          <Route path={ADMIN_PRICE} element={<AdminPrice />}></Route>
          <Route path={ADMIN_GRADE} element={<AdminGrade />}></Route>
          <Route path={ADMIN_GRADE_EDIT} element={<AdminGradeEdit />}></Route>
          <Route path={ADMIN_PRICE_EDIT} element={<AdminPriceEdit />}></Route>
          <Route path={ADMIN_PRICE_CREATE} element={<AdminPriceCreate />}></Route>
          <Route path={ADMIN_USER} element={<AdminUser />}></Route>
          <Route path={ADMIN_WITHDRAWAL_POINT} element={<AdminWithdrawalPoint />}></Route>
          <Route path={ADMIN_UPDATE_DURATION} element={<AdminDurationUpdate />}></Route>
        </Route>
      </Route>
      <Route path={NOT_FOUND} element={<NotFound />} />
      <Route path="*" element={<Navigate to={NOT_FOUND} />} />
    </Routes>
  );
};
