import { Icon } from "App/Atomics/Icon";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { Color } from "Constants/Color";
import { ReactElement } from "react";
import styled from "styled-components";
import { foramtNumberToPriceFormat } from "Utils/format";

type Props = {
  readonly name: string;
  readonly price: number;
  readonly isTotal?: boolean;
};

export const PriceGroup = ({ name, price, isTotal }: Props): ReactElement => {
  return (
    <Layout>
      <Text size={TypoSize.F15} color={!isTotal ? Color.Ac_Gray1 : Color.Ac_White} weight={!isTotal ? TypoWeight.Regular : TypoWeight.Bold}>
        {name}
      </Text>
      <Price weight={TypoWeight.Bold} color={!isTotal ? Color.Ac_White : Color.AC_Carrot}>
        {isTotal && `${"₩"} ${foramtNumberToPriceFormat(price)}`}
        {!isTotal && <CoinIcon name="coin" />}
        {!isTotal && `${foramtNumberToPriceFormat(price)}`}
      </Price>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & + & {
    margin-top: 20px;
  }
`;
const Text = styled(Typography)``;
const Price = styled(Typography)`
  text-align: right;
  display: flex;
  align-items: center;
`;

const CoinIcon = styled(Icon)`
  width: 1.8rem;
  height: 1.8rem;
  margin-left: 0.25rem;
`;
