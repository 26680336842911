import { MAX_BETWEEN, MIN_BETWEEN } from "Constants/Count";
import { useLoadCacheMetadataTrackCount } from "GraphQL/Queries/useLoadCacheMetadataTrackCount";
import { useLoaded } from "Lib/use-loaded";
import { MetdataKind } from "Models/GenreModel/GenreModel";
import { useEffect } from "react";
import { TypeMetadataSubclass, useSubclass } from "Utils/useSubclass";
import { HomeAction, HomeStore } from "../Store";

interface Props {
  readonly genres: string[];
  readonly moods: string[];
  readonly roles: string[];
  readonly duration: string | null;
  readonly bpm: string | null;
  readonly search: string | null;
  readonly page: number;
  readonly isSkip: boolean;
  readonly subclass: TypeMetadataSubclass;
  readonly order: string | null;
  readonly artist?: string;
  readonly companyIds: string[];
}

export const useCacheMetadataCount = ({ genres, moods, roles, duration, bpm, search, subclass, artist, companyIds }: Props) => {
  const { metadataType } = useSubclass();
  const tagSearh = [
    ...genres.map(genre => ({
      metadata_genre_relation__some: {
        genre__some: {
          genre_id: genre
        }
      }
    })),
    ...moods.map(mood => ({
      metadata_genre_relation__some: {
        genre__some: {
          genre_id: mood
        }
      }
    })),
    ...roles.map(role => ({
      metadata_artist_relation__some: {
        role__some: {
          role_id: role
        }
      }
    }))
  ];

  const isLicense = metadataType.kind === MetdataKind.Pd || metadataType.kind === MetdataKind.Ccl;
  const { data, loading } = useLoadCacheMetadataTrackCount({
    where: {
      metadata_artist_relation__some: artist
        ? {
            artist__some: {
              name__contains: artist
            }
          }
        : undefined,
      track_information__some: {
        duration__between: duration ? [+(duration?.split("-")[0] || MIN_BETWEEN), +(duration?.split("-")[1] || MAX_BETWEEN)] : undefined,
        tempo__between: bpm ? [+(bpm?.split("-")[0] || MIN_BETWEEN), +(bpm?.split("-")[1] || MAX_BETWEEN)] : undefined
      },
      type_metadata_class: "record",
      type_metadata_subclass: subclass.toLocaleLowerCase(),
      metadata_company_relation__some: {
        company__some: {
          company_id__in: companyIds.map(companyIds => companyIds.toString())
        }
      },
      track_license__some: isLicense
        ? {
            country_code__in: ["ZZ", "KR"],
            copyright: true,
            neighboring: true
          }
        : undefined,
      metadata_title__some: search
        ? {
            type_metadata_title__in: ["name", "search_genre", "search_role", "search_artist"],
            tsvec_words__search: search
          }
        : undefined,
      AND: tagSearh.length ? tagSearh : undefined
    }
  });
  const { isLoaded } = useLoaded(loading);
  useEffect(() => {
    if (data) {
      const { cacheMetadataTrackCount } = data;
      HomeStore.dispatch(HomeAction.setCount(cacheMetadataTrackCount.count));
    }
  }, [data]);

  return { isLoaded };
};
