import { setUserAccount } from "App/Common/setUserAccount";
import { useAccountSelector } from "App/Store/AccountStore";
import loadingData from "Assets/Lottie/loading.json";
import { useLazeLoadUserInfo } from "GraphQL/Queries/useLazeLoadUserInfo";
import { Lottie } from "Lib/lottie";
import { LOADING, useLoading } from "Lib/use-loading";
import { isGuestAccountModel } from "Models/AccountModel/AccountModel";
import { ReactNode, useEffect } from "react";
import styled from "styled-components";
import { useAsyncEffect } from "use-async-effekt";
import { useAuth } from "Utils/useAuth";

type Props = {
  children: ReactNode;
};

export const AccountManager = ({ children }: Props): JSX.Element => {
  const { loadUserInfo, data } = useLazeLoadUserInfo();
  const account = useAccountSelector(store => store.current);
  const isGuest = isGuestAccountModel(account);
  const auth = useAuth();
  const loadingToggle = useLoading(LOADING);

  useAsyncEffect(async () => {
    if (!auth.userId || !isGuest) {
      loadingToggle.setLoaded();
      return;
    }
    try {
      await loadUserInfo();
    } catch (error) {
      loadingToggle.setLoaded();
    }
  }, []);

  useEffect(() => {
    const userInfo = data?.userInfo;
    if (userInfo) {
      setUserAccount(userInfo);
      loadingToggle.setLoaded();
    }
  }, [data]);

  if (loadingToggle.isLoading) {
    return (
      <LoadingContianer>
        <Loading data={loadingData} loop playing />
      </LoadingContianer>
    );
  }

  return <>{children}</>;
};

const LoadingContianer = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
`;
const Loading = styled(Lottie)`
  width: 200px;
  height: 200px;
`;
