import { WatchQueryFetchPolicy } from "@apollo/client";
import { Edge } from "Constants/Edge";
import { EDGE_FRAGMENT } from "GraphQL/Fragment/EdgeFragment";
import { PLAYLIST_FRAGMENT } from "GraphQL/Fragment/PlaylistFragment";
import { BgmPlaylistWhereInput, Playlist, PlaylistOrderByInput } from "GraphQL/Schema";
import { gql, useQuery } from "Lib/apollo-client";

export const LOAD_PLAYLIST = gql`
  ${EDGE_FRAGMENT}
  ${PLAYLIST_FRAGMENT}
  query LOAD_PLAYLIST($first: Int, $page: Int, $where: BgmPlaylistWhereInput, $orderBy: [PlaylistOrderByInput!]) {
    playlist(first: $first, page: $page, where: $where, orderBy: $orderBy) {
      ...PlaylistFragment
    }

    edge(node: Playlist, where: { playlist: $where }, first: $first, page: $page, orderBy: { playlist: $orderBy }) {
      ...EdgeFragment
    }
  }
`;

type Result = {
  readonly playlist: Playlist[];
  readonly edge: Edge;
};

type Args = {
  readonly first?: number;
  readonly page?: number;
  readonly where?: BgmPlaylistWhereInput;
  readonly skip?: boolean;
  readonly orderBy?: [PlaylistOrderByInput];
  readonly fetchPolicy?: WatchQueryFetchPolicy;
};

export const useLoadPlaylist = ({ first, page, where, orderBy, skip = false, fetchPolicy = "cache-first" }: Args) => {
  const { data, loading, fetchMore } = useQuery<Result, Args>(LOAD_PLAYLIST, {
    skip: skip,
    variables: {
      first,
      page,
      where,
      orderBy
    },
    fetchPolicy
  });

  return { loading, data, fetchMore };
};
