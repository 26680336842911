import { TrackList } from "App/Features/List/TrackList";
import { useTrackNodeStore } from "App/Store/TrackNodeStore";
import { TrackCreateByArtist } from "Constants/Locale";
import { ArtistID } from "Models/ArtistModel";
import styled from "styled-components";
import { useArtistMetadataTrackList } from "../../hooks/useArtistMetadataTrackList";
import { useArtistTrackCount } from "../../hooks/useArtistTrackCount";

interface Props {
  readonly id: ArtistID;
  readonly isLoadedArtist: boolean;
}

export const TrackListForm = ({ id, isLoadedArtist }: Props) => {
  const { isLoaded: isLoadedTrack } = useArtistMetadataTrackList(id.raw.toString(), !isLoadedArtist);
  const isLoaded = isLoadedTrack && isLoadedArtist;

  const { data, isLoaded: isLoadedArtistTrackCount } = useArtistTrackCount(id.raw.toString());

  const trackList = useTrackNodeStore(store => store.filter(track => track.artist.raw === id.raw)).map(track => track.id);
  const count = data?.metadataTrackCount.count;

  return (
    <Layout>
      <ArtistTrackTitleContainer>
        <ArtistTrackTitle>
          <TrackCreateByArtist />
        </ArtistTrackTitle>
        <ArtistTrackCount>{isLoadedArtistTrackCount && `(${count})`}</ArtistTrackCount>
      </ArtistTrackTitleContainer>
      <TrackList trackList={trackList} isLoadedTrack={isLoaded} />
    </Layout>
  );
};

const Layout = styled.div``;
const ArtistTrackTitleContainer = styled.div`
  position: relative;
  padding: 25px;
  padding-left: 30px;
  display: flex;
  align-items: center;
`;

const ArtistTrackTitle = styled.h3`
  font-size: 1.5rem;
`;

const ArtistTrackCount = styled.span`
  margin-left: 0.25rem;
`;
