import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { Color } from "Constants/Color";
import styled from "styled-components";

export const LicenseHeader = () => {
  return (
    <Layout>
      <Container>
        <IdContainer>
          <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
            ID
          </Typography>
        </IdContainer>
        <UserNameContainer>
          <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
            소유자
          </Typography>
        </UserNameContainer>
        <UserNameContainer>
          <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
            이메일
          </Typography>
        </UserNameContainer>
        <CompanyNameContainer>
          <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
            회사
          </Typography>
        </CompanyNameContainer>
        <TrackNameContainer>
          <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
            트랙
          </Typography>
        </TrackNameContainer>
        <CreateAtContainer>
          <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
            생성 날짜
          </Typography>
        </CreateAtContainer>
        <DownloadContainer>
          <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
            버튼
          </Typography>
        </DownloadContainer>
      </Container>
    </Layout>
  );
};

const Layout = styled.thead`
  flex-wrap: nowrap;
  white-space: nowrap;
`;

const Container = styled.tr`
  border-top: 2px solid rgba(255, 255, 255, 0.6);
  border-bottom: 2px solid rgba(255, 255, 255, 0.6);
`;
const Item = styled.th`
  padding: 1rem 2rem;
  text-align: center;
  background-color: ${Color.Ac_Gray};
  &:not(:last-child) {
    border-right: 1px dashed ${Color.Ac_Gray0};
  }
`;

const IdContainer = styled(Item)``;
const UserNameContainer = styled(Item)``;
const TrackNameContainer = styled(Item)``;
const CompanyNameContainer = styled(Item)``;
const CreateAtContainer = styled(Item)``;
const DownloadContainer = styled(Item)`
  flex: 0 0 123px;
  justify-content: center;
`;
