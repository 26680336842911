import { BasicTemplate } from "App/Templates/BasicTemplate";
import { Outlet } from "react-router-dom";
import styled from "styled-components";

export const My = () => {
  return (
    <BasicTemplate isSidebar={false}>
      <Layout>
        <Outlet />
      </Layout>
    </BasicTemplate>
  );
};

const Layout = styled.div``;
