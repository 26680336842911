import { TrackID } from "Models/TrackModel";
import styled from "styled-components";

import { MoreTrackList } from "./MoreTrackList";

type Props = {
  readonly id: TrackID;
};

export const MoreTrackListForm = ({ id }: Props) => {
  return (
    <Layout>
      <Container>
        <MoreTrackList id={id} />
      </Container>
    </Layout>
  );
};

const Layout = styled.div`
  position: relative;
  padding: 60px 0;
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  max-width: 1280px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
