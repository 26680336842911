import { Edge } from "Constants/Edge";
import { createStore } from "Lib/store";
import { CurationManagementID } from "Models/CurationManagementModel";

const createInitialState = () => ({
  isLoadedMyCuration: false as boolean,
  curationManagement: [] as CurationManagementID[],
  edge: null as Edge | null
});

export const {
  useSelector: useMyCurationSelector,
  action: MyCurationAction,
  ...MyCurationStore
} = createStore("MyCuration", createInitialState, {
  setEdge(state, edge: Edge | null) {
    state.edge = edge;
  },
  setIsLoadedMyCuration(state, isLoadedMyCuration: boolean) {
    state.isLoadedMyCuration = isLoadedMyCuration;
  },
  setCurationManagement(state, curationManagement: CurationManagementID[]) {
    state.curationManagement = [...curationManagement];
  }
});
