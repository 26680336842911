import { createStore } from "Lib/store";

const createInitialState = () => ({
  isLoadedLibraryFavorites: false as boolean
});

export const {
  useSelector: useLibraryFavoritesSelector,
  action: LibraryFavoritesAction,
  ...LibraryFavoritesStore
} = createStore("LibraryFavorites", createInitialState, {
  setIsLoadedLibraryFavorites(state, isLoadedLibraryFavorites: boolean) {
    state.isLoadedLibraryFavorites = isLoadedLibraryFavorites;
  },
  clear(state) {
    state.isLoadedLibraryFavorites = false;
  }
});
