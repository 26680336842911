import { Time } from "Lib/time";
import { Hash } from ".";
import { createDuck } from "./store";

export type Metadata<Track> = {
  readonly track: Track;
  addedAt: Time;
  duration: null | number;
  maxTime: null | number;
};

export function createPlaylistActions<Track>() {
  const createInitialState = () => ({ trackMap: new Map<Hash, Metadata<Track>>() });

  return createDuck({
    namespace: "Playlist",
    createInitialState,
    reducers: {
      clear(state) {
        state.trackMap.clear();
      },
      upsertList(state, trackList: ReadonlyArray<readonly [Hash, Track]>) {
        for (const [hash, currentTrack] of trackList) {
          const track = state.trackMap.get(hash) ?? null;
          if (track === null) {
            state.trackMap.set(hash, { addedAt: Time.utc(), track: currentTrack, duration: null, maxTime: null });
          } else {
            state.trackMap.set(hash, { ...track, track: currentTrack });
          }
        }
      },
      removeList(state, hashList: readonly Hash[]) {
        for (const hash of hashList) {
          state.trackMap.delete(hash);
        }
      },
      setDurationList(state, durationList: ReadonlyArray<readonly [Hash, number]>) {
        for (const [hash, duration] of durationList) {
          const track = state.trackMap.get(hash);
          if (track) {
            track.duration = duration;
          }
        }
      },
      setMaxTimeList(state, maxTimeList: ReadonlyArray<readonly [Hash, number]>) {
        for (const [hash, maxTime] of maxTimeList) {
          const track = state.trackMap.get(hash);
          if (track) {
            track.maxTime = maxTime;
          }
        }
      }
    }
  });
}
