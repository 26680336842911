import { setPointTransaction } from "App/Common/setPointTransaction";

import { MAX_FIRST_COUNT } from "Constants/Count";
import { useLoadPointTransaction } from "GraphQL/Queries/useLoadPointTransaction";
import { useEffect } from "react";
import { HistoryPointModalAction, HistoryPointModalStore } from "../Store";

export const usePointTransaction = () => {
  const { data, loading } = useLoadPointTransaction({
    first: MAX_FIRST_COUNT
  });

  useEffect(() => {
    if (data) {
      const { pointTransaction } = data;
      const pointTransactionMs = setPointTransaction(pointTransaction);
      HistoryPointModalStore.dispatch(HistoryPointModalAction.setPointTransactionList(pointTransactionMs.map(({ id }) => id)));
    }
  }, [data]);

  useEffect(() => {
    HistoryPointModalStore.dispatch(HistoryPointModalAction.setIsLoadedPointTransactionList(!loading));
  }, [loading]);
};
