import { StringID } from "Utils/id";
import { immerable } from "immer";

export class TrackLicenseID extends StringID {
  constructor(id: string) {
    super("trackLicense", id);
  }
}

export type TrackActivityPlayload = {
  readonly id: string;
  readonly text: string;
  readonly copyright: boolean;
  readonly neighboring: boolean;
  readonly metadataId: string;
};

export class TrackLicenseModel {
  static form({ id, text, copyright, neighboring, metadataId }: TrackActivityPlayload) {
    return new TrackLicenseModel(new TrackLicenseID(id), text, copyright, neighboring, metadataId);
  }

  readonly [immerable] = true;

  constructor(
    readonly id: TrackLicenseID,
    readonly text: string,
    readonly copyright: boolean,
    readonly neighboring: boolean,
    readonly metadataId: string
  ) {}
}
