import { TRACK_ACTIVITY_FRAGMENT } from "GraphQL/Fragment/TrackActivityFragment";
import { TrackActivity } from "GraphQL/Schema";
import { gql, useMutation } from "Lib/apollo-client";

const LIKE_TRACK = gql`
  ${TRACK_ACTIVITY_FRAGMENT}
  mutation LIKE_TRACK($trackId: Int!) {
    likeTrack: like_track(track_id: $trackId) {
      ...TrackActivityFragment
    }
  }
`;

interface Props {
  readonly isReloadPlaylist?: boolean;
}

type Result = {
  readonly likeTrack: TrackActivity;
};

type Args = {
  readonly trackId: number;
};

export const useLikeTrack = ({ isReloadPlaylist = true }: Props) => {
  const [fetch, { loading }] = useMutation<Result, Args>(LIKE_TRACK, {
    update(cache, { data }) {
      const trackActivity = data!.likeTrack;
      cache.modify({
        fields: {
          track_activity(existingTrackActivity = []) {
            const newTrackActivity = cache.writeFragment({
              data: trackActivity,
              fragment: TRACK_ACTIVITY_FRAGMENT
            });
            return existingTrackActivity.concat(newTrackActivity);
          },
          playlist(details = [], { DELETE }) {
            if (!isReloadPlaylist) {
              return details;
            }
            return DELETE;
          }
        }
      });
    }
  });

  return { likeTrack: fetch, loading };
};
