import { t } from "Lib/localization";

export const SettingTerms = t("setting-terms", `Terms of Use`);
export const SettingLegalNotice = t("setting-legal-notice", `Legal Notice`);
export const SettingAboutUs = t("setting-about-us", `About Us`);
export const SettingPrivacyPolicy = t("setting-privacy-policy", `Privacy Policy`);
export const AccountPasswordErrorNull = t("account-password-error-null", `Please enter a password.`);
export const AccountPasswordErrorMinLength = t("account-password-error-min-length", `Please enter a password of at least 8 digits.`);
export const AccountPasswordErrorMaxLength = t("account-password-error-max-length", `Please enter a password of less than 32 characters.`);
export const AccountPasswordErrorNumber = t("account-password-error-number", `The password must contain numbers.`);
export const AccountPasswordErrorEnglish = t("account-password-error-english", `The password must contain English characters.`);
export const AccountPasswordErrorSpecial = t("account-password-error-special", `The special characters are @,! can only use`);
export const AccountNameErrorNull = t("account-name-error-null", `Please enter your nickname.`);
export const AccountNameErrorLength = t(
  "account-name-error-length",
  `Please use at least 1 characters and no more than 20 characters for your nickname.`
);
export const AccountNameErrorEmoji = t("account-name-error-emoji", `Cannot use emojis for nicknames.`);
export const AccountNewPasswordTitle = t("account-new-password-title", `New password`);
export const AccountEmailInvalid = t("account-email-invalid", `Please enter a valid email.`);
export const SettingAgreeTermsOfService = t("setting-agree-terms-of-service", `Please agree to the Terms of Service.`);
export const SettingAgreePrivacyPolicy = t("setting-agree-privacy-policy", `Please agree to the Privacy Policy.`);
export const SettingAgreeAge = t("setting-agree-age", `Please make sure you are over 14 years old.`);
export const AccountEmailMessageSimple = t("account-email-message-simple", `Please enter your email address.`);
export const AccountEmailAlreadyExist = t("account-email-already-exist", `This email is already in use.`);
export const AccountEmailSame = t("account-email-same", `It's the same email.`);
export const ErrorSimple = t("error-simple", `Try it again after a while`);
export const AccountAuth = t("account-auth", `Authenticate`);
export const AccountAuthNotConfirm = t("account-auth-not-confirm", `This email is expired or unauthenticated`);
export const AccountAuthConfirm = t("account-auth-confirm", `Authentication confirmed. Please log in again.`);
export const AccountAuthConfirmSuccess = t("account-auth-confirm-success", `It has been successfully authenticated.`);
export const AccountEmailVerifyMessage = t(
  "account-email-verify-message",
  `Please verify via email.\nIf you do not receive the email or the expiration date has passed, click Resend Email.`
);
export const AccountEmailVerifyRetryMessage = t(
  "account-email-verify-retry-message",
  `If you do not receive the e-mail or the expiration date has passed\n, please click <b>Resend e-mail</b>.`
);
export const AccountEmailUpdateError = t("account-email-update-error", `This email is already authenticated or invalid.`);
export const CommonDone = t("common-done", `Done`);
export const CommonSend = t("common-send", `Send`);
export const AccountEmailCheck = t("account-email-check", `Please check your email within 5 minutes.`);
export const AccountEmailVerifyUpdateEmail = t(
  "account-email-verify-update-email",
  `If the email is not usable, please change to a new email and verify.`
);
export const AccountEmailTitle = t("account-email-title", `Email`);
export const CommonSendFull = t("common-send-full", `It has been sent`);
export const CommonConfirm = t("common-confirm", `Confirm`);
export const AccountResetPasswordSubtitle = t("account-reset-password-subtitle", `Please enter the email address you used to sign up.`);
export const AccountResetPasswordMessage = t("account-reset-password-message", `We have sent you a password confirmation email.`);
export const AccountResetPasswordNotExist = t("account-reset-password-not-exist", `This email does not exist.`);
export const AccountPasswordReset = t("account-password-reset", `Password Reset`);
export const CommonBack = t("common-back", `Go back`);
export const Password = t("password", `Password`);
export const AccountPasswordErrorChange = t("account-password-error-change", `Please change your password`);
export const AccountPasswordChange = t("account-password-change", `Your password will be changed.`);
export const AccountAuthNotConfirmPassword = t("account-auth-not-confirm-password", `The authentication time has expired,`);
export const AccountInfoManage = t("account-info-manage", `Account Management`);
export const AccountLogout = t("account-logout", `Log Out`);
export const EditProfile = t("edit-profile", `Edit Profile`);
export const Library = t("library", `Library`);
export const SettingTermsOfService = t("setting-terms-of-service", `I agree to <b>Terms of Service </b>.`);
export const SettingAge = t("setting-age", `You are over 14 years old.`);
export const SettingPrivacyPolicyAgree = t("setting-privacy-policy-agree", `I agree to <c>Privacy Policy</c>.`);
export const SettingTermsAgreeShort = t("setting-terms-agree-short", `<b>Terms of Use</b> & <c>Privacy Policy</c>`);
export const AccountEmailSignup = t("account-email-signup", `Sign up by email`);
export const AccountRegister = t("account-register", `Register`);
export const AccountNamePlaceholder = t("account-name-placeholder", `Name (1 to 20 characters)`);
export const Genre = t("genre", `Genre`);
export const Mood = t("mood", `Mood`);
export const Role = t("role", `Role`);
export const UppercaseDuration = t("uppercase-duration", `DURATION`);
export const UppercaseBpm = t("uppercase-bpm", `BPM`);
export const TrackCount = t("track-count", `Track Count`);
export const Filter = t("filter", `FILTER`);
export const Title = t("title", `Title`);
export const TrackTags = t("track-tags", `TRACK TAGS`);
export const UppercaseGenre = t("uppercase-genre", `GENRE`);
export const UppercaseMood = t("uppercase-mood", `MOOD`);
export const UppercaseRole = t("uppercase-role", `ROLE`);
export const AccountName = t("account-name", `Name`);
export const AccountLogin = t("account-login", `Log In`);
export const AccountSignup = t("account-signup", `Sign Up`);
export const UserVerifyEmail = t("user-verify-email", `Verify your email`);
export const UserVerifyLogin = t("user-verify-login", `Logged in.`);
export const AccountPasswordMessageSimple = t("account-password-message-simple", `Please enter your password address.`);
export const AccountWrongEmailOrPassword = t("account-wrong-email-or-password", `Wrong email or password`);
export const AccountForgotPassword = t("account-forgot-password", `Forgot your password?`);
export const Cart = t("cart", `Cart`);
export const Duration = t("duration", `Duration`);
export const NotFoundItem = t("not-found-item", `No Results Found.`);
export const Artist = t("artist", `Artist`);
export const AddToCart = t("add-to-cart", `Add to Cart`);
export const GoToBuy = t("go-to-buy", `Go to buy`);
export const GetLicense = t("get-license", `Get a license`);
export const ModifiedDate = t("modified-date", `Modified date`);
export const Kind = t("kind", `Kind`);
export const Amount = t("amount", `Amount`);
export const PurchaseDate = t("purchase-date", `Date of purchase`);
export const AllCount = t("all-count", `Total number`);
export const Curation = t("curation", `Curation`);
export const Track = t("track", `Track`);
export const TitleErrorNull = t("title-error-null", `Please enter the subject`);
export const CreatePlaylist = t("create-playlist", `Create Playlist`);
export const Create = t("create", `Create`);
export const Description = t("description", `Description`);
export const ConfirmDeleteItem = t("confirm-delete-item", `Are you sure you want to delete?`);
export const ModifyPlaylist = t("modify-playlist", `Modify Playlist`);
export const Delete = t("delete", `Delete`);
export const Modify = t("modify", `Modify`);
export const Music = t("music", `Music`);
export const Playlist = t("playlist", `Playlist`);
export const AddPlaylist = t("add-playlist", `Add Playlist`);
export const PurchaseHistory = t("purchase-history", `Purchase history`);
export const FavoriteTrack = t("favorite-track", `Favorite track`);
export const ErrorNetworkMessage = t("error-network-message", `A network problem has occurred.`);
export const Save = t("save", `Save`);
export const CreateNewPlaylist = t("create-new-playlist", `Create a new playlist`);
export const Add = t("add", `Add`);
export const AllPlay = t("all-play", `ALL PLAY`);
export const MusicDownloadDesciprtion = t("music-download-desciprtion", `Unauthorized use of music may result in legal action.`);
export const MusicDownload = t("music-download", `Music Download`);
export const NoMusic = t("no-music", `The sound source does not exist.`);
export const MoreSong = t("more-song", `See more similar songs`);
export const MoreSongDesciprtion = t("more-song-desciprtion", `We Recommend more genres similar to this song.`);
export const UppercaseTrack = t("uppercase-track", `TRACK`);
export const TrackCreateByArtist = t("track-create-by-artist", `Tracks created by artists`);
export const NoCartItem = t("no-cart-item", `No tracks have been added to your cart.`);
export const ExistLicense = t("exist-license", `This license has already been issued.`);
export const Download = t("download", `Download`);
export const LicenseDownloadDescription = t(
  "license-download-description",
  `Please do not close the window until the download is complete.`
);
export const CheckLicenseInformation = t("check-license-information", `Check license information`);
export const LicenseInforatmionDesciprtion = t(
  "license-inforatmion-desciprtion",
  `Please check that the license information for the track is correct. It cannot be changed after confirmation.`
);
export const Completion = t("completion", `Completion`);
export const CompanyName = t("company-name", `Company Name`);
export const CompanyNameErrorNull = t("company-name-error-null", `Please enter your company name.`);
export const LicenseFooterDescription1 = t(
  "license-footer-description-1",
  `When editing, the user's information is changed. Information about licenses that have already been issued will not be changed.`
);
export const LicenseFooterDescription2 = t(
  "license-footer-description-2",
  `The license will be created with this information, so please fill it out accurately.`
);
export const LicenseFooterDescription3 = t("license-footer-description-3", `An asterisk (*) is a required value.`);
export const PurchaseComplete = t("purchase-complete", `Purchase complete`);
export const NoFavoriteTrack = t("no-favorite-track", `No favorite tracks`);
export const NotFountPage = t("not-fount-page", `This page cannot be found...😢`);
export const GoToHome = t("go-to-home", `Go to Home`);
export const OrderTrackInforatmion = t("order-track-inforatmion", `Order track Information`);
export const TotalPaymentAmount = t("total-payment-amount", `Total payment amount`);
export const Purchase = t("purchase", `Purchase`);
export const Pieces = t("pieces", `pieces`);
export const PointPayment = t("point-payment", `Point payment`);
export const Balance = t("balance", `Balance`);
export const ChoosePaymentMethod = t("choose-payment-method", `Choose a payment method`);
export const ProductPurchaseNotes = t("product-purchase-notes", `Notes on product purchase`);
export const OrderPayment = t("order-payment", `order payment`);
export const Edit = t("edit", `Edit`);
export const ChangePassword = t("change-password", `Change Password`);
export const Change = t("change", `Change`);
export const Withdrawal = t("withdrawal", `Withdrawal`);
export const Copied = t("copied", `Copied.`);
export const Close = t("close", `Close`);
export const Point = t("point", `Point`);
export const Searching = t("searching", `Searching...`);
export const PurchaseCompleted = t("purchase-completed", `Your purchase has been completed.`);
export const BackToHome = t("back-to-home", `back to home`);
export const ViewPaymentHistory = t("view-payment-history", `View payment history`);
export const NoPurchaseHistory = t("no-purchase-history", `No purchase history.`);
export const BeingPointCharging = t("being-point-charging", `The point charging function is being prepared.`);
export const Latest = t("latest", `Latest`);
export const Oldest = t("oldest", `Oldest`);
export const NoPlaylist = t("no-playlist", `There is no playlist.`);
export const Tracks = t("tracks", `Tracks`);
export const AcountEmailChangeDscription = t(
  "acount-email-change-dscription",
  `After changing your email, you can log in with that email.`
);
export const UserverifyDesciprtion = t(
  "userverify-desciprtion",
  `Order and point payment can be used after email verification for user verification.`
);
export const SucessCreatePlaylist = t("sucess-create-playlist", `You have created a playlist.`);
export const PlaylistModalAdd = t("playlist-modal-add", `Added to playlist.`);
export const PlaylistModalRemove = t("playlist-modal-remove", `Removed from playlist`);
export const PurchasedTrack = t("purchased-track", `Purchased track`);
export const SignupError = t("signup-error", `An error occurred while registering as a member`);
export const SucessSignup = t("sucess-signup", `You have been registered.`);
export const SucessWithdrawal = t("sucess-withdrawal", `Membership cancellation has been completed.`);
export const TryToAgain = t("try-to-again", `Please try again in a few minutes.`);
export const SecessionConfirm = t("secession-confirm", `Are you sure you want to cancel membership?`);
export const SecessionDescription1 = t(
  "secession-description-1",
  `If you leave the service now, the information below will be deleted and cannot be recovered.`
);
export const SecessionDescription2 = t("secession-description-2", `Deletion of account and profile information.`);
export const SecessionDescription3 = t("secession-description-3", `Delete purchase history (track license).`);
export const SecessionDescription4 = t(
  "secession-description-4",
  `If you have any questions about membership cancellation, please contact the customer center.`
);
export const SecessionDescription5 = t(
  "secession-description-5",
  `I have checked all of the above and agree to the withdrawal of membership.`
);
export const Secession = t("secession", `Withdrawal`);
export const SucessAccountPasswordChange = t("sucess-account-password-change", `Your password has been changed.`);
export const ChangedMessage = t("changed-message", `It is changed`);
export const ProfileEditError = t("profile-edit-error", `An error occurred while editing the profile.`);
export const ArtistDetailNoTrack = t("artist-detail-no-track", `There are no tracks created by the artist.`);
export const AllAddCart = t("all-add-cart", `All Add Cart`);
export const NoShareCuration = t("no-share-curation", `There is no shared curation.`);
export const CurationShareEmail = t("curation-share-email", `For curation requests, please contact b2b@artistscard.com.`);
export const BackToCart = t("back-to-cart", `back to cart`);
export const PurchaseErrorMinAmount = t("purchase-error-min-amount", `The minimum payment amount is KRW 1,000.`);
export const PaymentMethod = t("payment-method", `Payment Method`);
export const Remove = t("remove", `Remove`);
export const AdminSite = t("admin-site", `Admin Site`);
export const Like = t("like", `Like`);
export const VerificationCompleted = t("verification-completed", `Verification completed`);
export const ErrorPasswordDifferent = t("error-password-different", `The password you entered is different from the existing password.`);
export const CompleteWithdrawal = t("complete-withdrawal", `Your withdrawal is complete.`);
export const ServiceCharge = t("service-charge", `Service charge`);
export const Refund = t("refund", `Refund`);
export const PointTransactionAdmin = t("point-transaction-admin", `Admin Points Charge`);
export const PointTransactionAdminCancel = t("point-transaction-admin-cancel", `Manager Points Deducted`);
export const PointTransactionPayletter = t("point-transaction-payletter", `Point Charge`);
export const PointTransactionPayletterCancel = t("point-transaction-payletter-cancel", `Point Cancel`);
export const PointTransactionProduct = t("point-transaction-product", `Product Purchase`);
export const PointTransactionProductCancel = t("point-transaction-product-cancel", `Produc Refund`);
export const PointChargeText = t("point-charge-text", `Point Charge`);
export const DownloadMusicPurchaseModal = t("download-music-purchase-modal", `You can download the music after purchasing the track.`);
export const ChargingPoint = t("charging-point", `Charging Point`);
export const ProductAmount = t("product-amount", `Product Amount`);
export const KoreanPayment = t("korean-payment", `Korean Payment`);
export const GlobalPayment = t("global-payment", `Global Payment`);
export const ConfirmPurchaseCehckTitle1 = t("confirm-purchase-cehck-title-1", `The product you want to purchase is correct.`);
export const ConfirmPurchaseCehckDescription1 = t(
  "confirm-purchase-cehck-description-1",
  `Number of points Points price, I checked the options one more time.`
);
export const ConfirmPurchaseCehckTitle2 = t(
  "confirm-purchase-cehck-title-2",
  `I have checked all the BGM service usage policies and purchased.`
);
export const ConfirmPurchaseCehckDescription2 = t(
  "confirm-purchase-cehck-description-2",
  `We have identified the usage <a href="https://staging.bgmmanager.com/terms-of-service">policies</a> that you must know for a sound and safe transaction.`
);
export const ConfirmAllCheckAgree = t("confirm-all-check-agree", `I have checked all the contents and agree to the terms of purchase.`);
export const CookiePolicy = t("cookie-policy", `Cookie Policy`);
export const SupportMainTitleText = t("support-main-title-text", `Ask your questions`);
export const SupportMainDescriptionText = t(
  "support-main-description-text",
  `Questions about licensing? Or would you like some great music recommendations?\nYou can contact Artists'Card at any time.\nPlease send us an email or a letter and we'll get back to you as soon as possible.`
);
export const SendEmail = t("send-email", `Send Email`);
export const SendEmailLink = t("send-email-link", `Please contact us at <a>contact@artistscard.com</a>.`);
export const EmailQuicklyReply = t("email-quickly-reply", `We will respond as quickly as possible.`);
export const GoToSupport = t("go-to-support", `Go to Support`);
export const SearchQuestions = t("search-questions", `You can find your questions in the Support.`);
export const LicenseMainTitleText = t("license-main-title-text", `Track License Coverage`);
export const LicenseMainDescriptionText = t(
  "license-main-description-text",
  `If you are unsure of the scope of your track license usage, check out the simple chart below to easily determine availability for each purpose.`
);
export const PurposeOfUse = t("purpose-of-use", `purpose of use`);
export const Availability = t("availability", `Availability`);
export const FaqEmailText = t(
  "faq-email-text",
  `Questions about licensing? \nIf you need help, please let me know. Send us an email or a letter, \nand we'll get back to you as soon as possible.`
);
export const ContactEmailLink = t("contact-email-link", `<a>Contact us</a>`);
export const FaqSupportText = t(
  "faq-support-text",
  `Questions about licensing? \nYou can learn more about terms and conditions and restrictions in the \nCustomer Center \nand find questions.`
);
export const MoveLink = t("move-link", `<a>Go to</a>`);
export const FrequentlyAsk = t("frequently-ask", `Frequently Asked Questions`);
export const FaqDescription = t(
  "faq-description",
  `All your questions can be answered in one place. Do you have any questions? We will give you an appropriate answer.`
);
export const ViewFaq = t("view-faq", `View FAQ`);
export const EffectCount = t("effect-count", `Effect Count`);
export const LicenseText = t("license-text", `License`);
export const BuyButtonLicensePriceInfo = t(
  "buy-button-license-price-info",
  `Purchased music can be used for life without restrictions on the number of times and uses. If you want to check the track license usage scope, please check \n <a>License usage scope</a>`
);
export const SupportLink = t("support-link", `<a>https://support.artistscard.com/bgm-manager</a>`);
export const LicensePurposeOfUse1 = t("license-purpose-of-use-1", `Unlimited download of the track`);
export const LicensePurposeOfUse2 = t(
  "license-purpose-of-use-2",
  `Track editing (playtime and volume control, adding instruments, remix, etc.)`
);
export const LicensePurposeOfUse3 = t(
  "license-purpose-of-use-3",
  `Generate revenue from Video platforms (such as YouTube, AfreecaTV, Twitch, TikTok, Instagram, etc.)`
);
export const LicensePurposeOfUse4 = t(
  "license-purpose-of-use-4",
  `Generate revenue from audio platforms(such as Spoon Radio, Blabla, etc.)`
);
export const LicensePurposeOfUse5 = t(
  "license-purpose-of-use-5",
  `Broadcasting programs (terrestrial and cable TV, satellite TV, IPTV, OTT, etc.)`
);
export const LicensePurposeOfUse6 = t("license-purpose-of-use-6", `CM, drama (web drama), movie, exhibition`);
export const LicensePurposeOfUse7 = t("license-purpose-of-use-7", `Mobile / PC Games`);
export const LicensePurposeOfUse8 = t("license-purpose-of-use-8", `Webtoon, Animation, Audio book`);
export const LicensePurposeOfUse9 = t("license-purpose-of-use-9", `P2P uploads, offers and resells to others`);
export const LicensePurposeOfUse10 = t("license-purpose-of-use-10", `Releasing album`);
export const LicensePurposeOfUse11 = t("license-purpose-of-use-11", `Stream to third parties on YouTube, SoundCloud playlists and more`);
export const License = t("license", `License`);
export const TrackNoTag = t("track-no-tag", `The track is not tagged.`);
export const AvailabilityTab = t("availability-tab", `Availability`);
export const View = t("view", `View`);
export const Composer = t("composer", `Composer`);
export const AddMemo = t("add-memo", `Add Memo`);
export const Memo = t("memo", `Memo`);
export const ModifyMemo = t("modify-memo", `Modify Memo`);
export const FullDownload = t("full-download", `Full Download`);
export const FullDownloadModalDescription = t(
  "full-download-modal-description",
  `*For some sound sources, only mp3 files can be downloaded`
);
export const AddToPlaylist = t("add-to-playlist", `Add to Playlist`);
export const DownloadCsvPurchaseModal = t("download-csv-purchase-modal", `You can download CSV after purchasing the track.`);
export const DownloadCsv = t("download-csv", `Download CSV`);
export const FullDownloadCsvModalDescription = t(
  "full-download-csv-modal-description",
  `*Some sound sources may not have BPM and playback time.`
);
