import { setPurchaseHistory } from "App/Common/setPurchaseHistory";
import { BASIC_FIRST_COUNT } from "Constants/Count";
import { useLoadPurchaseHistory } from "GraphQL/Queries/useLoadPurchaseHistory";
import { PurchaseHistoryOrderByInput } from "GraphQL/Schema";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { PurchaseHistoryAction, PurchaseHistoryStore } from "../Store";

export const usePurchaseHistory = () => {
  const [searchParam] = useSearchParams();
  const page = +(searchParam.get("page") || 1);
  const { data, loading } = useLoadPurchaseHistory({
    first: BASIC_FIRST_COUNT,
    page,
    orderBy: [PurchaseHistoryOrderByInput.IdDesc]
  });

  useEffect(() => {
    if (data) {
      const { purchaseHistory, edge } = data;
      const purchaseHistoryMs = setPurchaseHistory(purchaseHistory);
      PurchaseHistoryStore.dispatch(PurchaseHistoryAction.setPurchaseHistoryList(purchaseHistoryMs.map(({ id }) => id)));
      PurchaseHistoryStore.dispatch(PurchaseHistoryAction.setEdge(edge));
    }
  }, [data]);

  useEffect(() => {
    return () => {
      PurchaseHistoryStore.dispatch(PurchaseHistoryAction.clear());
    };
  }, []);

  useEffect(() => {
    PurchaseHistoryStore.dispatch(PurchaseHistoryAction.setIsLoadedPurchaseHistory(!loading));
  }, [loading]);
};
