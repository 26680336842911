import { createStore } from "Lib/store";

export enum ModalType {
  ADD_PLAYLIST,
  TRACK_FILTER,
  SIDEBAR_MENU,
  TRACK_DOWNLOAD,
  TRACKS_DOWNLOAD,
  CSV_DOWNLOAD,
  MODIFY_PLAYLIST,
  MODIFY_PLAYLIST_ITEM,
  PLAYLIST,
  PLAYLISTS,
  CONFIRM,
  SIGNIN,
  SIGNUP,
  FIND_PASSWORD,
  USER_VERIFY,
  HISTORY_POINT,
  LICENSE,
  UPDATE_PASSWORD,
  UPDATE_PROFILE,
  DELETE_ACCOUNT,
  TRACK_LICENSE
}

export type ModalProps = Record<string, any>;

type ModalState = {
  modalMap: Map<ModalType, ModalProps>;
};

const createInitialState = (): ModalState => ({
  modalMap: new Map()
});

export const {
  useSelector: useModalSelector,
  action: ModalAction,
  ...ModalStore
} = createStore("Modal", createInitialState, {
  open(state, modalType: ModalType, props?: ModalProps) {
    state.modalMap.set(modalType, props ?? {});
  },
  replace(state, modalType: ModalType, props?: ModalProps) {
    state.modalMap.clear();
    state.modalMap.set(modalType, props ?? {});
  },
  close(state, modalType: ModalType) {
    state.modalMap.delete(modalType);
  },
  closeAll(state) {
    state.modalMap.clear();
  }
});
