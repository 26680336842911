import { Typography, TypoSize } from "App/Atomics/Typography";
import { BasicTemplate } from "App/Templates/BasicTemplate";
import { Color } from "Constants/Color";
import { LicenseMainDescriptionText, LicenseMainTitleText } from "Constants/Locale";
import styled from "styled-components";
import { Chart } from "./Chart";
import { Faq } from "./Faq";
import { Support } from "./Support";

export const License = () => {
  return (
    <BasicTemplate isMenu={false} isSidebar={false}>
      <Layout>
        <Container>
          <Title size={TypoSize.F32}>
            <LicenseMainTitleText />
          </Title>
          <SubTitle color={Color.Ac_Gray1}>
            <LicenseMainDescriptionText />
          </SubTitle>
          <Chart />
        </Container>

        <Faq />
        <Support />
      </Layout>
    </BasicTemplate>
  );
};

const Layout = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  padding: 80px 30px;
  white-space: normal;
`;

const Title = styled(Typography)`
  margin-bottom: 1.25rem;
`;
const SubTitle = styled(Typography)`
  font-size: 0.9rem;
  text-align: center;
`;
