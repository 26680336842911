import { PlaylistType } from "GraphQL/Schema";
import { Time } from "Lib/time";
import { NumericID } from "Utils/id";
import { AccountID } from "./AccountModel/AccountID";
import { TrackID } from "./TrackModel";
import { PlaylistItemID } from "./PlaylistItemModel";

export interface TrackItem {
  id: TrackID;
  item_id: PlaylistItemID;
  description: string;
}

export class PlaylistID extends NumericID {
  constructor(id: number) {
    super("playlist", id);
  }
}

export type PlaylistPlayload = {
  readonly id: number;
  readonly description: string;
  readonly title: string;
  readonly count: number;
  readonly type: PlaylistType;
  readonly trackList: TrackItem[];
  readonly updatedAt: string;
  readonly userId: AccountID;
};

export class PlaylistModel {
  static form({ id, title, description, count, type, updatedAt, userId, trackList }: PlaylistPlayload) {
    return new PlaylistModel(new PlaylistID(id), title, description, count, type, Time(updatedAt), userId, trackList);
  }

  constructor(
    readonly id: PlaylistID,
    readonly title: string,
    readonly desciprtion: string,
    readonly count: number,
    readonly type: PlaylistType,
    readonly updatedAt: Time,
    readonly userId: AccountID,
    readonly trackList: TrackItem[]
  ) {}
}
