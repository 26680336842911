import { Edge } from "Constants/Edge";
import { EDGE_FRAGMENT } from "GraphQL/Fragment/EdgeFragment";
import { TRACK_FRAGMENT } from "GraphQL/Fragment/TrackFragment";
import { License, LicenseWhereInput } from "GraphQL/Schema";
import { gql, useQuery } from "Lib/apollo-client";

export const LOAD_LICENSE = gql`
  ${TRACK_FRAGMENT}
  ${EDGE_FRAGMENT}
  query LOAD_LICENSE_ADMIN($first: Int, $page: Int, $where: LicenseWhereInput) {
    license: license(where: $where, first: $first, page: $page, is_force: true) {
      id
      track_id
      name
      company
      created_at
      updated_at
      user {
        id
        email
        name
        tier
        is_verification
      }
      track {
        ...TrackFragment
      }
    }
    edge(node: License, is_force: true, where: { license: $where }, first: $first, page: $page) {
      ...EdgeFragment
    }
  }
`;

type Result = {
  readonly license: License[];
  readonly edge: Edge;
};

type Args = {
  readonly first?: number;
  readonly page?: number;
  readonly where?: LicenseWhereInput;
};

export const useLoadAdminLicense = ({ first, page, where }: Args) => {
  const { data, loading } = useQuery<Result, Args>(LOAD_LICENSE, {
    variables: {
      first,
      page,
      where
    }
  });

  return { loading, data };
};
