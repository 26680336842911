import styled from "styled-components";
import { Section, useCachargePointSelector } from "../Store/CechargePointStore";
import { First } from "./First";
import { Second } from "./Second";

export const Form = () => {
  const { section } = useCachargePointSelector(store => store);
  return (
    <Layout>
      {section === Section.First && <First />}
      {section === Section.Second && <Second />}
    </Layout>
  );
};

const Layout = styled.div`
  max-width: 500px;
  width: 100%;
  margin: 4rem auto;
`;
