import { NumericID } from "Utils/id";
import { ArtistID } from "./ArtistModel";
import { GenreID } from "./GenreModel/GenreModel";
import { immerable } from "immer";
import { RoleID } from "./RoleModel";
import { GradeID } from "./GradeModel";
import { FileRuleID } from "./FileRuleModel";
import { TypeMetadataSubclass } from "GraphQL/Schema";

export class TrackID extends NumericID {
  constructor(id: number) {
    super("track", id);
  }
}

export type TrackPlayload = {
  readonly id: number;
  readonly bpm: number;
  readonly title: string;
  readonly duration: number;
  readonly artist: ArtistID;
  readonly genreList: GenreID[];
  readonly grade: GradeID;
  readonly roleList: RoleID[];
  readonly fileRule: FileRuleID;
  readonly relationTrackList: TrackID[];
  readonly subClass: TypeMetadataSubclass;
};

export class TrackModel {
  static form({ id, bpm, title, duration, artist, genreList, relationTrackList, roleList, grade, fileRule, subClass }: TrackPlayload) {
    return new TrackModel(new TrackID(id), bpm, title, duration, artist, genreList, roleList, relationTrackList, grade, fileRule, subClass);
  }

  readonly [immerable] = true;

  constructor(
    readonly id: TrackID,
    readonly bpm: number,
    readonly title: string,
    readonly duration: number,
    readonly artist: ArtistID,
    readonly genreList: GenreID[],
    readonly roleList: RoleID[],
    readonly relationTrackList: TrackID[],
    readonly grade: GradeID,
    readonly fileRule: FileRuleID,
    readonly subClass: TypeMetadataSubclass
  ) {}
}
