import { Button, ButtonColor, ButtonSize } from "App/Atomics/Button";
import { Icon } from "App/Atomics/Icon";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { useProductPriceNode } from "App/Store/ProductPriceNodeStore";
import { Color } from "Constants/Color";
import { adminPriceEdit } from "Constants/Routes";
import { useApprovePrice } from "GraphQL/Mutations/useApprovePrice";
import { useUnApprovePrice } from "GraphQL/Mutations/useUnApprovePrice";
import { ProductPriceType } from "GraphQL/Schema";
import { Toast } from "Lib/toast";
import { ProductPriceID } from "Models/ProductPriceModel";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { formatDate } from "Utils/format";

type Props = {
  readonly id: ProductPriceID;
};

const PRICE_ALREDY = "approved TRACK-price already exist";

export const PriceManagementItem = ({ id }: Props) => {
  const curation = useProductPriceNode(id);
  const { approvePrice, loading, error } = useApprovePrice();
  const { unapprovePrice, loading: unLoading, error: unError } = useUnApprovePrice();
  const navigate = useNavigate();
  const tryToPriceDetail = (id: ProductPriceID) => {
    navigate(adminPriceEdit.path({ id: id.raw }));
  };

  const tryToApprovePrice = () => {
    approvePrice({
      variables: {
        productPriceId: id.raw
      }
    });
  };
  const tryToUnApprovePrice = () => {
    unapprovePrice({
      variables: {
        productPriceId: id.raw
      }
    });
  };

  useEffect(() => {
    if (error?.graphQLErrors[0].message === PRICE_ALREDY) {
      Toast.error("이미 승인된 금액이 있습니다 해재후 다시 시도해 주세요");
    }
  }, [error, unError]);

  if (!curation) {
    return null;
  }

  return (
    <Layout>
      <Raw>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          {curation.id.raw}
        </Typography>
      </Raw>
      <Raw>
        {curation.isApprove ? (
          <Typography size={TypoSize.F14} weight={TypoWeight.Bold} color={Color.Ac_Red1}>
            사용중
          </Typography>
        ) : (
          <Typography size={TypoSize.F14} weight={TypoWeight.Bold} color={Color.Ac_Gray0}>
            미사용중
          </Typography>
        )}
      </Raw>
      <Raw>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          {curation.target}
        </Typography>
      </Raw>
      <Raw>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          {curation.type === ProductPriceType.Curation && "큐레이션"}
          {curation.type === ProductPriceType.Track && "트랙"}
          {curation.type === ProductPriceType.Mp3 && "mp3"}
          {curation.type === ProductPriceType.Wav && "wav"}
        </Typography>
      </Raw>
      <Raw>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          {curation.title}
        </Typography>
      </Raw>
      <Raw>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          {curation.price.toLocaleString()}
        </Typography>
      </Raw>
      <Raw>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          {formatDate(curation.createAt)}
        </Typography>
      </Raw>
      <Raw>
        <ButtonGroup>
          {curation.isApprove ? (
            <>
              {!unLoading && (
                <Button size={ButtonSize.Xsmall} color={ButtonColor.Disable} onClick={tryToUnApprovePrice}>
                  미승인 하기
                </Button>
              )}
              {unLoading && (
                <Button size={ButtonSize.Xsmall} color={ButtonColor.Disable}>
                  <Loading name="loading" />
                </Button>
              )}
            </>
          ) : (
            <>
              {!loading && (
                <Button size={ButtonSize.Xsmall} color={ButtonColor.Primary} onClick={tryToApprovePrice}>
                  승인
                </Button>
              )}
              {loading && (
                <Button size={ButtonSize.Xsmall} color={ButtonColor.Disable}>
                  <Loading name="loading" />
                </Button>
              )}
            </>
          )}
        </ButtonGroup>
      </Raw>
      <Raw>
        <Button size={ButtonSize.Xsmall} color={ButtonColor.Primary} onClick={() => tryToPriceDetail(id)}>
          상세
        </Button>
      </Raw>
    </Layout>
  );
};

const Layout = styled.tr`
  padding: 1rem 1rem;
  border-bottom: 1px solid ${Color.Ac_Gray0};
`;

const Raw = styled.td`
  padding: 1rem 2rem;
  text-align: center;

  &:not(:last-child) {
    border-right: 1px dashed ${Color.Ac_Gray0};
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;

  button {
    margin: 0 0.25rem;
  }
`;

const Loading = styled(Icon)`
  width: 1rem;
  height: 1rem;
`;
