import { PlaylistID } from "Models/PlaylistModel";
import styled from "styled-components";
import { MyLikeInformation } from "./MyLikeInformation";

interface Props {
  readonly id: PlaylistID;
}

export const Header = ({ id }: Props) => {
  return (
    <Layout>
      <MyLikeInformation id={id} />
    </Layout>
  );
};

const Layout = styled.div``;
