import { useSearchParams } from "react-router-dom";
import { useSubclass } from "Utils/useSubclass";
import { useCacheMetadataCount } from "./useCacheMetadataCount";

export const useMetadataCountSwitch = () => {
  const [searchParam] = useSearchParams();

  const genres = searchParam.getAll("genres");
  const moods = searchParam.getAll("moods");
  const roles = searchParam.getAll("roles");
  const artist = searchParam.get("artist") ?? undefined;
  const search = searchParam.get("search");
  const page = +(searchParam.get("page") || 1);
  const order = searchParam.get("order");
  const bpm = searchParam.get("bpm");
  const duration = searchParam.get("duration");
  const { metadataType } = useSubclass();

  const isSkip = !search && !genres.length && !moods.length && !roles.length && page === 1 && !order && !bpm && !duration && !artist;

  const { isLoaded } = useCacheMetadataCount({
    bpm,
    duration,
    genres,
    isSkip,
    moods,
    page,
    roles,
    search,
    subclass: metadataType.subClass,
    companyIds: metadataType.companyIds,
    order,
    artist
  });

  return { isLoaded };
};
