import styled from "styled-components";
import Tippy, { TippyProps } from "@tippyjs/react";
import { hideAll } from "tippy.js";
import { Color } from "Constants/Color";
import { Icon, IconName } from "../Icon";
import { Typography, TypoSize } from "../Typography";

type Props = TippyProps & {
  trigger: "mouseenter focus" | "click" | "focus" | "mouseenter click" | "manual";
};

type itemProps = {
  text: string;
  isActive?: boolean;
  border?: boolean;
  icon?: IconName;
  onClick?: () => void;
};

const Layout = ({ trigger = "click", animation = "fade", appendTo = "parent", interactive = true, children, ...props }: Props) => {
  return (
    <L>
      <Tippy trigger={trigger} animation={animation} appendTo={appendTo} interactive={interactive} {...props} arrow={false}>
        <Content>{children}</Content>
      </Tippy>
    </L>
  );
};

const Item = ({ text, border, isActive, icon, onClick }: itemProps) => {
  const preventEventClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClick && onClick();
    hideAll();
  };
  return (
    <DropdownItem aria-current={isActive} border={border} onClick={preventEventClick}>
      <TextWrap>
        <Typography size={TypoSize.F14} color={isActive ? Color.AC_Carrot : Color.Ac_Black}>
          {text}
        </Typography>
      </TextWrap>

      {icon && <ButtonIcon name={icon} fill={isActive ? Color.AC_Carrot : Color.Ac_Black} />}
    </DropdownItem>
  );
};

export const Dropdown = { Layout, Item };

export const DropdownContextMenu = styled.div`
  pointer-events: auto;
  white-space: nowrap;
`;

export const DropdownList = styled.ul`
  border-radius: 4px;
  max-height: calc(100vh - 24px);
  overflow-y: auto;
  user-select: none;
  padding: 19px 20px 20px;
  border-radius: 5px;
  background-color: ${Color.Ac_White};
  box-shadow: 5px 5px 15px 0 rgba(8, 8, 13, 0.3);
`;

const DropdownItem = styled.button<{ border?: boolean }>`
  width: 100%;
  background: transparent;
  cursor: default;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  text-align: start;
  pointer-events: auto;
  transition: background 0.1s;
  cursor: pointer;

  & + & {
    margin-top: 20px;
  }
`;
const Content = styled.div``;
const TextWrap = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ButtonIcon = styled(Icon)`
  margin-left: 2px;
  width: 20px;
  height: 20px;
`;

const L = styled.div`
  .tippy-box {
    background-color: rgba(0, 0, 0, 0);
  }
`;
