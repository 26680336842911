import { Icon } from "App/Atomics/Icon";
import { VolumeSlider } from "App/Features/VolumeSlider";
import { BuyButtonInfo } from "App/Molecules/BuyButtonInfo";
import { useAccountSelector } from "App/Store/AccountStore";
import { ModalAction, ModalStore, ModalType } from "App/Store/ModalStore";
import { useTrackActivityNodeStore } from "App/Store/TrackActivity";
import { Color } from "Constants/Color";
import { MOBILE_MODE, Size, WIDE_MODE, X_WIDE_MODE } from "Constants/Size";
import { TrackActivityType } from "GraphQL/Schema";
import { isGuestAccountModel } from "Models/AccountModel/AccountModel";
import styled from "styled-components";
import { useCurrent } from "Utils/artistscardPlayer";

type Props = {
  isVisiblePlayer: boolean;
  onTogglePlayer: () => void;
};

export const RightController = ({ isVisiblePlayer, onTogglePlayer }: Props) => {
  const togglePlayer = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onTogglePlayer();
  };

  const currentTrack = useCurrent();
  const trackId = currentTrack?.track;
  const activity = useTrackActivityNodeStore(store => store.values()).filter(activity => activity.track.raw === trackId?.raw);
  const purchaseActivity = activity.find(({ type }) => type === TrackActivityType.Purchase)?.id ?? null;
  const isGuest = useAccountSelector(store => isGuestAccountModel(store.current));

  const openLogin = () => ModalStore.dispatch(ModalAction.replace(ModalType.SIGNIN));
  const openTrackDownload = () => {
    if (isGuest) {
      openLogin();
      return;
    }
    ModalStore.dispatch(ModalAction.open(ModalType.TRACK_DOWNLOAD, { track: trackId, purchaseActivity: purchaseActivity }));
  };

  return (
    <Layout>
      <Volume direction="left" />
      <SimpleButton onClick={openTrackDownload}>
        <DownArrowIcon name="download" />
        <ButtonText>DOWNLOAD</ButtonText>
      </SimpleButton>
      <BuyButton>
        <BuyButtonInfo trackId={trackId!} />
      </BuyButton>
      <UpDownButton direction={!isVisiblePlayer ? "up" : "down"} onClick={togglePlayer}>
        <ToggleIcon className="toggle-button" name="down-button" />
      </UpDownButton>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
  font-size: 0.8rem;
  user-select: none;
  z-index: 999;
  display: none;
  @media ${WIDE_MODE} {
    display: flex;
  }
`;

const SimpleButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 250px;
  border: 2px solid ${Color.Ac_Gray1};
  color: ${Color.Ac_White};
  border-radius: 2rem;
  padding: 0 1.5rem;

  margin: 0 0.3rem;
  height: 38px;
  cursor: pointer;
  transition: all 0.15s;
  &:hover {
    border: 2px solid ${Color.Ac_White};
  }

  display: none;
  @media ${X_WIDE_MODE} {
    display: flex;
  }
`;

const BuyButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 250px;
  margin-left: 0.25rem;
`;

const ButtonText = styled.span`
  font-size: 0.65rem;
  margin: 0 0.375rem;
  line-height: 1;
  font-weight: bold;
`;

const DownArrowIcon = styled(Icon)`
  width: 0.9rem;
`;

const ToggleIcon = styled(Icon)`
  width: 2.5rem;
  height: 2.5rem;
  fill: ${Color.GRAY_2};
  transition: fill 0.15s;
`;

const UpDownButton = styled.div<{ direction: "up" | "down" }>`
  transition: transform 0.3s;
  transform: ${props => (props.direction === "down" ? "rotate(0)" : "rotate(.5turn)")};
  cursor: pointer;
  padding: ${Size.PADDING_SMALL_PX};
  @media ${MOBILE_MODE} {
    padding: ${Size.PADDING_X_LARGE_PX};
  }
  &:active {
    .toggle-button {
      fill: ${Color.PURPLE_2};
    }
  }
`;

const Volume = styled(VolumeSlider)`
  margin: 0 ${Size.MARGIN_LARGE_PX} !important;
`;
