import { BasicTemplate } from "App/Templates/BasicTemplate";
import styled from "styled-components";
import { Form } from "./Form";

export const TrackDetail = () => {
  return (
    <BasicTemplate isSidebar={false}>
      <Layout>
        <Background />
        <Shadow />
        <Form />
      </Layout>
    </BasicTemplate>
  );
};

const Layout = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 120px;
  min-height: 100vh;
`;

const Background = styled.div`
  position: absolute;

  width: 100%;
  height: 100%;

  background: rgb(21, 30, 51);
  background: linear-gradient(78deg, rgba(21, 30, 51, 1) 0%, rgba(95, 88, 83, 1) 100%);
`;

const Shadow = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 0;
  background: rgba(18, 18, 24, 0.3);
  width: 100%;
  height: 100%;
`;
