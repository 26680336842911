import { Grade } from "GraphQL/Schema";
import { gql, useMutation } from "Lib/apollo-client";

const UPDATE_GRADE_PRICE = gql`
  mutation UPDATE_GRADE_PRICE($gradeId: Int!, $productPriceId: Int!) {
    updateGradePrice: update_grade_price(grade_id: $gradeId, product_price_id: $productPriceId) {
      id
      grade
      created_at
      updated_at
      product_price_id
      product_price {
        id
        price
        title
        description
        created_at
        is_approve
        type
        updated_at
      }
    }
  }
`;

type Result = {
  readonly updateGradePrice: Grade;
};

type Args = {
  readonly gradeId: number;
  readonly productPriceId: number;
};

export const useUpdateGradePrice = () => {
  const [fetch, { loading, data, error }] = useMutation<Result, Args>(UPDATE_GRADE_PRICE);

  return { updateGradePrice: fetch, loading, data, error };
};
