import { TrackHeader } from "App/Features/Header/TrackHeader";
import { TrackList } from "App/Features/List/TrackList";
import styled from "styled-components";
import { useMetadataSwitch } from "../hooks/useMetadataSwitch";
import { useHomeSelector } from "../Store";
import { TrackFooter } from "./TrackFooter";

export const Form = () => {
  useMetadataSwitch();
  const { edge, isLoadedTrackList, trackList } = useHomeSelector(store => store);

  return (
    <Layout>
      <TrackHeader />
      <TrackList trackList={trackList} isLoadedTrack={isLoadedTrackList} />
      {isLoadedTrackList && <TrackFooter edge={edge} />}
    </Layout>
  );
};

const Layout = styled.div``;
