import { Button, ButtonColor } from "App/Atomics/Button";
import { Checkbox } from "App/Atomics/Checkbox";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { AccountAction, AccountStore } from "App/Store/AccountStore";
import { useAuth } from "Utils/useAuth";
import { Color } from "Constants/Color";
import { HOME } from "Constants/Routes";
import { Modal } from "Lib/modal";
import { Toast } from "Lib/toast";
import { useNavigate } from "Lib/use-router";
import { ReactElement, useState } from "react";
import styled from "styled-components";
import { ModalAction, ModalStore } from "App/Store/ModalStore";
import {
  Close,
  CompleteWithdrawal,
  ErrorPasswordDifferent,
  Password,
  Secession,
  SecessionConfirm,
  SecessionDescription1,
  SecessionDescription2,
  SecessionDescription3,
  SecessionDescription4,
  SecessionDescription5
} from "Constants/Locale";
import { useDeleteAccount } from "GraphQL/Mutations/useDeleteAccount";
import { AuthForm } from "./style";
import { sha256 } from "Utils/hash";

type Props = {
  readonly isOpen: boolean;
};

export const DeleteAccountModal = ({ isOpen }: Props): ReactElement => {
  const onRequestClose = () => ModalStore.dispatch(ModalAction.closeAll());
  const username = AccountStore.getState().current.displayName;
  const [password, setPassword] = useState<string>("");
  const [agreeTerm, setAgreeTerm] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();

  const { fetch: deleteAccount } = useDeleteAccount();

  const onDelete = async (): Promise<void> => {
    try {
      await deleteAccount({
        variables: {
          password: sha256(password)
        }
      });
      AccountStore.dispatch(AccountAction.clear());
      auth.clear();
      onRequestClose();
      Toast.primary(CompleteWithdrawal.message());
      navigate(HOME);
    } catch (err) {
      console.log(err);
      Toast.primary(ErrorPasswordDifferent.message());
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Layout>
        <Container>
          <RowText size={TypoSize.F20} weight={TypoWeight.Bold}>
            <SecessionConfirm />
          </RowText>
          <RowText size={TypoSize.F15} color={Color.Ac_Gray1}>
            {`${username.text()}님과 헤어지려니 너무 아쉬워요`}
          </RowText>
          <Content>
            <RowText size={TypoSize.F15} weight={TypoWeight.Bold}>
              <SecessionDescription1 />
            </RowText>
            <ListItem>
              <SecessionDescription2 />
            </ListItem>
            <ListItem>
              <SecessionDescription3 />
            </ListItem>
          </Content>
          <Tooltip size={TypoSize.F12} color={Color.Ac_Gray0}>
            <SecessionDescription4 />
          </Tooltip>
          <AuthForm.LabelBox>
            <AuthForm.Input onChange={setPassword} type="password" placeholder={Password.message()} />
          </AuthForm.LabelBox>
          <InputBox>
            <InputCheck id="1" isChecked={agreeTerm} onToggle={() => setAgreeTerm(prev => !prev)} />
            <Typography size={TypoSize.F14}>
              <SecessionDescription5 />
            </Typography>
          </InputBox>
          <ButtonGroup>
            <StyledButton color={ButtonColor.Disable} onClick={onRequestClose}>
              <Close />
            </StyledButton>
            <StyledButton disabled={!agreeTerm} color={!agreeTerm ? ButtonColor.Gray : ButtonColor.PrimaryNotFill} onClick={onDelete}>
              <Secession />
            </StyledButton>
          </ButtonGroup>
        </Container>
      </Layout>
    </Modal>
  );
};

const Layout = styled.div`
  width: 400px;
  padding: 40px 20px 20px;
  border-radius: 5px;
  background-color: ${Color.Ac_Charcoal};
  pointer-events: auto;
  white-space: normal;
  text-align: center;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const RowText = styled(Typography)`
  margin-bottom: 20px;
`;
const Content = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 20px;
  white-space: normal;

  background-color: ${Color.Ac_Shadow};
  border-radius: 5px;
  margin-bottom: 10px;
`;

const Tooltip = styled(Typography)`
  align-self: flex-start;
  margin-bottom: 1rem;
`;

const ListItem = styled.li`
  font-size: 12px;
  & + & {
    margin-top: 8px;
  }
`;
const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;
const StyledButton = styled(Button)`
  width: 100%;
  font-weight: bold;
  & + & {
    margin-left: 8px;
  }
`;

const InputBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
  user-select: none;
`;

const InputCheck = styled(Checkbox)`
  margin-right: 8px;
`;
