export enum FirebaseConfig {
  BUSINESS_INFO = "BUSINESS_INFO",
  BUSINESS_INFO_EN = "BUSINESS_INFO_EN",
  BUSINESS_INFO_CONTACT_EMAIL = "BUSINESS_INFO_CONTACT_EMAIL"
}

export const defaultBusinessInfo = [
  { key: "사업자등록번호", value: ["558-88-00394"] },
  { key: "통신판매업등록번호", value: ["제 2023-서울성동-1306호"] },
  { key: "대표", value: ["정연승"] },
  { key: "회사명", value: ["(주)아티스츠카드"] },
  { key: "주소", value: ["서울특별시 성동구 자동차시장1길 49, 화성빌딩 9층 (용답동)"] },
  { key: "문의", value: ["02-6337-7284", "contact@artistscard.com"] }
];

export const defaultBusinessInfoEN = [
  { key: "Business Registration Number", value: ["558-88-00394"] },
  { key: "CEO", value: ["Micky Jung"] },
  { key: "Name Of Company", value: ["Artists Card Inc."] },
  { key: "Address", value: ["Hwasung Building, 9th Floor, 49 Jadongchasijang 1-gil, Seongdong-gu, Seoul, Republic of Korea"] },
  { key: "Contact", value: ["+82 2-6337-7284", "contact@artistscard.com"] }
];
