import {LanguageClient} from 'Utils/clients';

type Item = {
  readonly code: string;
  readonly locales: Record<string, string>;
};

export type LocaleItem = Item;

export const loadLocale = async (language: string): Promise<Item> => {
  const locales = await LanguageClient.get(`${language}/current/index.json?${Date.now()}`);
  return {code: language, locales};
};
