import { Button, ButtonColor, ButtonSize } from "App/Atomics/Button";
import { Icon } from "App/Atomics/Icon";
import { Input } from "App/Atomics/Input";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";

import { Color } from "Constants/Color";
import {
  AccountAuth,
  AccountAuthNotConfirm,
  AccountEmailCheck,
  AccountEmailTitle,
  AccountEmailUpdateError,
  CommonSendFull,
  AccountEmailVerifyRetryMessage,
  AccountEmailVerifyUpdateEmail,
  CommonDone,
  CommonSend,
  AccountEmailAlreadyExist
} from "Constants/Locale";
import { verifyUser } from "GraphQL/Mutations/verifyUser";
import { loadUser } from "GraphQL/Queries/loadUser";
import { Toast } from "Lib/toast";
import { useInterval } from "Lib/use-interval";
import { useParams } from "Lib/use-router";
import ms from "ms.macro";
import { useRef, useState } from "react";
import styled from "styled-components";
import { parseJwt, secondsToTime } from "Utils/format";
import { updateEmail } from "GraphQL/Mutations/updateEmail";
import { ModalAction, ModalStore, ModalType } from "App/Store/ModalStore";

export const VerifyForm = () => {
  const emailRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(0);
  const { token } = useParams();
  const defaultEmail = token ? parseJwt(token as string).email : null;

  const openConfirmModal = () => {
    ModalStore.dispatch(ModalAction.open(ModalType.CONFIRM, { title: AccountEmailTitle.message() }));
  };

  const onUpdateEmail = async (): Promise<boolean> => {
    const email = emailRef.current?.value ?? "";
    try {
      if (email.length) {
        await updateEmail({ token: token!, email });
        return true;
      } else {
        throw new Error();
      }
    } catch (err) {
      console.log(err);
      Toast.error(AccountEmailUpdateError.message());
      return false;
    }
  };

  const onVerifyUser = async (): Promise<void> => {
    try {
      setLoading(true);
      const response = await verifyUser(token!);
      if (!response) {
        throw new Error();
      }
      Toast.primary(`${CommonSendFull.message()}. ${AccountEmailCheck.message()}`, { timeout: 5000 });
      setTimer(300);
    } catch (err) {
      console.log(err);
      Toast.error(AccountEmailAlreadyExist.message(), { timeout: 3000 });
    } finally {
      setLoading(false);
    }
  };

  const updateAndVerify = async () => {
    const isSuccess = await onUpdateEmail();
    if (isSuccess) {
      await onVerifyUser();
    }
  };

  const checkSuccess = async (): Promise<void> => {
    const { data } = await loadUser(token ?? "");
    if (!data.userInfo.is_verification) {
      Toast.error(AccountAuthNotConfirm.message(), { timeout: 3000 });
      return;
    }
    openConfirmModal();
  };

  useInterval(() => {
    if (timer > 0) {
      setTimer(prev => prev - 1);
    }
  }, ms("1s"));

  return (
    <Layout>
      <Title>
        <Typography color={Color.Ac_White} weight={TypoWeight.Bold} size={TypoSize.F24}>
          <AccountAuth />
        </Typography>
      </Title>
      <Description>
        <Typography color={Color.Ac_Gray1} size={TypoSize.F15}>
          <AccountEmailVerifyUpdateEmail />
        </Typography>
      </Description>
      <InputWrap>
        <InputText
          ref={emailRef}
          size={TypoSize.F14}
          weight={TypoWeight.Bold}
          placeholder={AccountEmailTitle.message()}
          defaultValue={defaultEmail}
        />
        {loading ? (
          <Loading name="loading" fill={Color.AC_Carrot} />
        ) : timer < 1 ? (
          <SendButton
            style={{ width: "80px" }}
            disabled={loading}
            color={ButtonColor.White}
            size={ButtonSize.Xsmall}
            onClick={updateAndVerify}
          >
            <CommonSend />
          </SendButton>
        ) : (
          <Timer>
            <Typography color={Color.Ac_Gray2} weight={TypoWeight.Bold} size={TypoSize.F14}>
              {secondsToTime(timer)}
            </Typography>
          </Timer>
        )}
      </InputWrap>
      <StyledButton size={ButtonSize.Medium} onClick={checkSuccess}>
        <CommonDone />
      </StyledButton>
      <Typography color={Color.Ac_Gray0} size={TypoSize.F14}>
        - <AccountEmailCheck />
      </Typography>
      <Extra>
        -{" "}
        <AccountEmailVerifyRetryMessage
          b={({ children }) => (
            <ButtonText weight={TypoWeight.Bold} color={Color.Ac_Gray1} size={TypoSize.F14} onClick={onVerifyUser}>
              {children}
            </ButtonText>
          )}
        />
      </Extra>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 343px;
  padding: 20px;
  overflow: hidden;
`;
const Title = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

const InputWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Description = styled.p`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  white-space: normal;
`;

const SendButton = styled(Button)`
  height: 100%;
  font-weight: bold;
`;

const StyledButton = styled(Button)`
  width: 100%;
  font-size: 14px;
  margin-bottom: 8px;
`;

const ButtonText = styled(Typography)`
  text-decoration: underline;
  cursor: pointer;
`;

const Extra = styled.p`
  white-space: pre-line;
  color: ${Color.Ac_Gray0};
  font-size: 14px;
`;

const InputText = styled(Input.Text)`
  margin-right: 8px;
`;

const Timer = styled.div`
  margin: 0 4px;
  width: 54.7px;
  overflow: hidden;
`;

const Loading = styled(Icon)`
  margin: 0 6px;
  width: 54.7px;
  height: 40px;
`;
