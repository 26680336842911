import { Color } from "Constants/Color";
import { DESKTOP_MODE } from "Constants/Size";
import { ToastContainer as ReactToastContainer } from "react-toastify";
import styled from "styled-components";

export const ToastContainer = styled(ReactToastContainer).attrs({})`
  width: inherit;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  .Toastify__toast {
    font-weight: normal;
    margin-top: 8%;
    padding: 1.1vw 2vw 1vw;
    font-size: 12px;
    border-radius: 10px;
    background: ${Color.Ac_Charcoal};
    color: ${Color.Ac_White};
    border: 2px solid ${Color.Ac_Charcoal};
    @media ${DESKTOP_MODE} {
      font-size: 15px;
      margin-top: 5%;
    }
  }
  .Toastify__toast--error {
    border: 2px solid ${Color.Ac_Red1};
  }
  .Toastify__toast--warning {
    border: 2px solid ${Color.Ac_Magenta};
  }
  .Toastify__toast--success {
    border: 2px solid ${Color.Ac_Cyan};
  }
  .Toastify__toast--info {
    border: 2px solid ${Color.Ac_Gray2};
  }
  .Toastify__toast-body {
  }
  .Toastify__close-button {
    display: none;
  }
`;
