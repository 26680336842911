import { setRole } from "App/Common/setRole";
import { CCL_COMPANY_IDS, EFFECT_COMPANY_IDS, PUBLIC_DOMAIN_COMPANY_IDS, TRACK_COMPANY_IDS } from "Constants/CompanyIds";
import { MAX_FIRST_COUNT } from "Constants/Count";
import { useLoadRole } from "GraphQL/Queries/useLoadRole";
import { MetadataRightType } from "GraphQL/Schema";
import { MetdataKind } from "Models/GenreModel/GenreModel";
import { useEffect, useMemo } from "react";

interface Props {
  readonly kind: MetdataKind;
}

export const useRole = ({ kind }: Props) => {
  const companyIds = useMemo(() => {
    switch (kind) {
      case MetdataKind.Pd:
        return PUBLIC_DOMAIN_COMPANY_IDS;
      case MetdataKind.Ccl:
        return CCL_COMPANY_IDS;
      case MetdataKind.Effect:
        return EFFECT_COMPANY_IDS;
      case MetdataKind.Track:
        return TRACK_COMPANY_IDS;
    }
  }, [kind]);

  const isLicense = kind === MetdataKind.Pd || kind === MetdataKind.Ccl;

  const { data, loading } = useLoadRole({
    first: MAX_FIRST_COUNT,
    where: {
      type_role_class: "instrument",
      metadata_artist_relation__some: {
        metadata__some: {
          track_license__some: isLicense
            ? {
                country_code__in: ["ZZ", "KR"],
                copyright: true,
                neighboring: true
              }
            : undefined,
          metadata_company_relation__some: {
            type_kind: MetadataRightType.RightCompany,
            company__some: {
              company_id__in: companyIds
            }
          }
        }
      }
    }
  });

  useEffect(() => {
    if (data) {
      setRole(data.role, kind);
    }
  }, [data]);

  return { data, loading };
};
