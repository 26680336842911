import { Info } from "App/Atomics/AutoComplete/TextAutoComplete";
import { Button, ButtonColor, ButtonSize } from "App/Atomics/Button";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { UserTextAutoComplete } from "App/Molecules/AutoCompletes/User";
import { Color } from "Constants/Color";
import styled from "styled-components";
import { CachargePointAction, CachargePointStore, Section, useCachargePointSelector } from "../../Store/CechargePointStore";
import { SelectStyle, Style } from "../../Style";

export const First = () => {
  const { amount, userInfo } = useCachargePointSelector(store => store);

  const isDisable = !userInfo || amount <= 0;

  const next = () => CachargePointStore.dispatch(CachargePointAction.setSection(Section.Second));

  const setAmount = (amount: number) => CachargePointStore.dispatch(CachargePointAction.setAmount(amount));
  const setUserInfo = (info: Info) => CachargePointStore.dispatch(CachargePointAction.setUserInfo(info));

  return (
    <Layout>
      <Typography weight={TypoWeight.Bold} color={Color.Ac_White} size={TypoSize.F24}>
        포인트를 충전하기 위해 아래 정보를 입력해주세요.
      </Typography>
      <Container>
        <Style.Row>
          <Style.Caption>이메일*</Style.Caption>
          <UserTextAutoComplete
            defaultValue={userInfo}
            className="autocomplete"
            style={SelectStyle}
            onChange={info => {
              if (info) {
                setUserInfo(info);
              }
            }}
          />
        </Style.Row>
        <Style.Row>
          <Style.Caption>충전 금액</Style.Caption>
          <Style.SubCaption>충전 시킬 금액을 입력해 주세요</Style.SubCaption>
          <Style.InputText
            placeholder={"충전금액"}
            type="text"
            onChange={value => {
              const price = +value.split(",").join("");
              if (!isNaN(price)) {
                setAmount(price);
              }
            }}
            value={amount.toLocaleString()}
          />
        </Style.Row>
        <ButtonGroup>
          <SubmitButton
            size={ButtonSize.Small}
            color={isDisable ? ButtonColor.Disable : ButtonColor.Primary}
            disabled={isDisable}
            onClick={next}
          >
            다음
          </SubmitButton>
        </ButtonGroup>
      </Container>
    </Layout>
  );
};

const Layout = styled.div`
  max-width: 500px;
  width: 100%;
  margin: 4rem auto;
`;

const Container = styled.div`
  margin-top: 2rem;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
`;
const SubmitButton = styled(Button)`
  width: 100px;
`;
