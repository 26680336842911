import { ProductPriceType, TypeMetadataSubclass } from "GraphQL/Schema";
import { Time } from "Lib/time";
import { NumericID } from "Utils/id";

export class ProductPriceID extends NumericID {
  constructor(id: number) {
    super("productPrice", id);
  }
}

export type ProductPricePlayload = {
  readonly id: number;
  readonly title: string;
  readonly type: ProductPriceType;
  readonly description: string;
  readonly isApprove: boolean;
  readonly price: number;
  readonly createAt: string;
  readonly updateAt: string;
  readonly target: TypeMetadataSubclass;
};

export class ProductPriceModel {
  static form({ id, title, price, createAt, description, isApprove, type, updateAt, target }: ProductPricePlayload) {
    return new ProductPriceModel(
      new ProductPriceID(id),
      title,
      price,
      description,
      isApprove,
      type,
      Time(createAt),
      Time(updateAt),
      target
    );
  }

  constructor(
    readonly id: ProductPriceID,
    readonly title: string,
    readonly price: number,
    readonly description: string,
    readonly isApprove: boolean,
    readonly type: ProductPriceType,
    readonly createAt: Time,
    readonly updateAt: Time,
    readonly target: TypeMetadataSubclass
  ) {}
}
