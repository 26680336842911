import { LOAD_CART_COUNT } from "GraphQL/Queries/useLoadCartCount";
import { LOAD_TRACK_ACTIVITY } from "GraphQL/Queries/useLoadTrackActivity";

import { CartItem } from "GraphQL/Schema";
import { gql, useMutation } from "Lib/apollo-client";

const PUSH_CART_ITEMS = gql`
  mutation PUSH_CART_ITEMS($trackIds: [Int!]!) {
    pushCartItems: push_cart_items(track_id: $trackIds) {
      id
      type
      track_id
    }
  }
`;

type Result = {
  readonly pushCartItems: CartItem;
};

type Args = {
  readonly trackIds: number[];
};

export const usePushCartItems = () => {
  const [fetch, { loading }] = useMutation<Result, Args>(PUSH_CART_ITEMS, {
    refetchQueries: [LOAD_TRACK_ACTIVITY, LOAD_CART_COUNT],
    awaitRefetchQueries: true
  });

  return { pushCartItems: fetch, loading };
};
