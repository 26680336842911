import { PlaylistNodeStore } from "App/Store/PlaylistNodeStore";
import { Playlist } from "GraphQL/Schema";
import { AccountID } from "Models/AccountModel/AccountID";
import { PlaylistItemID } from "Models/PlaylistItemModel";
import { PlaylistID, PlaylistModel } from "Models/PlaylistModel";
import { TrackID } from "Models/TrackModel";

export const setPlaylist = (playlists: Playlist[]) => {
  const playlistMs = playlists?.map(playlist => {
    const playlistM = PlaylistModel.form({
      id: playlist.id,
      type: playlist.type,
      title: playlist.title,
      count: playlist.playlist_item?.length ?? 0,
      description: playlist.description ?? "",
      trackList:
        playlist.playlist_item!.map(track => ({
          id: new TrackID(track!.track_id),
          description: track?.description ?? "",
          item_id: new PlaylistItemID(track!.id)
        })) ?? [],
      updatedAt: playlist.updated_at,
      userId: new AccountID(playlist.user_id)
    });

    PlaylistNodeStore.set(new PlaylistID(playlist.id), playlistM);
    return playlistM;
  });
  return playlistMs;
};
