import { setCurationManagement } from "App/Common/setCurationManagement";
import { BASIC_FIRST_COUNT } from "Constants/Count";
import { useLoadCurationManagement } from "GraphQL/Queries/useLoadCurationManagement";
import { useLoaded } from "Lib/use-loaded";
import { useEffect } from "react";
import { LibraryCurationAction, LibraryCurationStore } from "../Store";

export const useCurationManagement = () => {
  const { data, loading, fetchMore } = useLoadCurationManagement({
    first: BASIC_FIRST_COUNT,
    fetchPolicy: "cache-first"
  });

  const { isLoaded } = useLoaded(loading);

  useEffect(() => {
    if (data) {
      const { curationManagement, edge } = data;
      const curationManagementMs = setCurationManagement(curationManagement);
      LibraryCurationStore.dispatch(LibraryCurationAction.setCurationManagement(curationManagementMs.map(({ id }) => id)));
      LibraryCurationStore.dispatch(LibraryCurationAction.setEdge(edge));
    }
  }, [data]);

  useEffect(() => {
    LibraryCurationStore.dispatch(LibraryCurationAction.setIsLoadedLibraryCuration(!loading));
  }, [loading]);

  return { isLoaded, fetchMore };
};
