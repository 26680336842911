import { Icon } from "App/Atomics/Icon";
import { useAccountSelector } from "App/Store/AccountStore";
import { ModalAction, ModalStore, ModalType } from "App/Store/ModalStore";
import { Color } from "Constants/Color";
import { useLikeTrack } from "GraphQL/Mutations/useLikeTrack";
import { useUnlikeTrack } from "GraphQL/Mutations/useUnlikeTrack";
import { isGuestAccountModel } from "Models/AccountModel/AccountModel";
import { TrackActivityID } from "Models/TrackActivity";
import { TrackID } from "Models/TrackModel";
import styled from "styled-components";

interface Props {
  readonly likeActivity: TrackActivityID | null;
  readonly track: TrackID;
  readonly isReloadPlaylist?: boolean;
}

export const FavoriteButton = ({ likeActivity, track, isReloadPlaylist = true }: Props) => {
  const { likeTrack, loading: loadingLike } = useLikeTrack({ isReloadPlaylist });
  const { unlikeTrack, loading: loadingUnlike } = useUnlikeTrack({ isReloadPlaylist });

  const isGuest = useAccountSelector(store => isGuestAccountModel(store.current));
  const openLogin = () => ModalStore.dispatch(ModalAction.replace(ModalType.SIGNIN));
  const tryToLikeTrack = async (id: number) => {
    if (isGuest) {
      openLogin();
      return;
    }
    if (loadingLike) {
      return;
    }
    try {
      await likeTrack({ variables: { trackId: id } });
    } catch (error) {
      console.log(error);
    }
  };

  const tryToUnLikeTrack = async (id: number) => {
    if (isGuest) {
      openLogin();
      return;
    }
    if (loadingUnlike) {
      return;
    }

    try {
      await unlikeTrack({ variables: { trackId: id } });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {!!likeActivity && (
        <StatusIconContainer onClick={() => tryToUnLikeTrack(track!.raw)}>
          <StatusIcon name="like-fill" />
        </StatusIconContainer>
      )}
      {!likeActivity && (
        <StatusIconContainer onClick={() => tryToLikeTrack(track!.raw)}>
          <StatusIcon name="like" />
        </StatusIconContainer>
      )}
    </>
  );
};

const StatusIconContainer = styled.div`
  height: 30px;
  min-width: 30px;

  padding: 0 5px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    path,
    rect {
      fill: ${Color.Ac_White};
    }
  }
`;

const StatusIcon = styled(Icon)`
  width: 2rem;

  path {
    fill: ${Color.Ac_Gray1};
  }

  rect {
    fill: rgba(0, 0, 0, 0) !important;
  }
`;
