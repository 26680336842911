import { Icon } from "App/Atomics/Icon";
import { CartItemContentLoader } from "App/Features/ContentLoaders/CartItemContentLoader";
import { CartItem } from "App/Features/Items/CartItem";
import { useTrackActivity } from "App/hooks/useTrackActivity";
import { useCartItemNodeList } from "App/Store/CartItemNodeStore";
import { NoCartItem } from "Constants/Locale";
import { CartItemID } from "Models/CartItemModel";

import styled from "styled-components";

interface Props {
  readonly isLoadedCartTrack: boolean;
  readonly cartItems: readonly CartItemID[];
}

export const CartTrackList = ({ isLoadedCartTrack, cartItems }: Props) => {
  const cartItemsModel = useCartItemNodeList(cartItems);

  useTrackActivity({
    isLoadedTrack: isLoadedCartTrack,
    trackList: cartItemsModel.map(({ track }) => track)
  });

  const isLoaded = isLoadedCartTrack;

  return (
    <Layout>
      {!isLoaded && (
        <TrackListContainer>
          {[...Array(3)].map((_, key) => (
            <CartItemContentLoader key={key} />
          ))}
        </TrackListContainer>
      )}
      {isLoaded && !!cartItems.length && (
        <TrackListContainer>
          {cartItems.map(id => (
            <CartItem key={id.key} id={id} />
          ))}
        </TrackListContainer>
      )}
      {isLoaded && !cartItems.length && (
        <NotFoundContainer>
          <NotFoundIcon name="search" />
          <NotFound>
            <NoCartItem />
          </NotFound>
        </NotFoundContainer>
      )}
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
  height: 240px;
  overflow-y: auto;
`;
const TrackListContainer = styled.div``;

const NotFoundContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const NotFound = styled.span`
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 1rem;
  white-space: normal;
  text-align: center;
`;

const NotFoundIcon = styled(Icon)`
  width: 2.5em;
  height: 2.5rem;
`;
