/* eslint-disable no-extra-label */
/* eslint-disable no-labels */

import {isSymbolToken, isTextToken, SymbolKind, SymbolToken, Token} from './createTokenList';

export const createNodeList = (tokenList: readonly Token[]): readonly Node[] => {
  const nodeList: Node[] = [];

  let cursor = 0;

  const current = (): Token => peek(0)!;

  const peek = (count: number): null | Token => tokenList[cursor + count] ?? null;

  const next = (): void => consume(1);

  const consume = (count: number): void => {
    cursor += count;
  };

  const recover = (index: number): void => {
    cursor = index;
  };

  const node = (): null | Node => tagNode() ?? variableNode() ?? textNode();

  const attributes = (attributes: Record<string, Primitive>, delimiter: SymbolKind, ender: SymbolKind): boolean => {
    outer: while (cursor < tokenList.length) {
      const token = current();
      if (token !== null) {
        if (isSymbolToken(token)) {
          switch (token.kind) {
            case ender: {
              break outer;
            }
            case SymbolKind.Whitespace:
            case SymbolKind.Linebreak: {
              next();
              continue outer;
            }
          }
        }

        if (isTextToken(token)) {
          next();

          const name = token.text;
          {
            const token = current();
            if (token !== null) {
              if (isTextToken(token)) {
                attributes[name] = true;
                continue outer;
              }

              if (isSymbolToken(token)) {
                switch (token.kind) {
                  case SymbolKind.Whitespace:
                  case SymbolKind.Linebreak: {
                    attributes[name] = true;
                    next();
                    continue outer;
                  }
                  case ender: {
                    attributes[name] = true;
                    break outer;
                  }
                  case delimiter: {
                    next();

                    inner: while (cursor < tokenList.length) {
                      const token = current();
                      if (token !== null && isSymbolToken(token)) {
                        switch (token.kind) {
                          case SymbolKind.Whitespace:
                          case SymbolKind.Linebreak: {
                            next();
                            continue inner;
                          }
                          default: {
                            break inner;
                          }
                        }
                      } else {
                        break;
                      }
                    }

                    const token = current();
                    if (token !== null) {
                      if (isTextToken(token)) {
                        switch (token.text) {
                          case 'null': {
                            attributes[name] = null;
                            next();
                            continue outer;
                          }
                          case 'true': {
                            attributes[name] = true;
                            next();
                            continue outer;
                          }
                          case 'false': {
                            attributes[name] = false;
                            next();
                            continue outer;
                          }
                          default: {
                            const numeric = +token.text;
                            if (!Number.isNaN(numeric)) {
                              attributes[name] = numeric;
                              next();
                              continue outer;
                            }
                          }
                        }
                      } else if (isSymbolToken(token) && token.kind === SymbolKind.DoubleQuote) {
                        const token = current();
                        if (token !== null) {
                          if (isTextToken(token)) {
                            attributes[name] = token.text;
                          } else {
                            attributes[name] = token.kind;
                          }

                          {
                            const token = peek(2);
                            if (token !== null && isSymbolToken(token) && token.kind === SymbolKind.DoubleQuote) {
                              consume(3);
                              continue outer;
                            }
                          }
                        }
                      }
                    }
                    break outer;
                  }
                }
              }
            }
          }
        }
      }

      return false;
    }

    return true;
  };

  const variableNode = (): null | VariableNode => {
    const currentCursor = cursor;

    const token = current();
    if (isSymbolToken(token) && token.kind === SymbolKind.LeftBrace) {
      const token = peek(1);
      if (token !== null && isSymbolToken(token) && token.kind === SymbolKind.Dollar) {
        const token = peek(2);
        if (token !== null && isTextToken(token)) {
          consume(3);

          const currentAttributes: Record<string, Primitive> = {};
          const name = token.text;
          {
            const token = current();
            if (token !== null && isSymbolToken(token) && token.kind === SymbolKind.LeftParens) {
              next();

              if (attributes(currentAttributes, SymbolKind.Colon, SymbolKind.RightParens)) {
                next();
              } else {
                recover(currentCursor);
                return null;
              }
            }
          }

          {
            const token = current();
            if (token !== null && isSymbolToken(token) && token.kind === SymbolKind.RightBrace) {
              next();
              return new VariableNode(name, currentAttributes);
            }
          }
        }
      }
    }

    recover(currentCursor);
    return null;
  };

  const tagNode = (): null | TagNode => {
    const currentCursor = cursor;

    const token = current();
    if (isSymbolToken(token) && token.kind === SymbolKind.LeftBracket) {
      const token = peek(1);
      if (token !== null && isTextToken(token)) {
        consume(2);

        const currentAttributes: Record<string, Primitive> = {};
        const name = token.text;
        {
          {
            const token = current();
            if (token !== null && isSymbolToken(token)) {
              switch (token.kind) {
                case SymbolKind.Whitespace:
                case SymbolKind.Linebreak: {
                  next();

                  if (!attributes(currentAttributes, SymbolKind.Equal, SymbolKind.RightBracket)) {
                    recover(currentCursor);
                    return null;
                  }
                }
              }
            }
          }

          const token = current();
          if (token !== null && isSymbolToken(token) && token.kind === SymbolKind.RightBracket) {
            next();

            const nodeList: Node[] = [];
            while (cursor < tokenList.length) {
              const currentNode = node();
              if (currentNode !== null) {
                nodeList.push(currentNode);
                continue;
              }

              const token = current();
              if (token !== null && isSymbolToken(token)) {
                if (token.kind === SymbolKind.LeftBracket) {
                  const token = peek(1);
                  if (token !== null && isSymbolToken(token) && token.kind === SymbolKind.Slash) {
                    break;
                  }
                }

                nodeList.push(new TextNode(token.kind));
                next();
                continue;
              }

              break;
            }

            const token = current();
            if (token !== null && isSymbolToken(token) && token.kind === SymbolKind.LeftBracket) {
              const token = peek(1);
              if (token !== null && isSymbolToken(token) && token.kind === SymbolKind.Slash) {
                const token = peek(2);
                if (token !== null && isTextToken(token) && token.text === name) {
                  const token = peek(3);
                  if (token !== null && isSymbolToken(token) && token.kind === SymbolKind.RightBracket) {
                    consume(4);
                    return new TagNode(name, currentAttributes, nodeList);
                  }
                }
              }
            }
          }
        }
      }
    }

    recover(currentCursor);
    return null;
  };

  const textNode = (): null | TextNode => {
    const currentToken = current();
    if (isTextToken(currentToken)) {
      next();
      return new TextNode(currentToken.text);
    }
    return null;
  };

  while (cursor < tokenList.length) {
    const currentNode = node();
    if (currentNode !== null) {
      nodeList.push(currentNode);
    } else {
      const token = current() as SymbolToken;
      nodeList.push(new TextNode(token.kind));
      next();
    }
  }

  return nodeList;
};

export type Node = VariableNode | TagNode | TextNode;

export class VariableNode {
  constructor(readonly name: string, readonly args: Record<string, Primitive>) {}
}

export const isVariableNode = (node: Node): node is VariableNode => node instanceof VariableNode;

export class TagNode {
  constructor(readonly name: string, readonly attributes: JSONObject, readonly children: readonly Node[]) {}
}

export const isTagNode = (node: Node): node is TagNode => node instanceof TagNode;

export class TextNode {
  constructor(readonly text: string) {}
}

export const isTextNode = (node: Node): node is TextNode => node instanceof TextNode;
