import { Icon } from "App/Atomics/Icon";
import { TypoSize } from "App/Atomics/Typography";
import { Color } from "Constants/Color";
import { Size } from "Constants/Size";
import { noop } from "lodash-es";
import { forwardRef, KeyboardEvent } from "react";
import { useState } from "react";
import { ChangeEvent, FocusEvent, ReactElement } from "react";
import styled from "styled-components";

export type InputSearchProps = Styleable & {
  readonly size?: TypoSize;
  readonly color?: string;
  readonly minLength?: number;
  readonly maxLength?: number;
  readonly autoComplete?: string;
  readonly defaultValue?: string;
  readonly value?: string;
  readonly placeholder?: string;

  readonly onChange?: (value: string) => void;
  readonly onFocus?: () => void;
  readonly onBlur?: (value: string) => void;
  readonly onClear?: () => void;
  readonly onEnter?: (value: string) => void;
};

export const Search = forwardRef<HTMLInputElement, InputSearchProps>(
  (
    {
      style,
      size = TypoSize.F12,
      color = Color.Ac_Black,
      onChange = noop,
      onBlur = noop,
      onFocus = noop,
      onEnter = noop,
      onClear,
      defaultValue,
      value,
      ...props
    }: InputSearchProps,
    ref
  ): ReactElement => {
    const [isFocus, setIsFocus] = useState(false);
    const change = (event: ChangeEvent<HTMLInputElement>) => {
      const nextValue = event.currentTarget.value;
      onChange(nextValue);
    };
    const blur = (event: FocusEvent<HTMLInputElement>) => {
      const nextValue = event.currentTarget.value;
      onBlur(nextValue);
      setIsFocus(false);
    };
    const focus = () => {
      setIsFocus(true);
      onFocus();
    };

    const enter = (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        const nextValue = e.currentTarget.value;
        onEnter(nextValue);
        setIsFocus(false);
      }
    };

    return (
      <Layout isFocus={isFocus}>
        <SearchInput
          ref={ref}
          type="text"
          placeholder="Search.."
          style={{ color, fontSize: size, ...style }}
          onChange={change}
          onBlur={blur}
          onFocus={focus}
          onKeyUp={enter}
          defaultValue={defaultValue}
          value={value}
          {...props}
        />
        <SearchIcon name="search" className="search-icon" />
        {onClear && (defaultValue?.length || value?.length) ? <ClearIcon name="clear" onClick={onClear} /> : null}
      </Layout>
    );
  }
);

const Layout = styled.div<{ isFocus: boolean }>`
  position: relative;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 2;
  border-radius: 2rem;
  transition: background-color 0.3s;

  input {
    color: ${Color.Ac_Gray4}!important;
  }
  padding: 11px 22px 11px 10px;
  width: 100%;
  background-color: #333;

  .search-icon {
    fill: "#999" !important;
    transition: fill 0.2s;
  }
`;

const SearchIcon = styled(Icon)``;
const ClearIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  cursor: pointer;
`;

const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  margin: 0 8px;
  color: ${Color.Ac_Black};
  background-color: ${Color.Transparent};
  font-size: ${Size.FONT_DEFAULT_PX};
  font-weight: bold;
  text-align: left;
  border: none;
  outline: none;

  &::placeholder {
    color: ${Color.Ac_Gray1};
  }
`;
