import { Color } from "Constants/Color";
import { ReactNode } from "react";
import styled from "styled-components";

const Layout = styled.div`
  a {
    text-decoration: underline;
  }
  &[data-mode="light"] {
    color: ${Color.Ac_Black};
    background-color: ${Color.Ac_White};
  }

  &[data-mode="dark"] {
    color: ${Color.Ac_White};
    background-color: ${Color.Ac_Black};
  }

  min-height: calc(100vh - 64px);

  white-space: normal;
  word-break: keep-all;
  user-select: text;

  html:lang(ja) &,
  html:lang(zh) & {
    word-break: break-all;
  }

  &[data-kind="terms-of-service"],
  &[data-kind="privacy-policy"] {
    h1 {
      font-size: 2rem;
      margin-bottom: 2.5rem;
    }

    h2 {
      font-size: 1.25rem;
      margin-top: 2rem;
    }

    h3 {
      font-size: 1.25rem;
      font-weight: normal;
      margin-top: 1.25rem;
    }

    h4 {
      font-size: 1.125rem;
      font-weight: normal;
      margin-top: 1.125rem;
    }

    p {
      margin-top: 1rem;
      margin-bottom: 1rem;
      line-height: 1.25rem;
      font-size: 0.875rem;
    }

    ul {
      margin: 1rem;

      p {
        display: inline;
      }
    }

    li {
      list-style: disc;
      list-style-position: inside;

      margin-left: 1rem;
      font-size: 0.875rem;
    }
  }

  &[data-kind="cookie-policy"] {
    & ~ h3 {
      font-size: 1rem;
      font-weight: bolder;
    }

    & ~ h4 {
      margin: 0.5rem 0;

      font-size: 0.875rem;
    }

    & ~ h5 {
      margin-left: 1rem;

      font-size: 0.875rem;
      font-weight: normal;
    }

    p {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  &[data-kind="terms-of-service"] .premium {
    & ~ h3 {
      font-size: 1rem;
      font-weight: bolder;
    }

    & ~ h4 {
      margin: 0.5rem 0;

      font-size: 0.875rem;
    }

    & ~ h5 {
      margin-left: 1rem;

      font-size: 0.875rem;
      font-weight: normal;
    }

    p {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  &[data-kind="legal-notice"] {
    h2 {
      margin-top: 1.5rem;
      margin-bottom: 1rem;
    }

    .internal-link {
      color: ${Color.Ac_Gray6};
    }

    .padding-block {
      padding-top: 1rem;
    }

    a {
      font-size: 0.875rem;
    }

    p {
      font-size: 0.875rem;

      &.copyright {
        margin-top: 1rem;

        color: ${Color.Ac_Gray6};
      }
    }
  }
`;

const Guard = styled.div`
  max-width: 1080px;
  width: 100%;

  margin: 0 auto;
  padding: 1rem;
`;

type Props = {
  mode?: "light" | "dark" | "transparent";
  kind: "legal-notice" | "privacy-policy" | "terms-of-service" | "cookie-policy";
  children: ReactNode;
};

export const Markdown = ({ mode = "light", kind, children, ...props }: Props) => (
  <Layout {...props} data-mode={mode} data-kind={kind} data-language={"ko"}>
    <Guard>{children}</Guard>
  </Layout>
);
