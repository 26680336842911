import { Color } from "Constants/Color";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import styled from "styled-components";

export const TrackTagInformationContentLoader = () => {
  return (
    <SkeletonTheme baseColor={Color.Ac_Charcoal} highlightColor={Color.Ac_Gray0}>
      <Layout>
        <Title>
          <Skeleton width={100} />
        </Title>
        <TagContainer>
          <TagTitle>
            <Skeleton width={50} />
          </TagTitle>
          <TagList>
            <Skeleton width={"100%"} height={30} />
          </TagList>
        </TagContainer>
        <TagContainer>
          <TagTitle>
            <Skeleton width={50} />
          </TagTitle>
          <TagList>
            <Skeleton width={"100%"} height={40} />
          </TagList>
        </TagContainer>
        <TagContainer>
          <TagTitle>
            <Skeleton width={50} />
          </TagTitle>
          <TagList>
            <Skeleton width={"100%"} height={40} />
          </TagList>
        </TagContainer>
      </Layout>
    </SkeletonTheme>
  );
};

const Layout = styled.div`
  position: relative;
  width: 90%;
  max-width: 1280px;
  padding: 50px;
  margin: 0 auto;

  background-color: ${Color.Ac_Shadow};
  border-radius: 1rem;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 50px;
`;

const TagContainer = styled.div`
  margin-bottom: 30px;
`;

const TagTitle = styled.div`
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 20px;
`;

const TagList = styled.div`
  width: 100%;
  height: 30px;
`;
