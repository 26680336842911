import { Icon } from "App/Atomics/Icon";
import { HOME } from "Constants/Routes";
import { useMounted } from "Lib/use-mounted";
import { Outlet, useNavigate } from "Lib/use-router";
import { useMemo } from "react";
import styled from "styled-components";
import { backgroundImages } from "./backgroundImages";

export const SignIn = () => {
  const { mounted } = useMounted();
  const navigate = useNavigate();
  const moveToHome = () => navigate(HOME);
  const index = useMemo(() => Math.floor(Math.random() * 6), []);
  return (
    <Layout>
      <Background data-loaded={mounted} src={backgroundImages[index]} />
      <Shadow />
      <Container>
        <Content>
          <Logo name="login-logo" onClick={moveToHome} />
          <Outlet />
        </Content>
      </Container>
    </Layout>
  );
};

const Layout = styled.div`
  flex: 1;
  background: #141517;
  position: relative;
  overflow: hidden;
`;

const Background = styled.img`
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.2s ease-in;
  object-fit: cover;
  &[data-loaded="true"] {
    opacity: 1;
  }
`;

const Shadow = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 0;
  background: rgba(18, 18, 24, 0.8);
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  height: 100vh;
  z-index: 3;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 1;
  overflow: hidden;
`;

const Logo = styled(Icon)`
  cursor: pointer;
  width: 343px;
  margin-bottom: 3rem;
`;
