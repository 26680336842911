import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { useUserNode } from "App/Store/UserNodeStore";
import { Color } from "Constants/Color";
import { GradeID } from "Models/GradeModel";
import styled from "styled-components";

type Props = {
  readonly id: GradeID;
};

export const UserItem = ({ id }: Props) => {
  const user = useUserNode(id);

  if (!user) {
    return null;
  }

  return (
    <Layout>
      <Raw>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          {user.id.raw}
        </Typography>
      </Raw>
      <Raw>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          {user.email}
        </Typography>
      </Raw>
      <Raw>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          {user.name}
        </Typography>
      </Raw>
      <Raw>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          {user.company}
        </Typography>
      </Raw>
      <Raw>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          {user.balance.toLocaleString()} 포인트
        </Typography>
      </Raw>
    </Layout>
  );
};

const Layout = styled.tr`
  padding: 1rem 1rem;
  border-bottom: 1px solid ${Color.Ac_Gray0};
`;

const Raw = styled.td`
  padding: 1rem 2rem;
  text-align: center;

  &:not(:last-child) {
    border-right: 1px dashed ${Color.Ac_Gray0};
  }
`;
