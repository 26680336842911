import { Track_License, Track_LicenseWhereUniqueInput } from "GraphQL/Schema";
import { gql, useQuery } from "Lib/apollo-client";

const LOAD_TRACK_LICENSE = gql`
  query LOAD_TRACK_LICENSE($where: Track_licenseWhereUniqueInput, $first: Int) {
    trackLicense: track_license(where: $where, first: $first) {
      id
      copyright
      neighboring
      notice
      metadata_id
      country_code
    }
  }
`;

type Result = {
  readonly trackLicense: Track_License[];
};

type Args = {
  readonly where?: Track_LicenseWhereUniqueInput;
  readonly first?: number;
};

export const useLoadTrackLicense = ({ first, where }: Args) => {
  const { data, loading } = useQuery<Result, Args>(LOAD_TRACK_LICENSE, {
    variables: {
      where,
      first
    }
  });

  return { loading, data };
};
