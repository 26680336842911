import { ArtistInformationContentLoader } from "App/Features/ContentLoaders/ArtistInformationContentLoader";
import { ArtistID } from "Models/ArtistModel";
import styled from "styled-components";
import { ArtistInformation } from "../../ArtistInformation";

interface Props {
  readonly isLoadedArtist: boolean;
  readonly id: ArtistID;
}

export const ArtistInformationForm = ({ isLoadedArtist, id }: Props) => {
  return <Layout>{isLoadedArtist ? <ArtistInformation id={id} /> : <ArtistInformationContentLoader />}</Layout>;
};

const Layout = styled.div``;
