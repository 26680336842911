import { Info } from "App/Atomics/AutoComplete/TextAutoComplete";
import { loadTrackAutoComplete } from "GraphQL/Queries/loadTrackAutoComplete";

export const findTrackList = async (keyword: string): Promise<readonly Info[]> => {
  try {
    const data = await loadTrackAutoComplete({
      first: 20,
      word: keyword
    });
    if (data.data) {
      return data.data.metadata.map(item => {
        return { id: item.metadata_id.toString(), name: item.title ?? "-" };
      });
    }
  } catch (error) {}
  return [];
};
