import { PLAYLIST_FRAGMENT } from "GraphQL/Fragment/PlaylistFragment";
import { gql, useMutation } from "Lib/apollo-client";

const UPDATE_CURATION = gql`
  ${PLAYLIST_FRAGMENT}
  mutation UPDATE_CURATION($playlistId: Int!, $title: String, $description: String) {
    updateCuration: update_playlist(input: { playlist_id: $playlistId, description: $description, title: $title }) {
      ...PlaylistFragment
    }
  }
`;

type Result = {
  readonly updateCuration: boolean;
};

type Args = {
  readonly playlistId: number;
  readonly title: string;
  readonly description?: string;
};

export const useUpdateCuration = () => {
  const [fetch, { loading, data, error }] = useMutation<Result, Args>(UPDATE_CURATION);

  return { updatePlaylist: fetch, loading, data, error };
};
