import { TypoSize, TypoWeight } from "App/Atomics/Typography";
import { Color } from "Constants/Color";
import { Size } from "Constants/Size";
import { noop } from "lodash-es";
import { forwardRef } from "react";
import { ChangeEvent, FocusEvent, ReactElement } from "react";
import styled from "styled-components";
import { InputType } from "../common";

export type InputTextProps = Styleable & {
  readonly type?: InputType;
  readonly weight?: TypoWeight;
  readonly size?: TypoSize;
  readonly color?: string;
  readonly min?: number;
  readonly max?: number;
  readonly step?: number;
  readonly minLength?: number;
  readonly maxLength?: number;
  readonly autoComplete?: string;
  readonly defaultValue?: string;
  readonly value?: string;
  readonly a11y?: string;
  readonly placeholder?: string;
  readonly isDisabled?: boolean;
  readonly isRequired?: boolean;
  readonly isReadonly?: boolean;
  readonly pattern?: string;

  readonly onChange?: (value: string) => void;
  readonly onFocus?: () => void;
  readonly onBlur?: (value: string) => void;
};

export const Text = forwardRef<HTMLInputElement, InputTextProps>(
  (
    {
      style,
      type = "text",
      weight = TypoWeight.Regular,
      size = TypoSize.F12,
      color = Color.Ac_Black,
      pattern,
      a11y,
      autoComplete,
      isDisabled,
      isRequired,
      isReadonly,
      onChange = noop,
      onBlur = noop,
      onFocus = noop,
      ...props
    }: InputTextProps,
    ref
  ): ReactElement => {
    const change = (event: ChangeEvent<HTMLInputElement>) => {
      const nextValue = event.currentTarget.value;
      onChange(nextValue);
    };
    const blur = (event: FocusEvent<HTMLInputElement>) => {
      const nextValue = event.currentTarget.value;
      onBlur(nextValue);
    };

    return (
      <InputLayout
        ref={ref}
        type={type}
        alt={a11y}
        readOnly={isReadonly}
        required={isRequired}
        disabled={isDisabled}
        autoComplete={autoComplete}
        style={{ fontWeight: weight, color, fontSize: size, ...style }}
        onChange={change}
        onBlur={blur}
        onFocus={onFocus}
        {...props}
      />
    );
  }
);

const InputLayout = styled.input`
  color: ${Color.Ac_Black};
  background-color: ${Color.Ac_White};
  font-size: ${Size.FONT_SMALL_PX};
  text-align: left;
  border: 1px solid ${Color.Ac_Gray3};
  border-radius: 3px;
  padding: 11px 10px;
  transition: all 0.15s;
  outline: none;
  width: 100%;

  &::placeholder {
    color: ${Color.Ac_Gray1};
  }

  &:hover {
    border-color: ${Color.Ac_Gray1};
  }

  &:focus {
    border-color: ${Color.AC_Carrot};
  }

  &:disabled {
    color: ${Color.Ac_Gray2} !important;
    border-color: ${Color.Ac_Gray4};
    cursor: not-allowed;
  }
`;
