import { LOAD_PRODUCT_PRICE } from "GraphQL/Queries/useLoadProductPrice";
import { gql, useMutation } from "Lib/apollo-client";

const UNAPPROVE_PRICE = gql`
  mutation UNAPPROVE_PRICE($productPriceId: Int!) {
    unapprovePrice: unapproved_price(product_price_id: $productPriceId)
  }
`;

type Result = {
  readonly unapprovePrice: boolean;
};

type Args = {
  readonly productPriceId: number;
};

export const useUnApprovePrice = () => {
  const [fetch, { loading, error }] = useMutation<Result, Args>(UNAPPROVE_PRICE, {
    refetchQueries: [LOAD_PRODUCT_PRICE]
  });

  return { unapprovePrice: fetch, loading, error };
};
