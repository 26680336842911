import { Typography } from "App/Atomics/Typography";
import { Color } from "Constants/Color";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import styled from "styled-components";

export const CartItemContentLoader = () => {
  return (
    <SkeletonTheme baseColor={Color.Ac_Charcoal} highlightColor={Color.Ac_Gray0}>
      <Track>
        <FlexAudioContainer>
          <ButtonContainer>
            <Skeleton height={"40px"} borderRadius={"2rem"} />
          </ButtonContainer>
        </FlexAudioContainer>
        <FlexRightContainer>
          <FlexRightTopContainer>
            <FlexInformationContainer>
              <TrackTitleLayout>
                <TrackTitleContainer>
                  <TrackTitle>
                    <Skeleton />
                  </TrackTitle>
                </TrackTitleContainer>
              </TrackTitleLayout>
            </FlexInformationContainer>
            <FlexBuyContainer>
              <Price>
                <Skeleton width={"100%"} />
              </Price>
            </FlexBuyContainer>
            <FlexCloseContainer>
              <StatusIconContainer>
                <Skeleton width={"20px"} height={"15px"} />
              </StatusIconContainer>
            </FlexCloseContainer>
          </FlexRightTopContainer>
        </FlexRightContainer>
      </Track>
    </SkeletonTheme>
  );
};

const FlexRightTopContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
`;

const FlexAudioContainer = styled.div`
  flex: 0 0 54px;
`;

const FlexRightContainer = styled.div`
  flex: 1 0 10px;
  min-width: 0;
  flex-direction: column;
  display: flex;
  align-items: center;
`;

const FlexInformationContainer = styled.div`
  flex: 5 0 50%;
  min-width: 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TrackTitleLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex: 9 0 50px;
  font-size: 0.9rem;
  min-width: 0;
`;

const TrackTitleContainer = styled.div`
  margin-right: 0.625rem;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TrackTitle = styled.span`
  font-size: 0.9rem;
  font-weight: bold;
`;

const Track = styled.div`
  padding: 1rem 0;
  display: flex;
  align-items: center;
`;

const ButtonContainer = styled.div`
  width: 40px;
  height: 40px;
`;

const FlexCloseContainer = styled.div`
  flex: 1 0 20px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const StatusIconContainer = styled.div`
  height: 1.5rem;
  min-width: 1.5rem;

  padding: 0 5px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    path,
    rect {
      fill: ${Color.Ac_White};
    }
  }
`;

const FlexBuyContainer = styled.div`
  flex: 1 0 50px;
  margin: 0 0.5rem;
  display: flex;
  align-items: center;
`;

const Price = styled(Typography)`
  width: 100%;
  font-size: 1rem;
  font-weight: bold;
`;
