import { Button, ButtonColor, ButtonSize } from "App/Atomics/Button";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { Color } from "Constants/Color";
import { FaqDescription, FrequentlyAsk, ViewFaq } from "Constants/Locale";
import { DESKTOP_MODE } from "Constants/Size";
import styled from "styled-components";

export const Support = () => {
  return (
    <Layout>
      <Title size={TypoSize.F32} weight={TypoWeight.Bold}>
        <FrequentlyAsk />
      </Title>
      <SubTitle>
        <FaqDescription />
      </SubTitle>
      <Link href={"https://support.artistscard.com/bgm-manager/faq"}>
        <SimpleButton size={ButtonSize.Xsmall} color={ButtonColor.Primary}>
          <ViewFaq />
        </SimpleButton>
      </Link>
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: normal;
  text-align: center;

  @media ${DESKTOP_MODE} {
    padding: 150px;
  }
`;

const Title = styled(Typography)`
  margin-bottom: 2rem;
`;

const SubTitle = styled(Typography)`
  margin-bottom: 2rem;
`;

const Link = styled.a`
  color: ${Color.AC_Carrot};
  text-decoration: underline;
  font-weight: bold;
  font-size: 0.8rem;
`;

const SimpleButton = styled(Button)``;
