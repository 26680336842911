import { useCurrentLanguage } from "Lib/localization";
import { GenreModel } from "Models/GenreModel/GenreModel";
import { RoleModel } from "Models/RoleModel";

export const TitleModelSort = () => {
  const languageCode = useCurrentLanguage();
  const sortGenreTitleAsc = (firstGenreModel: GenreModel, secondGenreModel: GenreModel) =>
    firstGenreModel.title.text(languageCode) < secondGenreModel.title.text(languageCode)
      ? -1
      : firstGenreModel.title.text(languageCode) > secondGenreModel.title.text(languageCode)
      ? 1
      : 0;

  const sortRoleTitleAsc = (firstRoleModel: RoleModel, secondRoleModel: RoleModel) =>
    firstRoleModel.title.text(languageCode) < secondRoleModel.title.text(languageCode)
      ? -1
      : firstRoleModel.title.text(languageCode) > secondRoleModel.title.text(languageCode)
      ? 1
      : 0;
  return { sortGenreTitleAsc, sortRoleTitleAsc };
};
