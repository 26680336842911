import { useInterval } from "Lib/use-interval";
import { serviceHealthClient } from "Utils/clients";

const TIME = 1000 * 60 * 5;

export const IntervalServiceHealthCheck = () => {
  useInterval(() => {
    serviceHealthClient.get("/");
  }, TIME);
};
