import { setCurationManagement } from "App/Common/setCurationManagement";
import { CurationManagementNodeStore } from "App/Store/CurationManagementStore";

import { useLoadCurationManagement } from "GraphQL/Queries/useLoadCurationManagement";
import { useLoaded } from "Lib/use-loaded";
import { useEffect } from "react";

export const useCurationManagementDetail = (id: number) => {
  const { data, loading } = useLoadCurationManagement({
    where: {
      playlist_id: +id!
    },
    isForce: true,
    fetchPolicy: "no-cache"
  });

  const { isLoaded } = useLoaded(loading);

  useEffect(() => {
    CurationManagementNodeStore.clear();
    if (data) {
      const { curationManagement } = data;
      setCurationManagement(curationManagement);
    }
  }, [data]);

  useEffect(() => {
    return () => {
      CurationManagementNodeStore.clear();
    };
  }, []);

  return { isLoaded };
};
