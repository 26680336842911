import { Button, ButtonColor } from "App/Atomics/Button";
import { Icon } from "App/Atomics/Icon";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { useAccountSelector } from "App/Store/AccountStore";
import { useWallet } from "App/Templates/BasicTemplate/hooks/useWallet";
// import { useAccountSelector } from "App/Store/AccountStore";
import { Color } from "Constants/Color";
import { BackToCart, BackToHome, PurchaseCompleted, TotalPaymentAmount } from "Constants/Locale";
import { CART, HOME } from "Constants/Routes";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { formatDate } from "Utils/format";

export const Form = () => {
  const navigate = useNavigate();
  const moveToHome = () => navigate(HOME);
  const moveToCart = () => navigate(CART);

  const [search] = useSearchParams();

  const userId = search.get("userId");
  const date = search.get("date")!;
  const amount = +(search.get("amount") ?? 0);

  const { loading } = useWallet({});
  const wallet = useAccountSelector(store => store.wallet);
  const user = useAccountSelector(store => store.current);

  if (user.id.raw.toString() !== userId) {
    navigate(-1);
  }

  if (loading) {
    return null;
  }
  return (
    <Layout>
      <Center>
        <CheckIcon name="payment-complete"></CheckIcon>
        <Typography weight={TypoWeight.Bold} size={TypoSize.F24}>
          <PurchaseCompleted />
        </Typography>
        <Typography size={TypoSize.F13} color={Color.Ac_Gray1}>
          <TotalPaymentAmount />: ₩{amount?.toLocaleString() ?? "-"}
        </Typography>
        <Belt>
          <Typography size={TypoSize.F13} color={Color.Ac_Gray4}>
            {formatDate(new Date(date!))}
          </Typography>
          <Stick size={TypoSize.F13} color={Color.Ac_Gray2}>
            |
          </Stick>
          <Typography size={TypoSize.F13} color={Color.AC_Carrot}>
            충전 후 포인트: {wallet?.balance.toLocaleString()}
          </Typography>
        </Belt>
      </Center>
      <ButtonContainer>
        <Button color={ButtonColor.Disable} onClick={moveToHome}>
          <BackToHome />
        </Button>
        <Button color={ButtonColor.Primary} onClick={moveToCart}>
          <BackToCart />
        </Button>
      </ButtonContainer>
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
  background-color: ${Color.Ac_Charcoal};
  margin-top: 30px;
  padding: 120px 40px 40px;
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-bottom: 100px;
`;

const CheckIcon = styled(Icon)`
  width: 90px;
  height: 90px;
  margin-bottom: 30px;
`;

const Belt = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 30px;
  padding: 10px 46px;
  background-color: ${Color.Ac_Gray};
  border-radius: 1rem;
`;

const Stick = styled(Typography)`
  margin: 0 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  button {
    width: 100%;
    font-weight: bold;
    &:first-child {
      margin-right: 1rem;
    }
  }
`;
