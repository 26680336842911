import { User } from "GraphQL/Schema";
import { gql, useLazyQuery } from "Lib/apollo-client";

const LOAD_USER_INFO = gql`
  query LoadUserInfo {
    userInfo {
      id
      email
      tier
      name
      company
      is_verification
    }
  }
`;

interface Result {
  readonly userInfo: User;
}

export const useLazeLoadUserInfo = () => {
  const [loadUserInfo, { data, loading, error }] = useLazyQuery<Result>(LOAD_USER_INFO, {
    fetchPolicy: "network-only",
    context: {
      headers: {
        endpoint: "auth"
      }
    }
  });
  return { loadUserInfo, data, loading, error };
};
