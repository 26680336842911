import { ArtistNodeStore } from "App/Store/ArtistNodeStore";

import { Metadata } from "GraphQL/Schema";
import { ArtistID, ArtistModel } from "Models/ArtistModel";
import { TrackID } from "Models/TrackModel";
import { setTrack } from "./setTrack";

export const setMetadataTrack = (metadataTrackList: Metadata[]) => {
  for (const metadataTrack of metadataTrackList) {
    const track = metadataTrack.track![0]!;

    setTrack([track]);

    if (!ArtistNodeStore.get(new ArtistID(track.artist_id!))) {
      const artistM = ArtistModel.form({
        id: track.artist_id!,
        name: track.artist_name!,
        roleList: []
      });
      ArtistNodeStore.set(new ArtistID(track.artist_id!), artistM);
    }
  }

  return metadataTrackList.map(({ metadata_id }) => new TrackID(+metadata_id));
};
