import { GenreNodeStore } from "App/Store/GenreNodeStore";
import { RoleNodeStore } from "App/Store/RoleNodeStore";
import { useCurrentLanguage } from "Lib/localization";
import { GenreID } from "Models/GenreModel/GenreModel";
import { RoleID } from "Models/RoleModel";
import styled from "styled-components";
import { secondsToTime } from "Utils/format";

type Props = {
  type: string;
  id: string;
};

export const SwitchTag = ({ type, id }: Props) => {
  const langugeCode = useCurrentLanguage();

  const firstDuration = id?.split("-")[0] ? secondsToTime(+id?.split("-")[0]) : "";
  const secondDuration = id?.split("-")[1] ? secondsToTime(+id?.split("-")[1]) : "";
  return (
    <Layout>
      {type}:
      <>
        {(type === "genres" || type === "moods") && <>{GenreNodeStore.get(new GenreID(id))?.title.text(langugeCode)}</>}
        {type === "roles" && <>{RoleNodeStore.get(new RoleID(id))?.title.text(langugeCode)}</>}
        {!(type === "genres" || type === "moods" || type === "roles" || type === "duration") && <>{id.replaceAll("+", " ")}</>}
        {type === "duration" && <>{[firstDuration, secondDuration].join(" - ")}</>}
      </>
    </Layout>
  );
};

const Layout = styled.div``;
