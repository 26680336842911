import { configs } from "Config";
import { defaultBusinessInfo, FirebaseConfig } from "Constants/FirebaseConfig";
import { initializeApp } from "firebase/app";
import { getRemoteConfig, fetchAndActivate, getAll } from "firebase/remote-config";

initializeApp(configs.firebaseAuth);

export const remoteConfig = getRemoteConfig();
remoteConfig.settings.minimumFetchIntervalMillis = 1_800_000;

remoteConfig.defaultConfig = {
  [FirebaseConfig.BUSINESS_INFO]: JSON.stringify(defaultBusinessInfo),
  [FirebaseConfig.BUSINESS_INFO_CONTACT_EMAIL]: "contact@artistscard.com"
};

fetchAndActivate(remoteConfig);
export const getAllRemoteConfig = () => getAll(remoteConfig);
