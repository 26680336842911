import { Button, ButtonSize } from "App/Atomics/Button";
import { Icon } from "App/Atomics/Icon";
import { Input } from "App/Atomics/Input";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { Color } from "Constants/Color";
import {
  AccountEmailInvalid,
  AccountEmailTitle,
  AccountPasswordReset,
  AccountResetPasswordMessage,
  AccountResetPasswordNotExist,
  AccountResetPasswordSubtitle,
  CommonConfirm
} from "Constants/Locale";
import { HOME } from "Constants/Routes";
import { useSendResetPasswordEmail } from "GraphQL/Mutations/useSendResetPasswordEmail";
import { Toast } from "Lib/toast";
import { LOADED, useLoading } from "Lib/use-loading";
import { useNavigate } from "Lib/use-router";
import { FormEvent, useRef } from "react";
import styled from "styled-components";
import isEmail from "validator/lib/isEmail";

export const FindPasswordForm = () => {
  const inputEmail = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const loading = useLoading(LOADED);
  const { sendResetPasswordEmail } = useSendResetPasswordEmail();
  const findPassword = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    const email = inputEmail.current!.value;
    if (!isEmail) {
      Toast.primary(AccountEmailInvalid.message());
      return;
    }
    try {
      loading.setLoading();
      const { errors, data } = await sendResetPasswordEmail({ variables: { email } });
      loading.setLoaded();
      if (errors || !data?.sendResetPasswordEmail) {
        throw errors;
      }
      if (data) {
        Toast.primary(AccountResetPasswordMessage.message());
        navigate(HOME);
      }
    } catch (err) {
      Toast.primary(AccountResetPasswordNotExist.message());
    }
  };
  return (
    <Layout onSubmit={findPassword}>
      <Title>
        <Typography color={Color.Ac_White} weight={TypoWeight.Bold} size={TypoSize.F24}>
          <AccountPasswordReset />
        </Typography>
      </Title>
      <Description>
        <Typography color={Color.Ac_Gray1} size={TypoSize.F15}>
          <AccountResetPasswordSubtitle />
        </Typography>
      </Description>
      <InputWrap>
        <Input.Text ref={inputEmail} type="email" placeholder={AccountEmailTitle.message()} />
      </InputWrap>
      {loading.isLoaded ? (
        <StyledButton type="submit" size={ButtonSize.Medium}>
          <CommonConfirm />
        </StyledButton>
      ) : (
        <StyledButton disabled={true} size={ButtonSize.Medium}>
          <Loading name="loading" />
        </StyledButton>
      )}
    </Layout>
  );
};

const Layout = styled.form`
  display: flex;
  flex-direction: column;
  width: 343px;
  overflow: hidden;
`;
const Title = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const InputWrap = styled.div`
  width: 100%;
  margin-bottom: 20px;
  & + & {
    margin-top: 8px;
  }
`;
const Description = styled.p`
  margin-bottom: 20px;
  white-space: normal;
`;

const StyledButton = styled(Button)`
  width: 100%;
  font-size: 14px;
`;

const Loading = styled(Icon)`
  width: 1rem;
  height: 1rem;
`;
