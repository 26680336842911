import { Color } from "Constants/Color";
import { ModifiedDate, Title, TrackCount } from "Constants/Locale";
import { DESKTOP_MODE } from "Constants/Size";
import styled from "styled-components";

export const CurationHeader = () => {
  return (
    <Layout>
      <PlayListNameContainer>
        <Title />
      </PlayListNameContainer>
      <ModifyDateContainer>
        <ModifiedDate />
      </ModifyDateContainer>
      <MusicCountContainer>
        <TrackCount />
      </MusicCountContainer>
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
  display: flex;
  padding: 0 30px 10px;

  border-bottom: 1px solid ${Color.Ac_Gray};
`;

const Container = styled.div`
  display: flex;
  align-items: center;

  font-size: 0.8rem;
  font-weight: bold;
  color: ${Color.Ac_Gray0};
`;

const PlayListNameContainer = styled(Container)`
  flex: 3;
  padding-right: 5rem;
`;

const MusicCountContainer = styled(Container)`
  flex: 1;
  display: none;
  @media ${DESKTOP_MODE} {
    display: flex;
  }
`;

const ModifyDateContainer = styled(Container)`
  flex: 1;
`;
