import { WatchQueryFetchPolicy } from "@apollo/client";
import { Edge } from "Constants/Edge";
import { EDGE_FRAGMENT } from "GraphQL/Fragment/EdgeFragment";
import { PLAYLIST_FRAGMENT } from "GraphQL/Fragment/PlaylistFragment";
import { BgmPlaylistWhereInput, Playlist } from "GraphQL/Schema";
import { gql, useQuery } from "Lib/apollo-client";

export const LOAD_CURATION = gql`
  ${EDGE_FRAGMENT}
  ${PLAYLIST_FRAGMENT}
  query LOAD_CURATION($first: Int, $page: Int, $where: BgmPlaylistWhereInput) {
    curation: playlist(first: $first, page: $page, where: $where, orderBy: [id__DESC]) {
      ...PlaylistFragment
    }
    edge(node: Playlist, is_force: true, where: { playlist: $where }, first: $first, page: $page) {
      ...EdgeFragment
    }
  }
`;

type Result = {
  readonly curation: Playlist[];
  readonly edge: Edge;
};

type Args = {
  readonly first?: number;
  readonly page?: number;
  readonly where?: BgmPlaylistWhereInput;
  readonly fetchPolicy?: WatchQueryFetchPolicy;
};

export const useLoadCuration = ({ first, page, where, fetchPolicy = "network-only" }: Args) => {
  const { data, loading } = useQuery<Result, Args>(LOAD_CURATION, {
    variables: {
      first,
      page,
      where
    },
    fetchPolicy
  });

  return { loading, data };
};
