import { LOAD_POINT_TRANSACTION } from "GraphQL/Queries/useLoadPointTransaction";
import { PayletterBill } from "GraphQL/Schema";
import { gql } from "Lib/apollo-client";
import { paymentClient } from "Utils/clients";

const PAYLETTER_CANCEL = gql`
  mutation PAYLETTER_CANCEL($billId: Int!) {
    cancel(bill_id: $billId) {
      id
    }
  }
`;

type Result = {
  readonly cancel: PayletterBill;
};

type Args = {
  readonly billId: number;
  readonly token?: string;
};

export const payletterCancel = async ({ billId, token }: Args) => {
  const { data: result } = await paymentClient.mutate<Result, Args>({
    mutation: PAYLETTER_CANCEL,
    refetchQueries: [LOAD_POINT_TRANSACTION],
    awaitRefetchQueries: true,
    variables: {
      billId: billId
    },
    context: {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  });

  return result?.cancel;
};
