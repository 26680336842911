import { Document, Page, Text, StyleSheet, Image, View, Font, Link } from "@react-pdf/renderer";
import { useLicenseNode } from "App/Store/LicenseNodeStore";
import { useTrackActivityNodeStore } from "App/Store/TrackActivity";
import { useTrackNode } from "App/Store/TrackNodeStore";
import logo from "Assets/Images/logo.png";
import { configs } from "Config";
import { LICENSE } from "Constants/Routes";
import { TrackActivityType } from "GraphQL/Schema";
import { LicenseID } from "Models/LicenseModel";
import { formatDate } from "Utils/format";

type Props = {
  readonly id: LicenseID;
};

export const LicenseDocument = ({ id }: Props) => {
  const license = useLicenseNode(id);
  const track = useTrackNode(license?.trackId);
  const activity = useTrackActivityNodeStore(store => store.values()).filter(activity => activity.track.raw === license?.trackId.raw);
  const purhcaseActivity = activity.find(({ type }) => type === TrackActivityType.Purchase);

  if (!license) {
    console.log("test1");
    return <Document />;
  }

  if (!track) {
    console.log("test2");
    return <Document />;
  }

  if (!purhcaseActivity) {
    console.log("test3");
    return <Document />;
  }

  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.imageContainer}>
          <Image style={styles.image} src={logo} />
        </View>
        <Text style={styles.header}>BGM Manager Track License Certificate</Text>
        <Text style={styles.subHeader}>License Number: {license?.id.raw}</Text>
        <Text style={styles.subHeader}>
          License owner: {license?.name.normalize("NFC")} {license.company ? `(${license.company.normalize("NFC")})` : ``}
        </Text>
        <View style={styles.hrView}></View>
        <Text style={styles.text}>
          This Track License Certificate is granted by BGM Manager Ltd (hereinafter : “BGM Manager”), to :
          <Text style={styles.Bold}>
            {license?.name.normalize("NFC")} {license.company ? `(${license.company.normalize("NFC")}) ` : ``}
          </Text>
          (hereinafter : the “Client”) as of <Text style={styles.Bold}>{formatDate(purhcaseActivity.createAt)}</Text>, regarding the use of
          the song
          <Text style={styles.Bold}> {track?.title.normalize("NFC")}.</Text>
        </Text>

        <Text style={styles.text}>
          BGM Manager hereby grants the Client a non-exclusive, worldwide and perpetual license to integrate and utilize the Song into an
          audio-visual work (hereinafter: the “Project”) and use the Song as part of the Project in accordance with BGM Manager’s Terms of
          Use and<Link src={`https://${configs.urls.current}${LICENSE}`}> BGM Manager License.</Link>
        </Text>

        <Text style={styles.text}>
          The BGM Manager License allows the Client to commercially use and otherwise use the Song as part of the Project, including simple
          editing of the Song, only under the scope of use detailed in the
          <Link src={`https://${configs.urls.current}${LICENSE}`}> BGM Manager License.</Link>
        </Text>

        <View style={styles.hrViewT}></View>
        <View style={styles.subHeaderContainer}>
          <Text style={styles.footer}>contact@artistscard.com</Text>
          <Text style={styles.block}>|</Text>
          <Text style={styles.footer}>031-604-7284</Text>
        </View>
      </Page>
    </Document>
  );
};

Font.register({
  family: "Pretendard",
  fonts: [
    { src: "https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraLight.woff" },
    { src: "https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff", fontWeight: 600 }
  ]
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 30,
    paddingBottom: 30,
    paddingHorizontal: 60,
    fontFamily: "Pretendard"
  },
  hrView: {
    width: "100%",
    height: 1,
    backgroundColor: "rgba(0,0,0,0.2)",
    marginTop: 30,
    marginBottom: 30
  },
  hrViewT: {
    width: "100%",
    height: 1,
    backgroundColor: "rgba(0,0,0,0.2)"
  },
  footer: {
    fontSize: 12,
    color: "gray"
  },
  imageContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: 30
  },
  subHeaderContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10
  },
  subHeader: {
    fontSize: 12,
    marginRight: 20,
    fontFamily: "Pretendard"
  },
  image: {
    width: 230,
    marginVertical: 15,
    marginHorizontal: 100
  },
  block: {
    marginLeft: 5,
    marginRight: 5,
    fontSize: 12,
    color: "gray"
  },
  text: {
    fontSize: 12,
    marginBottom: 25
  },
  header: {
    fontSize: 17,
    marginBottom: 3,
    fontFamily: "Pretendard"
  },
  Bold: {
    fontFamily: "Pretendard",
    fontWeight: 600
  }
});
