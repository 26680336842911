import { ButtonColor, ButtonSize } from "App/Atomics/Button";
import { ExternalLink, LinkTarget } from "App/Atomics/ExternalLink";
import { setUserAccount } from "App/Common/setUserAccount";
import { ModalAction, ModalStore } from "App/Store/ModalStore";
import {
  AccountEmailMessageSimple,
  AccountEmailTitle,
  AccountForgotPassword,
  AccountLogin,
  AccountPasswordMessageSimple,
  AccountWrongEmailOrPassword,
  Password,
  TryToAgain
} from "Constants/Locale";
import { FIND_PASSWORD } from "Constants/Routes";
import { useLazeLoadUserInfo } from "GraphQL/Queries/useLazeLoadUserInfo";
import { useSignIn } from "GraphQL/Queries/useSignIn";
import { LOADED, useLoading } from "Lib/use-loading";
import { useEffect, useMemo, useState } from "react";
import { useAsyncEffect } from "use-async-effekt";
import { sha256 } from "Utils/hash";
import { useAuth } from "Utils/useAuth";
import { AuthForm } from "../style";

export const SignInForm = () => {
  const auth = useAuth();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const tokenLoading = useLoading(LOADED);
  const userInfoLoading = useLoading(LOADED);
  const { loadUserInfo, data: userInfoData, error: userError } = useLazeLoadUserInfo();

  const { signin, data, error } = useSignIn();

  const isAllowButton = useMemo(() => !!(email.length && password.length), [email, password]);

  const onRequestClose = () => ModalStore.dispatch(ModalAction.closeAll());

  const tryToSignIn = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isAllowButton) return;
    if (!tokenLoading) return;
    try {
      tokenLoading.setLoading();
      await signin({ variables: { email, password: sha256(password) } });
    } catch (error) {
      tokenLoading.setLoaded();
    }
  };

  useEffect(() => {
    if (error) {
      tokenLoading.setLoaded();
    }
  }, [error]);

  useEffect(() => {
    if (userError) {
      userInfoLoading.setLoaded();
    }
  }, [userError]);

  useAsyncEffect(async () => {
    if (data?.signIn) {
      userInfoLoading.setLoading();
      tokenLoading.setLoaded();
      const token = data.signIn;
      auth.set(token);
      try {
        await loadUserInfo();
      } catch (error) {
        userInfoLoading.setLoaded();
      }
    }
  }, [data]);

  useEffect(() => {
    if (userInfoData?.userInfo) {
      try {
        const user = userInfoData.userInfo;
        setUserAccount(user);
        userInfoLoading.setLoaded();
        onRequestClose();
      } catch (err) {
        console.log(err);
        userInfoLoading.setLoaded();
      }
    }
  }, [userInfoData]);

  return (
    <AuthForm.Layout onSubmit={tryToSignIn}>
      {error && (
        <AuthForm.ErrorBox>
          <AuthForm.ErrorIcon name="close"></AuthForm.ErrorIcon>
          <AuthForm.ErrorText color={ButtonColor.Transparent}>
            {error?.graphQLErrors[0].extensions?.code === "400" && <AccountWrongEmailOrPassword />}
            {error?.graphQLErrors[0].extensions?.code !== "400" && <TryToAgain />}
          </AuthForm.ErrorText>
        </AuthForm.ErrorBox>
      )}
      <AuthForm.LabelBox>
        <AuthForm.Label>
          <AccountEmailTitle />
        </AuthForm.Label>
        <AuthForm.Input
          type="email"
          autoComplete="email"
          placeholder={AccountEmailMessageSimple.message()}
          defaultValue={email}
          onChange={setEmail}
        />
      </AuthForm.LabelBox>
      <AuthForm.LabelBox>
        <AuthForm.Label>
          <Password />
        </AuthForm.Label>
        <AuthForm.Input
          type="password"
          autoComplete="current-password"
          placeholder={AccountPasswordMessageSimple.message()}
          defaultValue={password}
          onChange={setPassword}
        />
      </AuthForm.LabelBox>

      <AuthForm.TextButton color={ButtonColor.Transparent}>
        <ExternalLink to={FIND_PASSWORD} target={LinkTarget.Blank}>
          <AccountForgotPassword />
        </ExternalLink>
      </AuthForm.TextButton>
      <AuthForm.ButtonGroup>
        <AuthForm.Button
          disabled={!isAllowButton || tokenLoading.isLoading || userInfoLoading.isLoading}
          type="submit"
          size={ButtonSize.Small}
          color={!isAllowButton || tokenLoading.isLoading || userInfoLoading.isLoading ? ButtonColor.Disable : ButtonColor.Primary}
        >
          {tokenLoading.isLoading || userInfoLoading.isLoading ? <AuthForm.Icon name="loading" /> : <AccountLogin />}
        </AuthForm.Button>
      </AuthForm.ButtonGroup>
    </AuthForm.Layout>
  );
};
