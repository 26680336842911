import { Icon } from "App/Atomics/Icon";
import { ModalAction, ModalProps, ModalStore } from "App/Store/ModalStore";
import { useTrackLicenseNodeStore } from "App/Store/TrackLicenseStore";
import { useTrackNode } from "App/Store/TrackNodeStore";
import { Color } from "Constants/Color";
import { License } from "Constants/Locale";
import { TypeMetadataSubclass } from "GraphQL/Schema";
import { Modal } from "Lib/modal";
import { TrackID } from "Models/TrackModel";
import styled from "styled-components";

import { useTrackLicense } from "./hooks/useTrackLicense";

type Props = {
  readonly props: ModalProps;
  readonly isOpen: boolean;
};

export const TrackLicenseModal = ({ props, isOpen }: Props) => {
  const track = props.track as TrackID;
  const onRequestClose = () => ModalStore.dispatch(ModalAction.closeAll());

  const { isLoaded } = useTrackLicense({ trackId: track });

  const trackModel = useTrackNode(track);

  const isEffect = trackModel?.subClass === TypeMetadataSubclass.Effect;

  const trackLicense = useTrackLicenseNodeStore(store => store.filter(({ metadataId }) => metadataId === track.raw.toString()));
  const [currentTrackLicense] = trackLicense;

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Layout>
        <HeaderLayout>
          <Title>
            <License />
          </Title>
          <CrossIcon name="close" onClick={onRequestClose}></CrossIcon>
        </HeaderLayout>
        <DescriptionContainer>
          {!isLoaded && (
            <LoadingContainer>
              <Loading name="loading" />
            </LoadingContainer>
          )}
          {isEffect && isLoaded && <Desciprtion>License : ArtistsCard EFFECT</Desciprtion>}
          {!isEffect && isLoaded && currentTrackLicense ? <Desciprtion>{currentTrackLicense.text}</Desciprtion> : <></>}
        </DescriptionContainer>
      </Layout>
    </Modal>
  );
};

const Layout = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;
  background-color: ${Color.Ac_Charcoal};
  padding: 30px;
  overflow: hidden;
  pointer-events: auto;

  display: flex;
  flex-direction: column;
`;

const HeaderLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  font-size: 1.5rem;
`;

const CrossIcon = styled(Icon)`
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
`;

const Desciprtion = styled.p`
  font-size: 0.8rem;
  font-weight: bold;
`;

const DescriptionContainer = styled.div`
  word-wrap: break-word;
  white-space: pre-wrap;
`;

const Loading = styled(Icon)`
  margin: 0 6px;
  width: 54.7px;
  height: 20px;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
`;
