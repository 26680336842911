import { Icon } from "App/Atomics/Icon";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { GenreTagItem, RoleTagItem } from "App/Features/Items/TagItem";
import { useGenreNodeList } from "App/Store/GenreNodeStore";
import { useRoleNodeList } from "App/Store/RoleNodeStore";
import { useTrackNode } from "App/Store/TrackNodeStore";
import { Color } from "Constants/Color";
import { TrackNoTag, TrackTags, UppercaseGenre, UppercaseMood, UppercaseRole } from "Constants/Locale";
import { GenreKind } from "Models/GenreModel/GenreKind";
import { TrackID } from "Models/TrackModel";
import styled from "styled-components";

type Props = {
  readonly id: TrackID;
  readonly isLoadedTrack: boolean;
};

export const TrackTagInforamtion = ({ id }: Props) => {
  const track = useTrackNode(id);

  const genreList = useGenreNodeList(track?.genreList)
    .filter(({ kind }) => kind === GenreKind.Genre)
    .map(({ id }) => id);

  const moodList = useGenreNodeList(track?.genreList)
    .filter(({ kind }) => kind === GenreKind.Mood)
    .map(({ id }) => id);

  const roleList = useRoleNodeList(track?.roleList).map(({ id }) => id);

  if (!genreList.length && !moodList.length && !roleList.length) {
    return (
      <NoTag>
        <SearchIcon name="search"></SearchIcon>
        <Typography weight={TypoWeight.Bold} size={TypoSize.F14}>
          <TrackNoTag />
        </Typography>
      </NoTag>
    );
  }

  return (
    <Layout>
      <Title>
        <TrackTags />
      </Title>
      {!!genreList.length && (
        <TagContainer>
          <TagTitle>
            <UppercaseGenre />
          </TagTitle>
          <TagList>
            {genreList.map(id => (
              <GenreTagItem key={id.key} id={id} />
            ))}
          </TagList>
        </TagContainer>
      )}
      {!!moodList.length && (
        <TagContainer>
          <TagTitle>
            <UppercaseMood />
          </TagTitle>
          <TagList>
            {moodList.map(id => (
              <GenreTagItem key={id.key} id={id} />
            ))}
          </TagList>
        </TagContainer>
      )}
      {!!roleList.length && (
        <TagContainer>
          <TagTitle>
            <UppercaseRole />
          </TagTitle>
          <TagList>
            {roleList.map(id => (
              <RoleTagItem key={id.key} id={id} />
            ))}
          </TagList>
        </TagContainer>
      )}
    </Layout>
  );
};

const Layout = styled.div`
  position: relative;
  width: 100%;
  max-width: 1280px;

  padding: 50px;
  margin: 0 auto;

  background-color: ${Color.Ac_Shadow};
  border-radius: 1rem;
`;

const SearchIcon = styled(Icon)`
  width: 3rem;
  height: 3rem;
  margin-bottom: 2rem;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 50px;
`;

const TagContainer = styled.div`
  margin-bottom: 30px;
`;

const TagTitle = styled.div`
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 20px;
`;

const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const NoTag = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
`;
