import { Color } from "Constants/Color";
import { DESKTOP_MODE, WIDE_MODE, X_WIDE_MODE } from "Constants/Size";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import styled from "styled-components";

export const TrackInformationContentLoader = () => {
  return (
    <SkeletonTheme baseColor={Color.Ac_Shadow} highlightColor={Color.Ac_Gray0}>
      <Layout>
        <CenterBox>
          <ButtonContainer>
            <Skeleton height={60} circle />
          </ButtonContainer>
          <Title>
            <Skeleton width={300} />
          </Title>
          <Artist>
            <Skeleton width={150} />
          </Artist>
          <CartButtonContainer>
            <Skeleton height={40} width={132} />
          </CartButtonContainer>
        </CenterBox>
        <TabList>
          <TabListContainer>
            <Tab>
              <TabHeader>
                <Skeleton width={"40%"} />
              </TabHeader>
              <TabSubFooter>
                <Skeleton width={"50%"} />
              </TabSubFooter>
            </Tab>
            <Tab>
              <TabHeader>
                <Skeleton width={"40%"} />
              </TabHeader>
              <TabSubFooter>
                <Skeleton width={"50%"} />
              </TabSubFooter>
            </Tab>
            <HoverTab>
              <TabHeader>
                <Skeleton width={"40%"} />
              </TabHeader>
              <TabSubFooter>
                <Skeleton width={"50%"} />
              </TabSubFooter>
            </HoverTab>
            <HoverTab>
              <TabHeader>
                <Skeleton width={"40%"} />
              </TabHeader>
              <TabSubFooter>
                <Skeleton width={"50%"} />
              </TabSubFooter>
            </HoverTab>
            <HoverTab>
              <TabHeader>
                <Skeleton width={"40%"} />
              </TabHeader>
              <TabSubFooter>
                <Skeleton width={"50%"} />
              </TabSubFooter>
            </HoverTab>
          </TabListContainer>
        </TabList>
      </Layout>
    </SkeletonTheme>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${DESKTOP_MODE} {
    padding: 50px;
  }

  @media ${WIDE_MODE} {
    padding: 60px;
  }

  @media ${X_WIDE_MODE} {
    padding: 70px;
  }

  padding: 30px;

  position: relative;
`;

const CenterBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
`;

const Title = styled.h2`
  width: 100%;
  text-align: center;
  font-size: 3rem;
  margin-bottom: 1.25rem;
`;

const Artist = styled.p`
  font-size: 1rem;
  width: 100%;
  color: ${Color.Ac_Gray1};
  text-align: center;
  margin-bottom: 20px;
  cursor: pointer;
  &:hover {
    color: ${Color.Ac_Apricot};
    text-decoration: underline;
  }
`;

const TabList = styled.div`
  position: relative;
  margin-top: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const TabListContainer = styled.div`
  width: 100%;
  display: flex;

  max-width: 1280px;

  @media ${DESKTOP_MODE} {
    flex-direction: row;
  }
  flex-direction: column;
`;

const Tab = styled.div`
  display: flex;
  justify-content: center;
  height: 90px;
  flex: 1 1 0%;
  padding: 0 10px;
  flex-direction: column;
  align-items: center;

  &:not(:last-child) {
    @media ${DESKTOP_MODE} {
      border-right: 1px solid ${Color.Ac_Gray};
    }
    border-right: 0px;
  }
`;

const HoverTab = styled(Tab)`
  display: flex;
  height: 90px;
  flex: 1 1 0%;
  padding: 0 10px;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  &:not(:last-child) {
    @media ${DESKTOP_MODE} {
      border-right: 1px solid ${Color.Ac_Gray};
    }
    border-right: 0px;
  }

  &:hover {
    background: ${Color.Ac_Gray};
  }
`;

const TabHeader = styled.div`
  width: 100%;
  text-align: center;
  font-size: 2rem;
`;

const TabSubFooter = styled.div`
  width: 100%;
  text-align: center;
  font-size: 0.75rem;
  font-weight: bold;
  color: ${Color.Ac_Gray1};
`;

const CartButtonContainer = styled.div`
  height: 40px;
`;

const ButtonContainer = styled.div`
  width: 60px;
  height: 60px;
  line-height: 1;
`;
