import { Button, ButtonColor } from "App/Atomics/Button";
import { Icon } from "App/Atomics/Icon";

import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { ModalAction, ModalStore } from "App/Store/ModalStore";
import { Color } from "Constants/Color";
import { Modal } from "Lib/modal";
import styled from "styled-components";
import { HistoryItem } from "./HistoryItem";
import { usePointTransaction } from "./hooks/usePointTransaction";
import { useHistoryPointModalSelector } from "./Store";

type Props = {
  readonly isOpen: boolean;
};

export const HistoryPointModal = ({ isOpen }: Props) => {
  const onRequestClose = () => ModalStore.dispatch(ModalAction.closeAll());
  usePointTransaction();

  const { isLoadedPointTransactionList, pointTransactionList } = useHistoryPointModalSelector(store => store);

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Layout>
        <Container>
          <Title size={TypoSize.F20} weight={TypoWeight.Bold}>
            포인트 히스토리
          </Title>
        </Container>
        <HistoryList>
          {!isLoadedPointTransactionList && (
            <LoadinContainer>
              <Loading name="loading" />
            </LoadinContainer>
          )}

          {isLoadedPointTransactionList && pointTransactionList.map(id => <HistoryItem id={id} />)}

          {isLoadedPointTransactionList && !pointTransactionList.length && (
            <TextContainer>
              <Typography weight={TypoWeight.Bold} size={TypoSize.F14}>
                이용 내역이없습니다
              </Typography>
            </TextContainer>
          )}
        </HistoryList>
        <ButtonGroup>
          <StyledButton color={ButtonColor.Primary} onClick={onRequestClose}>
            닫기
          </StyledButton>
        </ButtonGroup>
      </Layout>
    </Modal>
  );
};

const Layout = styled.form`
  width: 400px;
  padding: 40px 20px 20px;
  margin: 0 1.5rem;
  border-radius: 5px;
  background-color: ${Color.Ac_Charcoal};
  pointer-events: auto;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Title = styled(Typography)`
  margin-bottom: 20px;
`;
const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;
const StyledButton = styled(Button)`
  width: 100%;
  font-weight: bold;
  & + & {
    margin-left: 8px;
  }
`;

const HistoryList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${Color.Ac_Shadow};
  border-radius: 0.25rem;
  padding: 1rem;
  max-height: 400px;
  overflow-y: auto;
`;
const Loading = styled(Icon)`
  width: 2rem;
  height: 2rem;
`;

const LoadinContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
