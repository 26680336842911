import { configs } from "Config";
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  createPersistedQueryLink,
  from,
  onError,
  RetryLink,
  setContext
} from "Lib/apollo-client";
import { cache } from "./cache";
import { FC, ReactNode, useMemo, useRef } from "react";
import { useAuth } from "Utils/useAuth";
import { ApolloLink, HttpLink, Observable } from "@apollo/client";
import { GraphqlType } from "Constants/GraphqlType";
import { sha256 } from "Utils/hash";
import { AccountAction, AccountStore } from "App/Store/AccountStore";
import { refresh } from "GraphQL/Queries/refresh";

type Props = {
  children: ReactNode;
};

const UNAUTHENTICATED = "UNAUTHENTICATED";

export const GraphQLClientProvider: FC<Props> = ({ children }) => {
  const auth = useAuth();
  const authRef = useRef<typeof auth>();
  authRef.current = auth;

  const client = useMemo(() => {
    const retryLink = new RetryLink();

    const serviceHttpLink = createHttpLink({ uri: configs.urls.service });

    const cacheHttpLink = new HttpLink({ uri: configs.urls.service, useGETForQueries: true });
    const persistedQueriesLink = createPersistedQueryLink({ sha256, useGETForHashedQueries: true });

    const graphqlEndpoints = ApolloLink.split(
      operation => operation.getContext().serviceName === GraphqlType.Cache,
      persistedQueriesLink.concat(cacheHttpLink),
      serviceHttpLink
    );

    const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors?.length) {
        const { extensions } = graphQLErrors[0];
        if (UNAUTHENTICATED === extensions.code) {
          const token = authRef.current?.token;
          if (!token) {
            authRef.current?.clear();
            AccountStore.dispatch(AccountAction.clear());
            return;
          }

          return new Observable(observer => {
            refresh(token.refresh_token)
              .then(({ data: refreshData }) => {
                const tokens = refreshData.accessTokenRefresh;
                authRef.current?.set(tokens);

                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer)
                };

                operation.setContext({
                  headers: {
                    authorization: tokens.access_token ? `Bearer ${tokens.access_token}` : ""
                  }
                });

                forward(operation).subscribe(subscriber);
              })
              .catch(() => {
                authRef.current?.clear();
                window.location.reload();
                return;
              });
          });
        }

        if (extensions.code !== "400") {
          return forward(operation); // can be excuted only once!
        }
      }
      if (networkError) {
        // Toast.error(ErrorNetworkMessage.message());
        retryLink.request(operation, forward);
      }
    });

    const serviceAuthLink = setContext((_, { headers }) => {
      const { token } = authRef.current!;
      const authorization = token ? `Bearer ${token.access_token}` : "";

      return { headers: { ...headers, authorization } };
    });

    const cacheAuthLink = setContext((_, { headers }) => {
      return { headers: { ...headers } };
    });

    const authLink = ApolloLink.split(
      operation => operation.getContext().serviceName === GraphqlType.Cache,
      cacheAuthLink,
      serviceAuthLink
    );

    return new ApolloClient({
      link: from([errorLink, authLink, graphqlEndpoints]),
      cache,
      connectToDevTools: true
    });
  }, []);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
