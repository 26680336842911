import { BasicTemplate } from "App/Templates/BasicTemplate";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { Header } from "./Header";

export const Library = () => {
  return (
    <BasicTemplate isSidebar={false} isMenu={false}>
      <Layout>
        <Header />
        <Container>
          <Outlet />
        </Container>
      </Layout>
    </BasicTemplate>
  );
};

const Layout = styled.div`
  width: 100%;
  height: 100%;

  min-height: 100vh;
`;

const Container = styled.div``;
