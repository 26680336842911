import { AccountAction, AccountStore } from "App/Store/AccountStore";
import { User, UserTier } from "GraphQL/Schema";
import { AccountModel } from "Models/AccountModel/AccountModel";
import { AccountType } from "Models/AccountModel/AccountType";
import { FALLBACK_LANGUAGE_CODE } from "Models/LocalizationModel";

export const setUserAccount = (user: User) => {
  const { id, email: userEmail, name, tier, company, is_verification } = user;
  const accountType = tier === UserTier.Admin || tier === UserTier.Staff ? AccountType.Admin : AccountType.Normal;
  const accountModel = AccountModel.withType({
    id: id!,
    type: accountType,
    displayName: [{ languageCode: FALLBACK_LANGUAGE_CODE, value: name! }],
    email: userEmail!,
    tier: tier!,
    company: company ?? undefined,
    isVerification: is_verification
  });

  AccountStore.dispatch(AccountAction.current(accountModel));
};
