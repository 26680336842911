import { createVariablesInStyleSheets } from "Utils/createVariablesInStyleSheets";

export const pixelize = <S extends number>(size: S) => `${size}px` as const;

export const UNIT = 16;
export const UNIT_PX = pixelize(UNIT);

export const MOBILE = 500;
export const MOBILE_PX = pixelize(MOBILE);
export const MOBILE_MODE = `(min-width: ${MOBILE_PX})` as const;
export const MAX_MOBILE_MODE = `(max-width: ${MOBILE_PX})` as const;

export const DESKTOP = 720;
export const DESKTOP_PX = pixelize(DESKTOP);
export const DESKTOP_MODE = `(min-width: ${DESKTOP_PX})` as const;
export const MAX_DESKTOP_MODE = `(max-width: ${DESKTOP_PX})` as const;

export const WIDE = 1080;
export const WIDE_PX = pixelize(WIDE);
export const WIDE_MODE = `(min-width: ${WIDE_PX})` as const;
export const MAX_WIDE_MODE = `(max-width: ${WIDE_PX})` as const;

export const X_WIDE = 1440;
export const X_WIDE_PX = pixelize(X_WIDE);
export const X_WIDE_MODE = `(min-width: ${X_WIDE_PX})` as const;
export const MAX_X_WIDE_MODE = `(max-width: ${X_WIDE_PX})` as const;

const variables = {
  HEADER_DESKTOP_HEIGHT: 70,
  SIDEBAR_WIDTH: 15 * UNIT,
  SIDEBAR_WIDTH_COLLAPSED: 3.5 * UNIT,
  PLAYER_BAR_HEIGHT: 4.5 * UNIT,
  PADDING_XXX_LARGE: 64,
  PADDING_XX_LARGE: 32,
  PADDING_X_LARGE: 16,
  PADDING_LARGE: 8,
  PADDING_SMALL: 4,
  MARGIN_XXX_LARGE: 64,
  MARGIN_XX_LARGE: 32,
  MARGIN_X_LARGE: 16,
  MARGIN_LARGE: 8,
  MARGIN_SMALL: 4,
  BORDER_RADIUS: 0.25 * UNIT,
  FONT_X_SMALL: 0.75 * UNIT,
  FONT_SMALL: 0.875 * UNIT,
  FONT_DEFAULT: UNIT,
  FONT_LARGE: 1.25 * UNIT,
  ICON: 1.5 * UNIT
};

export const Size = createVariablesInStyleSheets(
  Object.entries(variables).reduce((record, [key, value]) => {
    record[key as keyof typeof variables] = value;
    record[`${key}_PX` as `${keyof typeof variables}_PX`] = pixelize(value);
    return record;
  }, {} as typeof variables & Record<`${keyof typeof variables}_PX`, string>)
);
