import { AccountNodeStore } from "App/Store/AccountNodeStore";
import { LicenseNodeStore } from "App/Store/LicenseNodeStore";
import { License, UserTier } from "GraphQL/Schema";
import { AccountID } from "Models/AccountModel/AccountID";
import { AccountModel } from "Models/AccountModel/AccountModel";
import { AccountType } from "Models/AccountModel/AccountType";
import { LicenseID, LicenseModel } from "Models/LicenseModel";
import { FALLBACK_LANGUAGE_CODE } from "Models/LocalizationModel";
import { TrackID } from "Models/TrackModel";
import { setTrack } from "./setTrack";

export const setLicense = (licenseList: License[]) => {
  for (const license of licenseList) {
    const licenseM = LicenseModel.form({
      id: license.id,
      createdAt: license.created_at,
      updatedAt: license.updated_at,
      company: license.company ?? null,
      user: license.user.id,
      name: license.name,
      trackId: new TrackID(license.track_id)
    });

    if (license.track) {
      setTrack([license.track]);
    }

    if (license.user) {
      const { id, email: userEmail, name, tier, is_verification } = license.user;
      const accountType = tier === UserTier.Admin || tier === UserTier.Staff ? AccountType.Admin : AccountType.Normal;
      const accountModel = AccountModel.withType({
        id: id!,
        type: accountType,
        displayName: [{ languageCode: FALLBACK_LANGUAGE_CODE, value: name! }],
        email: userEmail!,
        tier: tier!,
        isVerification: is_verification
      });
      AccountNodeStore.set(new AccountID(id), accountModel);
    }

    LicenseNodeStore.set(new LicenseID(license.id), licenseM);
  }
};
