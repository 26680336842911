import { PLAYLIST_IN_PLAYLIST_ITEM_FRAGMENT } from "GraphQL/Fragment/PlaylistInPlayItemFragment";
import { Playlist, PlaylistType } from "GraphQL/Schema";
import { gql, useQuery } from "Lib/apollo-client";

export const LOAD_PLAYLIST_TRACK_DETAIL = gql`
  ${PLAYLIST_IN_PLAYLIST_ITEM_FRAGMENT}
  query LOAD_PLAYLIST_TRACK_DETAIL($id: Int, $type: [PlaylistType!], $first: Int) {
    playlist(where: { id: $id, type__in: $type }, first: $first) {
      ...PlaylistInPlayItemFragment
    }
  }
`;

type Result = {
  readonly playlist: Playlist[];
};

type Args = {
  readonly id?: number;
  readonly type?: PlaylistType[];
  readonly first?: number;
};

export const useLoadPlaylistTrackDetail = ({ id, type, first }: Args) => {
  const { data, loading } = useQuery<Result, Args>(LOAD_PLAYLIST_TRACK_DETAIL, {
    variables: {
      id,
      type,
      first
    }
  });
  return { loading, data };
};
