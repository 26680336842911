import { PayoneqInformation } from "GraphQL/Schema";
import { gql } from "Lib/apollo-client";
import { paymentClient } from "Utils/clients";

const PAYONEQ_INFORMATION = gql`
  query PAYONEQ_INFORMATION {
    payoneq_information {
      id
      name
      name_en
      is_enable
      service_type
      icon
      pgcode
    }
  }
`;

type Result = {
  readonly payoneq_information: PayoneqInformation[];
};

export const loadPayoneqInfo = async () => {
  const { data, error } = await paymentClient.query<Result>({
    query: PAYONEQ_INFORMATION
  });
  return { data, error };
};
