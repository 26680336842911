import { StringID } from "Utils/id";
import { LocalizationModel, LocalizationPayload } from "../LocalizationModel";
import { GenreKind } from "./GenreKind";
import { immerable } from "immer";

export class GenreID extends StringID {
  constructor(id: string) {
    super("genre", id);
  }
}

export enum MetdataKind {
  Track = "track",
  Effect = "effect",
  Ccl = "ccl",
  Pd = "pd"
}

export type GenrePlayload = {
  readonly id: string;
  readonly title: LocalizationPayload;
  readonly weight: number;
  readonly kind: GenreKind;
  readonly childGenreIds: GenreID[];
  readonly metdataKind: MetdataKind[];
};

export class GenreModel {
  static form({ id, title, childGenreIds, weight, kind, metdataKind }: GenrePlayload) {
    return new GenreModel(new GenreID(id), LocalizationModel.from(title), childGenreIds, weight, kind, metdataKind);
  }

  readonly [immerable] = true;

  constructor(
    readonly id: GenreID,
    readonly title: LocalizationModel,
    readonly childGenreIds: GenreID[],
    readonly weight: number,
    readonly kind: GenreKind,
    readonly metdataKind: MetdataKind[]
  ) {}
}
