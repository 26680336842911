import { configs } from "Config";
import { Metadata } from "GraphQL/Schema";
import { createHttpLink, gql, ApolloClient, InMemoryCache } from "Lib/apollo-client";

const LOAD_TRACK_AUTO_COMPLETE = gql`
  query LOAD_TRACK_AUTO_COMPLETE($word: String!, $first: Int!) {
    metadata(
      first: $first
      where: { type_metadata_subclass: "track", metadata_title__some: { tsvec_words__search: $word, type_metadata_title: "name" } }
      group: true
    ) {
      metadata_id
      title
    }
  }
`;

type Result = {
  readonly metadata: Metadata[];
};

type Props = {
  readonly word: string;
  readonly first: number;
};

export const loadTrackAutoComplete = async ({ word, first }: Props) => {
  const httpLink = createHttpLink({
    uri: configs.urls.service
  });

  const client = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache()
  });
  const { data, error } = await client.query<Result, Props>({
    query: LOAD_TRACK_AUTO_COMPLETE,
    variables: {
      word,
      first
    }
  });
  return { data, error };
};
