import { ModalType, useModalSelector } from "App/Store/ModalStore";
import { AddPlayListModal } from "./AddPlayListModal";
import { AuthModal } from "./AuthModal";
import { ConfirmModal } from "./ConfirmModal";
import { DeleteAccountModal } from "./DeleteAccountModal";
import { HistoryPointModal } from "./HistoryPointModal";
import { LicenseModal } from "./LicenseModal";
import { ModifyPlayListModal } from "./ModifyPlayListModal";
import { PasswordChangeModal } from "./PasswordChangeModal";
import { PlaylistModal } from "./PlaylistModal";
import { SidebarMenuModal } from "./SidebarMenuModal";
import { TrackDownloadModal } from "./TrackDownloadModal";
import { TrackFilterModal } from "./TrackFilterModal";
import { TrackLicenseModal } from "./TrackLicenseModal";
import { UpdateProfileModal } from "./UpdateProfileModal";
import { UserVerifyModal } from "./UserVerifyModal";
import { PlaylistsModal } from "./PlaylistsModal";
import { TracksDownloadModal } from "./TracksDownloadModal";
import { ModifyPlayListItemModal } from "./ModifyPlayListItemModal";
import { CsvsDownloadModal } from "./CsvsDownloadModal";

export const Modals = () => {
  const modalMap = useModalSelector(store => store.modalMap);

  return (
    <>
      <AuthModal
        isOpen={modalMap.has(ModalType.SIGNIN) || modalMap.has(ModalType.SIGNUP)}
        type={modalMap.has(ModalType.SIGNIN) ? ModalType.SIGNIN : ModalType.SIGNUP}
      />
      <AddPlayListModal isOpen={modalMap.has(ModalType.ADD_PLAYLIST)}></AddPlayListModal>
      <TrackFilterModal isOpen={modalMap.has(ModalType.TRACK_FILTER)}></TrackFilterModal>
      <SidebarMenuModal isOpen={modalMap.has(ModalType.SIDEBAR_MENU)}></SidebarMenuModal>
      {!!modalMap.get(ModalType.TRACK_DOWNLOAD) && (
        <TrackDownloadModal
          isOpen={modalMap.has(ModalType.TRACK_DOWNLOAD)}
          props={modalMap.get(ModalType.TRACK_DOWNLOAD)!}
        ></TrackDownloadModal>
      )}
      {!!modalMap.get(ModalType.TRACKS_DOWNLOAD) && (
        <TracksDownloadModal
          isOpen={modalMap.has(ModalType.TRACKS_DOWNLOAD)}
          props={modalMap.get(ModalType.TRACKS_DOWNLOAD)!}
        ></TracksDownloadModal>
      )}
      {!!modalMap.get(ModalType.CSV_DOWNLOAD) && (
        <CsvsDownloadModal isOpen={modalMap.has(ModalType.CSV_DOWNLOAD)} props={modalMap.get(ModalType.CSV_DOWNLOAD)!}></CsvsDownloadModal>
      )}

      {!!modalMap.get(ModalType.MODIFY_PLAYLIST) && (
        <ModifyPlayListModal
          isOpen={modalMap.has(ModalType.MODIFY_PLAYLIST)}
          props={modalMap.get(ModalType.MODIFY_PLAYLIST)!}
        ></ModifyPlayListModal>
      )}
      {!!modalMap.get(ModalType.TRACK_LICENSE) && (
        <TrackLicenseModal
          isOpen={modalMap.has(ModalType.TRACK_LICENSE)}
          props={modalMap.get(ModalType.TRACK_LICENSE)!}
        ></TrackLicenseModal>
      )}
      {!!modalMap.get(ModalType.MODIFY_PLAYLIST_ITEM) && (
        <ModifyPlayListItemModal
          isOpen={modalMap.has(ModalType.MODIFY_PLAYLIST_ITEM)}
          props={modalMap.get(ModalType.MODIFY_PLAYLIST_ITEM)!}
        ></ModifyPlayListItemModal>
      )}
      {!!modalMap.get(ModalType.LICENSE) && (
        <LicenseModal isOpen={modalMap.has(ModalType.LICENSE)} props={modalMap.get(ModalType.LICENSE)!}></LicenseModal>
      )}
      {!!modalMap.get(ModalType.UPDATE_PASSWORD) && (
        <PasswordChangeModal isOpen={modalMap.has(ModalType.UPDATE_PASSWORD)}></PasswordChangeModal>
      )}
      {!!modalMap.get(ModalType.UPDATE_PROFILE) && (
        <UpdateProfileModal isOpen={modalMap.has(ModalType.UPDATE_PROFILE)}></UpdateProfileModal>
      )}
      {!!modalMap.get(ModalType.DELETE_ACCOUNT) && (
        <DeleteAccountModal isOpen={modalMap.has(ModalType.DELETE_ACCOUNT)}></DeleteAccountModal>
      )}
      {!!modalMap.get(ModalType.PLAYLIST) && (
        <PlaylistModal isOpen={modalMap.has(ModalType.PLAYLIST)} props={modalMap.get(ModalType.PLAYLIST)!}></PlaylistModal>
      )}
      {!!modalMap.get(ModalType.PLAYLISTS) && (
        <PlaylistsModal isOpen={modalMap.has(ModalType.PLAYLISTS)} props={modalMap.get(ModalType.PLAYLISTS)!}></PlaylistsModal>
      )}
      {!!modalMap.get(ModalType.CONFIRM) && (
        <ConfirmModal isOpen={modalMap.has(ModalType.CONFIRM)} props={modalMap.get(ModalType.CONFIRM)!}></ConfirmModal>
      )}
      {!!modalMap.get(ModalType.USER_VERIFY) && (
        <UserVerifyModal isOpen={modalMap.has(ModalType.USER_VERIFY)} props={modalMap.get(ModalType.USER_VERIFY)!}></UserVerifyModal>
      )}
      {!!modalMap.get(ModalType.HISTORY_POINT) && <HistoryPointModal isOpen={modalMap.has(ModalType.HISTORY_POINT)}></HistoryPointModal>}
    </>
  );
};
