import { CartItemNodeStore } from "App/Store/CartItemNodeStore";
import { TrackNodeStore } from "App/Store/TrackNodeStore";
import { Cart } from "GraphQL/Schema";
import { CartItemID, CartItemModel } from "Models/CartItemModel";
import { GradeID } from "Models/GradeModel";
import { TrackID } from "Models/TrackModel";
import { setTrack } from "./setTrack";

export const setCartItem = (cart: Cart) => {
  const cartItemList = cart.cart_item!;

  for (const cartItem of cartItemList) {
    const track = cartItem?.track![0]!;
    const cartItemM = CartItemModel.form({
      id: cartItem?.id!,
      track: new TrackID(cartItem?.track_id!),
      grade: new GradeID(+track.grade_id!)
    });

    const trackM = TrackNodeStore.get(new TrackID(track.id));
    if (!trackM) {
      setTrack([track]);
    }
    CartItemNodeStore.set(new CartItemID(cartItem?.id!), cartItemM);
  }
};
