import { gql } from "@apollo/client";

export const EDGE_FRAGMENT = gql`
  fragment EdgeFragment on PageInfo {
    hasNextPage
    hasPreviousPage
    endCursor
    currentPage
    pageCount
  }
`;
