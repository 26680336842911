import { useCurationManagementNodeStore } from "App/Store/CurationManagementStore";
import styled from "styled-components";
import { CurationManagementItem } from "./CurationManagementItem";

export const CurationManagementContent = () => {
  const curationManagementList = useCurationManagementNodeStore(store => store.keys());
  return (
    <Layout>
      {curationManagementList.map(id => (
        <CurationManagementItem key={id.key} id={id} />
      ))}
    </Layout>
  );
};

const Layout = styled.tbody``;
