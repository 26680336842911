import { Tree } from "App/Features/Trees/Tree";
import { useRoleNode } from "App/Store/RoleNodeStore";
import { useCurrentLanguage } from "Lib/localization";
import { useSearch } from "Lib/use-search-query";
import { RoleID } from "Models/RoleModel";

export const RoleTree = ({ search, id }: { search: string; id: RoleID }) => {
  const { query, removeQuery, appendQuery } = useSearch();
  const langugeCode = useCurrentLanguage();

  const tagsParmas = query.getAll(search);
  const role = useRoleNode(id)!;

  const changeTree = (id: string, isCheck: boolean) => {
    if (isCheck) {
      appendQuery(search, id);
    } else {
      removeQuery(search, id);
    }
  };

  const isSelected = !!tagsParmas.find(tag => tag === id.raw);
  return <Tree name={role.title.text(langugeCode)} id={role.id.raw} key={role.id.key} defaultOption={isSelected} onChange={changeTree} />;
};
