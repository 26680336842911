import { setGrade } from "App/Common/setGrade";
import { GradeNodeStore } from "App/Store/GradeNodeStore";
import { useLoadGrade } from "GraphQL/Queries/useLoadGrade";
import { useLoaded } from "Lib/use-loaded";
import { useEffect } from "react";

export const useGradeList = () => {
  const { data, loading } = useLoadGrade({});
  const { isLoaded } = useLoaded(loading);
  useEffect(() => {
    GradeNodeStore.clear();
    if (data) {
      setGrade(data.grade);
    }
  }, [data]);

  return { isLoaded };
};
