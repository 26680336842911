import { Button, ButtonColor, ButtonSize } from "App/Atomics/Button";
import { Icon } from "App/Atomics/Icon";
import { Input } from "App/Atomics/Input";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { setUserAccount } from "App/Common/setUserAccount";
import { AccountAction, AccountStore } from "App/Store/AccountStore";
import { ModalAction, ModalStore } from "App/Store/ModalStore";
import { Color } from "Constants/Color";
import {
  AccountAuthNotConfirm,
  AccountEmailAlreadyExist,
  AccountEmailCheck,
  AccountEmailTitle,
  AccountEmailUpdateError,
  AccountEmailVerifyRetryMessage,
  AcountEmailChangeDscription,
  CommonDone,
  CommonSend,
  CommonSendFull,
  UserVerifyEmail,
  UserVerifyLogin
} from "Constants/Locale";
import { Size } from "Constants/Size";
import { updateEmail } from "GraphQL/Mutations/updateEmail";
import { verifyUser } from "GraphQL/Mutations/verifyUser";
import { loadUser } from "GraphQL/Queries/loadUser";
import { refresh } from "GraphQL/Queries/refresh";
import { Toast } from "Lib/toast";
import { useInterval } from "Lib/use-interval";
import ms from "ms.macro";
import { useRef, useState } from "react";
import styled from "styled-components";
import { parseJwt, secondsToTime } from "Utils/format";
import { useAuth } from "Utils/useAuth";

type Props = {
  accessToken: string;
  refreshToken: string;
};

export const VerifyForm = ({ accessToken, refreshToken }: Props) => {
  const emailRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(0);
  const auth = useAuth();
  const defaultEmail = accessToken ? parseJwt(accessToken as string).email : null;

  const onCloseVerifyModal = () => ModalStore.dispatch(ModalAction.closeAll());

  const onUpdateEmail = async (): Promise<boolean> => {
    const email = emailRef.current?.value ?? "";
    console.log(email, defaultEmail);
    if (email === defaultEmail) {
      return true;
    }
    try {
      if (email.length) {
        await updateEmail({ token: accessToken!, email });
        return true;
      } else {
        throw new Error();
      }
    } catch (err) {
      console.log(err);
      Toast.error(AccountEmailUpdateError.message());
      return false;
    }
  };

  const onVerifyUser = async (): Promise<void> => {
    try {
      setLoading(true);
      const response = await verifyUser(accessToken!);
      if (!response) {
        throw new Error();
      }
      Toast.primary(`${CommonSendFull.message()}. ${AccountEmailCheck.message()}`, { timeout: 5000 });
      setTimer(300);
    } catch (err) {
      console.log(err);
      Toast.error(AccountEmailAlreadyExist.message(), { timeout: 3000 });
    } finally {
      setLoading(false);
    }
  };

  const updateAndVerify = async () => {
    const isSuccess = await onUpdateEmail();
    if (isSuccess) {
      await onVerifyUser();
    }
  };

  const checkSuccess = async (): Promise<void> => {
    const { data: userData } = await loadUser(accessToken ?? "");
    if (!userData.userInfo.is_verification) {
      Toast.error(AccountAuthNotConfirm.message(), { timeout: 3000 });
      return;
    }
    if (!userData?.userInfo) {
      AccountStore.dispatch(AccountAction.clear());
      auth.clear();
      return;
    }

    const { data: refreshData } = await refresh(refreshToken);
    if (refreshData) {
      const tokens = refreshData.accessTokenRefresh;
      auth.set(tokens);
    }

    const user = userData.userInfo;
    setUserAccount(user);

    Toast.success(UserVerifyLogin.message());
    onCloseVerifyModal();
  };

  useInterval(() => {
    if (timer > 0) {
      setTimer(prev => prev - 1);
    }
  }, ms("1s"));

  return (
    <Layout>
      <Title>
        <Typography color={Color.Ac_White} weight={TypoWeight.Bold} size={TypoSize.F24}>
          <UserVerifyEmail />
        </Typography>
      </Title>

      <InputWrap>
        <InputText
          ref={emailRef}
          size={TypoSize.F14}
          weight={TypoWeight.Bold}
          placeholder={AccountEmailTitle.message()}
          defaultValue={defaultEmail}
        />
        {loading ? (
          <Loading name="loading" fill={Color.AC_Carrot} />
        ) : timer < 1 ? (
          <SendButton disabled={loading} color={ButtonColor.Primary} size={ButtonSize.Xsmall} onClick={updateAndVerify}>
            <CommonSend />
          </SendButton>
        ) : (
          <Timer>
            <Typography color={Color.Ac_Gray2} weight={TypoWeight.Bold} size={TypoSize.F14}>
              {secondsToTime(timer)}
            </Typography>
          </Timer>
        )}
      </InputWrap>
      <ButtonGroup>
        <StyledButton size={ButtonSize.Medium} onClick={checkSuccess}>
          <CommonDone />
        </StyledButton>
      </ButtonGroup>
      <Description>
        <Typography color={Color.Ac_Gray0} size={TypoSize.F12}>
          - <AccountEmailCheck />
        </Typography>
        <Typography color={Color.Ac_Gray0} size={TypoSize.F12}>
          - <AcountEmailChangeDscription />
        </Typography>
        <Extra>
          -{" "}
          <AccountEmailVerifyRetryMessage
            b={({ children }) => (
              <ButtonText weight={TypoWeight.Bold} color={Color.Ac_Gray1} size={TypoSize.F12} onClick={updateAndVerify}>
                {children}
              </ButtonText>
            )}
          />
        </Extra>
      </Description>
      <NextStyledButton onClick={onCloseVerifyModal}>건너뛰기</NextStyledButton>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  overflow: hidden;
  max-width: 500px;
`;
const Title = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

const InputWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Description = styled.p`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  white-space: normal;
  background-color: ${Color.Ac_Charcoal};
  padding: 1rem;
  border-radius: 0.25rem;
`;

const SendButton = styled(Button)`
  height: 100%;
  font-weight: bold;
`;

const StyledButton = styled(Button)`
  width: 100%;
  font-size: 14px;
  margin-bottom: 8px;
`;

const NextStyledButton = styled.span`
  font-size: 14px;
  text-decoration: underline;
  margin-left: auto;
  font-size: 0.8rem;
  margin-right: 4px;
  padding: 0;
  color: ${Color.Ac_White};
  cursor: pointer;
`;

const ButtonText = styled(Typography)`
  text-decoration: underline;
  cursor: pointer;
`;

const Extra = styled.p`
  white-space: pre-line;
  color: ${Color.Ac_Gray0};
  font-size: 12px;
`;

const InputText = styled(Input.Text)`
  width: 100%;
  border: 2.25px solid ${Color.GRAY_8};
  background-color: ${Color.GRAY_8};
  border-radius: 4px;
  padding: 0.5rem ${Size.PADDING_LARGE_PX};
  font-size: 0.75rem;
  transition: all 0.3s ease-in;
  color: ${Color.Ac_Gray4}!important;
  margin-right: 4px;
  &:hover {
    border-color: ${Color.GRAY_7};
  }

  input {
    color: ${Color.Ac_Gray4};
  }

  &::placeholder {
    color: ${Color.GRAY_4};
  }
  &:focus {
    border-color: ${Color.AC_Carrot};
    background-color: ${Color.Ac_Black};

    &::placeholder {
      color: ${Color.Ac_Black};
    }
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

const Timer = styled.div`
  margin: 0 4px;
  width: 54.7px;
  overflow: hidden;
`;

const Loading = styled(Icon)`
  margin: 0 6px;
  width: 54.7px;
  height: 40px;
`;

const ButtonGroup = styled.div`
  display: flex;
`;
