import { BasicTemplate } from "App/Templates/BasicTemplate";
import styled from "styled-components";
import { Header } from "./Header";
import { Form } from "./Form";
import { useServiceHealthCheck } from "App/hooks/useServiceHealthCheck";

export const Home = () => {
  useServiceHealthCheck();

  return (
    <BasicTemplate isCenterTab={true}>
      <Layout>
        <Header />
        <Form />
      </Layout>
    </BasicTemplate>
  );
};

const Layout = styled.div`
  width: 100%;
`;
