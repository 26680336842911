import { setCurationManagement } from "App/Common/setCurationManagement";
import { BASIC_FIRST_COUNT } from "Constants/Count";
import { useLoadCurationManagement } from "GraphQL/Queries/useLoadCurationManagement";
import { useLoaded } from "Lib/use-loaded";
import { useEffect } from "react";
import { MyCurationAction, MyCurationStore } from "../Store";

export const useCurationManagement = () => {
  const { data, loading, fetchMore } = useLoadCurationManagement({
    first: BASIC_FIRST_COUNT,
    fetchPolicy: "cache-first"
  });

  const { isLoaded } = useLoaded(loading);

  useEffect(() => {
    if (data) {
      const { curationManagement, edge } = data;
      const curationManagementMs = setCurationManagement(curationManagement);
      MyCurationStore.dispatch(MyCurationAction.setCurationManagement(curationManagementMs.map(({ id }) => id)));
      MyCurationStore.dispatch(MyCurationAction.setEdge(edge));
    }
  }, [data]);

  useEffect(() => {
    MyCurationStore.dispatch(MyCurationAction.setIsLoadedMyCuration(!loading));
  }, [loading]);

  return { isLoaded, fetchMore };
};
