import { WatchQueryFetchPolicy } from "@apollo/client";
import { Edge } from "Constants/Edge";
import { EDGE_FRAGMENT } from "GraphQL/Fragment/EdgeFragment";
import { PLAYLIST_FRAGMENT } from "GraphQL/Fragment/PlaylistFragment";
import { CurationManagement, CurationManagementWhereInput } from "GraphQL/Schema";
import { gql, useQuery } from "Lib/apollo-client";

export const LOAD_CURATION_MANAGEMENT = gql`
  ${PLAYLIST_FRAGMENT}
  ${EDGE_FRAGMENT}
  query LOAD_CURATION_MANAGEMENT($first: Int, $page: Int, $where: CurationManagementWhereInput, $isForce: Boolean) {
    curationManagement: curation_management(where: $where, first: $first, page: $page, orderBy: id__DESC, is_force: $isForce) {
      id
      is_owner
      created_at
      playlist_id
      user_id
      playlist {
        ...PlaylistFragment
      }
      user {
        id
        name
        email
        company
      }
    }
    edge(
      node: CurationManagement
      is_force: $isForce
      orderBy: { curation_management: [id__DESC] }
      where: { curation_management: $where }
    ) {
      ...EdgeFragment
    }
  }
`;

type Result = {
  readonly curationManagement: CurationManagement[];
  readonly edge: Edge;
};

type Args = {
  readonly first?: number;
  readonly page?: number;
  readonly where?: CurationManagementWhereInput;
  readonly isForce?: boolean;
  readonly fetchPolicy?: WatchQueryFetchPolicy;
};
export const useLoadCurationManagement = ({ first, page, where, fetchPolicy = "network-only", isForce = false }: Args) => {
  const { data, loading, fetchMore } = useQuery<Result, Args>(LOAD_CURATION_MANAGEMENT, {
    variables: {
      first,
      page,
      where,
      isForce
    },
    fetchPolicy: fetchPolicy
  });

  return { loading, data, fetchMore };
};
