import { Tree } from "App/Features/Trees/Tree";
import { useGenreNode } from "App/Store/GenreNodeStore";
import { useCurrentLanguage } from "Lib/localization";
import { useSearch } from "Lib/use-search-query";
import { GenreID } from "Models/GenreModel/GenreModel";

export const MoodTree = ({ search, id }: { search: string; id: GenreID }) => {
  const { query, appendQuery, setQuery } = useSearch();
  const tagsParmas = query.getAll(search);
  const genre = useGenreNode(id)!;
  const allTags = [genre.id.raw, ...genre.childGenreIds.map((childGenreId: GenreID) => childGenreId.raw)];

  const languageCode = useCurrentLanguage();

  const changeParentTree = (id: string, isCheck: boolean) => {
    if (isCheck) {
      appendQuery(search, id);
    } else {
      setQuery(search, [...tagsParmas.filter(tag => !allTags.includes(tag))]);
    }
  };

  const tagInTagsParmas = (tag: string) => tagsParmas.includes(tag);
  const isSelectParent = allTags.some(tagInTagsParmas);

  return (
    <Tree
      name={genre.title.text(languageCode)}
      id={genre.id.raw}
      key={genre.id.key}
      defaultOption={isSelectParent}
      onChange={changeParentTree}
    ></Tree>
  );
};
