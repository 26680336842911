import { NumericID } from "Utils/id";
import { RoleID } from "./RoleModel";

export class ArtistID extends NumericID {
  constructor(id: number) {
    super("artist", id);
  }
}

export type ArtistPlayload = {
  readonly id: number;
  readonly name: string;
  readonly roleList: RoleID[];
};

export class ArtistModel {
  static form({ id, name, roleList }: ArtistPlayload) {
    return new ArtistModel(new ArtistID(id), name, roleList);
  }

  constructor(readonly id: ArtistID, readonly name: string, readonly roleList: RoleID[]) {}
}
