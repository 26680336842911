import { usePlaylistNodeStore } from "App/Store/PlaylistNodeStore";
import { PlaylistType } from "GraphQL/Schema";
import styled from "styled-components";
import { useLikePlaylistDetail } from "../hooks/useLikePlaylistDetail";
import { MyFavoriteForm } from "./MyFavoriteForm";
import { Header } from "./Header";

export const Form = () => {
  useLikePlaylistDetail();
  const playlist = usePlaylistNodeStore(store => store.filter(({ type }) => type === PlaylistType.Like))[0]?.id;

  return (
    <Layout>
      <Header id={playlist} />
      <MyFavoriteForm id={playlist} />
    </Layout>
  );
};

const Layout = styled.div``;
