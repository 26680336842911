import { MetdataKind } from "Models/GenreModel/GenreModel";
import styled from "styled-components";
import { useSubclass } from "Utils/useSubclass";
import { BpmMenu } from "./BpmMenu";
import { DurationMenu } from "./DurationMenu";
import { GenreMenu } from "./GenreMenu";
import { MoodMenu } from "./MoodMenu";
import { RoleMenu } from "./RoleMenu";

export const MenuContent = () => {
  const { metadataType } = useSubclass();
  return (
    <Layout>
      <GenreMenu />
      <MoodMenu />
      {(metadataType.kind === MetdataKind.Track || metadataType.kind === MetdataKind.Pd) && <RoleMenu />}
      <DurationMenu />
      {metadataType.kind === MetdataKind.Track && <BpmMenu />}
    </Layout>
  );
};

const Layout = styled.div``;
