import styled from "styled-components";
import { Header } from "./Header";

import { MyPlayListsForm } from "./MyPlayListsForm";

export const MyPlayList = () => {
  return (
    <Layout>
      <Header />
      <MyPlayListsForm />
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
  min-height: 100vh;
`;
