import { PaymentSuccessResponse } from "GraphQL/Schema";
import { gql } from "Lib/apollo-client";
import { paymentClient } from "Utils/clients";

const PAY = gql`
  mutation ($pgcode: String!, $amount: Int!) {
    pay(pgcode: $pgcode, amount: $amount) {
      token
      online_url
      mobile_url
    }
  }
`;

type Result = {
  readonly pay: PaymentSuccessResponse;
};

type Args = {
  readonly token: string;
  readonly pgcode: string;
  readonly amount: number;
};

export const purchase = async ({ token, amount, pgcode }: Args): Promise<PaymentSuccessResponse | null> => {
  const { data: result } = await paymentClient.mutate<Result>({
    mutation: PAY,
    variables: { amount, pgcode },
    context: {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  });

  return result?.pay ?? null;
};
