import { Button, ButtonColor, ButtonSize } from "App/Atomics/Button";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { usePlaylistNode } from "App/Store/PlaylistNodeStore";
import { Color } from "Constants/Color";
import { adminCurationEditPath, adminCurationTrackPath, adminCurationUserPath } from "Constants/Routes";
import { PlaylistID } from "Models/PlaylistModel";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { formatDate } from "Utils/format";

type Props = {
  readonly id: PlaylistID;
};

export const CurationItem = ({ id }: Props) => {
  const curation = usePlaylistNode(id);
  const navigate = useNavigate();
  const moveToCurationEdit = (id: number) => {
    navigate(adminCurationEditPath.path({ id }));
  };

  const moveToCurationTrack = (id: number) => {
    navigate(adminCurationTrackPath.path({ id }));
  };

  const moveToCurationUser = (id: number) => {
    navigate(adminCurationUserPath.path({ id }));
  };

  if (!curation) {
    return null;
  }

  return (
    <Layout>
      <Raw>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          {curation.id.raw}
        </Typography>
      </Raw>
      <Raw>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          {curation.title}
        </Typography>
      </Raw>
      <Raw>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          {curation.count ?? "-"}
        </Typography>
      </Raw>
      <Raw>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          {formatDate(curation.updatedAt)}
        </Typography>
      </Raw>
      <Raw>
        <ButtonGroup>
          <Button size={ButtonSize.Xsmall} color={ButtonColor.PrimaryNotFill} onClick={() => moveToCurationTrack(id.raw)}>
            트랙 관리
          </Button>
          <Button size={ButtonSize.Xsmall} color={ButtonColor.PrimaryNotFill} onClick={() => moveToCurationUser(id.raw)}>
            유저 관리
          </Button>
          <Button size={ButtonSize.Xsmall} color={ButtonColor.PrimaryNotFill} onClick={() => moveToCurationEdit(id.raw)}>
            수정
          </Button>
        </ButtonGroup>
      </Raw>
    </Layout>
  );
};

const Layout = styled.tr`
  padding: 1rem 1rem;
  border-bottom: 1px solid ${Color.Ac_Gray0};
`;

const Raw = styled.td`
  padding: 1rem 2rem;
  text-align: center;

  &:not(:last-child) {
    border-right: 1px dashed ${Color.Ac_Gray0};
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;

  button {
    margin: 0 0.25rem;
  }
`;
