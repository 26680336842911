import { Color } from "Constants/Color";
import { DESKTOP_MODE, WIDE_MODE, X_WIDE_MODE } from "Constants/Size";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import styled from "styled-components";

export const PlaylistInformationContentLoader = () => {
  return (
    <SkeletonTheme baseColor={Color.Ac_Shadow} highlightColor={Color.Ac_Gray0}>
      <Layout>
        <CenterBox>
          <Title>
            <Skeleton width={200} />
          </Title>
          <Artist>
            <Skeleton width={20} />
          </Artist>
          <Artist>
            <Skeleton width={80} />
          </Artist>

          <Skeleton width={80} height={40} />
        </CenterBox>
      </Layout>
    </SkeletonTheme>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${DESKTOP_MODE} {
    padding: 50px;
  }

  @media ${WIDE_MODE} {
    padding: 60px;
  }

  @media ${X_WIDE_MODE} {
    padding: 70px;
  }

  padding: 30px;

  position: relative;
`;

const CenterBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
`;

const Title = styled.h2`
  font-size: 3rem;
  margin-bottom: 1.25rem;
`;

const Artist = styled.p`
  font-size: 1rem;
  color: ${Color.Ac_Gray1};
  margin-bottom: 20px;
  cursor: pointer;
  &:hover {
    color: ${Color.Ac_Apricot};
    text-decoration: underline;
  }
`;
