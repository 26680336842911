import { RangeSlider } from "App/Atomics/RangeSlider";
import { Color } from "Constants/Color";
import { UppercaseDuration } from "Constants/Locale";
import { useSearch } from "Lib/use-search-query";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { secondsToTime } from "Utils/format";
import { MenuItem } from "../MenuItem";

export const DurationMenu = () => {
  const { query, setQuery, removeAllQuery } = useSearch();

  const durationParam = query.get("duration");
  const defaultDuration = durationParam ? durationParam.split("-").map(Number) : [0, 480];

  const [duration, setDuration] = useState<number[]>(defaultDuration);

  useEffect(() => {
    if (!durationParam) {
      setDuration([0, 480]);
      return;
    }
    const firstDuration = durationParam[0] ? duration[0] : 0;
    const secondDuration = durationParam[1] ? duration[1] : 480;

    setDuration([firstDuration, secondDuration]);
  }, [durationParam]);

  const setQueryValue = (key: string, value: string | null) => {
    if (!value) {
      removeAllQuery(key);
      return;
    }

    setQuery(key, value);
  };

  const setQueryDuration = (value: string | null) => setQueryValue("duration", value);

  const firstDuration = duration[0] !== 0 ? secondsToTime(duration[0]) : "";
  const secondDuration = duration[1] !== 480 ? secondsToTime(duration[1]) : "";
  const subName = (firstDuration || secondDuration) && [firstDuration, secondDuration].join("-");
  return (
    <MenuItem name={UppercaseDuration.message()} subName={subName}>
      <RangeSlider
        min={0}
        max={480}
        defaultValue={defaultDuration}
        value={duration ?? [0, 480]}
        onChange={setDuration}
        onAfterChange={setQueryDuration}
      />
      <RangeCountContainer>
        <RangeCount>0:00</RangeCount>
        <RangeCount>8:00</RangeCount>
      </RangeCountContainer>
    </MenuItem>
  );
};

const RangeCountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`;

const RangeCount = styled.span`
  color: ${Color.Ac_Gray0};
  font-size: 14px;
`;
