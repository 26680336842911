import { Dropdown, DropdownList } from "App/Atomics/Dropdown";
import { Icon } from "App/Atomics/Icon";
import { Color } from "Constants/Color";
import { ReactElement, useState } from "react";
import styled from "styled-components";
import { useCurrentLanguage } from "Lib/localization";
import { LanguageCode } from "Constants/LangaugeCode";
import { hideAll } from "tippy.js";
import { useSearch } from "Lib/use-search-query";

export const HeaderLanguageInfo = (): ReactElement => {
  const [isShowDropdown, setIsShowDropdown] = useState(false);
  const cureenLangauge = useCurrentLanguage();
  const { setQuery } = useSearch();

  const chnageLanguage = (languageCode: LanguageCode) => {
    setIsShowDropdown(false);
    setQuery("lang", languageCode);
    hideAll();
  };

  return (
    <Dropdown.Layout
      trigger="mouseenter focus"
      placement={"bottom-start"}
      onShow={() => setIsShowDropdown(true)}
      onHide={() => setIsShowDropdown(false)}
      content={
        <MenuWrap>
          <MenuItem onClick={() => chnageLanguage(LanguageCode.Ko)}>
            <FlagIcon name="flag-south-korea" />
            한국어
          </MenuItem>

          <MenuItem onClick={() => chnageLanguage(LanguageCode.En)}>
            <FlagIcon name="flag-usa" />
            English
          </MenuItem>
        </MenuWrap>
      }
    >
      <Layout>
        <Text>
          {cureenLangauge === LanguageCode.Ko && "한국어"}
          {cureenLangauge === LanguageCode.En && "English"}
        </Text>
        <DropdownIcon name="dropdown-down" className={isShowDropdown ? "dropdown-show" : ""} />
      </Layout>
    </Dropdown.Layout>
  );
};

const Layout = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding-left: 1rem;
`;

const Text = styled.span`
  font-size: 0.875rem;
  font-weight: bold;

  color: ${Color.Ac_Black};

  cursor: pointer;
`;

const FlagIcon = styled(Icon)`
  width: 2rem;

  margin-right: 0.8rem;

  border-radius: 2px;
  border: 1px solid ${Color.GRAY_4};
  background-color: #fff;
`;

const MenuWrap = styled(DropdownList)`
  padding: 10px 0;
  background-color: ${Color.Ac_White};
  overflow: hidden;
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  font-size: 0.9rem;
  padding: 10px 15px;
  color: ${Color.Ac_Black};
  &:hover {
    background-color: ${Color.Ac_Gray3};
  }
`;

const DropdownIcon = styled(Icon)`
  transition: transform 0.2s ease-in-out;
  &.dropdown-show {
    transform: rotate(-180deg);
  }
  path {
    fill: ${Color.Ac_Black};
  }
`;
