/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { LANGUAGE_INFO } from "Constants/StorageKey";
import { Cookie } from "Lib/cookie";
import { FC, ReactElement, useEffect, useRef } from "react";
import { createStore } from "Lib/store";
import { useForceUpdate } from "Lib/use-force-update";
import { createDocument, Document } from "./createDocument";

type Language = string;
type Key = string;
type Source = string;

export const DEFAULT_LANGUAGE: Language = "en";
export const FALLBACK_LANGUAGE: Language = DEFAULT_LANGUAGE;

const createInitialState = () => ({
  currentLanguage: DEFAULT_LANGUAGE,
  fallbackLanguage: FALLBACK_LANGUAGE,
  languages: new Map<Language, Map<Key, Document>>()
});

const { action, useSelector, getState, dispatch, subscribe } = createStore("Localization", createInitialState, {
  clear(state) {
    state.currentLanguage = DEFAULT_LANGUAGE;
    state.fallbackLanguage = FALLBACK_LANGUAGE;
    state.languages = new Map();
    Cookie.remove(LANGUAGE_INFO);
  },
  currentLanguage(state, language: Language) {
    state.currentLanguage = language;
    Cookie.set(LANGUAGE_INFO, language);
  },
  fallbackLanguage(state, language: Language) {
    state.fallbackLanguage = language;
  },
  messages(state, language: Language, documents: Map<Key, Document>) {
    if (!state.languages.has(language)) {
      state.languages.set(language, new Map());
    }
    const currentDocuments = state.languages.get(language)!;
    for (const [key, value] of documents) {
      currentDocuments.set(key, value);
    }
  }
});

export function currentLanguage(): string;
export function currentLanguage(language: Language, messages: Record<Key, Source>): void;
export function currentLanguage(language?: string, messages?: Record<Key, Source>): void | string {
  if (language === undefined) {
    return getState().currentLanguage;
  }
  const documents = new Map<Key, Document>();
  for (const [key, value] of Object.entries(messages!)) {
    documents.set(key, createDocument(value));
  }

  dispatch(action.currentLanguage(language), action.messages(language, documents));
}

export function fallbackLanguage(): string;
export function fallbackLanguage(language: Language, messages: Record<Key, Source>): void;
export function fallbackLanguage(language?: string, messages?: Record<Key, Source>): void | string {
  if (language === undefined) {
    return getState().fallbackLanguage;
  }

  const documents = new Map<Key, Document>();
  for (const [key, value] of Object.entries(messages!)) {
    documents.set(key, createDocument(value));
  }

  dispatch(action.fallbackLanguage(language), action.messages(language, documents));
}

export const useCurrentLanguage = (): string => useSelector(store => store.currentLanguage);
export const useFallbackLanguage = (): string => useSelector(store => store.fallbackLanguage);

type Translation<K extends Key, S extends Source> = {
  (args?: Record<string, Primitive | FC>): ReactElement;
  readonly key: string;
  readonly document: Document;
  readonly raw: () => string;
  readonly message: (args?: JSONObject) => string;
};

export const t = <K extends Key, S extends Source>(key: K, source: S): Translation<K, S> => {
  const document = createDocument(source);

  const Message = (args: Record<string, Primitive | FC> = {}): ReactElement => {
    const format = useSelector(
      ({ currentLanguage, fallbackLanguage, languages }) =>
        languages.get(currentLanguage)?.get(key)?.Message ?? languages.get(fallbackLanguage)?.get(key)?.Message ?? document.Message
    );
    return format(args);
  };

  Message.key = key;
  Message.document = document;

  Message.raw = (): string => {
    const { currentLanguage, fallbackLanguage, languages } = getState();
    return languages.get(currentLanguage)?.get(key)?.raw() ?? languages.get(fallbackLanguage)?.get(key)?.raw() ?? document.raw();
  };

  Message.message = (args: JSONObject = {}): string => {
    const { currentLanguage, fallbackLanguage, languages } = getState();
    return (
      languages.get(currentLanguage)?.get(key)?.message(args) ??
      languages.get(fallbackLanguage)?.get(key)?.message(args) ??
      document.message(args)
    );
  };

  return Message;
};

export const useTranslation = () => {
  const forceUpdate = useForceUpdate();

  const documentsRef = useRef<Map<string, Document>>();
  if (!documentsRef.current) {
    documentsRef.current = new Map();
  }
  const documents = documentsRef.current;

  useEffect(
    () =>
      subscribe(({ currentLanguage, fallbackLanguage, languages }) => {
        let shouldForceUpdate = false;

        for (const [key, currentDocument] of documents) {
          const nextDocument = languages.get(currentLanguage)?.get(key) ?? languages.get(fallbackLanguage)?.get(key) ?? currentDocument;
          if (currentDocument !== nextDocument) {
            shouldForceUpdate = false;
            documents.set(key, nextDocument);
          }
        }

        if (shouldForceUpdate) {
          forceUpdate();
        }
      }),
    []
  );

  const raw = <K extends Key, S extends Source>(translation: Translation<K, S>): string => {
    const { currentLanguage, fallbackLanguage, languages } = getState();
    const document =
      languages.get(currentLanguage)?.get(translation.key) ?? languages.get(fallbackLanguage)?.get(translation.key) ?? translation.document;
    documents.set(translation.key, document);
    return translation.raw();
  };
  const message = <K extends Key, S extends Source>(translation: Translation<K, S>, args: JSONObject = {}): string => {
    const { currentLanguage, fallbackLanguage, languages } = getState();
    const document =
      languages.get(currentLanguage)?.get(translation.key) ?? languages.get(fallbackLanguage)?.get(translation.key) ?? translation.document;
    documents.set(translation.key, document);
    return translation.message(args);
  };

  return { raw, message };
};
