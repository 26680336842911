import { Button, ButtonSize } from "App/Atomics/Button";
import { Input } from "App/Atomics/Input";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { Color } from "Constants/Color";
import { AccountAuthNotConfirmPassword, AccountPasswordChange, AccountPasswordReset, CommonConfirm, Password } from "Constants/Locale";
import { HOME, SIGN_FAIL } from "Constants/Routes";
import { updatePasswordByEmailAccess } from "GraphQL/Mutations/updatePasswordByEmailAccess";
import { Toast } from "Lib/toast";
import { FormEvent, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { sha256 } from "Utils/hash";

export const ResetPasswordForm = () => {
  const inputPassword = useRef<HTMLInputElement>(null);

  const { token } = useParams();
  const navigate = useNavigate();
  const resetPassword = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    const password = inputPassword.current!.value;

    try {
      if (!token) {
        Toast.error(AccountAuthNotConfirmPassword.message());
        navigate(SIGN_FAIL);
        return;
      }

      const error = () => {
        Toast.error(AccountAuthNotConfirmPassword.message());
        navigate(SIGN_FAIL);
        return;
      };

      const reuslt = await updatePasswordByEmailAccess({ token: token, password: sha256(password), errorCallback: error });
      if (reuslt) {
        Toast.primary(AccountPasswordChange.message());
        navigate(HOME);
      }
    } catch (err) {
      Toast.error(AccountAuthNotConfirmPassword.message());
      navigate(SIGN_FAIL);
      return;
    }
  };

  return (
    <Layout onSubmit={resetPassword}>
      <Title>
        <Typography color={Color.Ac_White} weight={TypoWeight.Bold} size={TypoSize.F24}>
          <AccountPasswordReset />
        </Typography>
      </Title>
      <Description>
        <Typography color={Color.Ac_Gray1} size={TypoSize.F15}>
          <AccountPasswordChange />
        </Typography>
      </Description>
      <InputWrap>
        <Input.Text ref={inputPassword} type="password" placeholder={Password.message()} />
      </InputWrap>
      <StyledButton type="submit" size={ButtonSize.Medium}>
        <CommonConfirm />
      </StyledButton>
    </Layout>
  );
};

const Layout = styled.form`
  display: flex;
  flex-direction: column;
  width: 343px;
  overflow: hidden;
`;
const Title = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const InputWrap = styled.div`
  width: 100%;
  margin-bottom: 20px;
  & + & {
    margin-top: 8px;
  }
`;
const Description = styled.p`
  margin-bottom: 20px;
  white-space: normal;
`;

const StyledButton = styled(Button)`
  width: 100%;
  font-size: 14px;
`;
