import { configs } from "Config";
import { createHttpLink, gql, setContext, ApolloClient, InMemoryCache } from "Lib/apollo-client";

const UPDATE_EMAIL = gql`
  mutation UPDATE_EMAIL($name: String, $company: String) {
    updateUserInfo: update_user_info(name: $name, company: $company) {
      id
    }
  }
`;

type Result = {
  readonly updateUserInfo: boolean;
};

export const updateUserInfo = async ({ token, name, company }: { token: string; name: string; company?: string }): Promise<boolean> => {
  const httpLink = createHttpLink({
    uri: configs.urls.service
  });
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`
      }
    };
  });
  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  });
  const { data } = await client.mutate<Result>({ mutation: UPDATE_EMAIL, variables: { name, company } });

  return data?.updateUserInfo ?? false;
};
