import styled from "styled-components";
import { Button } from "App/Atomics/Button";
import { Size } from "Constants/Size";
import { Color } from "Constants/Color";
import { Input } from "App/Atomics/Input";

const Layout = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const LabelBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${Size.MARGIN_LARGE_PX} 0;
`;
const Label = styled.label`
  font-size: 0.8rem;
  margin-bottom: ${Size.MARGIN_SMALL_PX};
`;

const Description = styled.p`
  font-size: 0.725rem;
  color: ${Color.GRAY_4};
  margin-bottom: ${Size.MARGIN_SMALL_PX};
`;

const CustomInput = styled(Input.Text)`
  width: 100%;
  border: 2.25px solid ${Color.GRAY_8};
  background-color: ${Color.GRAY_8};
  border-radius: 4px;
  padding: 0.5rem ${Size.PADDING_LARGE_PX};
  font-size: 0.75rem;
  transition: all 0.3s ease-in;
  color: ${Color.Ac_White}!important;

  &:hover {
    border-color: ${Color.GRAY_7};
  }

  &::placeholder {
    color: ${Color.GRAY_4};
  }
  &:focus {
    border-color: ${Color.AC_Carrot};
    background-color: ${Color.Ac_Shadow};

    &::placeholder {
      color: ${Color.Ac_Gray0};
    }
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

const ButtonGroup = styled.div`
  width: 100%;
  margin: ${Size.MARGIN_X_LARGE_PX} 0;
  height: 1.6rem;
  display: flex;
  align-items: center;
`;

const TextButton = styled(Button)`
  width: 100%;
  font-size: 0.7rem;
  text-align: left;
  color: ${Color.PURPLE_3};
`;
const SignButton = styled(Button)``;

export const PaylistForm = { Layout, LabelBox, Label, Description, Input: CustomInput, ButtonGroup, TextButton, Button: SignButton };
