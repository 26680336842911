import { createStore } from "Lib/store";
import { PointTransactionID } from "Models/PointTransactionModel";

const createInitialState = () => ({
  pointTransactionList: [] as PointTransactionID[],
  isLoadedPointTransactionList: false as boolean
});

export const {
  useSelector: useHistoryPointModalSelector,
  action: HistoryPointModalAction,
  ...HistoryPointModalStore
} = createStore("HistoryPointModal", createInitialState, {
  setPointTransactionList(state, pointTransactionList: PointTransactionID[]) {
    state.pointTransactionList = pointTransactionList;
  },
  setIsLoadedPointTransactionList(state, isLoadedPointTransactionList: boolean) {
    state.isLoadedPointTransactionList = isLoadedPointTransactionList;
  }
});
