import { Button, ButtonColor, ButtonSize } from "App/Atomics/Button";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { useAccountNode } from "App/Store/AccountNodeStore";
import { useLicenseNode } from "App/Store/LicenseNodeStore";
import { ModalAction, ModalStore, ModalType } from "App/Store/ModalStore";
import { useTrackNode } from "App/Store/TrackNodeStore";
import { Color } from "Constants/Color";
import { LicenseID } from "Models/LicenseModel";
import { TrackID } from "Models/TrackModel";
import styled from "styled-components";
import { formatDate } from "Utils/format";
import { useAdminTrackActivity } from "../../../hooks/useAdminTrackActivity";

type Props = {
  readonly id: LicenseID;
};

export const LicenseItem = ({ id }: Props) => {
  const license = useLicenseNode(id);

  const user = useAccountNode(license?.user);

  const track = useTrackNode(license?.trackId);

  useAdminTrackActivity({ trackList: [license?.trackId!], user: user?.id });

  const openLicenseModal = (trackId: TrackID) => ModalStore.dispatch(ModalAction.open(ModalType.LICENSE, { license: { track: trackId } }));
  if (!license || !track) {
    return null;
  }

  return (
    <Layout>
      <IdContainer>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          {license.id.raw}
        </Typography>
      </IdContainer>
      <UserNameContainer>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          {license.name}
        </Typography>
      </UserNameContainer>
      <UserNameContainer>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          {user?.email}
        </Typography>
      </UserNameContainer>
      <CompanyNameContainer>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          {license.company ?? "-"}
        </Typography>
      </CompanyNameContainer>
      <TrackNameContainer>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          {track.title}
        </Typography>
      </TrackNameContainer>
      <CreateAtContainer>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          {formatDate(license.createdAt)}
        </Typography>
      </CreateAtContainer>
      <DownloadContainer>
        <Button size={ButtonSize.Xsmall} color={ButtonColor.Primary} onClick={() => openLicenseModal(license.trackId)}>
          라이선스 다운로드
        </Button>
      </DownloadContainer>
    </Layout>
  );
};

const Layout = styled.tr`
  padding: 1rem 1rem;
  border-bottom: 1px solid ${Color.Ac_Gray0};
`;

const Container = styled.td`
  padding: 1rem 2rem;
  text-align: center;

  &:not(:last-child) {
    border-right: 1px dashed ${Color.Ac_Gray0};
  }
`;

const IdContainer = styled(Container)``;
const UserNameContainer = styled(Container)``;
const TrackNameContainer = styled(Container)``;
const CompanyNameContainer = styled(Container)``;
const CreateAtContainer = styled(Container)`
  justify-content: center;
`;
const DownloadContainer = styled(Container)`
  display: flex;
  justify-content: center;
`;
