import isEmail from "validator/lib/isEmail";
import { Toast } from "Lib/toast";
import {
  AccountEmailInvalid,
  AccountEmailMessageSimple,
  AccountNameErrorLength,
  AccountNameErrorNull,
  AccountPasswordErrorEnglish,
  AccountPasswordErrorMaxLength,
  AccountPasswordErrorMinLength,
  AccountPasswordErrorNull,
  AccountPasswordErrorNumber,
  AccountPasswordErrorSpecial,
  SettingAgreeAge,
  SettingAgreePrivacyPolicy,
  SettingAgreeTermsOfService
} from "Constants/Locale";

export const useValidation = () => {
  const email = (email: string): boolean => {
    const m = !email.length ? AccountEmailMessageSimple.message() : !isEmail(email) ? AccountEmailInvalid.message() : null;
    Toast.primary(m);
    return !m;
  };

  const password = (password: string): boolean => {
    const m = !password.length
      ? AccountPasswordErrorNull.message()
      : password.length < 8
      ? AccountPasswordErrorMinLength.message()
      : 32 < password.length
      ? AccountPasswordErrorMaxLength.message()
      : !/\d/.test(password)
      ? AccountPasswordErrorNumber.message()
      : !/[a-zA-Z]/.test(password)
      ? AccountPasswordErrorEnglish.message()
      : !!/[-~`#$%^&*()_+=“‘?:;.,]/.test(password)
      ? AccountPasswordErrorSpecial.message()
      : null;
    Toast.primary(m);
    return !m;
  };

  const name = (name: string): boolean => {
    const m = !name.trim().length
      ? AccountNameErrorNull.message()
      : name.trim().length > 20 || name.trim().length < 1
      ? AccountNameErrorLength.message()
      : null;
    Toast.primary(m);
    return !m;
  };

  const termsOfService = (isAgree: boolean): boolean => {
    const m = isAgree ? null : SettingAgreeTermsOfService.message();
    Toast.primary(m);
    return !m;
  };

  const privacyPolicy = (isAgree: boolean): boolean => {
    const m = isAgree ? null : SettingAgreePrivacyPolicy.message();
    Toast.primary(m);
    return !m;
  };

  const age = (isAgree: boolean): boolean => {
    const m = isAgree ? null : SettingAgreeAge.message();
    Toast.primary(m);
    return !m;
  };

  const validate = { email, password, name, termsOfService, privacyPolicy, age };
  return [validate] as const;
};
