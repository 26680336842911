import { LoadingAction, LoadingStore } from "App/Store/LoadingStore";
import { Color } from "Constants/Color";
import { ReactNode, useEffect } from "react";
import styled from "styled-components";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { Sidebar } from "./Sidebar";
import { useMoodList } from "./hooks/useMoodList";
import { useGenreList } from "./hooks/useGenreList";
import { useRoleList } from "./hooks/useRoleList";
import { useSidebarSelector } from "App/Store/SidebarStore";
import { useGradeList } from "./hooks/useGradeList";
import { useFileRuleList } from "./hooks/useFileRuleList";
import { IntervalServiceHealthCheck } from "Utils/IntervalServiceHealthCheck";

type Props = {
  readonly isMenu?: boolean;
  readonly isCenterTab?: boolean;
  readonly isSidebar?: boolean;
  readonly children: ReactNode;
};

export const BasicTemplate = ({ isMenu = true, isCenterTab = false, children, isSidebar = true }: Props) => {
  IntervalServiceHealthCheck();

  const { loading: isLoadingGenre } = useGenreList();
  const { loading: isLoadingMood } = useMoodList();
  const { loading: isLoadingRole } = useRoleList();
  const { loading: isLoadingGrade } = useGradeList();
  const { loading: isLoadingRule } = useFileRuleList();
  const { isDesktopOpen } = useSidebarSelector(store => store.current);

  const isLoadedCache = !(isLoadingGenre || isLoadingMood || isLoadingRole || isLoadingGrade || isLoadingRule);

  useEffect(() => {
    LoadingStore.dispatch(LoadingAction.setIsLoadedCache(isLoadedCache));
  }, [isLoadedCache]);

  return (
    <Layout>
      <HeaderLayout>
        <Header isMenu={isMenu} isSidebar={isSidebar} isCenterTab={isCenterTab} />
      </HeaderLayout>
      <MainLayout>
        {isDesktopOpen && isSidebar && <Sidebar />}
        <Main>{children}</Main>
      </MainLayout>
      <Footer />
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${Color.Ac_Black};
`;

const HeaderLayout = styled.div``;
const MainLayout = styled.div`
  display: flex;
  min-height: 100vh;
`;
const Main = styled.div`
  overflow: hidden;
  flex: 1;
  min-width: 0;
`;
