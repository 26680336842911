import { Button, ButtonColor, ButtonSize } from "App/Atomics/Button";
import { ReactElement } from "react";
import { useNavigate } from "Lib/use-router";
import styled from "styled-components";
import { HOME } from "Constants/Routes";
import { Typography } from "App/Atomics/Typography";
import { GoToHome, NotFountPage } from "Constants/Locale";

export const NotFound = (): ReactElement => {
  const navigate = useNavigate();
  return (
    <Layout>
      <Title color="white">
        <NotFountPage />
      </Title>
      <Button size={ButtonSize.Small} color={ButtonColor.Primary} onClick={() => navigate(HOME)}>
        <GoToHome />
      </Button>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

const Title = styled(Typography)`
  margin-bottom: 0.5rem;
`;
