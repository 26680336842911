import { LanguageCode } from "Constants/LangaugeCode";
import ExcelJS from "exceljs";
import { fileSave } from "Lib/file-saver";
import { GenreModel } from "Models/GenreModel/GenreModel";
import { RoleModel } from "Models/RoleModel";

const fields = ["트랙 ID", "제목", "메모", "아티스트", "재생시간", "BPM", "장르", "무드", "악기"];

export type CsvTrackInformation = {
  id: number;
  description: string;
  artistName: string;
  duration: number | null;
  bpm: number | null;
  title: string;
  genre: GenreModel[];
  mood: GenreModel[];
  role: RoleModel[];
};

export const useCsvTrackInformation = () => {
  const downloadExcel = async (items: CsvTrackInformation[]) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");
    const columns = fields.map(item => ({ header: item, key: item }));
    worksheet.columns = columns;
    for (const item of items) {
      const { id, bpm, description, artistName, duration, genre, mood, role, title } = item;

      const row = {
        [fields[0]]: id,
        [fields[1]]: title,
        [fields[2]]: description,
        [fields[3]]: artistName,
        [fields[4]]: duration,
        [fields[5]]: bpm,
        [fields[6]]: genre.map(({ title }) => title.text(LanguageCode.Ko)).join(", "),
        [fields[7]]: mood.map(({ title }) => title.text(LanguageCode.Ko)).join(", "),
        [fields[8]]: role.map(({ title }) => title.text(LanguageCode.Ko)).join(", ")
      };
      worksheet.addRow(row);
    }

    const mimeType = { type: "text/csv;charset=UTF-8" };
    const buffer = await workbook.csv.writeBuffer();
    const blob = new Blob([buffer], mimeType);
    const fileName = `트랙 상세 정보.csv`;
    fileSave(blob, fileName);
  };

  return { downloadExcel };
};
