import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { useAccountNode } from "App/Store/AccountNodeStore";
import { useCurationManagementNode } from "App/Store/CurationManagementStore";
import { usePlaylistNode } from "App/Store/PlaylistNodeStore";
import { Color } from "Constants/Color";
import { CurationManagementID } from "Models/CurationManagementModel";
import styled from "styled-components";
import { formatDate } from "Utils/format";

type Props = {
  readonly id: CurationManagementID;
};

export const CurationManagementItem = ({ id }: Props) => {
  const curation = useCurationManagementNode(id);
  const playlist = usePlaylistNode(curation?.playlist);
  const user = useAccountNode(curation?.user);

  if (!curation) {
    return null;
  }

  return (
    <Layout>
      <Raw>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          {curation.id.raw}
        </Typography>
      </Raw>
      <Raw>
        {curation.isOwner ? (
          <Typography size={TypoSize.F14} weight={TypoWeight.Bold} color={Color.AC_LightPurpleTest}>
            공유자
          </Typography>
        ) : (
          <Typography size={TypoSize.F14} weight={TypoWeight.Bold} color={Color.AC_Carrot}>
            사용자
          </Typography>
        )}
      </Raw>
      <Raw>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          {playlist?.title}
        </Typography>
      </Raw>
      <Raw>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          {user?.email}
        </Typography>
      </Raw>
      <Raw>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          {user?.displayName.text() ?? "-"}
        </Typography>
      </Raw>
      <Raw>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          {user?.company ?? "-"}
        </Typography>
      </Raw>
      <Raw>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
          {formatDate(curation.createAt) ?? "-"}
        </Typography>
      </Raw>
    </Layout>
  );
};

const Layout = styled.tr`
  padding: 1rem 1rem;
  border-bottom: 1px solid ${Color.Ac_Gray0};
`;

const Raw = styled.td`
  padding: 1rem 2rem;
  text-align: center;

  &:not(:last-child) {
    border-right: 1px dashed ${Color.Ac_Gray0};
  }
`;
