import { setMetadataTrack } from "App/Common/setMetadataTrack";
import { useLoadMetadataTrack } from "GraphQL/Queries/useLoadMetadataTrack";
import { useLoaded } from "Lib/use-loaded";
import { useEffect } from "react";
import { useSubclass } from "Utils/useSubclass";
import { MetdataKind } from "Models/GenreModel/GenreModel";
import { BASIC_FIRST_COUNT } from "Constants/Count";

export const useArtistMetadataTrackList = (id: string, isSkip?: boolean) => {
  const { metadataType } = useSubclass();

  const isLicense = metadataType.kind === MetdataKind.Pd || metadataType.kind === MetdataKind.Ccl;

  const { data, loading } = useLoadMetadataTrack({
    first: BASIC_FIRST_COUNT,
    where: {
      type_metadata_class: "record",
      type_metadata_subclass: metadataType.subClass.toLocaleLowerCase(),
      metadata_artist_relation__some: {
        artist__some: {
          artist_id: id
        }
      },
      track_license__some: isLicense
        ? {
            country_code__in: ["ZZ", "KR"],
            copyright: true,
            neighboring: true
          }
        : undefined,
      metadata_company_relation__some: {
        company__some: {
          company_id__in: metadataType.companyIds.map(companyId => companyId.toString())
        }
      }
    },
    skip: isSkip
  });
  const { isLoaded } = useLoaded(loading);
  useEffect(() => {
    if (data) {
      const { metadata } = data;
      setMetadataTrack(metadata);
    }
  }, [data, loading]);

  return { isLoaded };
};
