import { Button } from "App/Atomics/Button";
import { RoleNodeStore, useRoleNode } from "App/Store/RoleNodeStore";
import { Color } from "Constants/Color";
import { HOME } from "Constants/Routes";
import { useCurrentLanguage } from "Lib/localization";
import { RoleID } from "Models/RoleModel";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

type Props = {
  readonly id: RoleID;
};

export const RoleTagItem = ({ id }: Props) => {
  const role = useRoleNode(id);
  const langugeCode = useCurrentLanguage();

  const navigate = useNavigate();
  const searchToTrack = (id: RoleID) => {
    const role = RoleNodeStore.get(id);
    navigate({
      pathname: HOME,
      search: `?roles=${role?.id.raw}`
    });
  };

  return (
    <SelectButton onClick={() => searchToTrack(id)}>
      <ButtonText>{role?.title.text(langugeCode)}</ButtonText>
    </SelectButton>
  );
};

const SimpeButton = styled(Button)`
  height: 30px;
  margin: 0.3rem;
  border-radius: 2rem;
  color: ${Color.Ac_White}!important;
  &:hover {
    border: 2px solid ${Color.Ac_White}!important;
  }
`;

const SelectButton = styled(SimpeButton)`
  padding: 0 1rem;
`;

const ButtonText = styled.span`
  font-size: 0.75rem;

  line-height: 1;
  font-weight: bold;
`;
