import { PurchaseHistoryHeader } from "App/Features/Header/PurchaseHistoryHeader";
import { usePurchaseHistory } from "App/Pages/Library/Form/PurchaseHistory/hooks/usePurchaseHistory";
import styled from "styled-components";
import { PurchaseHistoryFooter } from "../PurchaseHistoryFooter";
import { usePurchaseHistorySelector } from "../Store";
import { Content } from "./Content";

export const PurchaseHistoryList = () => {
  usePurchaseHistory();
  const { isLoadedPurchaseHistory, edge } = usePurchaseHistorySelector(store => store);
  return (
    <Layout>
      <PurchaseHistoryHeader />
      <Content />
      {isLoadedPurchaseHistory && <PurchaseHistoryFooter edge={edge} />}
    </Layout>
  );
};

const Layout = styled.div`
  margin: 2rem 0;
`;
