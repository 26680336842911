import { gql, useQuery } from "@apollo/client";
import { MetadataWhereInput } from "GraphQL/Schema";

const LOAD_METADATA_TRACK_COUNT = gql`
  query LOAD_METADATA_TRACK_COUNT($where: MetadataWhereInput) {
    metadataTrackCount: getCount(node: Metadata, where: { metadata: $where }) {
      count
    }
  }
`;

type Count = {
  count: number;
};

type Result = {
  readonly metadataTrackCount: Count;
};

type Args = {
  readonly where?: MetadataWhereInput;
  readonly isSkip?: boolean;
};

export const useLoadMetadataTrackCount = ({ where, isSkip }: Args) => {
  const { data, loading } = useQuery<Result, Args>(LOAD_METADATA_TRACK_COUNT, {
    variables: {
      where
    },
    skip: isSkip
  });

  return { data, loading };
};
