import { useLoadCacheTrack } from "GraphQL/Queries/useLoadCacheTrack";
import { MetadataType } from "Utils/useSubclass";

interface Props {
  readonly metadataType: MetadataType;
  readonly skip?: boolean;
}

export const useMetadataTrackCacheList = ({ skip, metadataType }: Props) => {
  const { data, loading } = useLoadCacheTrack({ skip, metadataType });
  return { loading, data };
};
