import { useSidebarSelector } from "App/Store/SidebarStore";
import { Color } from "Constants/Color";
import styled from "styled-components";
import { useWide } from "Utils/useWide";
import { MenuList } from "./MenuList";

export const SideBar = () => {
  const isWide = useWide();

  const { isVisible } = useSidebarSelector(store => ({
    isVisible: store.current.isAdminDesktopOpen
  }));

  return (
    <Layout>
      {isVisible && (
        <Header>
          <AdminText>관리자</AdminText>
        </Header>
      )}
      <MenuList isWide={isWide} />
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  height: 128px;
  font-weight: bold;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AdminText = styled.div`
  color: ${Color.Ac_White};
`;
