import { useGradeNodeStore } from "App/Store/GradeNodeStore";
import styled from "styled-components";
import { GradeItem } from "./GradeItem";

export const GradeContent = () => {
  const gradeList = useGradeNodeStore(store => store.keys());

  return (
    <Layout>
      {gradeList.map(id => (
        <GradeItem key={id.key} id={id} />
      ))}
    </Layout>
  );
};

const Layout = styled.tbody``;
