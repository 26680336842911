import { Edge } from "Constants/Edge";
import { createStore } from "Lib/store";
import { CurationManagementID } from "Models/CurationManagementModel";

const createInitialState = () => ({
  isLoadedLibraryCuration: false as boolean,
  curationManagement: [] as CurationManagementID[],
  edge: null as Edge | null
});

export const {
  useSelector: useLibraryCurationSelector,
  action: LibraryCurationAction,
  ...LibraryCurationStore
} = createStore("LibraryCuration", createInitialState, {
  setEdge(state, edge: Edge | null) {
    state.edge = edge;
  },
  setIsLoadedLibraryCuration(state, isLoadedLibraryCuration: boolean) {
    state.isLoadedLibraryCuration = isLoadedLibraryCuration;
  },
  setCurationManagement(state, curationManagement: CurationManagementID[]) {
    state.curationManagement = [...curationManagement];
  }
});
