import { Time } from "Lib/time";
import { StringID } from "Utils/id";
import { AccountID } from "./AccountModel/AccountID";
import { TrackID } from "./TrackModel";

export class LicenseID extends StringID {
  constructor(id: string) {
    super("license", id);
  }
}

export type LicensePlayload = {
  readonly id: string;
  readonly trackId: TrackID;
  readonly user: number;
  readonly name: string;
  readonly company: string | null;
  readonly createdAt: string;
  readonly updatedAt: string;
};

export class LicenseModel {
  static form({ id, trackId, name, company, createdAt, updatedAt, user }: LicensePlayload) {
    return new LicenseModel(new LicenseID(id), trackId, name, company, Time(createdAt), Time(updatedAt), new AccountID(user));
  }

  constructor(
    readonly id: LicenseID,
    readonly trackId: TrackID,
    readonly name: string,
    readonly company: string | null,
    readonly createdAt: Time,
    readonly updatedAt: Time,
    readonly user: AccountID
  ) {}
}
