import { Edge } from "Constants/Edge";
import { createStore } from "Lib/store";
import { PlaylistID } from "Models/PlaylistModel";

const createInitialState = () => ({
  myPlaylists: [] as PlaylistID[],
  isLoadedLibraryPlaylist: false as boolean,
  edge: null as Edge | null
});

export const {
  useSelector: useLibraryPlaylistSelector,
  action: LibraryPlaylistAction,
  ...LibraryPlaylistStore
} = createStore("LibraryPlaylist", createInitialState, {
  setEdge(state, edge: Edge | null) {
    state.edge = edge;
  },
  setLibraryPlaylist(state, myPlaylists: PlaylistID[]) {
    state.myPlaylists = [...myPlaylists];
  },
  setIsLoadedLibraryPlaylist(state, isLoadedLibraryPlaylist: boolean) {
    state.isLoadedLibraryPlaylist = isLoadedLibraryPlaylist;
  }
});
