import { createDuck } from "./store";

export const createTimeActions = () => {
  const createInitialState = () => ({ time: 0 });

  return createDuck({
    namespace: "Time",
    createInitialState,
    reducers: {
      set(state, time: number) {
        state.time = Math.max(time, 0);
      }
    }
  });
};
