import { configs } from "Config";
import { User, UserWhereInput } from "GraphQL/Schema";
import { ApolloClient, createHttpLink, gql, InMemoryCache, setContext } from "Lib/apollo-client";
const LOAD_ARTIST_FIND = gql`
  query LOAD_USER($where: UserWhereInput!) {
    user(where: $where) {
      id
      email
    }
  }
`;

type GraphQLResult = {
  user: User[];
};

type Props = {
  where: UserWhereInput;
  token: string;
};

export const loadUserFind = async ({ where, token }: Props) => {
  const httpLink = createHttpLink({
    uri: configs.urls.service
  });
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`
      }
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  });

  const { data } = await client.query<GraphQLResult>({
    query: LOAD_ARTIST_FIND,
    variables: {
      where
    }
  });
  return { data };
};
