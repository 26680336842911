import { gql, useQuery, WatchQueryFetchPolicy } from "@apollo/client";
import { UserWallet } from "GraphQL/Schema";

export const LOAD_USER_WALLET = gql`
  query LOAD_USER_WALLET {
    userWallet: user_wallet {
      id
      user_id
      balance
      event_point
      created_at
      updated_at
    }
  }
`;

type Result = {
  readonly userWallet: UserWallet;
};

type Args = {
  readonly skip: boolean;
  readonly watchQueryFetchPolicy?: WatchQueryFetchPolicy;
};

export const useLoadUserWallet = ({ skip, watchQueryFetchPolicy = "cache-first" }: Args) => {
  const { data, loading } = useQuery<Result>(LOAD_USER_WALLET, {
    skip,
    fetchPolicy: watchQueryFetchPolicy,
    context: {
      headers: {
        endpoint: "child"
      }
    }
  });

  return { loading, data };
};
