import { setGrade } from "App/Common/setGrade";
import { useLoadGrade } from "GraphQL/Queries/useLoadGrade";
import { useLoaded } from "Lib/use-loaded";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export const useLoadGradeDetail = () => {
  const { id } = useParams();
  const { data, loading } = useLoadGrade({
    where: {
      id: +id!
    }
  });
  const { isLoaded } = useLoaded(loading);
  useEffect(() => {
    if (data) {
      setGrade(data.grade);
    }
  }, [data]);

  return { isLoaded };
};
