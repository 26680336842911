import { ModalAction, ModalProps, ModalStore } from "App/Store/ModalStore";
import { Color } from "Constants/Color";
import { Size } from "Constants/Size";
import { Modal } from "Lib/modal";
import styled from "styled-components";
import { VerifyForm } from "./VerifyForm";

type Props = {
  readonly isOpen: boolean;
  readonly props: ModalProps;
};

export const UserVerifyModal = ({ isOpen, props }: Props) => {
  const onRequestClose = () => ModalStore.dispatch(ModalAction.closeAll());

  const accessToken = props.accessToken as string;
  const refreshToken = props.refreshToken as string;

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Layout>
        <Container>
          <VerifyForm accessToken={accessToken} refreshToken={refreshToken} />
        </Container>
      </Layout>
    </Modal>
  );
};

const Layout = styled.div`
  pointer-events: auto;
  background-color: ${Color.Ac_Shadow};
  max-width: 900px;
  border-radius: 0.5rem;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: ${Size.PADDING_X_LARGE_PX} ${Size.PADDING_X_LARGE_PX};
`;
