import { TRACK_ACTIVITY_FRAGMENT } from "GraphQL/Fragment/TrackActivityFragment";
import { TrackActivity } from "GraphQL/Schema";
import { gql, useQuery } from "Lib/apollo-client";

export const LOAD_TRACK_ACTIVITY = gql`
  ${TRACK_ACTIVITY_FRAGMENT}
  query LOAD_TRACK_ACTIVITY($first: Int, $trackIds: [Int!], $userId: Int) {
    trackActivity: track_activity(where: { track_id__in: $trackIds, user_id: $userId }, first: $first) {
      ...TrackActivityFragment
    }
  }
`;

type Result = {
  readonly trackActivity: TrackActivity[];
};

type Args = {
  readonly first?: number;
  readonly trackIds?: number[];
  readonly userId?: number;
  readonly skip?: boolean;
};

export const useLoadAdminTrackActivity = ({ skip, first, trackIds, userId }: Args) => {
  const { data, loading, error } = useQuery<Result, Args>(LOAD_TRACK_ACTIVITY, {
    variables: {
      first,
      trackIds,
      userId
    },
    skip
  });
  return { loading: loading, data, error };
};
