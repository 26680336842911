import styled, { css } from "styled-components";
import { Input } from "App/Atomics/Input";
import { Color } from "Constants/Color";

const Row = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
`;

const Caption = styled.span<{ color?: string }>`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1rem;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 4px;
  color: ${({ color }) => color ?? Color.Ac_White};
`;

const SubCaption = styled.span`
  font-size: 0.85rem;
  margin-bottom: 2px;
  color: #bababa;
`;

const InputStyle = css`
  position: relative;
  width: 100%;
  height: 44px;
  margin-top: 4px;
  background-color: ${Color.Ac_Shadow}!important;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.5);
  color: ${Color.Ac_White}!important;
  border: none;
  border-radius: 8px;
  line-height: 22px;
  padding: 1rem;
  transition: box-shadow 0.2s ease;
  &:hover {
    box-shadow: inset 0 0 0 2px ${Color.Ac_Gray0};
  }
  &:focus {
    box-shadow: inset 0 0 0 2px ${Color.AC_Carrot};
  }
`;
const InputText = styled(Input.Text)`
  ${InputStyle};
`;

export const SelectStyle = {
  container: (base: any) => ({
    ...base
  }),
  input: (base: any) => ({
    ...base,
    color: "#fff"
  }),
  singleValue: (base: any) => ({
    ...base,
    color: "#fff"
  }),
  menu: (base: any) => ({
    ...base,
    backgroundColor: Color.Ac_Shadow
  }),
  control: (base: any, state: any) => ({
    ...base,
    minHeight: 44,
    color: `red`,
    fontSize: 14,
    backgroundColor: Color.Ac_Shadow,
    boxShadow: "inset 0 0 0 1px rgba(0, 0, 0, 0.5)",
    border: "none",
    borderRadius: "8px",
    ":hover": {
      boxShadow: `inset 0 0 0 2px ${Color.Ac_Gray0}`
    }
  }),

  placeholder: (base: any) => ({
    ...base,
    color: "#cfcfcf"
  })
};

export const Style = {
  Row,
  SelectStyle,
  Caption,
  SubCaption,
  InputText
};
