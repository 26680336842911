import { useLicenseNodeStore } from "App/Store/LicenseNodeStore";
import styled from "styled-components";
import { LicenseItem } from "./LicenseItem";

export const LicenseContent = () => {
  const licenseList = useLicenseNodeStore(store => store.keys());
  return (
    <Layout>
      {licenseList.map(id => (
        <LicenseItem key={id.key} id={id} />
      ))}
    </Layout>
  );
};

const Layout = styled.tbody``;
