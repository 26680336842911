import { Button, ButtonColor, ButtonSize } from "App/Atomics/Button";
import { Icon } from "App/Atomics/Icon";
import { Input } from "App/Atomics/Input";
import { ModalAction, ModalProps, ModalStore } from "App/Store/ModalStore";
import { Color } from "Constants/Color";
import { Memo, Modify, ModifyMemo } from "Constants/Locale";
import { Modal } from "Lib/modal";
import { useRef } from "react";
import styled from "styled-components";
import { PlaylistItemID } from "Models/PlaylistItemModel";
import { useUpdatePlaylistItem } from "GraphQL/Mutations/useUpdatePlaylistItem";
type Props = {
  readonly props: ModalProps;
  readonly isOpen: boolean;
};

export const ModifyPlayListItemModal = ({ props, isOpen }: Props) => {
  const playlistItemId = props.playlistItemId as PlaylistItemID;
  const playlistItemDescription = props.description ?? ("" as string);

  const onRequestClose = () => ModalStore.dispatch(ModalAction.closeAll());
  const { updatePlaylistItem } = useUpdatePlaylistItem();

  const textAreaDescription = useRef<HTMLTextAreaElement>(null);

  const onUpdatePlaylistItem = async (id: number) => {
    const description = textAreaDescription.current?.value;

    await updatePlaylistItem({
      variables: {
        playlistItemId: id,
        description
      }
    });

    onRequestClose();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Layout>
        <HeaderLayout>
          <Title>{<ModifyMemo />}</Title>
          <CrossIcon name="close" onClick={onRequestClose}></CrossIcon>
        </HeaderLayout>
        <CustomTextArea ref={textAreaDescription} placeholder={Memo.message()} defaultValue={playlistItemDescription}></CustomTextArea>
        <ButtonContainer>
          <Button
            style={{ width: "100%" }}
            size={ButtonSize.Small}
            color={ButtonColor.Primary}
            onClick={() => onUpdatePlaylistItem(playlistItemId.raw)}
          >
            <Modify />
          </Button>
        </ButtonContainer>
      </Layout>
    </Modal>
  );
};

const Layout = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;
  background-color: ${Color.Ac_Charcoal};
  padding: 30px;
  overflow: hidden;
  pointer-events: auto;

  display: flex;
  flex-direction: column;
`;

const CrossIcon = styled(Icon)`
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
`;

const Title = styled.h2`
  font-size: 1.5rem;
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;

  button:first-child {
    margin-right: 1rem;
  }
`;

const HeaderLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const CustomTextArea = styled(Input.TextArea)`
  background-color: ${Color.Ac_Shadow};
  border-color: ${Color.Ac_Gray0};

  margin-top: 1rem;

  &::placeholder {
    color: ${Color.Ac_Gray0};
  }
  color: ${Color.Ac_White}!important;
`;
