import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { PointChargeText } from "Constants/Locale";
import { ReactElement } from "react";
import styled from "styled-components";
import { PayInfo } from "./PayInfo";
import { PriceInfo } from "./PriceInfo";
import { ShipInfo } from "./ShipInfo";
import { TermInfo } from "./TermInfo";

export const PointChargeForm = (): ReactElement | null => {
  return (
    <Layout>
      <Title>
        <Typography size={TypoSize.F24} weight={TypoWeight.Bold}>
          <PointChargeText />
        </Typography>
      </Title>
      <ShipInfo />
      <PayInfo />
      <TermInfo />
      <PriceInfo />
    </Layout>
  );
};

const Layout = styled.div``;

const Title = styled.div`
  margin-bottom: 30px;
`;
