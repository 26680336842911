import { ReactElement, useEffect } from "react";

export const BUY_SUCCESS_MESSAGE = "purchase-success";

export const BuySuccessRedirect = (): ReactElement => {
  useEffect(() => {
    const data = {
      search: window.location.search,
      service: BUY_SUCCESS_MESSAGE
    };
    window.opener.postMessage(data, window.location.origin);
  }, []);
  return <></>;
};
