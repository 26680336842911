import { Button, ButtonSize } from "App/Atomics/Button";
import { Icon } from "App/Atomics/Icon";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { SelectTagItem } from "App/Features/Items/TagItem";
import { TrackOrder } from "App/Molecules/TrackOrder";
import { ModalAction, ModalStore, ModalType } from "App/Store/ModalStore";
import { Color } from "Constants/Color";
import { EffectCount, Filter, TrackCount } from "Constants/Locale";
import { DESKTOP_MODE, WIDE_MODE } from "Constants/Size";
import { useSearch } from "Lib/use-search-query";
import styled from "styled-components";
import { TypeMetadataSubclass, useSubclass } from "Utils/useSubclass";
import { useTrackInfo } from "Utils/useTrackInfo";
import { useMetadataCountSwitch } from "../hooks/useMetadataCountSwitch";
import { useHomeSelector } from "../Store";

export const Header = () => {
  const { count } = useHomeSelector(store => store);
  const { metadataType } = useSubclass();
  const { query } = useSearch();
  const tagSearchString = decodeURIComponent(query.toString());
  const { set, isInfo } = useTrackInfo();
  const { isLoaded: isLoadedCount } = useMetadataCountSwitch();
  const openTrackFilter = () => {
    ModalStore.dispatch(ModalAction.open(ModalType.TRACK_FILTER));
  };

  const tagFilterList = ["page", "lang", "order"];
  const tagList = tagSearchString
    ? tagSearchString
        .split("&")
        .map(tag => ({
          type: tag.split("=")[0],
          id: tag.split("=")[1]
        }))
        .filter(({ type }) => !tagFilterList.includes(type))
    : [];

  return (
    <Layout>
      <TopContainer>
        <Title size={TypoSize.F20} weight={TypoWeight.Bold}>
          {metadataType.subClass === TypeMetadataSubclass.Track ? <TrackCount /> : <EffectCount />}
          <Count>{isLoadedCount && `(${count})`}</Count>
        </Title>
      </TopContainer>
      <BottomContainer>
        <SelectContainer>
          {!!tagList.length && tagList.map(({ type, id }) => <SelectTagItem key={id} type={type} id={id} />)}
        </SelectContainer>
        <RightContainer>
          <ButtonContainer>
            <FilterButton size={ButtonSize.Small} onClick={openTrackFilter}>
              <ButtonText weight={TypoWeight.Bold} size={TypoSize.F11}>
                <Filter />
              </ButtonText>
            </FilterButton>
          </ButtonContainer>
          <ButtonContainer>
            <TrackOrder />
          </ButtonContainer>
          <ReadMoreButtonContainer>
            <ReadMoreLeftButton isSelected={isInfo} size={ButtonSize.Small} onClick={() => set(false)}>
              <DetailIcon name="detail-track" />
            </ReadMoreLeftButton>
            <ReadMoreRightButton isSelected={!isInfo} size={ButtonSize.Small} onClick={() => set(true)}>
              <DetailIcon name="basic-track" />
            </ReadMoreRightButton>
          </ReadMoreButtonContainer>
        </RightContainer>
      </BottomContainer>
    </Layout>
  );
};

const Layout = styled.div`
  padding: 1.5rem;
  padding-left: 1.8rem;
`;

const TopContainer = styled.div`
  padding: 0.625rem 0;
  white-space: normal;
`;

const Title = styled(Typography)`
  margin-right: 0.3rem;
`;

const Count = styled.span`
  font-size: 1rem;
  color: ${Color.Ac_Gray0};
`;

const BottomContainer = styled.div`
  display: flex;

  @media ${WIDE_MODE} {
    flex-direction: row;
  }

  flex-direction: column;

  padding: 0.625rem 0;
  align-items: center;
`;

const SelectContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;

  align-self: flex-start;

  min-width: 0;
  margin-right: 0.625rem;
`;

const ButtonText = styled(Typography)`
  margin: 0 0.5rem;

  line-height: 1;
`;

const ButtonContainer = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
  height: 30px;
  @media ${WIDE_MODE} {
    margin: 0 0.31rem 0.31rem;
  }

  margin-top: 3rem;
`;

const SimpeButton = styled(Button)`
  height: 30px;
  margin: 0.3rem;
  border-radius: 2rem;
  color: ${Color.Ac_Gray1}!important;
  &:hover {
    border: 2px solid ${Color.Ac_White}!important;
    color: ${Color.Ac_White}!important;

    path {
      fill: ${Color.Ac_White};
    }
  }
`;

const FilterButton = styled(SimpeButton)`
  @media ${DESKTOP_MODE} {
    display: none;
  }
  display: flex;

  height: 32px;
  padding: 0.25rem 1rem;
  border: 2px solid ${Color.Ac_Gray0}!important;
`;

const RightContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;

  @media ${WIDE_MODE} {
    justify-content: flex-end;
    width: min-content;
  }
  width: 100%;
  justify-content: space-between;
`;

const ReadMoreButtonContainer = styled(ButtonContainer)`
  @media ${WIDE_MODE} {
    display: flex;
  }
  display: none;
`;

const ReadMoreButton = styled(SimpeButton)<{ isSelected: boolean }>`
  background-color: ${props => props.isSelected && `${Color.Ac_Gray0}`}!important;

  margin: 0;
`;

const ReadMoreLeftButton = styled(ReadMoreButton)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-width: 1px;

  &:hover {
    border-right: 1px solid ${Color.Ac_White}!important;
  }
`;

const DetailIcon = styled(Icon)`
  width: 1rem;
  height: 1rem;
`;

const ReadMoreRightButton = styled(ReadMoreButton)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left-width: 1px;

  @media ${WIDE_MODE} {
    display: flex;
  }

  display: none;

  &:hover {
    border-left: 1px solid ${Color.Ac_White} !important;
  }
`;
