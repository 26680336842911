import { useLoadMetadataTrackCount } from "GraphQL/Queries/useLoadMetadataTrackCount";
import { useLoaded } from "Lib/use-loaded";
import { MetdataKind } from "Models/GenreModel/GenreModel";
import { useSubclass } from "Utils/useSubclass";

export const useArtistTrackCount = (id: string, isSkip?: boolean) => {
  const { metadataType } = useSubclass();
  const isLicense = metadataType.kind === MetdataKind.Pd || metadataType.kind === MetdataKind.Ccl;

  const { data, loading } = useLoadMetadataTrackCount({
    isSkip,
    where: {
      type_metadata_class: "record",
      type_metadata_subclass: metadataType.subClass.toLocaleLowerCase(),
      metadata_artist_relation__some: {
        artist__some: {
          artist_id: id
        }
      },
      track_license__some: isLicense
        ? {
            country_code__in: ["ZZ", "KR"],
            copyright: true,
            neighboring: true
          }
        : undefined,
      metadata_company_relation__some: {
        company__some: {
          company_id__in: metadataType.companyIds.map(companyId => companyId.toString())
        }
      }
    }
  });
  const { isLoaded } = useLoaded(loading);

  return { isLoaded, data };
};
