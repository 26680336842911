import { Icon, IconName } from "App/Atomics/Icon";
import { InternalLink } from "App/Atomics/InternalLink";
import { useAccountSelector } from "App/Store/AccountStore";
import { SidebarAction, SidebarStore, useSidebarSelector } from "App/Store/SidebarStore";
import { Color } from "Constants/Color";
import {
  ADMIN_CECHARGE_POINT,
  ADMIN_CURATION,
  ADMIN_GRADE,
  ADMIN_HISTORY_POINT,
  ADMIN_HOME,
  ADMIN_LICENSE,
  ADMIN_PRICE,
  ADMIN_STAFF_CREATE,
  ADMIN_SYNCHRONIZATION,
  ADMIN_UPDATE_DURATION,
  ADMIN_USER,
  ADMIN_WITHDRAWAL_POINT
} from "Constants/Routes";
import { DESKTOP_MODE, pixelize, Size, UNIT } from "Constants/Size";
import { isAdminAccountTier } from "Models/AccountModel/AccountTierModel";
import { useEffect } from "react";
import styled, { css } from "styled-components";

type ItemLinkProps = {
  to: string;
  icon?: IconName;
  name: string;
  isVisible: boolean;
};

const ItemLink = ({ to, icon, name, isVisible }: ItemLinkProps) => (
  <ItemLinkLayout to={to}>
    <IconLayout aria-hidden={isVisible}>{icon && <Icon name={icon} />}</IconLayout>
    <Text aria-hidden={!isVisible}>{name}</Text>
  </ItemLinkLayout>
);

type Props = {
  isWide: boolean;
};

export const MenuList = ({ isWide }: Props) => {
  const { isVisible } = useSidebarSelector(store => ({
    isVisible: store.current.isAdminDesktopOpen
  }));

  const tier = useAccountSelector(store => store.current.tier);

  const closeSidebarOnMobile = () => {
    if (!isWide) {
      SidebarStore.dispatch(SidebarAction.open());
    }
  };

  useEffect(closeSidebarOnMobile, [isWide]);

  return (
    <>
      <Layout data-testid="MenuList">
        <ItemLink to={ADMIN_HOME} name={"메인"} icon="home" isVisible={isVisible} />
        <ItemLink to={ADMIN_LICENSE} name={"라이선스 관리"} icon="question" isVisible={isVisible} />
        <ItemLink to={ADMIN_CURATION} name={"큐레이션 관리"} icon="filter" isVisible={isVisible} />
        <ItemLink to={ADMIN_SYNCHRONIZATION} name={"DMS 동기화"} icon="basic-track" isVisible={isVisible} />
        {isAdminAccountTier(tier) && (
          <>
            <ItemLink to={ADMIN_CECHARGE_POINT} name={"포인트 충전"} icon="exchange" isVisible={isVisible} />
            <ItemLink to={ADMIN_WITHDRAWAL_POINT} name={"포인트 회수"} icon="exchange" isVisible={isVisible} />
            <ItemLink to={ADMIN_STAFF_CREATE} name={"관리자 계정 생성"} icon="add-playlist" isVisible={isVisible} />
            <ItemLink to={ADMIN_HISTORY_POINT} name={"포인트 히스토리"} icon="library" isVisible={isVisible} />
            <ItemLink to={ADMIN_PRICE} name={"상품 금액 관리"} icon="money" isVisible={isVisible} />
            <ItemLink to={ADMIN_GRADE} name={"상품 등급 관리"} icon="curation" isVisible={isVisible} />
            <ItemLink to={ADMIN_USER} name={"유저 리스트"} icon="detail-track" isVisible={isVisible} />
            <ItemLink to={ADMIN_UPDATE_DURATION} name={"재생시간 업데이트"} icon="detail-track" isVisible={isVisible} />
          </>
        )}
      </Layout>
    </>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemStyle = css`
  color: ${Color.GRAY_3};
  position: relative;

  display: flex;
  flex-direction: row;

  padding: ${Size.PADDING_X_LARGE_PX};

  &:hover {
    color: #fff;
    background-color: ${Color.Ac_Black};
  }

  svg {
    path {
      fill: ${Color.Ac_White};
    }
    width: ${Size.ICON_PX};
    height: ${Size.ICON_PX};
  }
`;

const ItemLayoutStyle = css`
  ${ItemStyle}

  &[aria-current="page"] {
    background-color: ${Color.Ac_Black};

    font-weight: bolder;
    color: ${Color.AC_Carrot};

    svg {
      path {
        fill: ${Color.AC_Carrot}!important;
        stroke: ${Color.AC_Carrot}!important;
      }
      .st11 {
        fill: ${Color.AC_Carrot}!important;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;

      width: ${pixelize(UNIT * 0.25)};
      height: 100%;

      border-top-left-radius: ${Size.BORDER_RADIUS_PX};
      border-bottom-left-radius: ${Size.BORDER_RADIUS_PX};
      background-color: ${Color.AC_Carrot};
    }
  }
`;

const ItemLinkLayout = styled(InternalLink)`
  ${ItemLayoutStyle}
`;

const IconLayout = styled.div`
  width: ${Size.ICON_PX};
  height: ${Size.ICON_PX};

  &[aria-hidden="true"] {
    margin-right: ${Size.MARGIN_X_LARGE_PX};
  }
`;

const Text = styled.span`
  display: none;
  @media ${DESKTOP_MODE} {
    display: none;

    &[aria-hidden="false"] {
      display: flex;
      align-items: flex-end;
    }
  }
`;
