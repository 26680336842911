import styled from "styled-components";
import { PlaylistID } from "Models/PlaylistModel";
import { usePlaylistNode } from "App/Store/PlaylistNodeStore";
import { TrackHeader } from "App/Features/Header/TrackHeader";
import { useMyPlaylistTrack } from "../hooks/useMyPlaylistTrack";
import { useEffect } from "react";
import { PlaylistDetailAction, PlaylistDetailStore, usePlaylistDetailSelector } from "../Store";
import { PlaylistTrackList } from "App/Features/List/PlaylistTrackList";

type Props = {
  readonly id: PlaylistID;
};

export const PlayLists = ({ id }: Props) => {
  const playlist = usePlaylistNode(id);
  const trackList = playlist?.trackList ?? [];
  const isLoadedPlaylist = usePlaylistDetailSelector(store => store.isLoadedPlaylist);
  const { isLoaded: isLoadedMyPlaylist } = useMyPlaylistTrack({
    ids: trackList.map(({ id }) => id.raw) ?? [],
    isSkip: !trackList.length
  });

  const isLoaded = isLoadedMyPlaylist && isLoadedPlaylist;

  useEffect(() => {
    PlaylistDetailStore.dispatch(PlaylistDetailAction.setIsLoadedPlaylistTrack(isLoaded));
  }, [isLoaded]);

  return (
    <Layout>
      <TrackHeader />
      <PlaylistTrackList playlist={id} trackList={trackList} isDetail isLoadedTrack={isLoaded} />
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
`;
