import { Info } from "App/Atomics/AutoComplete/TextAutoComplete";
import { loadPlaylistFind } from "GraphQL/Queries/loadPlaylistFind";
import { PlaylistType } from "GraphQL/Schema";

export const findPlaylistList = async (keyword: string, token: string): Promise<readonly Info[]> => {
  try {
    const data = await loadPlaylistFind({
      where: {
        type: PlaylistType.My,
        title__starts_with: keyword
      },
      token
    });
    if (data.data) {
      return data.data.playlist.map(item => {
        return { id: item.id.toString(), name: item.title };
      });
    }
  } catch (error) {}
  return [];
};
