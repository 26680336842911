import { useEffect, useState } from "react";

export const useLoaded = (loading: boolean) => {
  const [isLoading, setIsLoading] = useState<boolean>(loading);

  useEffect(() => {
    setIsLoading(loading);
    return () => setIsLoading(false);
  }, [loading]);

  return { isLoaded: loading ? false : !isLoading };
};
