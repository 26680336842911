import shajs from "sha.js";

export const sha256 = (value: any): Hash => new shajs.sha256().update(value).digest("hex");

export type Hash = string;

export const hash = (() => {
  let index = 0;

  const hash = (index: number): Hash => (index < 26 ? String.fromCharCode(97 + index) : hash(index / 26 - 1) + hash(index % 26));

  return (): Hash => hash(index++);
})();
