import { Icon } from "App/Atomics/Icon";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { Color } from "Constants/Color";
import {
  ConfirmAllCheckAgree,
  ConfirmPurchaseCehckDescription1,
  ConfirmPurchaseCehckDescription2,
  ConfirmPurchaseCehckTitle1,
  ConfirmPurchaseCehckTitle2
} from "Constants/Locale";
import { useCurrentLanguage } from "Lib/localization";
import { ReactElement } from "react";
import styled from "styled-components";
import { BuyCheckAction, BuyCheckStore, useBuyCheckSelector } from "../../Store";
import { BuyCheckStyle } from "../../Style";
import { TermItem } from "./TermItem";

export const TermInfo = (): ReactElement => {
  const agreeTerm = useBuyCheckSelector(store => store.current.agreeTerm);
  const toggleAgreeTerm = () => BuyCheckStore.dispatch(BuyCheckAction.toggleAgreeTerm());
  const languageCode = useCurrentLanguage();

  const TERM_LIST = [
    { title: ConfirmPurchaseCehckTitle1.message(), subtitle: ConfirmPurchaseCehckDescription1.message() },
    {
      title: ConfirmPurchaseCehckTitle2.message(),
      subtitle: ConfirmPurchaseCehckDescription2.message()
    }
  ];

  return (
    <BuyCheckStyle.Layout>
      {TERM_LIST.map((args, index) => (
        <TermItem {...args} key={index + languageCode} />
      ))}
      <AgreeButton color={agreeTerm ? Color.AC_Carrot : "rgba(255,255,255,0.4)"} onClick={toggleAgreeTerm}>
        <CheckIcon name="check" fill={agreeTerm ? Color.AC_Carrot : "rgba(255,255,255,0.4)"} />
        <Typography
          size={TypoSize.F12}
          weight={agreeTerm ? TypoWeight.Bold : TypoWeight.Regular}
          color={agreeTerm ? Color.AC_Carrot : Color.Ac_Gray2}
        >
          <ConfirmAllCheckAgree />
        </Typography>
      </AgreeButton>
    </BuyCheckStyle.Layout>
  );
};

const AgreeButton = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  padding: 11px 15px;
  border: 1px solid ${({ color }) => color};
  border-radius: 5px;
  user-select: none;
  cursor: pointer;
`;
const CheckIcon = styled(Icon)`
  margin-right: 6px;
`;
