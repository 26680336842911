import { PurchaseItems } from "App/Features/List/PurchaseItems";
import { usePurchaseItemNodeStore } from "App/Store/PurchaseItemNodeStore";
import { useParams } from "react-router-dom";
import styled from "styled-components";

export const Content = () => {
  const { id } = useParams();
  const trackList = usePurchaseItemNodeStore(store =>
    store.filter(({ purchaseHistoryId }) => purchaseHistoryId.raw === +id!).map(({ track }) => track)
  );

  return (
    <Layout>
      <PurchaseItems trackList={trackList} />
    </Layout>
  );
};

const Layout = styled.div``;
