import { configs } from "Config";
import { User } from "GraphQL/Schema";
import { createHttpLink, gql, from, onError, ApolloClient, InMemoryCache, setContext } from "Lib/apollo-client";

const LOAD_USER = gql`
  query LOAD_USER {
    userInfo {
      id
      email
      tier
      name
      company
      is_verification
    }
  }
`;
const ERROR_UNAUTHORIZED = "Unauthorized";

type Result = {
  readonly userInfo: User;
};

export const loadUser = async (accessToken: string, errorCallback?: () => void) => {
  const httpLink = createHttpLink({
    uri: configs.urls.service
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${accessToken}`
      }
    };
  });

  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors?.[0].message === ERROR_UNAUTHORIZED && errorCallback) {
      errorCallback();
    }
    if (graphQLErrors?.[0].extensions.code === "INTERNAL_SERVER_ERROR" && errorCallback) {
      errorCallback();
    }
  });
  const client = new ApolloClient({
    link: from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache()
  });
  const { data, error } = await client.query<Result>({ query: LOAD_USER });
  return { data, error };
};
