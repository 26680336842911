import { User } from "GraphQL/Schema";
import { gql, useMutation } from "Lib/apollo-client";

const UPDATE_PRODUCT_PRICE = gql`
  mutation UPDATE_PRODUCT_PRICE($productPriceId: Int!, $description: String!) {
    updateProductPrice: update_price(where: { product_price_id: $productPriceId }, description: $description) {
      id
      description
    }
  }
`;

type Result = {
  readonly createPrice: User;
};

type Args = {
  readonly productPriceId: number;
  readonly description: string;
};

export const useUpdatePrice = () => {
  const [fetch, { loading, data, error }] = useMutation<Result, Args>(UPDATE_PRODUCT_PRICE);

  return { updatePrice: fetch, loading, data, error };
};
