import { NumericID } from "Utils/id";
import { immerable } from "immer";
import { ProductPriceID } from "./ProductPriceModel";

export class FileRuleID extends NumericID {
  constructor(id: number) {
    super("fileRule", id);
  }
}

export type FileRulePlayload = {
  readonly id: number;
  readonly mp3PriceId: ProductPriceID | null;
  readonly wavPriceId: ProductPriceID | null;
  readonly amount: number;
};

export class FileRuleModel {
  static form({ id, amount, mp3PriceId, wavPriceId }: FileRulePlayload) {
    return new FileRuleModel(new FileRuleID(id), wavPriceId, mp3PriceId, amount);
  }

  constructor(
    readonly id: FileRuleID,
    readonly mp3PriceId: ProductPriceID | null,
    readonly wavPriceId: ProductPriceID | null,
    readonly amount: number
  ) {}
  readonly [immerable] = true;
}
