import { Color } from "Constants/Color";
import { MOBILE_PX, Size } from "Constants/Size";
import styled from "styled-components";
import { usePlaylist } from "Utils/artistscardPlayer";
import { PlayerHeader } from "App/Features/Header/PlayerHeader";
import { Playlist } from "Constants/Locale";
import { PlayerList } from "App/Features/List/PlayerList";

type Props = {
  visiblity: boolean;
};

export const PlayerView = ({ visiblity }: Props) => {
  const trackList = usePlaylist();

  return (
    <Layout visiblity={visiblity}>
      <HeaderLayout>
        <Header>
          <Playlist /> <Count>({trackList.length})</Count>
        </Header>
      </HeaderLayout>
      <BodyLayout>
        <PlayerHeader />
        <Scroll>
          <PlayerList trackList={trackList.map(({ track }) => track)} isLoadedPlayerlist></PlayerList>
        </Scroll>
      </BodyLayout>
    </Layout>
  );
};

const Layout = styled.div<{ visiblity: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(100% - ${Size.PLAYER_BAR_HEIGHT_PX});
  z-index: 900;
  background-color: ${Color.Ac_Black};
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  ${props => (props.visiblity ? `` : `transform: translate3d(0, 100vh, 0);`)}

  padding: 2rem 0;

  display: flex;
  flex-direction: column;
  min-height: 0;

  @media (max-width: ${MOBILE_PX}) {
    top: 0 !important;
    bottom: ${Size.PLAYER_BAR_HEIGHT_PX};
    height: 100%;
    z-index: 999 !important;
  }
`;

const HeaderLayout = styled.div``;
const Header = styled.h2`
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  padding: 0 25px;
`;
const Count = styled.span`
  font-size: 1rem;
  color: ${Color.Ac_Gray0};
  margin-left: 0.3rem;
`;

const BodyLayout = styled.div`
  margin-top: 2rem;
  width: 100%;
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
`;

const Scroll = styled.div`
  flex: 1;
  min-height: 0;
  overflow-y: auto;
`;
