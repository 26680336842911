import { Color } from "Constants/Color";
import { WIDE_MODE, X_WIDE_MODE } from "Constants/Size";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import styled from "styled-components";
import { useTrackInfo } from "Utils/useTrackInfo";

type Props = {
  isDetail: boolean;
};

export const TrackContentLoader = ({ isDetail }: Props) => {
  const { isInfo } = useTrackInfo();
  const isShow = isDetail && isInfo;

  return (
    <SkeletonTheme baseColor="#202020" highlightColor="#444">
      <Layout>
        <Track>
          <FlexAudioContainer>
            <PlayerButton>
              <Skeleton height={40} borderRadius={100} />
            </PlayerButton>
          </FlexAudioContainer>
          <FlexRightContainer>
            <FlexRightTopContainer>
              <FlexInformationContainer>
                <TrackTitleLayout>
                  <TrackTitleContainer>
                    <TrackTitle>
                      <Skeleton />
                    </TrackTitle>
                  </TrackTitleContainer>
                  <ArtistContainer>
                    <Artist>
                      <Skeleton />
                    </Artist>
                  </ArtistContainer>
                </TrackTitleLayout>
                <DurationLayout>
                  <StatusContentLayout>
                    <Skeleton height={20} />
                  </StatusContentLayout>
                </DurationLayout>
                <BpmLayout>
                  <StatusContentLayout>
                    <Skeleton height={20} />
                  </StatusContentLayout>
                </BpmLayout>
              </FlexInformationContainer>
              <FlexStatusContainer>
                <StatusIconContainer>
                  <Skeleton height={20} width={32} />
                </StatusIconContainer>
                <StatusIconContainer>
                  <Skeleton height={20} width={32} />
                </StatusIconContainer>
                <StatusIconContainer>
                  <Skeleton height={20} width={32} />
                </StatusIconContainer>
                <StatusIconContainer>
                  <Skeleton height={20} width={32} />
                </StatusIconContainer>
                <StatusIconContainer>
                  <Skeleton height={20} width={32} />
                </StatusIconContainer>
              </FlexStatusContainer>
              <FlexBuyContainer>
                <Skeleton width={160} height={40} borderRadius={20} />
              </FlexBuyContainer>
            </FlexRightTopContainer>

            <TrackDetailContainer isDetail={isShow}>
              <Padding />
            </TrackDetailContainer>
          </FlexRightContainer>
        </Track>
      </Layout>
    </SkeletonTheme>
  );
};

const Layout = styled.div`
  border-top: 1px solid ${Color.Ac_Gray};

  &:last-child {
    border-bottom: 1px solid ${Color.Ac_Gray};
  }
`;

const TrackDetailContainer = styled.div<{ isDetail: boolean }>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  transition: all 0.3s;

  transition: max-height 0.3s ${props => (props.isDetail ? "cubic-bezier(1, 0, 1, 0)" : "cubic-bezier(0,1,0,1)")};
  max-height: ${props => (props.isDetail ? "99999px" : "0px")};
  overflow: hidden;
`;

const Padding = styled.div`
  width: 100%;
  padding-top: 2rem;
`;

const FlexRightTopContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
`;

const FlexAudioContainer = styled.div`
  margin-right: 1rem;
  flex: 0 0 54px;
`;

const PlayerButton = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 3rem;
  padding: 0;
`;

const FlexRightContainer = styled.div`
  flex: 1 0 10px;
  min-width: 0;
  flex-direction: column;
  display: flex;
  align-items: center;
`;

const FlexInformationContainer = styled.div`
  margin-right: 1rem;
  flex: 5 0 50%;
  min-width: 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TrackTitleLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex: 9 0 50px;
  font-size: 0.9rem;
  min-width: 0;
`;

const DurationLayout = styled.div`
  margin-right: 0.625rem;
  flex: 1 0 40px;
  font-size: 0.9rem;
  text-align: center;

  justify-content: center;

  @media ${X_WIDE_MODE} {
    display: flex;
  }
  display: none;
`;

const BpmLayout = styled.div`
  margin-right: 0.625rem;
  flex: 1 0 40px;
  font-size: 0.9rem;
  text-align: center;
  justify-content: center;
  @media ${X_WIDE_MODE} {
    display: flex;
  }
  display: none;
`;

const TrackTitleContainer = styled.div`
  margin-right: 0.625rem;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TrackTitle = styled.span`
  font-size: 1rem;
  font-weight: bold;

  cursor: pointer;
`;

const FlexStatusContainer = styled.div`
  margin-right: 1rem;
  min-width: 0;

  @media ${WIDE_MODE} {
    flex: 1 0 200px;
    display: flex;
  }

  display: none;

  display: flex;
  align-items: center;
`;

const StatusIconContainer = styled.div`
  /* height: 30px; */
  min-width: 30px;

  padding: 0 5px;

  cursor: pointer;
`;

const ArtistContainer = styled.div`
  width: 50%;
  font-size: 0.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${Color.Ac_Gray1};
`;
const Artist = styled.span`
  cursor: pointer;
`;

const FlexBuyContainer = styled.div`
  flex: 1 0 140px;
  @media ${WIDE_MODE} {
    display: block;
  }
  display: none;
`;

const Track = styled.div`
  padding: 25px;
  display: flex;
`;

const StatusContentLayout = styled.div`
  width: 50%;
`;
