import { Color } from "Constants/Color";
import { noop } from "lodash-es";
import { Range } from "rc-slider";
import { FocusEvent } from "react";
import styled, { CSSProperties } from "styled-components";

export type RangeSliderProps = Styleable & {
  readonly onChange?: (value: number[]) => void;
  readonly onFocus?: () => void;
  readonly onBlur?: () => void;
  readonly onAfterChange?: (value: string | null) => void;
  readonly defaultValue?: number[];
  readonly min: number;
  readonly max: number;
  readonly allowCross?: boolean;
  readonly style?: CSSProperties;
  readonly handleStyle?: CSSProperties[];
  readonly trackStyle?: CSSProperties[];
  readonly railStyle?: CSSProperties;
  readonly value: number[];
};

export const RangeSlider = ({
  onBlur = noop,
  onChange = noop,
  onFocus = noop,
  onAfterChange = noop,
  defaultValue,
  min,
  max,
  allowCross = false,
  style = RangeSliderStyle,
  handleStyle = HandleStyle,
  trackStyle = TrackStyle,
  railStyle = RailStyle,
  value,
  ...props
}: RangeSliderProps) => {
  const change = (value: number[]) => {
    if (value[0] === min && value[1] === max) {
      return onChange([min, max]);
    }
    const first = value[0] > min ? value[0] : min;
    const second = value[1] < max ? value[1] : max;

    return onChange([first, second]);
  };

  const blur = (event: FocusEvent<HTMLDivElement, Element>) => {
    return onBlur();
  };

  const afterChange = (value: number[]) => {
    if (value[0] === min && value[1] === max) {
      return onAfterChange(null);
    }
    let val = "";

    if (value[0] > min) {
      val += value[0];
    }
    val += "-";
    if (value[1] < max) {
      val += value[1];
    }

    return onAfterChange(val);
  };

  return (
    <Layout>
      <Range
        onChange={change}
        onBlur={blur}
        value={value}
        onFocus={onFocus}
        onAfterChange={afterChange}
        {...props}
        allowCross={allowCross}
        defaultValue={defaultValue}
        style={RangeSliderStyle}
        handleStyle={HandleStyle}
        trackStyle={TrackStyle}
        railStyle={RailStyle}
        min={min}
        max={max}
      />
    </Layout>
  );
};

const Layout = styled.div`
  padding: 0 8px;
`;

const HandleStyle = [
  {
    border: "0px",
    boxShadow: "none"
  },
  {
    border: "0px",
    boxShadow: "none"
  }
];

const TrackStyle = [
  {
    backgroundColor: `${Color.AC_Carrot}`
  }
];

const RailStyle = {
  backgroundColor: `${Color.Ac_Shadow}`
};

const RangeSliderStyle = {
  width: "100%"
};
