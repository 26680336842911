import { Edge } from "Constants/Edge";
import { TRACK_FRAGMENT } from "GraphQL/Fragment/TrackFragment";
import { Aggregate, Track, TrackWhereInput } from "GraphQL/Schema";
import { gql, useQuery } from "Lib/apollo-client";

export const LOAD_TRACK = gql`
  ${TRACK_FRAGMENT}
  query LOAD_TRACK($where: TrackWhereInput, $first: Int) {
    track(where: $where, first: $first) {
      ...TrackFragment
    }
  }
`;

type Result = {
  readonly track: Track[];
  readonly edge: Edge;
  readonly getCount: Aggregate;
};

type Args = {
  readonly where?: TrackWhereInput;
  readonly skip?: boolean;
  readonly first?: number;
};

export const useLoadTrack = ({ where, skip, first = 20 }: Args) => {
  const { data, loading } = useQuery<Result, Args>(LOAD_TRACK, {
    variables: {
      where,
      first
    },
    skip
  });

  return { loading, data };
};
