import { usePlaylistNodeStore } from "App/Store/PlaylistNodeStore";
import { PlaylistType } from "GraphQL/Schema";
import styled from "styled-components";
import { useLikePlaylistDetail } from "../hooks/useLikePlaylistDetail";
import { LibraryFavoriteForm } from "./LibraryFavoriteForm";

export const Form = () => {
  useLikePlaylistDetail();
  const playlist = usePlaylistNodeStore(store => store.filter(({ type }) => type === PlaylistType.Like))[0]?.id;

  return (
    <Layout>
      <LibraryFavoriteForm id={playlist} />
    </Layout>
  );
};

const Layout = styled.div``;
