import { User, UserWhereInput } from "GraphQL/Schema";
import { gql, useQuery } from "Lib/apollo-client";

const LOAD_USER = gql`
  query LOAD_USER($where: UserWhereInput!) {
    user(where: $where) {
      id
      email
      name
      company
      user_wallet {
        balance
      }
    }
  }
`;

type Result = {
  user: User[];
};

type Props = {
  where: UserWhereInput;
  skip?: boolean;
};

type Args = {
  readonly where?: UserWhereInput;
};

export const useLoadUser = ({ where, skip }: Props) => {
  const { data, loading } = useQuery<Result, Args>(LOAD_USER, {
    variables: {
      where
    },
    fetchPolicy: "network-only",
    skip
  });

  return { data, loading };
};
