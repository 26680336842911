import { PurchaseItems } from "App/Features/List/PurchaseItems";
import { usePurchaseItemNodeList } from "App/Store/PurchaseItemNodeStore";
import styled from "styled-components";
import { usePurchaseHistorySelector } from "../../Store";

export const Content = () => {
  const { purchaseItemList } = usePurchaseHistorySelector(store => store);
  const trackList = usePurchaseItemNodeList(purchaseItemList).map(({ track }) => track);

  return (
    <Layout>
      <PurchaseItems trackList={trackList} />
    </Layout>
  );
};

const Layout = styled.div``;
