import { ApolloCache } from "@apollo/client";
import { MAX_FIRST_COUNT } from "Constants/Count";
import { TRACK_FRAGMENT } from "GraphQL/Fragment/TrackFragment";
import { LOAD_ALL_PLAYLIST } from "GraphQL/gql/playlist";
import { LOAD_PLAYLIST_DETAIL } from "GraphQL/Queries/useLoadPlaylistDetail";

import { Playlist, PlaylistItem, PlaylistType } from "GraphQL/Schema";
import { gql, useMutation } from "Lib/apollo-client";
import { Toast } from "Lib/toast";
import { useEffect } from "react";

const ADD_PLAYLIST_TRACK = gql`
  ${TRACK_FRAGMENT}
  mutation ADD_PLAYLIST_TRACK($playlistId: Int!, $trackId: Int!) {
    addPlaylistTrack: push_playlist_item(playlist_id: $playlistId, track_id: $trackId) {
      id
      track_id
      playlist_id
      track {
        ...TrackFragment
      }
    }
  }
`;

type Result = {
  readonly addPlaylistTrack: PlaylistItem;
};

type Args = {
  readonly playlistId: number;
  readonly trackId?: number;
};

type addPlaylistTrackCacheType = {
  readonly cache: ApolloCache<any>;
  readonly playlistItem: PlaylistItem;
  readonly playlistId: number;
};

const addPlaylistTrackFormCache = ({ cache, playlistItem, playlistId }: addPlaylistTrackCacheType) => {
  cache.updateQuery<{ playlist: Playlist[] }>(
    {
      query: LOAD_ALL_PLAYLIST,
      variables: {
        first: MAX_FIRST_COUNT,
        where: {
          id: playlistId,
          type: PlaylistType.My
        }
      }
    },
    data => {
      const playlist = data?.playlist[0];
      console.log(playlist, playlistItem);
      const updateData = { ...playlist, playlist_item: [...playlist!.playlist_item!, playlistItem] } as Playlist;
      return {
        playlist: [updateData]
      };
    }
  );
};

export const useAddPlaylistTrack = () => {
  const [fetch, { loading, error }] = useMutation<Result, Args>(ADD_PLAYLIST_TRACK, {
    update(cache, { data }, { variables }) {
      const playlistItem = data!.addPlaylistTrack;
      const playlistId = variables!.playlistId;
      addPlaylistTrackFormCache({ cache, playlistItem, playlistId });
    },
    refetchQueries: ({ data }) => {
      return [
        {
          query: LOAD_PLAYLIST_DETAIL,
          variables: {
            where: {
              id: data?.addPlaylistTrack.playlist_id
            }
          }
        }
      ];
    },
    awaitRefetchQueries: true
  });

  useEffect(() => {
    if (error) {
      if (error?.graphQLErrors[0]?.message === "is already purchased curation") {
        Toast.error("환불 후 수정해주세요.");
      }
    }
  }, [error]);

  return { addPlaylistTrack: fetch, loading };
};
