import { Button, ButtonColor, ButtonSize } from "App/Atomics/Button";
import { Icon } from "App/Atomics/Icon";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { Color } from "Constants/Color";
import { ADMIN_CURATION } from "Constants/Routes";
import { useSendCuration } from "GraphQL/Mutations/useSendCuration";
import { useLoadCurationManagement } from "GraphQL/Queries/useLoadCurationManagement";
import { useLoadPlaylistTrackDetail } from "GraphQL/Queries/useLoadPlaylistTrackDetail";
import { Toast } from "Lib/toast";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { CurationUserAction, CurationUserStore, Section, useCurationUserSelector } from "../../Store/CurationUserStore";

export const Third = () => {
  const navigate = useNavigate();
  const { userInfo, productPrice } = useCurationUserSelector(store => store);
  const back = () => CurationUserStore.dispatch(CurationUserAction.setSection(Section.Second));
  const { data: sendData, loading: sendLoading, error, sendCuration } = useSendCuration();
  const { data, loading } = useLoadCurationManagement({
    isForce: true,
    where: {
      user_id: +userInfo?.id!
    }
  });

  const { id } = useParams();
  const { loading: playlistLoading, data: playlistDetail } = useLoadPlaylistTrackDetail({
    id: +id!
  });

  const tryToConnetUserAndTrack = async () => {
    if (!window.confirm("유저와 연결 하시겠습니까?")) {
      return;
    }

    if (!!overlappingTracks.length && !window.confirm("중복되는 트랙이있습니다 그래도 진행하시겠습니까??")) {
      return;
    }

    try {
      await sendCuration({
        variables: {
          playlistId: +id!,
          userId: +userInfo?.id!,
          priceId: +productPrice?.id!
        }
      });
    } catch (error) {
      Toast.error("오류가 발생했습니다");
    }
  };

  useEffect(() => {
    if (sendData) {
      Toast.success("연결했습니다");
      CurationUserStore.dispatch(CurationUserAction.clear());
      navigate(ADMIN_CURATION);
    }
  }, [sendData]);

  if (loading || playlistLoading) return null;

  const curationPlaylist = playlistDetail?.playlist ?? [];

  const curationTrack = curationPlaylist
    ?.map(({ playlist_item }) => playlist_item)
    .flat()
    .map(track => track?.track!);

  const exsitionData = data?.curationManagement
    .map(({ playlist }) => playlist.playlist_item)
    .flat()
    .map(track => track?.track_id!);

  const overlappingTracks = curationTrack.filter(track => exsitionData?.includes(track.id)) ?? [];

  return (
    <Layout>
      <Typography weight={TypoWeight.Bold} color={Color.Ac_White} size={TypoSize.F24}>
        플레이 리스트 중복 검사
      </Typography>

      <Playlist>
        {overlappingTracks.map(track => {
          return (
            <>
              <PlaylistItem>{track.title}</PlaylistItem>
            </>
          );
        })}
        {!overlappingTracks.length && (
          <Typography size={TypoSize.F14} weight={TypoWeight.Bold}>
            중복되는 트랙이 없습니다.
          </Typography>
        )}
      </Playlist>

      {error && (
        <ErrorBox>
          <Typography size={TypoSize.F13} weight={TypoWeight.Bold} color={Color.Ac_Red1}>
            {error.graphQLErrors[0].message}
          </Typography>
        </ErrorBox>
      )}

      <ButtonGroup>
        {!sendLoading && (
          <>
            <SubmitButton size={ButtonSize.Small} color={ButtonColor.PrimaryNotFill} onClick={back}>
              뒤로가기
            </SubmitButton>
            <SubmitButton size={ButtonSize.Small} color={ButtonColor.Primary} onClick={tryToConnetUserAndTrack}>
              연결하기
            </SubmitButton>
          </>
        )}
        {loading && (
          <SubmitButton size={ButtonSize.Small} color={ButtonColor.Disable} disabled>
            <Loading name="loading" />
          </SubmitButton>
        )}
      </ButtonGroup>
    </Layout>
  );
};

const Layout = styled.div``;

const Playlist = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  border: 1px solid ${Color.Ac_Gray1};
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 0.25rem;
`;

const ErrorBox = styled.div`
  width: 100%;
  padding: 1rem;
  background-color: ${Color.Ac_Shadow};
  border-radius: 1rem;
  border: 2px solid ${Color.AC_Carrot};
  display: flex;
  flex-direction: column;
  white-space: normal;
  margin: 1rem 0;
`;
const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
`;
const SubmitButton = styled(Button)`
  width: 100px;
  margin: 0 0.25rem;
`;
const Loading = styled(Icon)`
  width: 1.25rem;
  height: 1.25rem;
`;

const PlaylistItem = styled.div`
  padding: 0.25rem 0;
  color: ${Color.Ac_Red1};
`;
