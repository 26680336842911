import { AccountAction, AccountStore, useAccountSelector } from "App/Store/AccountStore";
import { useLoadCartCount } from "GraphQL/Queries/useLoadCartCount";
import { isGuestAccountModel } from "Models/AccountModel/AccountModel";
import { useLayoutEffect } from "react";

export const useCartCount = () => {
  const isLogin = !useAccountSelector(store => isGuestAccountModel(store.current));
  const { data, loading } = useLoadCartCount({
    isSkip: !isLogin
  });

  useLayoutEffect(() => {
    if (!isLogin) {
      AccountStore.dispatch(AccountAction.setCartCount(0));
    }
    if (data) {
      AccountStore.dispatch(AccountAction.setCartCount(data.cartCount.count));
    }
  }, [data, isLogin]);

  return { loading };
};
