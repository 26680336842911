import { Button, ButtonColor, ButtonSize } from "App/Atomics/Button";
import { Icon } from "App/Atomics/Icon";
import { Input } from "App/Atomics/Input";
import { ModalAction, ModalProps, ModalStore } from "App/Store/ModalStore";
import { usePlaylistNode } from "App/Store/PlaylistNodeStore";
import { Color } from "Constants/Color";
import { ConfirmDeleteItem, Delete, Description, Modify, ModifyPlaylist, TitleErrorNull } from "Constants/Locale";
import { useRemovePlaylist } from "GraphQL/Mutations/useRemovePlaylist";
import { useUpdatePlaylist } from "GraphQL/Mutations/useUpdatePlaylist";
import { Modal } from "Lib/modal";
import { Toast } from "Lib/toast";
import { PlaylistID } from "Models/PlaylistModel";
import { useRef } from "react";
import styled from "styled-components";
import { Title as TitleLocale } from "Constants/Locale";
import { useNavigate } from "react-router-dom";
import { MY_PLAYLIST } from "Constants/Routes";
type Props = {
  readonly props: ModalProps;
  readonly isOpen: boolean;
};

export const ModifyPlayListModal = ({ props, isOpen }: Props) => {
  const playlistID = props.playlist as PlaylistID;
  const playlist = usePlaylistNode(playlistID);
  const onRequestClose = () => ModalStore.dispatch(ModalAction.closeAll());
  const { updatePlaylist } = useUpdatePlaylist();
  const { removePlaylist } = useRemovePlaylist();
  const inputEmail = useRef<HTMLInputElement>(null);
  const textAreaDescription = useRef<HTMLTextAreaElement>(null);
  const navigate = useNavigate();
  if (!playlist) {
    return null;
  }

  const onUpdatePlaylist = async (id: number) => {
    const title = inputEmail.current?.value;
    const description = textAreaDescription.current?.value;

    if (!title?.trim()) {
      Toast.primary(TitleErrorNull.message());
      return;
    }

    await updatePlaylist({
      variables: {
        playlistId: id,
        title,
        description
      }
    });

    onRequestClose();
  };

  const onDeletePlaylist = async () => {
    if (!window.confirm(ConfirmDeleteItem.message())) {
      return;
    }
    await removePlaylist({
      variables: {
        playlistId: playlistID.raw
      }
    });
    navigate(MY_PLAYLIST);
    onRequestClose();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Layout>
        <HeaderLayout>
          <Title>
            <ModifyPlaylist />
          </Title>
          <CrossIcon name="close" onClick={onRequestClose}></CrossIcon>
        </HeaderLayout>
        <CustomInput ref={inputEmail} type="text" placeholder={TitleLocale.message()} defaultValue={playlist.title} />
        <CustomTextArea ref={textAreaDescription} placeholder={Description.message()} defaultValue={playlist.desciprtion}></CustomTextArea>
        <ButtonContainer>
          <Button style={{ width: "50%" }} size={ButtonSize.Small} color={ButtonColor.OutRed} onClick={onDeletePlaylist}>
            <Delete />
          </Button>
          <Button
            style={{ width: "50%" }}
            size={ButtonSize.Small}
            color={ButtonColor.Primary}
            onClick={() => onUpdatePlaylist(playlistID.raw)}
          >
            <Modify />
          </Button>
        </ButtonContainer>
      </Layout>
    </Modal>
  );
};

const Layout = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;
  background-color: ${Color.Ac_Charcoal};
  padding: 30px;
  overflow: hidden;
  pointer-events: auto;

  display: flex;
  flex-direction: column;
`;

const CrossIcon = styled(Icon)`
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
`;

const Title = styled.h2`
  font-size: 1.5rem;
`;

const CustomInput = styled(Input.Text)`
  background-color: ${Color.Ac_Shadow};
  border-color: ${Color.Ac_Gray0};

  &::placeholder {
    color: ${Color.Ac_Gray0};
  }
  color: ${Color.Ac_White}!important;
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;

  button:first-child {
    margin-right: 1rem;
  }
`;

const HeaderLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const CustomTextArea = styled(Input.TextArea)`
  background-color: ${Color.Ac_Shadow};
  border-color: ${Color.Ac_Gray0};

  margin-top: 1rem;

  &::placeholder {
    color: ${Color.Ac_Gray0};
  }
  color: ${Color.Ac_White}!important;
`;
