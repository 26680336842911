import { ADMIN_HOME } from "Constants/Routes";
import { Navigate, Outlet } from "react-router-dom";
import { parseJwt } from "Utils/format";

import { useAuth } from "Utils/useAuth";

export const CheckTier = () => {
  const auth = useAuth();
  const tier = auth.token ? parseJwt(auth.token?.access_token).tier : null;

  if (tier !== "ADMIN") {
    return <Navigate to={ADMIN_HOME} />;
  }

  return <Outlet />;
};
