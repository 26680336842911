import { gql } from "@apollo/client";

export const PLAYLIST_FRAGMENT = gql`
  fragment PlaylistFragment on Playlist {
    id
    description
    title
    type
    updated_at
    user_id
    playlist_item(orderBy: id__DESC, first: 2147483647) {
      id
      playlist_id
      track_id
      description
    }
  }
`;
