import { Color } from "Constants/Color";
import { ReactNode } from "react";
import styled from "styled-components";

export enum TypoWeight {
  Bold = "bold",
  Regular = "normal"
}

export enum TypoSize {
  F48 = "48px",
  F40 = "40px",
  F32 = "32px",
  F24 = "24px",
  F20 = "20px",
  F18 = "18px",
  F16 = "16px",
  F15 = "15px",
  F14 = "14px",
  F13 = "13px",
  F12 = "12px",
  F11 = "11px",
  F10 = "10px"
}

type Props = Styleable & {
  readonly weight?: TypoWeight;
  readonly size?: TypoSize | string;
  readonly color?: string;
  readonly children?: ReactNode;
  readonly onClick?: () => void;
};

export const Typography = ({ size = TypoSize.F16, weight = TypoWeight.Regular, color = Color.Ac_White, children, ...props }: Props) => (
  <Text size={size} weight={weight} color={color} {...props}>
    {children}
  </Text>
);

const Text = styled.span<{ size: TypoSize | string; weight: TypoWeight; color: string }>`
  font-size: ${({ size }) => `${size}`};
  font-weight: ${({ weight }) => weight};
  color: ${({ color }) => color};
`;
