import { usePointTransactionNodeStore } from "App/Store/PointTransactionNodeStore";
import styled from "styled-components";
import { HistoryPointItem } from "./HistoryPointItem";

export const HistoryPointContent = () => {
  const historyPoint = usePointTransactionNodeStore(store => store.keys());
  return (
    <Layout>
      {historyPoint.map(id => (
        <HistoryPointItem key={id.key} id={id} />
      ))}
    </Layout>
  );
};

const Layout = styled.tbody``;
