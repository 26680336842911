import { Icon } from "App/Atomics/Icon";
import { usePlaylistNode } from "App/Store/PlaylistNodeStore";
import { PlaylistModalAdd, PlaylistModalRemove } from "Constants/Locale";
import { useAddPlaylistTrack } from "GraphQL/Mutations/useAddPlaylistTrack";
import { useRemovePlaylistTrack } from "GraphQL/Mutations/useRemovePlaylistTrack";
import { Toast } from "Lib/toast";
import { PlaylistID } from "Models/PlaylistModel";
import { TrackID } from "Models/TrackModel";
import styled from "styled-components";

type Props = {
  readonly id: PlaylistID;
  readonly trackId: TrackID;
  readonly onClose: () => void;
};

export const PlaylistItem = ({ id, trackId, onClose }: Props) => {
  const playlist = usePlaylistNode(id);
  const isAdded = playlist?.trackList.map(({ id }) => id.raw).includes(trackId.raw) ?? false;
  const { addPlaylistTrack } = useAddPlaylistTrack();
  const { removePlaylistTrack } = useRemovePlaylistTrack();

  const addPlaylist = async () => {
    try {
      await addPlaylistTrack({
        variables: {
          playlistId: id.raw,
          trackId: trackId.raw
        }
      });
      Toast.success(PlaylistModalAdd.message());
    } catch (error) {
      console.log(error);
    }
    onClose();
  };

  const removePlaylist = async () => {
    try {
      await removePlaylistTrack({
        variables: {
          playlistId: id.raw,
          trackId: trackId.raw
        }
      });
      Toast.success(PlaylistModalRemove.message());
    } catch (error) {
      console.log(error);
    }
    onClose();
  };

  return (
    <Layout onClick={isAdded ? removePlaylist : addPlaylist}>
      <Title>{playlist?.title}</Title>
      {isAdded && <Check name="checkbox-fill" />}
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }

  justify-content: space-between;
`;

const Title = styled.p`
  font-size: 0.825rem;
`;

const Check = styled(Icon)`
  width: 0.825rem;
  height: 0.825rem;
`;
