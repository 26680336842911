import { Color } from "Constants/Color";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import styled from "styled-components";

export const ArtistInformationContentLoader = () => {
  return (
    <SkeletonTheme baseColor={Color.Ac_Shadow} highlightColor={Color.Ac_Gray0}>
      <Layout>
        <Artist>
          <Skeleton width={"100%"} />
        </Artist>
        <TagList>
          <Skeleton width={200} height={50} />
        </TagList>
        <PlayButton>
          <Skeleton height={35} width={100} borderRadius={20} />
        </PlayButton>
      </Layout>
    </SkeletonTheme>
  );
};

const Layout = styled.div`
  position: relative;
  width: 100%;
  min-height: 500px;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Artist = styled.h2`
  width: 100%;
  font-size: 3.5rem;
  text-shadow: 0 0 50px ${Color.Ac_Black};
  text-align: center;
`;

const TagList = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 700px;
  margin-bottom: 2rem;
`;

const PlayButton = styled.div`
  height: 35px;
  border-radius: 1.5rem;

  border: none;
`;
