import { BASIC_FIRST_COUNT, MAX_BETWEEN, MIN_BETWEEN } from "Constants/Count";
import { useLoadMetadataTrack } from "GraphQL/Queries/useLoadMetadataTrack";
import { MetdataKind } from "Models/GenreModel/GenreModel";
import { TypeMetadataSubclass, useSubclass } from "Utils/useSubclass";

interface Props {
  readonly genres: string[];
  readonly moods: string[];
  readonly roles: string[];
  readonly duration: string | null;
  readonly bpm: string | null;
  readonly search: string | null;
  readonly page: number;
  readonly isSkip: boolean;
  readonly subclass: TypeMetadataSubclass;
  readonly order: string | null;
  readonly artist?: string;
  readonly companyIds: string[];
}

export const useMetadataTrackList = ({
  genres,
  moods,
  roles,
  page,
  duration,
  bpm,
  search,
  isSkip,
  subclass,
  order,
  artist,
  companyIds
}: Props) => {
  const tagSearh = [
    ...genres.map(genre => ({
      metadata_genre_relation__some: {
        genre__some: {
          genre_id: genre
        }
      }
    })),
    ...moods.map(mood => ({
      metadata_genre_relation__some: {
        genre__some: {
          genre_id: mood
        }
      }
    })),
    ...roles.map(role => ({
      metadata_artist_relation__some: {
        role__some: {
          role_id: role
        }
      }
    }))
  ];
  const { metadataType } = useSubclass();
  const isLicense = metadataType.kind === MetdataKind.Pd || metadataType.kind === MetdataKind.Ccl;

  const { data, loading } = useLoadMetadataTrack({
    first: BASIC_FIRST_COUNT,
    page,
    orderBy: [order ?? "metadata_id__DESC"],
    where: {
      metadata_artist_relation__some: artist
        ? {
            artist__some: {
              name__contains: artist
            }
          }
        : undefined,
      track_information__some: {
        duration__between: duration ? [+(duration?.split("-")[0] || MIN_BETWEEN), +(duration?.split("-")[1] || MAX_BETWEEN)] : undefined,
        tempo__between: bpm ? [+(bpm?.split("-")[0] || MIN_BETWEEN), +(bpm?.split("-")[1] || MAX_BETWEEN)] : undefined
      },
      type_metadata_class: "record",
      type_metadata_subclass: subclass.toLocaleLowerCase(),

      track_license__some: isLicense
        ? {
            country_code__in: ["ZZ", "KR"],
            copyright: true,
            neighboring: true
          }
        : undefined,
      metadata_company_relation__some: {
        company__some: {
          company_id__in: companyIds.map(companyIds => companyIds.toString())
        }
      },

      metadata_title__some: search
        ? {
            type_metadata_title__in: ["name", "search_genre", "search_role", "search_artist"],
            tsvec_words__search: search
          }
        : undefined,
      AND: tagSearh.length ? tagSearh : undefined
    },
    skip: isSkip
  });

  return { data, loading };
};
