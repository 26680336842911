import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { Color } from "Constants/Color";
import { AllCount, Amount, Kind, Playlist, PurchaseDate } from "Constants/Locale";
import { DESKTOP_MODE } from "Constants/Size";
import styled from "styled-components";

export const PurchaseHistoryHeader = () => {
  return (
    <Layout>
      <ProductContainer>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold} color={Color.Ac_Gray0}>
          <Kind />
        </Typography>
      </ProductContainer>
      <AmountContainer>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold} color={Color.Ac_Gray0}>
          <Amount />
        </Typography>
      </AmountContainer>
      <CreateAtContainer>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold} color={Color.Ac_Gray0}>
          <PurchaseDate />
        </Typography>
      </CreateAtContainer>
      <AllCountContainer>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold} color={Color.Ac_Gray0}>
          <AllCount />
        </Typography>
      </AllCountContainer>
      <PlaylistContainer>
        <Typography size={TypoSize.F14} weight={TypoWeight.Bold} color={Color.Ac_Gray0}>
          <Playlist />
        </Typography>
      </PlaylistContainer>
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
  display: flex;
  padding: 0 30px 10px;

  border-bottom: 1px solid ${Color.Ac_Gray};
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const ProductContainer = styled(Container)`
  flex: 0 0 100px;
  display: flex;
  justify-content: flex-start;
`;

const AmountContainer = styled(Container)`
  flex: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 5rem;
`;

const CreateAtContainer = styled(Container)`
  flex: 0 0 100px;
  display: flex;
  justify-content: flex-end;
`;

const AllCountContainer = styled(Container)`
  flex: 0 0 80px;
  display: flex;
  justify-content: center;

  display: none;
  @media ${DESKTOP_MODE} {
    display: flex;
  }
`;

const PlaylistContainer = styled(Container)`
  flex: 0 0 80px;
  display: flex;
  justify-content: center;

  display: none;
  @media ${DESKTOP_MODE} {
    display: flex;
  }
`;
