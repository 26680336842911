import { gql } from "@apollo/client";

export const TRACK_FRAGMENT = gql`
  fragment TrackFragment on Track {
    id
    bpm
    duration
    title
    artist_name
    artist_id
    genre_id
    role_id
    grade_id
    file_rule_id
    type_metadata_subclass
    duration_temporary
  }
`;
