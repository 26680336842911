import { Info } from "App/Atomics/Input/Select/TextSelect";
import { createStore } from "Lib/store";

export enum Section {
  First,
  Second,
  Third
}

const createInitialState = () => ({
  section: Section.First,
  userInfo: null as Info | null,
  productPrice: null as Info | null
});

export const {
  useSelector: useCurationUserSelector,
  action: CurationUserAction,
  ...CurationUserStore
} = createStore("CurationUser", createInitialState, {
  setSection(state, section: Section) {
    state.section = section;
  },
  setUserInfo(state, userInfo: Info) {
    state.userInfo = userInfo;
  },
  setProductPrice(state, productPrice: Info) {
    state.productPrice = productPrice;
  },
  clear(state) {
    state.section = Section.First;
    state.userInfo = null;
    state.productPrice = null;
  }
});
