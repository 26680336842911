import { gql } from "@apollo/client";

export const TRACK_ACTIVITY_FRAGMENT = gql`
  fragment TrackActivityFragment on TrackActivity {
    id
    track_id
    type
    created_at
  }
`;
