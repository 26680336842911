import { Button, ButtonColor, ButtonSize } from "App/Atomics/Button";
import { Icon } from "App/Atomics/Icon";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { PlaylistTextAutoComplete } from "App/Molecules/AutoCompletes/Playlist";
import { Color } from "Constants/Color";
import { ADMIN_CURATION } from "Constants/Routes";
import { useUpdatePlaylist } from "GraphQL/Mutations/useUpdatePlaylist";
import { PlaylistType } from "GraphQL/Schema";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { SelectStyle } from "../../CechargePoint/Style";
import { Style } from "../Style";

export const Form = () => {
  const { updatePlaylist, data, error, loading } = useUpdatePlaylist();
  const navigate = useNavigate();
  const [playlistId, setPlaylistId] = useState<number | null>(null);
  const isDisable = !playlistId;
  const tryToCreateStaff = () => {
    if (!playlistId) {
      return;
    }
    updatePlaylist({
      variables: {
        playlistId: playlistId,
        type: PlaylistType.Curation
      }
    });
  };

  useEffect(() => {
    if (data) {
      navigate(ADMIN_CURATION);
    }
  }, [data]);

  return (
    <Layout>
      <Typography weight={TypoWeight.Bold} color={Color.Ac_White} size={TypoSize.F24}>
        큐레이션으로 등록할 플레이리스트를 선택해주세요
      </Typography>
      {error && (
        <ErrorBox>
          <Typography size={TypoSize.F13} weight={TypoWeight.Bold} color={Color.Ac_Red1}>
            {error.graphQLErrors[0].message}
          </Typography>
        </ErrorBox>
      )}

      <Container>
        <Style.Row>
          <Style.Caption>플레이리스트 제목*</Style.Caption>
          <Style.SubCaption>플레이리스트 검색</Style.SubCaption>
          <PlaylistTextAutoComplete
            className="autocomplete"
            style={SelectStyle}
            onChange={info => {
              if (info) {
                setPlaylistId(+info.id);
              }
            }}
          />
        </Style.Row>
        <ButtonGroup>
          {!loading && (
            <SubmitButton
              size={ButtonSize.Small}
              color={isDisable ? ButtonColor.Disable : ButtonColor.Primary}
              disabled={isDisable}
              onClick={tryToCreateStaff}
            >
              등록
            </SubmitButton>
          )}
          {loading && (
            <SubmitButton size={ButtonSize.Small} color={ButtonColor.Disable} disabled>
              <Loading name="loading" />
            </SubmitButton>
          )}
        </ButtonGroup>
      </Container>
    </Layout>
  );
};

const Layout = styled.div`
  max-width: 500px;
  width: 100%;
  margin: 4rem auto;
`;

const Container = styled.div`
  margin-top: 2rem;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
`;
const SubmitButton = styled(Button)`
  width: 100px;
`;

const Loading = styled(Icon)`
  width: 1.25rem;
  height: 1.25rem;
`;

const ErrorBox = styled.div`
  width: 100%;
  padding: 1rem;
  background-color: ${Color.Ac_Shadow};
  border-radius: 1rem;
  border: 2px solid ${Color.AC_Carrot};
  display: flex;
  flex-direction: column;
  white-space: normal;
  margin: 1rem 0;
`;
