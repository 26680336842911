import { Button, ButtonColor, ButtonSize } from "App/Atomics/Button";
import { Icon } from "App/Atomics/Icon";
import { usePlaylistNode } from "App/Store/PlaylistNodeStore";
import { Color } from "Constants/Color";
import { AllAddCart, AllPlay } from "Constants/Locale";
import { DESKTOP_MODE, WIDE_MODE, X_WIDE_MODE } from "Constants/Size";
import { usePushCartItems } from "GraphQL/Mutations/usePushCartItems";
import { PlaylistID } from "Models/PlaylistModel";
import styled from "styled-components";
import { Player } from "Utils/artistscardPlayer";
import { useCurationDetailSelector } from "../../../Store";

type Props = {
  readonly id: PlaylistID;
};

export const CurationInformation = ({ id }: Props) => {
  const playlist = usePlaylistNode(id);
  const { pushCartItems } = usePushCartItems();
  const isLoadedCurationDetail = useCurationDetailSelector(store => store.isLoadedCurationDetail);
  const trackList = playlist?.trackList.map(({ id }) => id);

  const onPlay = () => {
    if (!trackList?.length) {
      return;
    }
    Player.insertListToLast(trackList);
    Player.current(trackList[0]);
  };

  const onAddCartItems = async () => {
    try {
      await pushCartItems({
        variables: {
          trackIds: trackList?.map(({ raw }) => raw) ?? []
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout>
      <CenterBox>
        <Title>{playlist?.title}</Title>
        <Artist>{playlist?.desciprtion}</Artist>
        {isLoadedCurationDetail && (
          <CartButton color={ButtonColor.PrimaryNotFill} size={ButtonSize.Xsmall} onClick={onAddCartItems}>
            <ButtonText>
              <AllAddCart />
            </ButtonText>
          </CartButton>
        )}

        {!isLoadedCurationDetail && (
          <CartButton color={ButtonColor.Disable} size={ButtonSize.Xsmall} onClick={onAddCartItems}>
            <ButtonText>
              <Loading name="loading" />
            </ButtonText>
          </CartButton>
        )}

        {isLoadedCurationDetail ? (
          <CartButton color={ButtonColor.Primary} size={ButtonSize.Xsmall} onClick={onPlay}>
            <ButtonText>
              <AllPlay />
            </ButtonText>
          </CartButton>
        ) : (
          <CartButton color={ButtonColor.Disable} size={ButtonSize.Xsmall} onClick={onPlay}>
            <ButtonText>
              <Loading name="loading" />
            </ButtonText>
          </CartButton>
        )}
      </CenterBox>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${DESKTOP_MODE} {
    padding: 50px;
  }

  @media ${WIDE_MODE} {
    padding: 60px;
  }

  @media ${X_WIDE_MODE} {
    padding: 70px;
  }

  padding: 30px;

  position: relative;
`;

const CenterBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
`;

const Title = styled.h2`
  font-size: 3rem;
  margin-bottom: 1.25rem;
  white-space: normal;
  text-align: center;
`;

const Artist = styled.p`
  font-size: 1rem;
  color: ${Color.Ac_Gray1};
  margin-bottom: 20px;
`;

const CartButton = styled(Button)`
  height: 40px;
  border-radius: 1.5rem;
  border: none;
  margin-bottom: 1rem;
`;

const ButtonText = styled.span`
  font-size: 0.75rem;
  margin: 0 0.5rem;
  line-height: 1;
  font-weight: bold;
`;

const Loading = styled(Icon)`
  width: 1rem;
  height: 1rem;
`;
