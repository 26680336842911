import { ReactElement } from "react";
import styled from "styled-components";
import { Input } from "App/Atomics/Input";

import { LanguageCode } from "Constants/LangaugeCode";
import { useCurrentLanguage } from "Lib/localization";
import { useSearch } from "Lib/use-search-query";
import { SelectStyle } from "App/Pages/Admin/CechargePoint/Style";

const serviceOptionList = [
  { id: LanguageCode.Ko, name: "한국어" },
  { id: LanguageCode.En, name: "English" }
];

type Props = {
  close: () => void;
};

export const SidebarLanguageInfo = ({ close }: Props): ReactElement => {
  const currentLanguage = useCurrentLanguage();

  const { setQuery } = useSearch();

  const chnageLanguage = (languageCode: LanguageCode) => {
    setQuery("lang", languageCode);
    close();
  };

  return (
    <LanguageSelect
      optionList={serviceOptionList}
      placeholder="언어 선택"
      isSearchable={false}
      style={SelectStyle}
      defaultValue={serviceOptionList.find(({ id }) => id === currentLanguage)}
      onChange={info => info && chnageLanguage(info.id as LanguageCode)}
    />
  );
};

const LanguageSelect = styled(Input.TextSelect)`
  width: 100%;
  z-index: 3;
  margin-top: 1rem;
`;
