import { useState, useEffect } from "react";

export const useMatchMediaQuery = (mediaQuery: string) => {
  const mediaQueryList = window.matchMedia(mediaQuery);
  const [isMatched, setBeMatched] = useState(mediaQueryList.matches);
  const addMatchMediaQueryEventListener = () => {
    const handleMatchChange = (event: MediaQueryListEvent) => setBeMatched(event.matches);
    mediaQueryList.addListener(handleMatchChange);
    return () => {
      mediaQueryList.removeListener(handleMatchChange);
    };
  };
  useEffect(addMatchMediaQueryEventListener, [mediaQuery]);
  return isMatched;
};
