import { setCartCount } from "App/Common/setCartCount";
import { setCartItem } from "App/Common/setCartItem";
import { CartItemNodeStore } from "App/Store/CartItemNodeStore";
import { MAX_FIRST_COUNT } from "Constants/Count";
import { useLoadCart } from "GraphQL/Queries/useLoadCart";
import { useLoaded } from "Lib/use-loaded";
import { useEffect } from "react";

export const useCart = () => {
  const { data, loading } = useLoadCart({ first: MAX_FIRST_COUNT });
  const { isLoaded } = useLoaded(loading);
  useEffect(() => {
    CartItemNodeStore.clear();
    if (data) {
      const cart = data.cart;
      setCartCount(cart);
      setCartItem(cart);
    }
  }, [data]);
  return { isLoaded };
};
