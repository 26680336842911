import "./Styles";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "./Atomics/Toast";
import { PlayerBar } from "./Organisms/PlayerBar";
import { Pages } from "./Pages";
import { CookiesProvider } from "Lib/cookie";
import { ScrollToTop } from "Utils/scrollToTop";
import { GraphQLClientProvider } from "Utils/clients";
import { AccountManager } from "Utils/AccountManager";
import { Modals } from "./Modals";
import { SkeletonTheme } from "react-loading-skeleton";
import { Color } from "Constants/Color";
import { LocaltionManager } from "Utils/LocaltionManager";

export const App = (): JSX.Element => (
  <BrowserRouter>
    <CookiesProvider>
      <GraphQLClientProvider>
        <SkeletonTheme baseColor={Color.Ac_Charcoal} highlightColor={Color.Ac_Gray0}>
          <ScrollToTop>
            <AccountManager>
              <LocaltionManager>
                <ToastContainer limit={1} />
                <Pages />
                <PlayerBar />
                <Modals />
              </LocaltionManager>
            </AccountManager>
          </ScrollToTop>
        </SkeletonTheme>
      </GraphQLClientProvider>
    </CookiesProvider>
  </BrowserRouter>
);
