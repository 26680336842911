import styled from "styled-components";
import { Button, ButtonSize } from "App/Atomics/Button";
import { Icon } from "App/Atomics/Icon";
import { WIDE_MODE } from "Constants/Size";
import { Color } from "Constants/Color";
import { useTrackInfo } from "Utils/useTrackInfo";
import { PlaylistID } from "Models/PlaylistModel";
import { usePlaylistNode } from "App/Store/PlaylistNodeStore";
import { FavoriteTrack } from "Constants/Locale";

type Props = {
  readonly id: PlaylistID;
};

export const MyLikeInformation = ({ id }: Props) => {
  const { set, isInfo } = useTrackInfo();
  const playlist = usePlaylistNode(id);

  return (
    <Layout>
      <TitleContainer>
        <Title>
          <FavoriteTrack /> <Count>{playlist?.count !== undefined && `(${playlist?.count})`}</Count>
        </Title>
      </TitleContainer>
      <ControlContainer>
        <RightContainer>
          <ReadMoreButtonContainer>
            <ReadMoreLeftButton isSelected={!isInfo} size={ButtonSize.Small} onClick={() => set(false)}>
              <DetailIcon name="basic-track" />
            </ReadMoreLeftButton>
            <ReadMoreRightButton isSelected={isInfo} size={ButtonSize.Small} onClick={() => set(true)}>
              <DetailIcon name="detail-track" />
            </ReadMoreRightButton>
          </ReadMoreButtonContainer>
        </RightContainer>
      </ControlContainer>
    </Layout>
  );
};

const Layout = styled.div`
  padding: 1.5rem;
  padding-left: 1.8rem;
  display: flex;
  justify-content: space-between;
`;

const TitleContainer = styled.div`
  padding: 0.625rem 0;
  white-space: normal;
`;

const Title = styled.h1`
  font-size: 1.25rem;
  margin-right: 0.3rem;
`;

const Count = styled.span`
  font-size: 1rem;
  color: ${Color.Ac_Gray0};
`;

const ControlContainer = styled.div`
  display: flex;

  @media ${WIDE_MODE} {
    flex-direction: row;
  }

  flex-direction: column;

  padding: 0.625rem 0;
  align-items: center;
`;

const ButtonContainer = styled.div`
  margin: 0.31rem;
  display: flex;
  align-items: center;

  @media ${WIDE_MODE} {
    margin-top: 0;
  }

  margin-top: 3rem;
`;

const SimpeButton = styled(Button)`
  height: 30px;
  margin: 0.3rem;
  border-radius: 2rem;
  color: ${Color.Ac_Gray1}!important;
  &:hover {
    border: 2px solid ${Color.Ac_White}!important;
    color: ${Color.Ac_White}!important;

    path {
      fill: ${Color.Ac_White};
    }
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;

  @media ${WIDE_MODE} {
    justify-content: flex-end;
    width: min-content;
  }
  width: 100%;
  justify-content: space-between;
`;

const ReadMoreButtonContainer = styled(ButtonContainer)`
  @media ${WIDE_MODE} {
    display: flex;
  }
  display: none;
`;

const ReadMoreButton = styled(SimpeButton)<{ isSelected: boolean }>`
  background-color: ${props => props.isSelected && `${Color.Ac_Gray0}`}!important;

  margin: 0;
`;

const ReadMoreLeftButton = styled(ReadMoreButton)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-width: 1px;

  &:hover {
    border-right: 1px solid ${Color.Ac_White}!important;
  }
`;

const DetailIcon = styled(Icon)`
  width: 1rem;
  height: 1rem;
`;

const ReadMoreRightButton = styled(ReadMoreButton)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left-width: 1px;

  @media ${WIDE_MODE} {
    display: flex;
  }

  display: none;

  &:hover {
    border-left: 1px solid ${Color.Ac_White} !important;
  }
`;
