import { ReactNode } from "react";
import ReactModal from "react-modal";
import styled, { css, keyframes } from "styled-components";

ReactModal.setAppElement("#Root");

type Props = ReactModal.Props & {
  children: ReactNode;
  animation?: boolean;
};

export const Modal = ({ children, animation = true, ...props }: Props) => {
  return (
    <ReactModal {...props} style={reactModalStyle}>
      <ModalWrapper animation={animation}>
        <ModalContent>{children}</ModalContent>
      </ModalWrapper>
    </ReactModal>
  );
};

const slowOpacityWithScale = keyframes`
  0% {
    opacity: 0;
    transform: scale3d(.93,.93,.93);
  }
  100% {
    opacity: 1;
    transform: scale3d(1,1,1);
  }
`;

const reactModalStyle = {
  overlay: {
    position: "fixed",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    zIndex: 5000,
    overflow: "auto",
    backgroundColor: "rgba(0,0,0,0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start"
  },
  content: {
    display: "flex",
    flexGrow: 0,
    justifyContent: "center",
    outline: "none",
    height: "100%",
    width: "100%",
    pointerEvents: "none"
  }
} as const;

const ModalWrapper = styled.div<{ animation: boolean }>`
  position: relative;

  display: flex !important;
  justify-content: center !important;
  align-items: flex-start !important;
  width: 100% !important;
  height: 100% !important;
  animation: ${props =>
    props.animation
      ? css`
          ${slowOpacityWithScale} .15s forwards
        `
      : ``};
  will-change: opacity;
`;

const ModalContent = styled.div`
  position: relative !important;
  margin-top: auto;
  margin-bottom: auto;
  outline: none;
  flex-grow: 0 !important;
  display: flex !important;
  justify-content: center !important;
  width: 100% !important;
`;

ReactModal.defaultStyles = {};
