import { setUserAccount } from "App/Common/setUserAccount";
import { ModalAction, ModalStore, ModalType } from "App/Store/ModalStore";
import { AccountEmailAlreadyExist, ErrorSimple } from "Constants/Locale";
import { useSingUp } from "GraphQL/Mutations/useSingUp";
import { useLazeLoadUserInfo } from "GraphQL/Queries/useLazeLoadUserInfo";
import { Toast } from "Lib/toast";
import { LOADED, useLoading } from "Lib/use-loading";
import { useEffect } from "react";
import { useAsyncEffect } from "use-async-effekt";
import { sha256 } from "Utils/hash";
import { useAuth } from "Utils/useAuth";
import { useValidation } from "./useValidation";

const ERROR_EXIST_EMAIL = "Exist email";

export const useSignUp = () => {
  const { singUp, data, error, loading } = useSingUp();
  const [validation] = useValidation();
  const replaceUserVerify = ({ accessToken, refreshToken }: { accessToken: string; refreshToken: string }) =>
    ModalStore.dispatch(ModalAction.replace(ModalType.USER_VERIFY, { accessToken, refreshToken }));
  const { loadUserInfo, data: userInfoData } = useLazeLoadUserInfo();

  const tokenLoading = useLoading(LOADED);
  const userInfoLoading = useLoading(LOADED);

  const signUp = async (
    name: string,
    email: string,
    pw: string,
    isTermsofService: boolean,
    isPrivacyPolicy: boolean,
    isAge: boolean
  ): Promise<void> => {
    if (loading) return;
    try {
      if (
        !validation.name(name) ||
        !validation.email(email) ||
        !validation.password(pw) ||
        !validation.termsOfService(isTermsofService) ||
        !validation.privacyPolicy(isPrivacyPolicy) ||
        !validation.age(isAge)
      )
        return;
      tokenLoading.setLoading();
      await singUp({ variables: { email, name, password: sha256(pw) } });
    } catch (err) {
      console.log({ err });
      tokenLoading.setLoaded();
    }
  };

  const auth = useAuth();

  useAsyncEffect(async () => {
    if (data) {
      const token = data.singUp.token!;
      auth.set(token);

      try {
        userInfoLoading.setLoading();
        tokenLoading.setLoaded();
        await loadUserInfo();
      } catch (error) {
        console.log(error);
        userInfoLoading.setLoaded();
      }
    }
  }, [data]);

  useEffect(() => {
    if (userInfoData?.userInfo) {
      try {
        const user = userInfoData.userInfo;
        setUserAccount(user);
        if (auth.token) {
          replaceUserVerify({
            accessToken: auth.token.access_token,
            refreshToken: auth.token.refresh_token
          });
        }
        userInfoLoading.setLoaded();
      } catch (err) {
        console.log(err);
        userInfoLoading.setLoaded();
      }
    }
  }, [userInfoData]);

  useEffect(() => {
    if (error) {
      if (error?.graphQLErrors[0].message === ERROR_EXIST_EMAIL) {
        Toast.error(AccountEmailAlreadyExist.message());
      } else {
        Toast.error(ErrorSimple.message());
      }
    }
  }, [error]);

  return { signUp, isLoaded: tokenLoading.isLoaded && userInfoLoading.isLoaded };
};
