import { configs } from "Config";
import { ApolloClient, InMemoryCache, createHttpLink, setContext, gql } from "Lib/apollo-client";

const VERIFY_USER = gql`
  mutation VERIFY_USER {
    verifyUser
  }
`;

type Result = {
  verifyUser: boolean;
};

export const verifyUser = async (token: string): Promise<boolean> => {
  const httpLink = createHttpLink({
    uri: configs.urls.service
  });
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`
      }
    };
  });
  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  });
  const { data } = await client.mutate<Result>({ mutation: VERIFY_USER });
  return data?.verifyUser ?? false;
};
