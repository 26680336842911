import { Button, ButtonColor, ButtonSize } from "App/Atomics/Button";
import { LocaleWidthTimePicker } from "App/Atomics/LocaleWithTimePicker";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { Color } from "Constants/Color";
import { upsertMetadata } from "GraphQL/Queries/upsertMetadata";
import { useInvalidateCacheAll } from "GraphQL/Queries/useInvalidateCacheAll";
import { Toast } from "Lib/toast";
import { useState } from "react";

import styled from "styled-components";

import { Style } from "../Style";

export const Form = () => {
  const [admissionTime, setAdmissionTime] = useState<Date | null>(null);
  const { loadInvalidateCacheAll, loading } = useInvalidateCacheAll();
  const isDisable = admissionTime === null;
  const tryToUpsertMetadata = async () => {
    if (!admissionTime) {
      return;
    }
    const { data, error } = await upsertMetadata({ licenseDate: admissionTime });

    if (error) {
      Toast.error("최신 데이터를 가져오는중 오류가 발생했습니다.");
      return;
    }

    if (data.metadata.length) {
      Toast.success(data.metadata.length + "개가 검색되었습니다.");
    }
    setAdmissionTime(null);
  };

  const tryToInvalidateCacheAll = async () => {
    try {
      await loadInvalidateCacheAll();
      Toast.success("캐시 삭제했습니다");
    } catch (error) {
      Toast.success("캐시 삭제중 오류가 발생했습니다.");
    }
  };

  return (
    <Layout>
      <Typography weight={TypoWeight.Bold} color={Color.Ac_White} size={TypoSize.F24}>
        최신 데이터를 가져오기위해 아래 정보를 입력해주세요.
      </Typography>
      <Container>
        <Style.Row>
          <Style.Caption>날짜*</Style.Caption>
          <ReactDatePickerBox>
            <LocaleWidthTimePicker
              placeholder="최신 데이터 업로드한 날짜를 입력해주세요"
              value={admissionTime}
              selected={admissionTime ?? undefined}
              onChange={date => setAdmissionTime(date)}
              onBlur={date => setAdmissionTime(date)}
              showTimeSelect
              locale="ko"
              timeIntervals={15}
            />
          </ReactDatePickerBox>
        </Style.Row>
        <ButtonGroup>
          <SubmitButton size={ButtonSize.Small} color={ButtonColor.OutRed} disabled={loading} onClick={tryToInvalidateCacheAll}>
            캐시 삭제
          </SubmitButton>
          <SubmitButton
            size={ButtonSize.Small}
            color={isDisable ? ButtonColor.Disable : ButtonColor.Primary}
            disabled={isDisable}
            onClick={tryToUpsertMetadata}
          >
            가져오기
          </SubmitButton>
        </ButtonGroup>
      </Container>
    </Layout>
  );
};

const Layout = styled.div`
  max-width: 500px;
  width: 100%;
  margin: 4rem auto;
`;

const Container = styled.div`
  margin-top: 2rem;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
`;
const SubmitButton = styled(Button)`
  width: 100px;
  &:first-child {
    margin-right: 1rem;
  }
`;

const ReactDatePickerBox = styled.div`
  input {
    position: relative;
    width: 100%;
    height: 44px;
    margin-top: 4px;
    background-color: ${Color.Ac_Shadow}!important;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.5);
    color: ${Color.Ac_White}!important;
    border: none;
    border-radius: 8px;
    line-height: 22px;
    padding: 1rem;
    transition: box-shadow 0.2s ease;
  }

  .react-datepicker__input-container input::placeholder {
    color: ${Color.GRAY_5};
  }
`;
