import { Icon } from "App/Atomics/Icon";
import { Typography, TypoWeight } from "App/Atomics/Typography";
import { AdminTemplate } from "App/Templates/AdminTemplate";
import { Color } from "Constants/Color";
import styled from "styled-components";
import { Form } from "./Form";
import { useUserList } from "./hooks/useUserList";
import { NavigationTab } from "./NavigationTab";

export const User = () => {
  const { isLoaded } = useUserList();
  return (
    <AdminTemplate>
      <Layout>
        {isLoaded && (
          <>
            <NavigationTab />
            <Form />
          </>
        )}
        {!isLoaded && (
          <LoadingContainer>
            <Loading name="loading" />
            <Typography weight={TypoWeight.Bold} color={Color.AC_Carrot}>
              로딩중 입니다 잠시만 기다려 주세요!
            </Typography>
          </LoadingContainer>
        )}
      </Layout>
    </AdminTemplate>
  );
};

const Layout = styled.div`
  width: 100%;
  height: 100%;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;

const Loading = styled(Icon)`
  width: 5rem;
  height: 5rem;
  margin-bottom: 2rem;
`;
