import { Button, ButtonColor } from "App/Atomics/Button";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { ModalAction, ModalProps, ModalStore } from "App/Store/ModalStore";
import { Color } from "Constants/Color";
import { CommonConfirm } from "Constants/Locale";
import { HOME } from "Constants/Routes";
import { Modal } from "Lib/modal";
import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

type Props = {
  readonly isOpen: boolean;
  readonly props: ModalProps;
};

export const ConfirmModal = ({ props, isOpen }: Props): ReactElement => {
  const navigate = useNavigate();
  const title = props.title as string;
  const onRequestClose = () => {
    ModalStore.dispatch(ModalAction.closeAll());
    navigate(HOME);
  };
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Layout>
        <Container>
          <Title size={TypoSize.F20} weight={TypoWeight.Bold}>
            {title}
          </Title>
          <ButtonGroup>
            <StyledButton color={ButtonColor.PrimaryNotFill} onClick={onRequestClose}>
              <CommonConfirm />
            </StyledButton>
          </ButtonGroup>
        </Container>
      </Layout>
    </Modal>
  );
};

const Layout = styled.div`
  min-width: 400px;
  padding: 40px 20px 20px;
  border-radius: 5px;
  background-color: ${Color.Ac_White};
  pointer-events: auto;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Title = styled(Typography)`
  margin-bottom: 20px;
`;
const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledButton = styled(Button)`
  width: 100%;
  font-weight: bold;
  & + & {
    margin-left: 8px;
  }
`;
