import { Icon } from "App/Atomics/Icon";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { TrackContentLoader } from "App/Features/ContentLoaders/TrackContentLoader";
import { TrackItem } from "App/Features/Items/TrackItem";
import { useTrackActivity } from "App/hooks/useTrackActivity";
import { NotFoundItem } from "Constants/Locale";
import { TrackID } from "Models/TrackModel";
import styled from "styled-components";

interface Props {
  readonly trackList: TrackID[];
  readonly isLoadedTrack?: boolean;
  readonly isDetail?: boolean;
  readonly isReloadPlaylist?: boolean;
}

export const TrackList = ({ trackList, isLoadedTrack = true, isDetail = true, isReloadPlaylist = true }: Props) => {
  const { isLoaded: isLoadedTrackActivity } = useTrackActivity({
    trackList: trackList,
    isLoadedTrack: isLoadedTrack
  });

  const isLoaded = isLoadedTrack && isLoadedTrackActivity;

  return (
    <Layout>
      <Container>
        {!isLoaded && (
          <>
            {[...Array(20)].map((_, key) => (
              <TrackContentLoader key={key} isDetail={isDetail} />
            ))}
          </>
        )}
        {isLoaded && (
          <>
            {trackList.map(id => (
              <TrackItem id={id} key={id.key} isDetail={isDetail} isReloadPlaylist={isReloadPlaylist} />
            ))}
          </>
        )}
        {isLoaded && !trackList.length && (
          <NotFoundContainer>
            <NotFoundIcon name="search" />
            <NotFound weight={TypoWeight.Bold} size={TypoSize.F18}>
              <NotFoundItem />
            </NotFound>
          </NotFoundContainer>
        )}
      </Container>
    </Layout>
  );
};

const Layout = styled.div`
  position: relative;

  width: 100%;
`;

const NotFoundContainer = styled.div`
  width: 100%;
  margin: 10rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const NotFound = styled(Typography)`
  margin-top: 1rem;
`;

const NotFoundIcon = styled(Icon)`
  width: 2.5em;
  height: 2.5rem;
`;

const Container = styled.div``;
