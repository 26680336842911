import { Icon } from "App/Atomics/Icon";
import { Input } from "App/Atomics/Input";
import { Color } from "Constants/Color";
import { DESKTOP_MODE } from "Constants/Size";
import { useToggle } from "Lib/use-toggle";
import { useEffect, useRef } from "react";
import styled from "styled-components";
import { withPreventDefault } from "Utils/withPreventDefault";

interface Props {
  onSearch: (keyword: string) => void;
  defaultValue?: string;
  placeholder?: string;
}

export const SimpleSearchForm = ({ onSearch, defaultValue, placeholder = "Search.." }: Props) => {
  const ref = useRef<HTMLInputElement>(null);
  const focus = useToggle();

  const onSubmit = withPreventDefault(() => {
    const keyword = ref.current!.value.trim();
    onSearch(keyword);
  });

  useEffect(() => {
    if (!defaultValue) {
      ref.current!.value = "";
    }
  }, [defaultValue]);

  return (
    <Layout isFocused={focus.isToggled}>
      <Form onSubmit={onSubmit}>
        <SearchBar>
          <SearchIocn name="search" disabled={!focus.isToggled} />
          <SearchInput ref={ref} placeholder={placeholder} onFocus={focus.on} onBlur={focus.off} defaultValue={defaultValue} />
        </SearchBar>
      </Form>
    </Layout>
  );
};

const Layout = styled.div<{ isFocused?: boolean }>`
  border-radius: 0.3rem;
  width: 9rem;
  font-size: 0.8rem;
  border-bottom: 1px solid ${Color.Ac_Gray3};
  padding: 0 4px;
  margin-bottom: 4px;

  @media ${DESKTOP_MODE} {
    font-size: 1rem;
    width: 15rem;
    border: ${props => (!props.isFocused ? `2px solid ${Color.Ac_Gray5}` : `2px solid ${Color.Ac_LightPurple}`)};
    transition: all 0.3s;
    padding: 0 8px;
    margin-bottom: 4px;
  }
`;

const Form = styled.form`
  position: relative;
  display: flex;
  align-items: center;
`;

const SearchBar = styled.div`
  position: relative;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: auto min-content;
`;

const SearchInput = styled(Input.Text)`
  width: 100%;
  border: none;
  background: none;
  outline: none;
  padding: 7px 4px 6px 22px;
  color: ${Color.Ac_White}!important;
`;

const SearchIocn = styled(Icon)`
  position: absolute;
  width: 1em;
  height: 1em;
  fill: ${Color.Ac_Gray3} !important;
  transition: fill 0.3s;
`;
