/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-labels */

export const createTokenList = (source: string): readonly Token[] => {
  const tokenList: Token[] = [];

  let cursor = 0;

  outer: while (cursor < source.length) {
    const start = cursor;

    inner: while (true) {
      if (cursor === source.length) {
        const end = cursor;
        tokenList.push(new TextToken(source.slice(start, end)));
        break outer;
      }

      const current = source[cursor];
      switch (current) {
        case SymbolKind.LeftParens:
        case SymbolKind.RightParens:
        case SymbolKind.LeftBrace:
        case SymbolKind.RightBrace:
        case SymbolKind.LeftBracket:
        case SymbolKind.RightBracket:
        case SymbolKind.SingleQuote:
        case SymbolKind.DoubleQuote:
        case SymbolKind.Comma:
        case SymbolKind.Equal:
        case SymbolKind.Colon:
        case SymbolKind.Slash:
        case SymbolKind.Dollar:
        case SymbolKind.Whitespace:
        case SymbolKind.Linebreak: {
          const end = cursor;
          if (start !== end) {
            tokenList.push(new TextToken(source.slice(start, end)));
          }
          tokenList.push(new SymbolToken(current));
          cursor += 1;
          break inner;
        }
        default: {
          cursor += 1;
        }
      }
    }
  }

  return tokenList;
};

export type Token = TextToken | SymbolToken;

export class TextToken {
  constructor(readonly text: string) {}
}

export const isTextToken = (token: Token): token is TextToken => token instanceof TextToken;

export class SymbolToken {
  constructor(readonly kind: SymbolKind) {}
}

export const isSymbolToken = (token: Token): token is SymbolToken => token instanceof SymbolToken;

export enum SymbolKind {
  LeftParens = '(',
  RightParens = ')',
  LeftBrace = '{',
  RightBrace = '}',
  LeftBracket = '<',
  RightBracket = '>',
  SingleQuote = "'",
  DoubleQuote = '"',
  Comma = ',',
  Equal = '=',
  Colon = ':',
  Slash = '/',
  Dollar = '$',
  Whitespace = ' ',
  Linebreak = '\n',
}
