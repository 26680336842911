import { Artist, ArtistWhereInput } from "GraphQL/Schema";
import { gql, useQuery } from "Lib/apollo-client";

const LOAD_ARTIST = gql`
  query LOAD_ARTIST($where: ArtistWhereInput) {
    artist(where: $where, group: true) {
      artist_id
      name
      metadata_artist_relation(
        where: {
          metadata__some: { metadata_company_relation__some: { company__some: { company_id__in: [44021, 902] } } }
          role__some: { type_role_class: "instrument" }
        }
      ) {
        role {
          role_id
          name
        }
      }
    }
  }
`;

type Result = {
  readonly artist: Artist[];
};

type Args = {
  readonly where?: ArtistWhereInput;
  readonly first?: number;
};

export const useLoadArtist = ({ first, where }: Args) => {
  const { data, loading } = useQuery<Result, Args>(LOAD_ARTIST, {
    variables: {
      where,
      first
    }
  });

  return { loading, data };
};
