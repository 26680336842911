import { Info } from "App/Atomics/AutoComplete/TextAutoComplete";
import { Button, ButtonColor, ButtonSize } from "App/Atomics/Button";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { useGradeNode } from "App/Store/GradeNodeStore";
import { useProductPriceNode, useProductPriceNodeStore } from "App/Store/ProductPriceNodeStore";
import { Color } from "Constants/Color";
import { ADMIN_GRADE } from "Constants/Routes";
import { useUpdateGradePrice } from "GraphQL/Mutations/useUpdateGradePrice";
import { ProductPriceType } from "GraphQL/Schema";
import { Toast } from "Lib/toast";
import { GradeID } from "Models/GradeModel";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { SelectStyle, Style } from "../Style";

export const Form = () => {
  const { id } = useParams();
  const grade = useGradeNode(new GradeID(+id!));
  const productPrice = useProductPriceNode(grade?.productPrice);

  const productPriceList = useProductPriceNodeStore(store =>
    store
      .values()
      .filter(({ type }) => type === ProductPriceType.Track)
      .map(store => ({ id: store.id.raw.toString(), name: store.title! }))
  ) as Info[];

  const [productPriceInfo, setProductPriceInfo] = useState<Info>({
    id: productPrice?.id.raw.toString() ?? "",
    name: productPrice?.title ?? "-"
  });
  const { updateGradePrice, loading } = useUpdateGradePrice();
  const navigate = useNavigate();
  const tryToUpdateGradePrice = async () => {
    await updateGradePrice({
      variables: {
        gradeId: +id!,
        productPriceId: +productPriceInfo.id!
      }
    });
    Toast.success("수정했습니다");
    navigate(ADMIN_GRADE);
  };

  return (
    <Layout>
      <Typography weight={TypoWeight.Bold} color={Color.Ac_White} size={TypoSize.F24}>
        등급 가격 정보를 수정하기 위해 아래 정보를 선택해주세요.
      </Typography>
      <Container>
        <Style.Row>
          <Style.Caption>가격정보*</Style.Caption>
          <Style.TextSelect
            optionList={productPriceList}
            placeholder="가격정보 선택"
            isSearchable={false}
            style={SelectStyle}
            defaultValue={productPriceList.find(({ id }) => id === productPriceInfo.id)}
            onChange={info => info && setProductPriceInfo(info)}
          />
        </Style.Row>

        <ButtonGroup>
          <SubmitButton
            size={ButtonSize.Small}
            color={loading ? ButtonColor.Disable : ButtonColor.Primary}
            disabled={loading}
            onClick={tryToUpdateGradePrice}
          >
            수정
          </SubmitButton>
        </ButtonGroup>
      </Container>
    </Layout>
  );
};

const Layout = styled.div`
  max-width: 500px;
  width: 100%;
  margin: 4rem auto;
`;

const Container = styled.div`
  margin-top: 2rem;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
`;
const SubmitButton = styled(Button)`
  width: 100px;
`;
