import { Button, ButtonColor } from "App/Atomics/Button";
import { Icon } from "App/Atomics/Icon";
import { setUserWallet } from "App/Common/setUserWallet";
import { BUY_CANCEL_MESSAGE } from "App/Pages/BuyCancelRedirect";
import { BUY_FAIL_MESSAGE } from "App/Pages/BuyFailRedirect";
import { BUY_SUCCESS_MESSAGE } from "App/Pages/BuySuccessRedirect";
import { useAccountSelector } from "App/Store/AccountStore";
import { ModalAction, ModalStore, ModalType } from "App/Store/ModalStore";
import { ChargingPoint, ProductAmount, PurchaseErrorMinAmount, TryToAgain } from "Constants/Locale";
import { BUY_POINT_ORDER } from "Constants/Routes";
import { useLazyLoadUserWallet } from "GraphQL/Queries/useLazyLoadUserWallet";
import { Toast } from "Lib/toast";
import { LOADED, useLoading } from "Lib/use-loading";
import { ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { foramtNumberToPriceFormat } from "Utils/format";
import { useAuth } from "Utils/useAuth";
import { usePurchasePoint } from "../../hooks/usePurchasePoint";
import { useBuyCheckSelector } from "../../Store";
import { BuyCheckStyle } from "../../Style";
import { PriceGroup } from "./PriceGroup";

export const PriceInfo = (): ReactElement => {
  const { agreeTerm, amount, isGlobal } = useBuyCheckSelector(store => store.current);
  const { purchasePoint } = usePurchasePoint();
  const loading = useLoading(LOADED);
  const [searchUrl, setSearchUrl] = useState<string>("");
  const [popup, setPopup] = useState<Window | null>(null);
  const MINIMUM_PAYMENT_AMOUNT = 1000;
  const { loadUserWallet, data } = useLazyLoadUserWallet();
  const isVerification = useAccountSelector(store => store.current.isVerification);
  const navigate = useNavigate();
  const { token } = useAuth();
  const onPurchase = async () => {
    if (!token) {
      Toast.error(TryToAgain.message());
      return;
    }

    if (isGlobal) {
      Toast.warning("해외결제 준비중 입니다.");
      return;
    }
    if (!isVerification) {
      const accessToken = token.access_token;
      const refreshToken = token.refresh_token;
      ModalStore.dispatch(ModalAction.replace(ModalType.USER_VERIFY, { accessToken, refreshToken }));
      return;
    }

    if (amount < MINIMUM_PAYMENT_AMOUNT) {
      Toast.error(PurchaseErrorMinAmount.message());
      return;
    }
    const windowReference = window.open(
      "about:blank",
      "purchase",
      "width=500,height=700,resizable=no,menubar=no,location=no,titlebar=no,status=no,toolbar=no"
    );
    try {
      loading.setLoading();
      const result = await purchasePoint(amount);
      loading.setLoaded();
      if (result) {
        const url = result.online_url;
        if (windowReference) {
          windowReference.location = url as any;
          setPopup(windowReference);
        }
      }
    } catch (err) {
      console.log(err);
      windowReference?.close();
    }
  };

  const onReceiveMessage = async (e: MessageEvent, popup: Window | null) => {
    if (popup) {
      const message = e.data;
      console.log(message);

      if (message.service === BUY_FAIL_MESSAGE) {
        Toast.primary("결제가 실패했습니다.");
        popup.close();
        return;
      }
      if (message.service === BUY_CANCEL_MESSAGE) {
        Toast.primary("결제가 취소했습니다.");
        popup.close();
        return;
      }
      if (message.service === BUY_SUCCESS_MESSAGE) {
        Toast.primary("결제가 완료되었습니다.");
        const searchUrl = message.search;
        setSearchUrl(searchUrl);
        try {
          await loadUserWallet();
          popup.close();
        } catch (error) {
          Toast.success("결제가 완료되었습니다");
          popup.close();
          window.location.reload();
        }
      }
    }
  };

  useEffect(() => {
    if (data) {
      console.log(data);
      setUserWallet(data.userWallet);
      navigate(`${BUY_POINT_ORDER}${searchUrl}`);
    }
  }, [data]);

  useEffect(() => {
    window.addEventListener("message", e => onReceiveMessage(e, popup));
    return () => {
      window.removeEventListener("message", e => onReceiveMessage(e, popup));
    };
  }, [popup]);

  return (
    <BuyCheckStyle.Layout>
      <Container>
        <PriceGroup name={ChargingPoint.message()} price={amount} />
        <PriceGroup name={ProductAmount.message()} price={amount} isTotal />
      </Container>
      <PayButton
        disabled={!agreeTerm || loading.isLoading}
        color={!agreeTerm ? ButtonColor.Disable : ButtonColor.Primary}
        onClick={onPurchase}
      >
        {loading.isLoaded ? `총 ${foramtNumberToPriceFormat(amount)} 결제하기` : <Loading name="loading" />}
      </PayButton>
    </BuyCheckStyle.Layout>
  );
};

const Container = styled.div``;
const PayButton = styled(Button)`
  margin-top: 40px;
  width: 100%;
  height: 54px;
  font-size: 14px;
  font-weight: bold;
`;

const Loading = styled(Icon)`
  width: 1rem;
  height: 1rem;
`;
