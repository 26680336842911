import { Hash } from ".";
import { createDuck } from "./store";

export function createSelectionActions() {
  const createInitialState = () => ({ selectionSet: new Set<Hash>() });

  return createDuck({
    namespace: "Selection",
    createInitialState,
    reducers: {
      selectList(state, hashList: readonly Hash[]) {
        for (const hash of hashList) {
          state.selectionSet.add(hash);
        }
      },
      unselectList(state, hashList: readonly Hash[]) {
        for (const hash of hashList) {
          state.selectionSet.delete(hash);
        }
      }
    }
  });
}
