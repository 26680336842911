import { NumericID } from "Utils/id";
import { AccountID } from "./AccountID";
import { AccountTierModel, NormalAccountTierModel } from "./AccountTierModel";
import { AccountType } from "./AccountType";
import { immerable } from "immer";
import { DEFAULT_LANGUAGE_CODE, LocalizationModel, LocalizationPayload } from "Models/LocalizationModel";

export abstract class AccountModel {
  static withType(
    payload: (NormalAccountPayload & { readonly type: AccountType.Normal }) | (AdminAccountPayload & { readonly type: AccountType.Admin })
  ): AccountModel {
    switch (payload.type) {
      case AccountType.Admin: {
        return AdminAccountModel.from(payload as AdminAccountPayload);
      }
      default:
        return NormalAccountModel.from(payload as NormalAccountPayload);
    }
  }

  readonly [immerable] = true;

  protected constructor(
    readonly id: AccountID,
    readonly email: string | null,
    readonly displayName: LocalizationModel,
    readonly tier: AccountTierModel,
    readonly isVerification: boolean,
    readonly company?: string
  ) {}
}

export type AdminAccountPayload = {
  readonly id: number;
  readonly email: string;
  readonly displayName: LocalizationPayload;
  readonly tier: string;
  readonly isVerification: boolean;
  readonly company?: string;
};

export class AdminAccountModel extends AccountModel {
  static from({ id, displayName, email, tier, company, isVerification }: AdminAccountPayload): AdminAccountModel {
    return new AdminAccountModel(
      new AccountID(id),
      LocalizationModel.from(displayName),
      email,
      AccountTierModel.from(tier),
      isVerification,
      company
    );
  }

  static dummy(id: number): AdminAccountModel {
    return new AdminAccountModel(
      new AccountID(id),
      LocalizationModel.dummy(`displayName-${id}`),
      "dummy@example.com",
      new NormalAccountTierModel(),
      false,
      "company"
    );
  }

  constructor(
    id: AccountID,
    displayName: LocalizationModel,
    readonly email: string,
    tier: AccountTierModel,
    isVerification: boolean,
    company?: string
  ) {
    super(id, email, displayName, tier, isVerification, company);
  }
}

export const isAdminAccountModel = (account: AccountModel): account is AdminAccountModel => account instanceof AdminAccountModel;

export class GuestAccountModel extends AccountModel {
  constructor(displayName: LocalizationModel) {
    super(GUEST_ACCOUNT_ID, "guest@artistscard.com", displayName, new NormalAccountTierModel(), false, "company");
  }
}

export const isGuestAccountModel = (account: AccountModel): account is GuestAccountModel => account instanceof GuestAccountModel;

export const GUEST_ACCOUNT_ID = new AccountID(-1);

export const GUEST_ACCOUNT = new GuestAccountModel(LocalizationModel.dummy("Guest"));

export type NormalAccountPayload = {
  readonly id: number;
  readonly email: string;
  readonly displayName: LocalizationPayload;
  readonly isVerification: boolean;
  readonly tier?: string;
  readonly company?: string;
};

export class NormalAccountModel extends AccountModel {
  static from({ id, displayName, email, tier, company, isVerification }: NormalAccountPayload): NormalAccountModel {
    return new NormalAccountModel(
      new NumericID("account", id),
      LocalizationModel.from(displayName),
      email,
      AccountTierModel.from(tier),
      isVerification,
      company
    );
  }

  static dummy(id: number): NormalAccountModel {
    return NormalAccountModel.from({
      id,
      displayName: [{ languageCode: DEFAULT_LANGUAGE_CODE, value: `displayName-${id}` }],
      email: `dummy${id}@example.com`,
      isVerification: false
    });
  }

  constructor(
    id: NumericID,
    displayName: LocalizationModel,
    readonly email: string,
    tier: AccountTierModel,
    isVerification: boolean,
    company?: string
  ) {
    super(id, email, displayName, tier, isVerification, company);
  }
}

export const isNormalAccountModel = (account: AccountModel): account is NormalAccountModel => account instanceof NormalAccountModel;
