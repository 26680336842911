import { GenreNodeStore } from "App/Store/GenreNodeStore";
import { Genre } from "GraphQL/Schema";
import { GenreKind } from "Models/GenreModel/GenreKind";
import { GenreID, GenreModel, MetdataKind } from "Models/GenreModel/GenreModel";

export const setGenre = (genres: Genre[], metdataKind: MetdataKind) => {
  for (const genre of genres) {
    const { genre_id, genre_title, genre_self_relation_child, weight } = genre;
    if (!genre_self_relation_child) {
      return;
    }
    const parentGenre = genre_self_relation_child![0]?.genre_self_relation_parent[0];
    if (parentGenre) {
      const parentGenreID = new GenreID(parentGenre?.genre_id!);

      if (GenreNodeStore.get(parentGenreID)) {
        const childGenreId = new GenreID(genre_id);

        if (GenreNodeStore.get(childGenreId)) {
          GenreNodeStore.update(childGenreId, node => {
            node.metdataKind = [...new Set([...node.metdataKind, metdataKind])] as MetdataKind[];
          });
        } else {
          const childGenreM = GenreModel.form({
            id: childGenreId.raw,
            title:
              genre_title?.map(({ language_code, value }) => ({
                languageCode: language_code!,
                value: value!
              })) ?? [],
            weight: weight,
            kind: genre.type_kind! as GenreKind,
            metdataKind: [metdataKind],
            childGenreIds: []
          });
          GenreNodeStore.set(new GenreID(genre_id), childGenreM);
          GenreNodeStore.update(parentGenreID, node => {
            node.childGenreIds = [...node.childGenreIds, new GenreID(genre_id)];
          });
        }

        GenreNodeStore.update(parentGenreID, node => {
          node.metdataKind = [...new Set([...node.metdataKind, metdataKind])] as MetdataKind[];
        });
      } else {
        const parentGenre = genre_self_relation_child[0]?.genre_self_relation_parent[0];
        if (parentGenre) {
          const genreM = GenreModel.form({
            id: parentGenreID.raw,
            title:
              parentGenre.genre_title?.map(({ language_code, value }) => ({
                languageCode: language_code!,
                value: value!
              })) ?? [],
            weight: parentGenre.weight,
            kind: parentGenre.type_kind! as GenreKind,
            metdataKind: [metdataKind],
            childGenreIds: []
          });
          GenreNodeStore.set(parentGenreID, genreM);
        }

        const childGenreId = new GenreID(genre_id);
        if (GenreNodeStore.get(childGenreId)) {
          GenreNodeStore.update(childGenreId, node => {
            node.metdataKind = [...new Set([...node.metdataKind, metdataKind])] as MetdataKind[];
          });
        } else {
          const childGenreM = GenreModel.form({
            id: childGenreId.raw,
            title:
              genre_title?.map(({ language_code, value }) => ({
                languageCode: language_code!,
                value: value!
              })) ?? [],
            weight: weight,
            kind: genre.type_kind! as GenreKind,
            metdataKind: [metdataKind],
            childGenreIds: []
          });
          GenreNodeStore.set(new GenreID(genre_id), childGenreM);
          GenreNodeStore.update(parentGenreID, node => {
            node.childGenreIds = [...node.childGenreIds, new GenreID(genre_id)];
          });
        }
      }
    }
  }
};
