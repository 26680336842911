import Axios, { AxiosInstance } from "axios";

type QueryString = Record<string, any>;
type Parameters = Record<string, any> | FormData | URLSearchParams;
type Body = Record<string, any>;
type Headers = Record<string, any>;

enum Method {
  Get = "get",
  Post = "post",
  Put = "put",
  Patch = "patch",
  Delete = "delete"
}

export interface HttpClientOption {
  readonly baseUrl: string;
  readonly headers?: () => Headers;
  readonly failover?: () => Promise<void>;
}

export class HttpClient {
  static create(option: HttpClientOption) {
    return new HttpClient(option);
  }

  private readonly client: AxiosInstance;

  private constructor(private readonly option: HttpClientOption) {
    this.client = Axios.create({ baseURL: option.baseUrl });
  }

  combineHeader(headers: Headers): Headers {
    return Object.assign({}, headers, this.option.headers?.());
  }

  private request<T = any, E extends any = any>(
    method: Method,
    url: string,
    query: QueryString = {},
    body?: null | Body,
    headers: Headers = {}
  ) {
    type R = T & { errors?: readonly E[] };
    const promise = new Promise(async (resolve: (value: R) => void, reject: (value?: any) => void) => {
      try {
        const response = await this.client(url, {
          method,
          params: query,
          data: body,
          headers: this.combineHeader(headers)
        }).catch(async response => {
          if (!this.option.failover) {
            return response;
          }
          switch (response?.response?.status) {
            case 401:
            case 403: {
              await this.option.failover?.();
              const response = await this.client(url, {
                method,
                params: query,
                data: body,
                headers: this.combineHeader(headers)
              });
              return response;
            }
            default: {
              return response;
            }
          }
        });
        if (response instanceof Error) {
          reject(response);
        } else {
          resolve(response?.data as R);
        }
      } catch (error) {
        if (error === "ABORTED") {
          resolve({} as R);
        } else {
          reject(error);
        }
      }
    });
    return promise;
  }

  get<T = any>(url: string, query?: QueryString, headers?: Headers) {
    return this.request<T>(Method.Get, url, query, null, headers);
  }

  post<T = any>(url: string, parameters?: Parameters, headers?: Headers) {
    return this.request<T>(Method.Post, url, {}, parameters, headers);
  }

  put<T = any>(url: string, parameters?: Parameters, headers?: Headers) {
    return this.request<T>(Method.Put, url, {}, parameters, headers);
  }

  patch<T = any>(url: string, parameters?: Parameters, headers?: Headers) {
    return this.request<T>(Method.Patch, url, {}, parameters, headers);
  }

  delete<T = any>(url: string, parameters?: Parameters, headers?: Headers) {
    return this.request<T>(Method.Delete, url, {}, parameters, headers);
  }
}
