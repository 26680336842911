import { Color } from "Constants/Color";
import { noop } from "lodash-es";
import { ChangeEvent, ReactElement } from "react";
import styled from "styled-components";
import { Icon } from "../Icon";

type Props = Styleable & {
  readonly id?: string;
  readonly isDisabled?: boolean;
  readonly isChecked?: boolean;
  readonly text?: string;
  readonly onToggle?: (isChecked: boolean) => void;
};

/**
 *
 * - `isChecked`에 상태를 저장해서 `onToggle`로 상태를 변경해야 합니다.
 * - `id`는 항상 고유해야 하며, 중복일 경우 제대로 동작하지 않습니다.
 */
export const Checkbox = ({
  style,
  className,
  id = `checkbox-default`,
  isDisabled,
  isChecked,
  text,
  onToggle = noop
}: Props): ReactElement => {
  const onToggleCheck = ({ currentTarget: { checked } }: ChangeEvent<HTMLInputElement>) => onToggle(checked);
  return (
    <Layout style={style} className={className}>
      <Input id={id} disabled={isDisabled} checked={isChecked} type="checkbox" onChange={onToggleCheck} />
      <CheckLabel htmlFor={id}>
        <CheckIcon name={!isChecked ? "checkbox" : "checkbox-fill"} />
        {text && <Text>{text}</Text>}
      </CheckLabel>
    </Layout>
  );
};

const Layout = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const Input = styled.input`
  display: none;
`;
const CheckLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const CheckIcon = styled(Icon)``;
const Text = styled.span`
  margin-left: 8px;
  font-size: 12px;
  color: ${Color.Ac_Gray1};
  user-select: none;
  cursor: pointer;
`;
