import { useGenre } from "App/hooks/useGenre";
import { MetdataKind } from "Models/GenreModel/GenreModel";

export const useGenreList = () => {
  const { loading: effectLoading } = useGenre({ kind: MetdataKind.Effect });
  const { loading: cclLoading } = useGenre({ kind: MetdataKind.Ccl });
  const { loading: pdLoading } = useGenre({ kind: MetdataKind.Pd });
  const { loading: trackLoading } = useGenre({ kind: MetdataKind.Track });

  const loading = effectLoading || cclLoading || pdLoading || trackLoading;

  return { loading };
};
