import { ReactElement } from "react";
import { useParams } from "Lib/use-router";
import styled from "styled-components";
import { TrackID } from "Models/TrackModel";
import { MoreTrackListForm } from "./MoreTrackListForm";
import { useTrackDetail } from "../hooks/useTrackDetail";
import { TrackInformationForm } from "./TrackInformationForm";
import { TrackTagInformationForm } from "./TrackTagInformationForm";

export const Form = (): ReactElement => {
  const { id } = useParams();
  const { isLoaded } = useTrackDetail(id!);

  const trackId = new TrackID(+id!);

  return (
    <Layout>
      <TrackInformationForm id={trackId} isLoadedTrack={isLoaded} />
      <TrackTagInformationForm id={trackId} isLoadedTrack={isLoaded} />
      {isLoaded && <MoreTrackListForm id={trackId} />}
    </Layout>
  );
};

const Layout = styled.div`
  position: relative;
`;
