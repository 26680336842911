import { AccountNodeStore } from "App/Store/AccountNodeStore";
import { CurationManagementNodeStore } from "App/Store/CurationManagementStore";
import { CurationManagement, UserTier } from "GraphQL/Schema";
import { AccountID } from "Models/AccountModel/AccountID";
import { AccountModel } from "Models/AccountModel/AccountModel";
import { AccountType } from "Models/AccountModel/AccountType";
import { CurationManagementID, CurationManagementModel } from "Models/CurationManagementModel";
import { FALLBACK_LANGUAGE_CODE } from "Models/LocalizationModel";
import { PlaylistID } from "Models/PlaylistModel";
import { setPlaylistDetail } from "./setPlaylistDetail";

export const setCurationManagementDetail = (curationManagementList: CurationManagement[]) => {
  for (const curationManagement of curationManagementList) {
    const curationManagementM = CurationManagementModel.form({
      id: curationManagement.id,
      isOwner: curationManagement.is_owner,
      playlist: new PlaylistID(curationManagement.playlist_id),
      user: new AccountID(curationManagement.user_id),
      createAt: curationManagement.created_at
    });

    const { id, email: userEmail, name, tier, is_verification } = curationManagement.user;
    const accountType = tier === UserTier.Admin || tier === UserTier.Staff ? AccountType.Admin : AccountType.Normal;
    const accountModel = AccountModel.withType({
      id: id!,
      type: accountType,
      displayName: [{ languageCode: FALLBACK_LANGUAGE_CODE, value: name! }],
      email: userEmail!,
      tier: tier!,
      isVerification: is_verification
    });

    const playlist = curationManagement.playlist;
    setPlaylistDetail([playlist]);
    AccountNodeStore.set(new AccountID(id), accountModel);
    CurationManagementNodeStore.set(new CurationManagementID(curationManagement.id), curationManagementM);
  }
};
