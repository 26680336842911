import { Reference } from "@apollo/client";
import { gql, useMutation } from "Lib/apollo-client";

const REMOVE_PLAYLIST = gql`
  mutation REMOVE_PLAYLIST($playlistId: Int!) {
    removePlaylist: remove_playlist(playlist_id: $playlistId)
  }
`;

type Result = {
  readonly removePlaylist: boolean;
};

type Args = {
  readonly playlistId: number;
};

export const useRemovePlaylist = () => {
  const [fetch, { loading }] = useMutation<Result, Args>(REMOVE_PLAYLIST, {
    update(cache, _, { variables }) {
      cache.modify({
        fields: {
          playlist(existingPlaylistRefs = [], { readField }) {
            const playlistRefs = existingPlaylistRefs.filter(
              (playlistRefs: Reference) => readField("id", playlistRefs) !== variables?.playlistId
            );
            return [...playlistRefs];
          }
        }
      });
    }
  });

  return { removePlaylist: fetch, loading };
};
