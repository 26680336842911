import { Button, ButtonColor, ButtonSize } from "App/Atomics/Button";
import { Typography, TypoSize, TypoWeight } from "App/Atomics/Typography";
import { useAccountNode } from "App/Store/AccountNodeStore";
import { usePayletterBillNode } from "App/Store/PayletterBillNodeStore";
import { usePointTransactionNode } from "App/Store/PointTransactionNodeStore";
import { Color } from "Constants/Color";
import { ADMIN_HISTORY_POINT } from "Constants/Routes";
import { payletterCancel } from "GraphQL/Mutations/usePayletterCancel";
import { useLoadUser } from "GraphQL/Queries/useLoadUser";
import { TransactionType } from "GraphQL/Schema";
import { Toast } from "Lib/toast";
import { useLoading } from "Lib/use-loading";
import { PayletterBillID } from "Models/PayletterBillModel";
import { PointTransactionID } from "Models/PointTransactionModel";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { formatDate } from "Utils/format";
import { useAuth } from "Utils/useAuth";
import { Style } from "../../Style";

export const First = () => {
  const { id } = useParams();
  const { token } = useAuth();
  const navigate = useNavigate();
  const pointTransaction = usePointTransactionNode(new PointTransactionID(+id!));

  const { data: userData, loading } = useLoadUser({
    where: {
      id: +pointTransaction?.targetUserId.raw!
    }
  });
  const mutaionLoading = useLoading();
  const payletterBill = usePayletterBillNode(pointTransaction?.payletterBill);
  const targetAccount = useAccountNode(pointTransaction?.targetUserId);

  if (!pointTransaction || loading) {
    return null;
  }

  const cancelHistoryPoint = async (payletterBillID: PayletterBillID | null) => {
    if (!window.confirm("정말 환불 하시겠습니까?")) {
      return;
    }

    if (!payletterBillID) {
      return;
    }

    try {
      mutaionLoading.setLoading();
      await payletterCancel({
        billId: payletterBillID.raw,
        token: token?.access_token
      });
      mutaionLoading.setLoaded();
      navigate(ADMIN_HISTORY_POINT);
    } catch (error) {
      Toast.error("에러가 발생했습니다");
    }
  };

  const disable =
    (payletterBill?.isCancel ?? true) ||
    (userData?.user[0]?.user_wallet?.balance ?? 0) < pointTransaction.amount ||
    mutaionLoading.isLoaded;

  return (
    <Layout>
      <Typography weight={TypoWeight.Bold} color={Color.Ac_White} size={TypoSize.F24}>
        포인트 히스토리 상세 정보 입니다.
      </Typography>
      <Container>
        <Style.Row>
          <Style.Caption>금액</Style.Caption>
          <Style.SubCaption>{pointTransaction?.transactionType === TransactionType.Deposit ? "충전" : "차감"}금액입니다.</Style.SubCaption>
          <Style.InputText
            type="text"
            value={`${
              pointTransaction?.transactionType === TransactionType.Deposit ? "+" : "-"
            }${pointTransaction.amount.toLocaleString()}원`}
            isReadonly
          />
        </Style.Row>
        <Style.Row>
          <Style.Caption>이벤트</Style.Caption>
          <Style.SubCaption>이벤트 타입입니다</Style.SubCaption>
          <Style.InputText type="text" value={`${pointTransaction.eventType}`} isReadonly />
        </Style.Row>
        <Style.Row>
          <Style.Caption>시간</Style.Caption>
          <Style.SubCaption>요청한 시간입니다</Style.SubCaption>
          <Style.InputText type="text" value={`${formatDate(pointTransaction.createAt)}`} isReadonly />
        </Style.Row>
        <Style.Row>
          <Style.Caption>유저 이메일(이름)</Style.Caption>
          <Style.SubCaption>유저 정보입니다.</Style.SubCaption>
          <Style.InputText type="text" value={`${targetAccount?.email}(${targetAccount?.displayName.text()})`} isReadonly />
        </Style.Row>
        <Style.Row>
          <Style.Caption>소지금액</Style.Caption>
          <Style.SubCaption>유저가 들고있는 금액입니다.</Style.SubCaption>
          <Style.InputText type="text" value={userData?.user[0]?.user_wallet?.balance.toLocaleString() + "원"} isReadonly />
        </Style.Row>
        <Style.Row>
          <Style.Caption>환불여부</Style.Caption>
          <Style.SubCaption>포인트 환불 여부입니다</Style.SubCaption>
          <Style.InputText type="text" value={payletterBill?.isCancel ? "환불됨" : "미환불"} isReadonly />
        </Style.Row>
        <ButtonGroup>
          <SubmitButton
            size={ButtonSize.Small}
            color={disable ? ButtonColor.Disable : ButtonColor.Primary}
            disabled={disable}
            onClick={() => cancelHistoryPoint(pointTransaction.payletterBill)}
          >
            환불
          </SubmitButton>
        </ButtonGroup>
        <Description size={TypoSize.F13}>환불은 페이레터 결제시에만 가능합니다.</Description>
      </Container>
    </Layout>
  );
};

const Layout = styled.div`
  max-width: 500px;
  width: 100%;
  margin: 4rem auto;
`;

const Container = styled.div`
  margin-top: 2rem;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
`;
const SubmitButton = styled(Button)`
  width: 100px;
`;

const Description = styled(Typography)`
  margin-top: 0.5rem;
  display: block;
  text-align: center;
`;
