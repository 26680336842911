import { Metadata } from "GraphQL/Schema";
import { gql } from "Lib/apollo-client";
import { useImperativeQuery } from "Utils/useImperativeQuery";

const LOAD_METADATA_URL = gql`
  query LOAD_METADATA_URL($ids: [ID!]!) {
    metadata(where: { metadata_id__in: $ids }, first: 10000) {
      metadata_id
      metadata_url(where: { valid_check: V3 }) {
        type_url
        url
      }
    }
  }
`;

type Result = {
  readonly metadata: Metadata[];
};

type Args = {
  readonly ids?: number[];
  readonly skip?: boolean;
};

export const useImperativeMetadataUrl = () => {
  const fetch = useImperativeQuery<Result, Args>(LOAD_METADATA_URL);

  return { loadMetadataUrl: fetch };
};
