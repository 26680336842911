import styled from "styled-components";
import { importMDX } from "mdx.macro";
import { Markdown } from "App/Atomics/Markdown";
import { lazy, Suspense } from "react";
import { currentLanguage } from "Lib/localization";
import { Size } from "Constants/Size";
import { Header } from "App/Templates/BasicTemplate/Header";

const Layout = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden scroll;
  flex-flow: column nowrap;
  padding-bottom: ${Size.PADDING_XXX_LARGE_PX};
`;

const Korean = lazy(() => importMDX("./Korean.mdx"));
const English = lazy(() => importMDX("./English.mdx"));

export const CookiePolicy = () => {
  const isKorean = currentLanguage() === "ko";

  const urlSearchParams = new window.URLSearchParams(window.location.search);
  const platform = urlSearchParams.get("platform")?.toLocaleLowerCase() ?? "web";

  return (
    <Layout data-platform={platform}>
      <Header isMenu={false} isSidebar={false} isCenterTab={false} />
      <Container>
        <Markdown mode="dark" kind="legal-notice">
          <Suspense fallback={null}>
            {isKorean && <Korean />}
            {!isKorean && <English />}
          </Suspense>
        </Markdown>
      </Container>
    </Layout>
  );
};

const Container = styled.div`
  margin-top: 5rem;
`;
