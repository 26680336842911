import { setPlaylistDetail } from "App/Common/setPlaylistDetail";
import { useLoadPlaylistDetail } from "GraphQL/Queries/useLoadPlaylistDetail";
import { useLoaded } from "Lib/use-loaded";
import { useEffect } from "react";

export const useCurationDetail = (id: number) => {
  const { data, loading } = useLoadPlaylistDetail({
    id
  });

  const { isLoaded } = useLoaded(loading);
  useEffect(() => {
    if (data) {
      const { playlist } = data;
      setPlaylistDetail(playlist);
    }
  }, [data]);

  return { isLoaded };
};
