import { JwtTokenSet, SignUpPayload } from "GraphQL/Schema";
import { gql, useLazyQuery } from "Lib/apollo-client";

const SIGN_IN_WITH_EMAIL = gql`
  query SIGNIN($email: String!, $password: String!) {
    signIn(payload: { email: $email, password: $password }) {
      iat
      exp
      access_token
      refresh_token
    }
  }
`;

type Result = {
  readonly signIn: JwtTokenSet;
};

export const useSignIn = () => {
  const [signin, { data, error, loading }] = useLazyQuery<Result, SignUpPayload>(SIGN_IN_WITH_EMAIL, { fetchPolicy: "no-cache" });
  return { signin, data, error, loading };
};
