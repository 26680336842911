import { NumericID } from "Utils/id";
import { immerable } from "immer";

export class CartID extends NumericID {
  constructor(id: number) {
    super("cart", id);
  }
}

export type CartPlayload = {
  readonly id: number;
  readonly count: number;
  readonly amount: number;
};

export class CartModel {
  static form({ id, count, amount }: CartPlayload) {
    return new CartModel(new CartID(id), count, amount);
  }

  constructor(readonly id: CartID, public count: number, public amount: number) {}
  readonly [immerable] = true;
}

const DUMMY_CART_ID = new CartID(-1);

export const INIT_CART = new CartModel(DUMMY_CART_ID, 0, 0);
