import { compile, match } from "path-to-regexp";

export const HOME = "/";
export const SUPPORT = "/support";
export const LICENSE = "/license";
export const SIGN_IN = "/sign-in";
export const SIGN_UP = "/sign-up";
export const FIND_PASSWORD = "/find-password";
export const PRIVACY_POLICY = "/privacy-policy";
export const TERM_OF_SERVICE = "/terms-of-service";
export const COOKIE_POLICY = "/cookie-policy";
export const LEGAL_NOTICE = `/legal-notice`;
export const NOT_FOUND = "/not-found";
export const TRACK_DETAIL = `/track/:id`;
export const ARTIST_DETAIL = `/artist/:id`;
export const CART = `/cart`;
export const LIBRARY = `/library`;
export const LIBRARY_PURCHASE_HISTORY = `${LIBRARY}/history`;
export const LIBRARY_PURCHASE_HISTORY_DETAIL = `${LIBRARY}/history/:id`;
export const LIBRARY_PLAYLIST = `${LIBRARY}/playlist`;
export const LIBRARY_FAVORITES = `${LIBRARY}/favorites`;
export const LIBRARY_PURCHASE_TRACK = `${LIBRARY}/purchase/track`;
export const LIBRARY_CURATION = `${LIBRARY}/curation`;
export const ACCOUNT = `/account`;
export const PROFILE = "/profile";
export const MY = "/my";
export const MY_FAVORITES = `${MY}/favorites`;
export const MY_PLAYLIST = `${MY}/playlist`;
export const MY_CURATION = `${MY}/curation`;
export const CURATION_DETAIL = `${MY}/curation/:id`;
export const PLAYLIST_DETAIL = `/playlist/:id`;
export const VERIFY = `/verify/:token`;
export const SIGN_SUCCESS = "/sign-success";
export const SIGN_FAIL = "/sign-fail";
export const SIGN_RESET_PASSWRD = "/reset-success/:token";
export const SIGN_RESET_FAIL = "/sign-reset-fail";
export const PAYMENT_COMPLETE = "/payment/complete";
export const ADMIN = "/admin";
export const ADMIN_HOME = `${ADMIN}/home`;
export const ADMIN_GRADE = `${ADMIN}/grade`;
export const ADMIN_GRADE_EDIT = `${ADMIN}/grade/:id`;
export const ADMIN_CECHARGE_POINT = `${ADMIN}/cecharge/point`;
export const ADMIN_STAFF_CREATE = `${ADMIN}/staff/create`;
export const ADMIN_LICENSE = `${ADMIN}/license`;
export const ADMIN_HISTORY_POINT = `${ADMIN}/history/point`;
export const ADMIN_HISTORY_POINT_EDIT = `${ADMIN}/history/point/:id`;
export const ADMIN_CURATION = `${ADMIN}/curation`;
export const ADMIN_CURATION_CREATE = `${ADMIN}/curation/create`;
export const ADMIN_CURATION_EDIT = `${ADMIN}/curation/:id/edit`;
export const ADMIN_CURATION_TRACK = `${ADMIN}/curation/:id/track`;
export const ADMIN_CURATION_USER = `${ADMIN}/curation/:id/user`;
export const ADMIN_CURATION_MANAGEMENT = `${ADMIN}/curation/management`;
export const ADMIN_PRICE = `${ADMIN}/price`;
export const ADMIN_PRICE_EDIT = `${ADMIN}/price/:id`;
export const ADMIN_SYNCHRONIZATION = `${ADMIN}/synchronization`;
export const ADMIN_PRICE_CREATE = `${ADMIN}/price/create`;
export const ADMIN_WITHDRAWAL_POINT = `${ADMIN}/withdrawal/point`;
export const ADMIN_USER = `${ADMIN}/user`;
export const ADMIN_UPDATE_DURATION = `${ADMIN}/update/duration`;

export const POINT_CHARGE = `/point/charge`;

export const PAY = "/pay";
export const BUY_POINT_SUCCESS = `${PAY}/pay-success`;
export const BUY_POINT_FAIL = `${PAY}/pay-fail`;
export const BUY_POINT_CENCEL = `${PAY}/pay-cancel`;
export const BUY_POINT_ORDER = `${PAY}/order`;

const createCompiler = <Args extends Record<string, void | null | boolean | number | string>, P extends string = string>(path: P) => {
  type PathSegmentation<R extends string> = R extends `/:${infer K}/${infer V}`
    ? { readonly [Key in K]: string } & PathSegmentation<`/${V}`>
    : R extends `/${infer K}/${infer V}` // eslint-disable-line
    ? PathSegmentation<`/${V}`>
    : R extends `/:${infer K}`
    ? { readonly [Key in K]: string }
    : {};

  const compiler = compile(path);
  const matcher = match(path);

  return {
    both(item: PathSegmentation<P>, args: Args): string {
      const urlSearchParams = new window.URLSearchParams(
        Object.fromEntries(Object.entries(args).filter(([_, value = null]) => value !== null) as Array<[string, string]>)
      );
      return [compiler(item), urlSearchParams.toString()].filter(Boolean).join("?");
    },
    args(args: Args): string {
      const urlSearchParams = new window.URLSearchParams(
        Object.fromEntries(Object.entries(args).filter(([_, value = null]) => value !== null) as Array<[string, string]>)
      );
      return [path, urlSearchParams.toString()].filter(Boolean).join("?");
    },
    path(item: PathSegmentation<P>): string {
      return compiler(item);
    },
    isMatch(path: string): boolean {
      return !!matcher(path);
    }
  };
};

export const trackDetailPath = createCompiler<{ id?: number }>(TRACK_DETAIL);
export const artistDetailPath = createCompiler<{ id?: number }>(ARTIST_DETAIL);
export const playListDetailPath = createCompiler<{ id?: number }>(PLAYLIST_DETAIL);
export const curationDetailPath = createCompiler<{ id?: number }>(CURATION_DETAIL);
export const verifyPath = createCompiler<{ id?: number }>(VERIFY);
export const purchaseHistoryDetailPath = createCompiler<{ id?: number }>(LIBRARY_PURCHASE_HISTORY_DETAIL);
export const adminCurationEditPath = createCompiler<{ id?: number }>(ADMIN_CURATION_EDIT);
export const adminCurationTrackPath = createCompiler<{ id?: number }>(ADMIN_CURATION_TRACK);
export const adminCurationUserPath = createCompiler<{ id?: number }>(ADMIN_CURATION_USER);
export const adminGradeEditPath = createCompiler<{ id?: number }>(ADMIN_GRADE_EDIT);
export const adminPaymentCompletePath = createCompiler<{ userId: number; count: number; amount: number; date: string }>(PAYMENT_COMPLETE);
export const adminHistoryPointEditPath = createCompiler<{ id?: number }>(ADMIN_HISTORY_POINT_EDIT);
export const adminPriceEdit = createCompiler<{ id?: number }>(ADMIN_PRICE_EDIT);
