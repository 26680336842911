import { immerable } from "immer";

export type SidebarPayload = {
  isDesktopOpen: boolean;
  isAdminDesktopOpen: boolean;
  isAdminMobileOpen: boolean;
};

export class SidebarModel {
  static from({ isDesktopOpen, isAdminDesktopOpen, isAdminMobileOpen }: SidebarPayload): SidebarModel {
    return new SidebarModel(isDesktopOpen, isAdminDesktopOpen, isAdminMobileOpen);
  }

  static dummy(): SidebarModel {
    return new SidebarModel(true, true, false);
  }

  readonly [immerable] = true;
  constructor(public isDesktopOpen: boolean, public isAdminDesktopOpen: boolean, public isAdminMobileOpen: boolean) {}
}

export const SIDEBAR_MODAL = SidebarModel.dummy();
