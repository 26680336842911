import { createStore } from "Lib/store";

const createInitialState = () => ({
  isLoadedMyFavorites: false as boolean
});

export const {
  useSelector: useMyFavoritesSelector,
  action: MyFavoritesAction,
  ...MyFavoritesStore
} = createStore("MyFavorites", createInitialState, {
  setIsLoadedMyFavorites(state, isLoadedMyFavorites: boolean) {
    state.isLoadedMyFavorites = isLoadedMyFavorites;
  },
  clear(state) {
    state.isLoadedMyFavorites = false;
  }
});
