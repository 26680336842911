import { Artist, Genre, Metadata, Role } from "GraphQL/Schema";
import { gql, useLazyQuery } from "Lib/apollo-client";

const SEARCH_AUTO_COMLETE = gql`
  query SEARCH_AUTO_COMPLETE($word: String!, $first: Int!, $companyIds: [ID!], $subclass: String) {
    metadata(
      first: $first
      where: {
        type_metadata_class: "record"
        type_metadata_subclass: $subclass
        metadata_company_relation__some: { company_id__in: $companyIds }
        metadata_title__some: { tsvec_words__search: $word, type_metadata_title: "name" }
        track_license__some: { country_code__in: ["ZZ", "KR"], copyright: true, neighboring: true }
      }
      group: true
    ) {
      metadata_id
      title
    }
    genre(
      first: $first
      where: {
        metadata_genre_relation__some: {
          metadata__some: { metadata_company_relation__some: { type_kind: rightCompany, company__some: { company_id__in: $companyIds } } }
        }
        genre_title__some: { genre__some: { type_kind: "genre" }, tsvec_words__search: $word, type_genre_title: "name" }
      }
      group: true
    ) {
      genre_id
      name
    }
    mood: genre(
      first: $first
      where: {
        metadata_genre_relation__some: {
          metadata__some: { metadata_company_relation__some: { type_kind: rightCompany, company__some: { company_id__in: $companyIds } } }
        }
        genre_title__some: { genre__some: { type_kind: "mood" }, tsvec_words__search: $word, type_genre_title: "name" }
      }
      group: true
    ) {
      genre_id
      name
    }
    role(
      first: $first
      where: {
        metadata_artist_relation__some: {
          metadata__some: { metadata_company_relation__some: { type_kind: rightCompany, company__some: { company_id__in: $companyIds } } }
        }
        type_role_class: "instrument"
        role_title__some: { tsvec_words__search: $word, type_role_title: "name" }
      }
      group: true
    ) {
      role_id
      name
    }

    artist(
      first: $first
      where: {
        metadata_artist_relation__some: {
          metadata__some: { metadata_company_relation__some: { type_kind: rightCompany, company__some: { company_id__in: $companyIds } } }
        }
        artist_title__some: { tsvec_words__search: $word, type_artist_title: "name" }
        type_artist_class: "person"
      }
      group: true
    ) {
      artist_id
      name
      type_artist_class
    }
  }
`;

type Result = {
  readonly genre: Genre[];
  readonly mood: Genre[];
  readonly role: Role[];
  readonly metadata: Metadata[];
  readonly artist: Artist[];
};

type Args = {
  readonly word?: string;
  readonly first?: number;
  readonly companyIds?: string[];
  readonly subclass: string;
};

export const useSearchAutoComplete = () => {
  const [searchAutoComplete, { loading, data }] = useLazyQuery<Result, Args>(SEARCH_AUTO_COMLETE);

  return { searchAutoComplete, data, loading };
};
