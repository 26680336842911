import styled from "styled-components";
import { Icon } from "App/Atomics/Icon";

import { DESKTOP_MODE, MOBILE_MODE, Size } from "Constants/Size";
import { Color } from "Constants/Color";
import { useCurrent } from "Utils/artistscardPlayer";
import { useTrackNode } from "App/Store/TrackNodeStore";
import { useArtistNode } from "App/Store/ArtistNodeStore";
import { useNavigate } from "react-router-dom";
import { artistDetailPath, trackDetailPath } from "Constants/Routes";
import { ArtistID } from "Models/ArtistModel";
import { Clipboard } from "App/Molecules/Clipboard";
import { configs } from "Config";
import { isGuestAccountModel } from "Models/AccountModel/AccountModel";
import { useAccountSelector } from "App/Store/AccountStore";
import { ModalAction, ModalStore, ModalType } from "App/Store/ModalStore";
import { PlayerAction, PlayerStore } from "App/Store/PlayerStore";
import { FavoriteButton } from "App/Features/Buttons/FavoriteButton";
import { useTrackActivityNodeStore } from "App/Store/TrackActivity";
import { TrackActivityType } from "GraphQL/Schema";
import { TrackBuyMobileButton } from "App/Features/Buttons/TrackBuyMobileButton";

export const TrackInfo = () => {
  const currentTrack = useCurrent();
  const navigate = useNavigate();

  const track = useTrackNode(currentTrack?.track);
  const artist = useArtistNode(track?.artist);
  const isGuest = useAccountSelector(store => isGuestAccountModel(store.current));

  const activity = useTrackActivityNodeStore(store => store.values()).filter(activity => activity.track.raw === track?.id.raw);

  const moveToArtistDetail = (id?: ArtistID) => {
    if (!id) return;
    PlayerStore.dispatch(PlayerAction.close());
    navigate(artistDetailPath.path({ id: id.raw }));
  };

  const openLogin = () => ModalStore.dispatch(ModalAction.replace(ModalType.SIGNIN));

  const openPlaylist = () => {
    if (isGuest) {
      openLogin();
      return;
    }
    ModalStore.dispatch(ModalAction.open(ModalType.PLAYLIST, { track: track?.id }));
  };

  const moveToTrackDetailUrl = `${configs.urls.current}${trackDetailPath.path({ id: track?.id.raw ?? 0 })}`;
  const likeActivity = activity.find(({ type }) => type === TrackActivityType.Like)?.id ?? null;

  if (!track) return null;

  return (
    <Layout>
      <InfoArea>
        <Title>{track?.title}</Title>
        <Artist onClick={() => moveToArtistDetail(artist?.id)}>{artist?.name}</Artist>
      </InfoArea>
      <ButtonGroup>
        <FavoriteButton likeActivity={likeActivity} track={track.id} />
        <TrackBuyMobileButton id={track.id} />
        <StatusIconContainer onClick={openPlaylist}>
          <StatusIcon name="add-playlist" />
        </StatusIconContainer>
        <StatusIconContainer>
          <Clipboard text={moveToTrackDetailUrl}>
            <StatusIcon name="link" />
          </Clipboard>
        </StatusIconContainer>
      </ButtonGroup>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  align-items: center;
  margin: 0 ${Size.MARGIN_LARGE_PX};
  @media ${DESKTOP_MODE} {
    margin: 0 ${Size.MARGIN_X_LARGE_PX};
  }
`;

const InfoArea = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 5rem;
  max-width: rem;
  padding: 0 ${Size.PADDING_SMALL_PX};
  user-select: none;
  @media ${DESKTOP_MODE} {
    padding: 0 ${Size.PADDING_LARGE_PX};
  }
`;
const Title = styled.span`
  font-size: 0.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  @media ${DESKTOP_MODE} {
    font-size: 0.9rem;
  }
`;
const Artist = styled.span`
  font-size: 0.7rem;
  color: ${Color.GRAY_6};
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  &:hover {
    color: ${Color.Ac_Apricot};
    text-decoration: underline;
  }
  @media ${DESKTOP_MODE} {
    font-size: 0.8rem;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;

  @media ${MOBILE_MODE} {
    display: flex;
  }
`;

const StatusIconContainer = styled.div`
  height: 30px;
  min-width: 30px;

  padding: 0 0.1rem;

  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: none;

  @media ${MOBILE_MODE} {
    display: flex;
  }

  &:hover {
    path {
      fill: ${Color.Ac_White};
    }
  }
`;

const StatusIcon = styled(Icon)`
  width: 2rem;

  path {
    fill: ${Color.Ac_Gray1};
  }

  rect {
    fill: rgba(0, 0, 0, 0) !important;
  }
`;
