import { gql, useQuery } from "@apollo/client";
import { GraphqlType } from "Constants/GraphqlType";
import { MetadataWhereInput } from "GraphQL/Schema";

const LOAD_CACHE_METADATA_TRACK_COUNT = gql`
  query LOAD_CACHE_METADATA_TRACK_COUNT($where: MetadataWhereInput) {
    cacheMetadataTrackCount: getCount(node: Metadata, where: { metadata: $where }) {
      count
    }
  }
`;

type Count = {
  count: number;
};

type Result = {
  readonly cacheMetadataTrackCount: Count;
};

type Args = {
  readonly where?: MetadataWhereInput;
  readonly isSkip?: boolean;
};

export const useLoadCacheMetadataTrackCount = ({ where, isSkip }: Args) => {
  const { data, loading } = useQuery<Result, Args>(LOAD_CACHE_METADATA_TRACK_COUNT, {
    variables: {
      where
    },
    context: {
      serviceName: GraphqlType.Cache
    },
    skip: isSkip
  });

  return { data, loading };
};
