import { Icon } from "App/Atomics/Icon";
import { PlayerButton } from "App/Features/Buttons/PlayerButton";
import { Clipboard } from "App/Molecules/Clipboard";
import { useAccountSelector } from "App/Store/AccountStore";
import { useArtistNode } from "App/Store/ArtistNodeStore";
import { ModalAction, ModalStore, ModalType } from "App/Store/ModalStore";
import { useTrackNode } from "App/Store/TrackNodeStore";
import { configs } from "Config";
import { Color } from "Constants/Color";
import { artistDetailPath, trackDetailPath } from "Constants/Routes";
import { MAX_MOBILE_MODE, WIDE_MODE, X_WIDE_MODE } from "Constants/Size";
import { isGuestAccountModel } from "Models/AccountModel/AccountModel";
import { TrackID } from "Models/TrackModel";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useTrackInfo } from "Utils/useTrackInfo";
import { Composer } from "Constants/Locale";
import { secondsToTime } from "Utils/format";
import { FavoriteButton } from "App/Features/Buttons/FavoriteButton";
import { useTrackActivityNodeStore } from "App/Store/TrackActivity";
import { TrackActivityType, TypeMetadataSubclass } from "GraphQL/Schema";
import { TrackBuyMobileButton } from "App/Features/Buttons/TrackBuyMobileButton";
import { BuyButtonInfo } from "App/Molecules/BuyButtonInfo";
import { GenreButton } from "App/Features/Buttons/GenreButton";

type Props = {
  readonly id?: TrackID;
  readonly isDetail?: boolean;
};

export const PurchaseItem = ({ isDetail = false, id }: Props) => {
  const navigate = useNavigate();

  const track = useTrackNode(id!);
  const artist = useArtistNode(track?.artist ?? null);
  const isGuest = useAccountSelector(store => isGuestAccountModel(store.current));
  const activity = useTrackActivityNodeStore(store => store.values()).filter(activity => activity.track.raw === id?.raw);

  const { isInfo } = useTrackInfo();

  const moveToArtistDetail = (id: number) => navigate(artistDetailPath.path({ id: id }));
  const moveToTrackDetail = (id: number) => navigate(trackDetailPath.path({ id: id }));

  const openLogin = () => ModalStore.dispatch(ModalAction.replace(ModalType.SIGNIN));

  const openPlaylist = () => {
    if (isGuest) {
      openLogin();
      return;
    }
    ModalStore.dispatch(ModalAction.open(ModalType.PLAYLIST, { track: track?.id }));
  };

  const openTrackDownload = () => {
    if (isGuest) {
      openLogin();
      return;
    }
    ModalStore.dispatch(ModalAction.open(ModalType.TRACK_DOWNLOAD, { track: track?.id, purchaseActivity: purchaseActivity }));
  };

  const isShow = isDetail && isInfo;
  const moveToTrackDetailUrl = `${configs.urls.current}${trackDetailPath.path({ id: id?.raw })}`;
  const likeActivity = activity.find(({ type }) => type === TrackActivityType.Like)?.id ?? null;
  const purchaseActivity = activity.find(({ type }) => type === TrackActivityType.Purchase)?.id ?? null;

  const openTrackLicense = () => {
    ModalStore.dispatch(ModalAction.open(ModalType.TRACK_LICENSE, { track: track?.id }));
  };

  if (!track) {
    return <></>;
  }

  return (
    <Layout>
      <Track>
        <FlexAudioContainer>
          <ButtonContainer>
            <PlayerButton id={id!} />
          </ButtonContainer>
        </FlexAudioContainer>
        <FlexRightContainer>
          <FlexRightTopContainer>
            <FlexInformationContainer>
              <TrackTitleLayout>
                <TrackTitleContainer>
                  <TrackTitle onClick={() => moveToTrackDetail(track.id.raw)}>{track.title}</TrackTitle>
                </TrackTitleContainer>
                {artist?.name && track.subClass === TypeMetadataSubclass.Bgm && (
                  <ArtistContainer>
                    <Composer />: <Artist onClick={() => moveToArtistDetail(artist.id.raw)}>{artist.name}</Artist>
                  </ArtistContainer>
                )}
              </TrackTitleLayout>
              <DurationLayout>{secondsToTime(track.duration)}</DurationLayout>
              <BpmLayout>{!!track.bpm ? track.bpm : "-"}</BpmLayout>
            </FlexInformationContainer>
            <FlexStatusContainer>
              <FavoriteButton likeActivity={likeActivity} track={track.id} />
              <PlaylistIconContainer onClick={openPlaylist}>
                <StatusIcon name="add-playlist" />
              </PlaylistIconContainer>
              <StatusIconContainer>
                <Clipboard text={moveToTrackDetailUrl}>
                  <StatusIcon name="link" />
                </Clipboard>
              </StatusIconContainer>
              <StatusIconContainer onClick={openTrackDownload}>
                <DownloadButton>
                  <DownLoadIcon name="download" />
                </DownloadButton>
              </StatusIconContainer>
              <StatusIconContainer onClick={openTrackLicense}>
                <LicenseButton>
                  <LicenseIcon name="question" />
                </LicenseButton>
              </StatusIconContainer>
              <TrackBuyMobileButton id={track.id} />
            </FlexStatusContainer>

            <FlexBuyContainer>
              <BuyButtonInfo trackId={track.id} />
            </FlexBuyContainer>
          </FlexRightTopContainer>

          <TrackDetailContainer isDetail={isShow}>
            <Padding />
            {track.genreList.map(id => (
              <GenreButton id={id} />
            ))}
          </TrackDetailContainer>
        </FlexRightContainer>
      </Track>
    </Layout>
  );
};

const Layout = styled.div`
  border-top: 1px solid ${Color.Ac_Gray};

  &:last-child {
    border-bottom: 1px solid ${Color.Ac_Gray};
  }
`;

const TrackDetailContainer = styled.div<{ isDetail: boolean }>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  transition: all 0.3s;

  transition: max-height 0.3s ${props => (props.isDetail ? "cubic-bezier(1, 0, 1, 0)" : "cubic-bezier(0,1,0,1)")};
  max-height: ${props => (props.isDetail ? "99999px" : "0px")};
  overflow: hidden;
`;

const Padding = styled.div`
  width: 100%;
  padding-top: 1rem;
`;

const FlexRightTopContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
`;

const FlexAudioContainer = styled.div`
  margin-right: 1rem;
  flex: 0 0 54px;
`;

const FlexRightContainer = styled.div`
  flex: 1 0 10px;
  min-width: 0;
  flex-direction: column;
  display: flex;
  align-items: center;
`;

const FlexInformationContainer = styled.div`
  margin-right: 1rem;
  flex: 5 0 50%;
  min-width: 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TrackTitleLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex: 9 0 50px;
  font-size: 0.9rem;
  min-width: 0;
`;

const DurationLayout = styled.div`
  margin-right: 0.625rem;
  flex: 1 0 40px;
  font-size: 0.9rem;
  text-align: center;

  @media ${X_WIDE_MODE} {
    display: block;
  }
  display: none;
`;

const BpmLayout = styled.div`
  margin-right: 0.625rem;
  flex: 1 0 40px;
  font-size: 0.9rem;
  text-align: center;
  @media ${X_WIDE_MODE} {
    display: block;
  }
  display: none;
`;

const TrackTitleContainer = styled.div`
  margin-right: 0.625rem;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TrackTitle = styled.span`
  font-size: 1rem;
  font-weight: bold;

  cursor: pointer;
  &:hover {
    color: ${Color.Ac_Apricot};
  }
`;

const FlexStatusContainer = styled.div`
  margin-right: 1rem;
  min-width: 0;

  @media ${WIDE_MODE} {
    flex: 1 0 172px;
    display: flex;
  }

  display: none;

  display: flex;
  align-items: center;
`;

const StatusIconContainer = styled.div`
  height: 30px;
  min-width: 30px;

  padding: 0 5px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    path,
    rect,
    polygon {
      fill: ${Color.Ac_White};
    }
  }
`;

const StatusIcon = styled(Icon)`
  width: 2rem;

  path {
    fill: ${Color.Ac_Gray1};
  }

  rect {
    fill: rgba(0, 0, 0, 0) !important;
  }
`;

const DownLoadIcon = styled(Icon)`
  width: 2rem;
  path,
  rect {
    fill: ${Color.Ac_Gray1};
  }
`;

const ArtistContainer = styled.div`
  font-size: 0.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${Color.Ac_Gray1};
`;
const Artist = styled.span`
  cursor: pointer;
  &:hover {
    color: ${Color.Ac_Apricot};
  }
`;

const FlexBuyContainer = styled.div`
  flex: 1 0 140px;

  @media ${WIDE_MODE} {
    display: block;
  }
  display: none;
`;

const Track = styled.div`
  padding: 25px;
  display: flex;
`;

const DownloadButton = styled.span`
  display: flex !important;
`;

const ButtonContainer = styled.div`
  width: 45px;
  height: 45px;
`;
const PlaylistIconContainer = styled(StatusIconContainer)`
  @media ${MAX_MOBILE_MODE} {
    display: none;
  }
  display: flex;
`;
const LicenseButton = styled.span`
  display: flex;
`;

const LicenseIcon = styled(Icon)`
  width: 1.5rem;
  path,
  rect,
  polygon {
    fill: ${Color.Ac_Gray1};
  }
`;
