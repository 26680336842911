import { gql, useQuery } from "@apollo/client";

import { GraphqlType } from "Constants/GraphqlType";
import { FileRule } from "GraphQL/Schema";

export const LOAD_CACHE_FILE_RULE = gql`
  query LOAD_CACHE_FILE_RULE($first: Int!) {
    fileRule: file_rule(first: $first) {
      id
      mp3_price {
        price
        id
      }
      wav_price {
        price
        id
      }
    }
  }
`;

type Result = {
  readonly fileRule: FileRule[];
};

type Args = {
  readonly first?: number;
};

export const useLoadFileRule = ({ first }: Args) => {
  const { data, loading } = useQuery<Result>(LOAD_CACHE_FILE_RULE, {
    variables: {
      first
    },
    context: {
      serviceName: GraphqlType.Cache
    }
  });
  return { loading, data };
};
