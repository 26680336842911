import { createDuck } from "./store";

export enum Repeat {
  Stop = "stop",
  All = "all",
  Current = "current"
}

export const nextRepeat = (repeat: Repeat): Repeat => {
  switch (repeat) {
    case Repeat.Stop: {
      return Repeat.All;
    }
    case Repeat.All: {
      return Repeat.Current;
    }
    case Repeat.Current: {
      return Repeat.Stop;
    }
  }
};

export function createConfigActions() {
  const createInitialState = () => ({
    isMuted: false,
    volume: 1,
    preloadPrevious: 0,
    preloadNext: 0,
    repeat: Repeat.Stop,
    isAutoPlay: false,
    isShuffle: false
  });

  return createDuck({
    namespace: "Config",
    createInitialState,
    reducers: {
      setPreloadPreviousCount(state, count: number) {
        state.preloadPrevious = count;
      },
      setPreloadNextCount(state, count: number) {
        state.preloadNext = count;
      },
      setRepeat(state, repeat: Repeat) {
        state.repeat = repeat;
      },
      setAutoplay(state, isAutoPlay: boolean) {
        state.isAutoPlay = isAutoPlay;
      },
      mute(state, isMuted: boolean) {
        state.isMuted = isMuted;
      },
      volume(state, volume: number) {
        state.volume = Math.max(Math.min(volume, 1), 0);
      },
      shuffle(state, isShuffle: boolean) {
        state.isShuffle = isShuffle;
      }
    }
  });
}
