import { PurchaseHistoryNodeStore } from "App/Store/PurchaseHistoryNodeStore";
import { PurchaseHistory } from "GraphQL/Schema";
import { AccountID } from "Models/AccountModel/AccountID";
import { PurchaseHistoryID, PurchaseHistoryModel } from "Models/PurchaseHistoryModel";
import { TrackID } from "Models/TrackModel";

export const setPurchaseHistory = (purchaseHistoryList: PurchaseHistory[]) => {
  const purchaseHistoryMs = purchaseHistoryList.map(purchaseHistory => {
    const purchaseHistoryM = PurchaseHistoryModel.form({
      id: purchaseHistory.id,
      isCancel: purchaseHistory.is_cancel,
      amount: purchaseHistory.item_total_amount,
      count: purchaseHistory.item_total_count,
      createdAt: purchaseHistory.created_at,
      updatedAt: purchaseHistory.updated_at,
      type: purchaseHistory.purchase_item![0]?.product_type!,
      productIds: purchaseHistory.purchase_item?.map(purchaseItem => new TrackID(purchaseItem?.product_id!)) ?? [],
      userId: new AccountID(purchaseHistory.user_id)
    });
    PurchaseHistoryNodeStore.set(new PurchaseHistoryID(purchaseHistory.id), purchaseHistoryM);
    return purchaseHistoryM;
  });

  return purchaseHistoryMs;
};
